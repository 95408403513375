import { RootState } from './Store'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
interface ToastStateType {
  isOpen: boolean
  message: string
  duration: number
}

const initialState: ToastStateType = {
  isOpen: false,
  message: '',
  duration: 3000,
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<{ message: string }>) => {
      const { message } = action.payload
      return { ...state, isOpen: true, message }
    },
    close: (state) => {
      return { ...state, isOpen: false, message: '' }
    },
  },
})

export const { open, close } = toastSlice.actions
export const toastState = (state: RootState) => state.toast

export default toastSlice.reducer
