import * as React from 'react'
import { request } from '../../util/request'
import { State } from './state'
import { Options } from '../../components/modal/CaptureControlEdit'

// モーダル処理
export const editStep = {
  none: 0,

  add: 1, // ページ追加
  addComplete: 2, // 追加完了

  edit: 3, // 編集
  editComplete: 4, // 編集完了

  delete: 5, // 削除確認
  deleteComplete: 6, // 削除完了
}

export class Edit {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  checkDisabled = ({
    page,
    pattern,
    event,
    interval,
  }: {
    page?: string
    pattern?: number
    event?: number
    interval?: number
  }) => {
    const selectedCapture = this.state.items.find((item) => item.id === this.state.edit.selectedId)
    if (!selectedCapture) return false
    const editingValue = {
      page: page || this.state.edit.page,
      pattern: pattern || this.state.edit.pattern,
      event: event || this.state.edit.event,
      interval: interval || this.state.edit.interval,
    }
    return (
      selectedCapture.page === editingValue.page &&
      selectedCapture.pattern === editingValue.pattern &&
      selectedCapture.event === editingValue.event &&
      selectedCapture.interval === editingValue.interval
    )
  }

  // ページ更新
  updatePage = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      edit: {
        ...this.state.edit,
        page: event.currentTarget.value,
        disabled: this.checkDisabled({ page: event.currentTarget.value }),
      },
    })
  }

  // ページ入力にフォーカス
  onFocus = () => {
    this.setState({
      ...this.state,
      edit: {
        ...this.state.edit,
        checkPage: this.state.edit.page, // 入力開始時の内容保持
        disabled: true,
      },
    })
  }

  // ページ入力からフォーカスが外れる
  onBlur = (event: React.FormEvent<HTMLInputElement>) => {
    // 入力内容が変更されていて空文字ではない
    if (this.state.edit.page !== this.state.edit.checkPage && this.state.edit.page !== '') {
      // 初回の入力チェック
      const load = this.state.edit.isFirst ? !this.state.edit.firstEvent && !this.state.edit.firstPattern : true
      this.setState({
        ...this.state,
        edit: {
          ...this.state.edit,
          firstPage: false,
          loading: load,
          allDisabled: this.state.edit.isFirst ? load : true,
          disabled: !event.currentTarget.validity.valid || this.checkDisabled({}),
          errorMessage: '',
        },
      })
    }
    // 入力内容そのまま
    else {
      this.setState({
        ...this.state,
        edit: {
          ...this.state.edit,
          disabled: !event.currentTarget.validity.valid || this.checkDisabled({}),
          errorMessage: '',
        },
      })
    }
  }

  // パターン更新
  updatePattern = (event: React.FormEvent<HTMLSelectElement>) => {
    const value = Number(event.currentTarget.value)
    // 初回の入力チェック
    const load = this.state.edit.isFirst
      ? !this.state.edit.firstPage && value !== Options.pattern.none && !this.state.edit.firstEvent
      : true

    this.setState({
      ...this.state,
      edit: {
        ...this.state.edit,
        pattern: value,
        firstPattern: this.state.edit.isFirst ? !(value !== Options.pattern.none) : false,
        loading: this.state.edit.disabled ? false : load, // ページの入力内容次第
        disabled: this.checkDisabled({ pattern: value }),
        allDisabled: this.state.edit.disabled ? false : load, // ページの入力内容次第
      },
    })
  }

  // ページキャプチャ更新
  updateEvent = (event: React.FormEvent<HTMLSelectElement>) => {
    const value = Number(event.currentTarget.value)
    // 初回の入力チェック
    const load = this.state.edit.isFirst
      ? !this.state.edit.firstPage && !this.state.edit.firstPattern && value !== Options.event.none
      : true

    this.setState({
      ...this.state,
      edit: {
        ...this.state.edit,
        event: value,
        firstEvent: this.state.edit.isFirst ? !(value !== Options.event.none) : false,
        loading: this.state.edit.disabled ? false : load, // ページの入力内容次第
        disabled: this.checkDisabled({ event: value }),
        allDisabled: this.state.edit.disabled ? false : load, // ページの入力内容次第
      },
    })
  }

  // 取得間隔更新
  updateInterval = (event: React.FormEvent<HTMLSelectElement>) => {
    const value = Number(event.currentTarget.value)
    this.setState({
      ...this.state,
      edit: {
        ...this.state.edit,
        interval: value,
        loading: this.state.edit.disabled ? false : true, // ページの入力内容次第
        disabled: this.checkDisabled({ interval: value }),
        allDisabled: this.state.edit.disabled ? false : true, // ページの入力内容次第
      },
    })
  }

  // ページ追加
  onAdd = () => {
    this.setState({
      ...this.state,
      edit: {
        ...this.state.edit,
        selectedId: 0,
        disabled: true,
        page: '',
        checkPage: '',
        pattern: Options.pattern.none,
        event: Options.event.none,
        interval: Options.interval.middle,
        isFirst: true, // 初回チェック有効
        firstPage: true,
        firstPattern: true,
        firstEvent: true,
        step: editStep.add,
        errorMessage: '',
      },
    })
  }

  // 編集開始
  onEdit = (id: number, isUnlimited?: boolean) => {
    const item = this.state.items.find((item) => item.id === id)
    if (item) {
      this.setState({
        ...this.state,
        edit: {
          ...this.state.edit,
          selectedId: id,
          disabled: true,
          allDisabled: true,
          loading: true,
          page: item.page,
          checkPage: item.page,
          pattern: isUnlimited ? item.pattern : Options.pattern.full,
          event: item.event,
          interval: item.interval,
          isFirst: false, // 初回チェック無効
          step: editStep.edit,
          errorMessage: '',
        },
      })
    }
  }

  // 削除開始
  onDelete = (id: number) => {
    const item = this.state.items.find((item) => item.id === id)
    if (item) {
      this.setState({
        ...this.state,
        edit: {
          ...this.state.edit,
          selectedId: id,
          page: item.page,
          step: editStep.delete,
        },
      })
    }
  }

  // キャンセル
  onCancel = () => {
    this.setState({
      ...this.state,
      edit: {
        ...this.state.edit,
        selectedId: 0,
        step: editStep.none,
      },
    })
  }

  // 追加実行
  onAddApply = async () => {
    try {
      await request(
        'POST',
        `/api/projects/${this.state.projectId}/page_captures/`,
        true,
        JSON.stringify({
          url_value: this.state.edit.page,
          url_match_type: this.state.edit.pattern,
          event_agg_type: this.state.edit.event,
          interval_type: this.state.edit.event === Options.event.notExec ? null : this.state.edit.interval,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        edit: {
          ...this.state.edit,
          selectedId: 0,
          step: editStep.none,
        },
        toastMessage: '計測対象ページを追加しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        edit: {
          ...this.state.edit,
          step: editStep.none,
        },
        errorMessage: typeof e === 'string' ? e : 'ページの追加に失敗しました。',
      })
    }
  }

  // 編集実行
  onEditApply = async () => {
    try {
      await request(
        'PATCH',
        `/api/projects/${this.state.projectId}/page_captures/${this.state.edit.selectedId}/`,
        true,
        JSON.stringify({
          url_value: this.state.edit.page,
          url_match_type: this.state.edit.pattern,
          event_agg_type: this.state.edit.event,
          interval_type: this.state.edit.event === Options.event.notExec ? null : this.state.edit.interval,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        edit: {
          ...this.state.edit,
          selectedId: 0,
          step: editStep.none,
        },
        toastMessage: '計測対象ページを変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        edit: {
          ...this.state.edit,
          step: editStep.none,
        },
        errorMessage: typeof e === 'string' ? e : 'ページの編集に失敗しました。',
      })
    }
  }

  // 削除実行
  onDeleteApply = async () => {
    try {
      await request(
        'DELETE',
        `/api/projects/${this.state.projectId}/page_captures/${this.state.edit.selectedId}/`,
        true,
      )
      this.setState({
        ...this.state,
        reload: true,
        edit: {
          ...this.state.edit,
          selectedId: 0,
          step: editStep.none,
        },
        toastMessage: '計測対象ページを削除しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        edit: {
          ...this.state.edit,
          step: editStep.none,
        },
        errorMessage: typeof e === 'string' ? e : 'ページの削除に失敗しました。',
      })
    }
  }
}
