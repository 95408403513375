import * as React from 'react'

import { useAvailablePageLayouts } from './api/Report/useAvailablePageLayouts'
import { useGoalList } from './api/Goal/useGoalList'
import { useUrlLimit } from './api/useUrlLimit'

interface Result {
  readonly isCompleted: boolean
  readonly isChecking: boolean
  readonly goalsExists: boolean
  readonly trackingCodeSet: boolean
  readonly pageCapturesExists: boolean
}

interface Props {
  readonly projectId: number
}

/**
 * 提供されたprojectIdに基づいて、レポート閲覧の設定が完了しかどうかを判定します。
 *
 * @param {Object} Props - The input props.
 * @param {string} Props.projectId - The project ID.
 *
 * @returns {Object} Result - The result object.
 * @property {boolean} Result.isCompleted - 設定が完了しているかどうか。
 * @property {boolean} Result.isChecking - 本処理のチェック中かどうか。
 * @property {boolean} Result.goalsExists - ゴールが登録済みかどうか。
 * @property {boolean} Result.trackingCodeSet - トラッキングコードが設置済みかどうか。
 * @property {boolean} Result.pageCapturesExists - ページキャプチャ対象の設定が登録済みかどうか。
 */
export const useReportSettings = ({ projectId }: Props): Result => {
  const { data: availablePageLayouts, isLoading: availablePageLayoutsLoading } = useAvailablePageLayouts({
    projectId: projectId,
  })
  const { data: goalList, isLoading: goalListLoading } = useGoalList({ projectId: projectId })
  const { data: urlLimit, isLoading: urlLimitLoading } = useUrlLimit({ projectId: projectId })

  const goalsExists = React.useMemo(() => !!goalList && goalList.length > 0, [goalList])
  const trackingCodeSet = React.useMemo(() => availablePageLayouts?.isExistsUrls === true, [availablePageLayouts])
  const pageCapturesExists = React.useMemo(() => !!urlLimit && urlLimit.pageCapturesCount > 0, [urlLimit])
  const isCompleted = React.useMemo(
    () => goalsExists && trackingCodeSet && pageCapturesExists,
    [goalsExists, trackingCodeSet, pageCapturesExists],
  )
  const isChecking = React.useMemo(
    () => availablePageLayoutsLoading || goalListLoading || urlLimitLoading,
    [availablePageLayoutsLoading, goalListLoading, urlLimitLoading],
  )

  return {
    isCompleted,
    isChecking,
    goalsExists,
    trackingCodeSet,
    pageCapturesExists,
  }
}
