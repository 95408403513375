import * as React from 'react'
import styled from 'styled-components'
import { Check } from 'styled-icons/boxicons-regular'

interface Props {
  readonly borderColor: string
  readonly iconSize: number
  readonly iconColor: string
}

export function CheckCircle({ iconSize, iconColor, borderColor }: Props) {
  return (
    <StyledCheckCircle borderColor={borderColor}>
      <Check size={iconSize} color={iconColor} />
    </StyledCheckCircle>
  )
}

const StyledCheckCircle = styled.div<{ borderColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 2px solid ${(props) => props.borderColor};
  border-radius: 50%;
  font-weight: bold;
`
