import * as React from 'react'
import { navigate, Redirect, RouteComponentProps } from '@gatsbyjs/reach-router'

import { ReportPageLayout } from '../../components/layout/ReportPageLayout'
import { PAGE_TITLES } from '../../constants'
import { ReportArea } from './ReportArea'
import { ProjectContext } from '../ProjectRoot'
import { BreadcrumbsItemType } from '../../components/breadcrumbs/Breadcrumbs'
import { useFunnelReports } from '../../util/hooks/api/FunnelReport/useFunnelReports'
import { SubActionIconButton } from '../../components/common/Button'
import { ModeEdit } from '@styled-icons/material-outlined/ModeEdit'
import { Authority } from '../../util/Authority'
import { GlobalContext } from '../../GlobalState'
import { useReportSettings } from '../../util/hooks/useReportSettings'

export const FUNNEL_REPORTS_MAX_WIDTH = 1400

interface Props
  extends RouteComponentProps<{
    funnelReportId: string
  }> {}

export function FunnelReportPage(props: Props) {
  const funnelReportId: number = Number(props.funnelReportId)
  if (isNaN(funnelReportId)) return null

  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { baseUrl, projectId },
  } = React.useContext(ProjectContext)

  const { isChecking: isSettingsChecking, isCompleted: isSettingsCompleted } = useReportSettings({ projectId })

  const canView = Authority.canViewFunnelReport(AccountInfo.permissions, projectId)
  const canChange = Authority.canChangeFunnelReport(AccountInfo.permissions, projectId)
  const { data } = useFunnelReports({ projectId, enabled: canView })

  const reportTitle = data?.results?.find((report) => report.id === funnelReportId)?.name || ''

  const { search } = window.location
  const breadcrumbsItems: BreadcrumbsItemType[] = [
    {
      text: PAGE_TITLES.FUNNEL_REPORTS,
      link: `${baseUrl}report/funnel${search}`,
    },
  ]

  if ((!isSettingsChecking && !isSettingsCompleted) || !canView || data?.reportLimitExceeded) {
    return <Redirect to={`${baseUrl}report/funnel`} noThrow />
  }

  const EditButton = (
    <SubActionIconButton
      onClick={() => {
        navigate(`${baseUrl}report/funnel/${funnelReportId}/edit${search}`)
      }}
      iconLeft={<ModeEdit size="16" />}
    >
      編集
    </SubActionIconButton>
  )

  return (
    <ReportPageLayout
      pageTitle={reportTitle}
      breadcrumbsItems={breadcrumbsItems}
      reportAreaMaxWidth={`${FUNNEL_REPORTS_MAX_WIDTH}px`}
      toolMenuLeftSideElement={canChange ? EditButton : undefined}
      goalHidden={true}
      filterHidden={true}
    >
      <ReportArea funnelReportId={funnelReportId} />
    </ReportPageLayout>
  )
}
