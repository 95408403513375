import * as React from 'react'
import { State, Actions } from './state'
import { editStep } from './edit'
import { Modal, ModalButtonWrapper, ModalHeadText, ModalContentWrapper } from '../../components/common/Modal'
import { CaptureControlEdit } from '../../components/modal/CaptureControlEdit'
import { CancelButton, NormalButton } from '../../components/common/Button'

interface Props {
  readonly state: State
  readonly actions: Actions
}

export function CaptureControlModals(props: Props) {
  const { edit } = props.state
  return (
    <div>
      {/* 追加 */}
      {edit.step === editStep.add && (
        <CaptureControlEdit
          created
          opened={edit.step === editStep.add}
          loading={edit.loading}
          allDisabled={edit.allDisabled}
          disabled={edit.disabled}
          page={edit.page}
          pattern={edit.pattern}
          event={edit.event}
          interval={edit.interval}
          pageCount={edit.pageCount}
          estimateCount={edit.estimateCount}
          firstPage={edit.firstPage}
          firstPattern={edit.firstPattern}
          firstEvent={edit.firstEvent}
          isUnlimited={edit.isUnlimited}
          isRegexError={edit.isRegexError}
          errorMessage={edit.errorMessage}
          updatePage={props.actions.edit.updatePage}
          updatePattern={props.actions.edit.updatePattern}
          updateEvent={props.actions.edit.updateEvent}
          updateInterval={props.actions.edit.updateInterval}
          onFocus={props.actions.edit.onFocus}
          onBlur={props.actions.edit.onBlur}
          onCancel={props.actions.edit.onCancel}
          onApply={props.actions.edit.onAddApply}
        />
      )}

      {/* 編集 */}
      {edit.step === editStep.edit && (
        <CaptureControlEdit
          opened={edit.step === editStep.edit}
          loading={edit.loading}
          allDisabled={edit.allDisabled}
          disabled={edit.disabled}
          page={edit.page}
          pattern={edit.pattern}
          event={edit.event}
          interval={edit.interval}
          pageCount={edit.pageCount}
          estimateCount={edit.estimateCount}
          isUnlimited={edit.isUnlimited}
          isRegexError={edit.isRegexError}
          errorMessage={edit.errorMessage}
          updatePage={props.actions.edit.updatePage}
          updatePattern={props.actions.edit.updatePattern}
          updateEvent={props.actions.edit.updateEvent}
          updateInterval={props.actions.edit.updateInterval}
          onFocus={props.actions.edit.onFocus}
          onBlur={props.actions.edit.onBlur}
          onCancel={props.actions.edit.onCancel}
          onApply={props.actions.edit.onEditApply}
        />
      )}

      {/* 削除 */}
      {edit.step === editStep.delete && (
        <Modal
          isOpen={edit.step === editStep.delete}
          onClose={props.actions.edit.onCancel}
          title="計測対象ページの削除"
          width={450}
        >
          <ModalContentWrapper>
            <ModalHeadText>削除してもよろしいですか？</ModalHeadText>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.edit.onCancel}>キャンセル</CancelButton>
            <NormalButton onClick={props.actions.edit.onDeleteApply}>実行</NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}
    </div>
  )
}
