import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'
import { Role } from '../../util/Authority'

// 処理ステップ
export const MemberStep = {
  none: 0,

  editExec: 1, // 編集実行
  editComplete: 2, // 編集完了
  deleteExec: 3, // 削除実行
  deleteComplete: 4, // 削除完了

  invite: 5, // メンバー招待
  inviteConfirm: 6,
  inviteComplete: 7,
}

interface InviteData {
  readonly message: string
}

export class Member {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // メンバー招待フロー開始
  onAdd = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        email: '',
        inviteMemberRole: Role.Project.member,
        inviteDisabled: true,
        inviteMessage: '',
        step: MemberStep.invite,
      },
    })
  }

  // メンバー招待キャンセル
  onCancelAdd = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        email: '',
        inviteMemberRole: Role.Project.member,
        inviteDisabled: true,
        inviteMessage: '',
        step: MemberStep.none,
      },
      modalErrorMessage: '',
    })
  }

  // メールアドレス更新
  updateInviteEmail = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        email: event.currentTarget.value,
        inviteDisabled: !event.currentTarget.validity.valid,
      },
    })
  }

  // 招待者の権限変更
  updateInviteRole = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        inviteMemberRole: Number(event.currentTarget.value),
      },
    })
  }

  // 招待メンバーの情報確認へ
  onConfirmAdd = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        step: MemberStep.inviteConfirm,
      },
    })
  }

  // 招待者情報の入力に戻る
  onBackAddInfo = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        step: MemberStep.invite,
      },
      modalErrorMessage: '',
    })
  }

  // メンバー招待実行
  onApplyAdd = async () => {
    try {
      const json: InviteData = await request(
        'POST',
        `/api/projects/${this.state.project.id}/members/invite/`,
        true,
        JSON.stringify({ email: this.state.member.email, project_role: this.state.member.inviteMemberRole }),
      )
      this.setState({
        ...this.state,
        reload: true,
        member: {
          ...this.state.member,
          email: '',
          inviteMemberRole: Role.Project.member,
          inviteDisabled: true,
          inviteMessage: json.message,
          step: MemberStep.none,
        },
        modalErrorMessage: '',
        toastMessage: json.message,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        modalErrorMessage: typeof e === 'string' ? e : 'メンバーの招待に失敗しました。',
      })
    }
  }

  // メンバーの編集フロー開始
  onEdit = (id: number) => {
    const member = this.state.member.members.find((member) => member.id === id)
    if (member) {
      this.setState({
        ...this.state,
        member: {
          ...this.state.member,
          selectedId: id,
          selectedName: member.userName,
          selectedRole: member.role,
          step: MemberStep.editExec,
        },
      })
    }
  }

  // メンバー編集キャンセル
  onCancelEdit = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        selectedId: 0,
        selectedName: '',
        selectedRole: Role.Project.none,
        editDisabled: true,
        step: MemberStep.none,
      },
    })
  }

  // 権限の変更
  updateRole = (event: React.FormEvent<HTMLSelectElement>) => {
    const member = this.state.member.members.find((member) => member.id === this.state.member.selectedId)
    if (member) {
      this.setState({
        ...this.state,
        member: {
          ...this.state.member,
          selectedRole: Number(event.currentTarget.value),
          editDisabled: !(member.role !== Number(event.currentTarget.value)), // 現在の権限から変更されている
        },
      })
    }
  }

  // メンバー編集実行
  onApplyEdit = async () => {
    try {
      await request(
        'PATCH',
        `/api/projects/${this.state.project.id}/members/${this.state.member.selectedId}/`,
        true,
        JSON.stringify({ role: this.state.member.selectedRole }),
      )
      this.setState({
        ...this.state,
        reload: true,
        member: {
          ...this.state.member,
          selectedId: 0,
          selectedName: '',
          selectedRole: Role.Project.none,
          editDisabled: true,
          step: MemberStep.none,
        },
        toastMessage: 'メンバーの権限を変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        member: {
          ...this.state.member,
          selectedId: 0,
          selectedName: '',
          selectedRole: Role.Project.none,
          editDisabled: true,
          step: MemberStep.none,
        },
        memberErrorMessage: typeof e === 'string' ? e : 'メンバーの編集に失敗しました。',
      })
    }
  }

  // メンバーの削除フロー開始
  onDelete = (id: number) => {
    const member = this.state.member.members.find((member) => member.id === id)
    if (member) {
      this.setState({
        ...this.state,
        member: {
          ...this.state.member,
          selectedId: id,
          selectedName: member.userName,
          step: MemberStep.deleteExec,
        },
      })
    }
  }

  // メンバー削除キャンセル
  onCancelDelete = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        selectedId: 0,
        selectedName: '',
        step: MemberStep.none,
      },
    })
  }

  // メンバー削除
  onApplyDelete = async () => {
    try {
      await request('DELETE', `/api/projects/${this.state.project.id}/members/${this.state.member.selectedId}/`, true)
      this.setState({
        ...this.state,
        reload: true,
        member: {
          ...this.state.member,
          selectedId: 0,
          selectedName: '',
          step: MemberStep.none,
        },
        toastMessage: 'メンバーを削除しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        member: {
          ...this.state.member,
          selectedId: 0,
          selectedName: '',
          step: MemberStep.none,
        },
        memberErrorMessage: typeof e === 'string' ? e : 'メンバーの削除に失敗しました。',
      })
    }
  }
}
