import React from 'react'
import { Authority } from '../../../util/Authority'
import { NetworkChart } from '@styled-icons/boxicons-regular/NetworkChart'
import { NoSavedPanel } from './NoSavedPanel'
import { ImportFieldContent } from './ImportFieldContent'
import { AccordionBox } from '../../common/AccordionBox'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { useDataImportCsv } from '../../../util/hooks/api/DataImport/useDataImportCsv'

export const DATA_TYPE_OPTIONS = [
  { label: '未選択', value: '' },
  { label: 'NUMERIC', value: 11 },
  { label: 'STRING', value: 21 },
  { label: 'TIMESTAMP', value: 31 },
  { label: 'DATE', value: 32 },
] as const

interface Props {
  projectId: string
  permissions: any
  isEditing: boolean
  setIsEditing: (arg: boolean) => void
  setHasImportField: (arg: boolean) => void
}

export function ImportFieldArea(props: Props) {
  const { permissions, projectId } = props
  const { data: dataImportCsv } = useDataImportCsv({ projectId })
  const isImporting = dataImportCsv?.isImporting || false

  return (
    <AccordionBox
      title={
        <>
          インポートフィールド
          {isImporting && (
            <ImportingNotion>データをインポート中です。インポートが終わり次第編集できます。</ImportingNotion>
          )}
        </>
      }
      icon={<NetworkChart />}
      accordionProps={{ defaultExpanded: true }}
    >
      <OverlayArea enableOverlay={isImporting}>
        {Authority.canViewDataImportSchema(permissions, projectId) ? (
          <ImportFieldContent {...props} />
        ) : (
          <NoSavedPanel
            onClickSettingField={() => {
              alert('現在のプランではご利用いただけません')
            }}
            limited={true}
          />
        )}
      </OverlayArea>
    </AccordionBox>
  )
}

const ImportingNotion = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${colors.error};
  padding-left: 16px;
`

const OverlayArea = styled.div<{ enableOverlay: boolean }>`
  position: relative;
  &:after {
    visibility: ${({ enableOverlay }) => (enableOverlay ? 'visible' : 'hidden')};
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
    opacity: 0.6;
    z-index: 10;
  }
`
