import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { PageLayout } from '../components/layout/PageLayout'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { TabPanel } from '../components/tab/TabPanel'
import { Tabs } from '@mui/material'
import { Tab } from '@mui/material'
import { colors } from '../styleConstants'
import { CancelButton, NormalButton } from '../components/common/Button'
import { HelpLink } from '../components/common/HelpLink'
import { HELP_LINKS } from '../constants'
import 'reactjs-popup/dist/index.css'
import { useCreateTargetContent } from '../util/hooks/api/useCreateTargetContent'
import { useEditTargetContent } from '../util/hooks/api/useEditTargetContent'
import { useDeleteTargetContent } from '../util/hooks/api/useDeleteTargetContent'
import { usePageCaptureInfoData } from '../util/hooks/api/usePageCaptureInfoData'
import { PageCaptureInfoTab } from './PageCapture/tabs/PageCaptureInfoTab'
import { CaptureHistoryTab } from './PageCapture/tabs/CaptureHistoryTab'
import { TargetContentsTab } from './PageCapture/tabs/TargetContentsTab'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../components/common/Modal'
import { TargetContentsData } from '../util/Response'
import { useQueryClient } from '@tanstack/react-query'
import { Loading } from '../components/common/Loading'
import { useToast } from '../util/hooks/useToast'
import { useDeviceType } from '../util/hooks/useDeviceType'
import { BreadcrumbsItemType } from '../components/breadcrumbs/Breadcrumbs'

interface PageCaptureProps extends RouteComponentProps {
  projectId?: string
  pageReportId?: string
  urlId?: string
}

const MODAL_KEY = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
} as const

export type ModalKey = typeof MODAL_KEY[keyof typeof MODAL_KEY]

export type PartialResultType = TargetContentsData['results'][number]

const TAB = {
  PAGE_INFO: {
    INDEX: 0,
    LABEL: '自動クローラー設定内容',
  },
  CAPTURE_HISTORY: {
    INDEX: 1,
    LABEL: 'キャプチャ履歴詳細',
  },
  TARGET_CONTENTS: {
    INDEX: 2,
    LABEL: 'ターゲットコンテンツ',
  },
}

const getModalActionText = (modalKey: ModalKey | null) => {
  switch (modalKey) {
    case MODAL_KEY.ADD:
      return '追加'
    case MODAL_KEY.EDIT:
      return '変更'
    case MODAL_KEY.DELETE:
      return '削除'
    default:
      return ''
  }
}

const getModalTitle = (modalKey: ModalKey | null) => `ターゲットコンテンツ${getModalActionText(modalKey)}`

export const PageCapture: React.VFC<PageCaptureProps> = (props) => {
  const [modalKey, setModalKey] = useState<ModalKey | null>(null)
  const [formValue, setFormValue] = useState({ contentName: '', cssSelector: '' })
  const [selectedContent, setSelectedContent] = useState<PartialResultType | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [currentTabIndex, setCurrentTabIndex] = useState(TAB.PAGE_INFO.INDEX)
  const { deviceTypeLabel } = useDeviceType()
  const { openToast } = useToast()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('tab') === 'history') {
      setCurrentTabIndex(TAB.CAPTURE_HISTORY.INDEX)
    }
  }, [])

  const baseUrl = `/projects/${props.projectId}/`

  const { contentName, cssSelector } = formValue

  const isInputEmpty = !(contentName.length && cssSelector.length)
  const isSameAsPrevValue =
    selectedContent?.content_name === contentName && selectedContent?.css_selector === cssSelector

  const queryClient = useQueryClient()

  const { data } = usePageCaptureInfoData({
    projectId: props.projectId || '',
    pageReportId: props.pageReportId || '',
  })

  const { createTargetContentMutate } = useCreateTargetContent({
    projectId: props.projectId || '',
    urlId: props.urlId || '',
  })

  const { editTargetContentMutate } = useEditTargetContent({
    projectId: props.projectId || '',
    urlId: props.urlId || '',
    contentId: selectedContent?.id || 0,
  })

  const { deleteTargetContentMutate } = useDeleteTargetContent({
    projectId: props.projectId || '',
    urlId: props.urlId || '',
    contentId: selectedContent?.id || 0,
  })

  const onClose = () => {
    setModalKey(null)
    setFormValue({ contentName: '', cssSelector: '' })
    setSelectedContent(null)
    setErrorMessage(null)
  }

  const openModal = (modalKey: ModalKey, targetContent: PartialResultType | null) => {
    setModalKey(modalKey)
    setSelectedContent(targetContent)
    modalKey === MODAL_KEY.EDIT &&
      setFormValue({ contentName: targetContent?.content_name || '', cssSelector: targetContent?.css_selector || '' })
  }

  const handleSubmit = (key: ModalKey) => {
    const { contentName, cssSelector } = formValue
    setIsProcessing(true)
    switch (key) {
      case MODAL_KEY.ADD: {
        createTargetContentMutate(
          { content_name: contentName, css_selector: cssSelector },
          {
            onSuccess: async () => {
              await onSuccessHandling(MODAL_KEY.ADD)
            },
            onError(error) {
              setIsProcessing(false)
              setErrorMessage(typeof error === 'string' ? error : 'ターゲットコンテンツ情報の保存に失敗しました。')
            },
          },
        )
        break
      }
      case MODAL_KEY.EDIT: {
        if (!selectedContent) return
        editTargetContentMutate(
          { content_name: contentName, css_selector: cssSelector },
          {
            onSuccess: async () => {
              await onSuccessHandling(MODAL_KEY.EDIT)
            },
            onError(error) {
              setIsProcessing(false)
              setErrorMessage(typeof error === 'string' ? error : 'ターゲットコンテンツ情報の変更に失敗しました。')
            },
          },
        )
        break
      }
      case MODAL_KEY.DELETE: {
        if (!selectedContent) return
        deleteTargetContentMutate(null, {
          onSuccess: async () => {
            await onSuccessHandling(MODAL_KEY.DELETE)
          },
          onError(error) {
            setIsProcessing(false)
            setErrorMessage(typeof error === 'string' ? error : 'ターゲットコンテンツ情報の削除に失敗しました。')
          },
        })
        break
      }
    }
  }

  const onSuccessHandling = async (modalkey: ModalKey) => {
    await queryClient.invalidateQueries(['TargetContentsData'])
    setIsProcessing(false)
    openToast({ message: `ターゲットコンテンツを${getModalActionText(modalkey)}しました` })
    onClose()
  }

  const handleChange = (key: 'contentName' | 'cssSelector') => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({ ...formValue, [key]: event.target.value })
  }

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue)
  }

  const breadcrumbsItems: BreadcrumbsItemType[] = [
    {
      text: 'ページレポート',
      link: `${baseUrl}report/page`,
    },
    {
      text: 'コンテンツレポート',
      link: `${baseUrl}report/content/${props.pageReportId}`,
    },
  ]

  return (
    <PageLayout
      headerTitle={`ページキャプチャ(${deviceTypeLabel})`}
      url={data ? data.url : ''}
      urlTitle={data ? data.title : ''}
      baseUrl={baseUrl}
      breadcrumbsItems={breadcrumbsItems}
    >
      {isProcessing && <Loading />}
      <StyledTabs value={currentTabIndex} onChange={handleChangeTab}>
        <StyledTab label={TAB.PAGE_INFO.LABEL} />
        <StyledTab label={TAB.CAPTURE_HISTORY.LABEL} />
        <StyledTab label={TAB.TARGET_CONTENTS.LABEL} />
      </StyledTabs>
      <div style={{ padding: '77px 32px 32px 32px' }}>
        {/* 取得設定確認 */}
        <TabPanel value={currentTabIndex} index={TAB.PAGE_INFO.INDEX}>
          <div style={{ backgroundColor: colors.white, padding: '32px' }}>
            <PageCaptureInfoTab data={data} />
          </div>
        </TabPanel>

        {/* 取得履歴詳細 */}
        <TabPanel value={currentTabIndex} index={TAB.CAPTURE_HISTORY.INDEX}>
          {props.projectId && props.urlId && <CaptureHistoryTab projectId={props.projectId} urlId={props.urlId} />}
        </TabPanel>

        {/* ターゲットコンテンツ */}
        <TabPanel value={currentTabIndex} index={TAB.TARGET_CONTENTS.INDEX}>
          {props.projectId && props.urlId && (
            <TargetContentsTab
              openModal={openModal}
              projectId={props.projectId}
              urlId={props.urlId}
              isProcessing={isProcessing}
            />
          )}

          {!isProcessing && (
            <div>
              {/* 追加・編集モーダル */}
              <Modal
                isOpen={modalKey === MODAL_KEY.ADD || modalKey === MODAL_KEY.EDIT}
                title={getModalTitle(modalKey)}
                onClose={onClose}
                width={664}
              >
                <ModalContentWrapper>
                  {errorMessage && <ModalErrorText style={{ margin: '0 0 24px 0' }}>{errorMessage}</ModalErrorText>}
                  <ModalLabel htmlFor="content-name">CSSセレクター名</ModalLabel>
                  <ModalInput
                    name="contentName"
                    value={contentName}
                    onChange={handleChange('contentName')}
                    id="content-name"
                    type="text"
                  />
                </ModalContentWrapper>
                <ModalContentWrapper>
                  <ModalLabel htmlFor="css-selector" style={{ display: 'flex', alignItems: 'center' }}>
                    CSSセレクター
                    <div
                      style={{
                        marginLeft: '1rem',
                        height: '14px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <HelpLink title={'CSSセレクターの取得方法'} link={HELP_LINKS.GET_CSS_SELECTOR_HOW_TO} />
                    </div>
                  </ModalLabel>
                  <ModalInput
                    name="cssSelector"
                    value={cssSelector}
                    onChange={handleChange('cssSelector')}
                    id="css-selector"
                    type="text"
                  />
                </ModalContentWrapper>
                <ModalButtonWrapper>
                  <CancelButton onClick={onClose}>キャンセル</CancelButton>
                  <NormalButton
                    disabled={isInputEmpty || isSameAsPrevValue}
                    onClick={() => {
                      if (!modalKey) return
                      handleSubmit(modalKey)
                    }}
                  >
                    保存
                  </NormalButton>
                </ModalButtonWrapper>
              </Modal>

              {/* 削除モーダル */}
              <Modal
                isOpen={modalKey === MODAL_KEY.DELETE && !!selectedContent}
                title={getModalTitle(modalKey)}
                onClose={onClose}
              >
                <ModalContentWrapper>
                  {errorMessage && <ModalErrorText>{errorMessage}</ModalErrorText>}
                  <ModalText style={{ fontWeight: 'bold' }}>CSSセレクター名</ModalText>
                  <ModalText style={{ margin: '0' }}>{selectedContent?.content_name || '-'}</ModalText>
                </ModalContentWrapper>
                <ModalContentWrapper>
                  <ModalText style={{ fontWeight: 'bold' }}>CSSセレクター</ModalText>
                  <ModalText style={{ margin: '0' }}>{selectedContent?.css_selector || '-'}</ModalText>
                </ModalContentWrapper>
                <ModalContentWrapper>
                  <p>このターゲットコンテンツを削除してもよろしいですか？</p>
                </ModalContentWrapper>
                <ModalButtonWrapper>
                  <CancelButton onClick={onClose}>キャンセル</CancelButton>
                  <NormalButton onClick={() => handleSubmit(MODAL_KEY.DELETE)}>実行</NormalButton>
                </ModalButtonWrapper>
              </Modal>
            </div>
          )}
        </TabPanel>
      </div>
    </PageLayout>
  )
}

const ModalLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`
const ModalInput = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0 8px;
  border-radius: 2px;
`

const ModalText = styled.p`
  margin: 0 0 8px 0;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ModalErrorText = styled(ModalText)`
  color: ${colors.error};
  font-weight: bold;
`

const StyledTab = styled(Tab).attrs({ disableRipple: true })`
  padding: 0px 16px;
  min-height: 40px;
  font-size: 14px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  color: ${colors.contentBlue.blue7};
  font-weight: bold;
  background-color: ${colors.contentBlue.blue2};

  &:hover {
    background-color: ${colors.bg};
  }

  &.Mui-selected {
    background-color: ${colors.bg};
    color: ${colors.contentBlue.blue7};
  }
`

const StyledTabs = styled(Tabs)`
  padding-left: 32px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-end;
  position: fixed;
  background-color: ${colors.blue};

  & .MuiTabs-flexContainer {
    gap: 4px;
  }

  & .MuiTabs-indicator {
    opacity: 0;
  }
`
