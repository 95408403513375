import * as React from 'react'
import { Book } from '@styled-icons/boxicons-regular/Book'

import { colors } from '../../../styleConstants'
import { BaseIcon } from '../BaseIcon'

interface Props {
  readonly size?: number
  readonly color?: string
}

export function BlogIcon({ size = 40, color = colors.orange }: Props) {
  return <BaseIcon as={Book} size={size} color={color} />
}
