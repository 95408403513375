import { useCookies } from 'react-cookie'

export const SCOPE_TYPE = {
  SESSION: 'session',
  USER: 'user',
} as const
export type ScopeType = typeof SCOPE_TYPE[keyof typeof SCOPE_TYPE]

const SCOPE_TYPE_KANA = {
  SESSION_KANA: 'セッション',
  USER_KANA: 'ユーザー',
} as const
type ScopeTypeKana = typeof SCOPE_TYPE_KANA[keyof typeof SCOPE_TYPE_KANA]

interface Result {
  readonly scopeType: ScopeType
  readonly setScopeType: (value: ScopeType) => void
  readonly scopeTypeLabel: ScopeTypeKana
}

export const useScopeType = (): Result => {
  const cookieKey = `scopeType`
  const [cookies, setCookie] = useCookies([cookieKey])

  const scopeType = cookies[cookieKey] || SCOPE_TYPE.SESSION

  const setScopeType = (newScopeType: ScopeType) => {
    const validatedNewScopeType = validateScopeType(newScopeType)
    if (!validatedNewScopeType) return
    setCookie(cookieKey, validatedNewScopeType, { path: '/' })
  }

  const scopeTypeLabel = scopeType === SCOPE_TYPE.USER ? SCOPE_TYPE_KANA.USER_KANA : SCOPE_TYPE_KANA.SESSION_KANA

  return { scopeType, setScopeType, scopeTypeLabel }
}

const validateScopeType = (scopeType: string | null): ScopeType | null => {
  const castScopeType = scopeType as ScopeType
  if (!Object.values(SCOPE_TYPE).includes(castScopeType)) {
    return null
  }
  return castScopeType
}

export const getSearchParamsScopeType = (): ScopeType | null => {
  return validateScopeType(new URLSearchParams(location.search).get('scope_type'))
}

export const setSearchParamsScopeType = (scopeType: ScopeType) => {
  const currentUrl = new URL(window.location.href)
  currentUrl.searchParams.set('scope_type', scopeType)
  history.replaceState(null, '', currentUrl.toString())
}
