import * as React from 'react'
import Tippy from '@tippyjs/react/headless'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Options, OptionsList, IntervalList } from '../modal/CaptureControlEdit'
import { Settings } from '@styled-icons/material/Settings'
import { SortIconState } from '../common/SortButton'

export const gridSize = {
  minWidth: '930px',
  maxWidth: '1030px',
  headerHeight: '55px',
  minHeight: '36px',
  row: {
    page: '200px',
    pattern: '130px',
    event: '140px',
    interval: '150px',
    count: '130px',
    estimate: '130px',
    edit: '50px',
  },
}

export interface CaptureControlItem {
  readonly id: number
  readonly page: string // ページ
  readonly pattern: number // 条件
  readonly event: number // ページキャプチャ
  readonly interval: number // 更新頻度
  readonly count: number // ページ数
  readonly estimate: number // 取得数予測
  readonly itemRef?: React.RefObject<HTMLDivElement>
}
export type CaptureControlItemKey = keyof Omit<CaptureControlItem, 'itemRef'>

export interface SortState {
  id: SortIconState
  page: SortIconState
  pattern: SortIconState
  event: SortIconState
  interval: SortIconState
  count: SortIconState
  estimate: SortIconState
  edit: SortIconState
}

interface Props {
  readonly item: CaptureControlItem
  readonly opened: boolean
  readonly selectedId: number
  readonly canChange?: boolean
  readonly canDelete?: boolean
  readonly onClick: (id: number) => void
  readonly onClose: () => void
  readonly onEdit: (id: number, isUnlimited?: boolean) => void
  readonly onDelete: (id: number) => void
  readonly isUnlimited: boolean
}

function Component(props: Props) {
  const { item, opened, selectedId, canChange, canDelete, onClick, onClose, onEdit, onDelete, isUnlimited } = props
  return (
    <Container>
      {/* ページ登録パターン */}
      <Row className="pattern" data-id={item.id}>
        {OptionsList.pattern[item.pattern]}
      </Row>

      {/* ページ */}
      <Row className="page" data-id={item.id}>
        {item.page}
      </Row>

      {/* ページキャプチャ */}
      <Row className="event" data-id={item.id}>
        {OptionsList.event[item.event]}
      </Row>

      {/* 自動キャプチャ頻度 */}
      <Row className="interval" data-id={item.id}>
        {item.event === Options.event.exec && IntervalList[item.interval]}
      </Row>

      {/* ページ数 */}
      <Row className="count" data-id={item.id}>
        {item.count.toLocaleString()}
      </Row>

      {/* 月間取得回数目安 */}
      <Row className="estimate" data-id={item.id}>
        {item.estimate.toLocaleString()}
      </Row>

      {/* エディット */}
      <Row className="edit" data-id={item.id}>
        {(canChange || canDelete) && (
          <Tippy
            visible={opened && selectedId === item.id}
            interactive={true}
            onClickOutside={onClose}
            render={() => (
              <SettingPopup>
                <Links>
                  {canChange && (
                    <DesignedLink
                      onClick={() => {
                        onEdit(item.id, isUnlimited)
                        onClose()
                      }}
                    >
                      編集
                    </DesignedLink>
                  )}
                  {canDelete && (
                    <DesignedLink
                      onClick={() => {
                        onDelete(item.id)
                        onClose()
                      }}
                    >
                      削除
                    </DesignedLink>
                  )}
                </Links>
              </SettingPopup>
            )}
          >
            <SettingBox>
              <SettingIcon onClick={() => onClick(item.id)} />
            </SettingBox>
          </Tippy>
        )}
      </Row>
    </Container>
  )
}

export const CaptureControlGridItem = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div ref={ref}>
      <Component {...props} />
    </div>
  )
})

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: ${gridSize.minHeight};
  box-sizing: border-box;
  white-space: normal;
`

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  font-size: 0.9rem;
  border-left: 1px solid ${colors.gray300};
  border-bottom: 1px solid ${colors.gray300};
  box-sizing: border-box;
  padding: 0.5rem 1rem;

  &.count,
  &.estimate {
    justify-content: flex-end;
  }

  &.pattern {
    justify-content: center;
    width: ${gridSize.row.pattern};
    min-width: ${gridSize.row.pattern};
    max-width: ${gridSize.row.pattern};
  }

  &.page {
    width: 100%;
    word-break: break-all;
    min-width: ${gridSize.row.page};
  }

  &.event {
    justify-content: center;
    width: ${gridSize.row.event};
    min-width: ${gridSize.row.event};
    max-width: ${gridSize.row.event};
  }

  &.interval {
    justify-content: center;
    width: ${gridSize.row.interval};
    min-width: ${gridSize.row.interval};
    max-width: ${gridSize.row.interval};
  }

  &.count {
    width: ${gridSize.row.count};
    min-width: ${gridSize.row.count};
    max-width: ${gridSize.row.count};
  }

  &.estimate {
    width: ${gridSize.row.estimate};
    min-width: ${gridSize.row.estimate};
    max-width: ${gridSize.row.estimate};
  }

  &.edit {
    justify-content: center;
    width: ${gridSize.row.edit};
    min-width: ${gridSize.row.edit};
    max-width: ${gridSize.row.edit};
  }

  &:last-child {
    border-right: 1px solid ${colors.gray300};
  }
`

const SettingBox = styled.div`
  position: relative;
  margin: auto;
`

const SettingIcon = styled(Settings).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const DesignedLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
    background-color: ${colors.gray200};
  }

  & + & {
    margin-top: 0.1rem;
  }
`
const SettingPopup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${colors.white};
  box-shadow: 0 0 4px ${colors.gray400};
  width: 80px;
  right: 55px;
  bottom: -60px;
`
