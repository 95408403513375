import React, { useMemo } from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ChartEvent,
  ActiveElement,
  TooltipItem,
  Chart,
} from 'chart.js'
import { Scatter } from 'react-chartjs-2'
import { safeDivide } from '../../../../util/safeDivide'
import { colors } from '../../../../styleConstants'

export interface QuadrantChartItem {
  readonly id: number
  readonly name: string
  readonly viewRate: number
  readonly goalRate: number
  readonly image?: string
  readonly selected: boolean
}

const POINT_RADIUS = 3
const POINT_RADIUS_BY_SELECTED = 9

interface Props {
  readonly items: QuadrantChartItem[]
  readonly contentsSummaryViewRate: number
  readonly pageSummaryConversionsRate: number
  readonly handleChartItemsSelect?: (ids: number[]) => void
}

interface CustomDataPoint {
  id: number
  name: string
  x: number
  y: number
  viewRate: number
  goalRate: number
  image?: string
}

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)

export function QuadrantChart({
  items,
  contentsSummaryViewRate,
  pageSummaryConversionsRate,
  handleChartItemsSelect,
}: Props) {
  const viewRates = useMemo(() => items.map((item) => item.viewRate), [items])
  const goalRates = useMemo(() => items.map((item) => item.goalRate), [items])
  const maxViewRate = useMemo(() => Math.max(...viewRates), [viewRates])
  const maxGoalRate = useMemo(() => Math.max(...goalRates), [goalRates])
  const minViewRate = useMemo(() => Math.min(...viewRates), [viewRates])
  const minGoalRate = useMemo(() => Math.min(...goalRates), [goalRates])

  // MEMO: 「ソート時にitemsの並び順が変わることでグラフ再描画してしまい一瞬ポイントがアニメーションしてしまう問題」の対応として、ソートしitemsが常に同じ並び順となるようにした
  // また、X軸(viewRate)順にソートすることで左から右にテキストが重なるようにし、テキストのストロークが合わさった時に右側の要素が上に表示されるようにした
  const sortItems = items.sort((a, b) => a.viewRate - b.viewRate)

  const data = {
    datasets: [
      {
        data: sortItems.map(
          (item): CustomDataPoint => ({
            id: item.id,
            name: item.name,
            x: calcScaledValue(item.viewRate, minViewRate, maxViewRate, contentsSummaryViewRate),
            y: calcScaledValue(item.goalRate, minGoalRate, maxGoalRate, pageSummaryConversionsRate),
            viewRate: item.viewRate,
            goalRate: item.goalRate,
            image: item?.image,
          }),
        ),
        backgroundColor: colors.contentBlue.blue6,
        pointRadius: sortItems.map((item) => (item.selected ? POINT_RADIUS_BY_SELECTED : POINT_RADIUS)),
        pointHoverRadius: sortItems.map((item) => (item.selected ? POINT_RADIUS_BY_SELECTED : POINT_RADIUS)),
      },
    ],
  }

  const optionsOnClick = (elements: ActiveElement[]) => {
    if (!handleChartItemsSelect) return

    if (!elements.length) return
    const ids = elements.map((element: any) => {
      return element.element.$context.raw.id
    })
    handleChartItemsSelect(ids)
  }

  const optionsPlugins = {
    tooltip: {
      callbacks: {
        label: function (context: TooltipItem<'scatter'>) {
          const label = []
          const dataPoint = context.raw as CustomDataPoint
          if (dataPoint.name) {
            const name =
              dataPoint.name.length > MAX_TOOLTIP_CONTENT_NAME_LENGTH
                ? dataPoint.name.substring(0, MAX_TOOLTIP_CONTENT_NAME_LENGTH) + '...'
                : dataPoint.name
            label.push(`${dataPoint.id} ${name}`)
          }

          if (dataPoint.viewRate !== null) label.push(`ビュー率: ${dataPoint.viewRate.toFixed(1)}%`)
          if (dataPoint.goalRate !== null) label.push(`ゴール貢献率: ${dataPoint.goalRate.toFixed(2)}%`)
          return label
        },
      },
    },

    legend: {
      display: false, // 凡例は非表示に
    },
  }

  return (
    <Scatter
      // plugin内のlabelが自動更新されないのでkeyが必要だった
      key={`${contentsSummaryViewRate},${pageSummaryConversionsRate}`}
      options={{
        scales: {
          y: makeScaleOptions(LABEL_ID.GOAL),
          x: makeScaleOptions(LABEL_ID.VIEW),
        },
        layout: {
          padding: LAYOUT_PADDING,
        },
        plugins: optionsPlugins,
        onClick: (_event: ChartEvent, element: ActiveElement[]) => optionsOnClick(element),
      }}
      data={data}
      plugins={[
        makeQuadrantBackgroundPlugin(contentsSummaryViewRate, pageSummaryConversionsRate),
        makeAxisLabelPlugin(),
        makePointLabelPlugin(),
      ]}
    />
  )
}

const MAX_POINT_CONTENT_NAME_LENGTH = 10 // ポイントの上に表示するコンテンツ名の最大文字数
const MAX_TOOLTIP_CONTENT_NAME_LENGTH = 20 // ツールチップに表示するコンテンツ名の最大文字数

// 背景色付きグラフエリアの外側の余白
const LAYOUT_PADDING = {
  top: 60,
  right: 50,
  bottom: 35,
  left: 50,
}
const CENTER_LINE_WIDTH = 10 // グラフ中央を十字に走る中央線の太さ
const CONTENT_NAME_VERTICAL_ADJUSTMENT = 6 // ポイントとその上のコンテント名の間の上下余白
const CONTENT_NAME_STROKE_WIDTH = 3 // コンテンツ名の文字を見やすくするストロークの幅
const AXIS_LABEL_PADDING = 45 // X軸・Y軸のラベルとグラフエリアの余白

const FONT_FAMILY = 'Arial'
const FONT_SIZE_SMALL = `12px ${FONT_FAMILY}`
const FONT_SIZE_LARGE = `16px ${FONT_FAMILY}`

const MEDIAN_VALUE = 50

// グラフにプロットする際、点に表示する画像がX軸・Y軸のラベルに重ならないように
// RANGE_SCALED_MINの値で調整する
const RANGE_SCALED_MIN = 3
const RANGE_SCALED_VALUE = MEDIAN_VALUE - RANGE_SCALED_MIN

const LABEL_ID = {
  GOAL: 'GOAL',
  VIEW: 'VIEW',
} as const
type LabelId = keyof typeof LABEL_ID

interface TicksLabel {
  [key: string]: string
}
const VIEW_TICS_LABEL: TicksLabel = {
  '25': '見られていない',
  '75': '見られている',
} as const

interface ScaleOption {
  min: number
  max: number
  grid: {
    color: string
  }
  ticks: {
    stepSize: number
    callback: (value: string | number) => string
  }
}

/**
 * グラフのscaleオプションを生成する
 *
 * 4象限マトリクスは4分割に等分表示としたいため、minを0、maxを100としたscaleのグラフで表示する。
 *
 * @return {ScaleOption} scaleオプション
 */
function makeScaleOptions(labelId: LabelId): ScaleOption {
  return {
    min: 0,
    max: 100,
    grid: {
      // grid線は非表示でOK。50の基準線はplugin(beforeDraw)で表示している。
      color: 'transparent',
    },
    ticks: {
      stepSize: 25,
      callback: function (value: string | number) {
        const valueStr = value.toString()

        if (labelId === LABEL_ID.VIEW) {
          return VIEW_TICS_LABEL[valueStr] || ''
        }
        switch (valueStr) {
          // min/maxが0で、stepSizeが25のため、ticksは0,25,50,75,100となる。
          // 25と75の値の位置に50の基準線より「低い」か「高い」かを示すラベルを表示する。
          case '25':
            return '低'
          case '75':
            return '高'
          default:
            return ''
        }
      },
    },
  }
}

/**
 * グラフにプロットするためのスケーリング値を計算します。
 *
 * @param {number} value - 計算対象の値
 * @param {number} min - 値の範囲内の最小値
 * @param {number} max - 値の範囲内の最大値
 * @param {number} referenceValue - グラフ中心線の基準値
 * @return {number} 計算後の値
 */
function calcScaledValue(value: number, min: number, max: number, referenceValue: number): number {
  // 基準値より小さい値の範囲で、グラフにプロットする位置に変換
  if (value < referenceValue) {
    const scaledValue = safeDivide(RANGE_SCALED_VALUE * (value - min), referenceValue - min)
    return RANGE_SCALED_MIN + scaledValue
  }

  // 基準値以上の値の範囲で、グラフにプロットする位置に変換
  const scaledValue = safeDivide(RANGE_SCALED_VALUE * (value - referenceValue), max - referenceValue)
  return MEDIAN_VALUE + scaledValue
}

/**
 * 4分割の背景色を描画するプラグイン
 *
 * @param {number} contentsSummaryViewRate
 * @param {number} pageSummaryConversionsRate
 */
function makeQuadrantBackgroundPlugin(contentsSummaryViewRate: number, pageSummaryConversionsRate: number) {
  return {
    id: 'makeQuadrantBackgroundPlugin',
    beforeDraw: (chart: Chart) => {
      const { ctx, chartArea } = chart
      ctx.save()

      const splitX = chart.scales.x.getPixelForValue(MEDIAN_VALUE)
      const splitY = chart.scales.y.getPixelForValue(MEDIAN_VALUE)

      // 左上を描画
      ctx.fillStyle = '#E58E6B'
      ctx.fillRect(chartArea.left, chartArea.top, splitX - chartArea.left, splitY - chartArea.top)

      // 右上を描画
      ctx.fillStyle = '#DB6031'
      ctx.fillRect(splitX, chartArea.top, chartArea.right - splitX, splitY - chartArea.top)

      // 左下を描画
      ctx.fillStyle = '#F8DFD4'
      ctx.fillRect(chartArea.left, splitY, splitX - chartArea.left, chartArea.bottom - splitY)

      // 右下を描画
      ctx.fillStyle = '#EEB79F'
      ctx.fillRect(splitX, splitY, chartArea.right - splitX, chartArea.bottom - splitY)

      // 中央線のstyle
      ctx.strokeStyle = colors.white
      ctx.lineWidth = CENTER_LINE_WIDTH

      // X軸中央の縦線を描画
      ctx.beginPath()
      ctx.moveTo(splitX, chartArea.top)
      ctx.lineTo(splitX, chartArea.bottom)
      ctx.stroke()

      // Y軸中央の横線を描画
      ctx.beginPath()
      ctx.moveTo(chartArea.left, splitY)
      ctx.lineTo(chartArea.right, splitY)
      ctx.stroke()

      // text style
      ctx.textAlign = 'left'
      ctx.textBaseline = 'middle'
      ctx.fillStyle = colors.black
      ctx.font = FONT_SIZE_SMALL

      // X軸の分割基準点のラベル
      const yTop = chartArea.top - 25
      ctx.fillText(`▼ビュー率平均: ${contentsSummaryViewRate.toFixed(1)}%`, splitX - 5, yTop)

      // Y軸の分割基準点のラベル
      const xRight = chartArea.right - 0
      ctx.translate(xRight + 20, splitY + 3)
      ctx.rotate(Math.PI / 2)
      ctx.textAlign = 'right'
      ctx.fillText(`ページ全体のゴール率: ${pageSummaryConversionsRate.toFixed(2)}% ▼`, 0, 0)

      ctx.restore()
    },
  }
}

/**
 * グラフ外のX軸・Y軸ラベル
 */
function makeAxisLabelPlugin() {
  return {
    id: 'makeAxisLabelPlugin',
    afterDraw: (chart: Chart) => {
      const { ctx, chartArea } = chart
      ctx.save()

      // text style
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillStyle = colors.black
      ctx.font = FONT_SIZE_LARGE

      // X軸のラベル (上下)
      const xMiddle = (chartArea.left + chartArea.right) / 2
      const yBottom = chartArea.bottom + AXIS_LABEL_PADDING
      ctx.fillText('ビュー率', xMiddle, yBottom) // X軸下部

      // Y軸のラベル
      const yMiddle = (chartArea.top + chartArea.bottom) / 2
      const xLeft = chartArea.left - AXIS_LABEL_PADDING

      // Y軸のラベルを縦に回転
      ctx.translate(xLeft, yMiddle)
      ctx.rotate(-Math.PI / 2)
      ctx.fillText('ゴール貢献率', 0, 0) // Y軸左部
      ctx.restore()
    },
  }
}

/**
 * ポイントの上に「No + テキスト」を表示するプラグイン
 */
function makePointLabelPlugin() {
  return {
    id: 'makePointLabelPlugin',
    afterDatasetsDraw: (chart: Chart) => {
      const { ctx, chartArea } = chart
      ctx.save()

      ctx.font = FONT_SIZE_SMALL
      ctx.textBaseline = 'bottom'

      chart.data.datasets.forEach((dataset: any) => {
        dataset.data.forEach((point: CustomDataPoint, index: number) => {
          if (point && point.id && point.name) {
            const { x, y } = chart.getDatasetMeta(0).data[index]
            const name =
              point.name.length > MAX_POINT_CONTENT_NAME_LENGTH
                ? point.name.substring(0, MAX_POINT_CONTENT_NAME_LENGTH) + '...'
                : point.name
            const text = `${point.id} ${name}`
            const textWidth = ctx.measureText(text).width
            let adjustedX = x

            ctx.textAlign = 'center' // current loop外の次のpointにも影響してしまうため、loop内で毎回宣言

            // 左端 テキストがグラフの端を超える場合はテキスト左右寄せを変更しグラフからはみ出さないようにする
            if (x - textWidth / 2 < chartArea.left) {
              adjustedX = chartArea.left
              ctx.textAlign = 'left'
            }
            // 右端
            else if (x + textWidth / 2 > chartArea.right) {
              adjustedX = chartArea.right
              ctx.textAlign = 'right'
            }

            // 文字が重なった時の可読性をあげるため、文字の外側にストロークを追加
            ctx.strokeStyle = colors.white
            ctx.lineWidth = CONTENT_NAME_STROKE_WIDTH
            ctx.strokeText(text, adjustedX, y - CONTENT_NAME_VERTICAL_ADJUSTMENT)
            ctx.fillText(text, adjustedX, y - CONTENT_NAME_VERTICAL_ADJUSTMENT)
          }
        })
      })

      ctx.restore()
    },
  }
}
