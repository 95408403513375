import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { ReportPageLayout } from '../../components/layout/ReportPageLayout'
import { PAGE_TITLES } from '../../constants'
import { ProjectContext } from '../ProjectRoot'
import { BreadcrumbsItemType } from '../../components/breadcrumbs/Breadcrumbs'
import { EditReportArea } from './EditReportArea'
import { REPORT_REGISTER_FOOTER_HEIGHT } from '../../util/hooks/api/FunnelReport/constants'

export const FUNNEL_REPORTS_MAX_WIDTH = 1400

interface Props
  extends RouteComponentProps<{
    funnelReportId: string
  }> {}

export function FunnelReportEditPage(props: Props) {
  const funnelReportId: number = Number(props.funnelReportId)
  if (isNaN(funnelReportId)) return null

  const {
    state: { baseUrl },
  } = React.useContext(ProjectContext)

  const { search } = window.location

  const breadcrumbsItems: BreadcrumbsItemType[] = [
    {
      text: PAGE_TITLES.FUNNEL_REPORTS,
      link: `${baseUrl}report/funnel${search}`,
    },
  ]

  return (
    <ReportPageLayout
      pageTitle={PAGE_TITLES.FUNNEL_REPORT_EDIT}
      optionHidden={true}
      toolbarHidden={true}
      footerHidden={true}
      footerAreaPadding={REPORT_REGISTER_FOOTER_HEIGHT}
      breadcrumbsItems={breadcrumbsItems}
      reportAreaMaxWidth={`${FUNNEL_REPORTS_MAX_WIDTH}px`}
    >
      <EditReportArea funnelReportId={funnelReportId} />
    </ReportPageLayout>
  )
}
