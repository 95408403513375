import * as React from 'react'
import { State } from './state'

export class Regist {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  onEdit = (id: number) => {
    this.setState({
      ...this.state,
      regist: {
        ...this.state.regist,
        selectedId: id,
      },
    })
  }

  onComplete = () => {
    this.setState({
      ...this.state,
      regist: {
        ...this.state.regist,
        selectedId: null,
      },
    })
  }
}
