import * as React from 'react'
import { dimensionPageTargetColumnOptions } from '../../../util/hooks/api/Filter/constants'
import { DimensionFilterState } from '../../../util/hooks/api/Filter/types'
import { styled } from '@mui/material'
import { Select } from '../../common/Select'

interface Props {
  state: DimensionFilterState
  onDimensionPageTargetColumnChange: ((event: React.FormEvent<HTMLSelectElement>) => void) | undefined
  filterIndex: number
  dimensionIndex: number
  stateIndex: number
}
export function DimensionItemPageTarget({
  state,
  onDimensionPageTargetColumnChange,
  filterIndex,
  dimensionIndex,
  stateIndex,
}: Props) {
  return (
    <DesignedTargetSelect
      value={state.target}
      options={dimensionPageTargetColumnOptions}
      onChange={onDimensionPageTargetColumnChange}
      data-index={filterIndex}
      data-dimensionindex={dimensionIndex}
      data-stateindex={stateIndex}
    />
  )
}

const DesignedSelect = styled(Select)`
  height: 30px;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
`

const DesignedTargetSelect = styled(DesignedSelect)`
  margin-left: 14px;
  width: 100px;
`
