import { useCookies } from 'react-cookie'

const COOKIE_KEY = 'deviceType'
const URL_SEARCH_PARAM = 'device'

// デバイスの種類
export const DEVICE_TYPE = {
  PC: 'pc',
  MOBILE: 'mobile',
} as const
export type DeviceType = typeof DEVICE_TYPE[keyof typeof DEVICE_TYPE]

const DEVICE_TYPE_LABELS = {
  PC: 'PC',
  MOBILE: 'Mobile',
} as const
type DeviceTypeLabel = typeof DEVICE_TYPE_LABELS[keyof typeof DEVICE_TYPE_LABELS]

interface Result {
  readonly deviceType: DeviceType
  readonly setDeviceType: (value: DeviceType) => void
  readonly deviceTypeLabel: DeviceTypeLabel
}
export const useDeviceType = (): Result => {
  const [cookies, setCookie] = useCookies([COOKIE_KEY])

  // デフォルトはmobile
  const deviceType: DeviceType = cookies[COOKIE_KEY] || DEVICE_TYPE.MOBILE

  const setDeviceType = (value: DeviceType) => {
    setCookie(COOKIE_KEY, value, { path: '/' })
  }

  const deviceTypeLabel = deviceType === DEVICE_TYPE.PC ? DEVICE_TYPE_LABELS.PC : DEVICE_TYPE_LABELS.MOBILE

  return { deviceType, setDeviceType, deviceTypeLabel }
}

export const getSearchParamsDeviceType = (): DeviceType | null => {
  const searchParams = new URLSearchParams(location.search)
  const value = searchParams.get(URL_SEARCH_PARAM) as DeviceType
  if (Object.values(DEVICE_TYPE).includes(value)) {
    return value
  }

  return null
}

export const setSearchParamsDeviceType = (value: DeviceType | null) => {
  if (value === null) return
  const currentUrl = new URL(window.location.href)
  currentUrl.searchParams.set(URL_SEARCH_PARAM, value)
  history.replaceState(null, '', currentUrl.toString())
}

// デバイスタイプ番号
// APIなどに使用
export const DEVICE_TYPES_FOR_API = {
  mobile: 1,
  pc: 2,
} as const

export type DeviceTypeForAPI = typeof DEVICE_TYPES_FOR_API[keyof typeof DEVICE_TYPES_FOR_API]

// デバイス番号取得
export function getDeviceLayoutNumber(deviceType: DeviceType) {
  if (deviceType === 'pc') {
    return DEVICE_TYPES_FOR_API.pc
  }
  return DEVICE_TYPES_FOR_API.mobile
}
