import * as React from 'react'
import { request } from '../../util/request'
import {
  PageCapturesData,
  UrlLimitData,
  PageCaptureEstimateCountData,
  PageCaptureUrlCheckData,
} from '../../util/Response'
import { Edit, editStep } from './edit'
import { CaptureControlItem, CaptureControlItemKey, SortState } from '../../components/grid/CaptureControlGridItem'
import { Options } from '../../components/modal/CaptureControlEdit'
import { isURL } from '../../util/isURL'
import { useToast } from '../../util/hooks/useToast'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

export interface State {
  readonly loading: boolean
  readonly reload: boolean
  readonly projectId: string
  readonly items: CaptureControlItem[]
  readonly totalCount: number // 合計ページ数
  readonly totalEstimate: number // 合計月間取得回数目安
  readonly limitCount: number // 月間キャプチャ上限
  readonly isLimited: boolean // 月間キャプチャ概算が月間上限キャプチャを超えていた場合True/それ以外はFalseを返す
  readonly selectedId: number
  readonly sortState: SortState
  readonly isSearch: boolean // 検索状態
  readonly isScroll: boolean
  readonly searchText: string // 検索文字列
  readonly matchedId: number | null // 検索にマッチしたページキャプチャのid
  readonly listRef: React.RefObject<HTMLUListElement> // 計測対象ページ確認時のスクロール用
  readonly toastMessage: string
  readonly edit: {
    // 作成、編集、削除用パラメータ
    readonly loading: boolean
    readonly allDisabled: boolean // 入力全体を止めるか
    readonly selectedId: number
    readonly disabled: boolean
    readonly page: string // ページ
    readonly pattern: number // ページ登録パターン
    readonly event: number // ページキャプチャ
    readonly interval: number // 取得間隔
    readonly pageCount: number // ページ数(APIにて計算)
    readonly estimateCount: number // 月間取得回数目安(APIにて計算)
    readonly step: number // モーダル処理
    readonly checkPage: string // pageの入力変更チェック用
    readonly isFirst: boolean // 初回入力チェック
    readonly firstPage: boolean // 初回ページ入力チェック
    readonly firstPattern: boolean // 初回パターン入力チェック
    readonly firstEvent: boolean // 初回イベント入力チェック
    readonly isUnlimited: boolean // 無制限はtrue、制限ありはfalse
    readonly isRegexError: boolean // 正規表現エラー
    readonly errorMessage?: string
  }

  readonly errorMessage?: string
}

export class Actions {
  edit: Edit
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly openToast: ({ message }: { message: string }) => void,
  ) {
    this.edit = new Edit(state, setState)
  }

  fetch = async (projectId: string) => {
    try {
      const captures: PageCapturesData = await request('GET', `/api/projects/${projectId}/page_captures/`, true)
      const limit: UrlLimitData = await request('GET', `/api/projects/${projectId}/url_limit/`, true)

      this.setState({
        ...this.state,
        loading: false,
        projectId: projectId,
        items:
          captures.count === 0
            ? []
            : captures.results.map((result) => {
                return {
                  id: result.id,
                  page: result.url_value,
                  pattern: result.url_match_type,
                  event: result.event_agg_type,
                  interval: result.interval_type === null ? Options.interval.middle : result.interval_type,
                  count: result.page_count,
                  estimate: result.estimate_count,
                  itemRef: React.createRef<HTMLDivElement>(),
                }
              }),
        matchedId: null,
        totalCount: captures.total_page_count,
        totalEstimate: captures.total_estimate_count,
        limitCount: captures.monthly_limit,
        isLimited: captures.is_limited,
        searchText: '',
        isSearch: false,
        edit: {
          ...this.state.edit,
          pageCount: 0,
          estimateCount: 0,
          isUnlimited: limit.is_unlimited,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        projectId: projectId,
        errorMessage: typeof e === 'string' ? e : 'キャプチャ情報の取得に失敗しました。',
      })
    }
  }

  refetch = async () => {
    try {
      const captures: PageCapturesData = await request(
        'GET',
        `/api/projects/${this.state.projectId}/page_captures/`,
        true,
      )
      this.state.toastMessage && this.openToast({ message: this.state.toastMessage })
      this.setState({
        ...this.state,
        reload: false,
        items:
          captures.count === 0
            ? []
            : captures.results.map((result) => {
                return {
                  id: result.id,
                  page: result.url_value,
                  pattern: result.url_match_type,
                  event: result.event_agg_type,
                  interval: result.interval_type === null ? Options.interval.middle : result.interval_type,
                  count: result.page_count,
                  estimate: result.estimate_count,
                  itemRef: React.createRef<HTMLDivElement>(),
                }
              }),
        matchedId: null,
        searchText: '',
        isSearch: false,
        totalCount: captures.total_page_count,
        totalEstimate: captures.total_estimate_count,
        limitCount: captures.monthly_limit,
        isLimited: captures.is_limited,
        errorMessage: '',
        toastMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        reload: false,
        errorMessage: typeof e === 'string' ? e : 'キャプチャ情報の取得に失敗しました。',
      })
    }
  }

  // 情報の計算
  calc = async () => {
    try {
      const params = new URLSearchParams()
      params.append('url_value', this.state.edit.page)
      params.append('url_match_type', this.state.edit.pattern.toString())
      params.append('event_agg_type', this.state.edit.event.toString())

      // 「取得する」の場合
      if (this.state.edit.event === Options.event.exec) {
        params.append('interval_type', this.state.edit.interval.toString())
      }
      // 編集時はidが必要
      if (this.state.edit.selectedId !== 0) {
        params.append('id', this.state.edit.selectedId.toString())
      }

      const estimate: PageCaptureEstimateCountData = await request(
        'GET',
        `/api/projects/${this.state.projectId}/page_capture_estimate_count/?${params.toString()}`,
        true,
      )
      this.setState({
        ...this.state,
        edit: {
          ...this.state.edit,
          loading: false,
          allDisabled: false,
          pageCount: estimate.page_count,
          estimateCount: estimate.estimate_count,
          isRegexError: false,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        edit: {
          ...this.state.edit,
          loading: false,
          allDisabled: false,
          pageCount: 0,
          estimateCount: 0,
          isRegexError: true,
          errorMessage: typeof e === 'string' ? e : '月間キャプチャ数予測の計算に失敗しました。',
        },
      })
    }
  }

  // ソート
  onSort = (key: CaptureControlItemKey) => {
    // 一度全ての項目のソート状態をリセットする
    for (const e in this.state.sortState) {
      const elem = e as CaptureControlItemKey
      if (key !== elem) {
        this.state.sortState[elem] = 'none'
      }
    }

    if (this.state.sortState[key] === 'down') {
      this.state.items.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1
      })
      this.state.sortState[key] = 'up'
    } else {
      this.state.items.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1
      })
      this.state.sortState[key] = 'down'
    }
    this.setState({ ...this.state })
  }

  // 検索文字列入力
  onUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, searchText: event.currentTarget.value })
  }

  // 検索文言のバリデーション
  validate = () => {
    const isValidUrl = isURL(this.state.searchText)
    if (!isValidUrl) {
      this.setState({
        ...this.state,
        loading: false,
        reload: false,
        errorMessage: '有効なURLを入力してください。',
      })
    }
    return isValidUrl
  }

  // 通常検索
  search = async () => {
    if (this.state.items.length) {
      try {
        const matchedUrl = await this.fetchMatchedUrl(this.state.projectId, this.state.searchText)

        this.setState({
          ...this.state,
          isSearch: this.state.searchText.length === 0 ? false : true,
          matchedId: matchedUrl?.id || null,
          isScroll: true,
          errorMessage: '',
        })
      } catch (e) {
        this.setState({
          ...this.state,
          matchedId: null,
          isSearch: this.state.searchText.length === 0 ? false : true,
          errorMessage: typeof e === 'string' ? e : 'キャプチャ情報の検索に失敗しました。',
        })
      }
    } else {
      this.setState({
        ...this.state,
        isSearch: this.state.searchText.length === 0 ? false : true,
      })
    }
  }

  // 検索クリア
  clear = () => {
    this.setState({ ...this.state, searchText: '', isSearch: false, matchedId: null })
  }

  // 検索時のスクロールからステータス変更
  onReset = () => {
    this.setState({ ...this.state, isScroll: false })
  }

  // URLにマッチするPageCaptureの取得
  fetchMatchedUrl = async (projectId: string, searchText: string): Promise<PageCaptureUrlCheckData | null> => {
    if (searchText === '') return null

    const params = new URLSearchParams()
    params.append('url', searchText)
    return await request('GET', `/api/projects/${projectId}/page_capture_url_check/?${params.toString()}`, true)
  }
}

export const initialState: State = {
  loading: true,
  reload: false,
  projectId: '',
  items: [],
  totalCount: 0,
  totalEstimate: 0,
  limitCount: 0,
  isLimited: false,
  selectedId: 0,
  sortState: {
    id: 'none',
    page: 'none',
    pattern: 'none',
    event: 'none',
    interval: 'none',
    count: 'none',
    estimate: 'none',
    edit: 'none',
  },
  isSearch: false,
  searchText: '',
  matchedId: null,
  listRef: React.createRef<HTMLUListElement>(),
  isScroll: false,
  toastMessage: '',
  edit: {
    loading: false,
    allDisabled: false,
    selectedId: 0,
    disabled: true,
    page: '',
    pattern: Options.pattern.full,
    event: Options.event.exec,
    interval: Options.interval.none,
    pageCount: 0,
    estimateCount: 0,
    step: editStep.none,
    checkPage: '',
    isFirst: false,
    firstPage: false,
    firstPattern: false,
    firstEvent: false,
    isUnlimited: false,
    isRegexError: false,
  },
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const { openToast } = useToast()
  const actions = new Actions(state, setState, openToast)
  return { state, actions }
}
