import React from 'react'
import styled from 'styled-components'
import { TableRow, HeaderCell, TableCell } from '..'
import { InteractiveTooltip } from '../../../Tooltip'
import { ImportData } from '../../../../util/hooks/api/DataImport/types'

interface Props {
  importData: ImportData['importData']
}

export function ImportDataTable({ importData }: Props) {
  const keyFields = Object.keys(importData[0])
  return (
    <Wrapper columnLength={keyFields.length}>
      <TableRow>
        {keyFields.map((keyField) => (
          <InteractiveTooltip key={keyField} content={keyField} disabled={!keyField}>
            <HeaderCell>{keyField}</HeaderCell>
          </InteractiveTooltip>
        ))}
      </TableRow>
      {importData.map((item, index) => (
        <TableRow key={index}>
          {keyFields.map((keyField) => (
            <InteractiveTooltip key={`${index}_${keyField}`} content={item[keyField]} disabled={!item[keyField]}>
              <TableCell>{item[keyField]}</TableCell>
            </InteractiveTooltip>
          ))}
        </TableRow>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ columnLength: number }>`
  display: grid;
  grid-template-columns: ${({ columnLength }) => `repeat(${columnLength}, minmax(120px, auto))`};
  grid-auto-rows: 36px;
  width: 100%;
  max-height: 320px;
  margin-bottom: 32px;
  overflow: auto;
`
