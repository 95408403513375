import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { HELP_LINKS } from '../../../constants'
import { SortItemKey, SortState } from '../../../util/hooks/api/useContentReport'
import { SortButton } from '../../common/SortButton'
import { BaseHeaderCell } from './BaseCell'
import { HeaderCellHelp } from './HeaderCellHelp'
import { useScopeType } from '../../../util/hooks/useScopeType'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'

interface Props {
  readonly sortState: SortState
  readonly onSortClick: (key: SortItemKey) => void
}

export function ViewCountHeaderCell({ sortState, onSortClick }: Props) {
  const { scopeTypeLabel } = useScopeType()

  return (
    <Cell onClick={() => onSortClick('viewCount')}>
      数
      <HeaderCellHelp width={240}>
        <div>同一ページビュー内でコンテンツが累計2秒以上閲覧された{scopeTypeLabel}数。</div>
        <div>
          コンテンツが50%以上表示されると、閲覧秒数をカウントします。詳細は
          <HelpLink href={HELP_LINKS.CONTENT_REPORT_VIEW_COUNT} target="_blank">
            ヘルプ
          </HelpLink>
          を確認ください。
        </div>
      </HeaderCellHelp>
      <SortButton elementKey={'viewCount'} sortIcon={sortState['viewCount']} />
    </Cell>
  )
}

const Cell = styled(BaseHeaderCell)`
  background-color: ${CELL_BACKGROUND_COLORS.VIEW};
`

const HelpLink = styled.a`
  color: ${colors.contentBlue.blue3};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
`
