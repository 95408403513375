import * as React from 'react'
import { Input, InputType, Props as InputProps } from './Input'
import { Eye } from '@styled-icons/evaicons-solid/Eye'
import { EyeOff } from '@styled-icons/evaicons-solid/EyeOff'

interface Props {
  readonly iconSize?: number
}

export function Password(props: Props & InputProps & React.InputHTMLAttributes<any>) {
  const { iconSize = 24, ...rest } = props
  const [typeState, setState] = React.useState<InputType>('password')
  const onClick = () => {
    if (typeState === 'password') {
      setState('text')
    } else {
      setState('password')
    }
  }
  return (
    <Input
      type={typeState}
      iconRight={
        <div onClick={onClick} style={{ cursor: 'pointer' }}>
          {typeState === 'password' && <EyeOff size={iconSize} />}
          {typeState === 'text' && <Eye size={iconSize} />}
        </div>
      }
      {...rest}
    />
  )
}
