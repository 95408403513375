import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { CancelButton, NormalButton } from '../common/Button'
import { Input } from '../common/Input'
import { RadioButton } from '../common/RadioButton'
import { Check } from '@styled-icons/boxicons-regular/Check'

// 表示幅
const layoutWidth = {
  label: '180px',
}

interface Props {
  readonly disabled?: boolean
  readonly isReset?: boolean
  readonly isUserAgent: boolean
  readonly mobileMaxWidth: number | string
  readonly tabletMinWidth: number | string
  readonly tabletMaxWidth: number | string
  readonly pcMinWidth: number | string
  readonly canChangeSiteResponsive?: boolean
  readonly onClick?: () => void
  readonly onChangeMobileWidth?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onChangePcWidth?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onReset?: () => void
  readonly onSave?: () => void
}

export function SiteResponsiveLayout(props: Props) {
  const {
    disabled,
    isReset,
    isUserAgent,
    mobileMaxWidth,
    tabletMinWidth,
    tabletMaxWidth,
    pcMinWidth,
    canChangeSiteResponsive,
    onClick,
    onChangeMobileWidth,
    onChangePcWidth,
    onReset,
    onSave,
  } = props
  const pcMinRange = Math.max(Number(pcMinWidth) - 1, 0)
  const mobileMaxRange = Number(mobileMaxWidth) + 1

  return (
    <Container>
      <MessageBox style={{ display: 'flex', flexDirection: 'column' }}>
        <RadioBox>
          {canChangeSiteResponsive ? (
            <RadioButton
              buttonSize="normal"
              name="siteResponsiveRadioButton"
              checked={isUserAgent}
              onChange={onClick}
              message="User AgentからPC・Mobileを判定して各レポートに振り分ける"
            />
          ) : (
            <Item>
              <Check size={24} color={isUserAgent ? colors.black : colors.white} />
              <div style={{ marginLeft: '0.3rem' }}>User AgentからPC・Mobileを判定して各レポートに振り分ける</div>
            </Item>
          )}
        </RadioBox>
        <MessageBox style={{ margin: '0.7rem 0 0 2rem' }}>
          <Message>Mobileレポートに振り分けられるデバイス： スマートフォン</Message>
          <Message>PCレポートに振り分けられるデバイス： パソコン、タブレットPC</Message>
        </MessageBox>

        <RadioBox style={{ marginTop: '2rem' }}>
          {canChangeSiteResponsive ? (
            <RadioButton
              buttonSize="normal"
              name="siteResponsiveRadioButton"
              checked={!isUserAgent}
              onChange={canChangeSiteResponsive ? onClick : () => {}}
              message="デバイスのブラウザ横幅からPC・Mobileを判定してレポートに振り分ける"
            />
          ) : (
            <Item>
              <Check size={24} color={!isUserAgent ? colors.black : colors.white} />
              <div style={{ marginLeft: '0.3rem' }}>
                デバイスのブラウザ横幅からPC・Mobileを判定してレポートに振り分ける
              </div>
            </Item>
          )}
        </RadioBox>
        <MessageBox style={{ margin: '0.7rem 0 0 2rem' }}>
          <Message>レスポンシブデザインのようにブラウザの横幅によってデザインが変わる場合、</Message>
          <Message>
            デザインが変わる境目によってレポートの種類を変えることで計測精度を向上させることができます。
          </Message>
        </MessageBox>
      </MessageBox>

      {!isUserAgent && (
        <Boxes>
          <Box>
            <Item style={{ width: layoutWidth.label }}>
              <div>Mobileレポート</div>
            </Item>
            {canChangeSiteResponsive ? (
              <DesignedInput
                type="number"
                value={mobileMaxWidth}
                min={0}
                max={pcMinRange === 0 ? '' : pcMinRange}
                onChange={onChangeMobileWidth}
              />
            ) : (
              <Item>{mobileMaxWidth}</Item>
            )}
            <Item style={{ marginLeft: '0.5rem' }}>
              <div>px以下のデバイスを対象にする</div>
            </Item>
          </Box>

          {/* TODO: Tabletは今後対応予定 */}
          <Box>
            <Item style={{ width: layoutWidth.label }}>
              <div style={{ color: `${colors.gray500}` }}>Tabletレポート(無効)</div>
            </Item>
            {/* TODO: 一旦使用できないように */}
            <Item>
              {Number(pcMinWidth) - Number(mobileMaxWidth) > 1 ? (
                <div
                  style={{
                    color: `${colors.gray500}`,
                  }}
                >{`${tabletMinWidth}px以上 ～ ${tabletMaxWidth}以下のデバイスを対象にする(今後実装予定)`}</div>
              ) : (
                <div style={{ color: `${colors.gray500}` }}>対象外</div>
              )}
            </Item>
          </Box>

          <Box>
            <Item style={{ width: layoutWidth.label }}>
              <div>PCレポート</div>
            </Item>
            {canChangeSiteResponsive ? (
              <DesignedInput type="number" value={pcMinWidth} min={mobileMaxRange} onChange={onChangePcWidth} />
            ) : (
              <Item>{pcMinWidth}</Item>
            )}
            <Item style={{ marginLeft: '0.5rem' }}>
              <div>px以上のデバイスを対象にする</div>
            </Item>
          </Box>
        </Boxes>
      )}

      {canChangeSiteResponsive && (
        <Buttons>
          {isReset && <CancelButton onClick={onReset}>リセット</CancelButton>}
          <NormalButton disabled={disabled} onClick={onSave}>
            保存
          </NormalButton>
        </Buttons>
      )}
    </Container>
  )
}

const Container = styled.div``

const RadioBox = styled.div`
  display: flex;
  height: 25px;

  & + & {
    margin-top: 0.7rem;
  }
`

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
`

const Message = styled.div`
  font-size: 0.9rem;

  & + & {
    margin-top: 0.4rem;
  }
`

const Boxes = styled.div`
  margin: 1.5rem 0 0 2rem;
`

const Box = styled.div`
  display: flex;
  height: 35px;

  & + & {
    margin-top: 1.5rem;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  gap: 0 1rem;
`

const DesignedInput = styled(Input)`
  width: 200px;
  height: 35px;
`
