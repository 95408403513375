import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { PlusLg } from 'styled-icons/bootstrap'

import { colors } from '../../styleConstants'

interface Props {
  readonly onClick: () => void
}

export function PlusAddButton({ onClick }: Props) {
  // hover状態で子要素のCSSを書き換える必要があるため、state管理する
  const [hovered, setHovered] = useState(false)
  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  return (
    <Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClick}>
      <StyledPlus size={16} hovered={hovered} />
    </Container>
  )
}

const Container = styled.button`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background-color: ${colors.white};
  border: 1px solid ${colors.contentBlue.blue7};
  cursor: pointer;

  &:hover {
    background-color: ${colors.contentBlue.blue5};
    border: ${colors.contentBlue.blue5};
  }
`

const StyledPlus = styled(PlusLg)<{ hovered: boolean }>`
  color: ${({ hovered }) => {
    return hovered ? colors.white : colors.contentBlue.blue7
  }};
`
