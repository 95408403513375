import React from 'react'
import styled from 'styled-components'
import { addMonths, format, isAfter, isEqual, max, startOfMonth, subDays } from 'date-fns'

import { Plan } from '../../../util/hooks/api/Team/types'
import { Select } from '../../common/Select'

interface Props {
  readonly plan?: Plan
  readonly createdAt?: Date
  readonly onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

/**
 * 消費PV情報閲覧対象の年月プルダウンを生成する
 *
 * 年月プルダウンは、レポート閲覧可能範囲の一番古い年月〜現在の年月までで生成する。
 *
 * ただし、レポート閲覧可能範囲の一番古い年月より引数"createdAt"(組織・プロジェクト登録日)の方が新しい年月の場合、
 * 引数"createdAt"(組織・プロジェクト登録日)の年月〜現在の年月までで生成する。
 * 理由）登録日より前の年月は消費PVがないため。
 *
 * @param {Object} props - The component properties.
 * @param {Object} props.plan - 契約プラン情報(レポート対象期間(日数)を使用する)
 * @param {Date} props.createdAt - プロジェクトや組織の登録日時
 * @param {Function} props.onChange - 年月プルダウン変更時のcallback関数
 */
export function YearMonthSelect({ plan, createdAt, onChange }: Props) {
  const yearMonthOptions = React.useMemo(() => makeYearMonthOptions(plan, createdAt), [plan, createdAt])

  return <DesignedSelect options={yearMonthOptions} onChange={onChange} />
}

function makeYearMonthOptions(plan?: Plan, createdAt?: Date) {
  if (!plan || !createdAt) return []

  const reportDaysLimit = plan.reportDaysLimit
  const today = new Date()
  const minReportDate = subDays(today, reportDaysLimit)

  // 閲覧可能なレポート期間の古い日付より、プロジェクト（組織）登録日が新しい場合は登録日の年月からプルダウンを作成する
  const startYearMonthDate = startOfMonth(max([createdAt, minReportDate]))

  const options: { label: string; value: string }[] = []
  for (
    let currentMonth = today;
    isAfter(currentMonth, startYearMonthDate) || isEqual(currentMonth, startYearMonthDate);
    currentMonth = addMonths(currentMonth, -1)
  ) {
    const yearMonthValue = format(currentMonth, 'yyyyMM')
    const yearMonthLabel = format(currentMonth, 'yyyy/MM')
    options.push({ label: yearMonthLabel, value: yearMonthValue })
  }
  return options
}

const DesignedSelect = styled(Select)`
  width: 110px;
  height: 30px;
  font-size: 14px;
  padding: 4px 12px;
`
