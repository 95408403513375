import { useGenericMutation } from '../useApi'

export const useDeleteTargetContent = ({
  projectId,
  urlId,
  contentId,
}: {
  projectId: string
  urlId: string
  contentId: number
}) => {
  const mutationResults = useGenericMutation<null, string>(
    'DELETE',
    `/projects/${projectId}/urls/${urlId}/target_contents/${contentId}/`,
    true,
  )
  return {
    deleteTargetContentMutate: mutationResults.mutate,
  }
}
