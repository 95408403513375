import * as React from 'react'
import { navigate } from '@gatsbyjs/reach-router'
import { request } from '../../util/request'
import { checkPasswordPattern, checkPasswordLength } from '../../util/Password'
import { PasswordResetVerifyData } from '../../util/Response'
import { OptionProps } from '../../components/common/Select'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly password: string
  readonly disabled: boolean
  readonly loading: boolean
  readonly isUrlValid: boolean
  readonly errorMessage?: string
  readonly token: string // 送信されたURLにtokenが記載されている
  readonly isTeamSelect: boolean
  readonly teamId: number
  readonly teamOptions: OptionProps[]
}

export class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {
    this.submit = this.submit.bind(this)
  }

  verify = async (token?: string) => {
    try {
      const json: PasswordResetVerifyData = await request(
        'POST',
        '/api/password_reset/verify/',
        false,
        JSON.stringify({ token: token }),
      )
      this.setState({
        ...this.state,
        loading: false,
        isUrlValid: true,
        token: token!,
        isTeamSelect: json.code === 'success' ? false : true,
        teamId: json.code === 'success' ? 0 : json.teams[0].id,
        teamOptions:
          json.code === 'success'
            ? []
            : json.teams.map((team) => {
                return { label: team.name, value: team.id }
              }),
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        isUrlValid: false,
        errorMessage: typeof e === 'string' ? e : 'URLの有効期限が切れています。',
      })
    }
  }

  updatePassword = (event: React.FormEvent<HTMLInputElement>) => {
    // 文字列に英数記号が2種類以上含まれるか確認する
    if (!checkPasswordPattern(event.currentTarget.value)) {
      event.currentTarget.setCustomValidity(
        'アルファベット、数字および記号のうち少なくとも２種類以上の組み合わせである必要があります',
      )
    } else {
      event.currentTarget.setCustomValidity('')
    }

    this.setState({
      ...this.state,
      password: event.currentTarget.value,
      disabled: !event.currentTarget.validity.valid,
    })
  }

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // 文字列が足りているか確認
    if (!checkPasswordLength(event.currentTarget.value)) {
      event.currentTarget.setCustomValidity('パスワードは最低１０文字以上必要です')
      event.currentTarget.reportValidity()
    }
    // 文字列に英数記号が2種類以上含まれるか確認
    else if (!checkPasswordPattern(event.currentTarget.value)) {
      event.currentTarget.setCustomValidity(
        'アルファベット、数字および記号のうち少なくとも２種類以上の組み合わせである必要があります',
      )
      event.currentTarget.reportValidity()
    } else {
      event.currentTarget.setCustomValidity('')
    }
    // フォーカスは外す
    event.currentTarget.blur()
  }

  onTeamChange = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({ ...this.state, teamId: Number(event.currentTarget.value) })
  }

  submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      await request(
        'POST',
        '/api/password_reset/complete/',
        false,
        this.state.isTeamSelect
          ? JSON.stringify({
              token: this.state.token,
              new_password1: this.state.password,
              new_password2: this.state.password,
              team_id: this.state.teamId,
            })
          : JSON.stringify({
              token: this.state.token,
              new_password1: this.state.password,
              new_password2: this.state.password,
            }),
      )
      navigate('/password/complete')
    } catch (e) {
      this.setState({
        ...this.state,
        errorMessage: typeof e === 'string' ? e : 'パスワードの変更に失敗しました。',
      })
    }
  }
}

const initialState: State = {
  password: '',
  disabled: true,
  loading: true,
  isUrlValid: false,
  token: '',
  isTeamSelect: false,
  teamId: 0,
  teamOptions: [],
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
