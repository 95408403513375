import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { getDateYYYYMMDDhhmiss } from '../../util/Date'
import { AddButton } from '../common/Button'
import { PopupMenu } from '../common/PopupMenu'
import { Settings } from '@styled-icons/material/Settings'

export interface IpLimitState {
  readonly id: number
  readonly address: string
  readonly memo: string
  readonly updatedUser: string
  readonly updated: string
}

// 表示幅
const layoutWidth = {
  address: '150px',
  memo: '330px',
  updatedUser: '180px',
  updated: '170px',
}

interface Props {
  readonly ipLimits: IpLimitState[]
  readonly canAddRestrictedIpAddress?: boolean
  readonly canChangeRestrictedIpAddress?: boolean
  readonly canDeleteRestrictedIpAddress?: boolean
  readonly onAdd?: () => void
  readonly onEdit?: (id: number) => void
  readonly onDelete?: (id: number) => void
}

export function IpLimitList(props: Props) {
  const {
    ipLimits,
    canAddRestrictedIpAddress,
    canChangeRestrictedIpAddress,
    canDeleteRestrictedIpAddress,
    onAdd,
    onEdit,
    onDelete,
  } = props
  const [state, setState] = React.useState({ id: 0, opened: false })
  const onClick = (id: number) => {
    setState({ ...state, id: id, opened: true })
  }
  const popupClose = () => {
    setState({ ...state, id: 0, opened: false })
  }
  return (
    <Container>
      {canAddRestrictedIpAddress && (
        <div style={{ marginBottom: '0.5rem' }}>
          <AddButton onClick={onAdd}>信頼できるIPアドレスを追加</AddButton>
        </div>
      )}
      {ipLimits.length === 0 ? (
        <div style={{ margin: '1.5rem 0' }}>IPアドレスが登録されていません</div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Item style={{ paddingBottom: '0.5rem' }}>
            <Box className="title" style={{ width: `${layoutWidth.address}` }}>
              IPアドレス
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.memo}` }}>
              説明
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.updatedUser}` }}>
              最終更新者
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.updated}` }}>
              更新日時
            </Box>
          </Item>
          {ipLimits.map((ipLimit) => {
            return (
              <Item key={ipLimit.id}>
                <Box style={{ width: `${layoutWidth.address}` }}>{ipLimit.address}</Box>
                <Box style={{ width: `${layoutWidth.memo}` }}>{ipLimit.memo}</Box>
                <Box style={{ width: `${layoutWidth.updatedUser}` }}>{ipLimit.updatedUser}</Box>
                <Box style={{ width: `${layoutWidth.updated}` }}>{getDateYYYYMMDDhhmiss(ipLimit.updated)}</Box>
                {(canChangeRestrictedIpAddress || canDeleteRestrictedIpAddress) && (
                  <SettingBox>
                    <SettingIcon onClick={() => onClick(ipLimit.id)} />
                    {state.opened && state.id === ipLimit.id && (
                      <PopupMenu
                        onClose={popupClose}
                        style={{
                          right: '25px',
                          bottom: canChangeRestrictedIpAddress && canDeleteRestrictedIpAddress ? '-25px' : '-10px',
                          width: '70px',
                        }}
                      >
                        <Links>
                          {canChangeRestrictedIpAddress && (
                            <DesignedLink onClick={() => onEdit && onEdit(ipLimit.id)}>編集</DesignedLink>
                          )}
                          {canDeleteRestrictedIpAddress && (
                            <DesignedLink onClick={() => onDelete && onDelete(ipLimit.id)}>削除</DesignedLink>
                          )}
                        </Links>
                      </PopupMenu>
                    )}
                  </SettingBox>
                )}
              </Item>
            )
          })}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div``

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  height: 100%;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid ${colors.gray300};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;

  & + & {
    margin-left: 1.5rem;
  }

  &.title {
    color: ${colors.gray500};
  }
`

const SettingBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
`

const SettingIcon = styled(Settings).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const DesignedLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
    background-color: ${colors.gray200};
  }

  & + & {
    margin-top: 0.1rem;
  }
`
