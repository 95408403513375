import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../../styleConstants'
import { ContentCaptureHistory } from '../../../../../util/hooks/api/useContentCaptureHistoryData'
import { getDateYYYYMMDDhhmi } from '../../../../../util/Date'

interface Props {
  histories: Array<ContentCaptureHistory>
}

export function CapturedAtListBox({ histories }: Props) {
  return (
    <Container>
      <Header>
        <Cell>キャプチャ取得日</Cell>
        <Cell>キャプチャ取得時コンテンツの表示</Cell>
      </Header>
      {histories.map((item) => (
        <Item key={item.id} error={!item.captured}>
          <Cell>{getDateYYYYMMDDhhmi(item.capturedAt)}</Cell>
          <CenterCell>{item.captured ? 'あり' : 'なし'}</CenterCell>
        </Item>
      ))}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid ${colors.gray300};
  font-size: 14px;
  line-height: 21px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid ${colors.gray300};
  color: ${colors.gray750};
`

const Item = styled.div<{ error?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid ${colors.gray200};
  color: ${({ error }) => (error ? colors.error : colors.black2)};
`

const Cell = styled.div`
  width: 50%;
  padding: 0 16px;
`
const CenterCell = styled(Cell)`
  text-align: center;
`
