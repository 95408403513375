import React from 'react'
import styled from 'styled-components'
import { Check } from '@styled-icons/boxicons-regular/Check'
import { MessageRoundedError } from '@styled-icons/boxicons-solid/MessageRoundedError'
import { colors } from '../../../../styleConstants'
import { DataImportStatus } from '../../../../util/hooks/api/DataImport/types'
import { DATA_IMPORT_STATUS } from '../../../../util/hooks/api/DataImport/constants'

interface Props {
  status: DataImportStatus
}

export function ResultCell({ status }: Props) {
  switch (status) {
    case DATA_IMPORT_STATUS.SUCCESS:
      return (
        <Wrapper>
          <StyledCheck size={16} />
          <ResultText status={status}>インポート成功</ResultText>
        </Wrapper>
      )
    case DATA_IMPORT_STATUS.DELETED:
      return (
        <Wrapper>
          <ResultText status={status}>データ削除</ResultText>
        </Wrapper>
      )
    case DATA_IMPORT_STATUS.FAILED:
      return (
        <Wrapper>
          <StyledMessageRoundedError size={16} />
          <ResultText status={status}>インポート失敗</ResultText>
        </Wrapper>
      )
    default:
      return null
  }
}

export const Wrapper = styled.div`
  line-height: 36px;
  padding: 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid ${colors.gray350};
  display: flex;
`

const StyledCheck = styled(Check)`
  color: ${colors.success};
  display: block;
  margin: 10px 4px 0 0;
`

const StyledMessageRoundedError = styled(MessageRoundedError)`
  color: ${colors.error};
  display: block;
  margin: 10px 4px 0 0;
`

const ResultText = styled.div<{ status: DataImportStatus }>`
  padding-left: ${({ status }) => (status === DATA_IMPORT_STATUS.DELETED ? '20px' : 0)};
  color: ${({ status }) => {
    switch (status) {
      case DATA_IMPORT_STATUS.SUCCESS:
        return colors.success
      case DATA_IMPORT_STATUS.FAILED:
        return colors.error
      default:
        return colors.gray750
    }
  }};
`
