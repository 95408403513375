import * as React from 'react'
import styled from 'styled-components'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { CancelButton, NormalButton } from '../common/Button'
import { Checkbox } from '../common/Checkbox'
import { Input } from '../common/Input'

interface Props {
  readonly created?: boolean
  readonly opened: boolean
  readonly disabled?: boolean
  readonly name: string
  readonly subdomain: boolean
  readonly sscUrl?: string
  readonly onChangeName?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onClickSubdomain?: () => void
  readonly onCancel: () => void
  readonly onApply?: () => void
}

export function CrossDomainSetting(props: Props) {
  const {
    created = false,
    opened,
    disabled,
    name,
    subdomain,
    onChangeName,
    onClickSubdomain,
    onCancel,
    onApply,
  } = props
  return (
    <Modal isOpen={opened} onClose={onCancel} title={created ? 'クロスドメイン設定' : 'クロスドメインの編集'}>
      <ModalContentWrapper>
        <Subtitle>許可するドメイン [必須]</Subtitle>
        <DesignedInput
          placeholder="ドメイン"
          type="text"
          value={name}
          required
          data-testid="input-name"
          onChange={onChangeName}
          disabled={false}
        />
        <div style={{ display: 'flex' }}>
          <Checkbox
            checked={subdomain}
            onClick={onClickSubdomain}
            style={{ width: '16px', height: '16px', marginRight: '0.5rem' }}
          />
          <div>
            <div style={{ marginBottom: '0.5rem' }}>
              サブドメインを許可：チェックすると、全てのサブドメインを計測可能になります。
            </div>
            <div>例：www.example.com,　blog.example.com等</div>
          </div>
        </div>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <NormalButton disabled={disabled} onClick={onApply}>
          {created ? '追加' : '変更'}
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const Subtitle = styled.div`
  font-weight: bold;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 35px;
  margin: 1rem 0 1.5rem;
`
