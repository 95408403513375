import { useMutation, UseMutationResult } from '@tanstack/react-query'
import html2canvas from 'html2canvas'

import { request } from '../../request'
import { getDeviceLayoutNumber, useDeviceType } from '../useDeviceType'
import { downloadBlobFile } from '../../downloadUtils'
import { PageHistory } from './useContentReport'

export const CAPTURE_AREA_ID = 'capture-target-area'

export type Payload = {
  pageHistories: PageHistory[] | undefined
  displayPageHistoryId: number
}

interface Props {
  projectId: string
}

export const usePageCaptureImageDownload = ({ projectId }: Props): UseMutationResult<void, unknown, Payload> => {
  const { deviceType } = useDeviceType()

  return useMutation({
    mutationFn: async (payload: Payload) => {
      const history = payload.pageHistories?.find((history) => history.id === payload.displayPageHistoryId)
      if (!history) {
        throw Error('Page history not found')
      }

      // 監査ログ出力のためだけにAPIにアクセスしている
      await request(
        'POST',
        `/api/projects/${projectId}/page_histories/${history.id}/download/`,
        true,
        JSON.stringify({
          page_layout: getDeviceLayoutNumber(deviceType),
        }),
      )

      const target: HTMLElement | null = document.getElementById(CAPTURE_AREA_ID)
      if (!target) return

      html2canvas(target, { useCORS: true }).then(async (canvas) => {
        const targetImgUri = canvas.toDataURL('image/png')
        await downloadBlobFile({ downloadUrl: targetImgUri, defaultFilename: history.downloadFilename })
      })
    },
  })
}
