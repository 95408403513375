import React from 'react'
import { Modal, ModalButtonWrapper, ModalHeadText, ModalContentWrapper } from '../../common/Modal'
import { NormalButton, CancelButton } from '../../common/Button'
import { WarningBox } from '../../common/WarningBox'
import styled from 'styled-components'

interface Props {
  opened: boolean
  onClose: () => void
  onCsvImport: () => void
  isImportDataExists: boolean
}

export function CsvImportModal({ opened, onClose, onCsvImport, isImportDataExists }: Props) {
  return (
    <Modal isOpen={opened} onClose={onClose} title="データインポート">
      <ModalContentWrapper>
        <StyledModalHeadText>
          インポートフィールドを保存しました。
          <br />
          先ほどインポートフィールドの読み取りに使用したCSVファイルから、そのままデータをインポートしますか？
        </StyledModalHeadText>
        {isImportDataExists && <WarningBox>インポートすると直近のインポートデータが上書きされます</WarningBox>}
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton onClick={onCsvImport}>インポート</NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const StyledModalHeadText = styled(ModalHeadText)`
  margin-bottom: 16px;
`
