import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Role, RoleLabel } from '../../util/Authority'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { AdminNormalButton, CancelButton } from '../common/Button'
import { Select } from '../common/Select'

interface Props {
  readonly opened: boolean
  readonly role: number
  readonly projects: { id: number; name: string; role: number }[]
  readonly inviteDisabled?: boolean
  readonly onSelect?: (event: React.FormEvent<HTMLDivElement>) => void
  readonly onChange?: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly onBack?: () => void
  readonly onCancel: () => void
  readonly onApply?: () => void
  readonly title: string
}

export function AuthorityModal(props: Props) {
  const { opened, role, projects, inviteDisabled = false, onSelect, onChange, onBack, onCancel, onApply, title } = props
  const authorityOptions = [
    { label: '-', value: Role.Project.none },
    { label: RoleLabel.Project.admin, value: Role.Project.admin },
    { label: RoleLabel.Project.member, value: Role.Project.member },
  ]
  const checked = role === Role.User.admin
  return (
    <Modal isOpen={opened} onClose={onCancel} title={title} width={600}>
      <ModalContentWrapper>
        <ModalText>権限を選択してください。</ModalText>
        <Container>
          <AdminBox className={role === Role.User.admin ? 'selected' : ''} data-id="admin" onClick={onSelect}>
            <DesignedRadioButton type="radio" checked={checked} readOnly />
            <p style={{ marginLeft: '1rem', width: '80px' }}>組織管理者</p>
            <p style={{ marginLeft: '2rem' }}>全プロジェクトの管理権限が付与されます</p>
          </AdminBox>

          <ProjectBox className={role === Role.User.member ? 'selected' : ''} data-id="project" onClick={onSelect}>
            <ProjectSelectBox>
              <DesignedRadioButton type="radio" checked={!checked} readOnly />
              <p style={{ marginLeft: '1rem', width: '80px' }}>プロジェクト</p>
              <p style={{ marginLeft: '2rem' }}>参加するプロジェクトごとに権限を選択できます</p>
            </ProjectSelectBox>
            <ProjectListBox>
              {projects.map((project, index) => {
                return (
                  <ProjectItem key={index}>
                    <div style={{ width: '230px', marginLeft: '1rem' }}>{project.name}</div>
                    <DesignedSelect
                      data-id={project.id}
                      style={{ marginRight: '1rem' }}
                      options={authorityOptions}
                      defaultValue={project.role}
                      onChange={onChange}
                    />
                  </ProjectItem>
                )
              })}
            </ProjectListBox>
          </ProjectBox>
        </Container>
      </ModalContentWrapper>
      <Buttons>
        <Link onClick={onBack}>＜ 戻る</Link>
        <ModalButtonWrapper>
          <CancelButton onClick={onCancel}>キャンセル</CancelButton>
          <AdminNormalButton disabled={inviteDisabled} onClick={onApply}>
            確認画面へ
          </AdminNormalButton>
        </ModalButtonWrapper>
      </Buttons>
    </Modal>
  )
}

const Container = styled.div`
  font-size: 12px;
`

const ModalText = styled.div`
  margin-bottom: 14px;
  font-size: 14px;
`

const AdminBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-style: solid;
  border-width: 2px;
  border-radius: 3px;
  border-color: ${colors.gray200};
  cursor: pointer;
  padding: 0 0.5rem;

  &.selected {
    border-color: ${colors.gray400};
  }
`

const ProjectBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 0.5rem;
  border-style: solid;
  border-width: 2px;
  border-radius: 3px;
  border-color: ${colors.gray200};
  cursor: pointer;
  padding: 0 0.5rem;

  &.selected {
    border-color: ${colors.gray400};
  }
`

const ProjectSelectBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
`

const ProjectListBox = styled.div`
  width: calc(100% - 10px);
  height: 300px;
  overflow-y: auto;
`

const ProjectItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid ${colors.gray200};
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 16px;
`

const DesignedSelect = styled(Select)`
  width: 180px;
  height: 30px;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
`

const DesignedRadioButton = styled.input`
  width: 20px;
  height: 20px;
`

const Link = styled.div`
  color: ${colors.link.base};
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
`
