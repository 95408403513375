import React from 'react'
import { FunnelReportData } from '../../../util/hooks/api/FunnelReport/types'
import { Funnels } from './Funnels'
import styled from 'styled-components'

export interface Props {
  data: FunnelReportData
}

export function Report({ data }: Props) {
  return (
    <Container>
      <Funnels data={data} />
    </Container>
  )
}

const Container = styled.div`
  line-height: 1.5;
`
