import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { History } from '@styled-icons/boxicons-regular/History'
import { RecentImportData } from './RecentImportData'
import { ImportHistory } from './ImportHistory'
import { useDataImportLogs } from '../../../util/hooks/api/DataImport/useDataImportLogs'
import { AccordionBox } from '../../common/AccordionBox'

interface Props {
  projectId: string
  permissions: any
  hasImportField: boolean
  isImportFieldEditing: boolean
}

export function ImportedDataArea({ projectId, permissions, hasImportField, isImportFieldEditing }: Props) {
  const { data: importLogs } = useDataImportLogs({ projectId })
  return (
    <AccordionBox
      title={
        <>
          インポート済データ
          {isImportFieldEditing && (
            <EditingNotion>インポートフィールドを編集中です、インポートフィールドを保存してください。</EditingNotion>
          )}
        </>
      }
      icon={<History />}
      accordionProps={{ defaultExpanded: true }}
    >
      <OverlayArea enableOverlay={isImportFieldEditing}>
        {hasImportField || (importLogs && 0 < importLogs.length) ? (
          <>
            <RecentImportData projectId={projectId} permissions={permissions} />
            <ImportHistory projectId={projectId} permissions={permissions} />
          </>
        ) : (
          <NoDataText>インポートフィールドを設定してください。</NoDataText>
        )}
      </OverlayArea>
    </AccordionBox>
  )
}

const OverlayArea = styled.div<{ enableOverlay: boolean }>`
  position: relative;
  &:after {
    visibility: ${({ enableOverlay }) => (enableOverlay ? 'visible' : 'hidden')};
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
    opacity: 0.6;
    z-index: 1;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.headerBg};
`

const EditingNotion = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${colors.error};
  padding-left: 16px;
`

const NoDataText = styled.div`
  font-size: 16px;
  line-height: 24px;
`

export const TableRow = styled.div`
  display: contents;
`

export const TableCell = styled.div`
  line-height: 36px;
  padding: 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid ${colors.gray350};
`

export const HeaderCell = styled(TableCell)`
  color: ${colors.gray750};
  position: sticky;
  top: 0;
  border-bottom: 1px solid ${colors.gray250};
  background-color: ${colors.white};
`

export const NoDataCell = styled(TableCell)`
  grid-column-start: 1;
  grid-column-end: 5;
  padding: 0;
  border: none;
  font-size: 16px;
`
