import * as React from 'react'
import styled from 'styled-components'
import { IpAddressConditionType, IpAddressConditionMap } from '../../util/IpAddress'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { CancelButton, NormalButton } from '../common/Button'
import { Select } from '../common/Select'
import { Input } from '../common/Input'

const ConditionOptions = [
  { value: IpAddressConditionType.perfect, label: IpAddressConditionMap[IpAddressConditionType.perfect] },
  { value: IpAddressConditionType.head, label: IpAddressConditionMap[IpAddressConditionType.head] },
  { value: IpAddressConditionType.regexp, label: IpAddressConditionMap[IpAddressConditionType.regexp] },
  { value: IpAddressConditionType.partial, label: IpAddressConditionMap[IpAddressConditionType.partial] },
  { value: IpAddressConditionType.rear, label: IpAddressConditionMap[IpAddressConditionType.rear] },
]

interface Props {
  readonly created?: boolean
  readonly opened: boolean
  readonly disabled?: boolean
  readonly name: string
  readonly condition: number
  readonly ipAddress: string
  readonly onChangeName?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onChangeCondition?: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly onChangeIpAddress?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onCancel: () => void
  readonly onApply?: () => void
}

export function IpAddressSetting(props: Props) {
  const {
    created = false,
    opened,
    disabled,
    name,
    condition,
    ipAddress,
    onChangeName,
    onChangeCondition,
    onChangeIpAddress,
    onCancel,
    onApply,
  } = props
  return (
    <Modal isOpen={opened} onClose={onCancel} title={created ? 'IPアドレスの追加' : 'IPアドレスの編集'}>
      <ModalContentWrapper>
        <Box>
          <DesignedInput
            style={{ marginRight: '1rem', width: '200px' }}
            placeholder="フィルタ名"
            type="text"
            value={name}
            required
            data-testid="input-name"
            onChange={onChangeName}
          />
          <DesignedSelect defaultValue={condition} options={ConditionOptions} onChange={onChangeCondition} />
          <DesignedInput
            placeholder="IPアドレス"
            type="text"
            value={ipAddress}
            required
            data-testid="input-ip-address"
            onChange={onChangeIpAddress}
          />
        </Box>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <NormalButton disabled={disabled} onClick={onApply}>
          {created ? '追加' : '変更'}
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const Box = styled.div`
  display: flex;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 40px;
`

const DesignedSelect = styled(Select)`
  width: 150px;
  height: 40px;
  margin-right: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
`
