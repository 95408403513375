import { saveAs } from 'file-saver'

interface args {
  downloadUrl: string
  defaultFilename?: string
}

/**
 * ダウンロードURLからファイルをダウンロードする
 * @param {args} params - ダウンロードのパラメータ
 * @param {string} params.downloadUrl - ダウンロードURL
 * @param {string} params.defaultFilename - デフォルトのダウンロードファイル名
 */
export const downloadBlobFile = async ({ downloadUrl, defaultFilename = 'file.csv' }: args): Promise<void> => {
  const response = await fetch(downloadUrl)
  if (!response.ok) throw new Error()

  const blob: Blob = await response.blob()
  const filename: string = (() => {
    // NOTE: サーバー側でヘッダー出力時、明示的に Content-Disposition をセーフリストに登録してもらう必要があった
    const disposition = response.headers.get('Content-Disposition') ?? ''
    const matches = disposition.match(/filename="(.+)"/)
    if (!disposition || !matches) return defaultFilename
    return matches[1]
  })()
  await saveAs(blob, filename)
}

/**
 * ファイルをダウンロードします
 * @param downloadUrl  - ダウンロードするファイルのURL
 */
// TODO: downloadBlobFile を使った新しい形式にリファクタリングする予定
export const downloadFile = (downloadUrl: string) => {
  const link = document.createElement('a')
  link.href = downloadUrl
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
