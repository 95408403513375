import { AGGREGATION_DATE_TYPES } from '../api/PageReport/TimelineReport/constants'
import { AggregationDateType } from '../api/PageReport/TimelineReport/types'
import { PAGE_REPORT_TIMELINE_AGGREGATION_DATE_TYPE_COOKIE_KEY } from './constants'
import { useCookieState } from './useCookieState'

/**
 * タイムラインの集計日付種別を管理するカスタムフック
 *
 * @returns {object} - タイムラインの集計日付種別と、それを更新する関数を返す
 */
export const useTimelineAggregationDateType = () => {
  const [timelineAggregationDateType, setTimelineAggregationDateType] = useCookieState<AggregationDateType>({
    cookieKey: PAGE_REPORT_TIMELINE_AGGREGATION_DATE_TYPE_COOKIE_KEY,
    initialValue: AGGREGATION_DATE_TYPES.DAY,
  })
  return { timelineAggregationDateType, setTimelineAggregationDateType }
}
