import * as React from 'react'
import { request } from '../../util/request'
import { State } from './state'

// 処理ステップ
export const InactiveStep = {
  none: 0,
  confirm: 1, // 確認
  complete: 2, // 完了
}

export class Inactive {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // 除外ユーザーの復帰
  onReturn = (id: number) => {
    const user = this.state.inactive.users.find((user) => user.id === id)
    if (user) {
      this.setState({
        ...this.state,
        selectedUserId: user.id,
        selectedUserName: user.userName,
        selectedUserRole: user.role,
        inactive: {
          ...this.state.inactive,
          step: InactiveStep.confirm,
        },
        inactiveErrorMessage: '',
      })
    }
  }

  onCancel = () => {
    this.setState({
      ...this.state,
      selectedUserId: 0,
      selectedUserName: '',
      selectedUserRole: 0,
      inactive: {
        ...this.state.inactive,
        step: InactiveStep.none,
      },
    })
  }

  // 復帰実行
  onApply = async () => {
    try {
      await request(
        'PATCH',
        `/api/users/${this.state.selectedUserId}/`,
        true,
        JSON.stringify({
          role: this.state.selectedUserRole,
          activate: true,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        selectedUserId: 0,
        selectedUserName: '',
        selectedUserRole: 0,
        inactive: {
          ...this.state.inactive,
          step: InactiveStep.none,
        },
        toastMessage: 'ユーザーを有効化しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        selectedUserId: 0,
        selectedUserName: '',
        selectedUserRole: 0,
        inactive: {
          ...this.state.inactive,
          step: InactiveStep.none,
        },
        inactiveErrorMessage: typeof e === 'string' ? e : 'ユーザーの復帰に失敗しました。',
      })
    }
  }
}
