import React, { useState } from 'react'
import { useUploadDataImportFieldSuggestion } from '../../../../../util/hooks/api/DataImport/useUploadDataImportFieldSuggestion'
import { CsvSuggestionFormModal } from './CsvSuggestionFormModal'
import { CheckOverwriteModal } from './CheckOverwriteModal'
import {
  DataImportFieldSuggestionData,
  EditImportField,
  ImportData,
  ImportField,
  ImportFieldSuggestion,
} from '../../../../../util/hooks/api/DataImport/types'
import { useToast } from '../../../../../util/hooks/useToast'

function makeEditImportField(suggestedField: ImportFieldSuggestion): EditImportField {
  return {
    primaryKey: false,
    fieldName: suggestedField.fieldName,
    dataType: suggestedField.dataType,
    enabled: true,
    editable: true,
  }
}

interface Props {
  projectId: string
  importFields: ImportField[] | undefined
  editImportFields: EditImportField[]
  importData: ImportData | null
  setEditImportFields: (arg: EditImportField[]) => void
  setCsvSuggestionId: (arg: number | null) => void
  isCsvSuggestionModalOpened: boolean
  openCsvSuggestionModal: () => void
  closeCsvSuggestionModal: () => void
}

export function CsvSuggestionModal({
  projectId,
  importFields,
  editImportFields,
  importData,
  setEditImportFields,
  setCsvSuggestionId,
  isCsvSuggestionModalOpened,
  openCsvSuggestionModal,
  closeCsvSuggestionModal,
}: Props) {
  const [csv, setCsv] = useState<Blob | null>(null)
  const [isCheckOverwriteModalOpened, setIsCheckOverwriteModalOpened] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { openToast } = useToast()

  const openCheckOverwriteModal = () => setIsCheckOverwriteModalOpened(true)
  const closeCheckOverwriteModal = () => setIsCheckOverwriteModalOpened(false)

  const {
    mutate: dataImportFieldSuggestionMutate,
    isLoading: isLoadingDataImportFieldSuggestion,
    isError: isDataImportFieldSuggestionMutateError,
  } = useUploadDataImportFieldSuggestion({
    projectId: projectId,
    options: {
      onSuccess: (response: DataImportFieldSuggestionData) => {
        closeModalAndCsvDiscard()
        onSuccesUploadedCsvSuggestion(response)
      },
      onError: (e: string) => {
        setErrorMessage(typeof e === 'string' ? e : 'CSVのアップロードに失敗しました。')
        closeModalAndCsvDiscard()
        openCsvSuggestionModal()
      },
    },
  })

  const onSuccesUploadedCsvSuggestion = (response: DataImportFieldSuggestionData) => {
    setCsvSuggestionId(response.suggestionId)
    if (importData === null) {
      // インポート済みデータがない場合はサジェストデータに置き換える
      const suggestFields = response.importFields.map((suggestionField) => {
        return makeEditImportField(suggestionField)
      })
      setEditImportFields(suggestFields)
    } else {
      // インポート済みフィールドは変更しない
      const importedFields = editImportFields.filter((field) => !field.editable)
      const importedFieldNames = importedFields.map((field) => field.fieldName)
      const suggestedFields = response.importFields
        .filter((field) => !importedFieldNames.includes(field.fieldName))
        .map((suggestedField) => {
          return makeEditImportField(suggestedField)
        })
      setEditImportFields(importedFields.concat(suggestedFields))
    }
    openToast({ message: 'ファイルからフィールドを読み取りました。' })
  }

  const onClose = () => {
    closeCsvSuggestionModal()
    closeCheckOverwriteModal()
  }

  const closeModalAndCsvDiscard = () => {
    onClose()
    setCsv(null)
  }

  const onCheckOverwrite = () => {
    // インポート済みデータがある場合は置き換えではないので上書き注意喚起のModalは表示しない
    if (importFields && importData === null) {
      onClose()
      openCheckOverwriteModal()
    } else {
      onUploadCsv()
    }
  }

  const onUploadCsv = () => {
    if (!csv) {
      onClose()
      return
    }

    const formData: FormData = new FormData()
    formData.append('file', csv)
    dataImportFieldSuggestionMutate({ formData: formData })
  }

  const onChangeCsvFile = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
    const files = event.currentTarget.files
    if (!files || files?.length === 0) return
    const file: File = files[0]
    setCsv(file)
  }

  return (
    <>
      <CsvSuggestionFormModal
        opened={isCsvSuggestionModalOpened}
        onClose={closeModalAndCsvDiscard}
        onCheckOverwrite={onCheckOverwrite}
        existCsv={!!csv}
        onChangeCsvFile={onChangeCsvFile}
        isDataImportFieldSuggestionMutateError={isDataImportFieldSuggestionMutateError}
        errorMessage={errorMessage}
        isLoadingDataImportFieldSuggestion={isLoadingDataImportFieldSuggestion}
      />
      <CheckOverwriteModal
        opened={isCheckOverwriteModalOpened}
        onClose={closeModalAndCsvDiscard}
        onUploadCsv={onUploadCsv}
        isLoadingDataImportFieldSuggestion={isLoadingDataImportFieldSuggestion}
      />
    </>
  )
}
