import React from 'react'

import { WarningBox } from '../WarningBox'

/**
 * ユーザーに追加権限がないことを示すメッセージを表示する
 */
export const CanNotAddMessage = () => {
  return <WarningBox>追加権限がありません。</WarningBox>
}
