import { useLayoutEffect, useState } from 'react'

export const useResize = () => {
  const [windowInnerWidth, setWindowInnerWidth] = useState(0)
  useLayoutEffect(() => {
    const updateSize = (): void => {
      setWindowInnerWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateSize)
    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return { windowInnerWidth }
}
