import * as React from 'react'

import { Content, SortItemKey, SortState } from '../../../util/hooks/api/useContentReport'
import { ContentPreviewHeaderCell } from '../cells/ContentPreviewHeaderCell'
import { ContentNameHeaderCell } from '../cells/ContentNameHeaderCell'
import { ContentCapturedAtHeaderCell } from '../cells/ContentCapturedAtHeaderCell'
import { ViewCountHeaderCell } from '../cells/ViewCountHeaderCell'
import { ViewRateHeaderCell } from '../cells/ViewRateHeaderCell'
import { ViewSecondHeaderCell } from '../cells/ViewSecondHeaderCell'
import { GoalCountHeaderCell } from '../cells/GoalCountHeaderCell'
import { GoalRateHeaderCell } from '../cells/GoalRateHeaderCell'
import { ClickCountHeaderCell } from '../cells/ClickCountHeaderCell'
import { ClickRateHeaderCell } from '../cells/ClickRateHeaderCell'
import { BaseRow } from './BaseRow'

interface Props {
  readonly items: Content[]
  readonly selectedIds: number[]
  readonly handleHeaderCheckboxChange: () => void
  readonly sortState: SortState
  readonly onSortClick: (key: SortItemKey) => void
}

export function HeaderRow({ items, selectedIds, handleHeaderCheckboxChange, sortState, onSortClick }: Props) {
  return (
    <BaseRow>
      <ContentPreviewHeaderCell items={items} selectedIds={selectedIds} onClick={handleHeaderCheckboxChange} />
      <ContentNameHeaderCell />
      <ContentCapturedAtHeaderCell />
      <ViewCountHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <ViewRateHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <ViewSecondHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <GoalCountHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <GoalRateHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <ClickCountHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <ClickRateHeaderCell sortState={sortState} onSortClick={onSortClick} />
    </BaseRow>
  )
}
