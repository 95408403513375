import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../request'
export const useApi = <TQueryKey extends [string], TQueryFnData, TError = unknown, TData = TQueryFnData>(
  queryKey: TQueryKey,
  path: string,
  auth: boolean,
  options?: Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery({
    queryKey,
    queryFn: async () => await request<TQueryFnData>('GET', `/api${path}`, auth),
    ...options,
  })
}

export const useGenericMutation = <TVariables, TError = unknown, TData = unknown, TContext = unknown>(
  method: 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  path: string,
  auth: boolean,
  options?: UseMutationOptions<TData | void, TError, TVariables, TContext>,
) => {
  return useMutation({
    mutationFn: async (body?: TVariables) => await request<TData>(method, `/api${path}`, auth, JSON.stringify(body)),
    ...options,
  })
}
