import * as React from 'react'
import { State } from './state'

export class NarrowDown {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // モーダル開く
  onOpen = () => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        disabled: true,
        tempStatus: this.state.search.status.slice(), // 初期状態を保持しておく
        tempText: this.state.search.text,
        openNarrowDown: true,
      },
    })
  }

  // モーダル閉じる
  onClose = () => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        tempStatus: [],
        tempText: '',
        openNarrowDown: false,
      },
    })
  }

  // キャンセル
  onCancel = (status: boolean[], text: string) => {
    // 保存しておいた条件に戻す
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        status: status,
        tempStatus: [],
        text: text,
        tempText: '',
        openNarrowDown: false,
      },
    })
  }

  // 検索可能かチェック
  disabledCheck = (status: boolean[], text: string) => {
    // 配列の数と一致する数が等しい時変更されていない
    const listCheck =
      this.state.search.tempStatus.filter((state, index) => state === status[index]).length ===
      this.state.search.tempStatus.length
    const textCheck = this.state.search.tempText === text
    return listCheck && textCheck
  }

  // ステータスチェック
  onClick = (index: number) => {
    this.state.search.status[index] = !this.state.search.status[index]
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        disabled: this.disabledCheck(this.state.search.status, this.state.search.text),
      },
    })
  }

  // TODO:検索オプションの一時対応
  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        text: event.currentTarget.value,
        disabled: this.disabledCheck(this.state.search.status, event.currentTarget.value),
      },
    })
  }

  // 検索条件リセット
  onReset = () => {
    this.setState({
      ...this.state,
      reload: true,
      search: {
        ...this.state.search,
        status: [false, false, false, false],
        text: '',
        openNarrowDown: false,
      },
    })
  }

  // 検索実行
  onApply = () => {
    // 検索オプションで条件がしていされていたら優先
    this.setState({
      ...this.state,
      reload: true,
      search: {
        ...this.state.search,
        openNarrowDown: false,
      },
    })
  }
}
