import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { CancelButton, NormalButton } from '../common/Button'
import { Checkbox } from '../common/Checkbox'
import { Input } from '../common/Input'

export const narrowDownLayout = {
  width: '780px',
  innerWidth: '700px', // width - ( sideMargin * 2 )
  innerHeight: '200px',
  headerHeight: '60px',
  mainHeight: '340px',
  height: '400px',
  closeIcon: '30',
  closeX: '370px', // ( width - iconSize ) * 0.5
  closeY: '215px',
  sideMargin: '40px',
  itemHeight: '40px',
}

export const SearchOptions = {
  status: {
    wait: 1, // 実行待ち
    success: 3, // 成功
    error: 4, // エラー
    skip: 7, // スキップ
  },
}

const StatusLabel = ['実行待ち', '成功', 'エラー', 'スキップ']

export interface NarrowDownState {
  status: boolean[]
  searchValue: string
}

interface Props {
  readonly opened: boolean
  readonly disabled?: boolean
  readonly status: boolean[]
  readonly text: string // TODO:検索オプション一時対応用
  readonly items?: NarrowDownState[]
  readonly onClose: () => void
  readonly onCancel?: () => void
  readonly onApply?: () => void
  readonly onAdd?: () => void
  readonly onDelete?: (index: number) => void
  readonly onReset?: () => void
  readonly onClick?: (index: number) => void // チェックボックス選択
  readonly onChange?: (event: React.FormEvent<HTMLInputElement>) => void // TODO:検索オプション一時対応用
}

// キャプチャ履歴の検索オプション
export function CaptureHistoryNarrowDown(props: Props) {
  const { opened, disabled, status, text, onClose, onCancel, onApply, onReset, onClick, onChange } = props

  return (
    <Modal isOpen={opened} onClose={onClose} title="検索オプション">
      <ModalContentWrapper>
        <OptionBox>
          <CancelButton onClick={onReset}>リセット</CancelButton>
        </OptionBox>

        <Box>
          {/* ステータス */}
          <NarrowDownBox>
            <span style={{ marginBottom: '0.5rem' }}>ステータス</span>
            <CheckBoxes>
              {status.map((state, index) => {
                return (
                  <span key={`status-${index}`} onClick={() => onClick!(index)} style={{ cursor: 'pointer' }}>
                    <DesignedCheckBox checked={state} />
                    <span>{StatusLabel[index]}</span>
                  </span>
                )
              })}
            </CheckBoxes>
          </NarrowDownBox>

          {/* 一時的に文字列検索できるようにしておく */}
          <NarrowDownBox style={{ marginTop: '2rem' }}>
            <span style={{ marginBottom: '0.5rem' }}>ページ</span>
            <Input type="text" value={text} style={{ width: '100%', height: '30px' }} onChange={onChange} />
          </NarrowDownBox>

          {/* TODO:ページレポートの用に条件を追加予定 */}
        </Box>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <NormalButton disabled={disabled} onClick={onApply}>
          検索
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const OptionBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
`

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
`

const NarrowDownBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.gray300};
  padding: 1rem;
  background-color: ${colors.bg};
`

const CheckBoxes = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  min-width: 0;
  gap: 1rem 2.5rem;
`

const DesignedCheckBox = styled(Checkbox)`
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  background-color: ${colors.white};
`
