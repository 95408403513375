import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { GoalType, GoalTypeMap } from '../../../util/Goal'
import { AddButton } from '../../common/Button'
import { PopupMenu } from '../../common/PopupMenu'
import { Settings } from '@styled-icons/material/Settings'
import { ConditionCell } from './ConditionCell'

export interface GoalState {
  readonly id: number
  readonly name: string
  readonly type: number // 1:url 2:event
  readonly url?: {
    readonly condition: number
    readonly url: string
  }
  readonly event?: {
    readonly category: string | null
    readonly action: string | null
    readonly label: string | null
    readonly value: number | null
    readonly category_condition: number | null
    readonly action_condition: number | null
    readonly label_condition: number | null
    readonly value_condition: number | null
  }
}

interface Props {
  readonly goals: GoalState[]
  readonly selectedGoalId: number
  readonly canAddGoal?: boolean
  readonly canChangeGoal?: boolean
  readonly canDeleteGoal?: boolean
  readonly onCreate?: () => void
  readonly onUrlEdit?: (id: number) => void
  readonly onEventEdit?: (id: number) => void
  readonly onDelete?: (id: number) => void
}

export function GoalList(props: Props) {
  const {
    goals,
    canAddGoal = false,
    canChangeGoal = false,
    canDeleteGoal = false,
    onCreate,
    onUrlEdit,
    onEventEdit,
    onDelete,
  } = props
  const [state, setState] = React.useState({ id: 0, opened: false })
  const onClick = (id: number) => {
    setState({ ...state, id: id, opened: true })
  }
  const popupClose = () => {
    setState({ ...state, id: 0, opened: false })
  }
  return (
    <>
      {canAddGoal && (
        <AddGoalButton>
          <AddButton onClick={onCreate}>ゴールを追加</AddButton>
        </AddGoalButton>
      )}
      {goals.length === 0 ? (
        <NoGoalContainer>ゴールが登録されていません</NoGoalContainer>
      ) : (
        <Container>
          <TitleRow>
            <GoalColumnTitle>目標名</GoalColumnTitle>
            <GoalTypeTitle>目標タイプ</GoalTypeTitle>
            <ConditionTitle>条件</ConditionTitle>
          </TitleRow>
          {goals.map((goal) => (
            <Item key={goal.id}>
              <GoalColumnCell>{goal.name}</GoalColumnCell>
              <GoalTypeCell>{GoalTypeMap[goal.type]}</GoalTypeCell>
              <ConditionCell goal={goal} />
              {(canChangeGoal || canDeleteGoal) && (
                <SettingBox>
                  <SettingIcon onClick={() => onClick(goal.id)} />
                  {state.opened && state.id === goal.id && (
                    <PopupMenu
                      onClose={popupClose}
                      style={{
                        right: '40px',
                        bottom: canChangeGoal && canDeleteGoal ? '-25px' : '-10px',
                        width: '80px',
                      }}
                    >
                      <Links>
                        {canChangeGoal && (
                          <DesignedLink
                            onClick={() => {
                              if (goal.type === GoalType.url) {
                                onUrlEdit && onUrlEdit(goal.id)
                              } else {
                                onEventEdit && onEventEdit(goal.id)
                              }
                            }}
                          >
                            編集
                          </DesignedLink>
                        )}
                        {canDeleteGoal && (
                          <DesignedLink onClick={() => onDelete && onDelete(goal.id)}>削除</DesignedLink>
                        )}
                      </Links>
                    </PopupMenu>
                  )}
                </SettingBox>
              )}
            </Item>
          ))}
        </Container>
      )}
    </>
  )
}

const AddGoalButton = styled.div`
  margin: 1rem 0;
`

const NoGoalContainer = styled.div`
  margin-bottom: 1rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  height: 100%;
  align-items: center;
  padding: 8px 0;
  line-height: 21px;
  border-bottom: 1px solid ${colors.gray300};
`

const TitleRow = styled(Item)`
  padding-bottom: 0, 5rem;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;
`

const GoalColumnCell = styled(Box)`
  width: 200px;
`

const GoalColumnTitle = styled(GoalColumnCell)`
  color: ${colors.gray500};
`

const GoalTypeCell = styled(Box)`
  width: 140px;
  margin-left: 1.5rem;
`

const GoalTypeTitle = styled(GoalTypeCell)`
  color: ${colors.gray500};
`

const ConditionTitle = styled(Box)`
  max-width: 500px;
  margin-left: 1.5rem;
  color: ${colors.gray500};
`

const SettingBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
`

const SettingIcon = styled(Settings).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  margin-left: auto;
  margin-right: 1rem;
  cursor: pointer;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const DesignedLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
    background-color: ${colors.gray200};
  }

  & + & {
    margin-top: 0.1rem;
  }
`
