import React, { FC } from 'react'
import { Link } from '@gatsbyjs/reach-router'
import { colors } from '../../styleConstants'

export interface BreadcrumbsItemType {
  text: string
  link: string
}

interface BreadcrumbsProps {
  items: BreadcrumbsItemType[]
  linkColor?: string
  separator?: string
  separatorColor?: string
  style?: React.CSSProperties
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ items, linkColor, separator = '>', separatorColor, style }) => {
  return (
    <div style={style}>
      {items.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Link to={item.link} style={{ color: linkColor || colors.white, textDecoration: 'none' }}>
              {item.text}
            </Link>
            <span style={{ color: separatorColor ? separatorColor : colors.white, margin: '0 0.5rem' }}>
              {separator}
            </span>
          </React.Fragment>
        )
      })}
    </div>
  )
}
