import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { AddButton } from '../common/Button'
import { PopupMenu } from '../common/PopupMenu'
import { Settings } from '@styled-icons/material/Settings'

export interface QueryState {
  readonly id: number
  readonly name: string
}

interface Props {
  readonly queries: QueryState[]
  readonly canAddUrlParameter?: boolean
  readonly canChangeUrlParameter?: boolean
  readonly canDeleteUrlParameter?: boolean
  readonly onAdd?: () => void
  readonly onEdit?: (id: number) => void
  readonly onDelete?: (id: number) => void
}

export function QueryList(props: Props) {
  const { queries, canAddUrlParameter, canChangeUrlParameter, canDeleteUrlParameter, onAdd, onEdit, onDelete } = props
  const [state, setState] = React.useState({ id: 0, opened: false })
  const onClick = (id: number) => {
    setState({ ...state, id: id, opened: true })
  }
  const popupClose = () => {
    setState({ ...state, id: 0, opened: false })
  }
  return (
    <Container>
      {canAddUrlParameter && (
        <div style={{ margin: '1rem 0' }}>
          <AddButton onClick={onAdd}>クエリーパラメータを追加</AddButton>
        </div>
      )}
      {queries.length === 0 ? (
        <div style={{ margin: '1.5rem 0' }}>クエリーパラメータが登録されていません</div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Item style={{ paddingBottom: '0.5rem' }}>
            <Box style={{ color: `${colors.gray500}` }}>クエリーパラメータ名</Box>
          </Item>
          {queries.map((query) => {
            return (
              <Item key={query.id}>
                <Box>{query.name}</Box>
                {(canChangeUrlParameter || canDeleteUrlParameter) && (
                  <SettingBox>
                    <SettingIcon onClick={() => onClick(query.id)} />
                    {state.opened && state.id === query.id && (
                      <PopupMenu
                        onClose={popupClose}
                        style={{
                          right: '20px',
                          bottom: canChangeUrlParameter && canDeleteUrlParameter ? '-25px' : '-10px',
                          width: '80px',
                        }}
                      >
                        <Links>
                          {canChangeUrlParameter && (
                            <DesignedLink onClick={() => onEdit && onEdit(query.id)}>編集</DesignedLink>
                          )}
                          {canDeleteUrlParameter && (
                            <DesignedLink onClick={() => onDelete && onDelete(query.id)}>削除</DesignedLink>
                          )}
                        </Links>
                      </PopupMenu>
                    )}
                  </SettingBox>
                )}
              </Item>
            )
          })}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div``

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 30px;
  height: 100%;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid ${colors.gray300};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  width: 100%;
  height: 100%;
`

const SettingBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
`

const SettingIcon = styled(Settings).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const DesignedLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
    background-color: ${colors.gray200};
  }

  & + & {
    margin-top: 0.1rem;
  }
`
