import { RefObject } from 'react'

export const scrollToElement = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

/**
 * Scrolls the referenced HTMLDivElement into view.
 *
 * @param {RefObject<HTMLDivElement>} ref - The reference to the HTMLDivElement.
 */
export const scrollTop = (ref: RefObject<HTMLDivElement>) => {
  ref.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  })
}
