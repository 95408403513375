import * as React from 'react'
import { checkPasswordPattern } from '../../util/Password'
import { request } from '../../util/request'
import { LoginData } from '../../util/Response'
import { navigate } from '@gatsbyjs/reach-router'
import { OptionProps } from '../../components/common/Select'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly email: string
  readonly password: string
  readonly isEmailValid: boolean
  readonly isPasswordValid: boolean
  readonly disabled: boolean
  readonly errorMessage?: string
  readonly isTeamSelect: boolean
  readonly teamId: number
  readonly teamOptions: OptionProps[]
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  updateEmail = (event: React.FormEvent<HTMLInputElement>) => {
    // メールとパスワードの入力に問題がなければログインボタンが押せる
    // ログインボタンは常に押せるよう変更
    this.setState({
      ...this.state,
      email: event.currentTarget.value,
      isEmailValid: event.currentTarget.validity.valid,
      disabled: false, //!(event.currentTarget.validity.valid && this.state.isPasswordValid),
    })
  }

  updatePassword = (event: React.FormEvent<HTMLInputElement>) => {
    // 文字列に英数記号が2種類以上含まれるか確認する
    if (!checkPasswordPattern(event.currentTarget.value)) {
      event.currentTarget.setCustomValidity(
        'アルファベット、数字および記号のうち少なくとも２種類以上の組み合わせである必要があります',
      )
    } else {
      event.currentTarget.setCustomValidity('')
    }

    this.setState({
      ...this.state,
      password: event.currentTarget.value,
      isPasswordValid: event.currentTarget.validity.valid,
      disabled: false, //!(event.currentTarget.validity.valid && this.state.isEmailValid),
    })
  }

  onTeamChange = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({ ...this.state, teamId: Number(event.currentTarget.value) })
  }

  submit = async (event: React.FormEvent<HTMLFormElement>, search: string | undefined) => {
    event.preventDefault()
    try {
      const json: LoginData = await request(
        'POST',
        '/api/login/',
        false,
        this.state.isTeamSelect
          ? JSON.stringify({ email: this.state.email, password: this.state.password, team_id: this.state.teamId })
          : JSON.stringify({ email: this.state.email, password: this.state.password }),
      )
      // ログイン成功
      if (json.code === 'success') {
        // システムユーザーは通常ログインはできないので
        // ログイン成功したらsyslogTokenは削除する
        window.localStorage.removeItem('syslogToken')
        // 指定がなければダッシュボードからリダイレクトされる
        const path = search?.replace('?next=/', '')
        if (path) {
          navigate(`/${path}`, { state: { redirect: true } })
        } else {
          navigate('/')
        }
      }
      // 組織選択
      else if (json.code === 'multiple_user') {
        this.setState({
          ...this.state,
          isTeamSelect: true,
          teamId: json.teams[0].id,
          teamOptions: json.teams.map((team) => {
            return { label: team.name, value: team.id }
          }),
          errorMessage: 'ログインする組織を選択してください。',
        })
      }
    } catch (e) {
      this.setState({
        ...this.state,
        errorMessage: typeof e === 'string' ? e : 'メールアドレスかパスワードが正しくありません。',
      })
    }
  }
}

const initialState: State = {
  email: '',
  password: '',
  isEmailValid: false,
  isPasswordValid: false,
  disabled: false,
  errorMessage: '',
  isTeamSelect: false,
  teamId: 0,
  teamOptions: [],
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
