import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { ErrorBox } from '../../common/ErrorBox'
import { ReportContext } from '../../../contexts/ReportProvider'

/**
 * 無効なURLパラメータのエラーメッセージを表示するコンポーネント。
 * URLパラメータを監視し、変更があれば自動的に再チェックするようにしている。
 *
 * @returns {ReactElement | null} - The ReactElement representing the error messages or null if there are no errors.
 */
export const UrlParamsErrorMessages = (): ReactElement | null => {
  const {
    actions: { validateUrlParams },
    state: { paramsErrorMessages, isParamsError },
  } = React.useContext(ReportContext)

  React.useEffect(() => {
    validateUrlParams(new URLSearchParams(window.location.search.slice()))
  }, [window.location.search])

  if (!isParamsError) return null

  return (
    <ErrorBox>
      <CautionLabel>URLパラメータに不正な値が指定されています。</CautionLabel>
      {paramsErrorMessages.map((list, index) => (
        <CautionMessage key={`param-error-${index}`}>{list}</CautionMessage>
      ))}
    </ErrorBox>
  )
}

const CautionLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
`

const CautionMessage = styled.div`
  font-size: 12px;
  line-height: 20px;
`
