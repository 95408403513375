import React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { WarningIcon } from '../../icons/WarningIcon'

interface Props {
  readonly children: React.ReactNode
}

export const WarningBox = ({ children }: Props) => {
  return (
    <Container>
      <IconArea>
        <WarningIcon size={26} color={colors.white} />
      </IconArea>
      <MessageArea>{children}</MessageArea>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border: solid 2px ${colors.orange};
  background-color: ${colors.orange};
`

const IconArea = styled.div`
  padding: 0 12px;
  background-color: ${colors.orange};
`

const MessageArea = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${colors.white};
`
