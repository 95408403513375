import * as React from 'react'
import { Link } from '../../common/Link'
import { Textarea } from '../../common/Textarea'
import { WarningBox } from '../../common/WarningBox'
import { AccordionBox } from '../../common/AccordionBox'
import { ContentCopy } from '@styled-icons/material-rounded/ContentCopy'
import { HELP_LINKS } from '../../../constants'
import styled from 'styled-components'
import { TrackingCodePvNotice } from '../../messages/TrackingCodePvNotice'

interface TrackingCodeProps {
  trackingCode: string
}
export function TrackingCodePanel({ trackingCode }: TrackingCodeProps) {
  return (
    <AccordionBox title="トラッキングコード" icon={<ContentCopy />} accordionProps={{ defaultExpanded: true }}>
      <div style={{ marginBottom: '16px' }}>
        発行した下記トラッキングコードを計測したいウェブページの&lt;head&gt;内に貼り付けてください。
      </div>
      <WarningBoxWrapper>
        <WarningBox>
          <div>
            このトラッキングコードを設置後、クローラーがページにアクセスを行うことでGoogle&nbsp;Analyticsなどの計測ツール上で訪問者数が増加する可能性があります。
          </div>
          <div>
            <span>{`このようなページへの影響と対応方法の詳細は`}</span>
            <Link
              href={HELP_LINKS.GA4_EXCLUDE_CA_TRAFFIC_HOW_TO}
              target="_blank"
              rel="noopener noreferrer"
              style={{ margin: '0 0.2rem' }}
            >
              こちら
            </Link>
            <span>{`をご覧ください。`}</span>
          </div>
        </WarningBox>

        <WarningBox>
          <TrackingCodePvNotice />
        </WarningBox>
      </WarningBoxWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div></div>
      </div>
      <Textarea value={trackingCode} rows={10} cols={80} readOnly copyButton noneResize></Textarea>
    </AccordionBox>
  )
}

const WarningBoxWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 10px;
  margin-bottom: 24px;
`
