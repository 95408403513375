import React from 'react'
import { NormalButton, CancelButton } from '../../../../common/Button'
import { Modal, ModalButtonWrapper, ModalHeadText, ModalContentWrapper } from '../../../../common/Modal'
interface Props {
  opened: boolean
  onClose: () => void
  onUploadCsv: () => void
  isLoadingDataImportFieldSuggestion: boolean
}

export function CheckOverwriteModal({ opened, onClose, onUploadCsv, isLoadingDataImportFieldSuggestion }: Props) {
  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      title="インポートフィールドを読み取り"
      isLoading={isLoadingDataImportFieldSuggestion}
    >
      <ModalContentWrapper>
        <ModalHeadText>
          設定済みのフィールドは、アップロードしたCSVファイルで上書きされます。 実行してよろしいですか？
        </ModalHeadText>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton onClick={onUploadCsv}>上書きする</NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}
