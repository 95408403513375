import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../styleConstants'
import { Select } from '../../../common/Select'
import { forceInt } from '../../../../util/Parse'
import {
  SCHEDULE_INTERVALS,
  MIN_PORT_NUMBER,
  MAX_PORT_NUMBER,
  WEEKDAY,
} from '../../../../util/hooks/api/DataImport/constants'
import { ImportScheduleFormData } from '../../../../util/hooks/api/DataImport/types'
import { PanelTitle } from '../'

type Props = {
  scheduleData: ImportScheduleFormData
  setScheduleData: (arg: ImportScheduleFormData) => void
}

const INTERVAL_OPTIONS = [
  { label: '毎日', value: SCHEDULE_INTERVALS.DAILY },
  { label: '毎週', value: SCHEDULE_INTERVALS.WEEKLY },
  { label: '毎月', value: SCHEDULE_INTERVALS.MONTHLY },
] as const

const MONTHLY_OPTIONS = [
  { label: '1日', value: 1 },
  { label: '2日', value: 2 },
  { label: '3日', value: 3 },
  { label: '4日', value: 4 },
  { label: '5日', value: 5 },
  { label: '6日', value: 6 },
  { label: '7日', value: 7 },
  { label: '8日', value: 8 },
  { label: '9日', value: 9 },
  { label: '10日', value: 10 },
  { label: '11日', value: 11 },
  { label: '12日', value: 12 },
  { label: '13日', value: 13 },
  { label: '14日', value: 14 },
  { label: '15日', value: 15 },
  { label: '16日', value: 16 },
  { label: '17日', value: 17 },
  { label: '18日', value: 18 },
  { label: '19日', value: 19 },
  { label: '20日', value: 20 },
  { label: '21日', value: 21 },
  { label: '22日', value: 22 },
  { label: '23日', value: 23 },
  { label: '24日', value: 24 },
  { label: '25日', value: 25 },
  { label: '26日', value: 26 },
  { label: '27日', value: 27 },
  { label: '28日', value: 28 },
  { label: '月末日', value: -1 },
] as const

const WEEKLY_OPTIONS = [
  { label: '月曜', value: WEEKDAY.MONDAY },
  { label: '火曜', value: WEEKDAY.TUESDAY },
  { label: '水曜', value: WEEKDAY.WEDNESDAY },
  { label: '木曜', value: WEEKDAY.THURSDAY },
  { label: '金曜', value: WEEKDAY.FRIDAY },
  { label: '土曜', value: WEEKDAY.SATURDAY },
  { label: '日曜', value: WEEKDAY.SUNDAY },
] as const

const TIME_OPTIONS = [
  { label: '00:00 - 01:00', value: '00:00:00' },
  { label: '01:00 - 02:00', value: '01:00:00' },
  { label: '02:00 - 03:00', value: '02:00:00' },
  { label: '03:00 - 04:00', value: '03:00:00' },
  { label: '04:00 - 05:00', value: '04:00:00' },
  { label: '05:00 - 06:00', value: '05:00:00' },
  { label: '06:00 - 07:00', value: '06:00:00' },
  { label: '07:00 - 08:00', value: '07:00:00' },
  { label: '08:00 - 09:00', value: '08:00:00' },
  { label: '09:00 - 10:00', value: '09:00:00' },
  { label: '10:00 - 11:00', value: '10:00:00' },
  { label: '11:00 - 12:00', value: '11:00:00' },
  { label: '12:00 - 13:00', value: '12:00:00' },
  { label: '13:00 - 14:00', value: '13:00:00' },
  { label: '14:00 - 15:00', value: '14:00:00' },
  { label: '15:00 - 16:00', value: '15:00:00' },
  { label: '16:00 - 17:00', value: '16:00:00' },
  { label: '17:00 - 18:00', value: '17:00:00' },
  { label: '18:00 - 19:00', value: '18:00:00' },
  { label: '19:00 - 20:00', value: '19:00:00' },
  { label: '20:00 - 21:00', value: '20:00:00' },
  { label: '21:00 - 22:00', value: '21:00:00' },
  { label: '22:00 - 23:00', value: '22:00:00' },
  { label: '23:00 - 24:00', value: '23:00:00' },
] as const

export function ScheduleForm({ scheduleData, setScheduleData }: Props) {
  const handleStringInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const newScheduleData = {
      ...scheduleData,
      [e.target.name]: e.target.value,
    }
    setScheduleData(newScheduleData)
  }

  const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const newScheduleData = {
      ...scheduleData,
      [e.target.name]: forceInt(e.target.value),
    }
    setScheduleData(newScheduleData)
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newScheduleData = {
      ...scheduleData,
      [e.target.name]: e.target.checked,
    }
    setScheduleData(newScheduleData)
  }

  return (
    <>
      <PanelTitle>スケジュールされたインポート(SFTP設定)</PanelTitle>
      <InputUnit>
        <label>
          <EnableInput type="checkbox" name="enable" checked={scheduleData.enable} onChange={handleCheckboxChange} />
          <EnableTitle>有効化</EnableTitle>
        </label>
      </InputUnit>

      <InputUnit>
        <label>
          <InputTitle>ログインユーザー名</InputTitle>
          <DesignedInputLong
            type="text"
            name="sftpUser"
            value={scheduleData.sftpUser}
            onChange={handleStringInputChange}
            disabled={!scheduleData.enable}
            required
          />
        </label>
      </InputUnit>

      <InputUnit>
        <label>
          <InputTitle>ホスト名</InputTitle>
          <DesignedInputLong
            type="text"
            name="sftpHost"
            value={scheduleData.sftpHost}
            onChange={handleStringInputChange}
            disabled={!scheduleData.enable}
            required
          />
        </label>
      </InputUnit>

      <InputUnit>
        <label>
          <InputTitle>ポート番号</InputTitle>
          <DesignedInputShort
            type="number"
            name="sftpPort"
            value={scheduleData.sftpPort}
            onChange={handleNumberInputChange}
            disabled={!scheduleData.enable}
            required
            pattern="\d*"
            min={MIN_PORT_NUMBER}
            max={MAX_PORT_NUMBER}
          />
        </label>
      </InputUnit>

      <InputUnit>
        <InputTitle>開始スケジュール</InputTitle>
        <SelectUnit>
          <DesignedSelectShort
            name="interval"
            value={scheduleData.interval}
            options={[...INTERVAL_OPTIONS]}
            onChange={handleNumberInputChange}
            disabled={!scheduleData.enable}
            required
          />
          {/* memo: day: interval が 3: "MONTHLY" のとき必須。1-28: 1日-28日。-1: 月末日 */}
          {scheduleData.interval === SCHEDULE_INTERVALS.MONTHLY && (
            <DesignedSelectShort
              name="day"
              value={scheduleData.day}
              options={[...MONTHLY_OPTIONS]}
              onChange={handleNumberInputChange}
              disabled={!scheduleData.enable}
            />
          )}
          {/* memo: weekday: interval が 2: "WEEKLY" のとき必須。0-7 の範囲 (0:日曜, 1:月曜, ..., 6:土曜, 7:日曜) */}
          {scheduleData.interval === SCHEDULE_INTERVALS.WEEKLY && (
            <DesignedSelectShort
              name="weekday"
              value={scheduleData.weekday}
              options={[...WEEKLY_OPTIONS]}
              onChange={handleNumberInputChange}
              disabled={!scheduleData.enable}
            />
          )}
        </SelectUnit>

        <SelectUnit>
          <DesignedSelectLong
            name="time"
            value={scheduleData.time}
            options={[...TIME_OPTIONS]}
            onChange={handleStringInputChange}
            disabled={!scheduleData.enable}
            required
          />
        </SelectUnit>
      </InputUnit>
    </>
  )
}

const InputUnit = styled.div`
  margin: 0 0 25px;
`

const EnableInput = styled.input`
  display: inline-block;
  position: relative;
  top: 1px;
  appearance: none;
  margin: 0 3px 0 0;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 2px solid ${colors.gray250};
  outline: none;
  vertical-align: baseline;
  &:checked {
  }
  &::before {
    display: block;
    opacity: 0;
    content: '';
    position: absolute;
    top: 0px;
    left: 4px;
    width: 5px;
    height: 10px;
    border-right: 2px solid ${colors.contentBlue.blue5};
    border-bottom: 2px solid ${colors.contentBlue.blue5};
    transform: rotate(45deg);
  }
  &:checked::before {
    opacity: 1;
  }
`

const EnableTitle = styled.span`
  font-size: 16px;
`

const InputTitle = styled.div`
  margin: 0 0 8px;
  font-weight: bold;
`

const DesignedInput = styled.input`
  padding: 10px;
  width: 100%;
  font-size: 16px;
  outline: 0;
  border: 1px solid ${colors.gray250};
  border-radius: 2px;
  &:active,
  &:focus {
    border: 1px solid ${colors.lightBlue};
  }
  &:invalid {
    border: 1px solid ${colors.error};
  }
  &::placeholder {
    color: ${colors.gray250};
  }
  &:disabled {
    background: ${colors.gray100};
  }
`

const DesignedInputLong = styled(DesignedInput)`
  width: 600px;
`

const DesignedInputShort = styled(DesignedInput)`
  width: 150px;
`

const SelectUnit = styled.div`
  margin-bottom: 16px;
`

const DesignedSelect = styled(Select)`
  padding: 10px;
  font-size: 16px;
  border: 1px solid ${colors.gray250};
  border-radius: 2px;
  &:invalid {
    border: 1px solid ${colors.error};
  }
  &:disabled {
    background: ${colors.gray100};
  }
`

const DesignedSelectLong = styled(DesignedSelect)`
  width: 312px;
`

const DesignedSelectShort = styled(DesignedSelect)`
  margin-right: 16px;
  width: 148px;
`
