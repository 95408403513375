import React from 'react'
import styled from 'styled-components'
import { Reload } from '@styled-icons/ionicons-outline/Reload'
import { colors } from '../../../../styleConstants'
import { SubActionIconButton } from '../../../common/Button'
import { TableRow, HeaderCell, TableCell, NoDataCell } from '..'
import { ResultCell } from './ResultCell'
import { SummaryCell } from './SummaryCell'
import { useDataImportLogs } from '../../../../util/hooks/api/DataImport/useDataImportLogs'
import { useDataImportCsv } from '../../../../util/hooks/api/DataImport/useDataImportCsv'
import { useLatestImportData } from '../../../../util/hooks/api/DataImport/useLatestImportData'
import { getDateYYYYMMDDhhmi } from '../../../../util/Date'
import { ErrorBox } from '../../../common/ErrorBox'
import { EmbeddedLoading } from '../../../common/EmbeddedLoading'
import { IMPORTED_HISTORY_AREA_ID } from '../..'

interface Props {
  projectId: string
  permissions: any
}

export function ImportHistory({ projectId, permissions }: Props) {
  const {
    data,
    isLoading,
    isError,
    isManualRefetching: isImportLogsRefetching,
    invalidate: invalidateImportLogs,
  } = useDataImportLogs({
    projectId,
  })
  const { isManualRefetching: isImportCsvRefetching, invalidate: invalidateImportCsv } = useDataImportCsv({
    projectId,
  })
  const { isManualRefetching: isLatestImportDataRefetching, invalidate: invalidateLatestImportData } =
    useLatestImportData({
      projectId,
    })

  const reload = () => {
    invalidateImportLogs()
    invalidateImportCsv()
    invalidateLatestImportData()
  }
  const isReloading = isImportLogsRefetching || isImportCsvRefetching || isLatestImportDataRefetching
  return (
    <>
      <Title id={IMPORTED_HISTORY_AREA_ID}>インポート履歴</Title>
      {isLoading && <EmbeddedLoading />}
      {isError && <ErrorBox>インポート履歴の取得に失敗しました。</ErrorBox>}
      {data && (
        <>
          <ContentText>
            インポート結果の履歴を表示しています。
            <br />
            実際にデータが保持されているのは直近のインポート1件のみです。
            <br />
            最新の取り込み状況を確認するには「結果をリロード」ボタンをクリックしてください。
          </ContentText>
          <ReloadButtonWrapper>
            <SubActionIconButton onClick={reload} iconLeft={<Reload size="16" />} disabled={isReloading}>
              {isReloading ? 'リロード中' : '結果をリロード'}
            </SubActionIconButton>
          </ReloadButtonWrapper>
          <ImportHistoryTable>
            <TableRow>
              <HeaderCell>実行結果</HeaderCell>
              <HeaderCell>実行ユーザー名</HeaderCell>
              <HeaderCell>実行日時</HeaderCell>
              <HeaderCell>サマリー</HeaderCell>
            </TableRow>
            {data.length ? (
              data.map((log) => (
                <TableRow key={log.id}>
                  <ResultCell status={log.status} />
                  <TableCell>{log.user.userName}</TableCell>
                  <TableCell>{getDateYYYYMMDDhhmi(log.createdAt)}</TableCell>
                  <SummaryCell log={log} projectId={projectId} permissions={permissions} />
                </TableRow>
              ))
            ) : (
              <NoDataCell>インポートされたデータはありません。</NoDataCell>
            )}
          </ImportHistoryTable>
        </>
      )}
    </>
  )
}

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
`

const ContentText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray750};
  margin-bottom: 24px;
`

const ReloadButtonWrapper = styled.div`
  margin-bottom: 16px;
`

const ImportHistoryTable = styled.div`
  display: grid;
  grid-template-columns: 150px 184px 184px 537px;
  grid-auto-rows: auto;
  width: 100%;
  max-height: 320px;
  margin-bottom: 32px;
  overflow: auto;
`
