import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'
import {
  ImportScheduleFormData,
  DataImportScheduleResponse,
  DataImportScheduleData,
  DataImportScheduleFormattedPayload,
} from './types'

type Props = {
  projectId: string
  options?: UseMutationOptions<DataImportScheduleData, string, ImportScheduleFormData, void>
}

export const useCreateDataImportSchedule = ({ projectId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async (payload: ImportScheduleFormData) => {
      const formattedPayload: DataImportScheduleFormattedPayload = {
        enable: payload.enable,
        sftp_user: payload.sftpUser,
        sftp_host: payload.sftpHost,
        sftp_port: payload.sftpPort,
        interval: payload.interval,
        time: payload.time,
        weekday: payload.weekday,
        day: payload.day,
      }

      const result: DataImportScheduleResponse = await request(
        'POST',
        `/api/projects/${projectId}/data_import_schedules/`,
        true,
        JSON.stringify(formattedPayload),
      )

      const formattedResponse: DataImportScheduleData = {
        scheduleId: result.id,
        enable: result.enable,
        sftpUser: result.sftp_user,
        sftpHost: result.sftp_host,
        sftpPort: result.sftp_port,
        interval: result.interval,
        time: result.time,
        weekday: result.weekday,
        day: result.day,
      }

      return formattedResponse
    },
    ...options,
  })
  return mutationResult
}
