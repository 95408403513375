export const SORT_KEYS = {
  SESSION_COUNT: 'session_count',
  USER_COUNT: 'user_count',
  GOAL_COUNT: 'goal_count',
  GOAL_RATE: 'goal_rate',
} as const

export const SORT_ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
} as const
