import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { getDateYYYYMMDDhhmi } from '../../util/Date'
import { AddButton } from '../common/Button'
import { PopupMenu } from '../common/PopupMenu'
import { Settings } from '@styled-icons/material/Settings'

export interface GcpAccountState {
  readonly id: number
  readonly user: string
  readonly gcpSaId: string
  readonly gcsBucketName: string
  readonly created: string
  readonly updated: string
}

export interface AccountKeyState {
  readonly id: number
  readonly description: string
  readonly gcpSaKeyId: string
  readonly author: string // 作成者
  readonly changer: string // 更新者
  readonly created: string
  readonly updated: string
}

export interface DataExportState {
  readonly id: number
  readonly projectId: number
  readonly name: string
  readonly latestDate: string // 最終更新日
  readonly author: string // 作成者
  readonly date: string // 更新日時
  readonly contentsMasterFileUrl: string
  readonly tagFileUrl: string
  readonly contentsTagsFileUrl: string
  readonly reportDaysLimit: number
}

// 表示幅
const layoutWidth = {
  // アカウント
  account: '300px',
  packet: '230px',

  // プロジェクト
  project: '300px',
  export: 'calc(230px - 1rem)',

  // 共通
  author: '130px',
  date: '140px',

  // キー
  key: '265px',
  desc: 'calc(265px - 1rem)',
  creater: '130px',
  created: '140px',
  //changer:'130px',
  //updated: '140px',
}

interface State {
  readonly tabIndex: number
  account: {
    readonly id: number
    readonly opened: boolean
  }
  project: {
    readonly id: number
    readonly opened: boolean
  }
  keys: {
    readonly id: number
    readonly opened: boolean
  }
}

type SettingType = 'account' | 'project' | 'key'

interface Props {
  readonly accounts: GcpAccountState[]
  readonly projects: DataExportState[]
  readonly keys: AccountKeyState[]
  readonly disabledAddProject: boolean // プロジェクト追加可能か
  readonly restKey: number // キー残り登録可能数
  readonly canViewAccount?: boolean
  readonly canDeleteAccount?: boolean
  readonly canViewProject?: boolean
  readonly canAddProject?: boolean
  readonly canDeleteProject?: boolean
  readonly canViewKey?: boolean
  readonly canAddKey?: boolean
  readonly canChangeKey?: boolean
  readonly canDeleteKey?: boolean
  readonly onAddProject?: () => void
  readonly onProjectInfo?: (id: number) => void
  readonly onKeyInfo?: (id: number) => void
  readonly onDeleteAccount?: (id: number) => void
  readonly onDeleteProject?: (id: number) => void
  readonly onDeleteKey?: (id: number) => void
  readonly onAddKey?: () => void
}

export function DataExportList(props: Props) {
  const {
    accounts,
    projects,
    keys,
    disabledAddProject,
    restKey,
    canViewAccount,
    canDeleteAccount,
    canViewProject,
    canAddProject,
    canDeleteProject,
    canViewKey,
    canAddKey,
    canChangeKey,
    canDeleteKey,
    onAddProject,
    onProjectInfo,
    onKeyInfo,
    onDeleteAccount,
    onDeleteProject,
    onDeleteKey,
    onAddKey,
  } = props

  const [state, setState] = React.useState<State>({
    tabIndex: 0,
    account: { id: 0, opened: false },
    project: { id: 0, opened: false },
    keys: { id: 0, opened: false },
  })

  const onClick = (id: number, type: SettingType) => {
    if (type === 'account') {
      setState({ ...state, account: { id: id, opened: true } })
    } else if (type === 'project') {
      setState({ ...state, project: { id: id, opened: true } })
    } else if (type === 'key') {
      setState({ ...state, keys: { id: id, opened: true } })
    }
  }
  const popupClose = (type: SettingType) => {
    if (type === 'account') {
      setState({ ...state, account: { id: 0, opened: false } })
    } else if (type === 'project') {
      setState({ ...state, project: { id: 0, opened: false } })
    } else if (type === 'key') {
      setState({ ...state, keys: { id: 0, opened: false } })
    }
  }

  return (
    <Container>
      {canViewAccount && (
        <div>
          {/* サービスアカウント */}
          {accounts.length === 0 ? (
            <div style={{ margin: '1.5rem 0' }}>{'サービスアカウントが追加されていません'}</div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Item style={{ paddingBottom: '0.5rem' }}>
                <Box className="title" style={{ width: `${layoutWidth.account}` }}>
                  サービスアカウント
                </Box>
                <Box className="title" style={{ width: `${layoutWidth.packet}` }}>
                  GCSバケット
                </Box>
                <Box className="title" style={{ width: `${layoutWidth.author}` }}>
                  追加したユーザー名
                </Box>
                <Box className="title" style={{ width: `${layoutWidth.date}` }}>
                  追加日時
                </Box>
              </Item>
              {accounts.map((account) => {
                return (
                  <Item key={`data-export-account-${account.id}`}>
                    <Box style={{ width: `${layoutWidth.account}` }}>{account.gcpSaId}</Box>
                    <Box style={{ width: `${layoutWidth.packet}` }}>{account.gcsBucketName}</Box>
                    <Box style={{ width: `${layoutWidth.author}` }}>{account.user}</Box>
                    <Box style={{ width: `${layoutWidth.date}` }}>{getDateYYYYMMDDhhmi(account.created)}</Box>
                    {canDeleteAccount && (
                      <SettingBox>
                        <SettingIcon onClick={() => onClick(account.id, 'account')} />
                        {state.account.opened && state.account.id === account.id && (
                          <PopupMenu
                            onClose={() => popupClose('account')}
                            style={{
                              right: '40px',
                              bottom: '-5px',
                              width: '80px',
                            }}
                          >
                            <Links>
                              {canDeleteAccount && (
                                <DesignedLink onClick={() => onDeleteAccount && onDeleteAccount(account.id)}>
                                  削除
                                </DesignedLink>
                              )}
                            </Links>
                          </PopupMenu>
                        )}
                      </SettingBox>
                    )}
                  </Item>
                )
              })}
            </div>
          )}
        </div>
      )}

      {/* エクスポート対象プロジェクト */}
      {canViewProject && (
        <Contents>
          <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>エクスポート対象プロジェクト</div>

          {canAddProject && (
            <div style={{ margin: '1rem 0' }}>
              <AddButton onClick={onAddProject} disabled={disabledAddProject}>
                エクスポート対象プロジェクトを追加
              </AddButton>
            </div>
          )}

          {/* エクスポート対象プロジェクト */}
          {projects.length === 0 ? (
            <div style={{ margin: '1.5rem 0' }}>{'プロジェクトが追加されていません'}</div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Item style={{ paddingBottom: '0.5rem' }}>
                <Box className="title" style={{ width: `${layoutWidth.project}` }}>
                  プロジェクト
                </Box>
                <Box className="title" style={{ width: `${layoutWidth.export}` }}>
                  エクスポートデータの最新日
                </Box>
                <Box className="title" style={{ width: `${layoutWidth.author}` }}>
                  追加したユーザー名
                </Box>
                <Box className="title" style={{ width: `${layoutWidth.date}` }}>
                  追加日時
                </Box>
              </Item>
              {projects.map((project) => {
                return (
                  <Item key={`data-export-project-${project.id}`}>
                    <Box style={{ width: `${layoutWidth.project}` }}>{project.name}</Box>
                    {/* ハイフンの形式なので置換しておく */}
                    <Box style={{ width: `${layoutWidth.export}` }}>
                      {project.latestDate ? project.latestDate.replace(/-/g, '/') : '----/--/--'}
                    </Box>
                    <Box style={{ width: `${layoutWidth.author}` }}>{project.author}</Box>
                    <Box style={{ width: `${layoutWidth.date}` }}>{getDateYYYYMMDDhhmi(project.date)}</Box>
                    <SettingBox style={{ marginRight: '0' }}>
                      <SettingIcon onClick={() => onClick(project.id, 'project')} />
                      {state.project.opened && state.project.id === project.id && (
                        <PopupMenu
                          onClose={() => popupClose('project')}
                          style={{
                            right: '40px',
                            bottom: canDeleteProject ? '-25px' : '-10px',
                            width: '80px',
                          }}
                        >
                          <Links>
                            <DesignedLink onClick={() => onProjectInfo && onProjectInfo(project.id)}>詳細</DesignedLink>
                            {canDeleteProject && (
                              <DesignedLink onClick={() => onDeleteProject && onDeleteProject(project.id)}>
                                削除
                              </DesignedLink>
                            )}
                          </Links>
                        </PopupMenu>
                      )}
                    </SettingBox>
                  </Item>
                )
              })}
            </div>
          )}
        </Contents>
      )}

      {/* キー */}
      {canViewKey && (
        <Contents>
          <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>キー</div>

          {/* キーには追加上限がある */}
          {canAddKey && (
            <div style={{ margin: '1rem 0' }}>
              <AddButton onClick={onAddKey} disabled={restKey === 0}>
                キーを追加
              </AddButton>
            </div>
          )}

          {/* キー */}
          {keys.length === 0 ? (
            <div style={{ margin: '1.5rem 0' }}>{'キーが追加されていません'}</div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Item style={{ paddingBottom: '0.5rem' }}>
                <Box className="title" style={{ width: `${layoutWidth.key}` }}>
                  キーID
                </Box>
                <Box className="title" style={{ width: `${layoutWidth.desc}` }}>
                  説明
                </Box>
                <Box className="title" style={{ width: `${layoutWidth.creater}` }}>
                  追加したユーザー名
                </Box>
                <Box className="title" style={{ width: `${layoutWidth.created}` }}>
                  追加日時
                </Box>
              </Item>
              {keys.map((k) => {
                return (
                  <Item key={`data-export-key-${k.id}`}>
                    <Box style={{ width: `${layoutWidth.key}` }}>{k.gcpSaKeyId}</Box>
                    <Box style={{ width: `${layoutWidth.desc}` }}>{k.description ? k.description : '-'}</Box>
                    <Box style={{ width: `${layoutWidth.creater}` }}>{k.author}</Box>
                    <Box style={{ width: `${layoutWidth.created}` }}>{getDateYYYYMMDDhhmi(k.created)}</Box>
                    {(canChangeKey || canDeleteKey) && (
                      <SettingBox style={{ marginRight: '0' }}>
                        <SettingIcon onClick={() => onClick(k.id, 'key')} />
                        {state.keys.opened && state.keys.id === k.id && (
                          <PopupMenu
                            onClose={() => popupClose('key')}
                            style={{
                              right: '40px',
                              bottom: canChangeKey && canDeleteKey ? '-25px' : '-10px',
                              width: '80px',
                            }}
                          >
                            <Links>
                              {canChangeKey && (
                                <DesignedLink onClick={() => onKeyInfo && onKeyInfo(k.id)}>詳細</DesignedLink>
                              )}
                              {canDeleteKey && (
                                <DesignedLink onClick={() => onDeleteKey && onDeleteKey(k.id)}>削除</DesignedLink>
                              )}
                            </Links>
                          </PopupMenu>
                        )}
                      </SettingBox>
                    )}
                  </Item>
                )
              })}
            </div>
          )}
          <div
            style={{
              color: `${colors.gray500}`,
              marginTop: '1rem',
            }}
          >{`キー作成可能数：残り${restKey}個`}</div>
        </Contents>
      )}
    </Container>
  )
}

const Container = styled.div``

const Contents = styled.div`
  background-color: ${colors.gray100};
  padding: 1rem;
  margin-top: 3rem;
`

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  height: 100%;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${colors.gray300};

  &.title {
    color: ${colors.gray500};
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;

  & + & {
    margin-left: 1.5rem;
  }

  &.title {
    color: ${colors.gray500};
  }
`

const SettingBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
`

const SettingIcon = styled(Settings).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  margin-left: auto;
  margin-right: 1rem;
  cursor: pointer;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const DesignedLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
    background-color: ${colors.gray200};
  }

  & + & {
    margin-top: 0.1rem;
  }
`
