import * as React from 'react'
import styled from 'styled-components'
import { GoalState } from '..'
import { GoalType, MatchMap, ValueConditionMap } from '../../../../util/Goal'
import { EventCondition } from './EventCondition'
import { InteractiveTooltip } from '../../../Tooltip'

const isUrl = (
  goal: GoalState,
): goal is GoalState & {
  url: Exclude<GoalState['url'], undefined>
} => {
  return goal.type === GoalType.url
}

interface Props {
  readonly goal: GoalState
}

export function ConditionCell({ goal }: Props) {
  return (
    <Container>
      {isUrl(goal) ? (
        <InteractiveTooltip content={goal.url.url}>
          <Url>
            {MatchMap[goal.url.condition]}: {goal.url.url}
          </Url>
        </InteractiveTooltip>
      ) : (
        <Condition>
          {goal.event?.category_condition && goal.event?.category && (
            <EventCondition
              type="カテゴリ"
              condition={MatchMap[goal.event.category_condition]}
              value={goal.event.category}
            />
          )}
          {goal.event?.action_condition && goal.event?.action && (
            <EventCondition
              type="アクション"
              condition={MatchMap[goal.event.action_condition]}
              value={goal.event.action}
            />
          )}
          {goal.event?.label_condition && goal.event?.label && (
            <EventCondition type="ラベル" condition={MatchMap[goal.event.label_condition]} value={goal.event.label} />
          )}
          {goal.event?.value_condition && goal.event?.value != null && (
            <EventCondition
              type="値"
              condition={ValueConditionMap[goal.event.value_condition]}
              value={goal.event.value}
            />
          )}
        </Condition>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 500px;
  margin-left: 1.5rem;
`
const Condition = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const Url = styled(Condition)`
  cursor: pointer;
`
