import * as React from 'react'
import styled from 'styled-components'
import { MessageRoundedError } from '@styled-icons/boxicons-solid/MessageRoundedError'
import { colors } from '../../../../../styleConstants'
import { ChevronThinDown } from '@styled-icons/entypo'
import { CapturedAtListBox } from './CapturedAtListBox'
import { YearMonthHistories } from './CaptureHistoryModalInner'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

interface Props {
  item: YearMonthHistories
}

export function CapturedAtListAccordion({ item }: Props) {
  return (
    <StyledAccordion disableGutters>
      <StyledAccordionSummary expandIcon={<ChevronThinDown size={20} color={colors.contentBlue.blue3} />}>
        <Title>{item.yearMonth}</Title>
        {item.failed && <MessageRoundedError size={16} color={colors.error} />}
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <CapturedAtListBox histories={item.histories} />
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  &:before {
    opacity: 0;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 40px;
  padding: 0 16px;
  color: ${colors.contentBlue.blue7};
  background-color: ${colors.contentBlue.blue1};
  cursor: pointer;
  .MuiAccordionSummary-content {
    margin: 0;
    align-items: center;
  }
`

const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-right: 8px;
  display: flex;
  align-items: center;
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  margin-top: 4px;
`
