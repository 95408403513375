import * as React from 'react'
import styled from 'styled-components'

import { ContentGroup } from '../../../util/hooks/api/useContentGroupReport'
import { BaseCell } from './BaseCell'
import { InteractiveTooltip, TOOLTIP_DELAY } from '../../Tooltip'

interface Props {
  readonly dataId: number
  readonly item: ContentGroup
}

export function ContentNameLongCell({ dataId, item }: Props) {
  return (
    <Cell data-id={dataId}>
      <GroupId>{item.id}</GroupId>
      <Value>
        <InteractiveTooltip content={item.name} delay={TOOLTIP_DELAY}>
          <div>{item.name}</div>
        </InteractiveTooltip>
      </Value>
    </Cell>
  )
}

const Cell = styled(BaseCell)`
  grid-column-start: 1;
  grid-column-end: 4;
  justify-content: flex-start;
`

const GroupId = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
`

const Value = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
