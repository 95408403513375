import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { GlobalContext } from '../GlobalState'
import { usePageState } from './SignUp/state'
import { colors, layout } from '../styleConstants'
import { AdminNormalButton } from '../components/common/Button'
import { Input } from '../components/common/Input'
import { Password } from '../components/common/Password'
import { SimplePageLayout } from '../components/layout/SimplePageLayout'
import { InvalidLayout } from '../components/layout/InvalidLayout'
import { PageHeader } from '../components/common/PageHeader'
import { PasswordMinLength, PasswordRegex } from '../util/Password'
import { UserNameMinLength, UserNameRegex } from '../util/UserName'

interface SignUpProps extends RouteComponentProps {
  token?: string
}

export function SignUp(props: SignUpProps) {
  const { actions: globalActions } = React.useContext(GlobalContext)
  const {
    state: { email, userName, password, disabled, errorMessage, loading, isUrlValid },
    actions,
  } = usePageState()
  if (loading) {
    actions.verify(props.token)
    return <div />
  }
  if (!isUrlValid) {
    return (
      <InvalidLayout
        message="URLの有効期限が切れています。"
        linkMessage="こちらのページからログインしてください"
        linkPath="/login"
      />
    )
  }
  return (
    <SimplePageLayout>
      <PageHeader title="ユーザー登録" description="ユーザー登録" />
      <Title>サインアップ</Title>
      <Form
        onSubmit={(e) => {
          globalActions.clearState()
          actions.submit(e)
        }}
        data-testid="form"
      >
        {errorMessage && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}

        <Font>メールアドレス</Font>
        <Email>{email}</Email>

        <Font>ユーザー名[必須]</Font>
        <DesignedInput
          type="text"
          value={userName}
          minLength={UserNameMinLength}
          pattern={UserNameRegex}
          required
          data-testid="user-name"
          onChange={actions.updateUserName}
        />
        <Comment>・２文字以上、半角英数字および_と-が使用できます。</Comment>

        <Font>パスワード[必須]</Font>
        <DesignedPassword
          value={password}
          minLength={PasswordMinLength}
          pattern={PasswordRegex}
          required
          className="input"
          data-testid="password"
          onChange={actions.updatePassword}
          onBlur={actions.onBlur}
          form="form"
        />
        <Comment style={{ marginTop: '0rem' }}>・パスワードは最低１０文字以上必要です。</Comment>
        <Comment style={{ marginBottom: '1rem' }}>
          ・パスワードはアルファベット、数字および記号のうち少なくとも２種類以上の組み合わせである必要があります。
        </Comment>

        <AdminNormalButton type="submit" data-testid="button" disabled={disabled} style={{ width: '100%' }}>
          登録
        </AdminNormalButton>
      </Form>
    </SimplePageLayout>
  )
}

const Title = styled.h1`
  text-align: center;
  color: ${colors.lightBlue};
`

const Form = styled.form``

const Font = styled.div`
  font-weight: ${layout.boldFontWeight};
  margin-top: 1.5rem;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 40px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`

const DesignedPassword = styled(Password)`
  width: 100%;
  height: 40px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`

const Email = styled.div`
  color: ${colors.gray500};
  font-size: 1rem;
  margin-top: 0.5rem;
  word-wrap: break-word;
`

const Comment = styled.div`
  color: ${colors.gray500};
  font-size: 0.8rem;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`
