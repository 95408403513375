import React from 'react'
import styled from 'styled-components'
import { Checkbox } from '../../../../components/common/Checkbox'
import { colors } from '../../../../styleConstants'

interface Props {
  checked: boolean
  onChange?: () => void
}
export function FunnelStepRequired({ checked, onChange }: Props) {
  const Checkbox = (
    <CheckboxContainer>
      <CheckboxLabel>
        <StyledCheckbox checked={checked} onClick={onChange} />
        <span onClick={onChange}>ステップを必須にする</span>
      </CheckboxLabel>
    </CheckboxContainer>
  )

  const Label = <LabelText disabled={!checked}>{checked && '✓'}ステップ必須</LabelText>

  return onChange ? Checkbox : Label
}

const CheckboxContainer = styled.div`
  margin-bottom: 18px;
  font-size: 12px;
  text-align: right;
`

const CheckboxLabel = styled.label`
  cursor: pointer;
`

const StyledCheckbox = styled(Checkbox)`
  width: 11px;
  height: 11px;
  margin-right: 0.5rem;
`

const LabelText = styled.div<{ disabled: boolean }>`
  margin-bottom: 10px;
  font-size: 12px;
  text-align: right;
  color: ${({ disabled }) => (disabled ? colors.disabled : colors.black2)};
  ${({ disabled }) => disabled && `text-decoration: line-through;`}
`
