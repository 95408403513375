import { DeviceType } from '../useDeviceType'
import { useCookieState } from './useCookieState'

const SPLIT_WIDTH_COOKIE_KEY_PREFIX = 'splitWidth'

export interface SplitWidthCookieValue {
  readonly reportId: string
  readonly width: number
}

interface Props {
  readonly deviceType: DeviceType
  readonly reportId: string
}

interface Result {
  readonly splitWidth: number | undefined
  readonly setSplitWidth: (width: number, reportId: string) => void
}

/**
 * 画面分割幅を管理するカスタムフック
 * デバイスタイプとレポートIDに基づいてCookieに保存された分割幅を取得・設定する
 *
 * @param {Props} props - フックのプロパティ
 * @param {DeviceType} props.deviceType - デバイスタイプ
 * @param {string} props.reportId - レポートID
 * @returns {Result} splitWidthとsetSplitWidthを含むオブジェクト
 * @returns {number | undefined} result.splitWidth - 現在の分割幅。未設定の場合はundefined
 * @returns {(width: number, reportId: string) => void} result.setSplitWidth - 分割幅を設定する関数
 *
 * @example
 * ```tsx
 * const { splitWidth, setSplitWidth } = useSplitWidth({
 *   deviceType: 'pc',
 *   reportId: '123'
 * })
 * ```
 */
export const useContentReportSplitWidth = ({ deviceType, reportId }: Props): Result => {
  const cookieKey = `${SPLIT_WIDTH_COOKIE_KEY_PREFIX}${deviceType}`
  const [cookieValue, setCookieValue] = useCookieState<SplitWidthCookieValue | undefined>({
    cookieKey,
    initialValue: undefined,
  })

  const splitWidth = cookieValue?.reportId === reportId ? cookieValue.width : undefined

  const setSplitWidth = (width: number, reportId: string) => {
    setCookieValue({
      reportId,
      width,
    })
  }

  return {
    splitWidth,
    setSplitWidth,
  }
}
