import * as React from 'react'

import { HelpTooltipLinkText } from '../Tooltip/HelpTooltipLinkText'
import { CS_ERROR_TYPES, CsErrorType } from '../../util/Response'
import { HELP_LINKS } from '../../constants'
import { ErrorIconTooltip } from '../Tooltip/IconTooltip'

interface Props {
  readonly errorType: CsErrorType
  readonly responseErrorHttpStatusCode: number | null
}

export function CaptureErrorIcon({ errorType, responseErrorHttpStatusCode }: Props) {
  return (
    <ErrorIconTooltip width={200} top={30} left={10}>
      <ErrorTooltipComponent errorType={errorType} responseErrorHttpStatusCode={responseErrorHttpStatusCode} />
    </ErrorIconTooltip>
  )
}

interface ErrorTooltipComponentProps {
  readonly errorType: CsErrorType
  readonly responseErrorHttpStatusCode: number | null
}

function ErrorTooltipComponent(props: ErrorTooltipComponentProps) {
  const { errorType, responseErrorHttpStatusCode } = props
  // 表示メッセージは以下のドキュメントで管理しています。変更あれば修正してください。
  // https://uncovertruth.tracery.jp/projects/contentanalytics/pages/8303
  switch (errorType) {
    case CS_ERROR_TYPES.CONNECTION_TIMEOUT:
      return (
        <div>
          クローラーのアクセス時に、ページが表示されずにタイムアウトしました。
          <br />
          手動でページキャプチャリクエストを実行し、改善されない場合はお問い合わせください。
        </div>
      )
    case CS_ERROR_TYPES.CONNECTION_REFUSED:
      return (
        <div>
          クローラーのアクセスが拒否されました。全てのページで同じエラーが発生している場合、Content&nbsp;Analyticsのクローラーがブロックされている可能性があります。
          <br />
          クローラーがサイトにアクセスできるように、IPアドレスをアクセス許可リストに追加するなど、お客様のサイトシステム担当者とご調整ください。
          <br />
          <HelpTooltipLinkText
            href={HELP_LINKS.CRAWLER_IP_ADDRESS}
            text={'※Content AnalyticsのIPアドレスはこちらを参照'}
          />
        </div>
      )
    case CS_ERROR_TYPES.ALERT_DISPLAYED:
      return <div>ページでアラートが表示されたため処理を中断しました。</div>
    case CS_ERROR_TYPES.PAGE_NOT_FOUND:
      return <div>存在しないページへのアクセスのため、処理を中断しました。</div>
    case CS_ERROR_TYPES.PAGE_RESPONSE_ERROR:
      return (
        <div>
          サイト側からエラーが返されて、ページが表示されなかったため、処理を中断しました。 <br />
          (HTTPステータス={responseErrorHttpStatusCode})<br />
          手動でページキャプチャリクエストを実行し、改善されない場合はお問い合わせください。
        </div>
      )
    default:
      return (
        <div>
          想定外のエラーが発生しました。
          <br />
          手動でページキャプチャリクエストを実行し、改善されない場合はお問い合わせください。
        </div>
      )
  }
}
