import { useQuery } from '@tanstack/react-query'

import { TEAM_PV_QUERY_KEY } from '../constants'
import { request } from '../../../request'
import { TeamPv, TeamPvResponse } from './types'

interface Props {
  readonly yearMonth?: string
}

export const useTeamPv = ({ yearMonth }: Props) => {
  const queryKey = [TEAM_PV_QUERY_KEY, { yearMonth }]

  return useQuery({
    queryKey: queryKey,
    queryFn: () => fetch(yearMonth),
    select: transform,
    staleTime: 1000 * 60 * 5,
  })
}

const fetch = async (yearMonth?: string) => {
  const path = yearMonth ? `/api/team/pv/?year_month=${yearMonth}` : '/api/team/pv/'
  return await request<TeamPvResponse>('GET', path, true)
}

const transform = (response: TeamPvResponse): TeamPv => {
  return {
    limit: response.limit,
    pageViewCount: response.page_view?.count ?? 0,
    projectPageViews: response.project_page_views,
  }
}
