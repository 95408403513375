import * as React from 'react'
import { request } from '../../util/request'
import { TeamData } from '../../util/Response'
import { State } from './state'

// プロジェクト別のPV情報
export interface ProjectPvInfo {
  readonly name: string
  readonly results: Array<{
    readonly yearMonth: string
    readonly count: number
  }>
}

export class Info {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly openToast: ({ message }: { message: string }) => void,
  ) {}

  // 組織名の編集
  updateName = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      info: {
        ...this.state.info,
        tempTeamName: event.currentTarget.value,
        disabled: !(event.currentTarget.validity.valid && this.state.info.teamName !== event.currentTarget.value),
      },
    })
  }

  // 組織名の変更リセット
  onCancel = () => {
    this.setState({
      ...this.state,
      info: {
        ...this.state.info,
        tempTeamName: this.state.info.teamName,
        disabled: true,
      },
    })
  }

  // 組織名の変更を送信
  onApply = async () => {
    try {
      const teamData: TeamData = await request(
        'PATCH',
        `/api/team/`,
        true,
        JSON.stringify({ name: this.state.info.tempTeamName }),
      )
      this.openToast({ message: '組織情報を変更しました' })
      this.setState({
        ...this.state,
        info: {
          ...this.state.info,
          teamName: teamData.name,
          tempTeamName: teamData.name,
          disabled: true,
        },
        teamErrorMessage: '',
        toastMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        teamErrorMessage: typeof e === 'string' ? e : '組織情報の変更に失敗しました。',
      })
    }
  }
}
