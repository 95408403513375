import React, { useEffect, useState } from 'react'
import { ErrorBox } from '../../common/ErrorBox'
import { Loading } from '../../common/Loading'
import { FieldEditPanel } from './FieldEditPanel'
import { FieldReferencePanel } from './FieldReferencePanel'
import { Authority } from '../../../util/Authority'
import { scrollToElement } from '../../../util/scrollToElement'
import { useDataImportAllKeyFields } from '../../../util/hooks/api/DataImport/useDataImportAllKeyFields'
import { useDataImportSchemas } from '../../../util/hooks/api/DataImport/useDataImportSchemas'
import { DeleteButton } from './DeleteButton'
import { NoSavedPanel } from './NoSavedPanel'
import { DeleteModal } from './DeleteModal'
import { IMPORT_FIELDS_AREA_ID } from '..'

interface Props {
  projectId: string
  permissions: any
  isEditing: boolean
  setIsEditing: (arg: boolean) => void
  setHasImportField: (arg: boolean) => void
}

export function ImportFieldContent({ projectId, isEditing, setIsEditing, setHasImportField, permissions }: Props) {
  const {
    data: importSchemasData,
    isError: isImportSchemasDataError,
    isLoading: isImportSchemasDataLoading,
  } = useDataImportSchemas({
    projectId: projectId,
  })
  const {
    data: allKeyFieldsData,
    isError: isAllKeyFieldsDataError,
    isLoading: isAllKeyFieldsDataLoading,
  } = useDataImportAllKeyFields({
    projectId: projectId,
  })

  const [csvSuggestionId, setCsvSuggestionId] = useState<number | null>(null)
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)

  useEffect(() => {
    setHasImportField(!!importSchemasData?.dataImportFields)
  }, [importSchemasData])

  if (isAllKeyFieldsDataLoading || isImportSchemasDataLoading) {
    return <Loading />
  }
  if (isAllKeyFieldsDataError || isImportSchemasDataError) {
    return <ErrorBox>インポートフィールドの取得に失敗しました。</ErrorBox>
  }

  const keyFieldLabel = importSchemasData?.dataImportKeyField?.nameLabel
  const importFields = importSchemasData?.dataImportFields
  const closeEditPanel = () => {
    setIsEditing(false)
    scrollToElement(IMPORT_FIELDS_AREA_ID)
  }
  return (
    <>
      {importFields && Authority.canDeleteDataImportSchema(permissions, projectId) && (
        <DeleteButton openDeleteModal={() => setIsDeleteModalOpened(true)} />
      )}
      {isEditing ? (
        <FieldEditPanel
          projectId={projectId}
          schemasId={importSchemasData?.schemasId}
          allKeyFieldsData={allKeyFieldsData}
          keyFieldLabel={keyFieldLabel}
          importFields={importFields}
          closeEditPanel={closeEditPanel}
          setCsvSuggestionId={setCsvSuggestionId}
          csvSuggestionId={csvSuggestionId}
        />
      ) : (
        <>
          {importFields ? (
            <FieldReferencePanel
              projectId={projectId}
              permissions={permissions}
              keyFieldLabel={keyFieldLabel}
              importFields={importFields}
              openEditPanel={() => setIsEditing(true)}
            />
          ) : (
            <NoSavedPanel onClickSettingField={() => setIsEditing(true)} />
          )}
        </>
      )}
      <DeleteModal
        opened={isDeleteModalOpened}
        onClose={() => setIsDeleteModalOpened(false)}
        setIsEditing={setIsEditing}
        projectId={projectId}
        csvSuggestionId={csvSuggestionId}
        setCsvSuggestionId={setCsvSuggestionId}
      />
    </>
  )
}
