import * as React from 'react'
import styled from 'styled-components'
import { CapturedAtListBox } from './CapturedAtListBox'
import {
  ContentCaptureHistory,
  ContentCaptureHistoryData,
} from '../../../../../util/hooks/api/useContentCaptureHistoryData'
import { WarningBox } from '../../../../common/WarningBox'
import { ErrorBox } from '../../../../common/ErrorBox'
import { CapturedAtListAccordion } from './CapturedAtListAccordion'

export type YearMonthHistories = {
  yearMonth: string
  failed: boolean
  histories: Array<ContentCaptureHistory>
}

function initializeYearMonthHistories(history: ContentCaptureHistory): YearMonthHistories {
  return {
    yearMonth: history.capturedYearMonth,
    failed: !history.captured,
    histories: [history],
  }
}
interface Props {
  data: ContentCaptureHistoryData
}

export function CaptureHistoryModalInner({ data }: Props) {
  if (!data.histories) return <ErrorBox>検索対象期間には履歴がありません</ErrorBox>

  // 最終キャプチャ月のキャプチャ履歴情報を取得
  const recentCapturedYearMonth = data.histories[0].capturedYearMonth
  const recentYearMonthHistories = data.histories.filter((hist) => recentCapturedYearMonth === hist.capturedYearMonth)

  // 最終キャプチャ月より過去のキャプチャ履歴情報を取得
  const pastYearMonthHistories: Array<YearMonthHistories> = []
  data.histories.forEach((hist) => {
    // 最終キャプチャ月は含めない
    if (recentCapturedYearMonth === hist.capturedYearMonth) return

    const pastObj = pastYearMonthHistories.find((pastObj) => pastObj.yearMonth === hist.capturedYearMonth)
    if (!pastObj) {
      pastYearMonthHistories.push(initializeYearMonthHistories(hist))
      return
    }

    pastObj.histories.push(hist)

    // 月単位で1度でもキャプチャ未取得であれば、対象月にエラーがあることをユーザーに知らせる
    if (pastObj.failed) return

    pastObj.failed = !hist.captured
  })

  const isAllCapturedExists = !data.histories.some((hist) => !hist.captured)

  return (
    <>
      {!isAllCapturedExists && (
        <WarningBoxWrapper>
          <WarningBox>
            対象のコンテンツは、キャプチャ取得時に表示されていない期間が存在します。
            <br />
            コンテンツレポートでは、キャプチャ取得時に表示されているコンテンツのビュー数・クリック数などを集計します。
          </WarningBox>
        </WarningBoxWrapper>
      )}
      <CapturedAtListWrapper>
        <CapturedAtListBox histories={recentYearMonthHistories} />
      </CapturedAtListWrapper>
      {pastYearMonthHistories.map((item) => (
        <CapturedAtListWrapper key={item.yearMonth}>
          <CapturedAtListAccordion item={item} />
        </CapturedAtListWrapper>
      ))}
    </>
  )
}

const CapturedAtListWrapper = styled.div`
  margin-bottom: 24px;
`

const WarningBoxWrapper = styled.div`
  margin-bottom: 24px;
`
