import * as React from 'react'
import styled from 'styled-components'

import { PageCapturedCheckbox } from '../../../../components/report/filters/PageCapturedCheckbox'
import { SearchText } from '../../../../components/textbox/SearchText'
import { CustomDimensionValueReportContext } from '../../../../contexts/CustomDimentionValueReportContext'

interface Props {
  readonly onSearch: () => void
  readonly onSearchClear: () => void
  readonly onPageCapturedChange?: () => void
}

/**
 * 検索テキスト・ページキャプチャ対象ページ絞り込みなどの検索エリアをレンダリングする
 */
export function SearchArea({ onSearch, onSearchClear, onPageCapturedChange }: Props) {
  const {
    state: { searchText },
    actions: { setSearchText },
  } = React.useContext(CustomDimensionValueReportContext)

  const handleSearch = (value: string) => {
    setSearchText(value)
    onSearch()
  }
  const handleSearchClear = () => {
    setSearchText('')
    onSearchClear()
  }

  return (
    <Container>
      <SearchWrapper>
        <SearchText value={searchText} onClear={handleSearchClear} onSearch={handleSearch} />
      </SearchWrapper>
      <ControlWrapper>
        <PageCapturedCheckbox onPageCapturedChange={onPageCapturedChange} />
      </ControlWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 416px;
  padding-right: 30px;
`

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
