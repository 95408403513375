import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { FileImport } from '@styled-icons/boxicons-solid/FileImport'
import { ExecutionCsvUploadPanel } from './ExecutionCsvUploadPanel'
import { ExecutionSftpSchedulePanel } from './ExecutionSftpSchedulePanel'
import { AccordionBox } from '../../common/AccordionBox'

interface Props {
  projectId: string
  isImportFieldEditing: boolean
  hasImportField: boolean
}

export function ImportExecutionArea({ projectId, hasImportField, isImportFieldEditing }: Props) {
  return (
    <AccordionBox
      title={
        <>
          データインポート
          {isImportFieldEditing && (
            <EditingNotion>インポートフィールドを編集中です。インポートフィールドを保存してください。</EditingNotion>
          )}
        </>
      }
      icon={<FileImport />}
      accordionProps={{ defaultExpanded: true }}
    >
      <OverlayArea enableOverlay={isImportFieldEditing}>
        {hasImportField ? (
          <>
            <ExecutionCsvUploadPanel projectId={projectId} />
            <ExecutionSftpSchedulePanel projectId={projectId} />
          </>
        ) : (
          <NoImportFieldText>インポートフィールドを設定してください。</NoImportFieldText>
        )}
      </OverlayArea>
    </AccordionBox>
  )
}

const NoImportFieldText = styled.div`
  margin: 0;
  font-size: 16px;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.headerBg};
`

export const Panel = styled.div`
  margin: 0 0 24px;
  padding: 16px;
  border: 1px solid ${colors.gray250};
`

export const PanelTitle = styled.h3`
  margin: 0 0 24px;
  font-size: 18px;
`

export const PanelText = styled.div`
  line-height: 1.5;
  font-size: 16px;
`

const EditingNotion = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${colors.error};
  padding-left: 16px;
`

const OverlayArea = styled.div<{ enableOverlay: boolean }>`
  position: relative;
  &:after {
    visibility: ${({ enableOverlay }) => (enableOverlay ? 'visible' : 'hidden')};
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
    opacity: 0.6;
    z-index: 1;
  }
`
