import * as React from 'react'
import { request } from '../../../util/request'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface GcpAccountData {
  readonly id: number
  readonly user: string
  readonly gcp_sa_id: string
  readonly gcs_bucket_name: string
  readonly created_at: string
  readonly updated_at: string
  readonly gcp_sa_key: {
    readonly id: number
    readonly user: string
    readonly gcp_sa: string
    readonly gcp_sa_key_id: string
    readonly name: string
    readonly created_at: string
    readonly updated_at: string
    readonly token: {
      readonly download_url: string
    }
  }
}

interface AccountKeyData {
  readonly id: number
  readonly user: string
  readonly gcp_sa: string
  readonly gcp_sa_key_id: string
  readonly name: string
  readonly created_at: string
  readonly updated_at: string
  readonly token: {
    readonly download_url: string
  }
}

interface State {
  readonly creating: boolean
  readonly complete: boolean
  readonly gcpId: number
  readonly description: string
  readonly errorMessage?: string
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // アカウント作成
  createGcpAccount = async () => {
    try {
      const json: GcpAccountData = await request(
        'POST',
        `/api/gcp_sas/`,
        true,
        JSON.stringify({ description: this.state.description }),
      )

      // キーダウンロード実行
      if (json.gcp_sa_key.token.download_url) {
        window.location.href = json.gcp_sa_key.token.download_url
      }

      this.setState({
        ...this.state,
        creating: false,
        complete: true,
        gcpId: json.id,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        creating: false,
        complete: false,
        errorMessage: typeof e === 'string' ? e : 'サービスアカウントの作成に失敗しました。',
      })
    }
  }

  // キー作成
  createGcpAccountKey = async (gcpServiceAccountId: number) => {
    try {
      const json: AccountKeyData = await request(
        'POST',
        `/api/gcp_sas/${gcpServiceAccountId}/keys/`,
        true,
        JSON.stringify({ description: this.state.description }),
      )

      // キーダウンロード実行
      if (json.token.download_url) {
        window.location.href = json.token.download_url
      }

      this.setState({
        ...this.state,
        creating: false,
        complete: true,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        creating: false,
        complete: false,
        errorMessage: typeof e === 'string' ? e : 'キーの作成に失敗しました。',
      })
    }
  }

  // アカウント作成
  onCreate = () => {
    this.setState({ ...this.state, creating: true })
  }

  // キー説明の編集
  updateDescription = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, description: event.currentTarget.value })
  }
}

const initialState: State = {
  creating: false,
  complete: false,
  gcpId: 0,
  description: '',
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
