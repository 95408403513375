import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { usePageState } from './state/ProjectModalState'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { AdminNormalButton, CancelButton } from '../common/Button'
import { Input } from '../common/Input'
import { WarningBox } from '../common/WarningBox'

interface Props {
  readonly opened: boolean
  readonly onClose: () => void
  readonly onCreate: (id: number) => void
}

export function ProjectModal({ opened, onClose, onCreate }: Props) {
  const {
    state: { name, url, disabled, inputDisabled, urlDisabled, errorMessage },
    actions,
  } = usePageState()
  return (
    <Modal isOpen={opened} onClose={onClose} title="新規プロジェクトの作成" width={600}>
      <ModalContentWrapper>
        {errorMessage && <ErrorMessage style={{ marginTop: 0 }}>{errorMessage}</ErrorMessage>}
        <WarningBox>
          <p style={{ margin: 0 }}>プロジェクト作成後はURLを変更することができません。</p>
        </WarningBox>
        <Label style={{ marginTop: '1.5rem' }}>プロジェクト名[必須]</Label>
        <DesignedInput
          type="text"
          value={name}
          required
          data-testid="input-project"
          onChange={actions.updateName}
          look={inputDisabled ? 'red' : 'normal'}
        />

        <Label style={{ marginTop: '1.5rem' }}>URL[必須]</Label>
        <DesignedInput
          type="url"
          value={url}
          required
          data-testid="input-url"
          onChange={actions.updateUrl}
          look={urlDisabled ? 'red' : 'normal'}
          placeholder="例）https://www.uncovertruth.co.jp"
        />
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <AdminNormalButton disabled={disabled} onClick={(e) => actions.createProject(e, onCreate)}>
          作成
        </AdminNormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 40px;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`
