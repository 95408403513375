import * as React from 'react'
import { request } from '../../util/request'
import { State } from './state'

// 処理ステップ
export const InviteStep = {
  none: 0,
  confirm: 1, // 確認
  complete: 2, // 完了
}

export class Invite {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // 招待ユーザーの取り消しフロー開始
  onRevoke = (id: number) => {
    const user = this.state.invite.users.find((user) => user.id === id)
    if (user) {
      this.setState({
        ...this.state,
        selectedUserId: user.id,
        selectedUserName: user.userName,
        invite: {
          ...this.state.invite,
          step: InviteStep.confirm,
        },
        inviteErrorMessage: '',
      })
    }
  }

  // 招待ユーザー取り消しキャンセル
  onCancel = () => {
    this.setState({
      ...this.state,
      selectedUserId: 0,
      selectedUserName: '',
      invite: {
        ...this.state.invite,
        step: InviteStep.none,
      },
    })
  }

  // 招待キャンセル実行
  onApply = async () => {
    try {
      await request('DELETE', `/api/invite/${this.state.selectedUserId}/`, true)
      this.setState({
        ...this.state,
        reload: true,
        selectedUserId: 0,
        selectedUserName: '',
        invite: {
          ...this.state.invite,
          step: InviteStep.none,
        },
        toastMessage: '招待を取り消しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        selectedUserId: 0,
        selectedUserName: '',
        invite: {
          ...this.state.invite,
          step: InviteStep.none,
        },
        inviteErrorMessage: typeof e === 'string' ? e : '招待の取り消しに失敗しました。',
      })
    }
  }
}
