import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Checkbox } from '../../../common/Checkbox'
import {
  setSearchParamsFilterContentEventsExists,
  useFilterContentEventsExists,
} from '../../../../util/hooks/cookie/useFilterContentEventsExists'

interface Props {
  readonly onPageCapturedChange?: (value: boolean) => void
}

/**
 * ページキャプチャ済みのデータのみ集計に含めるかの、フィルタリングON/OFFするためのチェックボックスコンポーネントを表示する。
 *
 * @returns {ReactElement | null} The rendered checkbox component.
 * @param {Function} props.onPageCapturedChange - チェックボックスの状態が変更されたときに呼び出される関数
 */
export const PageCapturedCheckbox = ({ onPageCapturedChange }: Props): ReactElement | null => {
  const { filterContentEventsExists, setFilterContentEventsExists } = useFilterContentEventsExists()

  const handleClick = () => {
    const value = !filterContentEventsExists
    setFilterContentEventsExists(value)
    setSearchParamsFilterContentEventsExists(value)
    onPageCapturedChange && onPageCapturedChange(value)
  }

  return (
    <CheckBoxWrapper onClick={handleClick}>
      <Checkbox checked={filterContentEventsExists} style={{ width: '16px', height: '16px', marginRight: '6px' }} />
      <CheckboxText>キャプチャ済みのページのみ表示する</CheckboxText>
    </CheckBoxWrapper>
  )
}

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

const CheckboxText = styled.div`
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
`
