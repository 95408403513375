import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Role, RoleLabel } from '../../util/Authority'
import { PopupMenu } from '../common/PopupMenu'
import { Settings } from '@styled-icons/material/Settings'

export interface UserState {
  readonly id: number
  readonly userName: string
  readonly email: string
  readonly role: number
  readonly roleName: string
}

interface Props {
  readonly users?: UserState[]
  readonly inactiveUsers?: UserState[]
  readonly inviteUsers?: UserState[]
  readonly canChangeTeamMember?: boolean
  readonly canDeleteTeamMember?: boolean
  readonly inactived?: boolean
  readonly invited?: boolean
  readonly onEdit?: (id: number) => void
  readonly onDelete?: (id: number) => void
  readonly onReturn?: (id: number) => void
  readonly onRevoke?: (id: number) => void
}

export function UserList(props: Props) {
  const {
    users,
    inactiveUsers,
    inviteUsers,
    canChangeTeamMember,
    canDeleteTeamMember,
    inactived,
    invited,
    onEdit,
    onDelete,
    onReturn,
    onRevoke,
  } = props
  const [state, setState] = React.useState({ id: 0, opened: false })
  const onClick = (id: number) => {
    setState({ ...state, id: id, opened: true })
  }
  const popupClose = () => {
    setState({ ...state, id: 0, opened: false })
  }
  return (
    <Container>
      {/* 登録ユーザー */}
      {!inactived && !invited && (
        <div>
          {users && users.length === 0 ? (
            <div style={{ margin: '1.5rem 0' }}>ユーザーが登録されていません</div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Item style={{ paddingBottom: '0.5rem' }}>
                <Box className="title" style={{ width: '250px' }}>
                  ユーザー名
                </Box>
                <Box className="title" style={{ width: '350px' }}>
                  メールアドレス
                </Box>
                <Box className="title">役割</Box>
              </Item>
              {users &&
                users.map((user) => {
                  return (
                    <Item key={user.id}>
                      <Box style={{ width: '250px' }}>{user.userName}</Box>
                      <Box style={{ width: '350px' }}>{user.email}</Box>
                      {user.role === Role.User.admin && <Box style={{ width: '200px' }}>{RoleLabel.User.admin}</Box>}
                      {user.role === Role.User.member && <Box style={{ width: '200px' }}>{RoleLabel.User.member}</Box>}
                      {(canChangeTeamMember || canDeleteTeamMember) && (
                        <SettingBox>
                          <SettingIcon onClick={() => onClick(user.id)} />
                          {state.opened && state.id === user.id && (
                            <PopupMenu
                              onClose={popupClose}
                              style={{
                                right: '20px',
                                bottom: canChangeTeamMember && canDeleteTeamMember ? '-25px' : '-10px',
                                width: '120px',
                              }}
                            >
                              <Links>
                                {canChangeTeamMember && (
                                  <DesignedLink onClick={() => onEdit && onEdit(user.id)}>権限の変更</DesignedLink>
                                )}
                                {canDeleteTeamMember && (
                                  <DesignedLink onClick={() => onDelete && onDelete(user.id)}>削除</DesignedLink>
                                )}
                              </Links>
                            </PopupMenu>
                          )}
                        </SettingBox>
                      )}
                    </Item>
                  )
                })}
            </div>
          )}
        </div>
      )}

      {/* 無効化ユーザー */}
      {inactived && (
        <div>
          {inactiveUsers && inactiveUsers.length === 0 ? (
            <div style={{ margin: '1.5rem 0' }}>無効化されたユーザーは存在しません。</div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Item style={{ paddingBottom: '0.5rem' }}>
                <Box className="title" style={{ width: '250px' }}>
                  ユーザー名
                </Box>
                <Box className="title" style={{ width: '350px' }}>
                  メールアドレス
                </Box>
              </Item>
              {inactiveUsers &&
                inactiveUsers.map((user) => {
                  return (
                    <Item key={user.id}>
                      <Box className={'inactive'} style={{ width: '250px' }}>
                        {user.userName}
                      </Box>
                      <Box className={'inactive'} style={{ width: '350px' }}>
                        {user.email}
                      </Box>
                      {canChangeTeamMember && (
                        <SettingBox>
                          <SettingIcon onClick={() => onClick(user.id)} />
                          {state.opened && state.id === user.id && (
                            <PopupMenu onClose={popupClose} style={{ right: '20px', bottom: '-10px', width: '80px' }}>
                              <Links>
                                <DesignedLink onClick={() => onReturn && onReturn(user.id)}>有効化</DesignedLink>
                              </Links>
                            </PopupMenu>
                          )}
                        </SettingBox>
                      )}
                    </Item>
                  )
                })}
            </div>
          )}
        </div>
      )}

      {/* 招待中ユーザー */}
      {invited && (
        <div>
          {inviteUsers && inviteUsers.length === 0 ? (
            <div style={{ margin: '1.5rem 0' }}>招待中のユーザーは存在しません。</div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Item style={{ paddingBottom: '0.5rem' }}>
                <Box className="title" style={{ width: '600px' }}>
                  メールアドレス
                </Box>
              </Item>
              {inviteUsers &&
                inviteUsers.map((user) => {
                  return (
                    <Item key={user.id}>
                      <Box style={{ width: '600px' }}>{user.email}</Box>
                      {canChangeTeamMember && (
                        <SettingBox>
                          <SettingIcon onClick={() => onClick(user.id)} />
                          {state.opened && state.id === user.id && (
                            <PopupMenu onClose={popupClose} style={{ right: '20px', bottom: '-10px', width: '120px' }}>
                              <Links>
                                <DesignedLink onClick={() => onRevoke && onRevoke(user.id)}>
                                  招待の取り消し
                                </DesignedLink>
                              </Links>
                            </PopupMenu>
                          )}
                        </SettingBox>
                      )}
                    </Item>
                  )
                })}
            </div>
          )}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div``

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  height: 100%;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${colors.gray300};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;

  & + & {
    margin-left: 1.5rem;
  }

  &.title,
  &.inactive {
    color: ${colors.gray500};
  }
`

const SettingBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
`

const SettingIcon = styled(Settings).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const DesignedLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
    background-color: ${colors.gray200};
  }

  & + & {
    margin-top: 0.1rem;
  }
`
