import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { request } from '../../request'
import { ContentTag } from './useContentReport'

type Payload = { name: string; tagNames: string[] }
type Response = { name: string; tags: ContentTag[] }

type ManagedContentSchema = {
  id: number
  name: string
}
type ContentTagSchema = {
  id: number
  name: string
}
type ApiResponse = {
  id: number
  managed_content: ManagedContentSchema
  content_tags: ContentTagSchema[]
}

export const useSaveContent = ({
  projectId,
  contentId,
  options,
}: {
  projectId: string
  contentId: number
  options: UseMutationOptions<Response, string, Payload, void>
}) => {
  const mutationResult = useMutation({
    mutationFn: async (payload: Payload) => {
      const apiPayload = {
        managed_content_name: payload.name,
        content_tag_names: payload.tagNames,
      }
      const response = await request<ApiResponse>(
        'PUT',
        `/api/projects/${projectId}/partial_contents/${contentId}/`,
        true,
        JSON.stringify(apiPayload),
      )
      return {
        name: response.managed_content?.name || '',
        tags: response.content_tags,
      }
    },
    ...options,
  })
  return {
    saveContentMutate: mutationResult.mutate,
    isSavingContent: mutationResult.isLoading,
    ...mutationResult,
  }
}
