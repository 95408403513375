import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { DEVICE_TYPES_FOR_API, DeviceTypeForAPI } from '../../util/hooks/useDeviceType'
import { WatchLater } from '@styled-icons/material-outlined/WatchLater'
import { OpenInNew } from '@styled-icons/material/OpenInNew'
import { CsErrorType } from '../../util/Response'
import { StatusItem } from '../CaptureHistory/StatusItem'

export const gridSize = {
  minWidth: '840px',
  maxWidth: '940px',
  headerHeight: '55px',
  minHeight: '60px',
  row: {
    page: '240px',
    date: '150px',
    device: '100px',
    status: '150px',
    completionDate: '150px',
    detail: '50px',
  },
  icon: '30px',
}

export interface ContentSegmentationHistoryItem {
  readonly errorType: CsErrorType | null
  readonly responseErrorHttpStatusCode: number | null
}
export interface CaptureHistoryItem {
  readonly id: number
  readonly page: string // ページ
  readonly title: string // タイトル
  readonly date: string // リクエスト日時
  readonly mobileCsHistory: ContentSegmentationHistoryItem
  readonly pcCsHistory: ContentSegmentationHistoryItem
  readonly mobileStatus: string // モバイルステータス
  readonly pcStatus: string // PCステータス
  readonly mobileCompletionDate: string // モバイル完了日時
  readonly pcCompletionDate: string // PC完了日時
  readonly mobilePageId: string // ページID
  readonly pcPageId: string // ページID
  readonly urlId: string
}

interface Props {
  readonly item: CaptureHistoryItem
  readonly onClick: (id: string, pageLayout: DeviceTypeForAPI) => void
}

const { mobile, pc } = DEVICE_TYPES_FOR_API

export function CaptureHistoryGridItem(props: Props) {
  const { item, onClick } = props
  return (
    <Container>
      {/* ページ */}
      <Row className="page" data-id={item.id}>
        <PageBox>
          <div>{item.page}</div>
          <Title>{item.title}</Title>
        </PageBox>
        <Open>
          <a href={`${item.page}`} target="_blank" rel="noopener noreferrer">
            <OpenInNew size={18} color={`${colors.gray600}`} />
          </a>
        </Open>
      </Row>

      {/* リクエスト日時 */}
      <Row className="date" data-id={item.id}>
        {item.date}
      </Row>

      {/* デバイス */}
      {item.pcStatus !== '' && item.mobileStatus !== '' && (
        <Row className="device" data-id={item.id}>
          <SplitBox className="top">
            <Item>{'Mobile'}</Item>
          </SplitBox>
          <SplitBox>
            <Item>{'PC'}</Item>
          </SplitBox>
        </Row>
      )}
      {((item.pcStatus !== '' && item.mobileStatus === '') || (item.pcStatus === '' && item.mobileStatus !== '')) && (
        <Row className="device" data-id={item.id} style={{ justifyContent: 'center' }}>
          <Item>{item.pcStatus === '' ? 'Mobile' : 'PC'}</Item>
        </Row>
      )}
      {item.pcStatus === '' && item.mobileStatus === '' && (
        <Row className="device" data-id={item.id} style={{ justifyContent: 'center' }}></Row>
      )}

      {/* ステータス */}
      {item.pcStatus !== '' && item.mobileStatus !== '' && (
        <Row className="status" data-id={item.id}>
          <SplitBox className="top">
            <Item>
              <StatusItem
                status={item.mobileStatus}
                errorType={item.mobileCsHistory.errorType}
                responseErrorHttpStatusCode={item.mobileCsHistory.responseErrorHttpStatusCode}
              />
            </Item>
          </SplitBox>
          <SplitBox>
            <Item>
              <StatusItem
                status={item.pcStatus}
                errorType={item.pcCsHistory.errorType}
                responseErrorHttpStatusCode={item.pcCsHistory.responseErrorHttpStatusCode}
              />
            </Item>
          </SplitBox>
        </Row>
      )}
      {((item.pcStatus !== '' && item.mobileStatus === '') || (item.pcStatus === '' && item.mobileStatus !== '')) && (
        <Row className="status" data-id={item.id} style={{ justifyContent: 'center' }}>
          <Item>
            {item.pcStatus !== '' && (
              <StatusItem
                status={item.pcStatus}
                errorType={item.pcCsHistory.errorType}
                responseErrorHttpStatusCode={item.pcCsHistory.responseErrorHttpStatusCode}
              />
            )}
            {item.mobileStatus !== '' && (
              <StatusItem
                status={item.mobileStatus}
                errorType={item.mobileCsHistory.errorType}
                responseErrorHttpStatusCode={item.mobileCsHistory.responseErrorHttpStatusCode}
              />
            )}
          </Item>
        </Row>
      )}
      {item.pcStatus === '' && item.mobileStatus === '' && (
        <Row className="status" data-id={item.id} style={{ justifyContent: 'center' }}></Row>
      )}

      {/* 取得完了日時 */}
      {item.pcStatus !== '' && item.mobileStatus !== '' && (
        <Row className="completionDate" data-id={item.id}>
          <SplitBox className="top">
            <Item>{item.mobileCompletionDate}</Item>
          </SplitBox>
          <SplitBox>
            <Item>{item.pcCompletionDate}</Item>
          </SplitBox>
        </Row>
      )}
      {((item.pcStatus !== '' && item.mobileStatus === '') || (item.pcStatus === '' && item.mobileStatus !== '')) && (
        <Row className="completionDate" data-id={item.id} style={{ justifyContent: 'center' }}>
          <Item>{item.pcStatus === '' ? item.mobileCompletionDate : item.pcCompletionDate}</Item>
        </Row>
      )}
      {item.pcStatus === '' && item.mobileStatus === '' && (
        <Row className="completionDate" data-id={item.id} style={{ justifyContent: 'center' }}></Row>
      )}

      {/* 詳細 */}
      {item.pcStatus !== '' && item.mobileStatus !== '' && (
        <Row className="detail" data-id={item.id}>
          <SplitBox className="top">
            <Item>
              <DetailIcon onClick={() => onClick(item.urlId, mobile)} />
            </Item>
          </SplitBox>
          <SplitBox>
            <Item>
              <DetailIcon onClick={() => onClick(item.urlId, pc)} />
            </Item>
          </SplitBox>
        </Row>
      )}
      {((item.pcStatus !== '' && item.mobileStatus === '') || (item.pcStatus === '' && item.mobileStatus !== '')) && (
        <Row className="detail" data-id={item.id} style={{ justifyContent: 'center' }}>
          <Item>
            <DetailIcon onClick={() => onClick(item.urlId, item.pcStatus === '' ? mobile : pc)} />
          </Item>
        </Row>
      )}
      {item.pcStatus === '' && item.mobileStatus === '' && (
        <Row className="detail" data-id={item.id} style={{ justifyContent: 'center' }}></Row>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: ${gridSize.minHeight};
  box-sizing: border-box;
  white-space: normal;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  font-size: 0.9rem;
  border-left: 1px solid ${colors.gray300};
  border-bottom: 1px solid ${colors.gray300};
  box-sizing: border-box;
  padding: 0.5rem 1rem;

  &.status,
  &.device,
  &.completionDate,
  &.detail {
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }

  &.page {
    justify-content: flex-start;
    width: 100%;
    word-break: break-all;
    min-width: ${gridSize.row.page};
    min-height: ${gridSize.minHeight};
  }

  &.date {
    width: ${gridSize.row.date};
    min-width: ${gridSize.row.date};
    max-width: ${gridSize.row.date};
  }

  &.status {
    word-break: break-all;
    width: ${gridSize.row.status};
    min-width: ${gridSize.row.status};
    max-width: ${gridSize.row.status};
    min-height: calc(${gridSize.minHeight} * 0.5);
  }

  &.device {
    width: ${gridSize.row.device};
    min-width: ${gridSize.row.device};
    max-width: ${gridSize.row.device};
  }

  &.completionDate {
    width: ${gridSize.row.completionDate};
    min-width: ${gridSize.row.completionDate};
    max-width: ${gridSize.row.completionDate};
  }

  &.detail {
    width: ${gridSize.row.detail};
    min-width: ${gridSize.row.detail};
    max-width: ${gridSize.row.detail};
  }

  &:last-child {
    border-right: 1px solid ${colors.gray300};
  }
`

const PageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const Title = styled.div`
  display: flex;
  color: ${colors.gray500};
  word-break: break-all;
  line-height: 0.9rem;
  margin-top: 0.3rem;
`

const Open = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${gridSize.icon};
  min-width: ${gridSize.icon};
  height: 100%;
  min-height: ${gridSize.minHeight};
`

const DetailIcon = styled(WatchLater).attrs({
  size: '18',
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const SplitBox = styled.div`
  display: flex;
  flex: 1 0 50%;
  align-items: center;
  height: 100%;

  &.top {
    border-bottom: 1px solid ${colors.gray300};
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
