import * as React from 'react'
import { RefObject } from 'react'
import styled from 'styled-components'

import { GlobalContext } from '../../../GlobalState'
import { Authority } from '../../../util/Authority'
import { Loading } from '../../../components/common/Loading'
import { ProjectContext } from '../../ProjectRoot'
import { CanNotViewMessage } from '../../../components/warnings/CanNotViewMessage'
import { ErrorBox } from '../../../components/common/ErrorBox'
import { useFunnelReports } from '../../../util/hooks/api/FunnelReport/useFunnelReports'
import { scrollTop } from '../../../util/scrollToElement'
import { ReportsTable } from '../ReportsTable'
import { FunnelReportContext } from '../../../contexts/FunnelReportContext'
import { FONT_COLOR_SUB } from '../../../styleConstants'
import { WarningBox } from '../../../components/warnings/WarningBox'
import { ReportsControlArea } from './ReportsControlArea'
import { useReportSettings } from '../../../util/hooks/useReportSettings'
import { NotSettingsMessages } from '../../../components/reportMessages/NotSettingsMessages'

interface Props {
  readonly mainAreaRef: RefObject<HTMLDivElement>
}

/**
 * ファネルレポート一覧ページのレポートリストエリアをレンダリングする
 *
 * @param {object} param - The input parameters for the method.
 * @param {React.RefObject} param.mainAreaRef - MainAreaの参照オブジェクト（各ページのスクロール処理に使用）
 */
export function ReportsArea({ mainAreaRef }: Props) {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { projectId, baseUrl },
  } = React.useContext(ProjectContext)

  const { searchText } = React.useContext(FunnelReportContext)

  const canView = Authority.canViewFunnelReport(AccountInfo.permissions, projectId)
  const canDelete = Authority.canDeleteFunnelReport(AccountInfo.permissions, projectId)

  const { isCompleted: isSettingsCompleted, isChecking: isSettingsChecking } = useReportSettings({
    projectId: projectId,
  })

  const { data, isLoading, isError } = useFunnelReports({
    projectId,
    enabled: canView && isSettingsCompleted,
  })

  React.useEffect(() => scrollTop(mainAreaRef), [data])

  if (!canView) return <CanNotViewMessage />
  if (!isSettingsChecking && !isSettingsCompleted)
    return (
      <NotSettingsMessages
        projectId={projectId}
        baseUrl={baseUrl}
        warningMessageComponent={<>ファネルレポートの作成は、以下の設定が完了すると可能になります。</>}
      />
    )
  if (isSettingsChecking || isLoading) return <Loading />
  if (isError || !data) return <ErrorBox>ファネルレポート一覧の取得に失敗しました。</ErrorBox>

  return (
    <>
      {data.reportLimitExceeded && (
        <WarningBox>
          組織合計でのファネルレポート作成数上限を超えているためレポートの閲覧・編集はできません。
          <br />
          組織合計で上限を超えないようファネルレポートを削除いただくか、プラン変更（アップグレード）をご検討ください。
          {!canDelete && (
            <>
              <br />
              ファネルレポート削除については、組織管理者またはプロジェクト管理者にお問い合わせください。
            </>
          )}
        </WarningBox>
      )}
      {!data.reportLimitExceeded && data.reportLimitReached && (
        <InformationText>
          組織合計でのファネルレポート作成数上限に達しました。上限数を増やしたい場合はプラン変更（アップグレード）をご検討ください。
        </InformationText>
      )}
      {!data.hasReports && searchText && (
        <WarningBox>ご指定の条件に一致するデータはありません。条件を変えて再度検索してください。</WarningBox>
      )}

      <ReportsControlArea />

      {data.hasReports && <ReportsTable />}
    </>
  )
}

const InformationText = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  color: ${FONT_COLOR_SUB};
`
