import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { CrownIcon } from '../CrownIcon'

interface Props {
  readonly rank: number
  readonly iconSize?: number
}

export function RankIcon({ rank, iconSize = 28 }: Props) {
  return (
    <CrownContainer>
      <CrownIcon size={iconSize} color={`${colors.red}`} />
      <RankOverlay iconSize={iconSize}>{rank}</RankOverlay>
    </CrownContainer>
  )
}

const CrownContainer = styled.div`
  position: relative;
  display: inline-block;
`

const RankOverlay = styled.span<{ iconSize: number }>`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${colors.white};
  font-size: ${({ iconSize }) => iconSize * (10 / 28)}px;
  font-weight: bold;
`
