import * as React from 'react'
import styled from 'styled-components'

import { BaseHeaderCell } from './BaseCell'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'
import { HeaderCellHelp } from './HeaderCellHelp'

export function ContentNameHeaderCell() {
  return (
    <Cell>
      コンテンツ名
      <HeaderCellHelp width={500}>
        <div>手動または自動付与されたコンテンツ名・タグを表示</div>
        <div>※"自動"ラベルとは？</div>
        <IndentBlock>
          <div>コンテンツ名自動付与設定機能がONで、ページキャプチャ時に名称が自動で付与されたものを表します。</div>
          <div>コンテンツ名自動付与設定は担当にて設定します。ご不明な点はお問い合わせください。</div>
          <div>自動付与ができるパターンは以下の通り。</div>
          <PatternList>
            <li>ヘッダー: ページ上部のheaderタグ</li>
            <li>フッター: ページ下部のfooterタグ</li>
            <li>セクションタイトル: h2〜h4タグのテキスト</li>
            <li>画像バナーリンク: aタグ内のimgタグのaltのテキスト</li>
            <li>付与済みコンテンツ名からコンテンツ名予測</li>
          </PatternList>
        </IndentBlock>
        <div>
          ※ページを横断して同じブロックを表示している場合、同一のコンテンツ名付与が可能な場合があります。ご希望の場合はお問い合わせください。
        </div>
      </HeaderCellHelp>
    </Cell>
  )
}

const Cell = styled(BaseHeaderCell)`
  background-color: ${CELL_BACKGROUND_COLORS.CONTENT};
`

const IndentBlock = styled.div`
  margin-left: 12px;
`

const PatternList = styled.ol`
  padding-inline-start: 24px;
  margin: 4px 0;
`
