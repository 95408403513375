import { FunnelData, FunnelReportData, FunnelStepData } from './types'

export const MAX_FUNNEL_LENGTH = 5
export const MAX_STEP_LENGTH = 9

export const MAX_REPORT_NAME_LENGTH = 100
export const MAX_FUNNEL_NAME_LENGTH = 30

export const REPORT_REGISTER_FOOTER_HEIGHT = 48

export const STEP_TYPES = {
  URL: 1,
  GOAL: 2,
} as const

export const STEP_TYPE_OPTIONS = [
  { label: '到達ページ', value: STEP_TYPES.URL },
  { label: 'ゴール', value: STEP_TYPES.GOAL },
]

export const STEP_URL_MATCH_TYPES = {
  FULL: 1,
  HEAD: 2,
  REGEXP: 3,
}

export const STEP_URL_MATCH_TYPE_OPTIONS = [
  { label: '完全一致', value: STEP_URL_MATCH_TYPES.FULL },
  { label: '前方一致', value: STEP_URL_MATCH_TYPES.HEAD },
  { label: '正規表現に一致', value: STEP_URL_MATCH_TYPES.REGEXP },
]

export const INIT_STEP: FunnelStepData = {
  name: '',
  stepType: STEP_TYPES.URL,
  stepUrl: {
    matchType: STEP_URL_MATCH_TYPES.FULL,
    url: '',
  },
  stepGoal: null,
  accessCount: null,
  accessCountScaledPercent: null,
  transitionCount: null,
  transitionRate: null,
}

export const INIT_FUNNEL: FunnelData = {
  name: '',
  stepRequired: true,
  steps: [],
  filters: [],
  filterTexts: [],
}

export const INIT_EDIT_DATA: FunnelReportData = {
  name: '',
  funnels: [INIT_FUNNEL],
}

export const SORT_KEYS = {
  NAME: 'name',
  UPDATED_AT: 'updated_at',
  UPDATED_USER: 'updated_user__username',
} as const

export const SORT_ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
} as const
