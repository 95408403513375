import * as React from 'react'
import styled from 'styled-components'
import { Modal } from '../../../../common/Modal'
import { CalenderState } from '../../../../common/DayPickerRange'
import { useContentCaptureHistoryData } from '../../../../../util/hooks/api/useContentCaptureHistoryData'
import { CaptureHistoryModalInner } from './CaptureHistoryModalInner'
import { ErrorBox } from '../../../../common/ErrorBox'

interface Props {
  open: boolean
  onClose: () => void
  contentId: number
  projectId: string
  pageId: number
  calenderState: CalenderState
}

export function CaptureHistoryModal({ open, onClose, projectId, pageId, contentId, calenderState }: Props) {
  const [errorMessage, setErrorMessage] = React.useState('')

  const { data, isLoading, isError } = useContentCaptureHistoryData({
    projectId,
    pageId,
    contentId,
    calenderState,
    setErrorMessage,
  })

  if (!data) return null

  return (
    <Modal isOpen={open} onClose={onClose} title="キャプチャ履歴" isLoading={isLoading}>
      <Box>
        {isError ? (
          <ErrorBox>{errorMessage || '情報の取得に失敗しました。'}</ErrorBox>
        ) : (
          <CaptureHistoryModalInner data={data} />
        )}
      </Box>
    </Modal>
  )
}

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px;
  min-height: 110px;
  max-height: 610px;
  height: auto;
  margin: 28px auto 14px;
  overflow-y: auto;
`
