import * as React from 'react'
import { navigate } from '@gatsbyjs/reach-router'
import { request } from '../../util/request'
import { ProjectsData, ProjectData } from '../../util/Response'

import { GoalsData } from '../../util/hooks/api/Goal'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly loading: boolean
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  fetch = async (projectId: number, setFunc: any) => {
    try {
      let id = projectId
      const projects: ProjectsData = await request('GET', `/api/projects/`, true)
      // プロジェクトリストに選択プロジェクトが含まれていない
      if (projects.results.filter((project) => project.id === Number(id)).length === 0) {
        // 初期プロジェクトは必ず存在している
        id = projects.results[0].id
        // URL書き換え
        let url = new URL(window.location.href)
        url.pathname = `/projects/${id}/report/page`
        history.replaceState(null, '', url)
        navigate(`/`)
      } else {
        const project: ProjectData = await request('GET', `/api/projects/${id}/`, true)
        const goalData: GoalsData = await request('GET', `/api/projects/${id}/goals/`, true)
        const goals: number[] = goalData.results.length === 0 ? [] : goalData.results.map((result) => result.id)

        // グローバルに情報を保存する
        await setFunc(project.name, id, goals)
        this.setState({ ...this.state, loading: false })
      }
    } catch {
      this.setState({ ...this.state, loading: false })
    }
  }
}

const initialState: State = {
  loading: true,
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
