import * as React from 'react'

import { AggregateScopes, CustomFilterState } from '../../../util/hooks/api/Filter/types'
import { CustomFilterAction, CustomFilterModalState } from '../../../contexts/CustomFilterContext/state'
import { CustomFilter } from '../../../components/filter/CustomFilter'
import { AGGREGATE_SCOPES, aggregateScopeOptions } from '../../../util/hooks/api/Filter/constants'
import { deepCopy } from '../../../util/copy'

const TARGET_SCOPE_OPTION_VALUES: AggregateScopes[] = [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION]
const TARGET_SCOPE_OPTIONS = aggregateScopeOptions.filter((option) => TARGET_SCOPE_OPTION_VALUES.includes(option.value))

interface Props {
  readonly opened: boolean
  readonly setOpened: (value: boolean) => void
  readonly filters: CustomFilterState[] | null
  readonly setFilters: (state: CustomFilterState[]) => void
}

export function EditCustomFilterModal({ opened, setOpened, filters, setFilters }: Props) {
  if (!filters) return null

  const [state, setState] = React.useState<CustomFilterModalState>({
    customFilterState: filters,
    // tempCustomFilterStateは編集用のオブジェクトのため、ディープコピーでセットする
    // see. https://github.com/uncovertruth/content-analytics/issues/2590#issuecomment-2262142490
    tempCustomFilterState: deepCopy(filters),
    openedFilter: opened,
    selectsData: undefined,
  })
  const actions = new CustomFilterAction(state, setState)

  React.useEffect(() => {
    if (state.customFilterState === filters) return
    setFilters(state.customFilterState)
  }, [state.customFilterState])

  React.useEffect(() => {
    setOpened(state.openedFilter)
  }, [state.openedFilter])

  return <CustomFilter state={state} actions={actions} scopeOptions={TARGET_SCOPE_OPTIONS} />
}
