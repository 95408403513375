import React from 'react'
import styled from 'styled-components'
import { Select } from '../common/Select'

interface Props {
  readonly listItemsCount: number
  readonly choices: readonly number[]
  readonly itemCountAll: number
  readonly itemCountFrom: number
  readonly itemCountTo: number
  readonly onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

/**
 * リストアイテムの表示件数を変更するセレクトボックス・現在の選択件数をレンダリングする
 *
 * @param {Object} props - The component props.
 * @param {number} props.listItemsCount - 表示するアイテムの件数
 * @param {number[]} props.choices - 選択可能な表示件数の選択肢
 * @param {number} props.itemCountAll - 表示するアイテムの総数
 * @param {number} props.itemCountFrom - 表示するアイテムの開始インデックス
 * @param {number} props.itemCountTo - 表示するアイテムの終了インデックス
 * @param {Function} props.onChange - 表示件数変更時のハンドラ関数
 */
export function ListItemsCount({ listItemsCount, choices, itemCountAll, itemCountFrom, itemCountTo, onChange }: Props) {
  const options = choices.map((num) => ({
    label: num.toLocaleString(),
    value: num,
  }))

  return (
    <Container>
      <SelectLabel>
        <SelectText>表示する件数:</SelectText>
        <DesignedSelect options={options} value={listItemsCount} onChange={onChange} />
      </SelectLabel>
      <Count>
        {`${itemCountFrom.toLocaleString()}～${itemCountTo.toLocaleString()}/${itemCountAll.toLocaleString()}件`}
      </Count>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.5;
`

const SelectLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SelectText = styled.div``

const DesignedSelect = styled(Select)`
  margin-left: 4px;
  padding: 0;
  width: 65px;
  height: 30px;
  font-size: 14px;
`

const Count = styled.div`
  margin-left: 16px;
`
