import React from 'react'
import styled from 'styled-components'
import { BorderCircleButtonDelete } from '../../common/CircleButton'

interface Props {
  openDeleteModal: () => void
  disabled?: boolean
}

export function DeleteButton({ openDeleteModal, disabled }: Props) {
  return (
    <DeleteButtonArea>
      <DeleteButtonContent>
        <BorderCircleButtonDelete onClick={openDeleteModal} disabled={disabled} />
      </DeleteButtonContent>
    </DeleteButtonArea>
  )
}

const DeleteButtonArea = styled.div`
  position: relative;
`

const DeleteButtonContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
