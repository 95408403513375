import { useQuery } from '@tanstack/react-query'

import { request } from '../../../request'
import { DEVICE_TYPES_FOR_API } from '../../useDeviceType'
import { PageReportsAvailablePageLayoutsData } from '../../../Response'
import { AVAILABLE_PAGE_LAYOUTS_QUERY_KEY } from '../constants'

export interface AvailablePageLayouts {
  readonly mobileAvailable: boolean
  readonly pcAvailable: boolean
  readonly isExistsUrls: boolean
}

interface Props {
  readonly projectId: number
}

export const useAvailablePageLayouts = ({ projectId }: Props) => {
  const queryKey = [AVAILABLE_PAGE_LAYOUTS_QUERY_KEY, { projectId }]

  const queryResult = useQuery({
    queryKey: queryKey,
    queryFn: () => fetch(projectId),
    select: transform,
    enabled: !!projectId,
    staleTime: 1000 * 60 * 5,
  })

  return {
    ...queryResult,
  }
}

const fetch = async (projectId: number) => {
  return await request<PageReportsAvailablePageLayoutsData>(
    'GET',
    `/api/projects/${projectId}/page_reports/available_page_layouts/`,
    true,
  )
}

const transform = (response: PageReportsAvailablePageLayoutsData): AvailablePageLayouts => {
  const mobileAvailable = response.results.filter((item) => item.page_layout === DEVICE_TYPES_FOR_API.mobile).length > 0
  const pcAvailable = response.results.filter((item) => item.page_layout === DEVICE_TYPES_FOR_API.pc).length > 0

  return {
    mobileAvailable,
    pcAvailable,
    isExistsUrls: response.is_exists_urls,
  }
}
