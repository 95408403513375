import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { colors, layout } from '../styleConstants'
import { Authority } from '../util/Authority'
import { GlobalContext } from '../GlobalState'
import { usePageState } from './Settings/state'
import { SettingModals } from './Settings/SettingModals'
import { PageLayout } from '../components/layout/PageLayout'
import { Loading } from '../components/common/Loading'
import { GoalList } from '../components/list/GoalList'
import { PageHeader } from '../components/common/PageHeader'
import { MemberList } from '../components/list/MemberList'
import { QueryList } from '../components/list/QueryList'
import { DimensionList } from '../components/list/DimensionList'
import { IpAddressList } from '../components/list/IpAddressList'
import { CrossDomainList } from '../components/list/CrossDomainList'
import { UserList } from '../components/list/UserList'
import { SiteResponsiveLayout } from '../components/layout/SiteResponsiveLayout'
import { ChevronThinUp, ChevronThinDown } from '@styled-icons/entypo'
import { FlagOutline } from '@styled-icons/evaicons-outline/FlagOutline'
import { Users } from '@styled-icons/heroicons-outline/Users'
import { QueryStats } from '@styled-icons/material/QueryStats'
import { FilterAlt } from '@styled-icons/boxicons-regular/FilterAlt'
import { Filter } from '@styled-icons/bootstrap/Filter'
import { Domain } from '@styled-icons/material/Domain'
import { DocumentWidth } from '@styled-icons/fluentui-system-regular/DocumentWidth'
import { UserPlus } from '@styled-icons/boxicons-regular/UserPlus'
import { AccordionBox } from '../components/common/AccordionBox'
import { ProjectInfo } from '../components/ProjectSettings/ProjectInfo'

interface SettingsProps extends RouteComponentProps {
  projectId?: string
  category?: string // 初期表示されている項目を指定
}

export function Settings(props: SettingsProps) {
  const {
    state: { AccountInfo },
    actions,
  } = React.useContext(GlobalContext)
  const { state, pageActions } = usePageState()

  // ライブラリの関数を直接実行
  const panelRefs = React.useRef<any[]>([])

  const {
    loading,
    reload,
    project,
    goal,
    query,
    trackingCode,
    member,
    admins,
    dimension,
    ipAddress,
    crossDomain,
    siteResponsive,
    invite,
    projectErrorMessage,
    goalErrorMessage,
    queryErrorMessage,
    memberErrorMessage,
    dimensionErrorMessage,
    ipAddressErrorMessage,
    crossDomainErrorMessage,
    siteResponsiveErrorMessage,
    inviteErrorMessage,
    errorMessage,
  } = state

  const errors = [
    projectErrorMessage,
    goalErrorMessage,
    queryErrorMessage,
    memberErrorMessage,
    dimensionErrorMessage,
    ipAddressErrorMessage,
    crossDomainErrorMessage,
    siteResponsiveErrorMessage,
    inviteErrorMessage,
    errorMessage,
  ]

  React.useEffect(() => {
    if (loading) {
      pageActions.fetch(props.projectId!)
    }
  }, [])
  React.useEffect(() => {
    if (reload) {
      pageActions.fetch(props.projectId!)
    }
  }, [reload])

  React.useEffect(() => {
    if (project.updated) {
      actions.setName('', project.name)
      pageActions.resetState()
    }
  }, [project.updated])

  React.useEffect(() => {
    // ライブラリの関数を直接実行
    panelRefs.current.forEach((ref) => {
      ref?.calcHeight()
    })
  }, [...errors])

  const baseUrl = props.uri?.substring(0, props.uri?.indexOf('settings'))

  return (
    <div>
      {(loading || reload) && <Loading />}
      <SettingModals state={state} actions={pageActions} />
      <PageLayout
        headerTitle={`プロジェクト設定 ${props.category ? `| ${pageActions.getCategoryTitle(props.category)}` : ''}`}
        optionHidden={true}
        baseUrl={baseUrl}
      >
        <PageHeader
          title={`プロジェクト設定 | ${AccountInfo.projectName} | ${AccountInfo.teamName}チーム`}
          description={`プロジェクト設定 | ${AccountInfo.projectName} | ${AccountInfo.teamName}チーム`}
        />
        {!Authority.canViewProjectSettings(AccountInfo.permissions, props.projectId!) && (
          <div style={{ margin: '1rem 0 0 1rem' }}>権限がないため表示できません。</div>
        )}
        {!loading && !reload && Authority.canViewProjectSettings(AccountInfo.permissions, props.projectId!) && (
          <Container>
            <div>
              {errorMessage && (
                <ErrorMessage style={{ marginBottom: '2rem' }} data-testid="error-message">
                  {errorMessage}
                </ErrorMessage>
              )}
              {(props.category === 'project' || props.category === 'trackingcode') && (
                <ProjectInfo
                  projectId={props.projectId || ''}
                  category={props.category || ''}
                  projectErrorMessage={projectErrorMessage}
                  project={project}
                  pageActions={pageActions}
                  AccountInfo={AccountInfo}
                  trackingCode={trackingCode}
                />
              )}
              {props.category === 'goal' && (
                <AccordionBox title="ゴール一覧" icon={<FlagOutline />} accordionProps={{ defaultExpanded: true }}>
                  {goalErrorMessage && <ErrorMessage data-testid="goal-error-message">{goalErrorMessage}</ErrorMessage>}
                  <GoalList
                    goals={goal.goals}
                    selectedGoalId={goal.selectedId}
                    canAddGoal={Authority.canAddGoal(AccountInfo.permissions, props.projectId!)}
                    canChangeGoal={Authority.canChangeGoal(AccountInfo.permissions, props.projectId!)}
                    canDeleteGoal={Authority.canDeleteGoal(AccountInfo.permissions, props.projectId!)}
                    onCreate={pageActions.goal.onCreate}
                    onUrlEdit={pageActions.goal.onUrlEdit}
                    onEventEdit={pageActions.goal.onEventEdit}
                    onDelete={pageActions.goal.onDelete}
                  />
                </AccordionBox>
              )}
              {props.category === 'ip_address' && (
                <AccordionBox title="IPアドレスフィルター" icon={<Filter />} accordionProps={{ defaultExpanded: true }}>
                  {ipAddressErrorMessage && (
                    <ErrorMessage data-testid="ipAddress-error-message">{ipAddressErrorMessage}</ErrorMessage>
                  )}
                  <IpAddressList
                    ipAddresses={ipAddress.ipAddresses}
                    canAddIpAddressFilter={Authority.canAddIpAddressFilter(AccountInfo.permissions, props.projectId!)}
                    canChangeIpAddressFilter={Authority.canChangeIpAddressFilter(
                      AccountInfo.permissions,
                      props.projectId!,
                    )}
                    canDeleteIpAddressFilter={Authority.canDeleteIpAddressFilter(
                      AccountInfo.permissions,
                      props.projectId!,
                    )}
                    onCreate={pageActions.ipAddress.onCreate}
                    onEdit={pageActions.ipAddress.onEdit}
                    onDelete={pageActions.ipAddress.onDelete}
                  />
                </AccordionBox>
              )}
              {props.category === 'site_responsive' && (
                <AccordionBox
                  title="デバイスのPC・Mobileレポートへの振り分け設定"
                  icon={<DocumentWidth />}
                  accordionProps={{ defaultExpanded: true }}
                >
                  {siteResponsiveErrorMessage && (
                    <ErrorMessage data-testid="siteResponsive-error-message">{siteResponsiveErrorMessage}</ErrorMessage>
                  )}
                  <SiteResponsiveLayout
                    disabled={siteResponsive.disabled}
                    isReset={siteResponsive.isReset}
                    isUserAgent={siteResponsive.isUserAgent}
                    mobileMaxWidth={siteResponsive.mobileMaxWidth}
                    tabletMinWidth={siteResponsive.tabletMinWidth}
                    tabletMaxWidth={siteResponsive.tabletMaxWidth}
                    pcMinWidth={siteResponsive.pcMinWidth}
                    canChangeSiteResponsive={Authority.canChangeSiteResponsive(
                      AccountInfo.permissions,
                      props.projectId!,
                    )}
                    onClick={pageActions.siteResponsive.onClick}
                    onChangeMobileWidth={pageActions.siteResponsive.onChangeMobileWidth}
                    onChangePcWidth={pageActions.siteResponsive.onChangePcWidth}
                    onReset={pageActions.siteResponsive.onReset}
                    onSave={pageActions.siteResponsive.onSave}
                  />
                </AccordionBox>
              )}
              {props.category === 'query' && (
                <AccordionBox
                  title="許可するクエリーパラメータ名"
                  icon={<QueryStats />}
                  accordionProps={{ defaultExpanded: true }}
                >
                  {queryErrorMessage && (
                    <ErrorMessage data-testid="query-error-message">{queryErrorMessage}</ErrorMessage>
                  )}
                  <QueryList
                    queries={query.queries}
                    canAddUrlParameter={Authority.canAddUrlParameter(AccountInfo.permissions, props.projectId!)}
                    canChangeUrlParameter={Authority.canChangeUrlParameter(AccountInfo.permissions, props.projectId!)}
                    canDeleteUrlParameter={Authority.canDeleteUrlParameter(AccountInfo.permissions, props.projectId!)}
                    onAdd={pageActions.query.onAdd}
                    onEdit={pageActions.query.onEdit}
                    onDelete={pageActions.query.onDelete}
                  />
                </AccordionBox>
              )}
              {props.category === 'cross_domain' && (
                <AccordionBox title="クロスドメイン設定" icon={<Domain />} accordionProps={{ defaultExpanded: true }}>
                  {crossDomainErrorMessage && (
                    <ErrorMessage data-testid="crossDomain-error-message">{crossDomainErrorMessage}</ErrorMessage>
                  )}
                  <CrossDomainList
                    crossDomains={crossDomain.crossDomains}
                    canAddCrossDomain={Authority.canAddCrossDomain(AccountInfo.permissions, props.projectId!)}
                    canChangeCrossDomain={Authority.canChangeCrossDomain(AccountInfo.permissions, props.projectId!)}
                    canDeleteCrossDomain={Authority.canDeleteCrossDomain(AccountInfo.permissions, props.projectId!)}
                    onAdd={pageActions.crossDomain.onAdd}
                    onEdit={pageActions.crossDomain.onEdit}
                    onDelete={pageActions.crossDomain.onDelete}
                  />
                </AccordionBox>
              )}
              {props.category === 'dimension' && (
                <AccordionBox
                  title="カスタムディメンション設定"
                  icon={<FilterAlt />}
                  accordionProps={{ defaultExpanded: true }}
                >
                  {dimensionErrorMessage && (
                    <ErrorMessage data-testid="dimension-error-message">{dimensionErrorMessage}</ErrorMessage>
                  )}
                  <DimensionList
                    dimensions={dimension.dimensions}
                    remained={dimension.remained}
                    canAddCustomDimension={Authority.canAddCustomDimension(AccountInfo.permissions, props.projectId!)}
                    canChangeCustomDimension={Authority.canChangeCustomDimension(
                      AccountInfo.permissions,
                      props.projectId!,
                    )}
                    onAdd={pageActions.dimension.onAdd}
                    onEdit={pageActions.dimension.onEdit}
                  />
                </AccordionBox>
              )}
              {(props.category === 'member' || props.category === 'invite') && (
                <AccordionBoxes>
                  <AccordionBox
                    title="プロジェクトメンバー管理"
                    icon={<Users />}
                    accordionProps={{ defaultExpanded: props.category === 'member' }}
                  >
                    {memberErrorMessage && (
                      <ErrorMessage data-testid="member-error-message">{memberErrorMessage}</ErrorMessage>
                    )}
                    <MemberList
                      members={member.members}
                      admins={admins}
                      canAddProjectMember={Authority.canAddProjectMember(AccountInfo.permissions, props.projectId!)}
                      canChangeProjectMember={Authority.canChangeProjectMember(
                        AccountInfo.permissions,
                        props.projectId!,
                      )}
                      canDeleteProjectMember={Authority.canDeleteProjectMember(
                        AccountInfo.permissions,
                        props.projectId!,
                      )}
                      canViewTeamSettings={Authority.canViewTeamSettings(AccountInfo.permissions)}
                      onAdd={pageActions.member.onAdd}
                      onEdit={pageActions.member.onEdit}
                      onDelete={pageActions.member.onDelete}
                    />
                  </AccordionBox>
                  <AccordionBox title="招待中のユーザー" icon={<UserPlus />} accordionProps={{ defaultExpanded: true }}>
                    {inviteErrorMessage && (
                      <ErrorMessage data-testid="invite-error-message">{inviteErrorMessage}</ErrorMessage>
                    )}
                    <UserList
                      inviteUsers={invite.users}
                      canChangeTeamMember={Authority.canDeleteProjectMember(AccountInfo.permissions, props.projectId!)}
                      invited
                      onRevoke={pageActions.invite.onRevoke}
                    />
                  </AccordionBox>
                </AccordionBoxes>
              )}
              <Padding />
            </div>
          </Container>
        )}
      </PageLayout>
    </div>
  )
}

const Container = styled.div`
  width: ${layout.settingPageWidth};
  height: 100%;
  padding-top: 2rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    height: calc(100vh - ${layout.headerHeight});
    min-height: calc(100vh - ${layout.headerHeight});
  }
`

export const HeaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.headerBg};
  background-color: ${colors.white};
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 1rem 3rem;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.headerBg};
`

export const Up = styled(ChevronThinUp).attrs({
  size: '40',
  color: `${colors.headerBg}`,
})``

export const Down = styled(ChevronThinDown).attrs({
  size: '40',
  color: `${colors.headerBg}`,
})``

const Padding = styled.div`
  min-height: 3rem;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`

const AccordionBoxes = styled.div`
  > :nth-child(n + 2) {
    margin-top: 28px;
  }
`
