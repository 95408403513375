import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { DeviceTypeForAPI } from '../../util/hooks/useDeviceType'
import { CaptureHistoryGridItem, CaptureHistoryItem, gridSize } from './CaptureHistoryGridItem'

interface Props {
  readonly items: CaptureHistoryItem[]
  readonly selectedId?: number
  readonly onClick: (id: string, pageLayout: DeviceTypeForAPI) => void
}

export function CaptureHistoryGrid(props: Props) {
  const { items, selectedId, onClick } = props

  const element = items.map((item, index) => {
    return (
      <Li data-id={item.id} key={index} className={selectedId === item.id ? 'on' : 'off'}>
        <CaptureHistoryGridItem item={item} onClick={onClick} />
      </Li>
    )
  })

  return (
    <Container>
      <Header>
        {/* ページ */}
        <Row className="page">
          <HeaderContents>ページ</HeaderContents>
        </Row>

        {/* リクエスト日時 */}
        <Row className="date">
          <HeaderContents>リクエスト日時</HeaderContents>
        </Row>

        {/* デバイス */}
        <Row className="device">
          <HeaderContents>デバイス</HeaderContents>
        </Row>

        {/* ステータス */}
        <Row className="status">
          <HeaderContents>ステータス</HeaderContents>
        </Row>

        {/* 取得完了日時 */}
        <Row className="completionDate">
          <HeaderContents>取得完了日時</HeaderContents>
        </Row>

        {/* 詳細 */}
        <Row className="detail">
          <HeaderContents>詳細</HeaderContents>
        </Row>
      </Header>

      {/* リスト表示 */}
      <Ul>{element}</Ul>
    </Container>
  )
}

const Container = styled.div`
  min-width: ${gridSize.minWidth};
  background-color: ${colors.white};
`

const Header = styled.header`
  display: flex;
  align-items: center;
  height: ${gridSize.headerHeight};
  box-sizing: border-box;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  border-top: 1px solid ${colors.gray300};
  border-bottom: 1px solid ${colors.gray300};
  border-left: 1px solid ${colors.gray300};
  background-color: ${colors.lightCyan};
  box-sizing: border-box;

  &.page {
    justify-content: flex-start;
    padding-left: 1rem;
    width: 100%;
    min-width: ${gridSize.row.page};
  }

  &.date {
    width: ${gridSize.row.date};
    min-width: ${gridSize.row.date};
    max-width: ${gridSize.row.date};
  }

  &.device {
    flex-direction: column;
    width: ${gridSize.row.device};
    min-width: ${gridSize.row.device};
    max-width: ${gridSize.row.device};
  }

  &.status {
    flex-direction: column;
    width: ${gridSize.row.status};
    min-width: ${gridSize.row.status};
    max-width: ${gridSize.row.status};
  }

  &.completionDate {
    flex-direction: column;
    width: ${gridSize.row.completionDate};
    min-width: ${gridSize.row.completionDate};
    max-width: ${gridSize.row.completionDate};
  }

  &.detail {
    width: ${gridSize.row.detail};
    min-width: ${gridSize.row.detail};
    max-width: ${gridSize.row.detail};
    border-right: 1px solid ${colors.gray300};
  }
`

const HeaderContents = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
`

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  background-color: ${colors.white};
  box-sizing: border-box;

  &.off {
    background-color: ${colors.white};
  }

  &:hover {
    background-color: ${colors.gray50};
  }

  &.on {
    background-color: ${colors.gray200};
  }
`
