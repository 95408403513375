import * as React from 'react'
import styled from 'styled-components'
import { GRID_MIN_WIDTH } from '.'
import { WarningBigBox } from '../common/WarningBigBox'

interface Props {
  readonly message: string
}

export function WarningArea({ message }: Props) {
  return (
    <Container>
      <WarningBigBox>{message}</WarningBigBox>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-width: ${GRID_MIN_WIDTH}px;
`
