import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../styleConstants'
import { DeviceTypeForAPI } from '../../util/hooks/useDeviceType'
import { usePageState } from './state/CaptureHistoryState'
import { NormalButton } from '../common/Button'
import { Checkbox } from '../common/Checkbox'
import { Select } from '../common/Select'
import { Paginate } from '../common/Paginate'
import { ScreenshotBox } from '../CaptureHistory/ScreenshotBox'
import { useResize } from '../../util/hooks/useResize'
import { Modal } from '../common/Modal'
import { ErrorBox } from '../common/ErrorBox'
import { StatusItem } from '../CaptureHistory/StatusItem'

const Layout = {
  width: '1040px',
  height: '700px',
  closeX: '500px',
  closeY: '-360px',
  headerHeight: '60px',
  boxHeight: '640px',
  item: {
    width: '690px',
    execDate: '170px',
    completeDate: '170px',
    user: '180px',
    status: '120px',
  },
  screenshot: {
    width: '300px',
  },
  grid: {
    execDate: '100px',
  },
}

interface Props {
  readonly opened: boolean
  readonly projectId: number
  readonly urlId: number
  readonly pageLayout: DeviceTypeForAPI
  readonly baseUrl?: string
  readonly onClose?: () => void
  readonly onCapture?: (success: boolean) => void
}

export function CaptureHistory(props: Props) {
  const { opened, projectId, urlId, onClose, onCapture, pageLayout } = props
  const {
    state: {
      loading,
      reload,
      pageChange,
      selectedId,
      checked,
      dispCount,
      currentPage,
      pageCount,
      itemCount,
      options,
      histories,
      disabledCapture,
      errorMessage,
    },
    actions,
  } = usePageState()

  useResize()

  React.useEffect(() => {
    if (loading) {
      actions.fetch(projectId, urlId, pageLayout)
    }
  }, [])
  React.useEffect(() => {
    if (reload) {
      actions.refetch()
    }
  }, [reload])
  React.useEffect(() => {
    if (pageChange) {
      actions.fetchNextPage()
    }
  }, [pageChange])

  // インデックス計算
  const topIndex = 1 + currentPage * dispCount
  const maxIndex = itemCount < topIndex + dispCount - 1 ? itemCount : topIndex + dispCount - 1

  return (
    <div>
      <Modal
        title="キャプチャ履歴詳細"
        width={1040}
        isOpen={opened}
        onClose={() => {
          if (!(opened && !loading && !reload && !pageChange)) return
          onClose && onClose()
        }}
        isLoading={loading || reload || pageChange}
      >
        <Container>
          <Box>
            <Contents style={{ width: histories.length === 0 ? '100%' : '' }}>
              {/* エラーメッセージ */}
              {errorMessage && <ErrorBox data-testid="error-message">{errorMessage}</ErrorBox>}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '1rem',
                }}
              >
                <NormalButton
                  disabled={disabledCapture}
                  onClick={() => {
                    actions.onCapture().then((result) => onCapture!(result))
                  }}
                >
                  ページのキャプチャリクエスト
                </NormalButton>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '0.5rem',
                }}
              >
                <Checkboxes onClick={actions.onClickCheckbox}>
                  <Checkbox checked={checked} style={{ width: '16px', height: '16px', marginRight: '0.5rem' }} />
                  <div style={{ fontSize: '0.9rem' }}>自動取得の履歴を表示しない</div>
                </Checkboxes>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    visibility: histories.length === 0 ? 'hidden' : 'visible',
                  }}
                >
                  <div style={{ fontSize: '0.8rem' }}>表示する件数：</div>
                  <DesignedSelect options={options} value={dispCount} onChange={actions.onChangeSelect} />
                  <div
                    style={{
                      fontSize: '0.8rem',
                      marginLeft: '1rem',
                    }}
                  >{`${topIndex}～${maxIndex}/${itemCount}件`}</div>
                </div>
              </div>

              {histories.length === 0 ? (
                <LoadingContainer>
                  <div style={{ fontSize: '1.5rem' }}>ページキャプチャ履歴がありません。</div>
                </LoadingContainer>
              ) : (
                <Items>
                  <Item className="title" style={{ paddingBottom: '0.5rem' }}>
                    <ItemBox className="title" style={{ width: `${Layout.item.execDate}` }}>
                      リクエスト日時
                    </ItemBox>
                    <ItemBox className="title" style={{ width: `${Layout.item.completeDate}` }}>
                      取得完了日時
                    </ItemBox>
                    <ItemBox className="title" style={{ width: `${Layout.item.user}` }}>
                      実行ユーザー名
                    </ItemBox>
                    <ItemBox className="title" style={{ width: `${Layout.item.status}` }}>
                      ステータス
                    </ItemBox>
                  </Item>
                  {histories.map((history, index) => {
                    return (
                      <Item
                        className={cc([
                          selectedId === history.id ? 'on' : 'off',
                          history.completeDate === '' ? 'error' : '',
                        ])}
                        key={`history-${index}`}
                        onClick={
                          history.completeDate === '' ? () => {} : () => actions.onClickItem(history.id, history.url)
                        }
                      >
                        <ItemBox style={{ width: `${Layout.item.execDate}` }}>{history.execDate}</ItemBox>
                        <ItemBox style={{ width: `${Layout.item.completeDate}` }}>{history.completeDate}</ItemBox>
                        <ItemBox style={{ width: `${Layout.item.user}` }}>{history.user}</ItemBox>
                        <ItemBox style={{ width: `${Layout.item.status}` }}>
                          <StatusItem
                            status={history.status}
                            errorType={history.errorType}
                            responseErrorHttpStatusCode={history.responseErrorHttpStatusCode}
                          />
                        </ItemBox>
                      </Item>
                    )
                  })}
                </Items>
              )}

              {histories.length !== 0 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50px',
                    marginTop: '0.5rem',
                  }}
                >
                  <Paginate
                    initialPage={currentPage}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    disableInitialCallback={false}
                    onPageChange={actions.onPageChange}
                  />
                </div>
              )}
            </Contents>

            {!loading && !reload && !pageChange && histories.length !== 0 && (
              <ScreenshotArea>
                <ScreenshotBox histories={histories} selectedId={selectedId} />
              </ScreenshotArea>
            )}
          </Box>
        </Container>
      </Modal>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${Layout.width};
  height: ${Layout.boxHeight};
  padding: 0 1.5rem;
`

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: ${Layout.item.width};
  height: 100%;
`

const Checkboxes = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

const Items = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
  border: 1px solid ${colors.gray300};
  overflow-y: auto;
  box-sizing: border-box;
`

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  align-items: center;
  padding: 1.5rem 0;
  box-sizing: border-box;
  border-top: 1px solid ${colors.gray300};
  border-left: 1px solid ${colors.white};
  border-right: 1px solid ${colors.white};

  &:not(.title):not(.error) {
    cursor: pointer;
  }

  &:first-child {
    border-top: none;
  }

  &:hover:not(.title) {
    background-color: ${colors.gray50};
    &.on {
      background-color: ${colors.contentOrange.orange1};
    }
  }

  &.on {
    border-top: 1px solid ${colors.orange};
    border-left: 1px solid ${colors.orange};
    border-right: 1px solid ${colors.orange};
    background-color: ${colors.contentOrange.orange1};
  }

  &.on + & {
    border-top: 1px solid ${colors.orange};
  }

  &.on:last-child {
    border-bottom: 1px solid ${colors.orange};
  }
`

const ItemBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;
  color: ${colors.black};
  font-size: 0.9rem;

  & + & {
    margin-left: 1rem;
  }

  &.title {
    color: ${colors.gray500};
  }
`

const ScreenshotArea = styled.div`
  width: ${Layout.screenshot.width};
  height: 100%;
`

const DesignedSelect = styled(Select)`
  width: 60px;
  height: 25px;
  font-size: 1rem;
  padding: 0;
`
