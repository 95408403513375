import * as React from 'react'
import { Redirect, RouteComponentProps } from '@gatsbyjs/reach-router'
import { usePageState } from './Dashboard/state'
import { isURL } from '../util/isURL'

export function Dashboard(props: RouteComponentProps) {
  const {
    state: { loading, logined, projectId },
    actions,
  } = usePageState()
  if (loading) {
    actions.check()
    return <div />
  }

  // cookieにパラメータ保存
  actions.saveCookie()

  // ログインできていない場合はログインページへ
  if (!logined) {
    return <Redirect to="/login" noThrow />
  }

  // 遷移先ページが指定されているか
  const path = props.location?.search
  if (path) {
    const next = path.replace('?next=', '')
    // 指定されている遷移先がURLかをチェック
    if (!isURL(next)) {
      return <Redirect to={`${next}`} noThrow />
    }
  }

  // 初回訪問パラメータを確認
  if (props.location!.state! && 'firstVisit' in (props.location!.state! as any)) {
    return <Redirect to={`/projects/${projectId}/report/page`} state={{ firstVisit: true }} noThrow />
  }

  return <Redirect to={`/projects/${projectId}/report/page`} noThrow />
}
