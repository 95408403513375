import { CHOICE_DATASET_KEYS } from '../api/PageReport/TimelineReport/constants'
import { ChoiceDatasetKey } from '../api/PageReport/TimelineReport/types'
import { PAGE_REPORT_TIMELINE_FIRST_CHOICE_COOKIE_KEY } from './constants'
import { useCookieState } from './useCookieState'

/**
 * 時系列グラフの1つ目の選択肢を取得するhook
 *
 * @returns {object} - The first choice of the timeline.
 */
export const useTimelineFirstChoice = () => {
  const [firstChoice, setFirstChoice] = useCookieState<ChoiceDatasetKey>({
    cookieKey: PAGE_REPORT_TIMELINE_FIRST_CHOICE_COOKIE_KEY,
    initialValue: CHOICE_DATASET_KEYS.VIEW_COUNT,
  })
  return { firstChoice, setFirstChoice }
}
