import * as React from 'react'
import styled from 'styled-components'

type TabPanelProps = {
  children?: React.ReactNode
  index: number
  value: number
}

export const TabPanel: React.VFC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index } = props
  return (
    <Container role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
`
