import * as React from 'react'
import styled from 'styled-components'
import { ContentGroup } from '../../../../util/hooks/api/useContentGroupReport'
import { ContentsSummary, PageSummary, SortItemKey, SortState } from '../../../../util/hooks/api/useContentReport'
import { ContentGroupGridHeader } from './ContentGroupGridHeader'
import { GridContentsListArea } from '../GridContentsListArea'
import { ContentGroupGridItem } from './ContentGroupGridItem'
import { GridArea } from '../GridArea'
import { QuadrantChartItem } from '../ChartPanel/QuadrantChart'
import { ChartPanel } from '../ChartPanel'
import { HelpLink } from '../../../common/HelpLink'
import { GRID_MIN_WIDTH } from '../..'
import { colors, FONT_COLOR_SUB, GRID_BORDER_COLOR } from '../../../../styleConstants'
import { HELP_LINKS } from '../../../../constants'

interface Props {
  readonly pageSummary: PageSummary
  readonly contentGroups: ContentGroup[]
  readonly contentsSummary: ContentsSummary
  readonly selectedIds: number[]
  readonly sortState: SortState
  readonly gridScrollTo: number | null
  readonly onClick: React.MouseEventHandler<HTMLDivElement>
  readonly onSort: (key: SortItemKey) => void
  readonly onReset?: () => void
  readonly handleChartItemsSelect: (ids: number[]) => void
  readonly isManagedContentExists: boolean
}

export function ContentGroupGrid({
  pageSummary,
  contentGroups,
  contentsSummary,
  selectedIds,
  sortState,
  gridScrollTo,
  onClick,
  onSort,
  onReset,
  handleChartItemsSelect,
  isManagedContentExists,
}: Props) {
  const quadrantChartItems: QuadrantChartItem[] = contentGroups.map((item) => ({
    id: item.id,
    name: item.name,
    viewRate: item.viewRate,
    goalRate: item.goalRate,
    selected: selectedIds.includes(item.id),
  }))

  return (
    <GridArea pageSummary={pageSummary}>
      <ChartPanel
        items={quadrantChartItems}
        contentsSummaryViewRate={contentsSummary.viewRate}
        pageSummaryConversionsRate={pageSummary.conversionsRate}
        handleChartItemsSelect={handleChartItemsSelect}
      />

      <ContentGroupGridHeader contentsSummary={contentsSummary} sortState={sortState} onSort={onSort} />

      {contentGroups.length > 0 && (
        <GridContentsListArea gridScrollTo={gridScrollTo} contents={contentGroups} onReset={onReset}>
          {contentGroups.map((item) => (
            <ContentGroupGridItem
              key={item.id}
              item={item}
              onClick={onClick}
              selected={selectedIds.includes(item.id)}
            />
          ))}
        </GridContentsListArea>
      )}
      {!isManagedContentExists && (
        <NoDataMessageArea>
          <MessageArea>
            <Message>コンテンツ名登録がされていません。</Message>
            <HelpLink title={'コンテンツ名登録とは'} link={HELP_LINKS.CONTENT_NAME_REGISTRATION} />
          </MessageArea>
        </NoDataMessageArea>
      )}
    </GridArea>
  )
}

const NoDataMessageArea = styled.div`
  min-height: 70px;
  width: 100%;
  min-width: ${GRID_MIN_WIDTH}px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const MessageArea = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: ${GRID_BORDER_COLOR};
  padding: 0 8px;
  background-color: ${colors.white};
`

const Message = styled.div`
  font-size: 14px;
  color: ${FONT_COLOR_SUB};
`
