import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../../common/Modal'
import { CancelButton, NormalButton } from '../../common/Button'
import { Input } from '../../common/Input'
import { WarningBox } from '../../common/WarningBox'
import {
  UpdateMultipleContentResponse,
  useUpdateMultipleContent,
} from '../../../util/hooks/api/useUpdateMultipleContent'
import { Content } from '../../../util/hooks/api/useContentReport'

interface State {
  readonly projectId: string
  readonly selectedContents: Content[]
  readonly onClose: () => void
  readonly onSuccess: (response: UpdateMultipleContentResponse) => void
}

export function MultipleContentRegister({ projectId, selectedContents, onClose, onSuccess }: State) {
  const { initialName, isAllMatched } = React.useMemo(() => getInitialName(selectedContents), [selectedContents])
  const [contentName, setContentName] = React.useState(initialName)
  const [errorMessage, setErrorMessage] = React.useState('')

  const { mutate, isLoading } = useUpdateMultipleContent({
    projectId,
    options: {
      onSuccess,
      onError: (e: string) => {
        setErrorMessage(typeof e === 'string' ? e : 'コンテンツ情報の編集に失敗しました。')
      },
    },
  })

  const onClickSaveButton = () => {
    mutate({
      name: contentName,
      partial_content_ids: selectedContents.map((content) => content.id),
    })
  }

  return (
    <Modal isOpen={true} onClose={onClose} title="コンテンツ名一括編集" isLoading={isLoading} width={800}>
      <ModalContentWrapper>
        <Container>
          {errorMessage && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}
          <ContentText>
            選択中のコンテンツ <SelectedContentCount>{selectedContents.length}</SelectedContentCount>個
          </ContentText>
          <ContentText>任意のコンテンツ名を登録することが可能です。</ContentText>
          {!isAllMatched && (
            <WarningBoxWrapper>
              <WarningBox>
                <WarningText>
                  選択中のコンテンツには異なるコンテンツ名がついているため、保存すると上書きされます。
                </WarningText>
              </WarningBox>
            </WarningBoxWrapper>
          )}
          <Label>
            <label htmlFor="contentName">コンテンツ名</label>
          </Label>
          <NameInputWrapper>
            <DesignedInput
              id="contentName"
              type="text"
              value={contentName}
              onChange={(e) => {
                setContentName(e.target.value)
              }}
              fontSize={16}
              autoFocus={true}
            />
          </NameInputWrapper>
        </Container>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton disabled={initialName === contentName} onClick={onClickSaveButton}>
          保存
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

function getInitialName(contents: Content[]): { initialName: string; isAllMatched: boolean } {
  if (contents.length === 0) return { initialName: '', isAllMatched: true }

  const names = contents.map((con) => con.name)
  const initialName = names[0]

  const isAllMatched = names.every((name) => name === initialName)
  if (!isAllMatched) return { initialName: '', isAllMatched: false }

  return { initialName, isAllMatched: true }
}

const Container = styled.div`
  font-size: 16px;
`

const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 40px;
  margin-bottom: 0.3rem;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
  margin: 0 0 14px 0;
`

const ContentText = styled.div`
  margin-bottom: 14px;
`

const SelectedContentCount = styled.span`
  color: ${colors.orange};
  font-size: 24px;
  font-weight: bold;
`

const WarningBoxWrapper = styled.div`
  margin-bottom: 24px;
`
const NameInputWrapper = styled.div`
  margin-bottom: 28px;
`
const WarningText = styled.div`
  font-size: 14px;
`
