import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../../styleConstants'
import { Check } from 'styled-icons/boxicons-regular'
import { ErrorBox } from '../../../common/ErrorBox'
import { PlusAddButton } from '../../../buttons/PlusAddButton'
import { HelpPopupEnableDisable } from '../../HelpPopups'
import { DATA_TYPE_OPTIONS } from '..'
import { NormalButton } from '../../../common/Button'
import {
  StepSection,
  StepSectionHead,
  StepSectionHeadNo,
  StepSectionBody,
  StepSectionBodyTitle,
  StepSectionBodyTitleText,
  StepSectionBodyNotes,
  DesignedSelect,
  INIT_EDIT_IMPORT_FIELD,
  ErrorMessage,
} from '.'
import {
  EditImportField,
  ImportData,
  ImportField,
  ImportFieldTypeTableTypes,
} from '../../../../util/hooks/api/DataImport/types'
import { CsvSuggestionModal } from './CsvSuggestionModal'
import { CheckCircle } from '../../../common/CheckCircle'

interface Props {
  projectId: string
  importFields: ImportField[] | undefined
  editImportFields: EditImportField[]
  importData: ImportData | null
  setEditImportFields: (arg: EditImportField[]) => void
  setCsvSuggestionId: (arg: number | null) => void
  errorMessage: string
  saved: boolean
  hasError: boolean
  enabledCount: number
  fieldsMaxCount: number
  isFieldsPrimaryKeyNotSelect: boolean
  isFieldsRowLimitExceeded: boolean
  isFieldsValueEmpty: boolean
  isFieldsNotLength: boolean
  isFieldsNotChanged: boolean
  isFieldStepError: boolean
}

export function ImportFieldStep({
  projectId,
  importFields,
  editImportFields,
  importData,
  setEditImportFields,
  setCsvSuggestionId,
  errorMessage,
  saved,
  hasError,
  enabledCount,
  fieldsMaxCount,
  isFieldsPrimaryKeyNotSelect,
  isFieldsRowLimitExceeded,
  isFieldsValueEmpty,
  isFieldsNotLength,
  isFieldsNotChanged,
  isFieldStepError,
}: Props) {
  const [isCsvSuggestionModalOpened, setIsCsvSuggestionModalOpened] = useState(false)

  const openCsvSuggestionModal = () => setIsCsvSuggestionModalOpened(true)
  const closeCsvSuggestionModal = () => setIsCsvSuggestionModalOpened(false)

  const changeFieldPrimaryKey = (event: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const newImportFields = [...editImportFields].map((importField) => {
      return { ...importField, primaryKey: false }
    })
    newImportFields[i].primaryKey = event.currentTarget.checked
    setEditImportFields(newImportFields)
  }

  const changeFieldName = (event: React.FormEvent<HTMLInputElement>, i: number) => {
    const newImportFields = [...editImportFields]
    newImportFields[i].fieldName = event.currentTarget.value
    setEditImportFields(newImportFields)
  }

  const changeFieldDataType = (event: React.ChangeEvent<HTMLSelectElement>, i: number) => {
    const newImportFields = [...editImportFields]
    newImportFields[i].dataType = parseInt(event.currentTarget.value, 10) as ImportFieldTypeTableTypes
    setEditImportFields(newImportFields)
  }

  const changeFieldEnabled = (event: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const newImportFields = [...editImportFields]
    newImportFields[i].enabled = event.currentTarget.checked
    setEditImportFields(newImportFields)
  }

  const addFieldRow = () => {
    setEditImportFields([...editImportFields, { ...INIT_EDIT_IMPORT_FIELD }])
  }

  const FieldCount = () => (
    <RowsTableCount>
      <RowsTableCountTitle>フィールド数:</RowsTableCountTitle>
      <span style={{ color: isFieldsRowLimitExceeded ? colors.error : 'black' }}>
        <strong>{enabledCount}</strong>/{fieldsMaxCount}
      </span>
    </RowsTableCount>
  )

  return (
    <StepSection>
      <StepSectionHead>
        <StepSectionHeadNo saved={saved}>{saved ? <Check size={20} color={colors.white} /> : '2'}</StepSectionHeadNo>
      </StepSectionHead>
      <StepSectionBody>
        <StepSectionBodyTitle>
          <StepSectionBodyTitleText saved={saved}>インポートフィールド</StepSectionBodyTitleText>
        </StepSectionBodyTitle>
        <StepSectionBodyNotes>
          {!!importData && <>データ取り込み済のフィールドは編集できません。</>}
          {!importData && (
            <>
              Content Analyticsにインポートするフィールドを選択することができます。
              <br />
              CSVをアップロードすることで、フィールドだけを取り込むことが可能です。
              <br />
              キーとなるフィールドを1つ選択してください。
            </>
          )}
        </StepSectionBodyNotes>

        <FlexNotesBox>
          <NormalButton onClick={openCsvSuggestionModal}>CSVファイルからフィールドを読み取り</NormalButton>
          {!isFieldStepError && <FieldCount />}
        </FlexNotesBox>

        {hasError && <ErrorBox>{errorMessage}</ErrorBox>}

        {isFieldStepError && (
          <FlexNotesBox>
            <ErrorMessage>
              {isFieldsPrimaryKeyNotSelect && <ErrorItem>キーを選択してください。</ErrorItem>}
              {isFieldsRowLimitExceeded && (
                <ErrorItem>
                  設定しようとしているフィールド数が上限を超えています。
                  <br />
                  上限に収まるようにフィールドを削除してください。
                </ErrorItem>
              )}
              {isFieldsValueEmpty && (
                <ErrorItem>設定されていないフィールド名またはデータタイプが存在します。入力してください。</ErrorItem>
              )}
              {isFieldsNotLength && (
                <ErrorItem>すべての項目が削除されています。1つ以上の項目を有効にしてください。</ErrorItem>
              )}
              {isFieldsNotChanged && <ErrorItem>変更がありません。1箇所以上の変更を行ってください。</ErrorItem>}
            </ErrorMessage>

            <FieldCount />
          </FlexNotesBox>
        )}

        <RowsTableWrapper>
          <RowsTable>
            <RowsTableHead>
              <RowsTableTr>
                <PrimaryKeyRowsTableTh>キー</PrimaryKeyRowsTableTh>
                <FieldNameRowsTableTh>フィールド名</FieldNameRowsTableTh>
                <DataTypeRowsTableTh>データタイプ</DataTypeRowsTableTh>
                <EnabledRowsTableTh>
                  <FlexBoxCenter>
                    <RowsTableThText>有効/削除</RowsTableThText>
                    <HelpPopupEnableDisable />
                  </FlexBoxCenter>
                </EnabledRowsTableTh>
              </RowsTableTr>
            </RowsTableHead>
            <RowsTableTbody>
              {editImportFields.map((importField, i) => {
                const { primaryKey, fieldName, dataType, enabled, editable } = importField
                return (
                  <RowsTableTr key={i} enabled={enabled}>
                    <PrimaryKeyRowsTableTd>
                      {importData === null ? (
                        <RadioLabel>
                          <RadioButton
                            type="radio"
                            name="primaryKey"
                            placeholder="primaryKey"
                            required
                            checked={primaryKey}
                            onChange={(event) => {
                              changeFieldPrimaryKey(event, i)
                            }}
                          />
                        </RadioLabel>
                      ) : (
                        <>
                          {primaryKey && (
                            <IconCenter>
                              <CheckCircle borderColor={colors.headerBg} iconSize={16} iconColor={colors.headerBg} />
                            </IconCenter>
                          )}
                        </>
                      )}
                    </PrimaryKeyRowsTableTd>
                    <FieldNameRowsTableTd>
                      {editable ? (
                        <DesignedInput
                          type="text"
                          value={fieldName}
                          placeholder="フィールド名"
                          required
                          onChange={(event) => {
                            changeFieldName(event, i)
                          }}
                        />
                      ) : (
                        <>{fieldName}</>
                      )}
                    </FieldNameRowsTableTd>
                    <DataTypeRowsTableTd>
                      {editable ? (
                        <DesignedSelect
                          options={[...DATA_TYPE_OPTIONS]} // optionsのOptionPropsがreadonlyを受け付けないため、スプレッド構文で展開
                          placeholder="データタイプ"
                          required
                          onChange={(event) => {
                            changeFieldDataType(event, i)
                          }}
                          value={dataType}
                        />
                      ) : (
                        <>{DATA_TYPE_OPTIONS.find((option) => option.value === dataType)?.label}</>
                      )}
                    </DataTypeRowsTableTd>
                    <EnabledRowsTableTd>
                      {editable && (
                        <Switch>
                          <SwitchLabel>
                            <SwitchInput
                              type="checkbox"
                              role="switch"
                              placeholder="有効/削除"
                              checked={enabled}
                              onChange={(event) => {
                                changeFieldEnabled(event, i)
                              }}
                            />
                            <SwitchChangeArea>
                              <SwitchChangeAreaOn>有効</SwitchChangeAreaOn>
                              <SwitchChangeAreaOff>削除</SwitchChangeAreaOff>
                            </SwitchChangeArea>
                          </SwitchLabel>
                        </Switch>
                      )}
                    </EnabledRowsTableTd>
                  </RowsTableTr>
                )
              })}
            </RowsTableTbody>
          </RowsTable>
          <RowsTableAddButtonWrapper>
            <PlusAddButton onClick={addFieldRow} />
          </RowsTableAddButtonWrapper>

          <FieldCountBottomWrapper>
            <FieldCount />
          </FieldCountBottomWrapper>
        </RowsTableWrapper>
      </StepSectionBody>

      <CsvSuggestionModal
        projectId={projectId}
        importFields={importFields}
        editImportFields={editImportFields}
        importData={importData}
        setEditImportFields={setEditImportFields}
        setCsvSuggestionId={setCsvSuggestionId}
        isCsvSuggestionModalOpened={isCsvSuggestionModalOpened}
        openCsvSuggestionModal={openCsvSuggestionModal}
        closeCsvSuggestionModal={closeCsvSuggestionModal}
      />
    </StepSection>
  )
}

const DesignedInput = styled.input`
  padding: 10px;
  width: 100%;
  font-size: 16px;
  outline: 0;
  border: 1px solid ${colors.gray250};
  border-radius: 2px;
  &:active,
  &:focus {
    border: 1px solid ${colors.lightBlue};
  }
  &:invalid {
    border: 1px solid ${colors.error};
  }
  &::placeholder {
    color: ${colors.gray250};
  }
  &:disabled {
    background: ${colors.gray100};
  }
`

const Switch = styled.div`
  width: fit-content;
`
const SwitchLabel = styled.label`
  cursor: pointer;
`
const SwitchChangeArea = styled.div`
  display: flex;
  justify-content: space-around;
  width: fit-content;
  font-size: 14px;
`
const SwitchChangeAreaOn = styled.div`
  padding: 7px 14px;
  border: 1px solid #999;
  border-radius: 6px 0px 0px 6px;
  background: ${colors.white};
  text-align: center;
`
const SwitchChangeAreaOff = styled.div`
  padding: 7px 14px;
  border: 1px solid #999;
  border-radius: 0px 6px 6px 0px;
  text-align: center;
  background: ${colors.gray750};
  color: ${colors.white};
`
const SwitchInput = styled.input`
  display: none;
  &:checked + ${SwitchChangeArea} > ${SwitchChangeAreaOn} {
    background: ${colors.headerBg};
    color: ${colors.white};
  }
  &:checked + ${SwitchChangeArea} > ${SwitchChangeAreaOff} {
    background: ${colors.white};
    color: ${colors.gray750};
  }
`

const FieldCountBottomWrapper = styled.div`
  margin: 20px 0;
`

const RowsTableWrapper = styled.div`
  margin: 20px 0 0;
  font-size: 14px;
`
const RowsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`
const RowsTableHead = styled.thead``
const RowsTableTbody = styled.tbody``
const RowsTableTr = styled.tr<{ enabled?: boolean }>`
  ${(props) => props.enabled === false && `background: ${colors.gray200}`};
`
const RowsTableTh = styled.th`
  padding: 8px 16px;
  border-bottom: 1px solid #ccc;
  font-weight: normal;
  text-align: left;
`
const PrimaryKeyRowsTableTh = styled(RowsTableTh)`
  width: 0;
  white-space: nowrap;
  text-align: center;
`
const FieldNameRowsTableTh = styled(RowsTableTh)`
  width: 340px;
`
const DataTypeRowsTableTh = styled(RowsTableTh)``
const EnabledRowsTableTh = styled(RowsTableTh)`
  width: 0;
  white-space: nowrap;
`

const RowsTableTd = styled.td`
  padding: 8px 11px;
  border-bottom: 1px solid #ddd;
  color: ${colors.black2};
`
const PrimaryKeyRowsTableTd = styled(RowsTableTd)`
  width: 0;
  white-space: nowrap;
  text-align: center;
`
const FieldNameRowsTableTd = styled(RowsTableTd)`
  width: 340px;
`
const DataTypeRowsTableTd = styled(RowsTableTd)``
const EnabledRowsTableTd = styled(RowsTableTd)`
  padding-right: 20px;
  width: 0;
  white-space: nowrap;
`

const RowsTableThText = styled.div`
  margin: 0 5px 0 0;
`

const RowsTableAddButtonWrapper = styled.div`
  padding: 9px 15px 9px 15px;
  border-bottom: 1px solid #ccc;
`
const RowsTableCount = styled.div`
  font-size: 14px;
  line-height: 1.5;
  text-align: right;
`
const RowsTableCountTitle = styled.span``

const RadioLabel = styled.label`
  display: block;
  padding: 5px;
`

const RadioButton = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid gray;
  outline: none;
  &:checked {
    border-color: ${colors.contentBlue.blue5};
  }
  &:invalid {
    border-color: ${colors.error};
  }
  &::before {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.contentBlue.blue5};
    transform: translate(3px, 3px) scale(0);
    transform-origin: center;
  }
  &:checked::before {
    transform: translate(3px, 3px) scale(1);
  }
`

const FlexBoxCenter = styled.div`
  display: flex;
  justify-content: center;
`

const FlexNotesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 16px;
`

const ErrorItem = styled.div`
  margin: 5px 0 0;
`

const IconCenter = styled.div`
  display: flex;
  justify-content: center;
`
