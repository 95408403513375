import * as React from 'react'
import { Redirect, RouteComponentProps } from '@gatsbyjs/reach-router'

interface ForceLoginProps extends RouteComponentProps {
  token?: string
  syslogToken?: string
}

export function ForceLogin(props: ForceLoginProps) {
  // スーパーアカウント機能のための強制ログインURL
  // see  https://github.com/uncovertruth/content-analytics/issues/1045

  window.localStorage.setItem('token', props.token!)
  window.localStorage.setItem('syslogToken', props.syslogToken!)
  return <Redirect to="/" noThrow />
}
