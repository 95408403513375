import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { colors } from '../styleConstants'
import { SimplePageLayout } from '../components/layout/SimplePageLayout'
import { PageHeader } from '../components/common/PageHeader'
import { CheckCircle } from 'styled-icons/boxicons-regular'

export function RemindDone(_: RouteComponentProps) {
  return (
    <SimplePageLayout>
      <PageHeader title="パスワード再設定メールの送信完了" description="パスワード再設定メールの送信完了" />
      <Title>メールを送信しました</Title>
      <Icon />
      <Comment>登録されているメールアドレス宛に、パスワード再設定用のメールをお送りしました。</Comment>
      <Comment>メールに記載されたURLを開いて、パスワードの変更手続きに進んでください。</Comment>
    </SimplePageLayout>
  )
}

const Title = styled.h1`
  text-align: center;
  color: ${colors.lightBlue};
`

const Comment = styled.div`
  margin: 0 auto;
`

const Icon = styled(CheckCircle).attrs({
  size: '128',
  color: `${colors.lightBlue}`,
})`
  display: block;
  margin: 1rem auto;
`
