import * as React from 'react'
import styled from 'styled-components'

export interface LegendsState {
  readonly name: string
  readonly color: string
}

interface Props {
  readonly legends: LegendsState[]
  readonly style?: React.CSSProperties
}

export function Legends({ legends, style }: Props) {
  return (
    <Container style={style}>
      {legends.length > 0 &&
        legends.map((legend, index) => {
          return (
            <Item key={`legends-${index}`}>
              <Icon style={{ background: `${legend.color}` }} />
              <Name>{legend.name}</Name>
            </Item>
          )
        })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-width: 0;
  gap: 1rem 1.5rem;
`

const Item = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
`

const Name = styled.div`
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
