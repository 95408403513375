import * as React from 'react'
import styled from 'styled-components'
import { Link, RouteComponentProps } from '@gatsbyjs/reach-router'
import { GlobalContext } from '../GlobalState'
import { usePageState } from './Login/state'
import { AdminNormalButton } from '../components/common/Button'
import { Input } from '../components/common/Input'
import { colors } from '../styleConstants'
import { SimplePageLayout } from '../components/layout/SimplePageLayout'
import { PasswordRegex, PasswordMinLength } from '../util/Password'
import { PageHeader } from '../components/common/PageHeader'
import { Select } from '../components/common/Select'

export function Login(props: RouteComponentProps) {
  const { actions: globalActions } = React.useContext(GlobalContext)
  const {
    state: { email, password, disabled, errorMessage, isTeamSelect, teamOptions },
    actions,
  } = usePageState()
  return (
    <SimplePageLayout>
      <PageHeader title="ログイン" description="ログイン" />
      <Title>ログイン</Title>

      <Form
        onSubmit={(e) => {
          globalActions.clearState()
          actions.submit(e, props.location?.search)
        }}
      >
        {isTeamSelect && (
          <div>
            <Label className="label">組織</Label>
            <DesignedSelect options={teamOptions} onChange={actions.onTeamChange} />
          </div>
        )}

        <Label className="label">メールアドレス</Label>
        <DesignedInput
          type="email"
          look="noError"
          value={email}
          onChange={actions.updateEmail}
          required
          className="input"
          data-testid="email"
          placeholder="メールアドレス"
        />

        <Label className="label">パスワード</Label>
        <DesignedInput
          type="password"
          look="noError"
          value={password}
          minLength={PasswordMinLength}
          pattern={PasswordRegex}
          onChange={actions.updatePassword}
          required
          className="input"
          data-testid="password"
        />
        <LinkContainer className="link">
          <DesignedLink to="/remind">パスワードをお忘れですか？</DesignedLink>
        </LinkContainer>
        <AdminNormalButton
          type="submit"
          className="button"
          data-testid="button"
          disabled={disabled}
          style={{ width: '100%' }}
        >
          ログイン
        </AdminNormalButton>
      </Form>
      {errorMessage && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}
    </SimplePageLayout>
  )
}

const Title = styled.h1`
  text-align: center;
  color: ${colors.lightBlue};
`

const Form = styled.form`
  margin: 2rem 0 1.25rem;

  .label + .input {
    margin-top: 0.5rem;
  }

  .input + .label {
    margin-top: 2rem;
  }
`

const Label = styled.div`
  font-weight: normal;
`

const LinkContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-size: 12px;
  font-weight: normal;
  text-align: right;
`

const DesignedLink = styled(Link)`
  text-decoration: underline;
  color: ${colors.link.base};

  &:visited {
    color: ${colors.link.visited};
  }
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 40px;
`

const DesignedSelect = styled(Select)`
  width: 100%;
  height: 40px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding: 0 0.2rem;
`

const ErrorMessage = styled.p`
  font-size: 12px;
  color: ${colors.error};
`
