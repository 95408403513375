import * as React from 'react'
import { State } from './state'
import { SearchOptions, NarrowDownState } from '../../components/filter/PageReportNarrowDown'
import { deepCopy } from '../../util/copy'

export class NarrowDown {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  open = () => {
    this.setState({
      ...this.state,
      openedNarrowDown: true,
      disabledNarrowDown: !this.isSearchValueInput(this.state.narrowDownTemp),
    })
  }

  onClose = () => {
    this.setState({
      ...this.state,
      openedNarrowDown: false,
      narrowDownTemp: deepCopy(this.state.narrowDown),
      disabledNarrowDown: false,
    })
  }

  onCancel = () => {
    this.setState({
      ...this.state,
      openedNarrowDown: false,
      narrowDownTemp: deepCopy(this.state.narrowDown),
      disabledNarrowDown: false,
    })
  }

  // 高度な絞り込み実行
  apply = () => {
    const tempValue = this.state.narrowDownTemp.map((obj) => {
      return { ...obj, error: undefined }
    })
    const newValue = deepCopy(tempValue)

    this.setState({
      ...this.state,
      openedNarrowDown: false,
      searchText: '', // 高度な絞り込み実行時には入力フォームはクリアする
      narrowDown: newValue,
      narrowDownTemp: tempValue,
      loadType: 'filter',
      currentPage: 0,
    })
    return newValue
  }

  // 高度な絞り込み条件追加
  onAdd = () => {
    this.state.narrowDownTemp.push({
      condition: [SearchOptions.Option1.match, SearchOptions.Option2.url, SearchOptions.Option3.full],
      searchValue: '',
    })
    this.setState({
      ...this.state,
      disabledNarrowDown: !this.isSearchValueInput(this.state.narrowDownTemp),
    })
  }

  // 高度な絞り込み条件を削除
  onDelete = (index: number) => {
    this.state.narrowDownTemp.splice(index, 1)
    this.setState({
      ...this.state,
      disabledNarrowDown: !this.isSearchValueInput(this.state.narrowDownTemp),
    })
  }

  // 高度な絞り込みのリセット
  reset = () => {
    // 条件をクリアしてモーダルを閉じる
    this.setState({
      ...this.state,
      searchText: '',
      isSearch: false,
      narrowDown: [],
      narrowDownTemp: [],
      disabledNarrowDown: true,
      openedNarrowDown: false,
      currentPage: 0,
    })
  }

  // 絞り込みオプション変更
  onSelect = (event: React.ChangeEvent<HTMLSelectElement>, index: number, condition: number) => {
    this.state.narrowDownTemp[index].condition[condition] = Number(event.currentTarget.value)
    this.setState({ ...this.state })
  }

  // 絞り込み文字列が全て入力されているか
  isSearchValueInput = (state: NarrowDownState[]) => {
    if (state.length === 0) {
      return false
    }

    for (let i = 0; i < state.length; ++i) {
      if (state[i].searchValue === '') {
        return false
      }
    }
    return true
  }

  // 絞り込み条件入力
  onChange = (event: React.FormEvent<HTMLInputElement>, index: number) => {
    this.state.narrowDownTemp[index].searchValue = event.currentTarget.value
    this.setState({
      ...this.state,
      disabledNarrowDown: !this.isSearchValueInput(this.state.narrowDownTemp),
    })
  }
}
