import * as React from 'react'
import styled from 'styled-components'

import { BaseHeaderCell } from './BaseCell'
import { SortButton } from '../../common/SortButton'
import { SortItemKey, SortState } from '../../../util/hooks/api/useContentReport'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'

interface Props {
  readonly sortState: SortState
  readonly onSortClick: (key: SortItemKey) => void
}

export function ContentNameLongHeaderCell({ sortState, onSortClick }: Props) {
  return (
    <Cell onClick={() => onSortClick('id')}>
      コンテンツ名
      <SortButton elementKey={'id'} sortIcon={sortState['id']} />
    </Cell>
  )
}

const Cell = styled(BaseHeaderCell)`
  grid-column-start: 1;
  grid-column-end: 4;
  background-color: ${CELL_BACKGROUND_COLORS.CONTENT};
`
