import * as React from 'react'

import { Actions, State } from '../../../../pages/ContentReport/state'
import { WarningArea } from '../../WarningArea'
import { ContentGroupGrid } from './ContentGroupGrid'
import { useContentGroupReport } from '../../../../util/hooks/api/useContentGroupReport'
import { Loading } from '../../../common/Loading'
import { SortItemKey } from '../../../../util/hooks/api/useContentReport'
import { SortOrder, sortBy } from '../../../../util/sortBy'
import { extractContentReportSortValue } from '../..'
import { ReportContext } from '../../../../contexts/ReportProvider'
import { CustomFilterContext } from '../../../../contexts/CustomFilterContext'

interface Props {
  pageState: State
  actions: Actions
  readonly setContentsGroupDisplayHistoryId: (newValue: number) => void
  readonly projectId: string
  readonly reportId: string
}
export function ContentGroupTab({ pageState, actions, setContentsGroupDisplayHistoryId, projectId, reportId }: Props) {
  const {
    selectedContentIds,
    contentGroupState: { errorMessage, sortState },
    gridScrollTo,
  } = pageState

  const {
    state: { calenderState },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)

  const { data, isError, isLoading } = useContentGroupReport({
    projectId,
    reportId,
    calenderState,
    customFilterState,
    sortState,
  })

  const { sortKey, order } = React.useMemo((): { sortKey: SortItemKey | null; order: SortOrder } => {
    return extractContentReportSortValue(sortState)
  }, [sortState])

  if (isLoading) return <Loading />
  if (isError) return <WarningArea message={errorMessage} />

  const handleItemClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!e.currentTarget.dataset.id) return
    const contentGroup = data.contentGroups.find((item) => item.id === Number(e.currentTarget.dataset.id))
    const history = data.pageHistories.find((history) => history.id === contentGroup?.linkPageHistoryId)
    if (contentGroup && history) {
      actions.onContentGroupCellSelected(contentGroup)
      setContentsGroupDisplayHistoryId(history.id)
    }
  }

  const handleChartItemsSelect = (ids: number[]) => {
    const contentGroups = data.contentGroups.filter((item) => ids.includes(item.id))
    const firstContentGroup = contentGroups[0]
    const history = data.pageHistories.find((history) => history.id === firstContentGroup?.linkPageHistoryId)
    if (contentGroups.length && history) {
      actions.onContentGroupChartItemsSelected(contentGroups)
      setContentsGroupDisplayHistoryId(history.id)
    }
  }

  return (
    <>
      {data.isManagedContentExists && errorMessage && <WarningArea message={errorMessage} />}
      <ContentGroupGrid
        pageSummary={data.pageSummary}
        contentGroups={sortKey ? sortBy(data.contentGroups, sortKey, order) : data.contentGroups}
        contentsSummary={data.contentsSummary}
        selectedIds={selectedContentIds}
        sortState={sortState}
        gridScrollTo={gridScrollTo}
        onClick={handleItemClick}
        handleChartItemsSelect={handleChartItemsSelect}
        onSort={(key) => {
          actions.onContentGroupGridSort(key)
        }}
        onReset={actions.onGridStateReset}
        isManagedContentExists={data.isManagedContentExists}
      />
    </>
  )
}
