import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../styleConstants'
import { TableCell } from '..'
import { DataImportLog } from '../../../../util/hooks/api/DataImport/types'
import { DATA_IMPORT_STATUS } from '../../../../util/hooks/api/DataImport/constants'

interface Props {
  log: DataImportLog
  projectId: string
  permissions: any
}

export function SummaryCell({ log }: Props) {
  switch (log.status) {
    case DATA_IMPORT_STATUS.SUCCESS:
      return <TableCell>取り込み件数: {log.summarySuccessedDataCount?.toLocaleString() || 0}件</TableCell>
    case DATA_IMPORT_STATUS.DELETED:
      return <TableCell>-</TableCell>
    case DATA_IMPORT_STATUS.FAILED:
      return (
        <FailedCell>
          {log.generalErrorMessage && <GeneralErrorMessage>{log.generalErrorMessage}</GeneralErrorMessage>}
          <SummaryBox>
            {log.summaryErrorLog?.map((errorLog) => (
              <div key={errorLog.rowNumber}>{errorLog.messages}</div>
            ))}
          </SummaryBox>
        </FailedCell>
      )
    default:
      return null
  }
}

const FailedCell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid ${colors.gray350};
  line-height: 21px;
  padding: 8px 16px;
`

const GeneralErrorMessage = styled.div`
  padding: 0 0 10px;
  color: ${colors.error};
`

const SummaryBox = styled.div`
  max-height: 120px;
  overflow-y: auto;
  background-color: ${colors.gray100};
  padding: 8px 16px;
  border: 1px solid ${colors.gray250};
  margin-bottom: 8px;
`
