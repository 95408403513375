import { useCookies } from 'react-cookie'

export const CAPTURE_VIEW = {
  WITH_BORDER: 'WITH_BORDER', // 枠あり
  WITHOUT_BORDER: 'WITHOUT_BORDER', // 枠なし
  VIEW_COUNT: 'VIEW_COUNT',
  VIEW_RATE: 'VIEW_RATE',
  VIEW_SECOND: 'VIEW_SECOND',
  GOAL_COUNT: 'GOAL_COUNT',
  GOAL_RATE: 'GOAL_RATE',
  CLICK_COUNT: 'CLICK_COUNT',
  CLICK_RATE: 'CLICK_RATE',
} as const

export type CaptureView = typeof CAPTURE_VIEW[keyof typeof CAPTURE_VIEW]

const DEFAULT_CAPTURE_VIEW = CAPTURE_VIEW.GOAL_RATE

const METRIC_VISIBLE_CAPTURE_VIEWS: CaptureView[] = [
  CAPTURE_VIEW.VIEW_COUNT,
  CAPTURE_VIEW.VIEW_RATE,
  CAPTURE_VIEW.VIEW_SECOND,
  CAPTURE_VIEW.GOAL_COUNT,
  CAPTURE_VIEW.GOAL_RATE,
  CAPTURE_VIEW.CLICK_COUNT,
  CAPTURE_VIEW.CLICK_RATE,
] as const

interface Result {
  readonly captureView: CaptureView
  readonly setCaptureView: (value: CaptureView) => void
  readonly isFrameVisible: boolean
  readonly isMetricVisible: boolean
  readonly isRankVisible: boolean
}

const COOKIE_KEY = `captureView`

export const useCaptureView = (): Result => {
  const [cookies, setCookie] = useCookies([COOKIE_KEY])

  const captureView = validateCaptureView(cookies[COOKIE_KEY]) || DEFAULT_CAPTURE_VIEW

  const isFrameVisible = captureView !== CAPTURE_VIEW.WITHOUT_BORDER
  const isMetricVisible = METRIC_VISIBLE_CAPTURE_VIEWS.includes(captureView)
  const isRankVisible = captureView === CAPTURE_VIEW.GOAL_RATE

  const setCaptureView = (newCaptureView: CaptureView) => {
    const validatedNewCaptureView = validateCaptureView(newCaptureView)
    if (!validatedNewCaptureView) return
    setCookie(COOKIE_KEY, validatedNewCaptureView, { path: '/' })
  }

  return {
    captureView,
    setCaptureView,
    isFrameVisible,
    isMetricVisible,
    isRankVisible,
  }
}

const validateCaptureView = (captureView: string | null): CaptureView | null => {
  const castCaptureView = captureView as CaptureView
  if (!Object.values(CAPTURE_VIEW).includes(castCaptureView)) {
    return null
  }
  return castCaptureView
}
