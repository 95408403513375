import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors, FONT_COLOR_MAIN } from '../../styleConstants'

export type InputType =
  | 'text'
  | 'password'
  | 'email'
  | 'number'
  | 'search'
  | 'url'
  | 'tel'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'week'
  | 'time'

export interface Props {
  readonly type?: InputType
  readonly iconRight?: JSX.Element
  readonly fluid?: boolean
  readonly className?: string
  readonly style?: React.CSSProperties
  readonly onEnterKeyDown?: React.KeyboardEventHandler
  readonly ref?: React.RefObject<HTMLInputElement>
  readonly look?: 'normal' | 'red' | 'noError'
  readonly textAlign?: 'left' | 'center' | 'right'
  readonly fontSize?: number
}

interface State {
  readonly iconRightWidth?: number
}

export const Input = React.forwardRef<HTMLInputElement, Props & React.InputHTMLAttributes<any>>((props, ref) => {
  const {
    type = 'text',
    iconRight,
    fluid,
    className,
    style,
    onEnterKeyDown,
    look = 'normal',
    textAlign,
    fontSize,
    ...rest
  } = props
  const iconRightRef = React.useRef<Element>(null)
  const [state, setState] = React.useState<State>({})
  const onKeyDown: React.KeyboardEventHandler = (event) => {
    if (onEnterKeyDown && event.key === 'Enter') {
      onEnterKeyDown(event)
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      if (!iconRightRef.current) return
      setState({ iconRightWidth: iconRightRef.current.getBoundingClientRect().width })
    }, 500)
  }, [])

  const inputStyle = {
    paddingRight: state.iconRightWidth ? `calc(${state.iconRightWidth}px + 0.25rem + 4px)` : undefined,
    textAlign: textAlign ? textAlign : undefined,
    fontSize: fontSize ? `${fontSize}px` : undefined,
  }
  return (
    <Container className={cc([{ fluid }, className])} style={style}>
      <DesignedInput className={cc(look)} onKeyDown={onKeyDown} style={inputStyle} type={type} ref={ref} {...rest} />
      {iconRight && React.cloneElement(iconRight, { 'data-icon': 'right', ref: iconRightRef })}
    </Container>
  )
})

const Container = styled.div`
  display: inline-flex;
  color: ${colors.gray800};
  position: relative;
  user-select: none;

  &.fluid {
    display: flex;
  }

  [data-icon='right'] {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const DesignedInput = styled.input`
  flex: 1 0 auto;
  max-width: 100%;
  outline: 0;
  border-radius: 2px;
  box-shadow: none;
  color: ${FONT_COLOR_MAIN};
  background: ${colors.white};
  border: 1px solid ${colors.gray250};
  padding: 0.5rem;

  &:active,
  &:focus {
    border: 1px solid ${colors.lightBlue};
  }

  &.normal&:invalid {
    border: 1px solid ${colors.error};
  }

  &.red {
    border: 1px solid ${colors.error};
    background: ${colors.errorBg};
  }
  &:disabled {
    color: #8e8e8e;
    background: ${colors.gray350};
    &:active,
    &:focus {
      border: 1px solid ${colors.gray350};
    }
  }

  ::placeholder {
    color: ${colors.gray250};
  }
`
