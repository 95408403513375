import * as React from 'react'
import styled from 'styled-components'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../../../common/Modal'
import { colors } from '../../../../styleConstants'
import { CancelButton, NormalButton } from '../../../common/Button'
import { useToast } from '../../../../util/hooks/useToast'
import { ImportData } from '../../../../util/hooks/api/DataImport/types'
import { useDataImportLogs } from '../../../../util/hooks/api/DataImport/useDataImportLogs'
import { useDeleteImportData } from '../../../../util/hooks/api/DataImport/useDeleteImportData'
import { useLatestImportData } from '../../../../util/hooks/api/DataImport/useLatestImportData'
import { getDateYYYYMMDDhhmi } from '../../../../util/Date'

type Props = {
  projectId: string
  importMetaData: ImportData['metaData']
  onClose: () => void
  opened: boolean
}
export const DeleteImportedDataModal = ({ projectId, importMetaData, onClose, opened }: Props) => {
  if (!opened) return null

  const { openToast } = useToast()
  const { resetLatestImportData } = useLatestImportData({ projectId })
  const { invalidate: invalidateImportLogs } = useDataImportLogs({ projectId })
  const {
    mutate: deleteImportData,
    isLoading: isDeleting,
    isError: isDeleteError,
    error: deleteError,
  } = useDeleteImportData({
    projectId,
    importDataLogId: importMetaData.id,
    options: {
      onSuccess: () => {
        invalidateImportLogs()
        resetLatestImportData()
        openToast({ message: 'インポートデータを削除しました' })
        onClose()
      },
    },
  })

  return (
    <Modal isOpen={opened} onClose={onClose} title="インポート済データ削除" isLoading={isDeleting}>
      {isDeleteError && (
        <ErrorMessage>
          {typeof deleteError === 'string' ? deleteError : 'インポートデータの削除に失敗しました。'}
        </ErrorMessage>
      )}
      <ModalContentWrapper>このインポート済データを削除してよろしいですか？</ModalContentWrapper>
      <ModalContentWrapper>
        <ContentHeadline>インポート日時</ContentHeadline>
        {getDateYYYYMMDDhhmi(importMetaData.createdAt)}
      </ModalContentWrapper>
      <ModalContentWrapper>
        <ContentHeadline>サマリー</ContentHeadline>
        取り込み件数: {importMetaData.summarySuccessedDataCount}件
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton
          onClick={() => {
            deleteImportData()
          }}
        >
          実行
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const ContentHeadline = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
  margin: 0;
`
