import * as React from 'react'
import { request } from '../../util/request'
import { UserData } from '../../util/Response'
import { checkPasswordPattern } from '../../util/Password'
import { useToast } from '../../util/hooks/useToast'

export const CategoryType = {
  info: 0,
  password: 1,
}

interface PageContextType {
  readonly state: State
  readonly pageActions: Actions
}

type OpenCloseState = 'open' | 'close'
export interface PageState {
  readonly info: OpenCloseState // アカウント情報
  readonly password: OpenCloseState // パスワード変更
  [key: string]: string
}

interface State {
  readonly loading: boolean
  readonly username: string
  readonly baseUsername: string // 比較用
  readonly email: string
  readonly disabled: boolean
  readonly oldPassword: string
  readonly newPassword: string
  readonly isOldPasswordValid: boolean
  readonly isNewPasswordValid: boolean
  readonly isOldPasswordMatch: boolean
  readonly isNewPasswordMatch: boolean
  readonly passwordDisabled: boolean
  readonly isSnackbar: boolean
  readonly toastMessage: string
  readonly accountErrorMessage?: string
  readonly passwordErrorMessage?: string
}

class Actions {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly openToast: ({ message }: { message: string }) => void,
  ) {}

  fetch = async () => {
    try {
      const json: UserData = await request('GET', `/api/user/`, true)
      this.setState({
        ...this.state,
        loading: false,
        username: json.username,
        baseUsername: json.username,
        email: json.email,
        accountErrorMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        accountErrorMessage: typeof e === 'string' ? e : 'アカウント情報の取得に失敗しました。',
      })
    }
  }

  updateUsername = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      username: event.currentTarget.value,
      disabled: !(event.currentTarget.validity.valid && this.state.baseUsername !== event.currentTarget.value),
    })
  }

  // アカウントの情報リセット
  onCancel = () => {
    this.setState({
      ...this.state,
      username: this.state.baseUsername,
      disabled: true,
    })
  }

  saveAccount = async () => {
    try {
      const json: UserData = await request(
        'PATCH',
        '/api/user/',
        true,
        JSON.stringify({ username: this.state.username }),
      )
      this.openToast({ message: 'アカウント情報を変更しました' })
      this.setState({
        ...this.state,
        username: json.username,
        baseUsername: json.username,
        disabled: true,
        isSnackbar: true,
        toastMessage: '',
        accountErrorMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        accountErrorMessage: typeof e === 'string' ? e : 'アカウントの更新に失敗しました。',
      })
    }
  }

  updateOldPassword = (event: React.FormEvent<HTMLInputElement>) => {
    // 文字列に英数記号が2種類以上含まれるか確認する
    if (!checkPasswordPattern(event.currentTarget.value)) {
      event.currentTarget.setCustomValidity(
        'アルファベット、数字および記号のうち少なくとも２種類以上の組み合わせである必要があります',
      )
    } else {
      event.currentTarget.setCustomValidity('')
    }

    this.setState({
      ...this.state,
      oldPassword: event.currentTarget.value,
      isOldPasswordValid: event.currentTarget.validity.valid,
      passwordDisabled: !(
        event.currentTarget.validity.valid &&
        this.state.isNewPasswordValid &&
        // 新しいパスワードが変更前のパスワードと一致していない
        !this.state.isNewPasswordMatch
      ),
    })
  }

  updateNewPassword = (event: React.FormEvent<HTMLInputElement>) => {
    if (!checkPasswordPattern(event.currentTarget.value)) {
      event.currentTarget.setCustomValidity(
        'アルファベット、数字および記号のうち少なくとも２種類以上の組み合わせである必要があります',
      )
    } else {
      event.currentTarget.setCustomValidity('')
    }

    this.setState({
      ...this.state,
      newPassword: event.currentTarget.value,
      isNewPasswordValid: event.currentTarget.validity.valid,
      passwordDisabled: !(
        event.currentTarget.validity.valid &&
        this.state.isOldPasswordValid &&
        // 設定前のパスワードが入力と一致していない
        event.currentTarget.value !== this.state.oldPassword
      ),
      isNewPasswordMatch: event.currentTarget.value === this.state.oldPassword,
    })
  }

  onBlurOldPassword = () => {}

  onBlurNewPassword = (event: React.FocusEvent<HTMLInputElement>) => {
    // 新しいパスワードが設定前のパスワードと一致していた場合にエラーを表示する
    if (this.state.isNewPasswordMatch && !event.currentTarget.validity.customError) {
      event.currentTarget.setCustomValidity('古いパスワードとは違うパスワードを設定してください。')
      event.currentTarget.reportValidity()
    }
  }

  changePassword = async () => {
    try {
      await request(
        'PATCH',
        '/api/user/password/',
        true,
        JSON.stringify({
          new_password1: this.state.newPassword,
          new_password2: this.state.newPassword,
          old_password: this.state.oldPassword,
        }),
      )
      this.openToast({ message: 'パスワードを変更しました' })
      // 表示状態をリセット
      this.setState({
        ...this.state,
        oldPassword: '',
        newPassword: '',
        isOldPasswordValid: false,
        isNewPasswordValid: false,
        isOldPasswordMatch: false,
        isNewPasswordMatch: false,
        disabled: true,
        isSnackbar: true,
        toastMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        passwordErrorMessage: typeof e === 'string' ? e : 'パスワードの変更に失敗しました。',
      })
    }
  }
}

const initialState: State = {
  loading: true,
  username: '',
  baseUsername: '',
  email: '',
  disabled: true,
  oldPassword: '',
  newPassword: '',
  isOldPasswordValid: false,
  isNewPasswordValid: false,
  isOldPasswordMatch: false,
  isNewPasswordMatch: false,
  passwordDisabled: true,
  isSnackbar: false,
  toastMessage: '',
  accountErrorMessage: '',
  passwordErrorMessage: '',
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const { openToast } = useToast()
  const pageActions = new Actions(state, setState, openToast)
  return { state, pageActions }
}
