import { useGenericMutation } from '../useApi'
import { CreateTargetContentsData } from '../../Response'

type CreateTargetContentRequestType = {
  content_name: string
  css_selector: string
}
export const useCreateTargetContent = ({ projectId, urlId }: { projectId: string; urlId: string }) => {
  const mutationResults = useGenericMutation<CreateTargetContentRequestType, string, CreateTargetContentsData>(
    'POST',
    `/projects/${projectId}/urls/${urlId}/target_contents/`,
    true,
  )
  return {
    createTargetContentMutate: mutationResults.mutate,
  }
}
