import * as React from 'react'
import styled from 'styled-components'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { AdminNormalButton, CancelButton } from '../common/Button'
import { Input } from '../common/Input'

interface Props {
  readonly opened: boolean
  readonly email: string
  readonly disabled: boolean
  readonly updateEmail?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onCancel: () => void
  readonly onApply?: () => void
  readonly title: string
}

export function SendInviteModal(props: Props) {
  const { opened, email, disabled, updateEmail, onCancel, onApply, title } = props
  return (
    <Modal isOpen={opened} onClose={onCancel} title={title} width={600}>
      <ModalContentWrapper>
        <div>招待するメンバーのメールアドレスを入力してください。</div>
        <div style={{ marginTop: '1.5rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>メールアドレス</div>
        <DesignedInput type="email" required value={email} onChange={updateEmail} />
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <AdminNormalButton disabled={disabled} onClick={onApply}>
          次へ
        </AdminNormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const DesignedInput = styled(Input)`
  width: 400px;
  height: 35px;
`
