import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { ThreeDotsVertical, Dot } from '@styled-icons/bootstrap'
import { MessageRoundedError } from '@styled-icons/boxicons-solid/MessageRoundedError'

export interface Item {
  name: string
  onClick: (e?: React.MouseEvent<HTMLAnchorElement>) => void
  href?: string
  isError?: boolean
}

interface Props {
  items: Item[]
}

export function ToolMenu(props: Props) {
  const [isPopupVisible, setIsPopupVisible] = React.useState(false)

  const handleMouseEnter = () => {
    setIsPopupVisible(true)
  }

  const handleMouseLeave = () => {
    setIsPopupVisible(false)
  }

  const hasErrorItem = props.items.some(({ isError = false }) => isError)

  return (
    <Container
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      hasErrorItem={hasErrorItem}
      data-testid="tool-menu"
    >
      {hasErrorItem ? <DesignedDot size={32} color={colors.error} /> : null}
      <ThreeDotsVertical size={26} color={colors.black2} />
      <Popup visible={isPopupVisible}>
        {props.items.map((item) => {
          const anchorProps = { as: 'a', href: item.href } as const
          const divProps = { as: 'div' } as const
          return (
            <PopupButton key={item.name} onClick={item.onClick} {...(item.href ? anchorProps : divProps)}>
              {item.isError ? <MessageRoundedError size={16} color={colors.error} /> : null}
              <PopupButtonText isError={item.isError}>{item.name}</PopupButtonText>
            </PopupButton>
          )
        })}
      </Popup>
    </Container>
  )
}

const Container = styled.div<{ hasErrorItem: boolean }>`
  width: 30px;
  height: 30px;
  border: ${({ hasErrorItem }) => `1px solid ${hasErrorItem ? colors.error : colors.contentBlue.blue1}`};
  background-color: ${({ hasErrorItem }) =>
    `${hasErrorItem ? colors.contentOrange.orange1 : colors.contentBlue.blue3}`};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${colors.contentBlue.blue2};
  }
`

const DesignedDot = styled(Dot)`
  position: absolute;
  top: -12px;
  right: -12px;
`

const Popup = styled.div<{ visible: boolean }>`
  position: absolute;
  border-top: solid 4px rgb(255, 255, 255, 0);
  top: calc(100%);
  right: 0;
  z-index: 1;
  padding: 8px 0;
  background: ${colors.white};
  background-clip: padding-box;
  transition: opacity 0.2s, visibility 0.2s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`
const PopupButton = styled.a`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  min-width: 160px;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background: ${colors.gray100};
  }
`
const PopupButtonText = styled.p<{ isError?: boolean }>`
  margin: 0;
  color: ${({ isError }) => `${isError ? colors.error : colors.black}`};
`
