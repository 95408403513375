import * as React from 'react'
import { request } from '../../util/request'
import { navigate } from '@gatsbyjs/reach-router'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly email: string
  readonly disabled: boolean
  readonly sendFailure: boolean
  readonly sendSuccess: boolean
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  updateEmail = (event: React.FormEvent<HTMLInputElement>) => {
    // 入力が更新されたら送信ステータスはリセットする
    this.setState({
      ...this.state,
      email: event.currentTarget.value,
      disabled: !event.currentTarget.validity.valid,
      sendFailure: false,
      sendSuccess: false,
    })
  }

  submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      await request('POST', '/api/password_reset/', false, JSON.stringify({ email: this.state.email }))
      navigate('/remind/done')
    } catch {
      this.setState({ ...this.state, sendSuccess: false, sendFailure: true })
    }
  }
}

export const initialState: State = {
  email: '',
  disabled: true,
  sendFailure: false,
  sendSuccess: false,
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
