import * as React from 'react'
import styled from 'styled-components'
import { FileDownload } from '@styled-icons/remix-line'
import { colors } from '../../styleConstants'
import { IconButton } from '../common/Button'
import { Select, OptionProps } from '../common/Select'

export interface AuditLogState {
  readonly id: number
  readonly date: string
  readonly user: string
  readonly project: string
  readonly ip: string
  readonly event: string
  readonly log: string
}

// 表示幅
const layoutWidth = {
  date: '120px',
  user: '120px',
  project: '120px',
  ip: '120px',
  event: '120px',
  log: '200px',
}

interface Props {
  readonly logs: AuditLogState[]
  readonly options: OptionProps[]
  readonly selectedValue: string
  readonly canDownloadAuditlog?: boolean
  readonly onDownload?: () => void
  readonly onDateChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

export function AuditLogList(props: Props) {
  const { logs, options, selectedValue, canDownloadAuditlog, onDownload, onDateChange } = props
  return (
    <Container>
      <div style={{ fontSize: '1.1rem' }}>ログは直近6カ月分まで閲覧可能です。</div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
        <DesignedSelect value={selectedValue} options={options} onChange={onDateChange} />
        {canDownloadAuditlog && logs.length > 0 && (
          <IconButton iconLeft={<FileDownload size={15} />} onClick={onDownload}>
            CSVダウンロード
          </IconButton>
        )}
      </div>
      {logs.length === 0 ? (
        <div style={{ margin: '1.5rem 0' }}>監査ログはありません</div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Item style={{ paddingBottom: '0.5rem' }}>
            <Box className="title" style={{ width: `${layoutWidth.date}` }}>
              操作日時
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.user}` }}>
              操作ユーザー
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.project}` }}>
              対象プロジェクト
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.ip}` }}>
              アクセスIP
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.event}` }}>
              操作イベント
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.log}` }}>
              ログメッセージ
            </Box>
          </Item>
          <Items>
            <div>
              {logs.map((log) => {
                return (
                  <Item key={log.id}>
                    <Box style={{ width: `${layoutWidth.date}` }}>{log.date}</Box>
                    <Box style={{ width: `${layoutWidth.user}` }}>{log.user}</Box>
                    <Box style={{ width: `${layoutWidth.project}` }}>{log.project}</Box>
                    <Box style={{ width: `${layoutWidth.ip}` }}>{log.ip}</Box>
                    <Box style={{ width: `${layoutWidth.event}` }}>{log.event}</Box>
                    <Box style={{ width: `${layoutWidth.log}` }}>{log.log}</Box>
                  </Item>
                )
              })}
            </div>
          </Items>
        </div>
      )}
    </Container>
  )
}

const Container = styled.div``

const Items = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: hidden;

  & > div {
    ma-height: 100vh;
    overflow-y: scroll;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  height: 100%;
  align-items: center;
  padding: 0.8rem 0;
  border-bottom: 1px solid ${colors.gray300};
  font-size: 0.9rem;

  &.title {
    color: ${colors.gray500};
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;

  & + & {
    margin-left: 1.5rem;
  }

  &.title {
    color: ${colors.gray500};
  }
`

const DesignedSelect = styled(Select)`
  width: 100px;
  height: 30px;
  padding: 0.2rem 0.5rem;
  margin-right: 3rem;
`
