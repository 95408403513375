import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'

type Props = {
  projectId: string
  scheduleId: number | undefined
  options?: UseMutationOptions<{}, string, null>
}

export const useDeleteDataImportSchedule = ({ projectId, scheduleId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async () => {
      const result: {} = await request(
        'DELETE',
        `/api/projects/${projectId}/data_import_schedules/${scheduleId}/`,
        true,
      )
      return result
    },
    ...options,
  })
  return mutationResult
}
