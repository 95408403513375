type Env = 'production' | 'staging' | 'development' | 'local'

const isEnv = (env: Env) => env === process.env.NODE_ENV

export const isLocal = () => {
  return isEnv('local')
}

export const isDevelopment = () => {
  return isEnv('development')
}

export const isStaging = () => {
  return isEnv('staging')
}

export const isProductiont = () => {
  return isEnv('production')
}

export const isLocalOrDevelopment = () => {
  return isEnv('local') || isEnv('development')
}
