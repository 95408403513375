import { useQuery } from '@tanstack/react-query'

import { URL_LIMIT_QUERY_KEY } from './constants'
import { request } from '../../request'
import { UrlLimitData } from '../../Response'

export interface UrlLimit {
  readonly isUnlimited: boolean
  readonly pageCapturesCount: number
}

interface Props {
  readonly projectId: number
}

export const useUrlLimit = ({ projectId }: Props) => {
  const queryKey = [URL_LIMIT_QUERY_KEY, { projectId }]

  const queryResult = useQuery({
    queryKey: queryKey,
    queryFn: () => fetch(projectId),
    select: transform,
    enabled: !!projectId,
    staleTime: 1000 * 60 * 5,
  })

  return {
    ...queryResult,
  }
}

const fetch = async (projectId: number) => {
  return await request<UrlLimitData>('GET', `/api/projects/${projectId}/url_limit/`, true)
}

const transform = (response: UrlLimitData): UrlLimit => {
  return {
    isUnlimited: response.is_unlimited,
    pageCapturesCount: response.page_captures,
  }
}
