import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors, layout } from '../../../styleConstants'
import { Breadcrumbs, BreadcrumbsItemType } from '../../breadcrumbs/Breadcrumbs'
import { AggregationOption } from './AggregateOption'

interface Props {
  readonly title: string
  readonly className?: string
  readonly optionHidden?: boolean
  readonly goalHidden?: boolean
  readonly dayLimit?: number // カレンダーの選択可能範囲
  readonly adminLayout?: boolean
  readonly isContentReport?: boolean
  readonly isPageCapture?: boolean
  readonly url?: string
  readonly urlTitle?: string
  readonly breadcrumbsItems?: BreadcrumbsItemType[]
  readonly onDateRangeApply?: () => void
  readonly onGoalChange?: () => void
  readonly onScopeTypeChange?: () => void
}

export function Header(props: Props) {
  const {
    title,
    className,
    optionHidden = false,
    goalHidden = false,
    adminLayout,
    isContentReport = false,
    isPageCapture = false,
    url,
    urlTitle,
    breadcrumbsItems,
    onDateRangeApply,
    onGoalChange,
    onScopeTypeChange,
  } = props

  const isUrlBoxVisible = isContentReport || isPageCapture

  return (
    <Container className={cc([{ adminLayout, isContentReport }, className])}>
      <TitleBox className={cc({ isContentReport })}>
        {/* UrlBoxを表示しない場合は、タイトルが長いケースがあるため(ファネルレポートなど)、
            省略表示するために、要素の横幅を指定（width=100%）する */}
        <TitleWrapper width={isUrlBoxVisible ? 'unset' : '100%'}>
          {breadcrumbsItems && <Breadcrumbs items={breadcrumbsItems} style={{ margin: '0 0 0.5rem 2rem' }} />}
          <Title>{title}</Title>
        </TitleWrapper>
        {isUrlBoxVisible && (
          <UrlBox className={cc([className])}>
            <Url>{url}</Url>
            <Url className="title">{urlTitle}</Url>
          </UrlBox>
        )}
      </TitleBox>
      {!optionHidden && (
        <AggregationOption
          goalHidden={goalHidden}
          onDateRangeApply={onDateRangeApply}
          onGoalChange={onGoalChange}
          onScopeTypeChange={onScopeTypeChange}
        />
      )}
    </Container>
  )
}

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: ${layout.pageMinWidth};
  height: ${layout.headerHeight};
  background-color: ${colors.headerBg};

  &.adminLayout {
    background-color: ${colors.orange};
  }
`

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;
  width: 100%;
  min-width: 550px;
  height: 100%;

  &:not(.isContentReport) {
    min-width: 350px;
  }
`

const Title = styled.div`
  font-size: 1.4rem;
  color: ${colors.white};
  font-weight: ${layout.boldFontWeight};
  margin-left: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TitleWrapper = styled.div<{ width: string }>`
  flex-shrink: 0;
  width: ${({ width }) => width};
`

const UrlBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 4rem;
  width: 100%;
  overflow: hidden;
`

const Url = styled.div`
  width: 100%;
  word-break: break-all;
  line-height: 1.3rem;
  color: ${colors.white};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;

  &.title {
    font-size: 0.8rem;
    color: ${colors.headerTitle};
  }
`
