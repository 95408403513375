import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { GlobalContext } from '../GlobalState'
import { usePageState } from './ResetPassword/state'
import { colors, layout } from '../styleConstants'
import { AdminNormalButton } from '../components/common/Button'
import { Password } from '../components/common/Password'
import { Select } from '../components/common/Select'
import { PageHeader } from '../components/common/PageHeader'
import { SimplePageLayout } from '../components/layout/SimplePageLayout'
import { InvalidLayout } from '../components/layout/InvalidLayout'
import { PasswordRegex, PasswordMinLength } from '../util/Password'

interface ResetPasswordProps extends RouteComponentProps {
  token?: string
}

export function ResetPassword(props: ResetPasswordProps) {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { password, disabled, loading, isUrlValid, errorMessage, isTeamSelect, teamOptions },
    actions,
  } = usePageState()
  if (loading) {
    actions.verify(props.token)
    return <div />
  }
  if (!isUrlValid) {
    return (
      <InvalidLayout
        message="URLの有効期限が切れています。"
        linkMessage="こちらのページからログインしてください"
        linkPath="/login"
      />
    )
  }
  return (
    <SimplePageLayout>
      <PageHeader
        title={`パスワード再設定 | ${AccountInfo.teamName}チーム`}
        description={`パスワード再設定 | ${AccountInfo.teamName}チーム`}
      />
      <Title>パスワードの再設定</Title>
      <Form onSubmit={actions.submit} id="form" data-testid="form">
        {isTeamSelect && (
          <div>
            <Label className="label">チーム[必須]</Label>
            <DesignedSelect options={teamOptions} onChange={actions.onTeamChange} />
          </div>
        )}
        <Label className="label">新しいパスワード[必須]</Label>
        <DesignedPassword
          value={password}
          minLength={PasswordMinLength}
          pattern={PasswordRegex}
          required
          className="input"
          data-testid="password"
          onChange={actions.updatePassword}
          onBlur={actions.onBlur}
          form="form"
          look={errorMessage ? 'red' : 'normal'}
        />

        <Comment style={{ marginTop: '1rem' }}>・パスワードは最低１０文字以上必要です。</Comment>
        <Comment>
          ・パスワードはアルファベット、数字および記号のうち少なくとも２種類以上の組み合わせである必要があります。
        </Comment>
        <div style={{ marginTop: '1.5rem' }}>
          <AdminNormalButton type="submit" data-testid="button" disabled={disabled} style={{ width: '100%' }}>
            登録完了
          </AdminNormalButton>
        </div>
        {errorMessage && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}
      </Form>
    </SimplePageLayout>
  )
}

const Title = styled.h1`
  text-align: center;
  color: ${colors.lightBlue};
  margin-bottom: 2rem;
`

const Form = styled.form``

const Label = styled.div`
  font-weight: ${layout.boldFontWeight};
`

const Comment = styled.div`
  font-size: 0.8rem;
  color: ${colors.gray500};
`

const DesignedPassword = styled(Password)`
  width: 100%;
  height: 40px;
  margin-top: 0.5rem;
`

const DesignedSelect = styled(Select)`
  width: 100%;
  height: 30px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`
