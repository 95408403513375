import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'
import { DeleteDataImportSchemasPayload } from './types'

type Props = {
  projectId: string
  options: UseMutationOptions<{}, string, DeleteDataImportSchemasPayload, void>
}

export const useDeleteDataImportSchemas = ({ projectId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async ({ schemasId }: DeleteDataImportSchemasPayload) => {
      const result = await request<{}>('DELETE', `/api/projects/${projectId}/data_import_schemas/${schemasId}/`, true)
      return result
    },
    ...options,
  })
  return mutationResult
}
