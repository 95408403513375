import * as React from 'react'

export interface GlobalContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly projectId: number
  readonly baseUrl: string // /projects/1/ のようなプロジェクトIDまでのパスを保持する
}

const initialState: State = {
  projectId: 0,
  baseUrl: '',
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  setValues = (projectId: number, baseUrl: string): void => {
    this.setState({
      ...this.state,
      projectId,
      baseUrl: `${baseUrl}/`,
    })
  }
}

/**
 * プロジェクト系画面で共通使用する state と actions を返す
 *
 * @return {GlobalContextType} The context state and actions.
 */
export function useContextState(): GlobalContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)

  return { state, actions }
}
