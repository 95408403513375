import React from 'react'
import styled from 'styled-components'
import { NormalButton } from '../../common/Button'

interface Props {
  onClickSettingField: () => void
  limited?: boolean
}

export function NoSavedPanel({ onClickSettingField, limited = false }: Props) {
  return (
    <div>
      <NormalButton onClick={onClickSettingField} limited={limited}>
        フィールドを設定
      </NormalButton>
      <Notes>インポートフィールドが設定されていません。</Notes>
    </div>
  )
}

const Notes = styled.div`
  margin: 25px 0 5px;
  font-size: 16px;
`
