import React, { useState } from 'react'
import { useDownloadSftpPubkey } from '../../../../../util/hooks/api/DataImport/useDownloadSftpPubkey'
import { PublicKeyDownloadModal } from './PublicKeyDownloadModal'
import { useToast } from '../../../../../util/hooks/useToast'
import { SubActionIconButton } from '../../../../common/Button'
import { downloadFile } from '../../../../../util/downloadUtils'

type Props = {
  projectId: string
  scheduleId: number
}

export function PublicKeyDownloadButton({ projectId, scheduleId }: Props) {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
  const openModal = () => setIsModalOpened(true)
  const onClose = () => setIsModalOpened(false)
  const { openToast } = useToast()

  const { mutate, isLoading, isError, error } = useDownloadSftpPubkey({
    projectId: projectId,
    options: {
      onSuccess: (downloadUrl: string) => {
        downloadFile(downloadUrl)
        openToast({ message: `ダウンロードしました。` })
        onClose()
      },
    },
  })

  const handleDownload = () => {
    if (!scheduleId) return
    mutate({ scheduleId })
  }

  return (
    <>
      <SubActionIconButton onClick={openModal}>公開鍵ダウンロード</SubActionIconButton>

      <PublicKeyDownloadModal
        opened={isModalOpened}
        onClose={onClose}
        handleDownload={handleDownload}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
    </>
  )
}
