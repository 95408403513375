import React from 'react'
import { Modal, ModalHeadText, ModalContentWrapper, ModalButtonWrapper } from '../../../../common/Modal'
import { NormalButton, CancelButton } from '../../../../common/Button'
import { ErrorBox } from '../../../../common/ErrorBox'

interface Props {
  opened: boolean
  onClose: () => void
  handleDownload: () => void
  isLoading: boolean
  isError: boolean
  error: string | null
}

export function PublicKeyDownloadModal({ opened, onClose, handleDownload, isLoading, isError, error }: Props) {
  return (
    <Modal isOpen={opened} onClose={onClose} title="公開鍵ダウンロード" isLoading={isLoading}>
      <ModalContentWrapper>
        {isError && <ErrorBox>{typeof error === 'string' ? error : 'ダウンロードに失敗しました。'}</ErrorBox>}
        <ModalHeadText>
          公開鍵をダウンロードしてください。 <br />
          SFTPサーバーにこの公開鍵をインストールしてください。
        </ModalHeadText>
      </ModalContentWrapper>

      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton onClick={handleDownload}>ダウンロード</NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}
