import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  readonly title?: string
  readonly description?: string
}

export function PageHeader(props: Props) {
  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: 'ja',
        }}
        title={(props.title ? props.title + ' | ' : '') + 'Content Analytics'}
        meta={[
          {
            name: 'description',
            content: props.description ? props.description + ' | Content Analytics' : 'Content Analytics Description',
          },
        ]}
      />
    </div>
  )
}
