import * as React from 'react'
import styled from 'styled-components'

/**
 * 指定された数値をパーセンテージで表示する
 *
 * @param {Object} props - props object.
 * @param {number} props.value - 変換対象の値
 * @param {boolean} [props.digit=1] - 小数点の数(基本的にCAは1桁表示で統一。ゴールは2桁)
 */
export function RateValue({ value, digit = 1 }: { value: number; digit?: number }) {
  const [integerPart, decimalPart] = value.toFixed(digit).split('.')
  return (
    <Container>
      <span>{integerPart}</span>
      <DecimalValue>.{decimalPart}</DecimalValue>
      <span>%</span>
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  align-items: baseline;
`

const DecimalValue = styled.span`
  font-size: 0.8em;
`
