import React from 'react'
import {
  FunnelData,
  FunnelStepData,
  FunnelReportEditData,
  EditingStepIndex,
} from '../../../util/hooks/api/FunnelReport/types'
import { Funnel } from './Funnel'
import { AddButton } from '../../../components/common/Button'
import { CustomFilterState } from '../../../util/hooks/api/Filter/types'
import { FunnelContainer, FunnelsContainer } from '../Report/Funnels'
import { getMaxStepHeights } from '../../../util/hooks/api/FunnelReport/utils'
import { INIT_FUNNEL } from '../../../util/hooks/api/FunnelReport/constants'

interface Props {
  funnels: FunnelData[]
  isExceedFunnel: boolean
  updateEditingData: (newData: (draft: FunnelReportEditData) => void) => void
  setEditingStepIndex: React.Dispatch<React.SetStateAction<EditingStepIndex | null>>
  setEditingStep: React.Dispatch<React.SetStateAction<FunnelStepData>>
  setIsStepModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  setIsFilterModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  setFunnelIndexForEditingFilter: React.Dispatch<React.SetStateAction<number | null>>
  setEditingFilter: React.Dispatch<React.SetStateAction<CustomFilterState[] | null>>
  resetAccessCount: (funnelIndex: number, stepIndex: number) => void
}

export const Funnels = ({
  funnels,
  isExceedFunnel,
  updateEditingData,
  setEditingStepIndex,
  setEditingStep,
  setIsStepModalOpened,
  setIsFilterModalOpened,
  setFunnelIndexForEditingFilter,
  setEditingFilter,
  resetAccessCount,
}: Props) => {
  const stepTitleRefs = React.useRef<(HTMLDivElement | null)[][]>([])
  const [maxHeights, setMaxHeights] = React.useState<number[]>([])

  React.useEffect(() => {
    if (stepTitleRefs.current.length) {
      const maxStepLength = Math.max(...stepTitleRefs.current.map((refArray) => refArray.length))
      const maxStepHeights = getMaxStepHeights(maxStepLength, stepTitleRefs)
      setMaxHeights(maxStepHeights)
    }
  }, [funnels])

  const handleAddFunnel = () => {
    updateEditingData((draft) => {
      draft.funnels.push(INIT_FUNNEL)
    })
  }

  return (
    <FunnelsContainer>
      {funnels.map((funnel, funnelIndex) => (
        <Funnel
          key={funnelIndex}
          funnel={funnel}
          funnelIndex={funnelIndex}
          isExceedFunnel={isExceedFunnel}
          updateEditingData={updateEditingData}
          setEditingStepIndex={setEditingStepIndex}
          setEditingStep={setEditingStep}
          setIsStepModalOpened={setIsStepModalOpened}
          setIsFilterModalOpened={setIsFilterModalOpened}
          setFunnelIndexForEditingFilter={setFunnelIndexForEditingFilter}
          setEditingFilter={setEditingFilter}
          resetAccessCount={resetAccessCount}
          stepTitleRefs={stepTitleRefs}
          maxHeights={maxHeights}
        />
      ))}

      {!isExceedFunnel && (
        <FunnelContainer>
          <AddButton onClick={handleAddFunnel}>ファネルを追加</AddButton>
        </FunnelContainer>
      )}
    </FunnelsContainer>
  )
}
