import * as React from 'react'
import { request } from '../../util/request'
import { State } from './state'

// 処理ステップ
export const IpLimitStep = {
  none: 0,

  create: 1, // 追加
  createComplete: 2, // 追加完了

  edit: 3, // 変更
  editComplete: 4, // 変更完了

  deleteComfirm: 5, // 削除確認
  deleteComplete: 6, // 削除完了
}

export class IpLimit {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // IPアドレス追加フロー開始
  onAdd = () => {
    this.setState({
      ...this.state,
      ipLimit: {
        ...this.state.ipLimit,
        address: '',
        memo: '',
        disabled: true,
        step: IpLimitStep.create,
      },
      ipLimitErrorMessage: '',
    })
  }

  // 追加キャンセル
  onAddCancel = () => {
    this.setState({
      ...this.state,
      ipLimit: {
        ...this.state.ipLimit,
        address: '',
        memo: '',
        disabled: true,
        step: IpLimitStep.none,
      },
    })
  }

  // IPアドレス更新
  updateAddress = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      ipLimit: {
        ...this.state.ipLimit,
        address: event.currentTarget.value,
        disabled: !event.currentTarget.validity.valid,
      },
    })
  }

  // 説明文更新
  updateMemo = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      ipLimit: {
        ...this.state.ipLimit,
        memo: event.currentTarget.value,
      },
    })
  }

  // 追加実行
  onAddApply = async () => {
    try {
      await request(
        'POST',
        `/api/restricted_ips/`,
        true,
        JSON.stringify({
          address: this.state.ipLimit.address,
          memo: this.state.ipLimit.memo,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        ipLimit: {
          ...this.state.ipLimit,
          address: '',
          memo: '',
          step: IpLimitStep.none,
        },
        toastMessage: 'IPアドレス制限を追加しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ipLimit: {
          ...this.state.ipLimit,
          step: IpLimitStep.none,
        },
        ipLimitErrorMessage: typeof e === 'string' ? e : 'IPアドレスの追加に失敗しました。',
      })
    }
  }

  // 編集フロー開始
  onEdit = (id: number) => {
    const ipLimit = this.state.ipLimit.ipLimits.filter((ipLimit) => ipLimit.id === id)
    if (ipLimit) {
      this.setState({
        ...this.state,
        ipLimit: {
          ...this.state.ipLimit,
          address: ipLimit[0].address,
          memo: ipLimit[0].memo,
          editAddress: ipLimit[0].address,
          editMemo: ipLimit[0].memo,
          selectedId: id,
          disabled: true,
          disabledAddress: true,
          disabledMemo: true,
          step: IpLimitStep.edit,
        },
        ipLimitErrorMessage: '',
      })
    }
  }

  // 編集キャンセル
  onEditCancel = () => {
    this.setState({
      ...this.state,
      ipLimit: {
        ...this.state.ipLimit,
        address: '',
        memo: '',
        editAddress: '',
        editMemo: '',
        selectedId: 0,
        step: IpLimitStep.none,
      },
    })
  }

  // IPアドレス更新
  updateEditAddress = (event: React.FormEvent<HTMLInputElement>) => {
    const disabled = !(event.currentTarget.validity.valid && event.currentTarget.value !== this.state.ipLimit.address)
    this.setState({
      ...this.state,
      ipLimit: {
        ...this.state.ipLimit,
        editAddress: event.currentTarget.value,
        disabledAddress: disabled,
        disabled: !(!disabled || !this.state.ipLimit.disabledMemo),
      },
    })
  }

  // 説明文更新
  updateEditMemo = (event: React.FormEvent<HTMLInputElement>) => {
    const disabled = !(event.currentTarget.validity.valid && event.currentTarget.value !== this.state.ipLimit.memo)
    this.setState({
      ...this.state,
      ipLimit: {
        ...this.state.ipLimit,
        editMemo: event.currentTarget.value,
        disabledMemo: disabled, // 文字列が異なっていれば更新とみなす
        disabled: !(!disabled || !this.state.ipLimit.disabledAddress),
      },
    })
  }

  // 編集実行
  onEditApply = async () => {
    try {
      await request(
        'PATCH',
        `/api/restricted_ips/${this.state.ipLimit.selectedId}/`,
        true,
        JSON.stringify({
          address: this.state.ipLimit.editAddress,
          memo: this.state.ipLimit.editMemo,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        ipLimit: {
          ...this.state.ipLimit,
          address: '',
          memo: '',
          editAddress: '',
          editMemo: '',
          selectedId: 0,
          step: IpLimitStep.none,
        },
        toastMessage: 'IPアドレス制限を変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ipLimit: {
          ...this.state.ipLimit,
          address: '',
          memo: '',
          editAddress: '',
          editMemo: '',
          selectedId: 0,
          step: IpLimitStep.none,
        },
        ipLimitErrorMessage: typeof e === 'string' ? e : 'IPアドレスの編集に失敗しました。',
      })
    }
  }

  // 削除フロー開始
  onDelete = (id: number) => {
    const ipLimit = this.state.ipLimit.ipLimits.filter((ipLimit) => ipLimit.id === id)
    if (ipLimit) {
      this.setState({
        ...this.state,
        ipLimit: {
          ...this.state.ipLimit,
          address: ipLimit[0].address,
          selectedId: id,
          step: IpLimitStep.deleteComfirm,
        },
        ipLimitErrorMessage: '',
      })
    }
  }

  // 削除キャンセル
  onDeleteCancel = () => {
    this.setState({
      ...this.state,
      ipLimit: {
        ...this.state.ipLimit,
        address: '',
        selectedId: 0,
        step: IpLimitStep.none,
      },
    })
  }

  // 削除実行
  onDeleteApply = async () => {
    try {
      await request('DELETE', `/api/restricted_ips/${this.state.ipLimit.selectedId}/`, true)
      this.setState({
        ...this.state,
        reload: true,
        ipLimit: {
          ...this.state.ipLimit,
          address: '',
          memo: '',
          editAddress: '',
          editMemo: '',
          selectedId: 0,
          step: IpLimitStep.none,
        },
        toastMessage: 'IPアドレス制限を削除しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ipLimit: {
          ...this.state.ipLimit,
          address: '',
          selectedId: 0,
          step: IpLimitStep.none,
        },
        ipLimitErrorMessage: typeof e === 'string' ? e : 'IPアドレスの削除に失敗しました。',
      })
    }
  }
}
