import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { AdminNormalButton, CancelButton } from '../common/Button'
import { Input } from '../common/Input'
import { Info } from '@styled-icons/evaicons-solid/Info'

interface Props {
  readonly created?: boolean
  readonly opened: boolean
  readonly disabled?: boolean
  readonly address?: string
  readonly memo?: string
  readonly updateAddress?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly updateMemo?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onCancel: () => void
  readonly onApply?: () => void
}

export function IpLimitEdit(props: Props) {
  const { created = false, opened, disabled, address, memo, updateAddress, updateMemo, onCancel, onApply } = props
  return (
    <Modal
      isOpen={opened}
      onClose={onCancel}
      title={created ? '信頼できるIPアドレスの追加' : '信頼できるIPアドレスの編集'}
    >
      <ModalContentWrapper>
        <WarningBox>
          <Info size={24} color={colors.error} />
          <div style={{ marginLeft: '1rem' }}>
            <WarningMessage>IPアドレスが追加または更新されると、即時IPアドレスの制限が有効になります。</WarningMessage>
            <WarningMessage>
              ご利用中の環境が追加するIPアドレスからのアクセスかどうかよく確認した上で追加してください。
            </WarningMessage>
          </div>
        </WarningBox>

        <Box>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '250px', marginRight: '2rem' }}>
              <Label>IPアドレス</Label>
              <DesignedInput
                placeholder="0.0.0.0 or 0.0.0.0/24"
                type="text"
                value={address}
                required
                data-testid="input-address"
                onChange={updateAddress}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Label>説明文 (任意)</Label>
              <DesignedInput type="text" value={memo} data-testid="input-memo" onChange={updateMemo} />
            </div>
          </div>

          <div style={{ fontSize: '0.9rem', marginTop: '1rem' }}>
            *IPアドレスを範囲指定したい場合にはCIDR方式での入力が可能です
          </div>
        </Box>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <AdminNormalButton disabled={disabled} onClick={onApply}>
          {created ? '追加' : '変更'}
        </AdminNormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const WarningBox = styled.div`
  display: flex;
  align-items: center;
  width: 640px;
  border: 1px solid ${colors.error};
  border-radius: 2px;
  padding: 0.5rem;
  margin-bottom: 16px;
`

const WarningMessage = styled.div`
  color: ${colors.error};

  & + & {
    margin-top: 0.5rem;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 640px;
`

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 35px;
`
