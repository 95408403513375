import { useQuery } from '@tanstack/react-query'

import { PROJECT_PV_QUERY_KEY } from '../constants'
import { request } from '../../../request'
import { ProjectPv, ProjectPvResponse } from './types'

interface Props {
  readonly projectId: number
  readonly yearMonth: string
}

export const useProjectPv = ({ projectId, yearMonth }: Props) => {
  const queryKey = [PROJECT_PV_QUERY_KEY, { projectId, yearMonth }]

  return useQuery({
    queryKey: queryKey,
    queryFn: () => fetch(projectId, yearMonth),
    select: transform,
    staleTime: 1000 * 60 * 5,
  })
}

const fetch = async (projectId: number, yearMonth: string) => {
  const path = `/api/projects/${projectId}/pv/?year_month=${yearMonth}`
  return await request<ProjectPvResponse>('GET', path, true)
}

const transform = (response: ProjectPvResponse): ProjectPv => {
  const pageViewCount = response.page_view?.count ?? 0
  const teamPageViewCount = response.team_page_view?.count ?? 0
  return {
    limit: response.limit,
    pageViewCount: pageViewCount,
    teamPageViewCount: teamPageViewCount,
  }
}
