import { AccountInfo } from '../GlobalState'

type PageHeaderInfo = {
  title: string
  description: string
}

export const makeProjectPageHeaderInfo = (pageTitle: string, AccountInfo: AccountInfo): PageHeaderInfo => {
  const title = `${pageTitle} | ${AccountInfo.projectName} | ${AccountInfo.teamName}チーム`
  return { title: title, description: title }
}
