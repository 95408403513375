import styled from 'styled-components'
import { GRID_BORDER_COLOR } from '../../../styleConstants'

export const BaseCell = styled.div<{ barPercent?: number; barColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 8px;
  font-size: 12px;
  line-height: 1.4;
  word-break: break-all;
  box-shadow: 0 0 0 1px ${GRID_BORDER_COLOR};
  white-space: nowrap;

  background: linear-gradient(
    to right,
    ${(props) => props.barColor + ' ' + props.barPercent + '%'},
    transparent ${(props) => props.barPercent + '%'}
  );
  background-size: 100% 24px;
  background-repeat: no-repeat;
  background-position: center;
`

export const BaseHeaderCell = styled(BaseCell)`
  font-size: 12px;
  font-weight: bold;
`
