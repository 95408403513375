import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { useCaptureView } from '../../../util/hooks/useCaptureView'
import { ContentLocation } from '../../../util/hooks/api/useContentReport'

interface Props {
  readonly location: ContentLocation
  readonly backgroundColor?: string
  readonly scale?: number
}

export function CaptureGroupNumber({ location, backgroundColor, scale = 1 }: Props) {
  const { isMetricVisible } = useCaptureView()
  return (
    <Container isMetricVisible={isMetricVisible} backgroundColor={backgroundColor} scale={scale}>
      {location.linkContentId}
    </Container>
  )
}

type ContainerProps = {
  readonly isMetricVisible: boolean
  readonly backgroundColor?: string
  readonly scale: number
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: ${({ scale }) => `${scale * 48}px`};
  font-size: ${({ scale }) => `${scale * 22}px`};
  font-weight: bold;
  background-color: ${({ backgroundColor }) => backgroundColor || colors.contentOrange.orange5};
  color: ${colors.white};
`
