import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors, layout } from '../../styleConstants'
import { navigate } from '@gatsbyjs/reach-router'
import { Dev } from '@styled-icons/fa-brands/Dev'
import icon from '../../../images/logo.svg'
import { isLocalOrDevelopment } from '../../util/getEnv'

interface Props {
  readonly size: 'large' | 'small'
  readonly style?: React.CSSProperties
  readonly linked?: boolean
}

export function Logo({ size, style, linked }: Props) {
  const onClick = () => {
    navigate('/')
  }
  return (
    <Container
      data-testid="full-logo"
      className={cc([size, process.env.NODE_ENV, { linked }])}
      style={style}
      onClick={onClick}
    >
      <Img src={icon} className={cc([process.env.NODE_ENV])} />
      {isLocalOrDevelopment() && (
        <Develop>
          <Dev size={24} color={`${colors.orange}`} />
          開発環境
        </Develop>
      )}
    </Container>
  )
}

const Container = styled.div`
  &.large {
    width: 195px;
    min-height: 50px;

    &.development,
    &.local {
      min-height: calc(50px + ${layout.navigationDevMargin});
    }
  }

  &.small {
    width: 169px;
    min-height: 43px;

    &.development,
    &.local {
      min-height: calc(43px + ${layout.navigationDevMargin});
    }
  }

  &.linked {
    cursor: pointer;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;

  &.development,
  &.local {
    height: calc(100% - ${layout.navigationDevMargin});
  }
`

const Develop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: ${colors.orange};
`
