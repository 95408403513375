import * as React from 'react'
import styled from 'styled-components'
import { colors as constantsColor, style } from '../../styleConstants'
import { ResponsiveBar } from '@nivo/bar'

interface Props {
  readonly width?: number | string
  readonly height?: number | string
  readonly data: any[]
  readonly margin?: { top: number; right: number; left: number; bottom: number }
  readonly keys?: string[]
  readonly layout?: 'horizontal' | 'vertical'
  readonly maxValue?: number | 'auto'
  readonly padding?: number
  readonly enableLabel?: boolean
  readonly labelSkipWidth?: number
  readonly legends?: boolean
  readonly legendsTranslateY?: number
  readonly itemWidth?: number
  readonly colors?: (bar: any) => void
  readonly hasGrid?: boolean // グリッド表示が必要か
}

/*
    margin がないと目盛り等が表示できなくなる
    そのため、height は marginTop と marginBottom を考慮した高さにする必要がある
    */
export function BarChart(props: Props) {
  const {
    data,
    width = 800,
    height = 200,
    margin = { top: 40, right: 90, bottom: 80, left: 90 },
    keys,
    layout,
    maxValue,
    padding = 0.1,
    enableLabel,
    labelSkipWidth,
    legends = true,
    legendsTranslateY,
    itemWidth = 150,
    colors,
    hasGrid,
  } = props

  const createTickValues = (max: number | 'auto' | undefined, grid?: boolean) => {
    if (max === 'auto' || max === undefined) {
      return undefined
    }
    // グリッド必要なし
    if (!grid) {
      return [0, max]
    }
    // 小さい数値の場合簡易表示
    if (max < 100) {
      if (max === 0) {
        return [0]
      }
      return [0, max]
    }
    const len = max.toString().length
    const base = max.toString()[0] === '1' ? 10 ** (len - 1) / 10 : 10 ** (len - 1)
    const digit = max.toString()[0] === '1' ? 10 : Math.floor(max / base) % 10
    let list = [0]
    // 二桁目が０以外の場合
    // 25000など
    if (len > 1 && max.toString()[1] !== '0') {
      const num = Math.floor(Number(max.toString()[0] + max.toString()[1]) / 3)
      list.push(max.toString()[0] === '1' ? base * num : (base * num) / 10)
      list.push(max.toString()[0] === '1' ? base * num * 2 : (base * num * 2) / 10)
    } else {
      for (let i = 1; i < digit; ++i) {
        // 分割数が多くなりそうなら偶数値のみにする
        if (digit > 5) {
          if (i % 2 === 0) {
            list.push(base * i)
          }
        } else {
          list.push(base * i)
        }
      }
    }
    list.push(max)
    return list
  }
  const tickValues = createTickValues(maxValue, hasGrid)

  return (
    <Container style={{ width: width, height: height, zIndex: 1000 }}>
      <ResponsiveBar
        data={data}
        margin={margin}
        keys={keys}
        layout={layout}
        maxValue={maxValue}
        indexBy="title"
        padding={padding}
        enableLabel={enableLabel}
        labelSkipWidth={labelSkipWidth}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        valueFormat={(value: number) => value.toLocaleString()}
        axisBottom={
          tickValues
            ? { format: (v: number) => v.toLocaleString(), tickValues: tickValues }
            : { format: (v: number) => v.toLocaleString() }
        }
        colors={colors ? colors : { scheme: 'nivo' }}
        tooltip={(data: any) => {
          return (
            <div
              style={{
                padding: '0.3rem 0.5rem',
                background: `${constantsColor.white}`,
                boxShadow: `0px 2px 4px ${style.shadow}`,
                border: `1px solid ${constantsColor.gray200}`,
                transform: 'translate(15%, 0)',
                fontSize: '0.8rem',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{ width: '16px', height: '16px', marginRight: '0.5rem', backgroundColor: data.color }}
                ></div>
                {`${data.id}: ${Number(data.value).toLocaleString()}`}
              </div>
            </div>
          )
        }}
        legends={
          !legends
            ? undefined
            : [
                {
                  dataFrom: 'keys',
                  anchor: 'bottom',
                  //anchor: 'right',
                  direction: 'row',
                  //direction: 'column',
                  justify: false,
                  translateX: 0,
                  translateY: legendsTranslateY,
                  itemsSpacing: 2,
                  itemWidth: itemWidth,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]
        }
      />
    </Container>
  )
}

const Container = styled.div``
