import * as React from 'react'
import {
  Content,
  ContentsSummary,
  PageSummary,
  SortItemKey,
  SortState,
} from '../../../../util/hooks/api/useContentReport'
import { CalenderState } from '../../../common/DayPickerRange'
import { AllContentsGridHeader } from './AllContentsGridHeader'
import { GridContentsListArea } from '../GridContentsListArea'
import { AllContentsGridItem } from './AllContentsGridItem'
import { GridArea } from '../GridArea'
import { CaptureHistoryModal } from './CaptureHistoryModal'
import { UnavailableChartPanel } from '../ChartPanel'
interface Props {
  readonly projectId: string
  readonly pageId: number
  readonly calenderState: CalenderState
  readonly pageSummary: PageSummary
  readonly items: Content[]
  readonly contentsSummary: ContentsSummary
  readonly selectedIds: number[]
  readonly sortState: SortState
  readonly gridScrollTo: number | null
  readonly canViewPartialContent?: boolean
  readonly onClick: React.MouseEventHandler<HTMLDivElement>
  readonly onCheckboxClick: React.MouseEventHandler<HTMLDivElement>
  readonly handleHeaderCheckboxChange: () => void
  readonly onSort: (key: SortItemKey) => void
  readonly onReset?: () => void
  readonly onEdit: (id: number) => void
}

export function AllContentsGrid({
  projectId,
  pageId,
  calenderState,
  pageSummary,
  items,
  contentsSummary,
  selectedIds,
  sortState,
  gridScrollTo,
  canViewPartialContent,
  onClick,
  onCheckboxClick,
  handleHeaderCheckboxChange,
  onSort,
  onReset,
  onEdit,
}: Props) {
  const [captureHistoryModalOpen, setCaptureHistoryModalOpen] = React.useState(false)
  const [selectedContentIdByHistory, setSelectedContentIdByHistory] = React.useState(0)

  const openCaptureHistoryModal = (contentId: number) => {
    setSelectedContentIdByHistory(contentId)
    setCaptureHistoryModalOpen(true)
  }
  const closeCaptureHistoryModal = () => {
    setCaptureHistoryModalOpen(false)
  }

  return (
    <>
      <GridArea pageSummary={pageSummary}>
        <UnavailableChartPanel />

        <AllContentsGridHeader
          items={items}
          contentsSummary={contentsSummary}
          selectedIds={selectedIds}
          sortState={sortState}
          handleHeaderCheckboxChange={handleHeaderCheckboxChange}
          onSort={onSort}
        />

        {items.length > 0 && (
          <GridContentsListArea gridScrollTo={gridScrollTo} contents={items} onReset={onReset}>
            {items.map((item) => (
              <AllContentsGridItem
                key={item.id}
                item={item}
                canViewPartialContent={canViewPartialContent}
                onClick={onClick}
                onCheckboxClick={onCheckboxClick}
                onEdit={onEdit}
                checked={selectedIds.includes(item.id)}
                selected={selectedIds.includes(item.id)}
                openCaptureHistoryModal={openCaptureHistoryModal}
                calenderState={calenderState}
              />
            ))}
          </GridContentsListArea>
        )}
      </GridArea>
      <CaptureHistoryModal
        open={captureHistoryModalOpen}
        onClose={closeCaptureHistoryModal}
        contentId={selectedContentIdByHistory}
        projectId={projectId}
        pageId={pageId}
        calenderState={calenderState}
      />
    </>
  )
}
