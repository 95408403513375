import * as React from 'react'
import { colors, layout } from '../../styleConstants'
import styled from 'styled-components'
import { Spinner } from './Spinner'

export const EmbeddedLoading: React.VFC = () => {
  return (
    <LoadingContainer>
      <LoadingLabel>Loading</LoadingLabel>
      <Spinner type="Moon" loading={true} width={250} height={25} />
    </LoadingContainer>
  )
}

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const LoadingLabel = styled.div`
  color: ${colors.lightBlue};
  font-size: 2rem;
  font-weight: ${layout.boldFontWeight};
  margin-right: 0.5rem;
`
