import * as React from 'react'
import { request } from '../../util/request'
import { State, CategoryType } from './state'

// 処理ステップ
export const AuditLogStep = {
  none: 0,
  confirm: 1, // 確認
  complete: 2, // 完了
}

interface DownloadData {
  readonly download_url: string
}

export class AuditLog {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // ダウンロード開始
  onDownload = async () => {
    try {
      const download: DownloadData = await request(
        'POST',
        `/api/auditlog/download/`,
        true,
        JSON.stringify({
          yearmonth: this.state.auditLog.selectedDate,
        }),
      )
      if (download.download_url) {
        window.location.href = download.download_url
      }
    } catch (e) {
      this.setState({
        ...this.state,
        auditLogErrorMessage: typeof e === 'string' ? e : '監査ログのダウンロードに失敗しました。',
      })
    }
  }

  // 月を変更
  onDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      reload: true,
      scrollCategory: CategoryType.audit,
      auditLog: {
        ...this.state.auditLog,
        selectedDate: e.target.options[e.target.selectedIndex].value,
      },
    })
  }
}
