import * as React from 'react'
import styled from 'styled-components'
import { GRID_TEMPLATE_COLUMNS } from '..'
import { colors } from '../../../styleConstants'
import { ContentTag } from '../../../util/hooks/api/useContentReport'

interface Props {
  readonly children: React.ReactNode
  readonly dataId: number
  readonly selected: boolean
  readonly disabled?: boolean
  readonly tags?: ContentTag[]
  readonly onClick: React.MouseEventHandler<HTMLDivElement>
}

export function ContentRow({ children, dataId, selected, disabled, tags, onClick }: Props) {
  return (
    <Container>
      <Row data-id={dataId} selected={selected} disabled={disabled} onClick={onClick} tags={tags || []}>
        {children}
      </Row>
      {selected && <SelectedFrame />}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

type RowProps = {
  selected: boolean
  disabled?: boolean
  height?: number
  tags: ContentTag[]
}
const Row = styled.div<RowProps>`
  display: grid;
  grid-template-columns: ${GRID_TEMPLATE_COLUMNS};
  grid-auto-rows: ${({ tags }) => {
    return 0 < tags.length ? 47 : 36
  }}px;
  grid-gap: 1px;
  background-color: ${({ disabled, selected }) => {
    if (disabled) return colors.gray200
    if (selected) return colors.lightSalmon
    return colors.white
  }};
  &:hover {
    background-color: ${({ selected }) => (selected ? colors.lightSalmon : colors.gray50)};
  }
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
`

const SelectedFrame = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 1px);
  background-color: transparent;
  pointer-events: none;
  border: 1px solid ${colors.orange};
  z-index: 1;
`
