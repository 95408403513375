import { useCallback } from 'react'
import { useDispatch } from '../../redux/Store'
import { open, close } from '../../redux/toastSlice'

export const useToast = () => {
  const dispatch = useDispatch()

  const openToast = useCallback(
    ({ message }: { message: string }) => {
      dispatch(open({ message }))
    },
    [dispatch],
  )
  const closeToast = useCallback(() => {
    dispatch(close())
  }, [dispatch])

  return {
    openToast,
    closeToast,
  }
}
