import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useToast } from '../../../../util/hooks/useToast'
import { Loading } from '../../../common/Loading'
import { EmbeddedLoading } from '../../../common/EmbeddedLoading'
import {
  SCHEDULE_INTERVALS,
  MAX_PORT_NUMBER,
  MIN_PORT_NUMBER,
  WEEKDAY,
} from '../../../../util/hooks/api/DataImport/constants'
import { ImportScheduleFormData } from '../../../../util/hooks/api/DataImport/types'
import { useDataImportSchedule } from '../../../../util/hooks/api/DataImport/useDataImportSchedule'
import { useCreateDataImportSchedule } from '../../../../util/hooks/api/DataImport/useCreateDataImportSchedule'
import { useUpdateDataImportSchedule } from '../../../../util/hooks/api/DataImport/useUpdateDataImportSchedule'
import { PublicKeyDownloadButton } from './PublicKeyDownloadButton'
import { ScheduleDeleteButton } from './ScheduleDeleteButton'
import { CancelButton, NormalButton } from '../../../common/Button'
import { Panel } from '../'
import { ErrorBox } from '../../../common/ErrorBox'
import { ScheduleForm } from './ScheduleForm'

type Props = {
  projectId: string
}

const INIT_SCHEDULE_DATA = {
  enable: false,
  sftpUser: '',
  sftpHost: '',
  sftpPort: 22,
  interval: SCHEDULE_INTERVALS.MONTHLY,
  time: '00:00:00',
  weekday: WEEKDAY.MONDAY,
  day: 1,
} as const

export function ExecutionSftpSchedulePanel({ projectId }: Props) {
  const [scheduleData, setScheduleData] = useState<ImportScheduleFormData>({ ...INIT_SCHEDULE_DATA })
  const { openToast } = useToast()

  const {
    data: importScheduleData,
    isError: isDataError,
    error: dataError,
    isLoading: isDataLoading,
    invalidate: invalidateData,
  } = useDataImportSchedule({
    projectId: projectId,
  })

  const {
    mutate: createMutate,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError,
  } = useCreateDataImportSchedule({
    projectId: projectId,
  })

  const scheduleId: number | undefined = importScheduleData?.scheduleId

  const {
    mutate: updateMutate,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError,
  } = useUpdateDataImportSchedule({
    projectId: projectId,
    scheduleId: scheduleId,
  })

  const isEmpty: boolean =
    !scheduleData.sftpUser ||
    !scheduleData.sftpHost ||
    typeof scheduleData.sftpPort !== 'number' ||
    typeof scheduleData.interval !== 'number' ||
    !scheduleData.time

  const isValidPort: boolean =
    typeof scheduleData.sftpPort === 'number' &&
    MIN_PORT_NUMBER <= scheduleData.sftpPort &&
    scheduleData.sftpPort <= MAX_PORT_NUMBER

  const isFormChanged: boolean = importScheduleData
    ? JSON.stringify({ ...importScheduleData, scheduleId: undefined }) !== JSON.stringify(scheduleData)
    : true

  useEffect(() => {
    if (!importScheduleData) {
      return
    }

    const { enable, sftpUser, sftpHost, sftpPort, interval, time, weekday, day } = importScheduleData
    setScheduleData({ enable, sftpUser, sftpHost, sftpPort, interval, time, weekday, day })
  }, [importScheduleData])

  const handleSave = () => {
    if (scheduleId) {
      updateMutate(scheduleData, {
        onSuccess: () => {
          openToast({ message: 'スケジュールされたインポートを更新しました。' })
          invalidateData()
        },
      })
    } else {
      createMutate(scheduleData, {
        onSuccess: () => {
          openToast({ message: 'スケジュールされたインポートを作成しました。' })
          invalidateData()
        },
      })
    }
  }

  const handleDeleteSuccess = () => {
    openToast({ message: 'スケジュールされたインポートを削除しました。' })
    invalidateData()
    setScheduleData({ ...INIT_SCHEDULE_DATA })
  }

  const onCancel = () => {
    openToast({ message: 'スケジュールされたインポートの変更をキャンセルしました。' })
    if (importScheduleData) {
      const { enable, sftpUser, sftpHost, sftpPort, interval, time, weekday, day } = importScheduleData
      setScheduleData({ enable, sftpUser, sftpHost, sftpPort, interval, time, weekday, day })
    }
  }

  return (
    <>
      <Panel>
        {isDataLoading && <EmbeddedLoading />}
        {(isCreating || isUpdating) && <Loading />}

        {isDataError && (
          <ErrorBox>
            {typeof dataError === 'string' ? dataError : 'スケジュールされたインポートの取得に失敗しました。'}
          </ErrorBox>
        )}
        {isCreateError && (
          <ErrorBox>
            {typeof createError === 'string' ? createError : 'スケジュールされたインポートの保存に失敗しました。'}
          </ErrorBox>
        )}
        {isUpdateError && (
          <ErrorBox>
            {typeof updateError === 'string' ? updateError : 'スケジュールされたインポートの更新に失敗しました。'}
          </ErrorBox>
        )}

        {!isDataLoading && !isDataError && (
          <>
            <ScheduleForm scheduleData={scheduleData} setScheduleData={setScheduleData} />
            <ButtonArea>
              {scheduleId && (
                <>
                  <ScheduleDeleteButton
                    projectId={projectId}
                    scheduleId={scheduleId}
                    handleDeleteSuccess={handleDeleteSuccess}
                  />
                  <PublicKeyDownloadButton projectId={projectId} scheduleId={scheduleId} />
                  <CancelButton onClick={onCancel} disabled={!isFormChanged}>
                    キャンセル
                  </CancelButton>
                </>
              )}

              <NormalButton onClick={handleSave} disabled={isEmpty || !isValidPort || !isFormChanged}>
                保存
              </NormalButton>
            </ButtonArea>
          </>
        )}
      </Panel>
    </>
  )
}

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0 16px;
`
