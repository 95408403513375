import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../styleConstants'
import { GoalEventType } from '../../util/Goal'
import { ConditionOptions, ValueConditionOptions } from './CreateGoal'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { CancelButton, NormalButton } from '../common/Button'
import { Select } from '../common/Select'
import { Input } from '../common/Input'
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle'
import { WarningBox } from '../common/WarningBox'
import { HelpLink } from '../common/HelpLink'
import { HELP_LINKS } from '../../constants'

interface Props {
  readonly opened: boolean
  readonly disabled?: boolean
  readonly name: string
  readonly selected: boolean[]
  readonly condition: (number | null)[]
  readonly value: string[]
  readonly onEditName?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onEditSelected: (index: number) => void
  readonly onEditCondition: (event: React.FormEvent<HTMLSelectElement>, index: number) => void
  readonly onEditValue: (event: React.FormEvent<HTMLInputElement>, index: number) => void
  readonly onCancel: () => void
  readonly onApply?: () => void
}

export function GoalEventEdit(props: Props) {
  const {
    opened,
    disabled,
    name,
    selected,
    condition,
    value,
    onEditName,
    onEditSelected,
    onEditCondition,
    onEditValue,
    onCancel,
    onApply,
  } = props

  // ゴールイベントが一つでも選択されているか
  const isSelectedEvent = selected.filter((type) => type === true).length > 0

  return (
    <Modal isOpen={opened} onClose={onCancel} title="ゴールの編集">
      <ModalContentWrapper>
        <CategoryBox>
          <Category>目標名</Category>
          <DesignedInput
            type="text"
            value={name}
            required
            placeholder={'目標名'}
            style={{ width: '220px', marginLeft: '0' }}
            onChange={onEditName}
          />
        </CategoryBox>

        <WarningBoxContainer>
          <WarningBoxWrapper>
            <WarningBox>
              自動送信されるカスタムイベントをゴール設定する場合、アクションやラベルに入力するURLの末尾のスラッシュ（/）は除外して設定してください。
            </WarningBox>
          </WarningBoxWrapper>
        </WarningBoxContainer>

        <CategoryBox className={cc({ bordered: !isSelectedEvent })}>
          <EventConditionLabel>
            イベント条件
            <HelpBox>
              <HelpLink title={'カスタムイベントのゴール設定方法'} link={HELP_LINKS.CUSTOM_EVENT_GOAL_HOW_TO} />
            </HelpBox>
          </EventConditionLabel>
          <Item>
            <CheckIcon
              className={cc({ checked: selected[GoalEventType.category] })}
              onClick={() => onEditSelected(GoalEventType.category)}
            />
            <Label className={cc({ checked: selected[GoalEventType.category] })}>カテゴリ</Label>
            <DesignedSelect
              options={ConditionOptions}
              defaultValue={condition[GoalEventType.category] || ''}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onEditCondition(e, GoalEventType.category)}
            />
            <DesignedInput
              type="text"
              value={value[GoalEventType.category]}
              placeholder={'カテゴリ'}
              required={selected[GoalEventType.category]}
              onChange={(e) => onEditValue(e, GoalEventType.category)}
            />
          </Item>
          <Item>
            <CheckIcon
              className={cc({ checked: selected[GoalEventType.action] })}
              onClick={() => onEditSelected(GoalEventType.action)}
            />
            <Label className={cc({ checked: selected[GoalEventType.action] })}>アクション</Label>
            <DesignedSelect
              options={ConditionOptions}
              defaultValue={condition[GoalEventType.action] || ''}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onEditCondition(e, GoalEventType.action)}
            />
            <DesignedInput
              type="text"
              value={value[GoalEventType.action]}
              placeholder={'アクション'}
              required={selected[GoalEventType.action]}
              onChange={(e) => onEditValue(e, GoalEventType.action)}
            />
          </Item>
          <Item>
            <CheckIcon
              className={cc({ checked: selected[GoalEventType.label] })}
              onClick={() => onEditSelected(GoalEventType.label)}
            />
            <Label className={cc({ checked: selected[GoalEventType.label] })}>ラベル</Label>
            <DesignedSelect
              options={ConditionOptions}
              defaultValue={condition[GoalEventType.label] || ''}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onEditCondition(e, GoalEventType.label)}
            />
            <DesignedInput
              type="text"
              value={value[GoalEventType.label]}
              placeholder={'ラベル'}
              required={selected[GoalEventType.label]}
              onChange={(e) => onEditValue(e, GoalEventType.label)}
            />
          </Item>
          <Item>
            <CheckIcon
              className={cc({ checked: selected[GoalEventType.value] })}
              onClick={() => onEditSelected(GoalEventType.value)}
            />
            <Label className={cc({ checked: selected[GoalEventType.value] })}>値</Label>
            <DesignedSelect
              options={ValueConditionOptions}
              defaultValue={condition[GoalEventType.value] || ''}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onEditCondition(e, GoalEventType.value)}
            />
            <DesignedInput
              type="number"
              value={value[GoalEventType.value]}
              min={0}
              required={selected[GoalEventType.value]}
              onChange={(e) => onEditValue(e, GoalEventType.value)}
            />
          </Item>
          {!isSelectedEvent && <ErrorMessage>少なくとも１つのイベント条件を指定してください。</ErrorMessage>}
        </CategoryBox>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <NormalButton disabled={disabled} onClick={onApply}>
          変更
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const CategoryBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 2px solid ${colors.white};

  &.bordered {
    border-left: 2px solid ${colors.error};
  }

  & + & {
    margin-top: 1.5rem;
  }
`

const Category = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const EventConditionLabel = styled(Category)`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

const HelpBox = styled.div`
  margin-left: 50px;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  & + & {
    margin-top: 0.5rem;
  }
`

const Label = styled.div`
  font-weight: 600;
  width: 90px;

  &:not(.checked) {
    color: ${colors.gray400};
  }
`

const DesignedInput = styled(Input)`
  flex: 1;
  width: 100%;
  height: 30px;
  margin-left: 1rem;
  background: #ddd;
`

const DesignedSelect = styled(Select)`
  width: 130px;
  height: 30px;
  font-size: 0.9rem;
  padding: 0.2rem 0.5rem;
`

const CheckIcon = styled(CheckCircle).attrs({
  size: 24,
  color: `${colors.gray400}`,
})`
  width: 30px;
  cursor: pointer;
  border-radius: 30px;
  margin-right: 1rem;

  &.checked {
    color: ${colors.orange};
  }
`

const ErrorMessage = styled.div`
  color: ${colors.error};
  margin: 1rem 0 0.5rem;
`

const WarningBoxContainer = styled.div`
  margin-top: 8px;
`
const WarningBoxWrapper = styled.div`
  margin-top: 16px;
`
