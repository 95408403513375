import { configureStore } from '@reduxjs/toolkit'
import { useSelector as rawUseSelector, useDispatch as rawUseDispatch, TypedUseSelectorHook } from 'react-redux'
import navigationReducer from './navigationSlice'
import toastReducer from './toastSlice'

export const store = configureStore({
  reducer: { navigation: navigationReducer, toast: toastReducer },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector
export const useDispatch = () => rawUseDispatch<AppDispatch>()
