import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { colors, layout } from '../styleConstants'
import { Authority } from '../util/Authority'
import { usePageState, CategoryType } from './Admin/state'
import { AdminModals } from './Admin/AdminModals'
import { GlobalContext } from '../GlobalState'
import { PageLayout } from '../components/layout/PageLayout'
import { Loading } from '../components/common/Loading'
import { PageHeader } from '../components/common/PageHeader'
import { Input } from '../components/common/Input'
import { CancelButton, AdminNormalButton, AddButton, AddMemberButton } from '../components/common/Button'
import { CaptureLimitList } from '../components/list/CaptureLimitList'
import { UserList } from '../components/list/UserList'
import { IpLimitList } from '../components/list/IpLimitList'
import { AuditLogList } from '../components/list/AuditLogList'
import { DataExportList } from '../components/list/DataExportList'
import { HelpLink } from '../components/common/HelpLink'
import { HELP_LINKS } from '../constants'
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle'
import { LayoutTextWindowReverse } from '@styled-icons/bootstrap/LayoutTextWindowReverse'
import { Users } from '@styled-icons/heroicons-outline/Users'
import { UsersSlash } from '@styled-icons/fa-solid/UsersSlash'
import { UserPlus } from '@styled-icons/boxicons-regular/UserPlus'
import { DeleteBin5 } from '@styled-icons/remix-line/DeleteBin5'
import { LaptopDismiss } from '@styled-icons/fluentui-system-filled/LaptopDismiss'
import { List } from '@styled-icons/fa-solid/List'
import { ArrowToTop } from '@styled-icons/boxicons-regular/ArrowToTop'
import { FileExport } from '@styled-icons/boxicons-solid/FileExport'
import { AccordionBox } from '../components/common/AccordionBox'
import { PvCountArea } from './Admin/PvCountArea'
import { ErrorBox } from '../components/common/ErrorBox'

interface AdminProps extends RouteComponentProps {
  category?: string // 初期表示されている項目を指定
}

export function Admin(props: AdminProps) {
  const {
    state: { AccountInfo, PlanInfo },
    actions: globalActions,
  } = React.useContext(GlobalContext)
  const { state, actions } = usePageState()

  // ライブラリの関数を直接実行
  const panelRefs = React.useRef<any[]>([])

  const {
    loading,
    reload,
    errorMessage,
    isScroll,
    projectErrorMessage,
    captureLimitErrorMessage,
    teamErrorMessage,
    memberErrorMessage,
    inactiveErrorMessage,
    inviteErrorMessage,
    ipLimitErrorMessage,
    auditLogErrorMessage,
    exportErrorMessage,
    contentRef,
    divRef,
    info,
    project,
    captureLimit,
    member,
    invite,
    inactive,
    ipLimit,
    auditLog,
    dataExport,
  } = state

  const errors = [
    errorMessage,
    projectErrorMessage,
    captureLimitErrorMessage,
    captureLimit.totalRate,
    teamErrorMessage,
    memberErrorMessage,
    inactiveErrorMessage,
    inviteErrorMessage,
    ipLimitErrorMessage,
    auditLogErrorMessage,
    exportErrorMessage,
  ]

  React.useEffect(() => {
    if (loading) {
      actions.fetch(props.category!, Authority.canViewGcpSa(AccountInfo.permissions))
    }
  }, [])
  React.useEffect(() => {
    if (reload) {
      actions.refetch(Authority.canViewGcpSa(AccountInfo.permissions))
      // 権限更新
      globalActions.updatePermissions()
    }
  }, [reload])
  React.useEffect(() => {
    if (isScroll) {
      actions.scroll()
    }
  }, [isScroll])

  React.useEffect(() => {
    // ライブラリの関数を直接実行
    panelRefs.current.forEach((ref) => {
      ref?.calcHeight()
    })
  }, [...errors])

  const [pvErrorMessage, setPvErrorMessage] = React.useState('')

  return (
    <div>
      <PageHeader title={`組織管理`} description={`組織管理`} />
      {(loading || reload) && <Loading />}
      <AdminModals state={state} actions={actions} />
      <PageLayout
        headerTitle={`組織管理 ${props.category ? `| ${actions.getCategoryTitle(props.category)}` : ''}`}
        optionHidden={true}
        baseUrl={props.uri?.split(props.path!).join('')}
        adminLayout={true}
        contentRef={contentRef}
      >
        {!Authority.canViewTeamSettings(AccountInfo.permissions) && <div>権限がないため表示できません。</div>}
        {!loading && !reload && Authority.canViewTeamSettings(AccountInfo.permissions) && (
          <Container>
            <div>
              {errorMessage && <ErrorBox data-testid="error-message">{errorMessage}</ErrorBox>}
              {pvErrorMessage && <ErrorBox>{pvErrorMessage}</ErrorBox>}

              {props.category === 'info' && (
                <AccordionBox
                  title="組織情報"
                  icon={<InfoCircle />}
                  isAdmin
                  accordionProps={{ defaultExpanded: true }}
                  ref={divRef[CategoryType.info]}
                >
                  {teamErrorMessage && <ErrorMessage data-testid="team-error-message">{teamErrorMessage}</ErrorMessage>}
                  <ProjectContainer>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Label>組織名</Label>
                        <DesignedInput
                          data-testid="team-name-input"
                          type="text"
                          value={info.tempTeamName}
                          style={{ marginBottom: '1.5rem' }}
                          onChange={actions.info.updateName}
                        />
                      </div>
                    </div>

                    <Label>プラン</Label>
                    <ProjectInfo>{`${PlanInfo.name}`}</ProjectInfo>

                    <Label>月間キャプチャ上限数</Label>
                    <ProjectInfo>
                      {PlanInfo.displayMonthlyCrawlLimit > 0
                        ? `約 ${PlanInfo.displayMonthlyCrawlLimit.toLocaleString()}`
                        : 0}
                    </ProjectInfo>

                    <PvCountArea setErrorMessage={setPvErrorMessage} />

                    <ButtonContainer>
                      {info.teamName !== info.tempTeamName && (
                        <CancelButton data-testid="team-name-button" onClick={actions.info.onCancel}>
                          キャンセル
                        </CancelButton>
                      )}
                      <AdminNormalButton disabled={info.disabled} onClick={actions.info.onApply}>
                        保存
                      </AdminNormalButton>
                    </ButtonContainer>
                  </ProjectContainer>
                </AccordionBox>
              )}

              {(props.category === 'project' || props.category === 'captureLimit') && (
                <AccordionBoxes>
                  <AccordionBox
                    title="プロジェクト一覧"
                    icon={<LayoutTextWindowReverse />}
                    isAdmin
                    accordionProps={{ defaultExpanded: props.category === 'project' }}
                    ref={divRef[CategoryType.project]}
                  >
                    {projectErrorMessage && (
                      <ErrorMessage data-testid="project-error-message">{projectErrorMessage}</ErrorMessage>
                    )}
                    {/* 表示権限 */}
                    {Authority.canViewAddProject(AccountInfo.permissions) ? (
                      <div style={{ marginBottom: '1.5rem' }}>
                        {/* プランによる制限あり */}
                        <AddButton
                          disabled={!Authority.canAddProject(AccountInfo.permissions)}
                          onClick={
                            Authority.canAddProject(AccountInfo.permissions)
                              ? actions.project.onCreate
                              : () => alert('現在のプランではご利用いただけません')
                          }
                        >
                          新規プロジェクトを追加
                        </AddButton>
                      </div>
                    ) : (
                      <div />
                    )}
                    <div style={{ color: `${colors.gray500}` }}>プロジェクト一覧</div>
                    {project.projects.map((project, index) => {
                      return (
                        <ProjectItem key={index}>
                          <ProjectItemBox>{project.name}</ProjectItemBox>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Link onClick={() => actions.project.navigation(project.id)}>プロジェクト設定</Link>
                            <DeleteIcon
                              onClick={() => {
                                actions.project.onDelete(project.id)
                              }}
                            />
                          </div>
                        </ProjectItem>
                      )
                    })}
                  </AccordionBox>
                  <AccordionBox
                    title="ページキャプチャ上限設定"
                    icon={<ArrowToTop />}
                    isAdmin
                    accordionProps={{ defaultExpanded: true }}
                    ref={divRef[CategoryType.captureLimit]}
                  >
                    {captureLimitErrorMessage && (
                      <ErrorMessage data-testid="capture-limit-error-message">{captureLimitErrorMessage}</ErrorMessage>
                    )}
                    <CaptureLimitList
                      disabled={captureLimit.disabled}
                      limits={captureLimit.limits}
                      teamLimit={captureLimit.teamLimit}
                      totalRate={captureLimit.totalRate}
                      onChange={actions.captureLimit.onChange}
                      onReset={actions.captureLimit.onReset}
                      onApply={actions.captureLimit.onApply}
                    />
                  </AccordionBox>
                </AccordionBoxes>
              )}

              {(props.category === 'member' || props.category === 'invite' || props.category === 'inactive') && (
                <AccordionBoxes>
                  <AccordionBox
                    title="メンバー管理"
                    icon={<Users />}
                    isAdmin
                    accordionProps={{ defaultExpanded: props.category === 'member' }}
                    ref={divRef[CategoryType.member]}
                  >
                    {memberErrorMessage && (
                      <ErrorMessage data-testid="member-error-message">{memberErrorMessage}</ErrorMessage>
                    )}
                    {Authority.canAddTeamMember(AccountInfo.permissions) && (
                      <div>
                        <AddMemberButton onClick={actions.member.onAddMember}>メンバーを招待</AddMemberButton>
                      </div>
                    )}
                    <UserList
                      users={member.users}
                      canChangeTeamMember={Authority.canChangeTeamMember(AccountInfo.permissions)}
                      canDeleteTeamMember={Authority.canDeleteTeamMember(AccountInfo.permissions)}
                      onEdit={actions.member.onEditUser}
                      onDelete={actions.member.onDeleteUser}
                    />
                  </AccordionBox>
                  <AccordionBox
                    title="招待中のユーザー"
                    icon={<UserPlus />}
                    isAdmin
                    accordionProps={{ defaultExpanded: props.category === 'member' || props.category === 'invite' }}
                    ref={divRef[CategoryType.invite]}
                  >
                    {inviteErrorMessage && (
                      <ErrorMessage data-testid="invite-error-message">{inviteErrorMessage}</ErrorMessage>
                    )}
                    <UserList
                      inviteUsers={invite.users}
                      canChangeTeamMember={Authority.canChangeTeamMember(AccountInfo.permissions)}
                      invited
                      onRevoke={actions.invite.onRevoke}
                    />
                  </AccordionBox>
                  <AccordionBox
                    title="無効化されているユーザー"
                    icon={<UsersSlash />}
                    isAdmin
                    accordionProps={{ defaultExpanded: props.category === 'member' || props.category === 'inactive' }}
                    ref={divRef[CategoryType.inactive]}
                  >
                    {inactiveErrorMessage && (
                      <ErrorMessage data-testid="inactive-error-message">{inactiveErrorMessage}</ErrorMessage>
                    )}
                    <UserList
                      inactiveUsers={inactive.users}
                      canChangeTeamMember={Authority.canChangeTeamMember(AccountInfo.permissions)}
                      inactived
                      onReturn={actions.inactive.onReturn}
                    />
                  </AccordionBox>
                </AccordionBoxes>
              )}

              {props.category === 'audit' && (
                <AccordionBox
                  title="監査ログ"
                  icon={<List />}
                  isAdmin
                  accordionProps={{ defaultExpanded: true }}
                  ref={divRef[CategoryType.audit]}
                >
                  {auditLogErrorMessage && (
                    <ErrorMessage data-testid="audit-log-error-message">{auditLogErrorMessage}</ErrorMessage>
                  )}
                  <AuditLogList
                    logs={auditLog.logs}
                    options={auditLog.options}
                    selectedValue={auditLog.selectedDate}
                    canDownloadAuditlog={Authority.canDownloadAuditlog(AccountInfo.permissions)}
                    onDownload={actions.auditLog.onDownload}
                    onDateChange={actions.auditLog.onDateChange}
                  />
                </AccordionBox>
              )}

              {props.category === 'ipLimit' && (
                <AccordionBox
                  title="IPアドレス制限"
                  icon={<LaptopDismiss />}
                  isAdmin
                  accordionProps={{ defaultExpanded: true }}
                  ref={divRef[CategoryType.ipLimit]}
                >
                  {ipLimitErrorMessage && (
                    <ErrorMessage data-testid="ip-limit-error-message">{ipLimitErrorMessage}</ErrorMessage>
                  )}
                  <IpLimitList
                    ipLimits={ipLimit.ipLimits}
                    canAddRestrictedIpAddress={Authority.canAddRestrictedIpAddress(AccountInfo.permissions)}
                    canChangeRestrictedIpAddress={Authority.canChangeRestrictedIpAddress(AccountInfo.permissions)}
                    canDeleteRestrictedIpAddress={Authority.canDeleteRestrictedIpAddress(AccountInfo.permissions)}
                    onAdd={actions.ipLimit.onAdd}
                    onEdit={actions.ipLimit.onEdit}
                    onDelete={actions.ipLimit.onDelete}
                  />
                </AccordionBox>
              )}

              {props.category === 'export' && (
                <AccordionBox
                  title="データエクスポート"
                  icon={<FileExport />}
                  isAdmin
                  accordionProps={{ defaultExpanded: true }}
                  ref={divRef[CategoryType.export]}
                >
                  {exportErrorMessage && (
                    <ErrorMessage style={{ marginBottom: '1.5rem' }} data-testid="export-error-message">
                      {exportErrorMessage}
                    </ErrorMessage>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                    <HelpLink
                      title={'コンテンツアナリティクスのデータを外部に連携する'}
                      link={HELP_LINKS.DATA_EXPORT_HOW_TO}
                    />
                  </div>
                  {/* サービスアカウントがないまたは権限がない */}
                  {dataExport.accounts.length === 0 && (
                    // プランによる制限あり
                    <div>
                      <AddButton
                        limited={!Authority.canAddGcpSa(AccountInfo.permissions)}
                        onClick={
                          Authority.canAddGcpSa(AccountInfo.permissions)
                            ? actions.dataExport.onCreateGcpAccount
                            : () => alert('現在のプランではご利用いただけません')
                        }
                      >
                        GCPサービスアカウントの作成
                      </AddButton>
                    </div>
                  )}
                  {dataExport.accounts.length !== 0 && (
                    <DataExportList
                      accounts={dataExport.accounts}
                      projects={dataExport.projects}
                      keys={dataExport.keys}
                      disabledAddProject={dataExport.selectProjects.length === 0} // 選択できるプロジェクトがない
                      restKey={dataExport.restKey}
                      canViewAccount={Authority.canViewGcpSa(AccountInfo.permissions)}
                      canDeleteAccount={Authority.canDeleteGcpSa(AccountInfo.permissions)}
                      canViewProject={Authority.canViewGcsExport(AccountInfo.permissions)}
                      canAddProject={Authority.canAddGcsExport(AccountInfo.permissions)}
                      canDeleteProject={Authority.canDeleteGcsExport(AccountInfo.permissions)}
                      canViewKey={Authority.canViewGcpSaKey(AccountInfo.permissions)}
                      canAddKey={Authority.canAddGcpSaKey(AccountInfo.permissions)}
                      canChangeKey={Authority.canChangeGcpSaKey(AccountInfo.permissions)}
                      canDeleteKey={Authority.canDeleteGcpSaKey(AccountInfo.permissions)}
                      onAddProject={actions.dataExport.onAddProject}
                      onAddKey={actions.dataExport.onAddKey}
                      onProjectInfo={actions.dataExport.onOpenProjectInfo}
                      onKeyInfo={actions.dataExport.onOpenKeyInfo}
                      onDeleteAccount={actions.dataExport.onDeleteAccount}
                      onDeleteProject={actions.dataExport.onDeleteProject}
                      onDeleteKey={actions.dataExport.onDeleteKey}
                    />
                  )}
                </AccordionBox>
              )}

              <Padding />
            </div>
          </Container>
        )}
      </PageLayout>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${layout.settingPageWidth};
  height: 100%;
  padding-top: 2rem;
  padding-left: 2rem;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    height: calc(100vh - ${layout.headerHeight});
    min-height: calc(100vh - ${layout.headerHeight});
  }
`

const ProjectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ProjectInfo = styled.div`
  margin-bottom: 24px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  gap: 0 1rem;
`

const ProjectItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid ${colors.gray400};
`

const ProjectItemBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  width: 700px;
  height: 100%;
`

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`

const Link = styled.a`
  color: ${colors.link.base};
  text-decoration: underline;
  cursor: pointer;

  &:visited {
    color: ${colors.link.visited};
  }
`

const DeleteIcon = styled(DeleteBin5).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  cursor: pointer;
  margin-left: 1.5rem;
`

const DesignedInput = styled(Input)`
  width: 250px;
  height: 30px;
`

const Padding = styled.div`
  min-height: 3rem;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`

const AccordionBoxes = styled.div`
  > :nth-child(n + 2) {
    margin-top: 28px;
  }
`
