import React from 'react'
import styled from 'styled-components'
import { CancelButton, NormalButton } from '../../../common/Button'

interface Props {
  onSave: () => void
  onCancel: () => void
  isSaveDisabled: boolean
}

export function EditButtons({ onSave, onCancel, isSaveDisabled }: Props) {
  return (
    <EditButtonsArea>
      <StyledCancelButton onClick={onCancel}>キャンセル</StyledCancelButton>
      <NormalButton disabled={isSaveDisabled} onClick={onSave}>
        保存
      </NormalButton>
    </EditButtonsArea>
  )
}

const EditButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledCancelButton = styled(CancelButton)`
  margin: 0 15px 0 0;
`
