import React from 'react'
import { useCookies } from 'react-cookie'

import { Goal } from './api/Goal'
import { useGoalList } from './api/Goal/useGoalList'

const COOKIE_KEY = 'goalId'
const URL_SEARCH_PARAM = 'goal'

interface Props {
  readonly projectId: number
}

interface Result {
  readonly goalId: number
  readonly setGoalId: (value: number) => void
}

/**
 * 与えられたプロジェクトIDに基づいてゴールIDを取得・設定する
 *
 * @param {Object} props - The props object containing the project ID.
 * @param {number} props.projectId - The ID of the project.
 * @returns {Object} - ゴールIDとゴールIDを設定する関数を含むオブジェクト
 */
export const useGoalId = ({ projectId }: Props): Result => {
  const [cookies, setCookie] = useCookies([COOKIE_KEY])
  const { data } = useGoalList({ projectId })

  const goalId = React.useMemo(() => {
    if (!data || data.length === 0) return 0

    // cookieに保存された値が、ゴールマスタに存在するか確認し、存在しない場合はリストの先頭を返す
    const goalIds = data.map((goal) => goal.id)
    const value = Number(cookies[COOKIE_KEY])
    if (isNaN(value) || !goalIds.includes(value)) return data[0].id

    return value
  }, [data, cookies[COOKIE_KEY]])

  const setGoalId = (value: number) => {
    setCookie(COOKIE_KEY, value, { path: '/' })
  }

  return { goalId, setGoalId }
}

/**
 * 検索パラメータで見つかったゴールIDを返します
 * ゴールマスタに値が存在しない場合は、nullを返す
 *
 * @param {Goal[]} goalList - ゴールマスタ
 * @returns {number | null} - 検索パラメータで見つかったゴールID、見つからない場合はNULL
 */
export const getSearchParamsGoalId = (goalList: Goal[]): number | null => {
  const searchParams = new URLSearchParams(location.search)
  const value = Number(searchParams.get(URL_SEARCH_PARAM) || 0)
  const goalIds = goalList.map((goal) => goal.id)
  if (goalIds.includes(value)) {
    return value
  }

  return null
}

/**
 * 検索パラメータを設定する。
 *
 * @param {number | null} value - 設定する値。null`の場合、関数は何も変更せずに戻る。
 *
 * @returns {void}
 */
export const setSearchParamsGoalId = (value: number | null): void => {
  if (value === null) return
  const currentUrl = new URL(window.location.href)
  currentUrl.searchParams.set(URL_SEARCH_PARAM, value.toString())
  history.replaceState(null, '', currentUrl.toString())
}
