import * as React from 'react'
import { useCookies } from 'react-cookie'
import { request } from '../../util/request'
import { UserData, ProjectsData } from '../../util/Response'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly loading: boolean
  readonly logined: boolean
  readonly userId: number
  readonly projectId: number
}

class Actions {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly cookies: { [name: string]: any },
    private readonly setCookie: any,
  ) {}

  check = async () => {
    try {
      const user: UserData = await request('GET', '/api/user/', true)
      const projects: ProjectsData = await request('GET', '/api/projects/', true)
      let id = projects.results.length !== 0 ? projects.results[0].id : 0
      if (this.cookies.projectId && this.cookies.projectId.userId === user.id && this.cookies.projectId.id > 0) {
        // ユーザーの所属しているプロジェクトに保存していたIDが存在するかチェック
        if (
          projects.results.length !== 0 &&
          projects.results.filter((result) => result.id === Number(this.cookies.projectId.id)).length > 0
        ) {
          id = Number(this.cookies.projectId.id)
        }
      }
      this.setState({
        ...this.state,
        loading: false,
        logined: true,
        userId: user.id,
        projectId: id,
      })
    } catch {
      this.setState({
        ...this.state,
        loading: false,
        logined: false,
      })
    }
  }

  saveCookie = () => {
    // ログアウト時にuserIDが0になっているのではじく
    if (this.state.userId !== 0 && this.state.projectId !== 0) {
      if (
        this.cookies.projectId &&
        (this.cookies.projectId.userId !== this.state.userId || this.cookies.projectId.id !== this.state.projectId)
      ) {
        this.setCookie('projectId', { userId: this.state.userId, id: this.state.projectId }, { path: '/' })
      }
    }
  }
}

const initialState: State = {
  loading: true,
  logined: false,
  userId: 0,
  projectId: 0,
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const [cookies] = useCookies()
  const setCookie = useCookies()[1]
  const actions = new Actions(state, setState, cookies, setCookie)
  return { state, actions }
}
