import { useState } from 'react'
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { request } from '../../../request'
import { DataImportCsvData } from './types'
import { DATA_IMPORT_CSV_QUERY_KEY } from '../constants'
import { IMPORT_LOG_POLLING_INTERVAL } from './constants'

type DataImportCsvResponse = {
  results: DataImportCsvResultsResponse[]
}

type DataImportCsvResultsResponse = {
  enable: boolean
}

type Props = {
  projectId: string
  options?: UseQueryOptions<DataImportCsvData | null>
}

export const useDataImportCsv = ({ projectId, options }: Props) => {
  const queryClient = useQueryClient()
  const queryKey = [DATA_IMPORT_CSV_QUERY_KEY, { projectId }]

  const [isManualRefetching, setIsManualRefetching] = useState(false)
  const invalidate = async () => {
    setIsManualRefetching(true)
    // 連打防止のため1秒pendする
    await setTimeout(async () => {
      await queryClient.invalidateQueries({ queryKey })
      setIsManualRefetching(false)
    }, 1000)
  }

  const setDataImportCsv = (
    updater:
      | DataImportCsvData
      | undefined
      | ((oldData: DataImportCsvData | undefined) => DataImportCsvData | undefined),
  ) => {
    queryClient.setQueryData<DataImportCsvData>(queryKey, updater)
    queryClient.invalidateQueries({ queryKey })
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const data = await request<DataImportCsvResponse>(
        'GET',
        `/api/projects/${projectId}/data_import_csv_uploads/`,
        true,
      )

      if (!data.results.length) {
        return null
      }
      const result: DataImportCsvResultsResponse = data.results[0]
      const formatted: DataImportCsvData = {
        isImporting: result.enable,
      }
      return formatted
    },
    refetchInterval: IMPORT_LOG_POLLING_INTERVAL,
    ...options,
  })

  return {
    ...queryResult,
    isManualRefetching,
    invalidate,
    setDataImportCsv,
  }
}
