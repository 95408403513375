import * as React from 'react'
import { addDays, isAfter } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'

import { UrlParams } from '../../util/UrlParams'
import { getDateStringYMD, isSameOrBefore, yyyymmddToDate } from '../../util/Date'
import { DeviceType } from '../../util/hooks/useDeviceType'
import { GlobalContext, PlanInfo } from '../../GlobalState'
import { CalenderState } from '../../components/common/DayPickerRange'
import { OptionProps } from '../../components/common/Select'
import { Goal } from '../../util/hooks/api/Goal'
import { ProjectPv } from '../../util/hooks/api/PageView/types'
import { CALENDER_INIT_END_DATE, CALENDER_INIT_START_DATE } from '../../env'

export interface ContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly uuid: string // queryKeyなどに追加し、簡易なcacheクリアに使用する
  readonly isParamsError: boolean
  readonly paramsErrorMessages: string[]
  readonly teamPvCount: number
  readonly teamPvLimit: number
  readonly goalIds: number[]
  readonly goalOptions: OptionProps[]
  readonly calenderState: CalenderState
}

const initialState: State = {
  uuid: '',
  isParamsError: false,
  paramsErrorMessages: [],
  teamPvCount: 0,
  teamPvLimit: 0,
  goalIds: [],
  goalOptions: [],
  calenderState: {
    startDate: CALENDER_INIT_START_DATE,
    endDate: CALENDER_INIT_END_DATE,
  },
}

// 一覧表示件数のデフォルト値・選択肢を定義
const LIST_ITEMS_COUNT_DEFAULT = 20
const LIST_ITEMS_COUNT_CHOICES = [20, 50, 100, 500, 1000] as const
type ListItemsCountChoice = typeof LIST_ITEMS_COUNT_CHOICES[number]

const GOAL_ID_DEFAULT = 0

class Actions {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly planInfo: PlanInfo,
  ) {}

  /**
   * プロジェクトに登録済みのゴール情報からOptionsを事前に生成し、セットする
   *
   * @param {Goal[]} goalList - The list of goals to set as options.
   * @returns {void}
   */
  setGoalOptions = (goalList: Goal[]): void => {
    const goalIds = goalList.map((goal) => goal.id)
    const goalOptions = goalList.map((goal) => {
      return { label: goal.name, value: goal.id }
    })
    this.setState({
      ...this.state,
      goalOptions,
      goalIds,
    })
  }

  setValues = (params: URLSearchParams, goalList: Goal[], pvData: ProjectPv) => {
    const reportParams = UrlParams.getReportPageUrlParams(params)

    let startDate = null
    if (reportParams.start_date) {
      startDate = validateStartDate(reportParams.start_date, this.planInfo.reportDaysLimit)[1]
    }

    let endDate = null
    if (reportParams.end_date) {
      endDate = validateEndDate(reportParams.end_date)[1]
    }

    const goalIds = goalList.map((goal) => goal.id)
    const goalOptions = goalList.map((goal) => {
      return { label: goal.name, value: goal.id }
    })

    this.setState({
      ...this.state,
      teamPvLimit: pvData.limit,
      teamPvCount: pvData.teamPageViewCount,
      calenderState: {
        startDate: startDate ?? this.state.calenderState.startDate,
        endDate: endDate ?? this.state.calenderState.endDate,
      },
      goalOptions,
      goalIds,
      uuid: uuidv4(),
    })
  }

  setCalenderState = (startDate: Date, endDate: Date) => {
    this.setState({
      ...this.state,
      calenderState: {
        startDate: startDate,
        endDate: endDate,
      },
    })
  }

  setSearchParamsCalender = (startDate: Date, endDate: Date): void => {
    const currentUrl = new URL(window.location.href)
    history.replaceState(null, '', UrlParams.createDateParam(currentUrl, startDate, endDate))
  }

  /**
   * URLパラメータを検証し、エラーが見つかった場合はstateにエラーメッセージを設定する
   *
   * @param {URLSearchParams} params - 検証するURLパラメータ
   * @returns {void}
   */
  validateUrlParams = (params: URLSearchParams): void => {
    const { start_date, end_date, device, goal, disp } = UrlParams.getReportPageUrlParams(params)

    const [isDataStartError, startDate] = validateStartDate(start_date, this.planInfo.reportDaysLimit)
    const [isDataEndError, endDate] = validateEndDate(end_date)
    const isDateError = validateConsistencyStartAndEndDates(startDate, endDate)
    const isDeviceError = validateDevice(device as DeviceType)
    const [isDispError] = validateDisp(disp)
    const [isGoalError] = validateGoal(goal, this.state.goalIds)

    const errorList: string[] = []
    if (isDataStartError || isDataEndError || isDateError) {
      const limitDay = addDays(new Date(), -this.planInfo.reportDaysLimit)
      const yesterday = addDays(new Date(), -1)
      errorList.push(
        `・カレンダー（${getDateStringYMD(limitDay)} から ${getDateStringYMD(yesterday)} までが選択できます）`,
      )
    }
    if (isDeviceError) errorList.push('・デバイス（pc、mobile に対応しています）')
    if (isDispError) errorList.push('・表示する件数（対応していない表示数です）')
    if (isGoalError) errorList.push('・ゴール（指定されたゴールは存在しません）')

    this.setState({
      ...this.state,
      paramsErrorMessages: errorList,
      isParamsError: errorList.length > 0,
    })
  }
}

/**
 * 入力パラメータ start_date の値を検証します
 * 値が有効でない場合は有効な日付を初期値として返す（有効な場合はDateにcastした値を返す）
 *
 * @param {string | null} value - 検証する値
 * @param {number} reportDaysLimit - カレンダー選択可能な本日を起点とした日数
 *
 * @returns {[boolean, Date]} - 値が有効かどうか、および有効な開始日を示すタプル
 */
const validateStartDate = (value: string | null, reportDaysLimit: number): [boolean, Date] => {
  if (!value) return [false, CALENDER_INIT_START_DATE]

  const castedValue = new Date(yyyymmddToDate(value))

  // isAfterは同日はfalseを返すため、-1をセットする。
  const limitDay = addDays(new Date(), -reportDaysLimit - 1)

  // Limit以降の日付であること（Limit日はOK）
  if (isAfter(limitDay, castedValue)) return [true, limitDay]

  return [false, castedValue]
}

/**
 * 入力パラメータ end_date の値を検証します
 * 値が有効でない場合は有効な日付を初期値として返す（有効な場合はDateにcastした値を返す）
 *
 * @param {string | null} value - 検証する値
 *
 * @returns {[boolean, Date]} - 値が有効かどうか、および有効な終了日を示すタプル
 */
const validateEndDate = (value: string | null): [boolean, Date] => {
  if (!value) return [false, CALENDER_INIT_END_DATE]

  const castedValue = new Date(yyyymmddToDate(value))

  // 当日以前の日付であること（当日はOK）
  if (isSameOrBefore(new Date(), castedValue)) return [true, addDays(new Date(), -1)]

  return [false, castedValue]
}

/**
 * 入力パラメータ start_date と end_date の整合性を検証する
 *
 * @param {Date} startDate - The start date.
 * @param {Date} endDate - The end date.
 * @returns {boolean} - 開始日が終了日の後にある場合はtrueを返し、そうでない場合はfalseを返す
 */
const validateConsistencyStartAndEndDates = (startDate: Date, endDate: Date): boolean => {
  return isAfter(startDate, endDate)
}

/**
 * 入力パラメータ device の値を検証します
 * 本メソッドでは有効な値は返さない (useDeviceTypeで管理しているため)
 *
 * @param {string | null} value - 検証する値
 *
 * @returns {boolean} - 値が有効かどうか
 */
const validateDevice = (value: DeviceType): boolean => {
  return value && value !== 'pc' && value !== 'mobile'
}

/**
 * 入力パラメータ disp の値を検証します
 * 値が有効でない場合は有効な選択肢を初期値として返す（有効な場合はnumberにcastした値を返す）
 *
 * @param {string | null} value - 検証する値
 *
 * @returns {[boolean, Date]} - 値が有効かどうか、および有効な選択肢を示すタプル
 */
const validateDisp = (value: string | null): [boolean, number] => {
  if (!value) return [false, LIST_ITEMS_COUNT_DEFAULT]

  // 一致する選択肢なし
  const num = Number(value) as ListItemsCountChoice
  if (!LIST_ITEMS_COUNT_CHOICES.includes(num)) return [true, LIST_ITEMS_COUNT_DEFAULT]

  return [false, num]
}

/**
 * 入力パラメータ goal の値を検証します
 * 値が有効でない場合は0を初期値として返す（有効な場合はnumberにcastした値を返す）
 *
 * @param {string | null} value - 検証する値
 * @param {number[]} goals - プロジェクト登録済みのゴールIDリスト
 *
 * @returns {[boolean, Date]} - 値が有効かどうか、および有効な選択肢を示すタプル
 */
const validateGoal = (value: string | null, goals: number[]): [boolean, number] => {
  if (!value) return [false, GOAL_ID_DEFAULT]

  const goalId = Number(value)

  // 一致するゴールなし
  if (!goals.includes(goalId)) return [true, GOAL_ID_DEFAULT]

  return [false, goalId]
}

/**
 * レポート系画面で共通使用する state と actions を返す
 *
 * @return {ContextType} The context state and actions.
 */
export function useContextState(): ContextType {
  const {
    state: { PlanInfo },
  } = React.useContext(GlobalContext)

  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState, PlanInfo)

  return { state, actions }
}
