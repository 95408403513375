import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'
import { ScopeCondition } from '../../components/list/DimensionList'

export class Dimension {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  checkDisabled = ({
    name,
    scope,
    querySourced,
    activated,
  }: {
    name?: string
    scope?: number
    querySourced?: boolean
    activated?: boolean
  }) => {
    const selectedDimension = this.state.dimension.dimensions.find((item) => item.id === this.state.dimension.editId)
    if (!selectedDimension) return false
    const editingValue = {
      name: name || this.state.dimension.name,
      scope: scope || this.state.dimension.scope,
      querySourced: querySourced !== undefined ? querySourced : this.state.dimension.querySourced,
      activated: activated !== undefined ? activated : this.state.dimension.activated,
    }
    return (
      selectedDimension.name === editingValue.name &&
      selectedDimension.scope === editingValue.scope &&
      selectedDimension.querySourced === editingValue.querySourced &&
      selectedDimension.activated === editingValue.activated
    )
  }

  // 追加
  onAdd = () => {
    this.setState({
      ...this.state,
      dimension: {
        ...this.state.dimension,
        opened: true,
        index: 0,
        name: '',
        scope: ScopeCondition.user, // 初期選択はユーザー
        querySourced: false,
        activated: true,
        created: true,
        disabled: true,
      },
    })
  }

  // 編集
  onEdit = (id: number) => {
    const dimension = this.state.dimension.dimensions.find((dimension) => dimension.id === id)
    if (dimension) {
      // 編集内容をセットしてモーダルに渡す
      this.setState({
        ...this.state,
        dimension: {
          ...this.state.dimension,
          opened: true,
          editId: id,
          index: dimension.index,
          name: dimension.name,
          scope: dimension.scope,
          querySourced: dimension.querySourced,
          activated: dimension.activated,
          created: false,
          disabled: true,
        },
      })
    }
  }

  // キャンセル
  onCancel = () => {
    this.setState({
      ...this.state,
      dimension: {
        ...this.state.dimension,
        opened: false,
        editId: 0,
        index: 0,
        name: '',
        scope: ScopeCondition.user,
        querySourced: false,
        activated: false,
        created: false,
        disabled: false,
        isConfirm: false,
        message: '',
      },
    })
  }

  // 追加実行
  onCreate = async () => {
    try {
      await request(
        'POST',
        `/api/projects/${this.state.project.id}/custom_dimensions/`,
        true,
        JSON.stringify({
          name: this.state.dimension.name,
          scope: this.state.dimension.scope,
          activated: this.state.dimension.activated,
          query_sourced: this.state.dimension.querySourced,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        dimension: {
          ...this.state.dimension,
          opened: false,
          isConfirm: false,
          message: 'カスタムディメンションを作成しました。',
        },
        toastMessage: 'カスタムディメンションを追加しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        dimension: {
          ...this.state.dimension,
          opened: false,
        },
        dimensionErrorMessage: typeof e === 'string' ? e : 'カスタムディメンションの作成に失敗しました。',
      })
    }
  }

  // 編集を保存
  onApply = async () => {
    try {
      await request(
        'PATCH',
        `/api/projects/${this.state.project.id}/custom_dimensions/${this.state.dimension.editId}/`,
        true,
        JSON.stringify({
          name: this.state.dimension.name,
          scope: this.state.dimension.scope,
          activated: this.state.dimension.activated,
          query_sourced: this.state.dimension.querySourced,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        dimension: {
          ...this.state.dimension,
          opened: false,
          isConfirm: false,
          message: 'カスタムディメンションを変更しました',
        },
        toastMessage: 'カスタムディメンションを変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        dimension: {
          ...this.state.dimension,
          opened: false,
        },
        dimensionErrorMessage: typeof e === 'string' ? e : 'カスタムディメンションの保存に失敗しました。',
      })
    }
  }

  // 名前更新
  onNameUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    this.setState({
      ...this.state,
      dimension: {
        ...this.state.dimension,
        name: value,
        disabled: !event.currentTarget.validity.valid || this.checkDisabled({ name: value }),
      },
    })
  }

  // 範囲変更
  onScopeChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const value = Number(event.currentTarget.value)
    this.setState({
      ...this.state,
      dimension: {
        ...this.state.dimension,
        scope: value,
        disabled: this.checkDisabled({ scope: value }),
      },
    })
  }

  // クエリー文字由来変更
  onQuerySourcedChange = () => {
    const value = !this.state.dimension.querySourced
    this.setState({
      ...this.state,
      dimension: {
        ...this.state.dimension,
        querySourced: value,
        disabled: this.checkDisabled({ querySourced: value }),
      },
    })
  }

  // 状態変更
  onActivatedChange = () => {
    const value = !this.state.dimension.activated
    this.setState({
      ...this.state,
      dimension: {
        ...this.state.dimension,
        activated: value,
        disabled: this.checkDisabled({ activated: value }),
      },
    })
  }
}
