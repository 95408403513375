import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { CancelButton, NormalButton } from '../common/Button'
import { Input } from '../common/Input'
import { Select } from '../common/Select'
import { Spinner } from '../common/Spinner'

export const Options = {
  pattern: {
    none: 0,
    full: 1, // 完全一致
    head: 2, // 前方一致
    regexp: 3, // 正規表現一致
    partial: 4, // 部分一致
    rear: 5, // 後方一致
  },
  event: {
    none: 0,
    exec: 1, // 取得する
    notExec: 2, // 取得しない
  },
  interval: {
    none: 0, // なし
    onece: 5, // 初回のみ
    higt: 10, // 高
    middle: 20, // 中
    low: 30, // 低
  },
}

interface IntervalData {
  [index: number]: string
}

export const OptionsList = {
  pattern: ['', '完全一致', '前方一致', '正規表現一致', '部分一致', '後方一致'],
  event: ['', '取得する', '取得しない'],
}

export const IntervalList: IntervalData = {
  0: '',
  5: '初回のみ',
  10: '高(各日間隔)',
  20: '中(各週間隔)',
  30: '低(各月間隔)',
}

// ページ登録パターン
const patternOptions = [
  { label: '完全一致', value: Options.pattern.full },
  { label: '前方一致', value: Options.pattern.head },
  { label: '正規表現一致', value: Options.pattern.regexp },
  { label: '部分一致', value: Options.pattern.partial },
  //{ label: '後方一致', value: Options.pattern.rear }, // バックエンドは対応しているので必要になったら使用する
]
const firstPatternOptions = [
  { label: '-', value: Options.pattern.none },
  { label: '完全一致', value: Options.pattern.full },
  { label: '前方一致', value: Options.pattern.head },
  { label: '正規表現一致', value: Options.pattern.regexp },
  { label: '部分一致', value: Options.pattern.partial },
  //{ label: '後方一致', value: Options.pattern.rear }, // バックエンドは対応しているので必要になったら使用する
]
// プランによる制限がある
const patternOptionsLimit = [{ label: '完全一致', value: Options.pattern.full }]
const firstPatternOptionsLimit = [
  { label: '-', value: Options.pattern.none },
  { label: '完全一致', value: Options.pattern.full },
]

// ページキャプチャ
const eventOptions = [
  { label: '取得する', value: Options.event.exec },
  { label: '取得しない', value: Options.event.notExec },
]
const firstEventOptions = [
  { label: '-', value: Options.event.none },
  { label: '取得する', value: Options.event.exec },
  { label: '取得しない', value: Options.event.notExec },
]

// 取得間隔
const intervalOptions = [
  { label: '初回のみ', value: Options.interval.onece },
  { label: '高(各日間隔)', value: Options.interval.higt },
  { label: '中(各週間隔)', value: Options.interval.middle },
  { label: '低(各月間隔)', value: Options.interval.low },
]

interface Props {
  readonly created?: boolean
  readonly opened: boolean
  readonly loading?: boolean
  readonly allDisabled?: boolean
  readonly disabled?: boolean
  readonly page?: string
  readonly pattern?: number
  readonly event?: number
  readonly interval?: number
  readonly pageCount: number
  readonly estimateCount: number
  readonly firstPage?: boolean // 初回ページ入力チェック
  readonly firstPattern?: boolean // 初回パターン入力チェック
  readonly firstEvent?: boolean // 初回イベント入力チェック
  readonly isUnlimited?: boolean // 無制限はtrue、制限ありはfalse
  readonly isRegexError?: boolean
  readonly errorMessage?: string
  readonly updatePage?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly updatePattern?: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly updateEvent?: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly updateInterval?: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly onFocus?: () => void
  readonly onBlur?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onCancel: () => void
  readonly onApply?: () => void
}

export function CaptureControlEdit(props: Props) {
  const {
    created = false,
    loading,
    allDisabled,
    disabled,
    page,
    pattern,
    event,
    interval,
    pageCount,
    estimateCount,
    firstPage,
    firstPattern,
    firstEvent,
    isUnlimited,
    isRegexError,
    errorMessage,
    updatePage,
    updatePattern,
    updateEvent,
    updateInterval,
    onFocus,
    onBlur,
    onCancel,
    onApply,
  } = props

  // 初回入力が完了しているか
  const isFirstInput = !firstPage && !firstPattern && !firstEvent

  return (
    <Modal
      isOpen={true}
      onClose={loading ? () => {} : onCancel}
      title={created ? '計測対象ページの追加' : '計測対象ページの編集'}
    >
      <ModalContentWrapper>
        <Label style={{ marginTop: '0' }}>ページURL</Label>
        <div style={{ display: 'flex' }}>
          <DesignedSelect
            disabled={allDisabled}
            defaultValue={pattern}
            options={
              isUnlimited
                ? firstPattern
                  ? firstPatternOptions
                  : patternOptions
                : firstPattern
                ? firstPatternOptionsLimit
                : patternOptionsLimit
            }
            onChange={updatePattern}
            style={{ marginRight: '1rem' }}
          />

          <DesignedInput
            type="text"
            required
            disabled={allDisabled}
            defaultValue={page}
            placeholder="URL"
            onChange={updatePage}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>

        <Label>取得可否</Label>
        <DesignedSelect
          disabled={allDisabled}
          defaultValue={event}
          options={firstEvent ? firstEventOptions : eventOptions}
          onChange={updateEvent}
        />

        <Label style={{ color: event === Options.event.exec ? `` : `${colors.gray300}` }}>頻度</Label>
        <DesignedSelect
          disabled={allDisabled ? allDisabled : event === Options.event.exec ? false : true}
          value={interval}
          options={intervalOptions}
          onChange={updateInterval}
        />

        {!loading ? (
          <div style={{ height: '120px' }}>
            <Label>ページ数</Label>
            <div>{!isFirstInput ? '設定が完了すると計算されます' : pageCount.toLocaleString()}</div>

            <Label>月間キャプチャ数予測</Label>
            <div>{!isFirstInput ? '設定が完了すると計算されます' : estimateCount.toLocaleString()}</div>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '3rem', height: '120px' }}>
            <Spinner type="Fade" height={15} width={5} radius={2} margin={2} />
          </div>
        )}
        <div style={{ fontSize: '0.9rem', marginTop: '1rem' }}>
          ページ数、月間キャプチャ数予測は入力内容によって自動計算されます。
        </div>

        {/* エラーメッセージ */}
        <div style={{ height: '35px', visibility: errorMessage ? 'visible' : 'hidden' }}>
          {errorMessage && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}
        </div>
      </ModalContentWrapper>

      <ModalButtonWrapper>
        <CancelButton disabled={allDisabled} onClick={onCancel}>
          キャンセル
        </CancelButton>
        <NormalButton
          disabled={allDisabled ? allDisabled : disabled || isRegexError || (created && !isFirstInput)}
          onClick={onApply}
        >
          {created ? '追加' : '変更'}
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const Label = styled.div`
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0.3rem;
`

const DesignedInput = styled(Input)`
  width: calc(100% - 180px - 1rem);
  height: 30px;
`

const DesignedSelect = styled(Select)`
  width: 180px;
  height: 30px;
  font-size: 0.9rem;
  padding: 0.2rem 0.5rem;
`

const ErrorMessage = styled.p`
  font-size: 12px;
  color: ${colors.error};
`
