import * as React from 'react'
import { Router } from '@gatsbyjs/reach-router'
import { Login } from './pages/Login'
import { SignUp } from './pages/SignUp'
import { NotFound } from './pages/NotFound'
import { Dashboard } from './pages/Dashboard'
import { Remind } from './pages/Remind'
import { RemindDone } from './pages/RemindDone'
import { ResetPassword } from './pages/ResetPassword'
import { CompletePassword } from './pages/CompletePassword'
import { ContentReport } from './pages/ContentReport'
import { PageReport } from './pages/PageReport'
import { Auth } from './pages/Auth'
import { Invite } from './pages/Invite'
import { ProjectRoot } from './pages/ProjectRoot'
import { Logout } from './pages/Logout'
import { ForceLogin } from './pages/ForceLogin'
import { Account } from './pages/Account'
import { Settings } from './pages/Settings'
import { Maintenance } from './pages/Maintenance'
import { Admin } from './pages/Admin'
import { Terms } from './pages/Terms'
import { CaptureControl } from './pages/CaptureControl'
import { CaptureHistory } from './pages/CaptureHistory'
import { PageCapture } from './pages/PageCapture'
import { DataImportPage } from './pages/DataImportPage'
import { CustomDimensionReportPage } from './pages/CustomDimensionReport'
import { CustomDimensionValueReportPage } from './pages/CustomDimensionValueReport'
import { ReportProvider } from './contexts/ReportProvider'
import { CustomDimensionReportProvider } from './contexts/CustomDimentionReportContext'
import { CustomFilterProvider } from './contexts/CustomFilterContext'
import { CustomDimensionValueReportProvider } from './contexts/CustomDimentionValueReportContext'
import { FunnelReportPage } from './pages/FunnelReport'
import { FunnelReportsPage } from './pages/FunnelReports'
import { FunnelReportEditPage } from './pages/FunnelReport/EditPage'
import { FunnelReportCreatePage } from './pages/FunnelReport/createPage'
import { FunnelReportProvider } from './contexts/FunnelReportContext'

export function Routes() {
  return (
    <Router>
      <Dashboard path="/" />
      <Login path="login" />
      <ForceLogin path="login/force/:token/:syslogToken" />
      <SignUp path="sign-up/:token" />
      <Remind path="remind" />
      <RemindDone path="remind/done" />
      <Invite path="invite/:token" />
      <ResetPassword path="password-reset/confirm/:token" />
      <CompletePassword path="password/complete" />
      <Maintenance path="maintenance" />
      <Terms path="terms" />
      {/* サービス利用約款は改定時の確認用ページとして /terms/revised も残している。see. https://github.com/uncovertruth/content-analytics/pull/2249/files#r1533373770 */}
      <Terms path="terms/revised" />

      {/* Auth以下のページはログインチェックが行われる */}
      <Auth path="/">
        <ProjectRoot path="projects/:projectId">
          <ReportProvider path="/">
            <CustomFilterProvider path="/">
              {/* ページレポートとカスタムディメンションレポートは行ったり来たりするため、Providerの範囲もページレポートまで拡張する */}
              <CustomDimensionReportProvider path="/">
                <CustomDimensionValueReportProvider path="/">
                  <FunnelReportProvider path="/">
                    <ContentReport path="report/content/:pageReportId" />
                    <PageReport path="report/page" />
                    <CustomDimensionReportPage path="report/custom-dimension" />
                    <CustomDimensionValueReportPage path="report/custom-dimension/:customDimensionId" />
                    <FunnelReportsPage path="report/funnel" />
                    <FunnelReportPage path="report/funnel/:funnelReportId" />
                    <FunnelReportEditPage path="report/funnel/:funnelReportId/edit" />
                    <FunnelReportCreatePage path="report/funnel/new" />
                    <PageCapture path="report/content/:pageReportId/capture/:urlId" />
                  </FunnelReportProvider>
                </CustomDimensionValueReportProvider>
              </CustomDimensionReportProvider>
            </CustomFilterProvider>
          </ReportProvider>
          <CaptureControl path="capture/control" />
          <CaptureHistory path="capture/history" />
          <Settings path="settings/:category" />
          <DataImportPage path="data-import" />
        </ProjectRoot>

        <Account path="account" />
        <Admin path="admin/:category" />
        <Logout path="logout" />
        <NotFound default />
      </Auth>
    </Router>
  )
}
