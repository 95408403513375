import * as React from 'react'
import styled from 'styled-components'
import Popup from 'reactjs-popup'
import { Cog } from '@styled-icons/boxicons-solid/Cog'

import { colors } from '../../../styleConstants'

interface PopupMenu {
  label: string
  disabled?: boolean
  hidden?: boolean
  onClick: (e?: React.MouseEvent<HTMLAnchorElement>) => void
}

interface Props {
  popupRef: React.RefObject<any>
  popupMenus: PopupMenu[]
}

/**
 * DataTableで使用するデータの設定メニューアイコンをレンダリングする
 *
 * @param {Object} props - The component props.
 * @param {React.MutableRefObject} props.popupRef - Popupコンポーネントに指定するreference object。
 *                                                  呼び出し元でポップアップを閉じる制御で使用する
 * @param {Array} props.popupMenus - ポップアップメニューのリスト
 */
export function SettingsIconWithPopupMenus({ popupRef, popupMenus }: Props) {
  return (
    <Popup
      ref={popupRef}
      trigger={<StyledCog data-testid="settings-icon-popup" />}
      position={'bottom right'}
      contentStyle={{ width: '100px' }}
      on={['click']}
      arrow={false}
      closeOnDocumentClick
    >
      <PopupMenus>
        {popupMenus.map((item) => (
          <Menu key={item.label} disabled={item.disabled} hidden={item.hidden}>
            <Link onClick={item.onClick} disabled={item.disabled}>
              {item.label}
            </Link>
          </Menu>
        ))}
      </PopupMenus>
    </Popup>
  )
}

const PopupMenus = styled.div`
  background-color: ${colors.white};
`
const Menu = styled.div<{ disabled?: boolean; hidden?: boolean }>`
  ${({ disabled = false }) =>
    !disabled &&
    `
      &:hover {
        background-color: ${colors.gray350};
        color: ${colors.link.hover};
      }     
    `}
  ${({ hidden = false }) => hidden && `display: none;`}
`

const Link = styled.a<{ disabled?: boolean }>`
  color: ${colors.link.base};
  padding: 4px 16px;
  cursor: pointer;
  display: inline-block;
  width: 100%;

  &:hover {
    color: ${colors.link.hover};
    font-weight: bold;
  }

  ${({ disabled = false }) =>
    disabled &&
    `
      pointer-events: none;
      color: ${colors.disabled};
      cursor: not-allowed;      
    `}
`

const StyledCog = styled(Cog)`
  width: 20px;
  height: 20px;
  color: ${colors.gray600};
  cursor: pointer;
`
