import React from 'react'
import styled from 'styled-components'
import { Check } from 'styled-icons/boxicons-regular'
import { colors } from '../../../styleConstants'
import { HelpPopupKeyField } from '../HelpPopups'

interface Props {
  value: string
}

export function KeyFieldUnit({ value }: Props) {
  return (
    <Content>
      <CheckIconWrapper>
        <CheckIcon>{<Check size={20} color={colors.white} />}</CheckIcon>
        <StepVerticalLine />
      </CheckIconWrapper>
      <LabelValueUnit>
        <FlexBox>
          <Label>キーフィールド</Label>
          <HelpPopupKeyField />
          <Value>{value}</Value>
        </FlexBox>
      </LabelValueUnit>
    </Content>
  )
}

const Content = styled.div`
  display: flex;
  font-size: 16px;
  padding: 0 0 20px;
`
const CheckIconWrapper = styled.div`
  position: relative;
`
const CheckIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 10px 0 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${colors.headerBg};
  color: ${colors.white};
  font-size: 1rem;
  font-weight: bold;
  z-index: 2;
`
const StepVerticalLine = styled.div`
  position: absolute;
  top: 1px;
  left: 11px;
  height: calc(100% + 30px);
  border-left: 2px solid ${colors.gray100};
  z-index: 1;
`
const LabelValueUnit = styled.div`
  flex: 1;
  padding-top: 3px;
  color: ${colors.gray750};
`

const Label = styled.div`
  margin: 0 5px 0 0;
  font-weight: bold;
  color: ${colors.headerBg};
`

const Value = styled.div`
  margin: 0 0 0 60px;
`

const FlexBox = styled.div`
  display: flex;
`
