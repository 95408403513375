import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'
import { IpAddressConditionType } from '../../util/IpAddress'

// 処理ステップ
export const IpAddressStep = {
  none: 0,
  createExec: 1, // 作成実行
  createComplete: 2, // 作成完了
  editExec: 3, // 編集実行
  editComplete: 4, // 編集完了
  deleteExec: 5, // 削除実行
  deleteComplete: 6, // 削除完了
}

export class IpAddress {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // IPアドレス追加フロー開始
  onCreate = () => {
    this.setState({
      ...this.state,
      ipAddress: {
        ...this.state.ipAddress,
        step: IpAddressStep.createExec,
        disabled: true,
        name: '',
        condition: IpAddressConditionType.perfect,
        ipAddress: '',
        isNameValid: false,
        isIpAddressValid: false,
      },
      ipAddressErrorMessage: '',
    })
  }

  // 作成キャンセル
  onCreateCancel = () => {
    this.setState({
      ...this.state,
      ipAddress: {
        ...this.state.ipAddress,
        step: IpAddressStep.none,
      },
    })
  }

  // フィルタ名設定
  onCreateName = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      ipAddress: {
        ...this.state.ipAddress,
        name: event.currentTarget.value,
        disabled: !(event.currentTarget.validity.valid && this.state.ipAddress.isIpAddressValid),
        isNameValid: event.currentTarget.validity.valid,
      },
    })
  }

  // フィルタの条件設定
  onCreateCondition = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      ipAddress: {
        ...this.state.ipAddress,
        condition: Number(event.currentTarget.value),
      },
    })
  }

  // IPアドレス入力
  onCreateIpAddress = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      ipAddress: {
        ...this.state.ipAddress,
        ipAddress: event.currentTarget.value,
        disabled: !(event.currentTarget.validity.valid && this.state.ipAddress.isNameValid),
        isIpAddressValid: event.currentTarget.validity.valid,
      },
    })
  }

  // フィルタ作成
  onCreateApply = async () => {
    try {
      await request(
        'POST',
        `/api/projects/${this.state.project.id}/ipaddress_filters/`,
        true,
        JSON.stringify({
          name: this.state.ipAddress.name,
          match_type: this.state.ipAddress.condition,
          ipaddress: this.state.ipAddress.ipAddress,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        ipAddress: {
          ...this.state.ipAddress,
          step: IpAddressStep.none,
        },
        toastMessage: 'IPアドレスフィルターを追加しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ipAddress: { ...this.state.ipAddress, step: IpAddressStep.none },
        ipAddressErrorMessage: typeof e === 'string' ? e : 'IPアドレスフィルターの作成に失敗しました。',
      })
    }
  }

  // フィルター編集フロー開始
  onEdit = (id: number) => {
    const ipAddress = this.state.ipAddress.ipAddresses.find((address) => address.id === id)
    if (ipAddress) {
      this.setState({
        ...this.state,
        ipAddress: {
          ...this.state.ipAddress,
          step: IpAddressStep.editExec,
          selectedId: id,
          disabled: true,
          name: ipAddress.name,
          condition: ipAddress.condition,
          ipAddress: ipAddress.ipAddress,
          baseName: ipAddress.name, // 比較用
          baseCondition: ipAddress.condition, // 比較用
          baseIpAddress: ipAddress.ipAddress, // 比較用
          isNameValid: false,
          isConditionValid: false,
          isIpAddressValid: false,
        },
        ipAddressErrorMessage: '',
      })
    }
  }

  // 編集キャンセル
  onEditCancel = () => {
    this.setState({
      ...this.state,
      ipAddress: {
        ...this.state.ipAddress,
        step: IpAddressStep.none,
      },
    })
  }

  // フィルタ名編集
  onEditName = (event: React.FormEvent<HTMLInputElement>) => {
    const valid = event.currentTarget.validity.valid && this.state.ipAddress.baseName !== event.currentTarget.value
    this.setState({
      ...this.state,
      ipAddress: {
        ...this.state.ipAddress,
        name: event.currentTarget.value,
        disabled: !(valid || this.state.ipAddress.isConditionValid || this.state.ipAddress.isIpAddressValid),
        isNameValid: valid,
      },
    })
  }

  // フィルタ条件編集
  onEditCondition = (event: React.FormEvent<HTMLSelectElement>) => {
    const valid =
      event.currentTarget.validity.valid && this.state.ipAddress.baseCondition !== Number(event.currentTarget.value)
    this.setState({
      ...this.state,
      ipAddress: {
        ...this.state.ipAddress,
        condition: Number(event.currentTarget.value),
        disabled: !(valid || this.state.ipAddress.isNameValid || this.state.ipAddress.isIpAddressValid),
        isConditionValid: valid,
      },
    })
  }

  // IPアドレス編集
  onEditIpAddress = (event: React.FormEvent<HTMLInputElement>) => {
    const valid = event.currentTarget.validity.valid && this.state.ipAddress.baseIpAddress !== event.currentTarget.value
    this.setState({
      ...this.state,
      ipAddress: {
        ...this.state.ipAddress,
        ipAddress: event.currentTarget.value,
        disabled: !(valid || this.state.ipAddress.isNameValid || this.state.ipAddress.isConditionValid),
        isIpAddressValid: valid,
      },
    })
  }

  // 編集内容を適用
  onEditApply = async () => {
    try {
      await request(
        'PATCH',
        `/api/projects/${this.state.project.id}/ipaddress_filters/${this.state.ipAddress.selectedId}/`,
        true,
        JSON.stringify({
          name: this.state.ipAddress.name,
          match_type: this.state.ipAddress.condition,
          ipaddress: this.state.ipAddress.ipAddress,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        ipAddress: {
          ...this.state.ipAddress,
          step: IpAddressStep.none,
        },
        toastMessage: 'IPアドレスフィルターを変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ipAddress: { ...this.state.ipAddress, step: IpAddressStep.none },
        ipAddressErrorMessage: typeof e === 'string' ? e : 'IPアドレスフィルターの編集に失敗しました。',
      })
    }
  }

  // フィルター削除フロー開始
  onDelete = (id: number) => {
    const ipAddress = this.state.ipAddress.ipAddresses.find((address) => address.id === id)
    if (ipAddress) {
      this.setState({
        ...this.state,
        ipAddress: {
          ...this.state.ipAddress,
          step: IpAddressStep.deleteExec,
          selectedId: id,
          name: ipAddress.name,
          ipAddress: ipAddress.ipAddress,
        },
        ipAddressErrorMessage: '',
      })
    }
  }

  // 削除キャンセル
  onDeleteCancel = () => {
    this.setState({
      ...this.state,
      ipAddress: {
        ...this.state.ipAddress,
        step: IpAddressStep.none,
      },
    })
  }

  // 削除を適用
  onDeleteApply = async () => {
    try {
      await request(
        'DELETE',
        `/api/projects/${this.state.project.id}/ipaddress_filters/${this.state.ipAddress.selectedId}/`,
        true,
      )
      this.setState({
        ...this.state,
        reload: true,
        ipAddress: {
          ...this.state.ipAddress,
          step: IpAddressStep.none,
        },
        toastMessage: 'IPアドレスフィルターを削除しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ipAddress: { ...this.state.ipAddress, step: IpAddressStep.none },
        ipAddressErrorMessage: typeof e === 'string' ? e : 'IPアドレスフィルターの削除に失敗しました。',
      })
    }
  }
}
