import React from 'react'
import styled from 'styled-components'

import { Legends, LegendsState } from '../../charts/Legends'
import { BarChart } from '../../charts/BarChart'
import { Checkbox } from '../../common/Checkbox'
import { getDateStringYM } from '../../../util/Date'

export interface ProjectPvInfo {
  readonly name: string
  readonly count: number
}

interface Props {
  readonly infos: ProjectPvInfo[]
  readonly teamPv: number
  readonly teamPvLimit: number
  readonly targetYearMonth: string
}

/**
 * プロジェクトPV（ページビュー）カウントの積み上げ棒グラフを表示する
 *
 * @param {Object} props - The properties object.
 * @param {Array} props.infos - グラフに表示するPV数・プロジェクト名情報のリスト
 * @param {number} props.teamPv - 組織PV数、グラフの上限数に使用
 * @param {number} props.teamPvLimit - 組織PV上限、グラフの上限数に使用（年月プルダウンで当月が指定されているかつ、最適化チェックボックスがOFFの場合に使用）
 * @param {string} props.targetYearMonth - グラフ対象年月プルダウンの値（形式: YYYYMM）
 */
export function ProjectsPvCountChart({ infos, teamPv, teamPvLimit, targetYearMonth }: Props) {
  const [barOptimized, setBarOptimized] = React.useState(false)

  const todayYearMonth = getDateStringYM(new Date(), '')
  const isCurrentYearMonth = todayYearMonth === targetYearMonth
  const barMaxValue = isCurrentYearMonth && !barOptimized ? teamPvLimit : teamPv

  const { data, keys, legends } = createBarData(infos)

  return (
    <>
      {/* BarChartの要素をOverlayの後ろに表示するためdiv追加
          see. https://github.com/uncovertruth/content-analytics/issues/2536#issuecomment-2390683962 */}
      <div>
        <BarChart
          data={data}
          keys={keys}
          enableLabel={true}
          layout="horizontal"
          maxValue={barMaxValue}
          width={'925px'}
          height={70}
          margin={{ top: 0, right: 40, bottom: 30, left: 25 }}
          labelSkipWidth={40}
          legendsTranslateY={60}
          legends={false}
          itemWidth={calcBarChartItemWidth(infos)}
          colors={setColor}
        />
      </div>
      <Legends legends={legends} style={{ margin: '8px 0' }} />
      <CheckboxContainer onClick={() => setBarOptimized(!barOptimized)} disabled={!isCurrentYearMonth}>
        {isCurrentYearMonth && (
          <>
            <DesignedCheckbox checked={barOptimized} />
            <span>グラフの上限値を月間上限PVに合わせる</span>
          </>
        )}
      </CheckboxContainer>
    </>
  )
}

function createBarData(infos: ProjectPvInfo[]) {
  let data = { title: 'PV' }
  const keys: string[] = []
  const legends: LegendsState[] = []
  infos.map((info) => {
    if (!info.name || !info.count) return
    const n = stringToNumber(info.name)
    const color = getColor(n)
    const d = {
      [`${info.name}`]: info.count,
      [`${info.name} Color`]: color,
    }
    const l: LegendsState = { name: info.name, color }
    data = { ...data, ...d }
    keys.push(info.name)
    legends.push(l)
  })

  return { data: [data], keys, legends }
}

function stringToNumber(str: string) {
  // 文字列 -> 配列 -> 文字コードの配列 -> 全部足す
  return Array.from(str)
    .map((ch) => ch.charCodeAt(0))
    .reduce((a, b) => a + b)
}

function setColor(bar: { id: string }) {
  const n = stringToNumber(bar.id)
  return getColor(n)
}

function getColor(n: number) {
  return `hsl(${(n * n) % 360}, 80%, 64%)`
}

function calcBarChartItemWidth(infos: ProjectPvInfo[]) {
  const maxProjectNameLength = Math.max(...infos.map((info) => info.name.length))
  return maxProjectNameLength * 7 + 20
}

const CheckboxContainer = styled.div<{
  disabled: boolean
}>`
  display: flex;
  align-items: center;
  height: 25px;
  gap: 5px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const DesignedCheckbox = styled(Checkbox)`
  width: 20px;
  height: 20px;
`
