export const SCHEDULE_INTERVALS = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
} as const

export const WEEKDAY = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
} as const

export const MIN_PORT_NUMBER = 0
export const MAX_PORT_NUMBER = 65535

export const KEY_FIELD_TABLE_TYPES = {
  CUSTOM_DIMENSION: 1,
  BUILD_IN_USER_ID: 11,
} as const

export const IMPORT_FIELD_TYPE_TABLE_TYPES = {
  NUMERIC: 11,
  STRING: 21,
  TIMESTAMP: 31,
  DATE: 32,
} as const

export const DATA_IMPORT_STATUS = {
  SUCCESS: 0,
  DELETED: 10,
  FAILED: 91, // 91番以上のステータスは全てFailedとして扱う
} as const

export const IMPORT_LOG_POLLING_INTERVAL = 1000 * 60
