import { useCookies } from 'react-cookie'

const COOKIE_KEY = `filterContentEventsExists`
const URL_SEARCH_PARAM = 'page_captured'

export const useFilterContentEventsExists = () => {
  const [cookies, setCookie] = useCookies([COOKIE_KEY])

  // 値取得できない場合など、デフォルトはfalseでセットする
  const filterContentEventsExists: boolean = cookies[COOKIE_KEY] === 'true'

  const setFilterContentEventsExists = (value: boolean | null) => {
    if (value === null) return
    setCookie(COOKIE_KEY, value, { path: '/' })
  }

  return { filterContentEventsExists, setFilterContentEventsExists }
}

export const getSearchParamsFilterContentEventsExists = (): boolean | null => {
  const param: string | null = new URLSearchParams(location.search).get(URL_SEARCH_PARAM)
  if (param === 'true') return true
  if (param === 'false') return false
  return null
}

export const setSearchParamsFilterContentEventsExists = (value: boolean) => {
  const currentUrl = new URL(window.location.href)
  currentUrl.searchParams.set(URL_SEARCH_PARAM, value ? 'true' : 'false')
  history.replaceState(null, '', currentUrl.toString())
}
