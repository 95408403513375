import * as React from 'react'
import { request } from '../../../util/request'
import { PartialContentsData } from '../../../util/Response'
import { DeviceType } from '../../../util/hooks/useDeviceType'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly loading: boolean
  readonly contentId: string // コンテンツID
  readonly mobileImageUrl: string
  readonly pcImageUrl: string
  readonly imageHeight: number
  readonly errorMessage?: string
}

const BaseMaxImageHeight = 150
const BaseMinImageHeight = 30

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  fetch = async (projectId: string, id: number, selectedDevice: DeviceType) => {
    try {
      const json: PartialContentsData = await request('GET', `/api/projects/${projectId}/partial_contents/${id}/`, true)

      let height = BaseMinImageHeight
      if (selectedDevice === 'mobile') {
        height = json.mobile_screenshot === null ? BaseMinImageHeight : json.mobile_screenshot.height
      } else {
        height = json.pc_screenshot === null ? BaseMinImageHeight : json.pc_screenshot.height
      }

      // 画像のサイズ補正
      if (height > BaseMaxImageHeight) {
        height = BaseMaxImageHeight
      } else if (height < BaseMinImageHeight) {
        height = BaseMinImageHeight
      }

      this.setState({
        ...this.state,
        loading: false,
        contentId: json.display_content_id,
        mobileImageUrl: json.mobile_screenshot ? json.mobile_screenshot.image_url : '',
        pcImageUrl: json.pc_screenshot ? json.pc_screenshot.image_url : '',
        imageHeight: height,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        errorMessage: typeof e === 'string' ? e : 'コンテンツ情報の取得に失敗しました。',
      })
    }
  }
}

const initialState: State = {
  loading: true,
  contentId: 'id',
  mobileImageUrl: '',
  pcImageUrl: '',
  imageHeight: BaseMinImageHeight,
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
