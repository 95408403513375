import * as React from 'react'

import { Loading } from '../../../components/common/Loading'
import { ProjectContext } from '../../ProjectRoot'
import { ErrorBox } from '../../../components/common/ErrorBox'
import { useFunnelReport } from '../../../util/hooks/api/FunnelReport/useFunnelReport'
import { Report } from '../Report'

interface Props {
  readonly funnelReportId: number
}

/**
 * ファネルレポート詳細ページのレポートエリアをレンダリングする
 *
 * @param {object} param - The input parameters for the method.
 * @param {number} param.funnelReportId - ファネルレポートID
 */
export function ReportArea({ funnelReportId }: Props) {
  const {
    state: { projectId },
  } = React.useContext(ProjectContext)

  const { data, isLoading, isError, errorMessage } = useFunnelReport({
    projectId,
    funnelReportId,
  })

  if (isLoading) return <Loading />
  if (isError && !errorMessage) return <ErrorBox>ファネルレポートの取得に失敗しました。</ErrorBox>

  return (
    <>
      {!data && <ErrorBox>ファネルレポートのデータがありません。</ErrorBox>}
      {data && <Report data={data} />}
    </>
  )
}
