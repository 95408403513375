import * as React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FUNNEL_REPORT_QUERY_KEY } from '../constants'
import { ErrorType, request } from '../../../request'
import { ScopeType, useScopeType } from '../../useScopeType'
import { DeviceType, getDeviceLayoutNumber, useDeviceType } from '../../useDeviceType'
import { ReportContext } from '../../../../contexts/ReportProvider'
import { getDateStringYMD } from '../../../Date'
import { getScopeTypeApiValue } from '../../../getScopeTypeApiValue'
import { CalenderState } from '../../../../components/common/DayPickerRange'
import { FunnelReportResponse } from './types'
import { transformResponseToData } from './utils'

export interface FunnelReportProps {
  readonly projectId: number
  readonly funnelReportId: number
  readonly enabled?: boolean
}

export const useFunnelReport = ({ projectId, funnelReportId, enabled = true }: FunnelReportProps) => {
  const queryClient = useQueryClient()

  const {
    state: { calenderState, uuid },
  } = React.useContext(ReportContext)

  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()

  const [errorMessage, setErrorMessage] = React.useState('')

  const queryKey = [
    FUNNEL_REPORT_QUERY_KEY,
    {
      uuid,
      funnelReportId,
      calenderState,
      scopeType,
      deviceType,
    },
  ]

  const resetFunnelReport = async () => {
    await queryClient.resetQueries({ queryKey: [FUNNEL_REPORT_QUERY_KEY] })
  }

  const fetchReport = async () => {
    const queryParams = makeQueryParams(calenderState, scopeType, deviceType)
    return await request<FunnelReportResponse>(
      'GET',
      `/api/projects/${projectId}/funnel_reports/${funnelReportId}?${queryParams}`,
      true,
    )
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: fetchReport,
    select: (response) => {
      if (errorMessage) setErrorMessage('')
      return transformResponseToData(response)
    },
    onError: (error: ErrorType) => {
      if (typeof error === 'string') setErrorMessage(error)
    },
    enabled: !!enabled,
  })

  return {
    ...queryResult,
    resetFunnelReport,
    errorMessage,
  }
}

const makeQueryParams = (calenderState: CalenderState, scopeType: ScopeType, deviceType: DeviceType) => {
  const params = new URLSearchParams({
    search_from: getDateStringYMD(calenderState.startDate, '-'),
    search_to: getDateStringYMD(calenderState.endDate, '-'),
    scope_type: getScopeTypeApiValue(scopeType).toString(),
    page_layout: getDeviceLayoutNumber(deviceType).toString(),
  })

  return params.toString()
}
