export const AGGREGATION_DATE_TYPES = {
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
} as const

export const AGGREGATION_DATE_TYPE_OPTIONS = [
  { label: '日別', value: AGGREGATION_DATE_TYPES.DAY },
  { label: '週別', value: AGGREGATION_DATE_TYPES.WEEK },
  { label: '月別', value: AGGREGATION_DATE_TYPES.MONTH },
]

export const CHOICE_DATASET_KEYS = {
  VIEW_COUNT: 'viewCount',
  LANDING_COUNT: 'landingCount',
  BOUNCE_RATE: 'bounceRate',
  GOAL_COUNT: 'goalCount',
  GOAL_RATE: 'goalRate',
} as const
