import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { AddButton } from '../common/Button'
import { PopupMenu } from '../common/PopupMenu'
import { HelpLink } from '../common/HelpLink'
import { HELP_LINKS } from '../../constants'
import { Settings } from '@styled-icons/material/Settings'
import { WarningBox } from '../common/WarningBox'
import { getDateStringYMD } from '../../util/Date'

export interface CrossDomainState {
  readonly id: number
  readonly name: string
  readonly allow_subdomain: boolean
  readonly updatedAt: string
  readonly createdAt: string
}

// 表示幅
const layoutWidth = {
  name: '450px',
  check: '130px',
  updatedAt: '130px',
  createdAt: '130px',
}

interface Props {
  readonly crossDomains: CrossDomainState[]
  readonly canAddCrossDomain?: boolean
  readonly canChangeCrossDomain?: boolean
  readonly canDeleteCrossDomain?: boolean
  readonly onAdd?: () => void
  readonly onEdit?: (id: number) => void
  readonly onDelete?: (id: number) => void
}

export function CrossDomainList(props: Props) {
  const { crossDomains, canAddCrossDomain, canChangeCrossDomain, canDeleteCrossDomain, onAdd, onEdit, onDelete } = props
  const [state, setState] = React.useState({ id: 0, opened: false })
  const onClick = (id: number) => {
    setState({ ...state, id: id, opened: true })
  }
  const popupClose = () => {
    setState({ ...state, id: 0, opened: false })
  }
  return (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.5rem' }}>
        <HelpLink title={'クロスドメイン設定を行う'} link={HELP_LINKS.CROSS_DOMAIN_HOW_TO} />
      </div>
      <div style={{ margin: '24px 0' }}>
        <WarningBox>
          <div>
            このクロスドメイン設定を行うことで、URL末尾に"_ca_sid_XXXXXX=ZZZZZZZZZZ"といったパラメータが付与されるようになります。
          </div>
          <div>※ZZZZZZZZZZ部分は訪問者ごとにユニークな値です</div>
          <div style={{ marginTop: '1rem' }}>
            パラメータ付きのURLが訪問者分増えることとなりますので、Google
            Analyticsなどご利用の際は「パラメータ付きのページは同じページとして扱われるようにパラメータを除外設定する」などの対策を必要に応じて行うようお願いいたします。
          </div>
        </WarningBox>
      </div>
      {canAddCrossDomain && (
        <div style={{ marginRight: '1rem' }}>
          <AddButton onClick={onAdd}>許可するドメインを追加</AddButton>
        </div>
      )}
      {crossDomains.length === 0 ? (
        <div style={{ margin: '1.5rem 0' }}>許可するドメインが登録されていません</div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Item style={{ paddingBottom: '0.5rem' }}>
            <Box className="title" style={{ width: `${layoutWidth.name}` }}>
              許可するドメイン
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.check}` }}>
              サブドメインを許可
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.createdAt}` }}>
              追加日
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.updatedAt}` }}>
              更新日
            </Box>
          </Item>
          {crossDomains.map((crossDomain) => {
            return (
              <Item key={crossDomain.id}>
                <Box style={{ width: `${layoutWidth.name}` }}>{crossDomain.name}</Box>
                <Box style={{ width: `${layoutWidth.check}` }}>
                  {crossDomain.allow_subdomain ? <div>許可する</div> : <div>許可しない</div>}
                </Box>
                <Box style={{ width: `${layoutWidth.createdAt}` }}>
                  {getDateStringYMD(new Date(crossDomain.createdAt))}
                </Box>
                <Box style={{ width: `${layoutWidth.updatedAt}` }}>
                  {getDateStringYMD(new Date(crossDomain.updatedAt))}
                </Box>
                {(canChangeCrossDomain || canDeleteCrossDomain) && (
                  <SettingBox>
                    <SettingIcon onClick={() => onClick(crossDomain.id)} />
                    {state.opened && state.id === crossDomain.id && (
                      <PopupMenu
                        onClose={popupClose}
                        style={{
                          right: '25px',
                          bottom: canChangeCrossDomain && canDeleteCrossDomain ? '-25px' : '-10px',
                          width: '80px',
                        }}
                      >
                        <Links>
                          {canChangeCrossDomain && (
                            <DesignedLink onClick={() => onEdit && onEdit(crossDomain.id)}>編集</DesignedLink>
                          )}
                          {canDeleteCrossDomain && (
                            <DesignedLink onClick={() => onDelete && onDelete(crossDomain.id)}>削除</DesignedLink>
                          )}
                        </Links>
                      </PopupMenu>
                    )}
                  </SettingBox>
                )}
              </Item>
            )
          })}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div``

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  height: 100%;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${colors.gray300};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;

  & + & {
    margin-left: 1.5rem;
  }

  &.title {
    color: ${colors.gray500};
  }
`

const SettingBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
`

const SettingIcon = styled(Settings).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const DesignedLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
    background-color: ${colors.gray200};
  }

  & + & {
    margin-top: 0.1rem;
  }
`
