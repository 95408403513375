import React, { useState } from 'react'
import { ScheduleDeleteModal } from './ScheduleDeleteModal'
import { WarningButton } from '../../../../common/Button'
import { useDeleteDataImportSchedule } from '../../../../../util/hooks/api/DataImport/useDeleteDataImportSchedule'

type Props = {
  projectId: string
  scheduleId: number | undefined
  handleDeleteSuccess: () => void
}

export function ScheduleDeleteButton({ projectId, scheduleId, handleDeleteSuccess }: Props) {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
  const openModal = () => setIsModalOpened(true)
  const onClose = () => setIsModalOpened(false)

  const { mutate, isLoading, isError, error } = useDeleteDataImportSchedule({
    projectId: projectId,
    scheduleId: scheduleId || undefined,
  })

  const handleDelete = () => {
    if (!scheduleId) return
    mutate(null, {
      onSuccess: () => {
        handleDeleteSuccess()
        onClose()
      },
    })
  }

  return (
    <>
      <WarningButton onClick={openModal}>削除</WarningButton>

      <ScheduleDeleteModal
        opened={isModalOpened}
        onClose={onClose}
        handleDelete={handleDelete}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
    </>
  )
}
