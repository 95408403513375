import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { GlobalContext } from '../GlobalState'
import { usePageState } from './Logout/state'

export function Logout(props: RouteComponentProps) {
  const { actions: globalActions } = React.useContext(GlobalContext)
  const {
    state: { loading },
    actions,
  } = usePageState()

  React.useEffect(() => {
    if (loading) {
      actions.logout()

      // 情報クリア
      globalActions.clearState()
    }
  }, [])

  if (loading) {
    return <div />
  }

  // ナビのログアウトからログインページへのリダイレクト処理
  let path = ''
  let search = ''
  if (props.location && props.location.state) {
    if ((props.location.state as any).path) {
      path = (props.location.state as any).path
    }
    if ((props.location.state as any).search) {
      search = (props.location.state as any).search
    }
  }
  // URLパラメータがあれば引き継ぐ
  if (search !== '') {
    path += search
  }
  // 組織管理、ログアウトからの遷移
  if (path.indexOf('/login') !== -1 || path.indexOf('/admin') !== -1 || path.indexOf('/logout') !== -1) {
    path = ''
  }

  // ログインページへリダイレクト
  // reach-routerのRedirectを使用すると、searchの部分がカットされるのでこの方法を使用する
  //return logout ? <Redirect to={path ? `/login/?next=${path}` : '/login'} noThrow /> : <div />
  window.location.href = path ? `/login/?next=${path}` : '/login'
  return <div />
}
