import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Role, RoleLabel } from '../../util/Authority'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { AdminNormalButton, CancelButton } from '../common/Button'

interface Props {
  readonly opened: boolean
  readonly email: string
  readonly role: number
  readonly projects: { id: number; name: string; role: number }[]
  readonly onBack?: () => void
  readonly onCancel: () => void
  readonly onApply?: () => void
  readonly title: string
}

export function InviteConfirmModal(props: Props) {
  const { opened, email, role, projects, onBack, onCancel, onApply, title } = props
  return (
    <Modal isOpen={opened} onClose={onCancel} title={title} width={600}>
      <ModalContentWrapper>
        <Container>
          <div>以下の内容で招待します。</div>
          <div style={{ marginBottom: '1rem' }}>問題なければ「招待」ボタンを押してください。</div>

          <div style={{ marginLeft: '0.5rem', fontWeight: 'bold' }}>メールアドレス</div>
          <div style={{ marginLeft: '0.5rem', marginTop: '0.5rem' }}>{email}</div>

          <div style={{ marginLeft: '0.5rem', marginTop: '1.5rem', fontWeight: 'bold' }}>権限</div>
          <div style={{ marginLeft: '0.5rem', marginTop: '0.5rem' }}>
            {role === Role.User.admin ? '組織管理者' : 'プロジェクト'}
          </div>

          <ProjectBox>
            {projects.map((project, index) => {
              // 組織管理者権限があればプロジェクトは全て管理者
              if (role === Role.User.admin) {
                return (
                  <ProjectItem key={index}>
                    <div style={{ width: '260px', marginLeft: '1rem' }}>{project.name}</div>
                    <div style={{ marginRight: '1rem' }}>{RoleLabel.Project.admin}</div>
                  </ProjectItem>
                )
              } else {
                // 権限なしのプロジェクト
                if (project.role === Role.Project.none) {
                  return <div key={index} />
                }
                return (
                  <ProjectItem key={index}>
                    <div style={{ width: '260px', marginLeft: '1rem' }}>{project.name}</div>
                    {project.role === Role.Project.admin && (
                      <div style={{ marginRight: '1rem' }}>{RoleLabel.Project.admin}</div>
                    )}
                    {project.role === Role.Project.member && (
                      <div style={{ marginRight: '1rem' }}>{RoleLabel.Project.member}</div>
                    )}
                  </ProjectItem>
                )
              }
            })}
          </ProjectBox>
        </Container>
      </ModalContentWrapper>
      <Buttons>
        <Link onClick={onBack}>＜ 戻る</Link>
        <ModalButtonWrapper>
          <CancelButton onClick={onCancel}>キャンセル</CancelButton>
          <AdminNormalButton onClick={onApply}>招待</AdminNormalButton>
        </ModalButtonWrapper>
      </Buttons>
    </Modal>
  )
}

const Container = styled.div`
  font-size: 14px;
`

const ProjectBox = styled.div`
  width: 100%;
  max-height: 300px;
  margin-top: 0.5rem;
  overflow-y: auto;
`

const ProjectItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0.4rem;
  border-bottom: 1px solid ${colors.gray200};
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 16px;
`

const Link = styled.div`
  color: ${colors.link.base};
  text-decoration: underline;
  cursor: pointer;
`
