import * as React from 'react'
import styled from 'styled-components'

import { getDateYYMMDD, parseDate } from '../../../util/Date'
import { Content } from '../../../util/hooks/api/useContentReport'
import { BaseCell } from './BaseCell'
import { AccessTime } from '@styled-icons/material/AccessTime'
import { colors } from '../../../styleConstants'
import { CalenderState } from '../../common/DayPickerRange'

interface Props {
  readonly item: Content
  readonly calenderState: CalenderState
  readonly openCaptureHistoryModal: (contentId: number) => void
}

export function ContentCapturedAtCell({ item, openCaptureHistoryModal, calenderState }: Props) {
  const isStartedDateBold = calenderState.startDate < parseDate(item.measurementStartedDate)

  return (
    <Cell data-id={item.id}>
      <Date bold={isStartedDateBold}>{getDateYYMMDD(item.measurementStartedDate)}</Date>
      <Delimiter>-</Delimiter>
      <Date bold={true}>{item.measurementEndedDate ? getDateYYMMDD(item.measurementEndedDate) : ''}</Date>
      <StyledTimeIcon
        size={18}
        color={colors.gray550}
        onClick={(e) => {
          e.stopPropagation()
          openCaptureHistoryModal(item.id)
        }}
      />
    </Cell>
  )
}

const Cell = styled(BaseCell)`
  position: relative;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 36px;
  font-size: 10px;
`

const Date = styled.span<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 'bold' : '')};
`

const Delimiter = styled.span`
  padding: 0 2px;
`

const StyledTimeIcon = styled(AccessTime)`
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  margin-left: 5px;
`
