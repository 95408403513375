// 権限定数
export const Role = {
  User: {
    none: 0,
    owner: 1,
    admin: 2,
    member: 3,
  },
  Project: {
    none: 0,
    admin: 1,
    member: 2,
  },
}

export const RoleLabel = {
  User: {
    none: '',
    owner: '',
    admin: '組織管理者',
    member: '組織メンバー',
  },
  Project: {
    none: '',
    admin: 'プロジェクト管理者',
    member: 'プロジェクトメンバー',
  },
}

export class Authority {
  //
  // 組織の持つ権限
  //
  // プロジェクトの作成
  static canAddProject(permissions: any) {
    return permissions['team.can_add_project']
  }
  // 組織メンバーの招待
  static canAddTeamMember(permissions: any) {
    return permissions['team.can_add_team_member']
  }
  // IPアドレス制限の追加
  static canAddRestrictedIpAddress(permissions: any) {
    return permissions['team.can_add_restricted_ip_address']
  }
  // 組織メンバーの役割変更
  static canChangeTeamMember(permissions: any) {
    return permissions['team.can_change_team_member']
  }
  // IPアドレス制限の変更
  static canChangeRestrictedIpAddress(permissions: any) {
    return permissions['team.can_change_restricted_ip_address']
  }
  // プロジェクトの削除
  static canDeleteProject(permissions: any) {
    return permissions['team.can_delete_project']
  }
  // 組織メンバーの無効
  static canDeleteTeamMember(permissions: any) {
    return permissions['team.can_delete_team_member']
  }
  // IPアドレス制限の削除
  static canDeleteRestrictedIpAddress(permissions: any) {
    return permissions['team.can_delete_restricted_ip_address']
  }
  // 監査ログのダウンロード
  static canDownloadAuditlog(permissions: any) {
    return permissions['team.can_download_auditlog']
  }
  // プロジェクトの追加ボタン表示
  static canViewAddProject(permissions: any) {
    return permissions['team.can_view_add_project']
  }
  // 全プロジェクトの閲覧
  static canViewAllProject(permissions: any) {
    return permissions['team.can_view_all_project']
  }
  // 組織メンバーの閲覧
  static canViewTeamMember(permissions: any) {
    return permissions['team.can_view_team_member']
  }
  // 組織管理画面の閲覧
  static canViewTeamSettings(permissions: any) {
    return permissions['team.can_view_team_settings']
  }
  // IPアドレス制限の閲覧
  static canViewRestrictedIpAddress(permissions: any) {
    return permissions['team.can_view_restricted_ip_address']
  }
  // 監査ログの閲覧
  static canViewAuditlog(permissions: any) {
    return permissions['team.can_view_auditlog']
  }

  // 外部連携
  // 外部連携サービスアカウントの追加
  static canAddGcpSa(permissions: any) {
    return permissions['team.can_add_gcp_sa']
  }
  // 外部連携サービスアカウントの閲覧
  static canViewGcpSa(permissions: any) {
    return permissions['team.can_view_gcp_sa']
  }
  // 外部連携サービスアカウントの削除
  static canDeleteGcpSa(permissions: any) {
    return permissions['team.can_delete_gcp_sa']
  }
  // 外部連携サービスアカウントキーの追加
  static canAddGcpSaKey(permissions: any) {
    return permissions['team.can_add_gcp_sa_key']
  }
  // 外部連携サービスアカウントキーの閲覧
  static canViewGcpSaKey(permissions: any) {
    return permissions['team.can_view_gcp_sa_key']
  }
  // 外部連携サービスアカウントキーの変更
  static canChangeGcpSaKey(permissions: any) {
    return permissions['team.can_change_gcp_sa_key']
  }
  // 外部連携サービスアカウントキーの削除
  static canDeleteGcpSaKey(permissions: any) {
    return permissions['team.can_delete_gcp_sa_key']
  }
  // 外部連携対象プロジェクトの追加
  static canAddGcsExport(permissions: any) {
    return permissions['team.can_add_gcs_export']
  }
  // 外部連携対象プロジェクトの閲覧
  static canViewGcsExport(permissions: any) {
    return permissions['team.can_view_gcs_export']
  }
  // 外部連携対象プロジェクトの削除
  static canDeleteGcsExport(permissions: any) {
    return permissions['team.can_delete_gcs_export']
  }

  //
  // プロジェクト別の権限
  //
  static canAddGoal(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_goal']
  }
  static canAddUrlParameter(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_url_parameter']
  }
  static canAddProjectMember(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_project_member']
  }
  static canAddCustomDimension(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_custom_dimension']
  }
  static canAddIpAddressFilter(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_ipaddress_filter']
  }
  static canAddCrossDomain(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_cross_domain']
  }
  static canAddPageCapture(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_page_capture']
  }
  // 登録済コンテンツの追加
  static canAddManagedContent(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_managed_content']
  }
  static canChangeGoal(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_goal']
  }
  static canChangeProject(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_project']
  }
  static canChangeUrlParameter(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_url_parameter']
  }
  static canChangeProjectMember(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_project_member']
  }
  static canChangeCustomDimension(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_custom_dimension']
  }
  static canChangeIpAddressFilter(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_ipaddress_filter']
  }
  static canChangeCrossDomain(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_cross_domain']
  }
  static canChangeSiteResponsive(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_site_responsive']
  }
  static canChangePageCapture(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_page_capture']
  }
  // 登録済コンテンツの変更
  static canChangeManagedContent(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_managed_content']
  }
  static canDeleteGoal(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_goal']
  }
  static canDeleteProjectMember(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_project_member']
  }
  static canDeleteUrlParameter(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_url_parameter']
  }
  static canDeleteCustomDimension(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_custom_dimension']
  }
  static canDeleteIpAddressFilter(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_ipaddress_filter']
  }
  static canDeleteCrossDomain(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_cross_domain']
  }
  static canDeletePageCapture(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_page_capture']
  }
  // 登録済コンテンツの削除
  static canDeleteManagedContent(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_managed_content']
  }
  static canDownloadReport(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_download_report']
  }
  static canViewAddPageCapture(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_add_page_capture']
  }
  static canViewDownloadReport(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_download_report']
  }
  static canViewProjectSettings(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_project_settings']
  }
  static canViewReport(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_report']
  }
  static canViewPageCapture(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_page_capture']
  }
  // コンテンツの閲覧
  static canViewPartialContent(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_partial_content']
  }
  // 登録済コンテンツの閲覧
  static canViewManagedContent(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_managed_content']
  }

  // ファネルレポート
  static canViewFunnelReport(permissions: any, id: number | string) {
    return !!(this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_funnel_report'])
  }
  static canAddFunnelReport(permissions: any, id: number | string) {
    return !!(this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_funnel_report'])
  }
  static canChangeFunnelReport(permissions: any, id: number | string) {
    return !!(this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_funnel_report'])
  }
  static canDeleteFunnelReport(permissions: any, id: number | string) {
    return !!(this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_funnel_report'])
  }

  //
  // ターゲットコンテンツ
  //

  // 閲覧
  static canViewTargetContent(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_target_content']
  }

  // 追加
  static canAddTargetContent(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_target_content']
  }

  //編集
  static canChangeTargetContent(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_target_content']
  }

  //削除
  static canDeleteTargetContent(permissions: any, id: number | string) {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_target_content']
  }

  //
  // データインポート
  //
  static canViewDataImportSchema(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_data_import_schema']
  }
  static canAddDataImportSchema(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_data_import_schema']
  }
  static canChangeDataImportSchema(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_data_import_schema']
  }
  static canDeleteDataImportSchema(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_data_import_schema']
  }

  static canViewDataImportSchedule(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_data_import_schedule']
  }
  static canAddDataImportSchedule(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_add_data_import_schedule']
  }
  static canChangeDataImportSchedule(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_change_data_import_schedule']
  }
  static canDeleteDataImportSchedule(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_data_import_schedule']
  }

  static canDownloadImportDataSftpPubkey(permissions: any, id: number | string): boolean {
    return (
      this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_download_import_data_sftp_pubkey']
    )
  }
  static canDownloadImportDataLog(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_download_import_data_log']
  }

  static canDataImport(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_data_import']
  }
  static canViewImportedData(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_view_imported_data']
  }
  static canDeleteImportedData(permissions: any, id: number | string): boolean {
    return this.isValid(permissions, id) && permissions.projects[`${id}`]['project.can_delete_imported_data']
  }

  //
  // その他
  //
  static isValid(permissions: any, id: number | string) {
    if (!permissions) {
      return false
    }
    return Object.keys(permissions.projects).some((project) => project === id.toString())
  }
}
