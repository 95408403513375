import * as React from 'react'

import styled from 'styled-components'

interface Props {
  readonly type: string
  readonly condition: string
  readonly value: string | number
}

export function EventCondition(props: Props) {
  return (
    <Container>
      <Type>{props.type}</Type>
      <div>
        {props.condition}: {props.value}
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  line-height: 21px;
`
const Type = styled.div`
  width: 100px;
`
