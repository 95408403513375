import * as React from 'react'
import styled from 'styled-components'

import { SortItemKey, SortState } from '../../../util/hooks/api/useContentReport'
import { SortButton } from '../../common/SortButton'
import { BaseHeaderCell } from './BaseCell'
import { HeaderCellHelp } from './HeaderCellHelp'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'

interface Props {
  readonly sortState: SortState
  readonly onSortClick: (key: SortItemKey) => void
}

export function ClickRateHeaderCell({ sortState, onSortClick }: Props) {
  return (
    <Cell onClick={() => onSortClick('clickRate')}>
      率
      <HeaderCellHelp width={240} left={-200}>
        <div>クリック数 ÷ ビュー数</div>
        <div>クリックはビュー判定に関わらずカウントされます。</div>
      </HeaderCellHelp>
      <SortButton elementKey={'clickRate'} sortIcon={sortState['clickRate']} />
    </Cell>
  )
}

const Cell = styled(BaseHeaderCell)`
  background-color: ${CELL_BACKGROUND_COLORS.CLICK};
`
