import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'

interface Props {
  filterLength: number
  hasFilterError?: boolean
}

export function FunnelAppliedFilterLabel({ filterLength, hasFilterError }: Props) {
  return (
    <LabelContainer apply={!!filterLength} hasFilterError={hasFilterError}>
      フィルタ{filterLength ? ` (${filterLength})` : 'なし'}
    </LabelContainer>
  )
}

const LabelContainer = styled.div<{ apply: boolean; hasFilterError?: boolean }>`
  display: inline-block;
  margin-bottom: 8px;
  padding: 6px 12px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  ${({ apply }) =>
    apply
      ? `
        background: ${colors.yellow2};
        color: ${colors.black};`
      : `
        background: ${colors.gray100};
        color: ${colors.disabled};`}
  ${({ hasFilterError }) =>
    hasFilterError &&
    `
      background: ${colors.errorBg};
      color: ${colors.error};`}
`
