// GENERATED BY tools/scripts/genUnicodeTable.js; DO NOT EDIT.
// yarn node ./tools/scripts/genUnicodeTable.js > src/UnicodeTables.js

class UnicodeTables {
  static CASE_ORBIT = new Map([
    [75, 107],
    [107, 8490],
    [8490, 75],
    [83, 115],
    [115, 383],
    [383, 83],
    [181, 924],
    [924, 956],
    [956, 181],
    [197, 229],
    [229, 8491],
    [8491, 197],
    [452, 453],
    [453, 454],
    [454, 452],
    [455, 456],
    [456, 457],
    [457, 455],
    [458, 459],
    [459, 460],
    [460, 458],
    [497, 498],
    [498, 499],
    [499, 497],
    [837, 921],
    [921, 953],
    [953, 8126],
    [8126, 837],
    [914, 946],
    [946, 976],
    [976, 914],
    [917, 949],
    [949, 1013],
    [1013, 917],
    [920, 952],
    [952, 977],
    [977, 1012],
    [1012, 920],
    [922, 954],
    [954, 1008],
    [1008, 922],
    [928, 960],
    [960, 982],
    [982, 928],
    [929, 961],
    [961, 1009],
    [1009, 929],
    [931, 962],
    [962, 963],
    [963, 931],
    [934, 966],
    [966, 981],
    [981, 934],
    [937, 969],
    [969, 8486],
    [8486, 937],
    [1042, 1074],
    [1074, 7296],
    [7296, 1042],
    [1044, 1076],
    [1076, 7297],
    [7297, 1044],
    [1054, 1086],
    [1086, 7298],
    [7298, 1054],
    [1057, 1089],
    [1089, 7299],
    [7299, 1057],
    [1058, 1090],
    [1090, 7300],
    [7300, 7301],
    [7301, 1058],
    [1066, 1098],
    [1098, 7302],
    [7302, 1066],
    [1122, 1123],
    [1123, 7303],
    [7303, 1122],
    [7304, 42570],
    [42570, 42571],
    [42571, 7304],
    [7776, 7777],
    [7777, 7835],
    [7835, 7776],
    [223, 7838],
    [7838, 223],
    [8064, 8072],
    [8072, 8064],
    [8065, 8073],
    [8073, 8065],
    [8066, 8074],
    [8074, 8066],
    [8067, 8075],
    [8075, 8067],
    [8068, 8076],
    [8076, 8068],
    [8069, 8077],
    [8077, 8069],
    [8070, 8078],
    [8078, 8070],
    [8071, 8079],
    [8079, 8071],
    [8080, 8088],
    [8088, 8080],
    [8081, 8089],
    [8089, 8081],
    [8082, 8090],
    [8090, 8082],
    [8083, 8091],
    [8091, 8083],
    [8084, 8092],
    [8092, 8084],
    [8085, 8093],
    [8093, 8085],
    [8086, 8094],
    [8094, 8086],
    [8087, 8095],
    [8095, 8087],
    [8096, 8104],
    [8104, 8096],
    [8097, 8105],
    [8105, 8097],
    [8098, 8106],
    [8106, 8098],
    [8099, 8107],
    [8107, 8099],
    [8100, 8108],
    [8108, 8100],
    [8101, 8109],
    [8109, 8101],
    [8102, 8110],
    [8110, 8102],
    [8103, 8111],
    [8111, 8103],
    [8115, 8124],
    [8124, 8115],
    [8131, 8140],
    [8140, 8131],
    [912, 8147],
    [8147, 912],
    [944, 8163],
    [8163, 944],
    [8179, 8188],
    [8188, 8179],
    [64261, 64262],
    [64262, 64261],
    [66560, 66600],
    [66600, 66560],
    [66561, 66601],
    [66601, 66561],
    [66562, 66602],
    [66602, 66562],
    [66563, 66603],
    [66603, 66563],
    [66564, 66604],
    [66604, 66564],
    [66565, 66605],
    [66605, 66565],
    [66566, 66606],
    [66606, 66566],
    [66567, 66607],
    [66607, 66567],
    [66568, 66608],
    [66608, 66568],
    [66569, 66609],
    [66609, 66569],
    [66570, 66610],
    [66610, 66570],
    [66571, 66611],
    [66611, 66571],
    [66572, 66612],
    [66612, 66572],
    [66573, 66613],
    [66613, 66573],
    [66574, 66614],
    [66614, 66574],
    [66575, 66615],
    [66615, 66575],
    [66576, 66616],
    [66616, 66576],
    [66577, 66617],
    [66617, 66577],
    [66578, 66618],
    [66618, 66578],
    [66579, 66619],
    [66619, 66579],
    [66580, 66620],
    [66620, 66580],
    [66581, 66621],
    [66621, 66581],
    [66582, 66622],
    [66622, 66582],
    [66583, 66623],
    [66623, 66583],
    [66584, 66624],
    [66624, 66584],
    [66585, 66625],
    [66625, 66585],
    [66586, 66626],
    [66626, 66586],
    [66587, 66627],
    [66627, 66587],
    [66588, 66628],
    [66628, 66588],
    [66589, 66629],
    [66629, 66589],
    [66590, 66630],
    [66630, 66590],
    [66591, 66631],
    [66631, 66591],
    [66592, 66632],
    [66632, 66592],
    [66593, 66633],
    [66633, 66593],
    [66594, 66634],
    [66634, 66594],
    [66595, 66635],
    [66635, 66595],
    [66596, 66636],
    [66636, 66596],
    [66597, 66637],
    [66637, 66597],
    [66598, 66638],
    [66638, 66598],
    [66599, 66639],
    [66639, 66599],
    [66736, 66776],
    [66776, 66736],
    [66737, 66777],
    [66777, 66737],
    [66738, 66778],
    [66778, 66738],
    [66739, 66779],
    [66779, 66739],
    [66740, 66780],
    [66780, 66740],
    [66741, 66781],
    [66781, 66741],
    [66742, 66782],
    [66782, 66742],
    [66743, 66783],
    [66783, 66743],
    [66744, 66784],
    [66784, 66744],
    [66745, 66785],
    [66785, 66745],
    [66746, 66786],
    [66786, 66746],
    [66747, 66787],
    [66787, 66747],
    [66748, 66788],
    [66788, 66748],
    [66749, 66789],
    [66789, 66749],
    [66750, 66790],
    [66790, 66750],
    [66751, 66791],
    [66791, 66751],
    [66752, 66792],
    [66792, 66752],
    [66753, 66793],
    [66793, 66753],
    [66754, 66794],
    [66794, 66754],
    [66755, 66795],
    [66795, 66755],
    [66756, 66796],
    [66796, 66756],
    [66757, 66797],
    [66797, 66757],
    [66758, 66798],
    [66798, 66758],
    [66759, 66799],
    [66799, 66759],
    [66760, 66800],
    [66800, 66760],
    [66761, 66801],
    [66801, 66761],
    [66762, 66802],
    [66802, 66762],
    [66763, 66803],
    [66803, 66763],
    [66764, 66804],
    [66804, 66764],
    [66765, 66805],
    [66805, 66765],
    [66766, 66806],
    [66806, 66766],
    [66767, 66807],
    [66807, 66767],
    [66768, 66808],
    [66808, 66768],
    [66769, 66809],
    [66809, 66769],
    [66770, 66810],
    [66810, 66770],
    [66771, 66811],
    [66811, 66771],
    [66928, 66967],
    [66967, 66928],
    [66929, 66968],
    [66968, 66929],
    [66930, 66969],
    [66969, 66930],
    [66931, 66970],
    [66970, 66931],
    [66932, 66971],
    [66971, 66932],
    [66933, 66972],
    [66972, 66933],
    [66934, 66973],
    [66973, 66934],
    [66935, 66974],
    [66974, 66935],
    [66936, 66975],
    [66975, 66936],
    [66937, 66976],
    [66976, 66937],
    [66938, 66977],
    [66977, 66938],
    [66940, 66979],
    [66979, 66940],
    [66941, 66980],
    [66980, 66941],
    [66942, 66981],
    [66981, 66942],
    [66943, 66982],
    [66982, 66943],
    [66944, 66983],
    [66983, 66944],
    [66945, 66984],
    [66984, 66945],
    [66946, 66985],
    [66985, 66946],
    [66947, 66986],
    [66986, 66947],
    [66948, 66987],
    [66987, 66948],
    [66949, 66988],
    [66988, 66949],
    [66950, 66989],
    [66989, 66950],
    [66951, 66990],
    [66990, 66951],
    [66952, 66991],
    [66991, 66952],
    [66953, 66992],
    [66992, 66953],
    [66954, 66993],
    [66993, 66954],
    [66956, 66995],
    [66995, 66956],
    [66957, 66996],
    [66996, 66957],
    [66958, 66997],
    [66997, 66958],
    [66959, 66998],
    [66998, 66959],
    [66960, 66999],
    [66999, 66960],
    [66961, 67000],
    [67000, 66961],
    [66962, 67001],
    [67001, 66962],
    [66964, 67003],
    [67003, 66964],
    [66965, 67004],
    [67004, 66965],
    [68736, 68800],
    [68800, 68736],
    [68737, 68801],
    [68801, 68737],
    [68738, 68802],
    [68802, 68738],
    [68739, 68803],
    [68803, 68739],
    [68740, 68804],
    [68804, 68740],
    [68741, 68805],
    [68805, 68741],
    [68742, 68806],
    [68806, 68742],
    [68743, 68807],
    [68807, 68743],
    [68744, 68808],
    [68808, 68744],
    [68745, 68809],
    [68809, 68745],
    [68746, 68810],
    [68810, 68746],
    [68747, 68811],
    [68811, 68747],
    [68748, 68812],
    [68812, 68748],
    [68749, 68813],
    [68813, 68749],
    [68750, 68814],
    [68814, 68750],
    [68751, 68815],
    [68815, 68751],
    [68752, 68816],
    [68816, 68752],
    [68753, 68817],
    [68817, 68753],
    [68754, 68818],
    [68818, 68754],
    [68755, 68819],
    [68819, 68755],
    [68756, 68820],
    [68820, 68756],
    [68757, 68821],
    [68821, 68757],
    [68758, 68822],
    [68822, 68758],
    [68759, 68823],
    [68823, 68759],
    [68760, 68824],
    [68824, 68760],
    [68761, 68825],
    [68825, 68761],
    [68762, 68826],
    [68826, 68762],
    [68763, 68827],
    [68827, 68763],
    [68764, 68828],
    [68828, 68764],
    [68765, 68829],
    [68829, 68765],
    [68766, 68830],
    [68830, 68766],
    [68767, 68831],
    [68831, 68767],
    [68768, 68832],
    [68832, 68768],
    [68769, 68833],
    [68833, 68769],
    [68770, 68834],
    [68834, 68770],
    [68771, 68835],
    [68835, 68771],
    [68772, 68836],
    [68836, 68772],
    [68773, 68837],
    [68837, 68773],
    [68774, 68838],
    [68838, 68774],
    [68775, 68839],
    [68839, 68775],
    [68776, 68840],
    [68840, 68776],
    [68777, 68841],
    [68841, 68777],
    [68778, 68842],
    [68842, 68778],
    [68779, 68843],
    [68843, 68779],
    [68780, 68844],
    [68844, 68780],
    [68781, 68845],
    [68845, 68781],
    [68782, 68846],
    [68846, 68782],
    [68783, 68847],
    [68847, 68783],
    [68784, 68848],
    [68848, 68784],
    [68785, 68849],
    [68849, 68785],
    [68786, 68850],
    [68850, 68786],
    [71840, 71872],
    [71872, 71840],
    [71841, 71873],
    [71873, 71841],
    [71842, 71874],
    [71874, 71842],
    [71843, 71875],
    [71875, 71843],
    [71844, 71876],
    [71876, 71844],
    [71845, 71877],
    [71877, 71845],
    [71846, 71878],
    [71878, 71846],
    [71847, 71879],
    [71879, 71847],
    [71848, 71880],
    [71880, 71848],
    [71849, 71881],
    [71881, 71849],
    [71850, 71882],
    [71882, 71850],
    [71851, 71883],
    [71883, 71851],
    [71852, 71884],
    [71884, 71852],
    [71853, 71885],
    [71885, 71853],
    [71854, 71886],
    [71886, 71854],
    [71855, 71887],
    [71887, 71855],
    [71856, 71888],
    [71888, 71856],
    [71857, 71889],
    [71889, 71857],
    [71858, 71890],
    [71890, 71858],
    [71859, 71891],
    [71891, 71859],
    [71860, 71892],
    [71892, 71860],
    [71861, 71893],
    [71893, 71861],
    [71862, 71894],
    [71894, 71862],
    [71863, 71895],
    [71895, 71863],
    [71864, 71896],
    [71896, 71864],
    [71865, 71897],
    [71897, 71865],
    [71866, 71898],
    [71898, 71866],
    [71867, 71899],
    [71899, 71867],
    [71868, 71900],
    [71900, 71868],
    [71869, 71901],
    [71901, 71869],
    [71870, 71902],
    [71902, 71870],
    [71871, 71903],
    [71903, 71871],
    [93760, 93792],
    [93792, 93760],
    [93761, 93793],
    [93793, 93761],
    [93762, 93794],
    [93794, 93762],
    [93763, 93795],
    [93795, 93763],
    [93764, 93796],
    [93796, 93764],
    [93765, 93797],
    [93797, 93765],
    [93766, 93798],
    [93798, 93766],
    [93767, 93799],
    [93799, 93767],
    [93768, 93800],
    [93800, 93768],
    [93769, 93801],
    [93801, 93769],
    [93770, 93802],
    [93802, 93770],
    [93771, 93803],
    [93803, 93771],
    [93772, 93804],
    [93804, 93772],
    [93773, 93805],
    [93805, 93773],
    [93774, 93806],
    [93806, 93774],
    [93775, 93807],
    [93807, 93775],
    [93776, 93808],
    [93808, 93776],
    [93777, 93809],
    [93809, 93777],
    [93778, 93810],
    [93810, 93778],
    [93779, 93811],
    [93811, 93779],
    [93780, 93812],
    [93812, 93780],
    [93781, 93813],
    [93813, 93781],
    [93782, 93814],
    [93814, 93782],
    [93783, 93815],
    [93815, 93783],
    [93784, 93816],
    [93816, 93784],
    [93785, 93817],
    [93817, 93785],
    [93786, 93818],
    [93818, 93786],
    [93787, 93819],
    [93819, 93787],
    [93788, 93820],
    [93820, 93788],
    [93789, 93821],
    [93821, 93789],
    [93790, 93822],
    [93822, 93790],
    [93791, 93823],
    [93823, 93791],
    [125184, 125218],
    [125218, 125184],
    [125185, 125219],
    [125219, 125185],
    [125186, 125220],
    [125220, 125186],
    [125187, 125221],
    [125221, 125187],
    [125188, 125222],
    [125222, 125188],
    [125189, 125223],
    [125223, 125189],
    [125190, 125224],
    [125224, 125190],
    [125191, 125225],
    [125225, 125191],
    [125192, 125226],
    [125226, 125192],
    [125193, 125227],
    [125227, 125193],
    [125194, 125228],
    [125228, 125194],
    [125195, 125229],
    [125229, 125195],
    [125196, 125230],
    [125230, 125196],
    [125197, 125231],
    [125231, 125197],
    [125198, 125232],
    [125232, 125198],
    [125199, 125233],
    [125233, 125199],
    [125200, 125234],
    [125234, 125200],
    [125201, 125235],
    [125235, 125201],
    [125202, 125236],
    [125236, 125202],
    [125203, 125237],
    [125237, 125203],
    [125204, 125238],
    [125238, 125204],
    [125205, 125239],
    [125239, 125205],
    [125206, 125240],
    [125240, 125206],
    [125207, 125241],
    [125241, 125207],
    [125208, 125242],
    [125242, 125208],
    [125209, 125243],
    [125243, 125209],
    [125210, 125244],
    [125244, 125210],
    [125211, 125245],
    [125245, 125211],
    [125212, 125246],
    [125246, 125212],
    [125213, 125247],
    [125247, 125213],
    [125214, 125248],
    [125248, 125214],
    [125215, 125249],
    [125249, 125215],
    [125216, 125250],
    [125250, 125216],
    [125217, 125251],
    [125251, 125217]
  ])
  static C = [
    [0, 31, 1],
    [127, 159, 1],
    [173, 888, 715],
    [889, 896, 7],
    [897, 899, 1],
    [907, 909, 2],
    [930, 1328, 398],
    [1367, 1368, 1],
    [1419, 1420, 1],
    [1424, 1480, 56],
    [1481, 1487, 1],
    [1515, 1518, 1],
    [1525, 1541, 1],
    [1564, 1757, 193],
    [1806, 1807, 1],
    [1867, 1868, 1],
    [1970, 1983, 1],
    [2043, 2044, 1],
    [2094, 2095, 1],
    [2111, 2140, 29],
    [2141, 2143, 2],
    [2155, 2159, 1],
    [2191, 2199, 1],
    [2274, 2436, 162],
    [2445, 2446, 1],
    [2449, 2450, 1],
    [2473, 2481, 8],
    [2483, 2485, 1],
    [2490, 2491, 1],
    [2501, 2502, 1],
    [2505, 2506, 1],
    [2511, 2518, 1],
    [2520, 2523, 1],
    [2526, 2532, 6],
    [2533, 2559, 26],
    [2560, 2564, 4],
    [2571, 2574, 1],
    [2577, 2578, 1],
    [2601, 2609, 8],
    [2612, 2618, 3],
    [2619, 2621, 2],
    [2627, 2630, 1],
    [2633, 2634, 1],
    [2638, 2640, 1],
    [2642, 2648, 1],
    [2653, 2655, 2],
    [2656, 2661, 1],
    [2679, 2688, 1],
    [2692, 2702, 10],
    [2706, 2729, 23],
    [2737, 2740, 3],
    [2746, 2747, 1],
    [2758, 2766, 4],
    [2767, 2769, 2],
    [2770, 2783, 1],
    [2788, 2789, 1],
    [2802, 2808, 1],
    [2816, 2820, 4],
    [2829, 2830, 1],
    [2833, 2834, 1],
    [2857, 2865, 8],
    [2868, 2874, 6],
    [2875, 2885, 10],
    [2886, 2889, 3],
    [2890, 2894, 4],
    [2895, 2900, 1],
    [2904, 2907, 1],
    [2910, 2916, 6],
    [2917, 2936, 19],
    [2937, 2945, 1],
    [2948, 2955, 7],
    [2956, 2957, 1],
    [2961, 2966, 5],
    [2967, 2968, 1],
    [2971, 2973, 2],
    [2976, 2978, 1],
    [2981, 2983, 1],
    [2987, 2989, 1],
    [3002, 3005, 1],
    [3011, 3013, 1],
    [3017, 3022, 5],
    [3023, 3025, 2],
    [3026, 3030, 1],
    [3032, 3045, 1],
    [3067, 3071, 1],
    [3085, 3089, 4],
    [3113, 3130, 17],
    [3131, 3141, 10],
    [3145, 3150, 5],
    [3151, 3156, 1],
    [3159, 3163, 4],
    [3164, 3166, 2],
    [3167, 3172, 5],
    [3173, 3184, 11],
    [3185, 3190, 1],
    [3213, 3217, 4],
    [3241, 3252, 11],
    [3258, 3259, 1],
    [3269, 3273, 4],
    [3278, 3284, 1],
    [3287, 3292, 1],
    [3295, 3300, 5],
    [3301, 3312, 11],
    [3316, 3327, 1],
    [3341, 3345, 4],
    [3397, 3401, 4],
    [3408, 3411, 1],
    [3428, 3429, 1],
    [3456, 3460, 4],
    [3479, 3481, 1],
    [3506, 3516, 10],
    [3518, 3519, 1],
    [3527, 3529, 1],
    [3531, 3534, 1],
    [3541, 3543, 2],
    [3552, 3557, 1],
    [3568, 3569, 1],
    [3573, 3584, 1],
    [3643, 3646, 1],
    [3676, 3712, 1],
    [3715, 3717, 2],
    [3723, 3748, 25],
    [3750, 3774, 24],
    [3775, 3781, 6],
    [3783, 3791, 8],
    [3802, 3803, 1],
    [3808, 3839, 1],
    [3912, 3949, 37],
    [3950, 3952, 1],
    [3992, 4029, 37],
    [4045, 4059, 14],
    [4060, 4095, 1],
    [4294, 4296, 2],
    [4297, 4300, 1],
    [4302, 4303, 1],
    [4681, 4686, 5],
    [4687, 4695, 8],
    [4697, 4702, 5],
    [4703, 4745, 42],
    [4750, 4751, 1],
    [4785, 4790, 5],
    [4791, 4799, 8],
    [4801, 4806, 5],
    [4807, 4823, 16],
    [4881, 4886, 5],
    [4887, 4955, 68],
    [4956, 4989, 33],
    [4990, 4991, 1],
    [5018, 5023, 1],
    [5110, 5111, 1],
    [5118, 5119, 1],
    [5789, 5791, 1],
    [5881, 5887, 1],
    [5910, 5918, 1],
    [5943, 5951, 1],
    [5972, 5983, 1],
    [5997, 6001, 4],
    [6004, 6015, 1],
    [6110, 6111, 1],
    [6122, 6127, 1],
    [6138, 6143, 1],
    [6158, 6170, 12],
    [6171, 6175, 1],
    [6265, 6271, 1],
    [6315, 6319, 1],
    [6390, 6399, 1],
    [6431, 6444, 13],
    [6445, 6447, 1],
    [6460, 6463, 1],
    [6465, 6467, 1],
    [6510, 6511, 1],
    [6517, 6527, 1],
    [6572, 6575, 1],
    [6602, 6607, 1],
    [6619, 6621, 1],
    [6684, 6685, 1],
    [6751, 6781, 30],
    [6782, 6794, 12],
    [6795, 6799, 1],
    [6810, 6815, 1],
    [6830, 6831, 1],
    [6863, 6911, 1],
    [6989, 6991, 1],
    [7039, 7156, 117],
    [7157, 7163, 1],
    [7224, 7226, 1],
    [7242, 7244, 1],
    [7305, 7311, 1],
    [7355, 7356, 1],
    [7368, 7375, 1],
    [7419, 7423, 1],
    [7958, 7959, 1],
    [7966, 7967, 1],
    [8006, 8007, 1],
    [8014, 8015, 1],
    [8024, 8030, 2],
    [8062, 8063, 1],
    [8117, 8133, 16],
    [8148, 8149, 1],
    [8156, 8176, 20],
    [8177, 8181, 4],
    [8191, 8203, 12],
    [8204, 8207, 1],
    [8234, 8238, 1],
    [8288, 8303, 1],
    [8306, 8307, 1],
    [8335, 8349, 14],
    [8350, 8351, 1],
    [8385, 8399, 1],
    [8433, 8447, 1],
    [8588, 8591, 1],
    [9255, 9279, 1],
    [9291, 9311, 1],
    [11124, 11125, 1],
    [11158, 11508, 350],
    [11509, 11512, 1],
    [11558, 11560, 2],
    [11561, 11564, 1],
    [11566, 11567, 1],
    [11624, 11630, 1],
    [11633, 11646, 1],
    [11671, 11679, 1],
    [11687, 11743, 8],
    [11870, 11903, 1],
    [11930, 12020, 90],
    [12021, 12031, 1],
    [12246, 12271, 1],
    [12352, 12439, 87],
    [12440, 12544, 104],
    [12545, 12548, 1],
    [12592, 12687, 95],
    [12772, 12782, 1],
    [12831, 42125, 29294],
    [42126, 42127, 1],
    [42183, 42191, 1],
    [42540, 42559, 1],
    [42744, 42751, 1],
    [42955, 42959, 1],
    [42962, 42964, 2],
    [42970, 42993, 1],
    [43053, 43055, 1],
    [43066, 43071, 1],
    [43128, 43135, 1],
    [43206, 43213, 1],
    [43226, 43231, 1],
    [43348, 43358, 1],
    [43389, 43391, 1],
    [43470, 43482, 12],
    [43483, 43485, 1],
    [43519, 43575, 56],
    [43576, 43583, 1],
    [43598, 43599, 1],
    [43610, 43611, 1],
    [43715, 43738, 1],
    [43767, 43776, 1],
    [43783, 43784, 1],
    [43791, 43792, 1],
    [43799, 43807, 1],
    [43815, 43823, 8],
    [43884, 43887, 1],
    [44014, 44015, 1],
    [44026, 44031, 1],
    [55204, 55215, 1],
    [55239, 55242, 1],
    [55292, 63743, 1],
    [64110, 64111, 1],
    [64218, 64255, 1],
    [64263, 64274, 1],
    [64280, 64284, 1],
    [64311, 64317, 6],
    [64319, 64325, 3],
    [64451, 64466, 1],
    [64912, 64913, 1],
    [64968, 64974, 1],
    [64976, 65007, 1],
    [65050, 65055, 1],
    [65107, 65127, 20],
    [65132, 65135, 1],
    [65141, 65277, 136],
    [65278, 65280, 1],
    [65471, 65473, 1],
    [65480, 65481, 1],
    [65488, 65489, 1],
    [65496, 65497, 1],
    [65501, 65503, 1],
    [65511, 65519, 8],
    [65520, 65531, 1],
    [65534, 65535, 1],
    [65548, 65575, 27],
    [65595, 65598, 3],
    [65614, 65615, 1],
    [65630, 65663, 1],
    [65787, 65791, 1],
    [65795, 65798, 1],
    [65844, 65846, 1],
    [65935, 65949, 14],
    [65950, 65951, 1],
    [65953, 65999, 1],
    [66046, 66175, 1],
    [66205, 66207, 1],
    [66257, 66271, 1],
    [66300, 66303, 1],
    [66340, 66348, 1],
    [66379, 66383, 1],
    [66427, 66431, 1],
    [66462, 66500, 38],
    [66501, 66503, 1],
    [66518, 66559, 1],
    [66718, 66719, 1],
    [66730, 66735, 1],
    [66772, 66775, 1],
    [66812, 66815, 1],
    [66856, 66863, 1],
    [66916, 66926, 1],
    [66939, 66955, 16],
    [66963, 66966, 3],
    [66978, 66994, 16],
    [67002, 67005, 3],
    [67006, 67071, 1],
    [67383, 67391, 1],
    [67414, 67423, 1],
    [67432, 67455, 1],
    [67462, 67505, 43],
    [67515, 67583, 1],
    [67590, 67591, 1],
    [67593, 67638, 45],
    [67641, 67643, 1],
    [67645, 67646, 1],
    [67670, 67743, 73],
    [67744, 67750, 1],
    [67760, 67807, 1],
    [67827, 67830, 3],
    [67831, 67834, 1],
    [67868, 67870, 1],
    [67898, 67902, 1],
    [67904, 67967, 1],
    [68024, 68027, 1],
    [68048, 68049, 1],
    [68100, 68103, 3],
    [68104, 68107, 1],
    [68116, 68120, 4],
    [68150, 68151, 1],
    [68155, 68158, 1],
    [68169, 68175, 1],
    [68185, 68191, 1],
    [68256, 68287, 1],
    [68327, 68330, 1],
    [68343, 68351, 1],
    [68406, 68408, 1],
    [68438, 68439, 1],
    [68467, 68471, 1],
    [68498, 68504, 1],
    [68509, 68520, 1],
    [68528, 68607, 1],
    [68681, 68735, 1],
    [68787, 68799, 1],
    [68851, 68857, 1],
    [68904, 68911, 1],
    [68922, 69215, 1],
    [69247, 69290, 43],
    [69294, 69295, 1],
    [69298, 69372, 1],
    [69416, 69423, 1],
    [69466, 69487, 1],
    [69514, 69551, 1],
    [69580, 69599, 1],
    [69623, 69631, 1],
    [69710, 69713, 1],
    [69750, 69758, 1],
    [69821, 69827, 6],
    [69828, 69839, 1],
    [69865, 69871, 1],
    [69882, 69887, 1],
    [69941, 69960, 19],
    [69961, 69967, 1],
    [70007, 70015, 1],
    [70112, 70133, 21],
    [70134, 70143, 1],
    [70162, 70210, 48],
    [70211, 70271, 1],
    [70279, 70281, 2],
    [70286, 70302, 16],
    [70314, 70319, 1],
    [70379, 70383, 1],
    [70394, 70399, 1],
    [70404, 70413, 9],
    [70414, 70417, 3],
    [70418, 70441, 23],
    [70449, 70452, 3],
    [70458, 70469, 11],
    [70470, 70473, 3],
    [70474, 70478, 4],
    [70479, 70481, 2],
    [70482, 70486, 1],
    [70488, 70492, 1],
    [70500, 70501, 1],
    [70509, 70511, 1],
    [70517, 70655, 1],
    [70748, 70754, 6],
    [70755, 70783, 1],
    [70856, 70863, 1],
    [70874, 71039, 1],
    [71094, 71095, 1],
    [71134, 71167, 1],
    [71237, 71247, 1],
    [71258, 71263, 1],
    [71277, 71295, 1],
    [71354, 71359, 1],
    [71370, 71423, 1],
    [71451, 71452, 1],
    [71468, 71471, 1],
    [71495, 71679, 1],
    [71740, 71839, 1],
    [71923, 71934, 1],
    [71943, 71944, 1],
    [71946, 71947, 1],
    [71956, 71959, 3],
    [71990, 71993, 3],
    [71994, 72007, 13],
    [72008, 72015, 1],
    [72026, 72095, 1],
    [72104, 72105, 1],
    [72152, 72153, 1],
    [72165, 72191, 1],
    [72264, 72271, 1],
    [72355, 72367, 1],
    [72441, 72447, 1],
    [72458, 72703, 1],
    [72713, 72759, 46],
    [72774, 72783, 1],
    [72813, 72815, 1],
    [72848, 72849, 1],
    [72872, 72887, 15],
    [72888, 72959, 1],
    [72967, 72970, 3],
    [73015, 73017, 1],
    [73019, 73022, 3],
    [73032, 73039, 1],
    [73050, 73055, 1],
    [73062, 73065, 3],
    [73103, 73106, 3],
    [73113, 73119, 1],
    [73130, 73439, 1],
    [73465, 73471, 1],
    [73489, 73531, 42],
    [73532, 73533, 1],
    [73562, 73647, 1],
    [73649, 73663, 1],
    [73714, 73726, 1],
    [74650, 74751, 1],
    [74863, 74869, 6],
    [74870, 74879, 1],
    [75076, 77711, 1],
    [77811, 77823, 1],
    [78896, 78911, 1],
    [78934, 82943, 1],
    [83527, 92159, 1],
    [92729, 92735, 1],
    [92767, 92778, 11],
    [92779, 92781, 1],
    [92863, 92874, 11],
    [92875, 92879, 1],
    [92910, 92911, 1],
    [92918, 92927, 1],
    [92998, 93007, 1],
    [93018, 93026, 8],
    [93048, 93052, 1],
    [93072, 93759, 1],
    [93851, 93951, 1],
    [94027, 94030, 1],
    [94088, 94094, 1],
    [94112, 94175, 1],
    [94181, 94191, 1],
    [94194, 94207, 1],
    [100344, 100351, 1],
    [101590, 101631, 1],
    [101641, 110575, 1],
    [110580, 110588, 8],
    [110591, 110883, 292],
    [110884, 110897, 1],
    [110899, 110927, 1],
    [110931, 110932, 1],
    [110934, 110947, 1],
    [110952, 110959, 1],
    [111356, 113663, 1],
    [113771, 113775, 1],
    [113789, 113791, 1],
    [113801, 113807, 1],
    [113818, 113819, 1],
    [113824, 118527, 1],
    [118574, 118575, 1],
    [118599, 118607, 1],
    [118724, 118783, 1],
    [119030, 119039, 1],
    [119079, 119080, 1],
    [119155, 119162, 1],
    [119275, 119295, 1],
    [119366, 119487, 1],
    [119508, 119519, 1],
    [119540, 119551, 1],
    [119639, 119647, 1],
    [119673, 119807, 1],
    [119893, 119965, 72],
    [119968, 119969, 1],
    [119971, 119972, 1],
    [119975, 119976, 1],
    [119981, 119994, 13],
    [119996, 120004, 8],
    [120070, 120075, 5],
    [120076, 120085, 9],
    [120093, 120122, 29],
    [120127, 120133, 6],
    [120135, 120137, 1],
    [120145, 120486, 341],
    [120487, 120780, 293],
    [120781, 121484, 703],
    [121485, 121498, 1],
    [121504, 121520, 16],
    [121521, 122623, 1],
    [122655, 122660, 1],
    [122667, 122879, 1],
    [122887, 122905, 18],
    [122906, 122914, 8],
    [122917, 122923, 6],
    [122924, 122927, 1],
    [122990, 123022, 1],
    [123024, 123135, 1],
    [123181, 123183, 1],
    [123198, 123199, 1],
    [123210, 123213, 1],
    [123216, 123535, 1],
    [123567, 123583, 1],
    [123642, 123646, 1],
    [123648, 124111, 1],
    [124154, 124895, 1],
    [124903, 124908, 5],
    [124911, 124927, 16],
    [125125, 125126, 1],
    [125143, 125183, 1],
    [125260, 125263, 1],
    [125274, 125277, 1],
    [125280, 126064, 1],
    [126133, 126208, 1],
    [126270, 126463, 1],
    [126468, 126496, 28],
    [126499, 126501, 2],
    [126502, 126504, 2],
    [126515, 126520, 5],
    [126522, 126524, 2],
    [126525, 126529, 1],
    [126531, 126534, 1],
    [126536, 126540, 2],
    [126544, 126547, 3],
    [126549, 126550, 1],
    [126552, 126560, 2],
    [126563, 126565, 2],
    [126566, 126571, 5],
    [126579, 126589, 5],
    [126591, 126602, 11],
    [126620, 126624, 1],
    [126628, 126634, 6],
    [126652, 126703, 1],
    [126706, 126975, 1],
    [127020, 127023, 1],
    [127124, 127135, 1],
    [127151, 127152, 1],
    [127168, 127184, 16],
    [127222, 127231, 1],
    [127406, 127461, 1],
    [127491, 127503, 1],
    [127548, 127551, 1],
    [127561, 127567, 1],
    [127570, 127583, 1],
    [127590, 127743, 1],
    [128728, 128731, 1],
    [128749, 128751, 1],
    [128765, 128767, 1],
    [128887, 128890, 1],
    [128986, 128991, 1],
    [129004, 129007, 1],
    [129009, 129023, 1],
    [129036, 129039, 1],
    [129096, 129103, 1],
    [129114, 129119, 1],
    [129160, 129167, 1],
    [129198, 129199, 1],
    [129202, 129279, 1],
    [129620, 129631, 1],
    [129646, 129647, 1],
    [129661, 129663, 1],
    [129673, 129679, 1],
    [129726, 129734, 8],
    [129735, 129741, 1],
    [129756, 129759, 1],
    [129769, 129775, 1],
    [129785, 129791, 1],
    [129939, 129995, 56],
    [129996, 130031, 1],
    [130042, 131071, 1],
    [173792, 173823, 1],
    [177978, 177983, 1],
    [178206, 178207, 1],
    [183970, 183983, 1],
    [191457, 191471, 1],
    [192094, 194559, 1],
    [195102, 196607, 1],
    [201547, 201551, 1],
    [205744, 917759, 1],
    [918000, 1114111, 1]
  ]
  static Cc = [
    [0, 31, 1],
    [127, 159, 1]
  ]
  static Cf = [
    [173, 1536, 1363],
    [1537, 1541, 1],
    [1564, 1757, 193],
    [1807, 2192, 385],
    [2193, 2274, 81],
    [6158, 8203, 2045],
    [8204, 8207, 1],
    [8234, 8238, 1],
    [8288, 8292, 1],
    [8294, 8303, 1],
    [65279, 65529, 250],
    [65530, 65531, 1],
    [69821, 69837, 16],
    [78896, 78911, 1],
    [113824, 113827, 1],
    [119155, 119162, 1],
    [917505, 917536, 31],
    [917537, 917631, 1]
  ]
  static Co = [
    [57344, 63743, 1],
    [983040, 1048573, 1],
    [1048576, 1114109, 1]
  ]
  static Cs = [[55296, 57343, 1]]
  static L = [
    [65, 90, 1],
    [97, 122, 1],
    [170, 181, 11],
    [186, 192, 6],
    [193, 214, 1],
    [216, 246, 1],
    [248, 705, 1],
    [710, 721, 1],
    [736, 740, 1],
    [748, 750, 2],
    [880, 884, 1],
    [886, 887, 1],
    [890, 893, 1],
    [895, 902, 7],
    [904, 906, 1],
    [908, 910, 2],
    [911, 929, 1],
    [931, 1013, 1],
    [1015, 1153, 1],
    [1162, 1327, 1],
    [1329, 1366, 1],
    [1369, 1376, 7],
    [1377, 1416, 1],
    [1488, 1514, 1],
    [1519, 1522, 1],
    [1568, 1610, 1],
    [1646, 1647, 1],
    [1649, 1747, 1],
    [1749, 1765, 16],
    [1766, 1774, 8],
    [1775, 1786, 11],
    [1787, 1788, 1],
    [1791, 1808, 17],
    [1810, 1839, 1],
    [1869, 1957, 1],
    [1969, 1994, 25],
    [1995, 2026, 1],
    [2036, 2037, 1],
    [2042, 2048, 6],
    [2049, 2069, 1],
    [2074, 2084, 10],
    [2088, 2112, 24],
    [2113, 2136, 1],
    [2144, 2154, 1],
    [2160, 2183, 1],
    [2185, 2190, 1],
    [2208, 2249, 1],
    [2308, 2361, 1],
    [2365, 2384, 19],
    [2392, 2401, 1],
    [2417, 2432, 1],
    [2437, 2444, 1],
    [2447, 2448, 1],
    [2451, 2472, 1],
    [2474, 2480, 1],
    [2482, 2486, 4],
    [2487, 2489, 1],
    [2493, 2510, 17],
    [2524, 2525, 1],
    [2527, 2529, 1],
    [2544, 2545, 1],
    [2556, 2565, 9],
    [2566, 2570, 1],
    [2575, 2576, 1],
    [2579, 2600, 1],
    [2602, 2608, 1],
    [2610, 2611, 1],
    [2613, 2614, 1],
    [2616, 2617, 1],
    [2649, 2652, 1],
    [2654, 2674, 20],
    [2675, 2676, 1],
    [2693, 2701, 1],
    [2703, 2705, 1],
    [2707, 2728, 1],
    [2730, 2736, 1],
    [2738, 2739, 1],
    [2741, 2745, 1],
    [2749, 2768, 19],
    [2784, 2785, 1],
    [2809, 2821, 12],
    [2822, 2828, 1],
    [2831, 2832, 1],
    [2835, 2856, 1],
    [2858, 2864, 1],
    [2866, 2867, 1],
    [2869, 2873, 1],
    [2877, 2908, 31],
    [2909, 2911, 2],
    [2912, 2913, 1],
    [2929, 2947, 18],
    [2949, 2954, 1],
    [2958, 2960, 1],
    [2962, 2965, 1],
    [2969, 2970, 1],
    [2972, 2974, 2],
    [2975, 2979, 4],
    [2980, 2984, 4],
    [2985, 2986, 1],
    [2990, 3001, 1],
    [3024, 3077, 53],
    [3078, 3084, 1],
    [3086, 3088, 1],
    [3090, 3112, 1],
    [3114, 3129, 1],
    [3133, 3160, 27],
    [3161, 3162, 1],
    [3165, 3168, 3],
    [3169, 3200, 31],
    [3205, 3212, 1],
    [3214, 3216, 1],
    [3218, 3240, 1],
    [3242, 3251, 1],
    [3253, 3257, 1],
    [3261, 3293, 32],
    [3294, 3296, 2],
    [3297, 3313, 16],
    [3314, 3332, 18],
    [3333, 3340, 1],
    [3342, 3344, 1],
    [3346, 3386, 1],
    [3389, 3406, 17],
    [3412, 3414, 1],
    [3423, 3425, 1],
    [3450, 3455, 1],
    [3461, 3478, 1],
    [3482, 3505, 1],
    [3507, 3515, 1],
    [3517, 3520, 3],
    [3521, 3526, 1],
    [3585, 3632, 1],
    [3634, 3635, 1],
    [3648, 3654, 1],
    [3713, 3714, 1],
    [3716, 3718, 2],
    [3719, 3722, 1],
    [3724, 3747, 1],
    [3749, 3751, 2],
    [3752, 3760, 1],
    [3762, 3763, 1],
    [3773, 3776, 3],
    [3777, 3780, 1],
    [3782, 3804, 22],
    [3805, 3807, 1],
    [3840, 3904, 64],
    [3905, 3911, 1],
    [3913, 3948, 1],
    [3976, 3980, 1],
    [4096, 4138, 1],
    [4159, 4176, 17],
    [4177, 4181, 1],
    [4186, 4189, 1],
    [4193, 4197, 4],
    [4198, 4206, 8],
    [4207, 4208, 1],
    [4213, 4225, 1],
    [4238, 4256, 18],
    [4257, 4293, 1],
    [4295, 4301, 6],
    [4304, 4346, 1],
    [4348, 4680, 1],
    [4682, 4685, 1],
    [4688, 4694, 1],
    [4696, 4698, 2],
    [4699, 4701, 1],
    [4704, 4744, 1],
    [4746, 4749, 1],
    [4752, 4784, 1],
    [4786, 4789, 1],
    [4792, 4798, 1],
    [4800, 4802, 2],
    [4803, 4805, 1],
    [4808, 4822, 1],
    [4824, 4880, 1],
    [4882, 4885, 1],
    [4888, 4954, 1],
    [4992, 5007, 1],
    [5024, 5109, 1],
    [5112, 5117, 1],
    [5121, 5740, 1],
    [5743, 5759, 1],
    [5761, 5786, 1],
    [5792, 5866, 1],
    [5873, 5880, 1],
    [5888, 5905, 1],
    [5919, 5937, 1],
    [5952, 5969, 1],
    [5984, 5996, 1],
    [5998, 6000, 1],
    [6016, 6067, 1],
    [6103, 6108, 5],
    [6176, 6264, 1],
    [6272, 6276, 1],
    [6279, 6312, 1],
    [6314, 6320, 6],
    [6321, 6389, 1],
    [6400, 6430, 1],
    [6480, 6509, 1],
    [6512, 6516, 1],
    [6528, 6571, 1],
    [6576, 6601, 1],
    [6656, 6678, 1],
    [6688, 6740, 1],
    [6823, 6917, 94],
    [6918, 6963, 1],
    [6981, 6988, 1],
    [7043, 7072, 1],
    [7086, 7087, 1],
    [7098, 7141, 1],
    [7168, 7203, 1],
    [7245, 7247, 1],
    [7258, 7293, 1],
    [7296, 7304, 1],
    [7312, 7354, 1],
    [7357, 7359, 1],
    [7401, 7404, 1],
    [7406, 7411, 1],
    [7413, 7414, 1],
    [7418, 7424, 6],
    [7425, 7615, 1],
    [7680, 7957, 1],
    [7960, 7965, 1],
    [7968, 8005, 1],
    [8008, 8013, 1],
    [8016, 8023, 1],
    [8025, 8031, 2],
    [8032, 8061, 1],
    [8064, 8116, 1],
    [8118, 8124, 1],
    [8126, 8130, 4],
    [8131, 8132, 1],
    [8134, 8140, 1],
    [8144, 8147, 1],
    [8150, 8155, 1],
    [8160, 8172, 1],
    [8178, 8180, 1],
    [8182, 8188, 1],
    [8305, 8319, 14],
    [8336, 8348, 1],
    [8450, 8455, 5],
    [8458, 8467, 1],
    [8469, 8473, 4],
    [8474, 8477, 1],
    [8484, 8490, 2],
    [8491, 8493, 1],
    [8495, 8505, 1],
    [8508, 8511, 1],
    [8517, 8521, 1],
    [8526, 8579, 53],
    [8580, 11264, 2684],
    [11265, 11492, 1],
    [11499, 11502, 1],
    [11506, 11507, 1],
    [11520, 11557, 1],
    [11559, 11565, 6],
    [11568, 11623, 1],
    [11631, 11648, 17],
    [11649, 11670, 1],
    [11680, 11686, 1],
    [11688, 11694, 1],
    [11696, 11702, 1],
    [11704, 11710, 1],
    [11712, 11718, 1],
    [11720, 11726, 1],
    [11728, 11734, 1],
    [11736, 11742, 1],
    [11823, 12293, 470],
    [12294, 12337, 43],
    [12338, 12341, 1],
    [12347, 12348, 1],
    [12353, 12438, 1],
    [12445, 12447, 1],
    [12449, 12538, 1],
    [12540, 12543, 1],
    [12549, 12591, 1],
    [12593, 12686, 1],
    [12704, 12735, 1],
    [12784, 12799, 1],
    [13312, 19903, 1],
    [19968, 42124, 1],
    [42192, 42237, 1],
    [42240, 42508, 1],
    [42512, 42527, 1],
    [42538, 42539, 1],
    [42560, 42606, 1],
    [42623, 42653, 1],
    [42656, 42725, 1],
    [42775, 42783, 1],
    [42786, 42888, 1],
    [42891, 42954, 1],
    [42960, 42961, 1],
    [42963, 42965, 2],
    [42966, 42969, 1],
    [42994, 43009, 1],
    [43011, 43013, 1],
    [43015, 43018, 1],
    [43020, 43042, 1],
    [43072, 43123, 1],
    [43138, 43187, 1],
    [43250, 43255, 1],
    [43259, 43261, 2],
    [43262, 43274, 12],
    [43275, 43301, 1],
    [43312, 43334, 1],
    [43360, 43388, 1],
    [43396, 43442, 1],
    [43471, 43488, 17],
    [43489, 43492, 1],
    [43494, 43503, 1],
    [43514, 43518, 1],
    [43520, 43560, 1],
    [43584, 43586, 1],
    [43588, 43595, 1],
    [43616, 43638, 1],
    [43642, 43646, 4],
    [43647, 43695, 1],
    [43697, 43701, 4],
    [43702, 43705, 3],
    [43706, 43709, 1],
    [43712, 43714, 2],
    [43739, 43741, 1],
    [43744, 43754, 1],
    [43762, 43764, 1],
    [43777, 43782, 1],
    [43785, 43790, 1],
    [43793, 43798, 1],
    [43808, 43814, 1],
    [43816, 43822, 1],
    [43824, 43866, 1],
    [43868, 43881, 1],
    [43888, 44002, 1],
    [44032, 55203, 1],
    [55216, 55238, 1],
    [55243, 55291, 1],
    [63744, 64109, 1],
    [64112, 64217, 1],
    [64256, 64262, 1],
    [64275, 64279, 1],
    [64285, 64287, 2],
    [64288, 64296, 1],
    [64298, 64310, 1],
    [64312, 64316, 1],
    [64318, 64320, 2],
    [64321, 64323, 2],
    [64324, 64326, 2],
    [64327, 64433, 1],
    [64467, 64829, 1],
    [64848, 64911, 1],
    [64914, 64967, 1],
    [65008, 65019, 1],
    [65136, 65140, 1],
    [65142, 65276, 1],
    [65313, 65338, 1],
    [65345, 65370, 1],
    [65382, 65470, 1],
    [65474, 65479, 1],
    [65482, 65487, 1],
    [65490, 65495, 1],
    [65498, 65500, 1],
    [65536, 65547, 1],
    [65549, 65574, 1],
    [65576, 65594, 1],
    [65596, 65597, 1],
    [65599, 65613, 1],
    [65616, 65629, 1],
    [65664, 65786, 1],
    [66176, 66204, 1],
    [66208, 66256, 1],
    [66304, 66335, 1],
    [66349, 66368, 1],
    [66370, 66377, 1],
    [66384, 66421, 1],
    [66432, 66461, 1],
    [66464, 66499, 1],
    [66504, 66511, 1],
    [66560, 66717, 1],
    [66736, 66771, 1],
    [66776, 66811, 1],
    [66816, 66855, 1],
    [66864, 66915, 1],
    [66928, 66938, 1],
    [66940, 66954, 1],
    [66956, 66962, 1],
    [66964, 66965, 1],
    [66967, 66977, 1],
    [66979, 66993, 1],
    [66995, 67001, 1],
    [67003, 67004, 1],
    [67072, 67382, 1],
    [67392, 67413, 1],
    [67424, 67431, 1],
    [67456, 67461, 1],
    [67463, 67504, 1],
    [67506, 67514, 1],
    [67584, 67589, 1],
    [67592, 67594, 2],
    [67595, 67637, 1],
    [67639, 67640, 1],
    [67644, 67647, 3],
    [67648, 67669, 1],
    [67680, 67702, 1],
    [67712, 67742, 1],
    [67808, 67826, 1],
    [67828, 67829, 1],
    [67840, 67861, 1],
    [67872, 67897, 1],
    [67968, 68023, 1],
    [68030, 68031, 1],
    [68096, 68112, 16],
    [68113, 68115, 1],
    [68117, 68119, 1],
    [68121, 68149, 1],
    [68192, 68220, 1],
    [68224, 68252, 1],
    [68288, 68295, 1],
    [68297, 68324, 1],
    [68352, 68405, 1],
    [68416, 68437, 1],
    [68448, 68466, 1],
    [68480, 68497, 1],
    [68608, 68680, 1],
    [68736, 68786, 1],
    [68800, 68850, 1],
    [68864, 68899, 1],
    [69248, 69289, 1],
    [69296, 69297, 1],
    [69376, 69404, 1],
    [69415, 69424, 9],
    [69425, 69445, 1],
    [69488, 69505, 1],
    [69552, 69572, 1],
    [69600, 69622, 1],
    [69635, 69687, 1],
    [69745, 69746, 1],
    [69749, 69763, 14],
    [69764, 69807, 1],
    [69840, 69864, 1],
    [69891, 69926, 1],
    [69956, 69959, 3],
    [69968, 70002, 1],
    [70006, 70019, 13],
    [70020, 70066, 1],
    [70081, 70084, 1],
    [70106, 70108, 2],
    [70144, 70161, 1],
    [70163, 70187, 1],
    [70207, 70208, 1],
    [70272, 70278, 1],
    [70280, 70282, 2],
    [70283, 70285, 1],
    [70287, 70301, 1],
    [70303, 70312, 1],
    [70320, 70366, 1],
    [70405, 70412, 1],
    [70415, 70416, 1],
    [70419, 70440, 1],
    [70442, 70448, 1],
    [70450, 70451, 1],
    [70453, 70457, 1],
    [70461, 70480, 19],
    [70493, 70497, 1],
    [70656, 70708, 1],
    [70727, 70730, 1],
    [70751, 70753, 1],
    [70784, 70831, 1],
    [70852, 70853, 1],
    [70855, 71040, 185],
    [71041, 71086, 1],
    [71128, 71131, 1],
    [71168, 71215, 1],
    [71236, 71296, 60],
    [71297, 71338, 1],
    [71352, 71424, 72],
    [71425, 71450, 1],
    [71488, 71494, 1],
    [71680, 71723, 1],
    [71840, 71903, 1],
    [71935, 71942, 1],
    [71945, 71948, 3],
    [71949, 71955, 1],
    [71957, 71958, 1],
    [71960, 71983, 1],
    [71999, 72001, 2],
    [72096, 72103, 1],
    [72106, 72144, 1],
    [72161, 72163, 2],
    [72192, 72203, 11],
    [72204, 72242, 1],
    [72250, 72272, 22],
    [72284, 72329, 1],
    [72349, 72368, 19],
    [72369, 72440, 1],
    [72704, 72712, 1],
    [72714, 72750, 1],
    [72768, 72818, 50],
    [72819, 72847, 1],
    [72960, 72966, 1],
    [72968, 72969, 1],
    [72971, 73008, 1],
    [73030, 73056, 26],
    [73057, 73061, 1],
    [73063, 73064, 1],
    [73066, 73097, 1],
    [73112, 73440, 328],
    [73441, 73458, 1],
    [73474, 73476, 2],
    [73477, 73488, 1],
    [73490, 73523, 1],
    [73648, 73728, 80],
    [73729, 74649, 1],
    [74880, 75075, 1],
    [77712, 77808, 1],
    [77824, 78895, 1],
    [78913, 78918, 1],
    [82944, 83526, 1],
    [92160, 92728, 1],
    [92736, 92766, 1],
    [92784, 92862, 1],
    [92880, 92909, 1],
    [92928, 92975, 1],
    [92992, 92995, 1],
    [93027, 93047, 1],
    [93053, 93071, 1],
    [93760, 93823, 1],
    [93952, 94026, 1],
    [94032, 94099, 67],
    [94100, 94111, 1],
    [94176, 94177, 1],
    [94179, 94208, 29],
    [94209, 100343, 1],
    [100352, 101589, 1],
    [101632, 101640, 1],
    [110576, 110579, 1],
    [110581, 110587, 1],
    [110589, 110590, 1],
    [110592, 110882, 1],
    [110898, 110928, 30],
    [110929, 110930, 1],
    [110933, 110948, 15],
    [110949, 110951, 1],
    [110960, 111355, 1],
    [113664, 113770, 1],
    [113776, 113788, 1],
    [113792, 113800, 1],
    [113808, 113817, 1],
    [119808, 119892, 1],
    [119894, 119964, 1],
    [119966, 119967, 1],
    [119970, 119973, 3],
    [119974, 119977, 3],
    [119978, 119980, 1],
    [119982, 119993, 1],
    [119995, 119997, 2],
    [119998, 120003, 1],
    [120005, 120069, 1],
    [120071, 120074, 1],
    [120077, 120084, 1],
    [120086, 120092, 1],
    [120094, 120121, 1],
    [120123, 120126, 1],
    [120128, 120132, 1],
    [120134, 120138, 4],
    [120139, 120144, 1],
    [120146, 120485, 1],
    [120488, 120512, 1],
    [120514, 120538, 1],
    [120540, 120570, 1],
    [120572, 120596, 1],
    [120598, 120628, 1],
    [120630, 120654, 1],
    [120656, 120686, 1],
    [120688, 120712, 1],
    [120714, 120744, 1],
    [120746, 120770, 1],
    [120772, 120779, 1],
    [122624, 122654, 1],
    [122661, 122666, 1],
    [122928, 122989, 1],
    [123136, 123180, 1],
    [123191, 123197, 1],
    [123214, 123536, 322],
    [123537, 123565, 1],
    [123584, 123627, 1],
    [124112, 124139, 1],
    [124896, 124902, 1],
    [124904, 124907, 1],
    [124909, 124910, 1],
    [124912, 124926, 1],
    [124928, 125124, 1],
    [125184, 125251, 1],
    [125259, 126464, 1205],
    [126465, 126467, 1],
    [126469, 126495, 1],
    [126497, 126498, 1],
    [126500, 126503, 3],
    [126505, 126514, 1],
    [126516, 126519, 1],
    [126521, 126523, 2],
    [126530, 126535, 5],
    [126537, 126541, 2],
    [126542, 126543, 1],
    [126545, 126546, 1],
    [126548, 126551, 3],
    [126553, 126561, 2],
    [126562, 126564, 2],
    [126567, 126570, 1],
    [126572, 126578, 1],
    [126580, 126583, 1],
    [126585, 126588, 1],
    [126590, 126592, 2],
    [126593, 126601, 1],
    [126603, 126619, 1],
    [126625, 126627, 1],
    [126629, 126633, 1],
    [126635, 126651, 1],
    [131072, 173791, 1],
    [173824, 177977, 1],
    [177984, 178205, 1],
    [178208, 183969, 1],
    [183984, 191456, 1],
    [191472, 192093, 1],
    [194560, 195101, 1],
    [196608, 201546, 1],
    [201552, 205743, 1]
  ]
  static foldL = [[837, 837, 1]]
  static Ll = [
    [97, 122, 1],
    [181, 223, 42],
    [224, 246, 1],
    [248, 255, 1],
    [257, 311, 2],
    [312, 328, 2],
    [329, 375, 2],
    [378, 382, 2],
    [383, 384, 1],
    [387, 389, 2],
    [392, 396, 4],
    [397, 402, 5],
    [405, 409, 4],
    [410, 411, 1],
    [414, 417, 3],
    [419, 421, 2],
    [424, 426, 2],
    [427, 429, 2],
    [432, 436, 4],
    [438, 441, 3],
    [442, 445, 3],
    [446, 447, 1],
    [454, 460, 3],
    [462, 476, 2],
    [477, 495, 2],
    [496, 499, 3],
    [501, 505, 4],
    [507, 563, 2],
    [564, 569, 1],
    [572, 575, 3],
    [576, 578, 2],
    [583, 591, 2],
    [592, 659, 1],
    [661, 687, 1],
    [881, 883, 2],
    [887, 891, 4],
    [892, 893, 1],
    [912, 940, 28],
    [941, 974, 1],
    [976, 977, 1],
    [981, 983, 1],
    [985, 1007, 2],
    [1008, 1011, 1],
    [1013, 1019, 3],
    [1020, 1072, 52],
    [1073, 1119, 1],
    [1121, 1153, 2],
    [1163, 1215, 2],
    [1218, 1230, 2],
    [1231, 1327, 2],
    [1376, 1416, 1],
    [4304, 4346, 1],
    [4349, 4351, 1],
    [5112, 5117, 1],
    [7296, 7304, 1],
    [7424, 7467, 1],
    [7531, 7543, 1],
    [7545, 7578, 1],
    [7681, 7829, 2],
    [7830, 7837, 1],
    [7839, 7935, 2],
    [7936, 7943, 1],
    [7952, 7957, 1],
    [7968, 7975, 1],
    [7984, 7991, 1],
    [8000, 8005, 1],
    [8016, 8023, 1],
    [8032, 8039, 1],
    [8048, 8061, 1],
    [8064, 8071, 1],
    [8080, 8087, 1],
    [8096, 8103, 1],
    [8112, 8116, 1],
    [8118, 8119, 1],
    [8126, 8130, 4],
    [8131, 8132, 1],
    [8134, 8135, 1],
    [8144, 8147, 1],
    [8150, 8151, 1],
    [8160, 8167, 1],
    [8178, 8180, 1],
    [8182, 8183, 1],
    [8458, 8462, 4],
    [8463, 8467, 4],
    [8495, 8505, 5],
    [8508, 8509, 1],
    [8518, 8521, 1],
    [8526, 8580, 54],
    [11312, 11359, 1],
    [11361, 11365, 4],
    [11366, 11372, 2],
    [11377, 11379, 2],
    [11380, 11382, 2],
    [11383, 11387, 1],
    [11393, 11491, 2],
    [11492, 11500, 8],
    [11502, 11507, 5],
    [11520, 11557, 1],
    [11559, 11565, 6],
    [42561, 42605, 2],
    [42625, 42651, 2],
    [42787, 42799, 2],
    [42800, 42801, 1],
    [42803, 42865, 2],
    [42866, 42872, 1],
    [42874, 42876, 2],
    [42879, 42887, 2],
    [42892, 42894, 2],
    [42897, 42899, 2],
    [42900, 42901, 1],
    [42903, 42921, 2],
    [42927, 42933, 6],
    [42935, 42947, 2],
    [42952, 42954, 2],
    [42961, 42969, 2],
    [42998, 43002, 4],
    [43824, 43866, 1],
    [43872, 43880, 1],
    [43888, 43967, 1],
    [64256, 64262, 1],
    [64275, 64279, 1],
    [65345, 65370, 1],
    [66600, 66639, 1],
    [66776, 66811, 1],
    [66967, 66977, 1],
    [66979, 66993, 1],
    [66995, 67001, 1],
    [67003, 67004, 1],
    [68800, 68850, 1],
    [71872, 71903, 1],
    [93792, 93823, 1],
    [119834, 119859, 1],
    [119886, 119892, 1],
    [119894, 119911, 1],
    [119938, 119963, 1],
    [119990, 119993, 1],
    [119995, 119997, 2],
    [119998, 120003, 1],
    [120005, 120015, 1],
    [120042, 120067, 1],
    [120094, 120119, 1],
    [120146, 120171, 1],
    [120198, 120223, 1],
    [120250, 120275, 1],
    [120302, 120327, 1],
    [120354, 120379, 1],
    [120406, 120431, 1],
    [120458, 120485, 1],
    [120514, 120538, 1],
    [120540, 120545, 1],
    [120572, 120596, 1],
    [120598, 120603, 1],
    [120630, 120654, 1],
    [120656, 120661, 1],
    [120688, 120712, 1],
    [120714, 120719, 1],
    [120746, 120770, 1],
    [120772, 120777, 1],
    [120779, 122624, 1845],
    [122625, 122633, 1],
    [122635, 122654, 1],
    [122661, 122666, 1],
    [125218, 125251, 1]
  ]
  static foldLl = [
    [65, 90, 1],
    [192, 214, 1],
    [216, 222, 1],
    [256, 302, 2],
    [306, 310, 2],
    [313, 327, 2],
    [330, 376, 2],
    [377, 381, 2],
    [385, 386, 1],
    [388, 390, 2],
    [391, 393, 2],
    [394, 395, 1],
    [398, 401, 1],
    [403, 404, 1],
    [406, 408, 1],
    [412, 413, 1],
    [415, 416, 1],
    [418, 422, 2],
    [423, 425, 2],
    [428, 430, 2],
    [431, 433, 2],
    [434, 435, 1],
    [437, 439, 2],
    [440, 444, 4],
    [452, 453, 1],
    [455, 456, 1],
    [458, 459, 1],
    [461, 475, 2],
    [478, 494, 2],
    [497, 498, 1],
    [500, 502, 2],
    [503, 504, 1],
    [506, 562, 2],
    [570, 571, 1],
    [573, 574, 1],
    [577, 579, 2],
    [580, 582, 1],
    [584, 590, 2],
    [837, 880, 43],
    [882, 886, 4],
    [895, 902, 7],
    [904, 906, 1],
    [908, 910, 2],
    [911, 913, 2],
    [914, 929, 1],
    [931, 939, 1],
    [975, 984, 9],
    [986, 1006, 2],
    [1012, 1015, 3],
    [1017, 1018, 1],
    [1021, 1071, 1],
    [1120, 1152, 2],
    [1162, 1216, 2],
    [1217, 1229, 2],
    [1232, 1326, 2],
    [1329, 1366, 1],
    [4256, 4293, 1],
    [4295, 4301, 6],
    [5024, 5109, 1],
    [7312, 7354, 1],
    [7357, 7359, 1],
    [7680, 7828, 2],
    [7838, 7934, 2],
    [7944, 7951, 1],
    [7960, 7965, 1],
    [7976, 7983, 1],
    [7992, 7999, 1],
    [8008, 8013, 1],
    [8025, 8031, 2],
    [8040, 8047, 1],
    [8072, 8079, 1],
    [8088, 8095, 1],
    [8104, 8111, 1],
    [8120, 8124, 1],
    [8136, 8140, 1],
    [8152, 8155, 1],
    [8168, 8172, 1],
    [8184, 8188, 1],
    [8486, 8490, 4],
    [8491, 8498, 7],
    [8579, 11264, 2685],
    [11265, 11311, 1],
    [11360, 11362, 2],
    [11363, 11364, 1],
    [11367, 11373, 2],
    [11374, 11376, 1],
    [11378, 11381, 3],
    [11390, 11392, 1],
    [11394, 11490, 2],
    [11499, 11501, 2],
    [11506, 42560, 31054],
    [42562, 42604, 2],
    [42624, 42650, 2],
    [42786, 42798, 2],
    [42802, 42862, 2],
    [42873, 42877, 2],
    [42878, 42886, 2],
    [42891, 42893, 2],
    [42896, 42898, 2],
    [42902, 42922, 2],
    [42923, 42926, 1],
    [42928, 42932, 1],
    [42934, 42948, 2],
    [42949, 42951, 1],
    [42953, 42960, 7],
    [42966, 42968, 2],
    [42997, 65313, 22316],
    [65314, 65338, 1],
    [66560, 66599, 1],
    [66736, 66771, 1],
    [66928, 66938, 1],
    [66940, 66954, 1],
    [66956, 66962, 1],
    [66964, 66965, 1],
    [68736, 68786, 1],
    [71840, 71871, 1],
    [93760, 93791, 1],
    [125184, 125217, 1]
  ]
  static Lm = [
    [688, 705, 1],
    [710, 721, 1],
    [736, 740, 1],
    [748, 750, 2],
    [884, 890, 6],
    [1369, 1600, 231],
    [1765, 1766, 1],
    [2036, 2037, 1],
    [2042, 2074, 32],
    [2084, 2088, 4],
    [2249, 2417, 168],
    [3654, 3782, 128],
    [4348, 6103, 1755],
    [6211, 6823, 612],
    [7288, 7293, 1],
    [7468, 7530, 1],
    [7544, 7579, 35],
    [7580, 7615, 1],
    [8305, 8319, 14],
    [8336, 8348, 1],
    [11388, 11389, 1],
    [11631, 11823, 192],
    [12293, 12337, 44],
    [12338, 12341, 1],
    [12347, 12445, 98],
    [12446, 12540, 94],
    [12541, 12542, 1],
    [40981, 42232, 1251],
    [42233, 42237, 1],
    [42508, 42623, 115],
    [42652, 42653, 1],
    [42775, 42783, 1],
    [42864, 42888, 24],
    [42994, 42996, 1],
    [43000, 43001, 1],
    [43471, 43494, 23],
    [43632, 43741, 109],
    [43763, 43764, 1],
    [43868, 43871, 1],
    [43881, 65392, 21511],
    [65438, 65439, 1],
    [67456, 67461, 1],
    [67463, 67504, 1],
    [67506, 67514, 1],
    [92992, 92995, 1],
    [94099, 94111, 1],
    [94176, 94177, 1],
    [94179, 110576, 16397],
    [110577, 110579, 1],
    [110581, 110587, 1],
    [110589, 110590, 1],
    [122928, 122989, 1],
    [123191, 123197, 1],
    [124139, 125259, 1120]
  ]
  static Lo = [
    [170, 186, 16],
    [443, 448, 5],
    [449, 451, 1],
    [660, 1488, 828],
    [1489, 1514, 1],
    [1519, 1522, 1],
    [1568, 1599, 1],
    [1601, 1610, 1],
    [1646, 1647, 1],
    [1649, 1747, 1],
    [1749, 1774, 25],
    [1775, 1786, 11],
    [1787, 1788, 1],
    [1791, 1808, 17],
    [1810, 1839, 1],
    [1869, 1957, 1],
    [1969, 1994, 25],
    [1995, 2026, 1],
    [2048, 2069, 1],
    [2112, 2136, 1],
    [2144, 2154, 1],
    [2160, 2183, 1],
    [2185, 2190, 1],
    [2208, 2248, 1],
    [2308, 2361, 1],
    [2365, 2384, 19],
    [2392, 2401, 1],
    [2418, 2432, 1],
    [2437, 2444, 1],
    [2447, 2448, 1],
    [2451, 2472, 1],
    [2474, 2480, 1],
    [2482, 2486, 4],
    [2487, 2489, 1],
    [2493, 2510, 17],
    [2524, 2525, 1],
    [2527, 2529, 1],
    [2544, 2545, 1],
    [2556, 2565, 9],
    [2566, 2570, 1],
    [2575, 2576, 1],
    [2579, 2600, 1],
    [2602, 2608, 1],
    [2610, 2611, 1],
    [2613, 2614, 1],
    [2616, 2617, 1],
    [2649, 2652, 1],
    [2654, 2674, 20],
    [2675, 2676, 1],
    [2693, 2701, 1],
    [2703, 2705, 1],
    [2707, 2728, 1],
    [2730, 2736, 1],
    [2738, 2739, 1],
    [2741, 2745, 1],
    [2749, 2768, 19],
    [2784, 2785, 1],
    [2809, 2821, 12],
    [2822, 2828, 1],
    [2831, 2832, 1],
    [2835, 2856, 1],
    [2858, 2864, 1],
    [2866, 2867, 1],
    [2869, 2873, 1],
    [2877, 2908, 31],
    [2909, 2911, 2],
    [2912, 2913, 1],
    [2929, 2947, 18],
    [2949, 2954, 1],
    [2958, 2960, 1],
    [2962, 2965, 1],
    [2969, 2970, 1],
    [2972, 2974, 2],
    [2975, 2979, 4],
    [2980, 2984, 4],
    [2985, 2986, 1],
    [2990, 3001, 1],
    [3024, 3077, 53],
    [3078, 3084, 1],
    [3086, 3088, 1],
    [3090, 3112, 1],
    [3114, 3129, 1],
    [3133, 3160, 27],
    [3161, 3162, 1],
    [3165, 3168, 3],
    [3169, 3200, 31],
    [3205, 3212, 1],
    [3214, 3216, 1],
    [3218, 3240, 1],
    [3242, 3251, 1],
    [3253, 3257, 1],
    [3261, 3293, 32],
    [3294, 3296, 2],
    [3297, 3313, 16],
    [3314, 3332, 18],
    [3333, 3340, 1],
    [3342, 3344, 1],
    [3346, 3386, 1],
    [3389, 3406, 17],
    [3412, 3414, 1],
    [3423, 3425, 1],
    [3450, 3455, 1],
    [3461, 3478, 1],
    [3482, 3505, 1],
    [3507, 3515, 1],
    [3517, 3520, 3],
    [3521, 3526, 1],
    [3585, 3632, 1],
    [3634, 3635, 1],
    [3648, 3653, 1],
    [3713, 3714, 1],
    [3716, 3718, 2],
    [3719, 3722, 1],
    [3724, 3747, 1],
    [3749, 3751, 2],
    [3752, 3760, 1],
    [3762, 3763, 1],
    [3773, 3776, 3],
    [3777, 3780, 1],
    [3804, 3807, 1],
    [3840, 3904, 64],
    [3905, 3911, 1],
    [3913, 3948, 1],
    [3976, 3980, 1],
    [4096, 4138, 1],
    [4159, 4176, 17],
    [4177, 4181, 1],
    [4186, 4189, 1],
    [4193, 4197, 4],
    [4198, 4206, 8],
    [4207, 4208, 1],
    [4213, 4225, 1],
    [4238, 4352, 114],
    [4353, 4680, 1],
    [4682, 4685, 1],
    [4688, 4694, 1],
    [4696, 4698, 2],
    [4699, 4701, 1],
    [4704, 4744, 1],
    [4746, 4749, 1],
    [4752, 4784, 1],
    [4786, 4789, 1],
    [4792, 4798, 1],
    [4800, 4802, 2],
    [4803, 4805, 1],
    [4808, 4822, 1],
    [4824, 4880, 1],
    [4882, 4885, 1],
    [4888, 4954, 1],
    [4992, 5007, 1],
    [5121, 5740, 1],
    [5743, 5759, 1],
    [5761, 5786, 1],
    [5792, 5866, 1],
    [5873, 5880, 1],
    [5888, 5905, 1],
    [5919, 5937, 1],
    [5952, 5969, 1],
    [5984, 5996, 1],
    [5998, 6000, 1],
    [6016, 6067, 1],
    [6108, 6176, 68],
    [6177, 6210, 1],
    [6212, 6264, 1],
    [6272, 6276, 1],
    [6279, 6312, 1],
    [6314, 6320, 6],
    [6321, 6389, 1],
    [6400, 6430, 1],
    [6480, 6509, 1],
    [6512, 6516, 1],
    [6528, 6571, 1],
    [6576, 6601, 1],
    [6656, 6678, 1],
    [6688, 6740, 1],
    [6917, 6963, 1],
    [6981, 6988, 1],
    [7043, 7072, 1],
    [7086, 7087, 1],
    [7098, 7141, 1],
    [7168, 7203, 1],
    [7245, 7247, 1],
    [7258, 7287, 1],
    [7401, 7404, 1],
    [7406, 7411, 1],
    [7413, 7414, 1],
    [7418, 8501, 1083],
    [8502, 8504, 1],
    [11568, 11623, 1],
    [11648, 11670, 1],
    [11680, 11686, 1],
    [11688, 11694, 1],
    [11696, 11702, 1],
    [11704, 11710, 1],
    [11712, 11718, 1],
    [11720, 11726, 1],
    [11728, 11734, 1],
    [11736, 11742, 1],
    [12294, 12348, 54],
    [12353, 12438, 1],
    [12447, 12449, 2],
    [12450, 12538, 1],
    [12543, 12549, 6],
    [12550, 12591, 1],
    [12593, 12686, 1],
    [12704, 12735, 1],
    [12784, 12799, 1],
    [13312, 19903, 1],
    [19968, 40980, 1],
    [40982, 42124, 1],
    [42192, 42231, 1],
    [42240, 42507, 1],
    [42512, 42527, 1],
    [42538, 42539, 1],
    [42606, 42656, 50],
    [42657, 42725, 1],
    [42895, 42999, 104],
    [43003, 43009, 1],
    [43011, 43013, 1],
    [43015, 43018, 1],
    [43020, 43042, 1],
    [43072, 43123, 1],
    [43138, 43187, 1],
    [43250, 43255, 1],
    [43259, 43261, 2],
    [43262, 43274, 12],
    [43275, 43301, 1],
    [43312, 43334, 1],
    [43360, 43388, 1],
    [43396, 43442, 1],
    [43488, 43492, 1],
    [43495, 43503, 1],
    [43514, 43518, 1],
    [43520, 43560, 1],
    [43584, 43586, 1],
    [43588, 43595, 1],
    [43616, 43631, 1],
    [43633, 43638, 1],
    [43642, 43646, 4],
    [43647, 43695, 1],
    [43697, 43701, 4],
    [43702, 43705, 3],
    [43706, 43709, 1],
    [43712, 43714, 2],
    [43739, 43740, 1],
    [43744, 43754, 1],
    [43762, 43777, 15],
    [43778, 43782, 1],
    [43785, 43790, 1],
    [43793, 43798, 1],
    [43808, 43814, 1],
    [43816, 43822, 1],
    [43968, 44002, 1],
    [44032, 55203, 1],
    [55216, 55238, 1],
    [55243, 55291, 1],
    [63744, 64109, 1],
    [64112, 64217, 1],
    [64285, 64287, 2],
    [64288, 64296, 1],
    [64298, 64310, 1],
    [64312, 64316, 1],
    [64318, 64320, 2],
    [64321, 64323, 2],
    [64324, 64326, 2],
    [64327, 64433, 1],
    [64467, 64829, 1],
    [64848, 64911, 1],
    [64914, 64967, 1],
    [65008, 65019, 1],
    [65136, 65140, 1],
    [65142, 65276, 1],
    [65382, 65391, 1],
    [65393, 65437, 1],
    [65440, 65470, 1],
    [65474, 65479, 1],
    [65482, 65487, 1],
    [65490, 65495, 1],
    [65498, 65500, 1],
    [65536, 65547, 1],
    [65549, 65574, 1],
    [65576, 65594, 1],
    [65596, 65597, 1],
    [65599, 65613, 1],
    [65616, 65629, 1],
    [65664, 65786, 1],
    [66176, 66204, 1],
    [66208, 66256, 1],
    [66304, 66335, 1],
    [66349, 66368, 1],
    [66370, 66377, 1],
    [66384, 66421, 1],
    [66432, 66461, 1],
    [66464, 66499, 1],
    [66504, 66511, 1],
    [66640, 66717, 1],
    [66816, 66855, 1],
    [66864, 66915, 1],
    [67072, 67382, 1],
    [67392, 67413, 1],
    [67424, 67431, 1],
    [67584, 67589, 1],
    [67592, 67594, 2],
    [67595, 67637, 1],
    [67639, 67640, 1],
    [67644, 67647, 3],
    [67648, 67669, 1],
    [67680, 67702, 1],
    [67712, 67742, 1],
    [67808, 67826, 1],
    [67828, 67829, 1],
    [67840, 67861, 1],
    [67872, 67897, 1],
    [67968, 68023, 1],
    [68030, 68031, 1],
    [68096, 68112, 16],
    [68113, 68115, 1],
    [68117, 68119, 1],
    [68121, 68149, 1],
    [68192, 68220, 1],
    [68224, 68252, 1],
    [68288, 68295, 1],
    [68297, 68324, 1],
    [68352, 68405, 1],
    [68416, 68437, 1],
    [68448, 68466, 1],
    [68480, 68497, 1],
    [68608, 68680, 1],
    [68864, 68899, 1],
    [69248, 69289, 1],
    [69296, 69297, 1],
    [69376, 69404, 1],
    [69415, 69424, 9],
    [69425, 69445, 1],
    [69488, 69505, 1],
    [69552, 69572, 1],
    [69600, 69622, 1],
    [69635, 69687, 1],
    [69745, 69746, 1],
    [69749, 69763, 14],
    [69764, 69807, 1],
    [69840, 69864, 1],
    [69891, 69926, 1],
    [69956, 69959, 3],
    [69968, 70002, 1],
    [70006, 70019, 13],
    [70020, 70066, 1],
    [70081, 70084, 1],
    [70106, 70108, 2],
    [70144, 70161, 1],
    [70163, 70187, 1],
    [70207, 70208, 1],
    [70272, 70278, 1],
    [70280, 70282, 2],
    [70283, 70285, 1],
    [70287, 70301, 1],
    [70303, 70312, 1],
    [70320, 70366, 1],
    [70405, 70412, 1],
    [70415, 70416, 1],
    [70419, 70440, 1],
    [70442, 70448, 1],
    [70450, 70451, 1],
    [70453, 70457, 1],
    [70461, 70480, 19],
    [70493, 70497, 1],
    [70656, 70708, 1],
    [70727, 70730, 1],
    [70751, 70753, 1],
    [70784, 70831, 1],
    [70852, 70853, 1],
    [70855, 71040, 185],
    [71041, 71086, 1],
    [71128, 71131, 1],
    [71168, 71215, 1],
    [71236, 71296, 60],
    [71297, 71338, 1],
    [71352, 71424, 72],
    [71425, 71450, 1],
    [71488, 71494, 1],
    [71680, 71723, 1],
    [71935, 71942, 1],
    [71945, 71948, 3],
    [71949, 71955, 1],
    [71957, 71958, 1],
    [71960, 71983, 1],
    [71999, 72001, 2],
    [72096, 72103, 1],
    [72106, 72144, 1],
    [72161, 72163, 2],
    [72192, 72203, 11],
    [72204, 72242, 1],
    [72250, 72272, 22],
    [72284, 72329, 1],
    [72349, 72368, 19],
    [72369, 72440, 1],
    [72704, 72712, 1],
    [72714, 72750, 1],
    [72768, 72818, 50],
    [72819, 72847, 1],
    [72960, 72966, 1],
    [72968, 72969, 1],
    [72971, 73008, 1],
    [73030, 73056, 26],
    [73057, 73061, 1],
    [73063, 73064, 1],
    [73066, 73097, 1],
    [73112, 73440, 328],
    [73441, 73458, 1],
    [73474, 73476, 2],
    [73477, 73488, 1],
    [73490, 73523, 1],
    [73648, 73728, 80],
    [73729, 74649, 1],
    [74880, 75075, 1],
    [77712, 77808, 1],
    [77824, 78895, 1],
    [78913, 78918, 1],
    [82944, 83526, 1],
    [92160, 92728, 1],
    [92736, 92766, 1],
    [92784, 92862, 1],
    [92880, 92909, 1],
    [92928, 92975, 1],
    [93027, 93047, 1],
    [93053, 93071, 1],
    [93952, 94026, 1],
    [94032, 94208, 176],
    [94209, 100343, 1],
    [100352, 101589, 1],
    [101632, 101640, 1],
    [110592, 110882, 1],
    [110898, 110928, 30],
    [110929, 110930, 1],
    [110933, 110948, 15],
    [110949, 110951, 1],
    [110960, 111355, 1],
    [113664, 113770, 1],
    [113776, 113788, 1],
    [113792, 113800, 1],
    [113808, 113817, 1],
    [122634, 123136, 502],
    [123137, 123180, 1],
    [123214, 123536, 322],
    [123537, 123565, 1],
    [123584, 123627, 1],
    [124112, 124138, 1],
    [124896, 124902, 1],
    [124904, 124907, 1],
    [124909, 124910, 1],
    [124912, 124926, 1],
    [124928, 125124, 1],
    [126464, 126467, 1],
    [126469, 126495, 1],
    [126497, 126498, 1],
    [126500, 126503, 3],
    [126505, 126514, 1],
    [126516, 126519, 1],
    [126521, 126523, 2],
    [126530, 126535, 5],
    [126537, 126541, 2],
    [126542, 126543, 1],
    [126545, 126546, 1],
    [126548, 126551, 3],
    [126553, 126561, 2],
    [126562, 126564, 2],
    [126567, 126570, 1],
    [126572, 126578, 1],
    [126580, 126583, 1],
    [126585, 126588, 1],
    [126590, 126592, 2],
    [126593, 126601, 1],
    [126603, 126619, 1],
    [126625, 126627, 1],
    [126629, 126633, 1],
    [126635, 126651, 1],
    [131072, 173791, 1],
    [173824, 177977, 1],
    [177984, 178205, 1],
    [178208, 183969, 1],
    [183984, 191456, 1],
    [191472, 192093, 1],
    [194560, 195101, 1],
    [196608, 201546, 1],
    [201552, 205743, 1]
  ]
  static Lt = [
    [453, 459, 3],
    [498, 8072, 7574],
    [8073, 8079, 1],
    [8088, 8095, 1],
    [8104, 8111, 1],
    [8124, 8140, 16],
    [8188, 8188, 1]
  ]
  static foldLt = [
    [452, 454, 2],
    [455, 457, 2],
    [458, 460, 2],
    [497, 499, 2],
    [8064, 8071, 1],
    [8080, 8087, 1],
    [8096, 8103, 1],
    [8115, 8131, 16],
    [8179, 8179, 1]
  ]
  static Lu = [
    [65, 90, 1],
    [192, 214, 1],
    [216, 222, 1],
    [256, 310, 2],
    [313, 327, 2],
    [330, 376, 2],
    [377, 381, 2],
    [385, 386, 1],
    [388, 390, 2],
    [391, 393, 2],
    [394, 395, 1],
    [398, 401, 1],
    [403, 404, 1],
    [406, 408, 1],
    [412, 413, 1],
    [415, 416, 1],
    [418, 422, 2],
    [423, 425, 2],
    [428, 430, 2],
    [431, 433, 2],
    [434, 435, 1],
    [437, 439, 2],
    [440, 444, 4],
    [452, 461, 3],
    [463, 475, 2],
    [478, 494, 2],
    [497, 500, 3],
    [502, 504, 1],
    [506, 562, 2],
    [570, 571, 1],
    [573, 574, 1],
    [577, 579, 2],
    [580, 582, 1],
    [584, 590, 2],
    [880, 882, 2],
    [886, 895, 9],
    [902, 904, 2],
    [905, 906, 1],
    [908, 910, 2],
    [911, 913, 2],
    [914, 929, 1],
    [931, 939, 1],
    [975, 978, 3],
    [979, 980, 1],
    [984, 1006, 2],
    [1012, 1015, 3],
    [1017, 1018, 1],
    [1021, 1071, 1],
    [1120, 1152, 2],
    [1162, 1216, 2],
    [1217, 1229, 2],
    [1232, 1326, 2],
    [1329, 1366, 1],
    [4256, 4293, 1],
    [4295, 4301, 6],
    [5024, 5109, 1],
    [7312, 7354, 1],
    [7357, 7359, 1],
    [7680, 7828, 2],
    [7838, 7934, 2],
    [7944, 7951, 1],
    [7960, 7965, 1],
    [7976, 7983, 1],
    [7992, 7999, 1],
    [8008, 8013, 1],
    [8025, 8031, 2],
    [8040, 8047, 1],
    [8120, 8123, 1],
    [8136, 8139, 1],
    [8152, 8155, 1],
    [8168, 8172, 1],
    [8184, 8187, 1],
    [8450, 8455, 5],
    [8459, 8461, 1],
    [8464, 8466, 1],
    [8469, 8473, 4],
    [8474, 8477, 1],
    [8484, 8490, 2],
    [8491, 8493, 1],
    [8496, 8499, 1],
    [8510, 8511, 1],
    [8517, 8579, 62],
    [11264, 11311, 1],
    [11360, 11362, 2],
    [11363, 11364, 1],
    [11367, 11373, 2],
    [11374, 11376, 1],
    [11378, 11381, 3],
    [11390, 11392, 1],
    [11394, 11490, 2],
    [11499, 11501, 2],
    [11506, 42560, 31054],
    [42562, 42604, 2],
    [42624, 42650, 2],
    [42786, 42798, 2],
    [42802, 42862, 2],
    [42873, 42877, 2],
    [42878, 42886, 2],
    [42891, 42893, 2],
    [42896, 42898, 2],
    [42902, 42922, 2],
    [42923, 42926, 1],
    [42928, 42932, 1],
    [42934, 42948, 2],
    [42949, 42951, 1],
    [42953, 42960, 7],
    [42966, 42968, 2],
    [42997, 65313, 22316],
    [65314, 65338, 1],
    [66560, 66599, 1],
    [66736, 66771, 1],
    [66928, 66938, 1],
    [66940, 66954, 1],
    [66956, 66962, 1],
    [66964, 66965, 1],
    [68736, 68786, 1],
    [71840, 71871, 1],
    [93760, 93791, 1],
    [119808, 119833, 1],
    [119860, 119885, 1],
    [119912, 119937, 1],
    [119964, 119966, 2],
    [119967, 119973, 3],
    [119974, 119977, 3],
    [119978, 119980, 1],
    [119982, 119989, 1],
    [120016, 120041, 1],
    [120068, 120069, 1],
    [120071, 120074, 1],
    [120077, 120084, 1],
    [120086, 120092, 1],
    [120120, 120121, 1],
    [120123, 120126, 1],
    [120128, 120132, 1],
    [120134, 120138, 4],
    [120139, 120144, 1],
    [120172, 120197, 1],
    [120224, 120249, 1],
    [120276, 120301, 1],
    [120328, 120353, 1],
    [120380, 120405, 1],
    [120432, 120457, 1],
    [120488, 120512, 1],
    [120546, 120570, 1],
    [120604, 120628, 1],
    [120662, 120686, 1],
    [120720, 120744, 1],
    [120778, 125184, 4406],
    [125185, 125217, 1]
  ]
  static Upper = this.Lu
  static foldLu = [
    [97, 122, 1],
    [181, 223, 42],
    [224, 246, 1],
    [248, 255, 1],
    [257, 303, 2],
    [307, 311, 2],
    [314, 328, 2],
    [331, 375, 2],
    [378, 382, 2],
    [383, 384, 1],
    [387, 389, 2],
    [392, 396, 4],
    [402, 405, 3],
    [409, 410, 1],
    [414, 417, 3],
    [419, 421, 2],
    [424, 429, 5],
    [432, 436, 4],
    [438, 441, 3],
    [445, 447, 2],
    [453, 454, 1],
    [456, 457, 1],
    [459, 460, 1],
    [462, 476, 2],
    [477, 495, 2],
    [498, 499, 1],
    [501, 505, 4],
    [507, 543, 2],
    [547, 563, 2],
    [572, 575, 3],
    [576, 578, 2],
    [583, 591, 2],
    [592, 596, 1],
    [598, 599, 1],
    [601, 603, 2],
    [604, 608, 4],
    [609, 613, 2],
    [614, 616, 2],
    [617, 620, 1],
    [623, 625, 2],
    [626, 629, 3],
    [637, 640, 3],
    [642, 643, 1],
    [647, 652, 1],
    [658, 669, 11],
    [670, 837, 167],
    [881, 883, 2],
    [887, 891, 4],
    [892, 893, 1],
    [940, 943, 1],
    [945, 974, 1],
    [976, 977, 1],
    [981, 983, 1],
    [985, 1007, 2],
    [1008, 1011, 1],
    [1013, 1019, 3],
    [1072, 1119, 1],
    [1121, 1153, 2],
    [1163, 1215, 2],
    [1218, 1230, 2],
    [1231, 1327, 2],
    [1377, 1414, 1],
    [4304, 4346, 1],
    [4349, 4351, 1],
    [5112, 5117, 1],
    [7296, 7304, 1],
    [7545, 7549, 4],
    [7566, 7681, 115],
    [7683, 7829, 2],
    [7835, 7841, 6],
    [7843, 7935, 2],
    [7936, 7943, 1],
    [7952, 7957, 1],
    [7968, 7975, 1],
    [7984, 7991, 1],
    [8000, 8005, 1],
    [8017, 8023, 2],
    [8032, 8039, 1],
    [8048, 8061, 1],
    [8112, 8113, 1],
    [8126, 8144, 18],
    [8145, 8160, 15],
    [8161, 8165, 4],
    [8526, 8580, 54],
    [11312, 11359, 1],
    [11361, 11365, 4],
    [11366, 11372, 2],
    [11379, 11382, 3],
    [11393, 11491, 2],
    [11500, 11502, 2],
    [11507, 11520, 13],
    [11521, 11557, 1],
    [11559, 11565, 6],
    [42561, 42605, 2],
    [42625, 42651, 2],
    [42787, 42799, 2],
    [42803, 42863, 2],
    [42874, 42876, 2],
    [42879, 42887, 2],
    [42892, 42897, 5],
    [42899, 42900, 1],
    [42903, 42921, 2],
    [42933, 42947, 2],
    [42952, 42954, 2],
    [42961, 42967, 6],
    [42969, 42998, 29],
    [43859, 43888, 29],
    [43889, 43967, 1],
    [65345, 65370, 1],
    [66600, 66639, 1],
    [66776, 66811, 1],
    [66967, 66977, 1],
    [66979, 66993, 1],
    [66995, 67001, 1],
    [67003, 67004, 1],
    [68800, 68850, 1],
    [71872, 71903, 1],
    [93792, 93823, 1],
    [125218, 125251, 1]
  ]
  static M = [
    [768, 879, 1],
    [1155, 1161, 1],
    [1425, 1469, 1],
    [1471, 1473, 2],
    [1474, 1476, 2],
    [1477, 1479, 2],
    [1552, 1562, 1],
    [1611, 1631, 1],
    [1648, 1750, 102],
    [1751, 1756, 1],
    [1759, 1764, 1],
    [1767, 1768, 1],
    [1770, 1773, 1],
    [1809, 1840, 31],
    [1841, 1866, 1],
    [1958, 1968, 1],
    [2027, 2035, 1],
    [2045, 2070, 25],
    [2071, 2073, 1],
    [2075, 2083, 1],
    [2085, 2087, 1],
    [2089, 2093, 1],
    [2137, 2139, 1],
    [2200, 2207, 1],
    [2250, 2273, 1],
    [2275, 2307, 1],
    [2362, 2364, 1],
    [2366, 2383, 1],
    [2385, 2391, 1],
    [2402, 2403, 1],
    [2433, 2435, 1],
    [2492, 2494, 2],
    [2495, 2500, 1],
    [2503, 2504, 1],
    [2507, 2509, 1],
    [2519, 2530, 11],
    [2531, 2558, 27],
    [2561, 2563, 1],
    [2620, 2622, 2],
    [2623, 2626, 1],
    [2631, 2632, 1],
    [2635, 2637, 1],
    [2641, 2672, 31],
    [2673, 2677, 4],
    [2689, 2691, 1],
    [2748, 2750, 2],
    [2751, 2757, 1],
    [2759, 2761, 1],
    [2763, 2765, 1],
    [2786, 2787, 1],
    [2810, 2815, 1],
    [2817, 2819, 1],
    [2876, 2878, 2],
    [2879, 2884, 1],
    [2887, 2888, 1],
    [2891, 2893, 1],
    [2901, 2903, 1],
    [2914, 2915, 1],
    [2946, 3006, 60],
    [3007, 3010, 1],
    [3014, 3016, 1],
    [3018, 3021, 1],
    [3031, 3072, 41],
    [3073, 3076, 1],
    [3132, 3134, 2],
    [3135, 3140, 1],
    [3142, 3144, 1],
    [3146, 3149, 1],
    [3157, 3158, 1],
    [3170, 3171, 1],
    [3201, 3203, 1],
    [3260, 3262, 2],
    [3263, 3268, 1],
    [3270, 3272, 1],
    [3274, 3277, 1],
    [3285, 3286, 1],
    [3298, 3299, 1],
    [3315, 3328, 13],
    [3329, 3331, 1],
    [3387, 3388, 1],
    [3390, 3396, 1],
    [3398, 3400, 1],
    [3402, 3405, 1],
    [3415, 3426, 11],
    [3427, 3457, 30],
    [3458, 3459, 1],
    [3530, 3535, 5],
    [3536, 3540, 1],
    [3542, 3544, 2],
    [3545, 3551, 1],
    [3570, 3571, 1],
    [3633, 3636, 3],
    [3637, 3642, 1],
    [3655, 3662, 1],
    [3761, 3764, 3],
    [3765, 3772, 1],
    [3784, 3790, 1],
    [3864, 3865, 1],
    [3893, 3897, 2],
    [3902, 3903, 1],
    [3953, 3972, 1],
    [3974, 3975, 1],
    [3981, 3991, 1],
    [3993, 4028, 1],
    [4038, 4139, 101],
    [4140, 4158, 1],
    [4182, 4185, 1],
    [4190, 4192, 1],
    [4194, 4196, 1],
    [4199, 4205, 1],
    [4209, 4212, 1],
    [4226, 4237, 1],
    [4239, 4250, 11],
    [4251, 4253, 1],
    [4957, 4959, 1],
    [5906, 5909, 1],
    [5938, 5940, 1],
    [5970, 5971, 1],
    [6002, 6003, 1],
    [6068, 6099, 1],
    [6109, 6155, 46],
    [6156, 6157, 1],
    [6159, 6277, 118],
    [6278, 6313, 35],
    [6432, 6443, 1],
    [6448, 6459, 1],
    [6679, 6683, 1],
    [6741, 6750, 1],
    [6752, 6780, 1],
    [6783, 6832, 49],
    [6833, 6862, 1],
    [6912, 6916, 1],
    [6964, 6980, 1],
    [7019, 7027, 1],
    [7040, 7042, 1],
    [7073, 7085, 1],
    [7142, 7155, 1],
    [7204, 7223, 1],
    [7376, 7378, 1],
    [7380, 7400, 1],
    [7405, 7412, 7],
    [7415, 7417, 1],
    [7616, 7679, 1],
    [8400, 8432, 1],
    [11503, 11505, 1],
    [11647, 11744, 97],
    [11745, 11775, 1],
    [12330, 12335, 1],
    [12441, 12442, 1],
    [42607, 42610, 1],
    [42612, 42621, 1],
    [42654, 42655, 1],
    [42736, 42737, 1],
    [43010, 43014, 4],
    [43019, 43043, 24],
    [43044, 43047, 1],
    [43052, 43136, 84],
    [43137, 43188, 51],
    [43189, 43205, 1],
    [43232, 43249, 1],
    [43263, 43302, 39],
    [43303, 43309, 1],
    [43335, 43347, 1],
    [43392, 43395, 1],
    [43443, 43456, 1],
    [43493, 43561, 68],
    [43562, 43574, 1],
    [43587, 43596, 9],
    [43597, 43643, 46],
    [43644, 43645, 1],
    [43696, 43698, 2],
    [43699, 43700, 1],
    [43703, 43704, 1],
    [43710, 43711, 1],
    [43713, 43755, 42],
    [43756, 43759, 1],
    [43765, 43766, 1],
    [44003, 44010, 1],
    [44012, 44013, 1],
    [64286, 65024, 738],
    [65025, 65039, 1],
    [65056, 65071, 1],
    [66045, 66272, 227],
    [66422, 66426, 1],
    [68097, 68099, 1],
    [68101, 68102, 1],
    [68108, 68111, 1],
    [68152, 68154, 1],
    [68159, 68325, 166],
    [68326, 68900, 574],
    [68901, 68903, 1],
    [69291, 69292, 1],
    [69373, 69375, 1],
    [69446, 69456, 1],
    [69506, 69509, 1],
    [69632, 69634, 1],
    [69688, 69702, 1],
    [69744, 69747, 3],
    [69748, 69759, 11],
    [69760, 69762, 1],
    [69808, 69818, 1],
    [69826, 69888, 62],
    [69889, 69890, 1],
    [69927, 69940, 1],
    [69957, 69958, 1],
    [70003, 70016, 13],
    [70017, 70018, 1],
    [70067, 70080, 1],
    [70089, 70092, 1],
    [70094, 70095, 1],
    [70188, 70199, 1],
    [70206, 70209, 3],
    [70367, 70378, 1],
    [70400, 70403, 1],
    [70459, 70460, 1],
    [70462, 70468, 1],
    [70471, 70472, 1],
    [70475, 70477, 1],
    [70487, 70498, 11],
    [70499, 70502, 3],
    [70503, 70508, 1],
    [70512, 70516, 1],
    [70709, 70726, 1],
    [70750, 70832, 82],
    [70833, 70851, 1],
    [71087, 71093, 1],
    [71096, 71104, 1],
    [71132, 71133, 1],
    [71216, 71232, 1],
    [71339, 71351, 1],
    [71453, 71467, 1],
    [71724, 71738, 1],
    [71984, 71989, 1],
    [71991, 71992, 1],
    [71995, 71998, 1],
    [72000, 72002, 2],
    [72003, 72145, 142],
    [72146, 72151, 1],
    [72154, 72160, 1],
    [72164, 72193, 29],
    [72194, 72202, 1],
    [72243, 72249, 1],
    [72251, 72254, 1],
    [72263, 72273, 10],
    [72274, 72283, 1],
    [72330, 72345, 1],
    [72751, 72758, 1],
    [72760, 72767, 1],
    [72850, 72871, 1],
    [72873, 72886, 1],
    [73009, 73014, 1],
    [73018, 73020, 2],
    [73021, 73023, 2],
    [73024, 73029, 1],
    [73031, 73098, 67],
    [73099, 73102, 1],
    [73104, 73105, 1],
    [73107, 73111, 1],
    [73459, 73462, 1],
    [73472, 73473, 1],
    [73475, 73524, 49],
    [73525, 73530, 1],
    [73534, 73538, 1],
    [78912, 78919, 7],
    [78920, 78933, 1],
    [92912, 92916, 1],
    [92976, 92982, 1],
    [94031, 94033, 2],
    [94034, 94087, 1],
    [94095, 94098, 1],
    [94180, 94192, 12],
    [94193, 113821, 19628],
    [113822, 118528, 4706],
    [118529, 118573, 1],
    [118576, 118598, 1],
    [119141, 119145, 1],
    [119149, 119154, 1],
    [119163, 119170, 1],
    [119173, 119179, 1],
    [119210, 119213, 1],
    [119362, 119364, 1],
    [121344, 121398, 1],
    [121403, 121452, 1],
    [121461, 121476, 15],
    [121499, 121503, 1],
    [121505, 121519, 1],
    [122880, 122886, 1],
    [122888, 122904, 1],
    [122907, 122913, 1],
    [122915, 122916, 1],
    [122918, 122922, 1],
    [123023, 123184, 161],
    [123185, 123190, 1],
    [123566, 123628, 62],
    [123629, 123631, 1],
    [124140, 124143, 1],
    [125136, 125142, 1],
    [125252, 125258, 1],
    [917760, 917999, 1]
  ]
  static foldM = [
    [921, 953, 32],
    [8126, 8126, 1]
  ]
  static Mc = [
    [2307, 2363, 56],
    [2366, 2368, 1],
    [2377, 2380, 1],
    [2382, 2383, 1],
    [2434, 2435, 1],
    [2494, 2496, 1],
    [2503, 2504, 1],
    [2507, 2508, 1],
    [2519, 2563, 44],
    [2622, 2624, 1],
    [2691, 2750, 59],
    [2751, 2752, 1],
    [2761, 2763, 2],
    [2764, 2818, 54],
    [2819, 2878, 59],
    [2880, 2887, 7],
    [2888, 2891, 3],
    [2892, 2903, 11],
    [3006, 3007, 1],
    [3009, 3010, 1],
    [3014, 3016, 1],
    [3018, 3020, 1],
    [3031, 3073, 42],
    [3074, 3075, 1],
    [3137, 3140, 1],
    [3202, 3203, 1],
    [3262, 3264, 2],
    [3265, 3268, 1],
    [3271, 3272, 1],
    [3274, 3275, 1],
    [3285, 3286, 1],
    [3315, 3330, 15],
    [3331, 3390, 59],
    [3391, 3392, 1],
    [3398, 3400, 1],
    [3402, 3404, 1],
    [3415, 3458, 43],
    [3459, 3535, 76],
    [3536, 3537, 1],
    [3544, 3551, 1],
    [3570, 3571, 1],
    [3902, 3903, 1],
    [3967, 4139, 172],
    [4140, 4145, 5],
    [4152, 4155, 3],
    [4156, 4182, 26],
    [4183, 4194, 11],
    [4195, 4196, 1],
    [4199, 4205, 1],
    [4227, 4228, 1],
    [4231, 4236, 1],
    [4239, 4250, 11],
    [4251, 4252, 1],
    [5909, 5940, 31],
    [6070, 6078, 8],
    [6079, 6085, 1],
    [6087, 6088, 1],
    [6435, 6438, 1],
    [6441, 6443, 1],
    [6448, 6449, 1],
    [6451, 6456, 1],
    [6681, 6682, 1],
    [6741, 6743, 2],
    [6753, 6755, 2],
    [6756, 6765, 9],
    [6766, 6770, 1],
    [6916, 6965, 49],
    [6971, 6973, 2],
    [6974, 6977, 1],
    [6979, 6980, 1],
    [7042, 7073, 31],
    [7078, 7079, 1],
    [7082, 7143, 61],
    [7146, 7148, 1],
    [7150, 7154, 4],
    [7155, 7204, 49],
    [7205, 7211, 1],
    [7220, 7221, 1],
    [7393, 7415, 22],
    [12334, 12335, 1],
    [43043, 43044, 1],
    [43047, 43136, 89],
    [43137, 43188, 51],
    [43189, 43203, 1],
    [43346, 43347, 1],
    [43395, 43444, 49],
    [43445, 43450, 5],
    [43451, 43454, 3],
    [43455, 43456, 1],
    [43567, 43568, 1],
    [43571, 43572, 1],
    [43597, 43643, 46],
    [43645, 43755, 110],
    [43758, 43759, 1],
    [43765, 44003, 238],
    [44004, 44006, 2],
    [44007, 44009, 2],
    [44010, 44012, 2],
    [69632, 69634, 2],
    [69762, 69808, 46],
    [69809, 69810, 1],
    [69815, 69816, 1],
    [69932, 69957, 25],
    [69958, 70018, 60],
    [70067, 70069, 1],
    [70079, 70080, 1],
    [70094, 70188, 94],
    [70189, 70190, 1],
    [70194, 70195, 1],
    [70197, 70368, 171],
    [70369, 70370, 1],
    [70402, 70403, 1],
    [70462, 70463, 1],
    [70465, 70468, 1],
    [70471, 70472, 1],
    [70475, 70477, 1],
    [70487, 70498, 11],
    [70499, 70709, 210],
    [70710, 70711, 1],
    [70720, 70721, 1],
    [70725, 70832, 107],
    [70833, 70834, 1],
    [70841, 70843, 2],
    [70844, 70846, 1],
    [70849, 71087, 238],
    [71088, 71089, 1],
    [71096, 71099, 1],
    [71102, 71216, 114],
    [71217, 71218, 1],
    [71227, 71228, 1],
    [71230, 71340, 110],
    [71342, 71343, 1],
    [71350, 71456, 106],
    [71457, 71462, 5],
    [71724, 71726, 1],
    [71736, 71984, 248],
    [71985, 71989, 1],
    [71991, 71992, 1],
    [71997, 72000, 3],
    [72002, 72145, 143],
    [72146, 72147, 1],
    [72156, 72159, 1],
    [72164, 72249, 85],
    [72279, 72280, 1],
    [72343, 72751, 408],
    [72766, 72873, 107],
    [72881, 72884, 3],
    [73098, 73102, 1],
    [73107, 73108, 1],
    [73110, 73461, 351],
    [73462, 73475, 13],
    [73524, 73525, 1],
    [73534, 73535, 1],
    [73537, 94033, 20496],
    [94034, 94087, 1],
    [94192, 94193, 1],
    [119141, 119142, 1],
    [119149, 119154, 1]
  ]
  static Me = [
    [1160, 1161, 1],
    [6846, 8413, 1567],
    [8414, 8416, 1],
    [8418, 8420, 1],
    [42608, 42610, 1]
  ]
  static Mn = [
    [768, 879, 1],
    [1155, 1159, 1],
    [1425, 1469, 1],
    [1471, 1473, 2],
    [1474, 1476, 2],
    [1477, 1479, 2],
    [1552, 1562, 1],
    [1611, 1631, 1],
    [1648, 1750, 102],
    [1751, 1756, 1],
    [1759, 1764, 1],
    [1767, 1768, 1],
    [1770, 1773, 1],
    [1809, 1840, 31],
    [1841, 1866, 1],
    [1958, 1968, 1],
    [2027, 2035, 1],
    [2045, 2070, 25],
    [2071, 2073, 1],
    [2075, 2083, 1],
    [2085, 2087, 1],
    [2089, 2093, 1],
    [2137, 2139, 1],
    [2200, 2207, 1],
    [2250, 2273, 1],
    [2275, 2306, 1],
    [2362, 2364, 2],
    [2369, 2376, 1],
    [2381, 2385, 4],
    [2386, 2391, 1],
    [2402, 2403, 1],
    [2433, 2492, 59],
    [2497, 2500, 1],
    [2509, 2530, 21],
    [2531, 2558, 27],
    [2561, 2562, 1],
    [2620, 2625, 5],
    [2626, 2631, 5],
    [2632, 2635, 3],
    [2636, 2637, 1],
    [2641, 2672, 31],
    [2673, 2677, 4],
    [2689, 2690, 1],
    [2748, 2753, 5],
    [2754, 2757, 1],
    [2759, 2760, 1],
    [2765, 2786, 21],
    [2787, 2810, 23],
    [2811, 2815, 1],
    [2817, 2876, 59],
    [2879, 2881, 2],
    [2882, 2884, 1],
    [2893, 2901, 8],
    [2902, 2914, 12],
    [2915, 2946, 31],
    [3008, 3021, 13],
    [3072, 3076, 4],
    [3132, 3134, 2],
    [3135, 3136, 1],
    [3142, 3144, 1],
    [3146, 3149, 1],
    [3157, 3158, 1],
    [3170, 3171, 1],
    [3201, 3260, 59],
    [3263, 3270, 7],
    [3276, 3277, 1],
    [3298, 3299, 1],
    [3328, 3329, 1],
    [3387, 3388, 1],
    [3393, 3396, 1],
    [3405, 3426, 21],
    [3427, 3457, 30],
    [3530, 3538, 8],
    [3539, 3540, 1],
    [3542, 3633, 91],
    [3636, 3642, 1],
    [3655, 3662, 1],
    [3761, 3764, 3],
    [3765, 3772, 1],
    [3784, 3790, 1],
    [3864, 3865, 1],
    [3893, 3897, 2],
    [3953, 3966, 1],
    [3968, 3972, 1],
    [3974, 3975, 1],
    [3981, 3991, 1],
    [3993, 4028, 1],
    [4038, 4141, 103],
    [4142, 4144, 1],
    [4146, 4151, 1],
    [4153, 4154, 1],
    [4157, 4158, 1],
    [4184, 4185, 1],
    [4190, 4192, 1],
    [4209, 4212, 1],
    [4226, 4229, 3],
    [4230, 4237, 7],
    [4253, 4957, 704],
    [4958, 4959, 1],
    [5906, 5908, 1],
    [5938, 5939, 1],
    [5970, 5971, 1],
    [6002, 6003, 1],
    [6068, 6069, 1],
    [6071, 6077, 1],
    [6086, 6089, 3],
    [6090, 6099, 1],
    [6109, 6155, 46],
    [6156, 6157, 1],
    [6159, 6277, 118],
    [6278, 6313, 35],
    [6432, 6434, 1],
    [6439, 6440, 1],
    [6450, 6457, 7],
    [6458, 6459, 1],
    [6679, 6680, 1],
    [6683, 6742, 59],
    [6744, 6750, 1],
    [6752, 6754, 2],
    [6757, 6764, 1],
    [6771, 6780, 1],
    [6783, 6832, 49],
    [6833, 6845, 1],
    [6847, 6862, 1],
    [6912, 6915, 1],
    [6964, 6966, 2],
    [6967, 6970, 1],
    [6972, 6978, 6],
    [7019, 7027, 1],
    [7040, 7041, 1],
    [7074, 7077, 1],
    [7080, 7081, 1],
    [7083, 7085, 1],
    [7142, 7144, 2],
    [7145, 7149, 4],
    [7151, 7153, 1],
    [7212, 7219, 1],
    [7222, 7223, 1],
    [7376, 7378, 1],
    [7380, 7392, 1],
    [7394, 7400, 1],
    [7405, 7412, 7],
    [7416, 7417, 1],
    [7616, 7679, 1],
    [8400, 8412, 1],
    [8417, 8421, 4],
    [8422, 8432, 1],
    [11503, 11505, 1],
    [11647, 11744, 97],
    [11745, 11775, 1],
    [12330, 12333, 1],
    [12441, 12442, 1],
    [42607, 42612, 5],
    [42613, 42621, 1],
    [42654, 42655, 1],
    [42736, 42737, 1],
    [43010, 43014, 4],
    [43019, 43045, 26],
    [43046, 43052, 6],
    [43204, 43205, 1],
    [43232, 43249, 1],
    [43263, 43302, 39],
    [43303, 43309, 1],
    [43335, 43345, 1],
    [43392, 43394, 1],
    [43443, 43446, 3],
    [43447, 43449, 1],
    [43452, 43453, 1],
    [43493, 43561, 68],
    [43562, 43566, 1],
    [43569, 43570, 1],
    [43573, 43574, 1],
    [43587, 43596, 9],
    [43644, 43696, 52],
    [43698, 43700, 1],
    [43703, 43704, 1],
    [43710, 43711, 1],
    [43713, 43756, 43],
    [43757, 43766, 9],
    [44005, 44008, 3],
    [44013, 64286, 20273],
    [65024, 65039, 1],
    [65056, 65071, 1],
    [66045, 66272, 227],
    [66422, 66426, 1],
    [68097, 68099, 1],
    [68101, 68102, 1],
    [68108, 68111, 1],
    [68152, 68154, 1],
    [68159, 68325, 166],
    [68326, 68900, 574],
    [68901, 68903, 1],
    [69291, 69292, 1],
    [69373, 69375, 1],
    [69446, 69456, 1],
    [69506, 69509, 1],
    [69633, 69688, 55],
    [69689, 69702, 1],
    [69744, 69747, 3],
    [69748, 69759, 11],
    [69760, 69761, 1],
    [69811, 69814, 1],
    [69817, 69818, 1],
    [69826, 69888, 62],
    [69889, 69890, 1],
    [69927, 69931, 1],
    [69933, 69940, 1],
    [70003, 70016, 13],
    [70017, 70070, 53],
    [70071, 70078, 1],
    [70089, 70092, 1],
    [70095, 70191, 96],
    [70192, 70193, 1],
    [70196, 70198, 2],
    [70199, 70206, 7],
    [70209, 70367, 158],
    [70371, 70378, 1],
    [70400, 70401, 1],
    [70459, 70460, 1],
    [70464, 70502, 38],
    [70503, 70508, 1],
    [70512, 70516, 1],
    [70712, 70719, 1],
    [70722, 70724, 1],
    [70726, 70750, 24],
    [70835, 70840, 1],
    [70842, 70847, 5],
    [70848, 70850, 2],
    [70851, 71090, 239],
    [71091, 71093, 1],
    [71100, 71101, 1],
    [71103, 71104, 1],
    [71132, 71133, 1],
    [71219, 71226, 1],
    [71229, 71231, 2],
    [71232, 71339, 107],
    [71341, 71344, 3],
    [71345, 71349, 1],
    [71351, 71453, 102],
    [71454, 71455, 1],
    [71458, 71461, 1],
    [71463, 71467, 1],
    [71727, 71735, 1],
    [71737, 71738, 1],
    [71995, 71996, 1],
    [71998, 72003, 5],
    [72148, 72151, 1],
    [72154, 72155, 1],
    [72160, 72193, 33],
    [72194, 72202, 1],
    [72243, 72248, 1],
    [72251, 72254, 1],
    [72263, 72273, 10],
    [72274, 72278, 1],
    [72281, 72283, 1],
    [72330, 72342, 1],
    [72344, 72345, 1],
    [72752, 72758, 1],
    [72760, 72765, 1],
    [72767, 72850, 83],
    [72851, 72871, 1],
    [72874, 72880, 1],
    [72882, 72883, 1],
    [72885, 72886, 1],
    [73009, 73014, 1],
    [73018, 73020, 2],
    [73021, 73023, 2],
    [73024, 73029, 1],
    [73031, 73104, 73],
    [73105, 73109, 4],
    [73111, 73459, 348],
    [73460, 73472, 12],
    [73473, 73526, 53],
    [73527, 73530, 1],
    [73536, 73538, 2],
    [78912, 78919, 7],
    [78920, 78933, 1],
    [92912, 92916, 1],
    [92976, 92982, 1],
    [94031, 94095, 64],
    [94096, 94098, 1],
    [94180, 113821, 19641],
    [113822, 118528, 4706],
    [118529, 118573, 1],
    [118576, 118598, 1],
    [119143, 119145, 1],
    [119163, 119170, 1],
    [119173, 119179, 1],
    [119210, 119213, 1],
    [119362, 119364, 1],
    [121344, 121398, 1],
    [121403, 121452, 1],
    [121461, 121476, 15],
    [121499, 121503, 1],
    [121505, 121519, 1],
    [122880, 122886, 1],
    [122888, 122904, 1],
    [122907, 122913, 1],
    [122915, 122916, 1],
    [122918, 122922, 1],
    [123023, 123184, 161],
    [123185, 123190, 1],
    [123566, 123628, 62],
    [123629, 123631, 1],
    [124140, 124143, 1],
    [125136, 125142, 1],
    [125252, 125258, 1],
    [917760, 917999, 1]
  ]
  static foldMn = [
    [921, 953, 32],
    [8126, 8126, 1]
  ]
  static N = [
    [48, 57, 1],
    [178, 179, 1],
    [185, 188, 3],
    [189, 190, 1],
    [1632, 1641, 1],
    [1776, 1785, 1],
    [1984, 1993, 1],
    [2406, 2415, 1],
    [2534, 2543, 1],
    [2548, 2553, 1],
    [2662, 2671, 1],
    [2790, 2799, 1],
    [2918, 2927, 1],
    [2930, 2935, 1],
    [3046, 3058, 1],
    [3174, 3183, 1],
    [3192, 3198, 1],
    [3302, 3311, 1],
    [3416, 3422, 1],
    [3430, 3448, 1],
    [3558, 3567, 1],
    [3664, 3673, 1],
    [3792, 3801, 1],
    [3872, 3891, 1],
    [4160, 4169, 1],
    [4240, 4249, 1],
    [4969, 4988, 1],
    [5870, 5872, 1],
    [6112, 6121, 1],
    [6128, 6137, 1],
    [6160, 6169, 1],
    [6470, 6479, 1],
    [6608, 6618, 1],
    [6784, 6793, 1],
    [6800, 6809, 1],
    [6992, 7001, 1],
    [7088, 7097, 1],
    [7232, 7241, 1],
    [7248, 7257, 1],
    [8304, 8308, 4],
    [8309, 8313, 1],
    [8320, 8329, 1],
    [8528, 8578, 1],
    [8581, 8585, 1],
    [9312, 9371, 1],
    [9450, 9471, 1],
    [10102, 10131, 1],
    [11517, 12295, 778],
    [12321, 12329, 1],
    [12344, 12346, 1],
    [12690, 12693, 1],
    [12832, 12841, 1],
    [12872, 12879, 1],
    [12881, 12895, 1],
    [12928, 12937, 1],
    [12977, 12991, 1],
    [42528, 42537, 1],
    [42726, 42735, 1],
    [43056, 43061, 1],
    [43216, 43225, 1],
    [43264, 43273, 1],
    [43472, 43481, 1],
    [43504, 43513, 1],
    [43600, 43609, 1],
    [44016, 44025, 1],
    [65296, 65305, 1],
    [65799, 65843, 1],
    [65856, 65912, 1],
    [65930, 65931, 1],
    [66273, 66299, 1],
    [66336, 66339, 1],
    [66369, 66378, 9],
    [66513, 66517, 1],
    [66720, 66729, 1],
    [67672, 67679, 1],
    [67705, 67711, 1],
    [67751, 67759, 1],
    [67835, 67839, 1],
    [67862, 67867, 1],
    [68028, 68029, 1],
    [68032, 68047, 1],
    [68050, 68095, 1],
    [68160, 68168, 1],
    [68221, 68222, 1],
    [68253, 68255, 1],
    [68331, 68335, 1],
    [68440, 68447, 1],
    [68472, 68479, 1],
    [68521, 68527, 1],
    [68858, 68863, 1],
    [68912, 68921, 1],
    [69216, 69246, 1],
    [69405, 69414, 1],
    [69457, 69460, 1],
    [69573, 69579, 1],
    [69714, 69743, 1],
    [69872, 69881, 1],
    [69942, 69951, 1],
    [70096, 70105, 1],
    [70113, 70132, 1],
    [70384, 70393, 1],
    [70736, 70745, 1],
    [70864, 70873, 1],
    [71248, 71257, 1],
    [71360, 71369, 1],
    [71472, 71483, 1],
    [71904, 71922, 1],
    [72016, 72025, 1],
    [72784, 72812, 1],
    [73040, 73049, 1],
    [73120, 73129, 1],
    [73552, 73561, 1],
    [73664, 73684, 1],
    [74752, 74862, 1],
    [92768, 92777, 1],
    [92864, 92873, 1],
    [93008, 93017, 1],
    [93019, 93025, 1],
    [93824, 93846, 1],
    [119488, 119507, 1],
    [119520, 119539, 1],
    [119648, 119672, 1],
    [120782, 120831, 1],
    [123200, 123209, 1],
    [123632, 123641, 1],
    [124144, 124153, 1],
    [125127, 125135, 1],
    [125264, 125273, 1],
    [126065, 126123, 1],
    [126125, 126127, 1],
    [126129, 126132, 1],
    [126209, 126253, 1],
    [126255, 126269, 1],
    [127232, 127244, 1],
    [130032, 130041, 1]
  ]
  static Nd = [
    [48, 57, 1],
    [1632, 1641, 1],
    [1776, 1785, 1],
    [1984, 1993, 1],
    [2406, 2415, 1],
    [2534, 2543, 1],
    [2662, 2671, 1],
    [2790, 2799, 1],
    [2918, 2927, 1],
    [3046, 3055, 1],
    [3174, 3183, 1],
    [3302, 3311, 1],
    [3430, 3439, 1],
    [3558, 3567, 1],
    [3664, 3673, 1],
    [3792, 3801, 1],
    [3872, 3881, 1],
    [4160, 4169, 1],
    [4240, 4249, 1],
    [6112, 6121, 1],
    [6160, 6169, 1],
    [6470, 6479, 1],
    [6608, 6617, 1],
    [6784, 6793, 1],
    [6800, 6809, 1],
    [6992, 7001, 1],
    [7088, 7097, 1],
    [7232, 7241, 1],
    [7248, 7257, 1],
    [42528, 42537, 1],
    [43216, 43225, 1],
    [43264, 43273, 1],
    [43472, 43481, 1],
    [43504, 43513, 1],
    [43600, 43609, 1],
    [44016, 44025, 1],
    [65296, 65305, 1],
    [66720, 66729, 1],
    [68912, 68921, 1],
    [69734, 69743, 1],
    [69872, 69881, 1],
    [69942, 69951, 1],
    [70096, 70105, 1],
    [70384, 70393, 1],
    [70736, 70745, 1],
    [70864, 70873, 1],
    [71248, 71257, 1],
    [71360, 71369, 1],
    [71472, 71481, 1],
    [71904, 71913, 1],
    [72016, 72025, 1],
    [72784, 72793, 1],
    [73040, 73049, 1],
    [73120, 73129, 1],
    [73552, 73561, 1],
    [92768, 92777, 1],
    [92864, 92873, 1],
    [93008, 93017, 1],
    [120782, 120831, 1],
    [123200, 123209, 1],
    [123632, 123641, 1],
    [124144, 124153, 1],
    [125264, 125273, 1],
    [130032, 130041, 1]
  ]
  static Nl = [
    [5870, 5872, 1],
    [8544, 8578, 1],
    [8581, 8584, 1],
    [12295, 12321, 26],
    [12322, 12329, 1],
    [12344, 12346, 1],
    [42726, 42735, 1],
    [65856, 65908, 1],
    [66369, 66378, 9],
    [66513, 66517, 1],
    [74752, 74862, 1]
  ]
  static No = [
    [178, 179, 1],
    [185, 188, 3],
    [189, 190, 1],
    [2548, 2553, 1],
    [2930, 2935, 1],
    [3056, 3058, 1],
    [3192, 3198, 1],
    [3416, 3422, 1],
    [3440, 3448, 1],
    [3882, 3891, 1],
    [4969, 4988, 1],
    [6128, 6137, 1],
    [6618, 8304, 1686],
    [8308, 8313, 1],
    [8320, 8329, 1],
    [8528, 8543, 1],
    [8585, 9312, 727],
    [9313, 9371, 1],
    [9450, 9471, 1],
    [10102, 10131, 1],
    [11517, 12690, 1173],
    [12691, 12693, 1],
    [12832, 12841, 1],
    [12872, 12879, 1],
    [12881, 12895, 1],
    [12928, 12937, 1],
    [12977, 12991, 1],
    [43056, 43061, 1],
    [65799, 65843, 1],
    [65909, 65912, 1],
    [65930, 65931, 1],
    [66273, 66299, 1],
    [66336, 66339, 1],
    [67672, 67679, 1],
    [67705, 67711, 1],
    [67751, 67759, 1],
    [67835, 67839, 1],
    [67862, 67867, 1],
    [68028, 68029, 1],
    [68032, 68047, 1],
    [68050, 68095, 1],
    [68160, 68168, 1],
    [68221, 68222, 1],
    [68253, 68255, 1],
    [68331, 68335, 1],
    [68440, 68447, 1],
    [68472, 68479, 1],
    [68521, 68527, 1],
    [68858, 68863, 1],
    [69216, 69246, 1],
    [69405, 69414, 1],
    [69457, 69460, 1],
    [69573, 69579, 1],
    [69714, 69733, 1],
    [70113, 70132, 1],
    [71482, 71483, 1],
    [71914, 71922, 1],
    [72794, 72812, 1],
    [73664, 73684, 1],
    [93019, 93025, 1],
    [93824, 93846, 1],
    [119488, 119507, 1],
    [119520, 119539, 1],
    [119648, 119672, 1],
    [125127, 125135, 1],
    [126065, 126123, 1],
    [126125, 126127, 1],
    [126129, 126132, 1],
    [126209, 126253, 1],
    [126255, 126269, 1],
    [127232, 127244, 1]
  ]
  static P = [
    [33, 35, 1],
    [37, 42, 1],
    [44, 47, 1],
    [58, 59, 1],
    [63, 64, 1],
    [91, 93, 1],
    [95, 123, 28],
    [125, 161, 36],
    [167, 171, 4],
    [182, 183, 1],
    [187, 191, 4],
    [894, 903, 9],
    [1370, 1375, 1],
    [1417, 1418, 1],
    [1470, 1472, 2],
    [1475, 1478, 3],
    [1523, 1524, 1],
    [1545, 1546, 1],
    [1548, 1549, 1],
    [1563, 1565, 2],
    [1566, 1567, 1],
    [1642, 1645, 1],
    [1748, 1792, 44],
    [1793, 1805, 1],
    [2039, 2041, 1],
    [2096, 2110, 1],
    [2142, 2404, 262],
    [2405, 2416, 11],
    [2557, 2678, 121],
    [2800, 3191, 391],
    [3204, 3572, 368],
    [3663, 3674, 11],
    [3675, 3844, 169],
    [3845, 3858, 1],
    [3860, 3898, 38],
    [3899, 3901, 1],
    [3973, 4048, 75],
    [4049, 4052, 1],
    [4057, 4058, 1],
    [4170, 4175, 1],
    [4347, 4960, 613],
    [4961, 4968, 1],
    [5120, 5742, 622],
    [5787, 5788, 1],
    [5867, 5869, 1],
    [5941, 5942, 1],
    [6100, 6102, 1],
    [6104, 6106, 1],
    [6144, 6154, 1],
    [6468, 6469, 1],
    [6686, 6687, 1],
    [6816, 6822, 1],
    [6824, 6829, 1],
    [7002, 7008, 1],
    [7037, 7038, 1],
    [7164, 7167, 1],
    [7227, 7231, 1],
    [7294, 7295, 1],
    [7360, 7367, 1],
    [7379, 8208, 829],
    [8209, 8231, 1],
    [8240, 8259, 1],
    [8261, 8273, 1],
    [8275, 8286, 1],
    [8317, 8318, 1],
    [8333, 8334, 1],
    [8968, 8971, 1],
    [9001, 9002, 1],
    [10088, 10101, 1],
    [10181, 10182, 1],
    [10214, 10223, 1],
    [10627, 10648, 1],
    [10712, 10715, 1],
    [10748, 10749, 1],
    [11513, 11516, 1],
    [11518, 11519, 1],
    [11632, 11776, 144],
    [11777, 11822, 1],
    [11824, 11855, 1],
    [11858, 11869, 1],
    [12289, 12291, 1],
    [12296, 12305, 1],
    [12308, 12319, 1],
    [12336, 12349, 13],
    [12448, 12539, 91],
    [42238, 42239, 1],
    [42509, 42511, 1],
    [42611, 42622, 11],
    [42738, 42743, 1],
    [43124, 43127, 1],
    [43214, 43215, 1],
    [43256, 43258, 1],
    [43260, 43310, 50],
    [43311, 43359, 48],
    [43457, 43469, 1],
    [43486, 43487, 1],
    [43612, 43615, 1],
    [43742, 43743, 1],
    [43760, 43761, 1],
    [44011, 64830, 20819],
    [64831, 65040, 209],
    [65041, 65049, 1],
    [65072, 65106, 1],
    [65108, 65121, 1],
    [65123, 65128, 5],
    [65130, 65131, 1],
    [65281, 65283, 1],
    [65285, 65290, 1],
    [65292, 65295, 1],
    [65306, 65307, 1],
    [65311, 65312, 1],
    [65339, 65341, 1],
    [65343, 65371, 28],
    [65373, 65375, 2],
    [65376, 65381, 1],
    [65792, 65794, 1],
    [66463, 66512, 49],
    [66927, 67671, 744],
    [67871, 67903, 32],
    [68176, 68184, 1],
    [68223, 68336, 113],
    [68337, 68342, 1],
    [68409, 68415, 1],
    [68505, 68508, 1],
    [69293, 69461, 168],
    [69462, 69465, 1],
    [69510, 69513, 1],
    [69703, 69709, 1],
    [69819, 69820, 1],
    [69822, 69825, 1],
    [69952, 69955, 1],
    [70004, 70005, 1],
    [70085, 70088, 1],
    [70093, 70107, 14],
    [70109, 70111, 1],
    [70200, 70205, 1],
    [70313, 70731, 418],
    [70732, 70735, 1],
    [70746, 70747, 1],
    [70749, 70854, 105],
    [71105, 71127, 1],
    [71233, 71235, 1],
    [71264, 71276, 1],
    [71353, 71484, 131],
    [71485, 71486, 1],
    [71739, 72004, 265],
    [72005, 72006, 1],
    [72162, 72255, 93],
    [72256, 72262, 1],
    [72346, 72348, 1],
    [72350, 72354, 1],
    [72448, 72457, 1],
    [72769, 72773, 1],
    [72816, 72817, 1],
    [73463, 73464, 1],
    [73539, 73551, 1],
    [73727, 74864, 1137],
    [74865, 74868, 1],
    [77809, 77810, 1],
    [92782, 92783, 1],
    [92917, 92983, 66],
    [92984, 92987, 1],
    [92996, 93847, 851],
    [93848, 93850, 1],
    [94178, 113823, 19645],
    [121479, 121483, 1],
    [125278, 125279, 1]
  ]
  static Pc = [
    [95, 8255, 8160],
    [8256, 8276, 20],
    [65075, 65076, 1],
    [65101, 65103, 1],
    [65343, 65343, 1]
  ]
  static Pd = [
    [45, 1418, 1373],
    [1470, 5120, 3650],
    [6150, 8208, 2058],
    [8209, 8213, 1],
    [11799, 11802, 3],
    [11834, 11835, 1],
    [11840, 11869, 29],
    [12316, 12336, 20],
    [12448, 65073, 52625],
    [65074, 65112, 38],
    [65123, 65293, 170],
    [69293, 69293, 1]
  ]
  static Pe = [
    [41, 93, 52],
    [125, 3899, 3774],
    [3901, 5788, 1887],
    [8262, 8318, 56],
    [8334, 8969, 635],
    [8971, 9002, 31],
    [10089, 10101, 2],
    [10182, 10215, 33],
    [10217, 10223, 2],
    [10628, 10648, 2],
    [10713, 10715, 2],
    [10749, 11811, 1062],
    [11813, 11817, 2],
    [11862, 11868, 2],
    [12297, 12305, 2],
    [12309, 12315, 2],
    [12318, 12319, 1],
    [64830, 65048, 218],
    [65078, 65092, 2],
    [65096, 65114, 18],
    [65116, 65118, 2],
    [65289, 65341, 52],
    [65373, 65379, 3]
  ]
  static Pf = [
    [187, 8217, 8030],
    [8221, 8250, 29],
    [11779, 11781, 2],
    [11786, 11789, 3],
    [11805, 11809, 4]
  ]
  static Pi = [
    [171, 8216, 8045],
    [8219, 8220, 1],
    [8223, 8249, 26],
    [11778, 11780, 2],
    [11785, 11788, 3],
    [11804, 11808, 4]
  ]
  static Po = [
    [33, 35, 1],
    [37, 39, 1],
    [42, 46, 2],
    [47, 58, 11],
    [59, 63, 4],
    [64, 92, 28],
    [161, 167, 6],
    [182, 183, 1],
    [191, 894, 703],
    [903, 1370, 467],
    [1371, 1375, 1],
    [1417, 1472, 55],
    [1475, 1478, 3],
    [1523, 1524, 1],
    [1545, 1546, 1],
    [1548, 1549, 1],
    [1563, 1565, 2],
    [1566, 1567, 1],
    [1642, 1645, 1],
    [1748, 1792, 44],
    [1793, 1805, 1],
    [2039, 2041, 1],
    [2096, 2110, 1],
    [2142, 2404, 262],
    [2405, 2416, 11],
    [2557, 2678, 121],
    [2800, 3191, 391],
    [3204, 3572, 368],
    [3663, 3674, 11],
    [3675, 3844, 169],
    [3845, 3858, 1],
    [3860, 3973, 113],
    [4048, 4052, 1],
    [4057, 4058, 1],
    [4170, 4175, 1],
    [4347, 4960, 613],
    [4961, 4968, 1],
    [5742, 5867, 125],
    [5868, 5869, 1],
    [5941, 5942, 1],
    [6100, 6102, 1],
    [6104, 6106, 1],
    [6144, 6149, 1],
    [6151, 6154, 1],
    [6468, 6469, 1],
    [6686, 6687, 1],
    [6816, 6822, 1],
    [6824, 6829, 1],
    [7002, 7008, 1],
    [7037, 7038, 1],
    [7164, 7167, 1],
    [7227, 7231, 1],
    [7294, 7295, 1],
    [7360, 7367, 1],
    [7379, 8214, 835],
    [8215, 8224, 9],
    [8225, 8231, 1],
    [8240, 8248, 1],
    [8251, 8254, 1],
    [8257, 8259, 1],
    [8263, 8273, 1],
    [8275, 8277, 2],
    [8278, 8286, 1],
    [11513, 11516, 1],
    [11518, 11519, 1],
    [11632, 11776, 144],
    [11777, 11782, 5],
    [11783, 11784, 1],
    [11787, 11790, 3],
    [11791, 11798, 1],
    [11800, 11801, 1],
    [11803, 11806, 3],
    [11807, 11818, 11],
    [11819, 11822, 1],
    [11824, 11833, 1],
    [11836, 11839, 1],
    [11841, 11843, 2],
    [11844, 11855, 1],
    [11858, 11860, 1],
    [12289, 12291, 1],
    [12349, 12539, 190],
    [42238, 42239, 1],
    [42509, 42511, 1],
    [42611, 42622, 11],
    [42738, 42743, 1],
    [43124, 43127, 1],
    [43214, 43215, 1],
    [43256, 43258, 1],
    [43260, 43310, 50],
    [43311, 43359, 48],
    [43457, 43469, 1],
    [43486, 43487, 1],
    [43612, 43615, 1],
    [43742, 43743, 1],
    [43760, 43761, 1],
    [44011, 65040, 21029],
    [65041, 65046, 1],
    [65049, 65072, 23],
    [65093, 65094, 1],
    [65097, 65100, 1],
    [65104, 65106, 1],
    [65108, 65111, 1],
    [65119, 65121, 1],
    [65128, 65130, 2],
    [65131, 65281, 150],
    [65282, 65283, 1],
    [65285, 65287, 1],
    [65290, 65294, 2],
    [65295, 65306, 11],
    [65307, 65311, 4],
    [65312, 65340, 28],
    [65377, 65380, 3],
    [65381, 65792, 411],
    [65793, 65794, 1],
    [66463, 66512, 49],
    [66927, 67671, 744],
    [67871, 67903, 32],
    [68176, 68184, 1],
    [68223, 68336, 113],
    [68337, 68342, 1],
    [68409, 68415, 1],
    [68505, 68508, 1],
    [69461, 69465, 1],
    [69510, 69513, 1],
    [69703, 69709, 1],
    [69819, 69820, 1],
    [69822, 69825, 1],
    [69952, 69955, 1],
    [70004, 70005, 1],
    [70085, 70088, 1],
    [70093, 70107, 14],
    [70109, 70111, 1],
    [70200, 70205, 1],
    [70313, 70731, 418],
    [70732, 70735, 1],
    [70746, 70747, 1],
    [70749, 70854, 105],
    [71105, 71127, 1],
    [71233, 71235, 1],
    [71264, 71276, 1],
    [71353, 71484, 131],
    [71485, 71486, 1],
    [71739, 72004, 265],
    [72005, 72006, 1],
    [72162, 72255, 93],
    [72256, 72262, 1],
    [72346, 72348, 1],
    [72350, 72354, 1],
    [72448, 72457, 1],
    [72769, 72773, 1],
    [72816, 72817, 1],
    [73463, 73464, 1],
    [73539, 73551, 1],
    [73727, 74864, 1137],
    [74865, 74868, 1],
    [77809, 77810, 1],
    [92782, 92783, 1],
    [92917, 92983, 66],
    [92984, 92987, 1],
    [92996, 93847, 851],
    [93848, 93850, 1],
    [94178, 113823, 19645],
    [121479, 121483, 1],
    [125278, 125279, 1]
  ]
  static Ps = [
    [40, 91, 51],
    [123, 3898, 3775],
    [3900, 5787, 1887],
    [8218, 8222, 4],
    [8261, 8317, 56],
    [8333, 8968, 635],
    [8970, 9001, 31],
    [10088, 10100, 2],
    [10181, 10214, 33],
    [10216, 10222, 2],
    [10627, 10647, 2],
    [10712, 10714, 2],
    [10748, 11810, 1062],
    [11812, 11816, 2],
    [11842, 11861, 19],
    [11863, 11867, 2],
    [12296, 12304, 2],
    [12308, 12314, 2],
    [12317, 64831, 52514],
    [65047, 65077, 30],
    [65079, 65091, 2],
    [65095, 65113, 18],
    [65115, 65117, 2],
    [65288, 65339, 51],
    [65371, 65375, 4],
    [65378, 65378, 1]
  ]
  static S = [
    [36, 43, 7],
    [60, 62, 1],
    [94, 96, 2],
    [124, 126, 2],
    [162, 166, 1],
    [168, 169, 1],
    [172, 174, 2],
    [175, 177, 1],
    [180, 184, 4],
    [215, 247, 32],
    [706, 709, 1],
    [722, 735, 1],
    [741, 747, 1],
    [749, 751, 2],
    [752, 767, 1],
    [885, 900, 15],
    [901, 1014, 113],
    [1154, 1421, 267],
    [1422, 1423, 1],
    [1542, 1544, 1],
    [1547, 1550, 3],
    [1551, 1758, 207],
    [1769, 1789, 20],
    [1790, 2038, 248],
    [2046, 2047, 1],
    [2184, 2546, 362],
    [2547, 2554, 7],
    [2555, 2801, 246],
    [2928, 3059, 131],
    [3060, 3066, 1],
    [3199, 3407, 208],
    [3449, 3647, 198],
    [3841, 3843, 1],
    [3859, 3861, 2],
    [3862, 3863, 1],
    [3866, 3871, 1],
    [3892, 3896, 2],
    [4030, 4037, 1],
    [4039, 4044, 1],
    [4046, 4047, 1],
    [4053, 4056, 1],
    [4254, 4255, 1],
    [5008, 5017, 1],
    [5741, 6107, 366],
    [6464, 6622, 158],
    [6623, 6655, 1],
    [7009, 7018, 1],
    [7028, 7036, 1],
    [8125, 8127, 2],
    [8128, 8129, 1],
    [8141, 8143, 1],
    [8157, 8159, 1],
    [8173, 8175, 1],
    [8189, 8190, 1],
    [8260, 8274, 14],
    [8314, 8316, 1],
    [8330, 8332, 1],
    [8352, 8384, 1],
    [8448, 8449, 1],
    [8451, 8454, 1],
    [8456, 8457, 1],
    [8468, 8470, 2],
    [8471, 8472, 1],
    [8478, 8483, 1],
    [8485, 8489, 2],
    [8494, 8506, 12],
    [8507, 8512, 5],
    [8513, 8516, 1],
    [8522, 8525, 1],
    [8527, 8586, 59],
    [8587, 8592, 5],
    [8593, 8967, 1],
    [8972, 9000, 1],
    [9003, 9254, 1],
    [9280, 9290, 1],
    [9372, 9449, 1],
    [9472, 10087, 1],
    [10132, 10180, 1],
    [10183, 10213, 1],
    [10224, 10626, 1],
    [10649, 10711, 1],
    [10716, 10747, 1],
    [10750, 11123, 1],
    [11126, 11157, 1],
    [11159, 11263, 1],
    [11493, 11498, 1],
    [11856, 11857, 1],
    [11904, 11929, 1],
    [11931, 12019, 1],
    [12032, 12245, 1],
    [12272, 12287, 1],
    [12292, 12306, 14],
    [12307, 12320, 13],
    [12342, 12343, 1],
    [12350, 12351, 1],
    [12443, 12444, 1],
    [12688, 12689, 1],
    [12694, 12703, 1],
    [12736, 12771, 1],
    [12783, 12800, 17],
    [12801, 12830, 1],
    [12842, 12871, 1],
    [12880, 12896, 16],
    [12897, 12927, 1],
    [12938, 12976, 1],
    [12992, 13311, 1],
    [19904, 19967, 1],
    [42128, 42182, 1],
    [42752, 42774, 1],
    [42784, 42785, 1],
    [42889, 42890, 1],
    [43048, 43051, 1],
    [43062, 43065, 1],
    [43639, 43641, 1],
    [43867, 43882, 15],
    [43883, 64297, 20414],
    [64434, 64450, 1],
    [64832, 64847, 1],
    [64975, 65020, 45],
    [65021, 65023, 1],
    [65122, 65124, 2],
    [65125, 65126, 1],
    [65129, 65284, 155],
    [65291, 65308, 17],
    [65309, 65310, 1],
    [65342, 65344, 2],
    [65372, 65374, 2],
    [65504, 65510, 1],
    [65512, 65518, 1],
    [65532, 65533, 1],
    [65847, 65855, 1],
    [65913, 65929, 1],
    [65932, 65934, 1],
    [65936, 65948, 1],
    [65952, 66000, 48],
    [66001, 66044, 1],
    [67703, 67704, 1],
    [68296, 71487, 3191],
    [73685, 73713, 1],
    [92988, 92991, 1],
    [92997, 113820, 20823],
    [118608, 118723, 1],
    [118784, 119029, 1],
    [119040, 119078, 1],
    [119081, 119140, 1],
    [119146, 119148, 1],
    [119171, 119172, 1],
    [119180, 119209, 1],
    [119214, 119274, 1],
    [119296, 119361, 1],
    [119365, 119552, 187],
    [119553, 119638, 1],
    [120513, 120539, 26],
    [120571, 120597, 26],
    [120629, 120655, 26],
    [120687, 120713, 26],
    [120745, 120771, 26],
    [120832, 121343, 1],
    [121399, 121402, 1],
    [121453, 121460, 1],
    [121462, 121475, 1],
    [121477, 121478, 1],
    [123215, 123647, 432],
    [126124, 126128, 4],
    [126254, 126704, 450],
    [126705, 126976, 271],
    [126977, 127019, 1],
    [127024, 127123, 1],
    [127136, 127150, 1],
    [127153, 127167, 1],
    [127169, 127183, 1],
    [127185, 127221, 1],
    [127245, 127405, 1],
    [127462, 127490, 1],
    [127504, 127547, 1],
    [127552, 127560, 1],
    [127568, 127569, 1],
    [127584, 127589, 1],
    [127744, 128727, 1],
    [128732, 128748, 1],
    [128752, 128764, 1],
    [128768, 128886, 1],
    [128891, 128985, 1],
    [128992, 129003, 1],
    [129008, 129024, 16],
    [129025, 129035, 1],
    [129040, 129095, 1],
    [129104, 129113, 1],
    [129120, 129159, 1],
    [129168, 129197, 1],
    [129200, 129201, 1],
    [129280, 129619, 1],
    [129632, 129645, 1],
    [129648, 129660, 1],
    [129664, 129672, 1],
    [129680, 129725, 1],
    [129727, 129733, 1],
    [129742, 129755, 1],
    [129760, 129768, 1],
    [129776, 129784, 1],
    [129792, 129938, 1],
    [129940, 129994, 1]
  ]
  static Sc = [
    [36, 162, 126],
    [163, 165, 1],
    [1423, 1547, 124],
    [2046, 2047, 1],
    [2546, 2547, 1],
    [2555, 2801, 246],
    [3065, 3647, 582],
    [6107, 8352, 2245],
    [8353, 8384, 1],
    [43064, 65020, 21956],
    [65129, 65284, 155],
    [65504, 65505, 1],
    [65509, 65510, 1],
    [73693, 73696, 1],
    [123647, 126128, 2481]
  ]
  static Sk = [
    [94, 96, 2],
    [168, 175, 7],
    [180, 184, 4],
    [706, 709, 1],
    [722, 735, 1],
    [741, 747, 1],
    [749, 751, 2],
    [752, 767, 1],
    [885, 900, 15],
    [901, 2184, 1283],
    [8125, 8127, 2],
    [8128, 8129, 1],
    [8141, 8143, 1],
    [8157, 8159, 1],
    [8173, 8175, 1],
    [8189, 8190, 1],
    [12443, 12444, 1],
    [42752, 42774, 1],
    [42784, 42785, 1],
    [42889, 42890, 1],
    [43867, 43882, 15],
    [43883, 64434, 20551],
    [64435, 64450, 1],
    [65342, 65344, 2],
    [65507, 127995, 62488],
    [127996, 127999, 1]
  ]
  static Sm = [
    [43, 60, 17],
    [61, 62, 1],
    [124, 126, 2],
    [172, 177, 5],
    [215, 247, 32],
    [1014, 1542, 528],
    [1543, 1544, 1],
    [8260, 8274, 14],
    [8314, 8316, 1],
    [8330, 8332, 1],
    [8472, 8512, 40],
    [8513, 8516, 1],
    [8523, 8592, 69],
    [8593, 8596, 1],
    [8602, 8603, 1],
    [8608, 8614, 3],
    [8622, 8654, 32],
    [8655, 8658, 3],
    [8660, 8692, 32],
    [8693, 8959, 1],
    [8992, 8993, 1],
    [9084, 9115, 31],
    [9116, 9139, 1],
    [9180, 9185, 1],
    [9655, 9665, 10],
    [9720, 9727, 1],
    [9839, 10176, 337],
    [10177, 10180, 1],
    [10183, 10213, 1],
    [10224, 10239, 1],
    [10496, 10626, 1],
    [10649, 10711, 1],
    [10716, 10747, 1],
    [10750, 11007, 1],
    [11056, 11076, 1],
    [11079, 11084, 1],
    [64297, 65122, 825],
    [65124, 65126, 1],
    [65291, 65308, 17],
    [65309, 65310, 1],
    [65372, 65374, 2],
    [65506, 65513, 7],
    [65514, 65516, 1],
    [120513, 120539, 26],
    [120571, 120597, 26],
    [120629, 120655, 26],
    [120687, 120713, 26],
    [120745, 120771, 26],
    [126704, 126705, 1]
  ]
  static So = [
    [166, 169, 3],
    [174, 176, 2],
    [1154, 1421, 267],
    [1422, 1550, 128],
    [1551, 1758, 207],
    [1769, 1789, 20],
    [1790, 2038, 248],
    [2554, 2928, 374],
    [3059, 3064, 1],
    [3066, 3199, 133],
    [3407, 3449, 42],
    [3841, 3843, 1],
    [3859, 3861, 2],
    [3862, 3863, 1],
    [3866, 3871, 1],
    [3892, 3896, 2],
    [4030, 4037, 1],
    [4039, 4044, 1],
    [4046, 4047, 1],
    [4053, 4056, 1],
    [4254, 4255, 1],
    [5008, 5017, 1],
    [5741, 6464, 723],
    [6622, 6655, 1],
    [7009, 7018, 1],
    [7028, 7036, 1],
    [8448, 8449, 1],
    [8451, 8454, 1],
    [8456, 8457, 1],
    [8468, 8470, 2],
    [8471, 8478, 7],
    [8479, 8483, 1],
    [8485, 8489, 2],
    [8494, 8506, 12],
    [8507, 8522, 15],
    [8524, 8525, 1],
    [8527, 8586, 59],
    [8587, 8597, 10],
    [8598, 8601, 1],
    [8604, 8607, 1],
    [8609, 8610, 1],
    [8612, 8613, 1],
    [8615, 8621, 1],
    [8623, 8653, 1],
    [8656, 8657, 1],
    [8659, 8661, 2],
    [8662, 8691, 1],
    [8960, 8967, 1],
    [8972, 8991, 1],
    [8994, 9000, 1],
    [9003, 9083, 1],
    [9085, 9114, 1],
    [9140, 9179, 1],
    [9186, 9254, 1],
    [9280, 9290, 1],
    [9372, 9449, 1],
    [9472, 9654, 1],
    [9656, 9664, 1],
    [9666, 9719, 1],
    [9728, 9838, 1],
    [9840, 10087, 1],
    [10132, 10175, 1],
    [10240, 10495, 1],
    [11008, 11055, 1],
    [11077, 11078, 1],
    [11085, 11123, 1],
    [11126, 11157, 1],
    [11159, 11263, 1],
    [11493, 11498, 1],
    [11856, 11857, 1],
    [11904, 11929, 1],
    [11931, 12019, 1],
    [12032, 12245, 1],
    [12272, 12287, 1],
    [12292, 12306, 14],
    [12307, 12320, 13],
    [12342, 12343, 1],
    [12350, 12351, 1],
    [12688, 12689, 1],
    [12694, 12703, 1],
    [12736, 12771, 1],
    [12783, 12800, 17],
    [12801, 12830, 1],
    [12842, 12871, 1],
    [12880, 12896, 16],
    [12897, 12927, 1],
    [12938, 12976, 1],
    [12992, 13311, 1],
    [19904, 19967, 1],
    [42128, 42182, 1],
    [43048, 43051, 1],
    [43062, 43063, 1],
    [43065, 43639, 574],
    [43640, 43641, 1],
    [64832, 64847, 1],
    [64975, 65021, 46],
    [65022, 65023, 1],
    [65508, 65512, 4],
    [65517, 65518, 1],
    [65532, 65533, 1],
    [65847, 65855, 1],
    [65913, 65929, 1],
    [65932, 65934, 1],
    [65936, 65948, 1],
    [65952, 66000, 48],
    [66001, 66044, 1],
    [67703, 67704, 1],
    [68296, 71487, 3191],
    [73685, 73692, 1],
    [73697, 73713, 1],
    [92988, 92991, 1],
    [92997, 113820, 20823],
    [118608, 118723, 1],
    [118784, 119029, 1],
    [119040, 119078, 1],
    [119081, 119140, 1],
    [119146, 119148, 1],
    [119171, 119172, 1],
    [119180, 119209, 1],
    [119214, 119274, 1],
    [119296, 119361, 1],
    [119365, 119552, 187],
    [119553, 119638, 1],
    [120832, 121343, 1],
    [121399, 121402, 1],
    [121453, 121460, 1],
    [121462, 121475, 1],
    [121477, 121478, 1],
    [123215, 126124, 2909],
    [126254, 126976, 722],
    [126977, 127019, 1],
    [127024, 127123, 1],
    [127136, 127150, 1],
    [127153, 127167, 1],
    [127169, 127183, 1],
    [127185, 127221, 1],
    [127245, 127405, 1],
    [127462, 127490, 1],
    [127504, 127547, 1],
    [127552, 127560, 1],
    [127568, 127569, 1],
    [127584, 127589, 1],
    [127744, 127994, 1],
    [128000, 128727, 1],
    [128732, 128748, 1],
    [128752, 128764, 1],
    [128768, 128886, 1],
    [128891, 128985, 1],
    [128992, 129003, 1],
    [129008, 129024, 16],
    [129025, 129035, 1],
    [129040, 129095, 1],
    [129104, 129113, 1],
    [129120, 129159, 1],
    [129168, 129197, 1],
    [129200, 129201, 1],
    [129280, 129619, 1],
    [129632, 129645, 1],
    [129648, 129660, 1],
    [129664, 129672, 1],
    [129680, 129725, 1],
    [129727, 129733, 1],
    [129742, 129755, 1],
    [129760, 129768, 1],
    [129776, 129784, 1],
    [129792, 129938, 1],
    [129940, 129994, 1]
  ]
  static Z = [
    [32, 160, 128],
    [5760, 8192, 2432],
    [8193, 8202, 1],
    [8232, 8233, 1],
    [8239, 8287, 48],
    [12288, 12288, 1]
  ]
  static Zl = [[8232, 8232, 1]]
  static Zp = [[8233, 8233, 1]]
  static Zs = [
    [32, 160, 128],
    [5760, 8192, 2432],
    [8193, 8202, 1],
    [8239, 8287, 48],
    [12288, 12288, 1]
  ]
  static Adlam = [
    [125184, 125259, 1],
    [125264, 125273, 1],
    [125278, 125279, 1]
  ]
  static Ahom = [
    [71424, 71450, 1],
    [71453, 71467, 1],
    [71472, 71494, 1]
  ]
  static Anatolian_Hieroglyphs = [[82944, 83526, 1]]
  static Arabic = [
    [1536, 1540, 1],
    [1542, 1547, 1],
    [1549, 1562, 1],
    [1564, 1566, 1],
    [1568, 1599, 1],
    [1601, 1610, 1],
    [1622, 1647, 1],
    [1649, 1756, 1],
    [1758, 1791, 1],
    [1872, 1919, 1],
    [2160, 2190, 1],
    [2192, 2193, 1],
    [2200, 2273, 1],
    [2275, 2303, 1],
    [64336, 64450, 1],
    [64467, 64829, 1],
    [64832, 64911, 1],
    [64914, 64967, 1],
    [64975, 65008, 33],
    [65009, 65023, 1],
    [65136, 65140, 1],
    [65142, 65276, 1],
    [69216, 69246, 1],
    [69373, 69375, 1],
    [126464, 126467, 1],
    [126469, 126495, 1],
    [126497, 126498, 1],
    [126500, 126503, 3],
    [126505, 126514, 1],
    [126516, 126519, 1],
    [126521, 126523, 2],
    [126530, 126535, 5],
    [126537, 126541, 2],
    [126542, 126543, 1],
    [126545, 126546, 1],
    [126548, 126551, 3],
    [126553, 126561, 2],
    [126562, 126564, 2],
    [126567, 126570, 1],
    [126572, 126578, 1],
    [126580, 126583, 1],
    [126585, 126588, 1],
    [126590, 126592, 2],
    [126593, 126601, 1],
    [126603, 126619, 1],
    [126625, 126627, 1],
    [126629, 126633, 1],
    [126635, 126651, 1],
    [126704, 126705, 1]
  ]
  static Armenian = [
    [1329, 1366, 1],
    [1369, 1418, 1],
    [1421, 1423, 1],
    [64275, 64279, 1]
  ]
  static Avestan = [
    [68352, 68405, 1],
    [68409, 68415, 1]
  ]
  static Balinese = [
    [6912, 6988, 1],
    [6992, 7038, 1]
  ]
  static Bamum = [
    [42656, 42743, 1],
    [92160, 92728, 1]
  ]
  static Bassa_Vah = [
    [92880, 92909, 1],
    [92912, 92917, 1]
  ]
  static Batak = [
    [7104, 7155, 1],
    [7164, 7167, 1]
  ]
  static Bengali = [
    [2432, 2435, 1],
    [2437, 2444, 1],
    [2447, 2448, 1],
    [2451, 2472, 1],
    [2474, 2480, 1],
    [2482, 2486, 4],
    [2487, 2489, 1],
    [2492, 2500, 1],
    [2503, 2504, 1],
    [2507, 2510, 1],
    [2519, 2524, 5],
    [2525, 2527, 2],
    [2528, 2531, 1],
    [2534, 2558, 1]
  ]
  static Bhaiksuki = [
    [72704, 72712, 1],
    [72714, 72758, 1],
    [72760, 72773, 1],
    [72784, 72812, 1]
  ]
  static Bopomofo = [
    [746, 747, 1],
    [12549, 12591, 1],
    [12704, 12735, 1]
  ]
  static Brahmi = [
    [69632, 69709, 1],
    [69714, 69749, 1],
    [69759, 69759, 1]
  ]
  static Braille = [[10240, 10495, 1]]
  static Buginese = [
    [6656, 6683, 1],
    [6686, 6687, 1]
  ]
  static Buhid = [[5952, 5971, 1]]
  static Canadian_Aboriginal = [
    [5120, 5759, 1],
    [6320, 6389, 1],
    [72368, 72383, 1]
  ]
  static Carian = [[66208, 66256, 1]]
  static Caucasian_Albanian = [
    [66864, 66915, 1],
    [66927, 66927, 1]
  ]
  static Chakma = [
    [69888, 69940, 1],
    [69942, 69959, 1]
  ]
  static Cham = [
    [43520, 43574, 1],
    [43584, 43597, 1],
    [43600, 43609, 1],
    [43612, 43615, 1]
  ]
  static Cherokee = [
    [5024, 5109, 1],
    [5112, 5117, 1],
    [43888, 43967, 1]
  ]
  static Chorasmian = [[69552, 69579, 1]]
  static Common = [
    [0, 64, 1],
    [91, 96, 1],
    [123, 169, 1],
    [171, 185, 1],
    [187, 191, 1],
    [215, 247, 32],
    [697, 735, 1],
    [741, 745, 1],
    [748, 767, 1],
    [884, 894, 10],
    [901, 903, 2],
    [1541, 1548, 7],
    [1563, 1567, 4],
    [1600, 1757, 157],
    [2274, 2404, 130],
    [2405, 3647, 1242],
    [4053, 4056, 1],
    [4347, 5867, 1520],
    [5868, 5869, 1],
    [5941, 5942, 1],
    [6146, 6147, 1],
    [6149, 7379, 1230],
    [7393, 7401, 8],
    [7402, 7404, 1],
    [7406, 7411, 1],
    [7413, 7415, 1],
    [7418, 8192, 774],
    [8193, 8203, 1],
    [8206, 8292, 1],
    [8294, 8304, 1],
    [8308, 8318, 1],
    [8320, 8334, 1],
    [8352, 8384, 1],
    [8448, 8485, 1],
    [8487, 8489, 1],
    [8492, 8497, 1],
    [8499, 8525, 1],
    [8527, 8543, 1],
    [8585, 8587, 1],
    [8592, 9254, 1],
    [9280, 9290, 1],
    [9312, 10239, 1],
    [10496, 11123, 1],
    [11126, 11157, 1],
    [11159, 11263, 1],
    [11776, 11869, 1],
    [12272, 12292, 1],
    [12294, 12296, 2],
    [12297, 12320, 1],
    [12336, 12343, 1],
    [12348, 12351, 1],
    [12443, 12444, 1],
    [12448, 12539, 91],
    [12540, 12688, 148],
    [12689, 12703, 1],
    [12736, 12771, 1],
    [12783, 12832, 49],
    [12833, 12895, 1],
    [12927, 13007, 1],
    [13055, 13144, 89],
    [13145, 13311, 1],
    [19904, 19967, 1],
    [42752, 42785, 1],
    [42888, 42890, 1],
    [43056, 43065, 1],
    [43310, 43471, 161],
    [43867, 43882, 15],
    [43883, 64830, 20947],
    [64831, 65040, 209],
    [65041, 65049, 1],
    [65072, 65106, 1],
    [65108, 65126, 1],
    [65128, 65131, 1],
    [65279, 65281, 2],
    [65282, 65312, 1],
    [65339, 65344, 1],
    [65371, 65381, 1],
    [65392, 65438, 46],
    [65439, 65504, 65],
    [65505, 65510, 1],
    [65512, 65518, 1],
    [65529, 65533, 1],
    [65792, 65794, 1],
    [65799, 65843, 1],
    [65847, 65855, 1],
    [65936, 65948, 1],
    [66000, 66044, 1],
    [66273, 66299, 1],
    [113824, 113827, 1],
    [118608, 118723, 1],
    [118784, 119029, 1],
    [119040, 119078, 1],
    [119081, 119142, 1],
    [119146, 119162, 1],
    [119171, 119172, 1],
    [119180, 119209, 1],
    [119214, 119274, 1],
    [119488, 119507, 1],
    [119520, 119539, 1],
    [119552, 119638, 1],
    [119648, 119672, 1],
    [119808, 119892, 1],
    [119894, 119964, 1],
    [119966, 119967, 1],
    [119970, 119973, 3],
    [119974, 119977, 3],
    [119978, 119980, 1],
    [119982, 119993, 1],
    [119995, 119997, 2],
    [119998, 120003, 1],
    [120005, 120069, 1],
    [120071, 120074, 1],
    [120077, 120084, 1],
    [120086, 120092, 1],
    [120094, 120121, 1],
    [120123, 120126, 1],
    [120128, 120132, 1],
    [120134, 120138, 4],
    [120139, 120144, 1],
    [120146, 120485, 1],
    [120488, 120779, 1],
    [120782, 120831, 1],
    [126065, 126132, 1],
    [126209, 126269, 1],
    [126976, 127019, 1],
    [127024, 127123, 1],
    [127136, 127150, 1],
    [127153, 127167, 1],
    [127169, 127183, 1],
    [127185, 127221, 1],
    [127232, 127405, 1],
    [127462, 127487, 1],
    [127489, 127490, 1],
    [127504, 127547, 1],
    [127552, 127560, 1],
    [127568, 127569, 1],
    [127584, 127589, 1],
    [127744, 128727, 1],
    [128732, 128748, 1],
    [128752, 128764, 1],
    [128768, 128886, 1],
    [128891, 128985, 1],
    [128992, 129003, 1],
    [129008, 129024, 16],
    [129025, 129035, 1],
    [129040, 129095, 1],
    [129104, 129113, 1],
    [129120, 129159, 1],
    [129168, 129197, 1],
    [129200, 129201, 1],
    [129280, 129619, 1],
    [129632, 129645, 1],
    [129648, 129660, 1],
    [129664, 129672, 1],
    [129680, 129725, 1],
    [129727, 129733, 1],
    [129742, 129755, 1],
    [129760, 129768, 1],
    [129776, 129784, 1],
    [129792, 129938, 1],
    [129940, 129994, 1],
    [130032, 130041, 1],
    [917505, 917536, 31],
    [917537, 917631, 1]
  ]
  static foldCommon = [[924, 956, 32]]
  static Coptic = [
    [994, 1007, 1],
    [11392, 11507, 1],
    [11513, 11519, 1]
  ]
  static Cuneiform = [
    [73728, 74649, 1],
    [74752, 74862, 1],
    [74864, 74868, 1],
    [74880, 75075, 1]
  ]
  static Cypriot = [
    [67584, 67589, 1],
    [67592, 67594, 2],
    [67595, 67637, 1],
    [67639, 67640, 1],
    [67644, 67647, 3]
  ]
  static Cypro_Minoan = [[77712, 77810, 1]]
  static Cyrillic = [
    [1024, 1156, 1],
    [1159, 1327, 1],
    [7296, 7304, 1],
    [7467, 7544, 77],
    [11744, 11775, 1],
    [42560, 42655, 1],
    [65070, 65071, 1],
    [122928, 122989, 1],
    [123023, 123023, 1]
  ]
  static Deseret = [[66560, 66639, 1]]
  static Devanagari = [
    [2304, 2384, 1],
    [2389, 2403, 1],
    [2406, 2431, 1],
    [43232, 43263, 1],
    [72448, 72457, 1]
  ]
  static Dives_Akuru = [
    [71936, 71942, 1],
    [71945, 71948, 3],
    [71949, 71955, 1],
    [71957, 71958, 1],
    [71960, 71989, 1],
    [71991, 71992, 1],
    [71995, 72006, 1],
    [72016, 72025, 1]
  ]
  static Dogra = [[71680, 71739, 1]]
  static Duployan = [
    [113664, 113770, 1],
    [113776, 113788, 1],
    [113792, 113800, 1],
    [113808, 113817, 1],
    [113820, 113823, 1]
  ]
  static Egyptian_Hieroglyphs = [[77824, 78933, 1]]
  static Elbasan = [[66816, 66855, 1]]
  static Elymaic = [[69600, 69622, 1]]
  static Ethiopic = [
    [4608, 4680, 1],
    [4682, 4685, 1],
    [4688, 4694, 1],
    [4696, 4698, 2],
    [4699, 4701, 1],
    [4704, 4744, 1],
    [4746, 4749, 1],
    [4752, 4784, 1],
    [4786, 4789, 1],
    [4792, 4798, 1],
    [4800, 4802, 2],
    [4803, 4805, 1],
    [4808, 4822, 1],
    [4824, 4880, 1],
    [4882, 4885, 1],
    [4888, 4954, 1],
    [4957, 4988, 1],
    [4992, 5017, 1],
    [11648, 11670, 1],
    [11680, 11686, 1],
    [11688, 11694, 1],
    [11696, 11702, 1],
    [11704, 11710, 1],
    [11712, 11718, 1],
    [11720, 11726, 1],
    [11728, 11734, 1],
    [11736, 11742, 1],
    [43777, 43782, 1],
    [43785, 43790, 1],
    [43793, 43798, 1],
    [43808, 43814, 1],
    [43816, 43822, 1],
    [124896, 124902, 1],
    [124904, 124907, 1],
    [124909, 124910, 1],
    [124912, 124926, 1]
  ]
  static Georgian = [
    [4256, 4293, 1],
    [4295, 4301, 6],
    [4304, 4346, 1],
    [4348, 4351, 1],
    [7312, 7354, 1],
    [7357, 7359, 1],
    [11520, 11557, 1],
    [11559, 11565, 6]
  ]
  static Glagolitic = [
    [11264, 11359, 1],
    [122880, 122886, 1],
    [122888, 122904, 1],
    [122907, 122913, 1],
    [122915, 122916, 1],
    [122918, 122922, 1]
  ]
  static Gothic = [[66352, 66378, 1]]
  static Grantha = [
    [70400, 70403, 1],
    [70405, 70412, 1],
    [70415, 70416, 1],
    [70419, 70440, 1],
    [70442, 70448, 1],
    [70450, 70451, 1],
    [70453, 70457, 1],
    [70460, 70468, 1],
    [70471, 70472, 1],
    [70475, 70477, 1],
    [70480, 70487, 7],
    [70493, 70499, 1],
    [70502, 70508, 1],
    [70512, 70516, 1]
  ]
  static Greek = [
    [880, 883, 1],
    [885, 887, 1],
    [890, 893, 1],
    [895, 900, 5],
    [902, 904, 2],
    [905, 906, 1],
    [908, 910, 2],
    [911, 929, 1],
    [931, 993, 1],
    [1008, 1023, 1],
    [7462, 7466, 1],
    [7517, 7521, 1],
    [7526, 7530, 1],
    [7615, 7936, 321],
    [7937, 7957, 1],
    [7960, 7965, 1],
    [7968, 8005, 1],
    [8008, 8013, 1],
    [8016, 8023, 1],
    [8025, 8031, 2],
    [8032, 8061, 1],
    [8064, 8116, 1],
    [8118, 8132, 1],
    [8134, 8147, 1],
    [8150, 8155, 1],
    [8157, 8175, 1],
    [8178, 8180, 1],
    [8182, 8190, 1],
    [8486, 43877, 35391],
    [65856, 65934, 1],
    [65952, 119296, 53344],
    [119297, 119365, 1]
  ]
  static foldGreek = [[181, 837, 656]]
  static Gujarati = [
    [2689, 2691, 1],
    [2693, 2701, 1],
    [2703, 2705, 1],
    [2707, 2728, 1],
    [2730, 2736, 1],
    [2738, 2739, 1],
    [2741, 2745, 1],
    [2748, 2757, 1],
    [2759, 2761, 1],
    [2763, 2765, 1],
    [2768, 2784, 16],
    [2785, 2787, 1],
    [2790, 2801, 1],
    [2809, 2815, 1]
  ]
  static Gunjala_Gondi = [
    [73056, 73061, 1],
    [73063, 73064, 1],
    [73066, 73102, 1],
    [73104, 73105, 1],
    [73107, 73112, 1],
    [73120, 73129, 1]
  ]
  static Gurmukhi = [
    [2561, 2563, 1],
    [2565, 2570, 1],
    [2575, 2576, 1],
    [2579, 2600, 1],
    [2602, 2608, 1],
    [2610, 2611, 1],
    [2613, 2614, 1],
    [2616, 2617, 1],
    [2620, 2622, 2],
    [2623, 2626, 1],
    [2631, 2632, 1],
    [2635, 2637, 1],
    [2641, 2649, 8],
    [2650, 2652, 1],
    [2654, 2662, 8],
    [2663, 2678, 1]
  ]
  static Han = [
    [11904, 11929, 1],
    [11931, 12019, 1],
    [12032, 12245, 1],
    [12293, 12295, 2],
    [12321, 12329, 1],
    [12344, 12347, 1],
    [13312, 19903, 1],
    [19968, 40959, 1],
    [63744, 64109, 1],
    [64112, 64217, 1],
    [94178, 94179, 1],
    [94192, 94193, 1],
    [131072, 173791, 1],
    [173824, 177977, 1],
    [177984, 178205, 1],
    [178208, 183969, 1],
    [183984, 191456, 1],
    [191472, 192093, 1],
    [194560, 195101, 1],
    [196608, 201546, 1],
    [201552, 205743, 1]
  ]
  static Hangul = [
    [4352, 4607, 1],
    [12334, 12335, 1],
    [12593, 12686, 1],
    [12800, 12830, 1],
    [12896, 12926, 1],
    [43360, 43388, 1],
    [44032, 55203, 1],
    [55216, 55238, 1],
    [55243, 55291, 1],
    [65440, 65470, 1],
    [65474, 65479, 1],
    [65482, 65487, 1],
    [65490, 65495, 1],
    [65498, 65500, 1]
  ]
  static Hanifi_Rohingya = [
    [68864, 68903, 1],
    [68912, 68921, 1]
  ]
  static Hanunoo = [[5920, 5940, 1]]
  static Hatran = [
    [67808, 67826, 1],
    [67828, 67829, 1],
    [67835, 67839, 1]
  ]
  static Hebrew = [
    [1425, 1479, 1],
    [1488, 1514, 1],
    [1519, 1524, 1],
    [64285, 64310, 1],
    [64312, 64316, 1],
    [64318, 64320, 2],
    [64321, 64323, 2],
    [64324, 64326, 2],
    [64327, 64335, 1]
  ]
  static Hiragana = [
    [12353, 12438, 1],
    [12445, 12447, 1],
    [110593, 110879, 1],
    [110898, 110928, 30],
    [110929, 110930, 1],
    [127488, 127488, 1]
  ]
  static Imperial_Aramaic = [
    [67648, 67669, 1],
    [67671, 67679, 1]
  ]
  static Inherited = [
    [768, 879, 1],
    [1157, 1158, 1],
    [1611, 1621, 1],
    [1648, 2385, 737],
    [2386, 2388, 1],
    [6832, 6862, 1],
    [7376, 7378, 1],
    [7380, 7392, 1],
    [7394, 7400, 1],
    [7405, 7412, 7],
    [7416, 7417, 1],
    [7616, 7679, 1],
    [8204, 8205, 1],
    [8400, 8432, 1],
    [12330, 12333, 1],
    [12441, 12442, 1],
    [65024, 65039, 1],
    [65056, 65069, 1],
    [66045, 66272, 227],
    [70459, 118528, 48069],
    [118529, 118573, 1],
    [118576, 118598, 1],
    [119143, 119145, 1],
    [119163, 119170, 1],
    [119173, 119179, 1],
    [119210, 119213, 1],
    [917760, 917999, 1]
  ]
  static foldInherited = [
    [921, 953, 32],
    [8126, 8126, 1]
  ]
  static Inscriptional_Pahlavi = [
    [68448, 68466, 1],
    [68472, 68479, 1]
  ]
  static Inscriptional_Parthian = [
    [68416, 68437, 1],
    [68440, 68447, 1]
  ]
  static Javanese = [
    [43392, 43469, 1],
    [43472, 43481, 1],
    [43486, 43487, 1]
  ]
  static Kaithi = [
    [69760, 69826, 1],
    [69837, 69837, 1]
  ]
  static Kannada = [
    [3200, 3212, 1],
    [3214, 3216, 1],
    [3218, 3240, 1],
    [3242, 3251, 1],
    [3253, 3257, 1],
    [3260, 3268, 1],
    [3270, 3272, 1],
    [3274, 3277, 1],
    [3285, 3286, 1],
    [3293, 3294, 1],
    [3296, 3299, 1],
    [3302, 3311, 1],
    [3313, 3315, 1]
  ]
  static Katakana = [
    [12449, 12538, 1],
    [12541, 12543, 1],
    [12784, 12799, 1],
    [13008, 13054, 1],
    [13056, 13143, 1],
    [65382, 65391, 1],
    [65393, 65437, 1],
    [110576, 110579, 1],
    [110581, 110587, 1],
    [110589, 110590, 1],
    [110592, 110880, 288],
    [110881, 110882, 1],
    [110933, 110948, 15],
    [110949, 110951, 1]
  ]
  static Kawi = [
    [73472, 73488, 1],
    [73490, 73530, 1],
    [73534, 73561, 1]
  ]
  static Kayah_Li = [
    [43264, 43309, 1],
    [43311, 43311, 1]
  ]
  static Kharoshthi = [
    [68096, 68099, 1],
    [68101, 68102, 1],
    [68108, 68115, 1],
    [68117, 68119, 1],
    [68121, 68149, 1],
    [68152, 68154, 1],
    [68159, 68168, 1],
    [68176, 68184, 1]
  ]
  static Khitan_Small_Script = [
    [94180, 101120, 6940],
    [101121, 101589, 1]
  ]
  static Khmer = [
    [6016, 6109, 1],
    [6112, 6121, 1],
    [6128, 6137, 1],
    [6624, 6655, 1]
  ]
  static Khojki = [
    [70144, 70161, 1],
    [70163, 70209, 1]
  ]
  static Khudawadi = [
    [70320, 70378, 1],
    [70384, 70393, 1]
  ]
  static Lao = [
    [3713, 3714, 1],
    [3716, 3718, 2],
    [3719, 3722, 1],
    [3724, 3747, 1],
    [3749, 3751, 2],
    [3752, 3773, 1],
    [3776, 3780, 1],
    [3782, 3784, 2],
    [3785, 3790, 1],
    [3792, 3801, 1],
    [3804, 3807, 1]
  ]
  static Latin = [
    [65, 90, 1],
    [97, 122, 1],
    [170, 186, 16],
    [192, 214, 1],
    [216, 246, 1],
    [248, 696, 1],
    [736, 740, 1],
    [7424, 7461, 1],
    [7468, 7516, 1],
    [7522, 7525, 1],
    [7531, 7543, 1],
    [7545, 7614, 1],
    [7680, 7935, 1],
    [8305, 8319, 14],
    [8336, 8348, 1],
    [8490, 8491, 1],
    [8498, 8526, 28],
    [8544, 8584, 1],
    [11360, 11391, 1],
    [42786, 42887, 1],
    [42891, 42954, 1],
    [42960, 42961, 1],
    [42963, 42965, 2],
    [42966, 42969, 1],
    [42994, 43007, 1],
    [43824, 43866, 1],
    [43868, 43876, 1],
    [43878, 43881, 1],
    [64256, 64262, 1],
    [65313, 65338, 1],
    [65345, 65370, 1],
    [67456, 67461, 1],
    [67463, 67504, 1],
    [67506, 67514, 1],
    [122624, 122654, 1],
    [122661, 122666, 1]
  ]
  static Lepcha = [
    [7168, 7223, 1],
    [7227, 7241, 1],
    [7245, 7247, 1]
  ]
  static Limbu = [
    [6400, 6430, 1],
    [6432, 6443, 1],
    [6448, 6459, 1],
    [6464, 6468, 4],
    [6469, 6479, 1]
  ]
  static Linear_A = [
    [67072, 67382, 1],
    [67392, 67413, 1],
    [67424, 67431, 1]
  ]
  static Linear_B = [
    [65536, 65547, 1],
    [65549, 65574, 1],
    [65576, 65594, 1],
    [65596, 65597, 1],
    [65599, 65613, 1],
    [65616, 65629, 1],
    [65664, 65786, 1]
  ]
  static Lisu = [
    [42192, 42239, 1],
    [73648, 73648, 1]
  ]
  static Lycian = [[66176, 66204, 1]]
  static Lydian = [
    [67872, 67897, 1],
    [67903, 67903, 1]
  ]
  static Mahajani = [[69968, 70006, 1]]
  static Makasar = [[73440, 73464, 1]]
  static Malayalam = [
    [3328, 3340, 1],
    [3342, 3344, 1],
    [3346, 3396, 1],
    [3398, 3400, 1],
    [3402, 3407, 1],
    [3412, 3427, 1],
    [3430, 3455, 1]
  ]
  static Mandaic = [
    [2112, 2139, 1],
    [2142, 2142, 1]
  ]
  static Manichaean = [
    [68288, 68326, 1],
    [68331, 68342, 1]
  ]
  static Marchen = [
    [72816, 72847, 1],
    [72850, 72871, 1],
    [72873, 72886, 1]
  ]
  static Masaram_Gondi = [
    [72960, 72966, 1],
    [72968, 72969, 1],
    [72971, 73014, 1],
    [73018, 73020, 2],
    [73021, 73023, 2],
    [73024, 73031, 1],
    [73040, 73049, 1]
  ]
  static Medefaidrin = [[93760, 93850, 1]]
  static Meetei_Mayek = [
    [43744, 43766, 1],
    [43968, 44013, 1],
    [44016, 44025, 1]
  ]
  static Mende_Kikakui = [
    [124928, 125124, 1],
    [125127, 125142, 1]
  ]
  static Meroitic_Cursive = [
    [68000, 68023, 1],
    [68028, 68047, 1],
    [68050, 68095, 1]
  ]
  static Meroitic_Hieroglyphs = [[67968, 67999, 1]]
  static Miao = [
    [93952, 94026, 1],
    [94031, 94087, 1],
    [94095, 94111, 1]
  ]
  static Modi = [
    [71168, 71236, 1],
    [71248, 71257, 1]
  ]
  static Mongolian = [
    [6144, 6145, 1],
    [6148, 6150, 2],
    [6151, 6169, 1],
    [6176, 6264, 1],
    [6272, 6314, 1],
    [71264, 71276, 1]
  ]
  static Mro = [
    [92736, 92766, 1],
    [92768, 92777, 1],
    [92782, 92783, 1]
  ]
  static Multani = [
    [70272, 70278, 1],
    [70280, 70282, 2],
    [70283, 70285, 1],
    [70287, 70301, 1],
    [70303, 70313, 1]
  ]
  static Myanmar = [
    [4096, 4255, 1],
    [43488, 43518, 1],
    [43616, 43647, 1]
  ]
  static Nabataean = [
    [67712, 67742, 1],
    [67751, 67759, 1]
  ]
  static Nag_Mundari = [[124112, 124153, 1]]
  static Nandinagari = [
    [72096, 72103, 1],
    [72106, 72151, 1],
    [72154, 72164, 1]
  ]
  static New_Tai_Lue = [
    [6528, 6571, 1],
    [6576, 6601, 1],
    [6608, 6618, 1],
    [6622, 6623, 1]
  ]
  static Newa = [
    [70656, 70747, 1],
    [70749, 70753, 1]
  ]
  static Nko = [
    [1984, 2042, 1],
    [2045, 2047, 1]
  ]
  static Nushu = [
    [94177, 110960, 16783],
    [110961, 111355, 1]
  ]
  static Nyiakeng_Puachue_Hmong = [
    [123136, 123180, 1],
    [123184, 123197, 1],
    [123200, 123209, 1],
    [123214, 123215, 1]
  ]
  static Ogham = [[5760, 5788, 1]]
  static Ol_Chiki = [[7248, 7295, 1]]
  static Old_Hungarian = [
    [68736, 68786, 1],
    [68800, 68850, 1],
    [68858, 68863, 1]
  ]
  static Old_Italic = [
    [66304, 66339, 1],
    [66349, 66351, 1]
  ]
  static Old_North_Arabian = [[68224, 68255, 1]]
  static Old_Permic = [[66384, 66426, 1]]
  static Old_Persian = [
    [66464, 66499, 1],
    [66504, 66517, 1]
  ]
  static Old_Sogdian = [[69376, 69415, 1]]
  static Old_South_Arabian = [[68192, 68223, 1]]
  static Old_Turkic = [[68608, 68680, 1]]
  static Old_Uyghur = [[69488, 69513, 1]]
  static Oriya = [
    [2817, 2819, 1],
    [2821, 2828, 1],
    [2831, 2832, 1],
    [2835, 2856, 1],
    [2858, 2864, 1],
    [2866, 2867, 1],
    [2869, 2873, 1],
    [2876, 2884, 1],
    [2887, 2888, 1],
    [2891, 2893, 1],
    [2901, 2903, 1],
    [2908, 2909, 1],
    [2911, 2915, 1],
    [2918, 2935, 1]
  ]
  static Osage = [
    [66736, 66771, 1],
    [66776, 66811, 1]
  ]
  static Osmanya = [
    [66688, 66717, 1],
    [66720, 66729, 1]
  ]
  static Pahawh_Hmong = [
    [92928, 92997, 1],
    [93008, 93017, 1],
    [93019, 93025, 1],
    [93027, 93047, 1],
    [93053, 93071, 1]
  ]
  static Palmyrene = [[67680, 67711, 1]]
  static Pau_Cin_Hau = [[72384, 72440, 1]]
  static Phags_Pa = [[43072, 43127, 1]]
  static Phoenician = [
    [67840, 67867, 1],
    [67871, 67871, 1]
  ]
  static Psalter_Pahlavi = [
    [68480, 68497, 1],
    [68505, 68508, 1],
    [68521, 68527, 1]
  ]
  static Rejang = [
    [43312, 43347, 1],
    [43359, 43359, 1]
  ]
  static Runic = [
    [5792, 5866, 1],
    [5870, 5880, 1]
  ]
  static Samaritan = [
    [2048, 2093, 1],
    [2096, 2110, 1]
  ]
  static Saurashtra = [
    [43136, 43205, 1],
    [43214, 43225, 1]
  ]
  static Sharada = [[70016, 70111, 1]]
  static Shavian = [[66640, 66687, 1]]
  static Siddham = [
    [71040, 71093, 1],
    [71096, 71133, 1]
  ]
  static SignWriting = [
    [120832, 121483, 1],
    [121499, 121503, 1],
    [121505, 121519, 1]
  ]
  static Sinhala = [
    [3457, 3459, 1],
    [3461, 3478, 1],
    [3482, 3505, 1],
    [3507, 3515, 1],
    [3517, 3520, 3],
    [3521, 3526, 1],
    [3530, 3535, 5],
    [3536, 3540, 1],
    [3542, 3544, 2],
    [3545, 3551, 1],
    [3558, 3567, 1],
    [3570, 3572, 1],
    [70113, 70132, 1]
  ]
  static Sogdian = [[69424, 69465, 1]]
  static Sora_Sompeng = [
    [69840, 69864, 1],
    [69872, 69881, 1]
  ]
  static Soyombo = [[72272, 72354, 1]]
  static Sundanese = [
    [7040, 7103, 1],
    [7360, 7367, 1]
  ]
  static Syloti_Nagri = [[43008, 43052, 1]]
  static Syriac = [
    [1792, 1805, 1],
    [1807, 1866, 1],
    [1869, 1871, 1],
    [2144, 2154, 1]
  ]
  static Tagalog = [
    [5888, 5909, 1],
    [5919, 5919, 1]
  ]
  static Tagbanwa = [
    [5984, 5996, 1],
    [5998, 6000, 1],
    [6002, 6003, 1]
  ]
  static Tai_Le = [
    [6480, 6509, 1],
    [6512, 6516, 1]
  ]
  static Tai_Tham = [
    [6688, 6750, 1],
    [6752, 6780, 1],
    [6783, 6793, 1],
    [6800, 6809, 1],
    [6816, 6829, 1]
  ]
  static Tai_Viet = [
    [43648, 43714, 1],
    [43739, 43743, 1]
  ]
  static Takri = [
    [71296, 71353, 1],
    [71360, 71369, 1]
  ]
  static Tamil = [
    [2946, 2947, 1],
    [2949, 2954, 1],
    [2958, 2960, 1],
    [2962, 2965, 1],
    [2969, 2970, 1],
    [2972, 2974, 2],
    [2975, 2979, 4],
    [2980, 2984, 4],
    [2985, 2986, 1],
    [2990, 3001, 1],
    [3006, 3010, 1],
    [3014, 3016, 1],
    [3018, 3021, 1],
    [3024, 3031, 7],
    [3046, 3066, 1],
    [73664, 73713, 1],
    [73727, 73727, 1]
  ]
  static Tangsa = [
    [92784, 92862, 1],
    [92864, 92873, 1]
  ]
  static Tangut = [
    [94176, 94208, 32],
    [94209, 100343, 1],
    [100352, 101119, 1],
    [101632, 101640, 1]
  ]
  static Telugu = [
    [3072, 3084, 1],
    [3086, 3088, 1],
    [3090, 3112, 1],
    [3114, 3129, 1],
    [3132, 3140, 1],
    [3142, 3144, 1],
    [3146, 3149, 1],
    [3157, 3158, 1],
    [3160, 3162, 1],
    [3165, 3168, 3],
    [3169, 3171, 1],
    [3174, 3183, 1],
    [3191, 3199, 1]
  ]
  static Thaana = [[1920, 1969, 1]]
  static Thai = [
    [3585, 3642, 1],
    [3648, 3675, 1]
  ]
  static Tibetan = [
    [3840, 3911, 1],
    [3913, 3948, 1],
    [3953, 3991, 1],
    [3993, 4028, 1],
    [4030, 4044, 1],
    [4046, 4052, 1],
    [4057, 4058, 1]
  ]
  static Tifinagh = [
    [11568, 11623, 1],
    [11631, 11632, 1],
    [11647, 11647, 1]
  ]
  static Tirhuta = [
    [70784, 70855, 1],
    [70864, 70873, 1]
  ]
  static Toto = [[123536, 123566, 1]]
  static Ugaritic = [
    [66432, 66461, 1],
    [66463, 66463, 1]
  ]
  static Vai = [[42240, 42539, 1]]
  static Vithkuqi = [
    [66928, 66938, 1],
    [66940, 66954, 1],
    [66956, 66962, 1],
    [66964, 66965, 1],
    [66967, 66977, 1],
    [66979, 66993, 1],
    [66995, 67001, 1],
    [67003, 67004, 1]
  ]
  static Wancho = [
    [123584, 123641, 1],
    [123647, 123647, 1]
  ]
  static Warang_Citi = [
    [71840, 71922, 1],
    [71935, 71935, 1]
  ]
  static Yezidi = [
    [69248, 69289, 1],
    [69291, 69293, 1],
    [69296, 69297, 1]
  ]
  static Yi = [
    [40960, 42124, 1],
    [42128, 42182, 1]
  ]
  static Zanabazar_Square = [[72192, 72263, 1]]

  static CATEGORIES = new Map([
    ['C', UnicodeTables.C],
    ['Cc', UnicodeTables.Cc],
    ['Cf', UnicodeTables.Cf],
    ['Co', UnicodeTables.Co],
    ['Cs', UnicodeTables.Cs],
    ['L', UnicodeTables.L],
    ['Ll', UnicodeTables.Ll],
    ['Lm', UnicodeTables.Lm],
    ['Lo', UnicodeTables.Lo],
    ['Lt', UnicodeTables.Lt],
    ['Lu', UnicodeTables.Lu],
    ['M', UnicodeTables.M],
    ['Mc', UnicodeTables.Mc],
    ['Me', UnicodeTables.Me],
    ['Mn', UnicodeTables.Mn],
    ['N', UnicodeTables.N],
    ['Nd', UnicodeTables.Nd],
    ['Nl', UnicodeTables.Nl],
    ['No', UnicodeTables.No],
    ['P', UnicodeTables.P],
    ['Pc', UnicodeTables.Pc],
    ['Pd', UnicodeTables.Pd],
    ['Pe', UnicodeTables.Pe],
    ['Pf', UnicodeTables.Pf],
    ['Pi', UnicodeTables.Pi],
    ['Po', UnicodeTables.Po],
    ['Ps', UnicodeTables.Ps],
    ['S', UnicodeTables.S],
    ['Sc', UnicodeTables.Sc],
    ['Sk', UnicodeTables.Sk],
    ['Sm', UnicodeTables.Sm],
    ['So', UnicodeTables.So],
    ['Z', UnicodeTables.Z],
    ['Zl', UnicodeTables.Zl],
    ['Zp', UnicodeTables.Zp],
    ['Zs', UnicodeTables.Zs]
  ])

  static SCRIPTS = new Map([
    ['Adlam', UnicodeTables.Adlam],
    ['Ahom', UnicodeTables.Ahom],
    ['Anatolian_Hieroglyphs', UnicodeTables.Anatolian_Hieroglyphs],
    ['Arabic', UnicodeTables.Arabic],
    ['Armenian', UnicodeTables.Armenian],
    ['Avestan', UnicodeTables.Avestan],
    ['Balinese', UnicodeTables.Balinese],
    ['Bamum', UnicodeTables.Bamum],
    ['Bassa_Vah', UnicodeTables.Bassa_Vah],
    ['Batak', UnicodeTables.Batak],
    ['Bengali', UnicodeTables.Bengali],
    ['Bhaiksuki', UnicodeTables.Bhaiksuki],
    ['Bopomofo', UnicodeTables.Bopomofo],
    ['Brahmi', UnicodeTables.Brahmi],
    ['Braille', UnicodeTables.Braille],
    ['Buginese', UnicodeTables.Buginese],
    ['Buhid', UnicodeTables.Buhid],
    ['Canadian_Aboriginal', UnicodeTables.Canadian_Aboriginal],
    ['Carian', UnicodeTables.Carian],
    ['Caucasian_Albanian', UnicodeTables.Caucasian_Albanian],
    ['Chakma', UnicodeTables.Chakma],
    ['Cham', UnicodeTables.Cham],
    ['Cherokee', UnicodeTables.Cherokee],
    ['Chorasmian', UnicodeTables.Chorasmian],
    ['Common', UnicodeTables.Common],
    ['Coptic', UnicodeTables.Coptic],
    ['Cuneiform', UnicodeTables.Cuneiform],
    ['Cypriot', UnicodeTables.Cypriot],
    ['Cypro_Minoan', UnicodeTables.Cypro_Minoan],
    ['Cyrillic', UnicodeTables.Cyrillic],
    ['Deseret', UnicodeTables.Deseret],
    ['Devanagari', UnicodeTables.Devanagari],
    ['Dives_Akuru', UnicodeTables.Dives_Akuru],
    ['Dogra', UnicodeTables.Dogra],
    ['Duployan', UnicodeTables.Duployan],
    ['Egyptian_Hieroglyphs', UnicodeTables.Egyptian_Hieroglyphs],
    ['Elbasan', UnicodeTables.Elbasan],
    ['Elymaic', UnicodeTables.Elymaic],
    ['Ethiopic', UnicodeTables.Ethiopic],
    ['Georgian', UnicodeTables.Georgian],
    ['Glagolitic', UnicodeTables.Glagolitic],
    ['Gothic', UnicodeTables.Gothic],
    ['Grantha', UnicodeTables.Grantha],
    ['Greek', UnicodeTables.Greek],
    ['Gujarati', UnicodeTables.Gujarati],
    ['Gunjala_Gondi', UnicodeTables.Gunjala_Gondi],
    ['Gurmukhi', UnicodeTables.Gurmukhi],
    ['Han', UnicodeTables.Han],
    ['Hangul', UnicodeTables.Hangul],
    ['Hanifi_Rohingya', UnicodeTables.Hanifi_Rohingya],
    ['Hanunoo', UnicodeTables.Hanunoo],
    ['Hatran', UnicodeTables.Hatran],
    ['Hebrew', UnicodeTables.Hebrew],
    ['Hiragana', UnicodeTables.Hiragana],
    ['Imperial_Aramaic', UnicodeTables.Imperial_Aramaic],
    ['Inherited', UnicodeTables.Inherited],
    ['Inscriptional_Pahlavi', UnicodeTables.Inscriptional_Pahlavi],
    ['Inscriptional_Parthian', UnicodeTables.Inscriptional_Parthian],
    ['Javanese', UnicodeTables.Javanese],
    ['Kaithi', UnicodeTables.Kaithi],
    ['Kannada', UnicodeTables.Kannada],
    ['Katakana', UnicodeTables.Katakana],
    ['Kawi', UnicodeTables.Kawi],
    ['Kayah_Li', UnicodeTables.Kayah_Li],
    ['Kharoshthi', UnicodeTables.Kharoshthi],
    ['Khitan_Small_Script', UnicodeTables.Khitan_Small_Script],
    ['Khmer', UnicodeTables.Khmer],
    ['Khojki', UnicodeTables.Khojki],
    ['Khudawadi', UnicodeTables.Khudawadi],
    ['Lao', UnicodeTables.Lao],
    ['Latin', UnicodeTables.Latin],
    ['Lepcha', UnicodeTables.Lepcha],
    ['Limbu', UnicodeTables.Limbu],
    ['Linear_A', UnicodeTables.Linear_A],
    ['Linear_B', UnicodeTables.Linear_B],
    ['Lisu', UnicodeTables.Lisu],
    ['Lycian', UnicodeTables.Lycian],
    ['Lydian', UnicodeTables.Lydian],
    ['Mahajani', UnicodeTables.Mahajani],
    ['Makasar', UnicodeTables.Makasar],
    ['Malayalam', UnicodeTables.Malayalam],
    ['Mandaic', UnicodeTables.Mandaic],
    ['Manichaean', UnicodeTables.Manichaean],
    ['Marchen', UnicodeTables.Marchen],
    ['Masaram_Gondi', UnicodeTables.Masaram_Gondi],
    ['Medefaidrin', UnicodeTables.Medefaidrin],
    ['Meetei_Mayek', UnicodeTables.Meetei_Mayek],
    ['Mende_Kikakui', UnicodeTables.Mende_Kikakui],
    ['Meroitic_Cursive', UnicodeTables.Meroitic_Cursive],
    ['Meroitic_Hieroglyphs', UnicodeTables.Meroitic_Hieroglyphs],
    ['Miao', UnicodeTables.Miao],
    ['Modi', UnicodeTables.Modi],
    ['Mongolian', UnicodeTables.Mongolian],
    ['Mro', UnicodeTables.Mro],
    ['Multani', UnicodeTables.Multani],
    ['Myanmar', UnicodeTables.Myanmar],
    ['Nabataean', UnicodeTables.Nabataean],
    ['Nag_Mundari', UnicodeTables.Nag_Mundari],
    ['Nandinagari', UnicodeTables.Nandinagari],
    ['New_Tai_Lue', UnicodeTables.New_Tai_Lue],
    ['Newa', UnicodeTables.Newa],
    ['Nko', UnicodeTables.Nko],
    ['Nushu', UnicodeTables.Nushu],
    ['Nyiakeng_Puachue_Hmong', UnicodeTables.Nyiakeng_Puachue_Hmong],
    ['Ogham', UnicodeTables.Ogham],
    ['Ol_Chiki', UnicodeTables.Ol_Chiki],
    ['Old_Hungarian', UnicodeTables.Old_Hungarian],
    ['Old_Italic', UnicodeTables.Old_Italic],
    ['Old_North_Arabian', UnicodeTables.Old_North_Arabian],
    ['Old_Permic', UnicodeTables.Old_Permic],
    ['Old_Persian', UnicodeTables.Old_Persian],
    ['Old_Sogdian', UnicodeTables.Old_Sogdian],
    ['Old_South_Arabian', UnicodeTables.Old_South_Arabian],
    ['Old_Turkic', UnicodeTables.Old_Turkic],
    ['Old_Uyghur', UnicodeTables.Old_Uyghur],
    ['Oriya', UnicodeTables.Oriya],
    ['Osage', UnicodeTables.Osage],
    ['Osmanya', UnicodeTables.Osmanya],
    ['Pahawh_Hmong', UnicodeTables.Pahawh_Hmong],
    ['Palmyrene', UnicodeTables.Palmyrene],
    ['Pau_Cin_Hau', UnicodeTables.Pau_Cin_Hau],
    ['Phags_Pa', UnicodeTables.Phags_Pa],
    ['Phoenician', UnicodeTables.Phoenician],
    ['Psalter_Pahlavi', UnicodeTables.Psalter_Pahlavi],
    ['Rejang', UnicodeTables.Rejang],
    ['Runic', UnicodeTables.Runic],
    ['Samaritan', UnicodeTables.Samaritan],
    ['Saurashtra', UnicodeTables.Saurashtra],
    ['Sharada', UnicodeTables.Sharada],
    ['Shavian', UnicodeTables.Shavian],
    ['Siddham', UnicodeTables.Siddham],
    ['SignWriting', UnicodeTables.SignWriting],
    ['Sinhala', UnicodeTables.Sinhala],
    ['Sogdian', UnicodeTables.Sogdian],
    ['Sora_Sompeng', UnicodeTables.Sora_Sompeng],
    ['Soyombo', UnicodeTables.Soyombo],
    ['Sundanese', UnicodeTables.Sundanese],
    ['Syloti_Nagri', UnicodeTables.Syloti_Nagri],
    ['Syriac', UnicodeTables.Syriac],
    ['Tagalog', UnicodeTables.Tagalog],
    ['Tagbanwa', UnicodeTables.Tagbanwa],
    ['Tai_Le', UnicodeTables.Tai_Le],
    ['Tai_Tham', UnicodeTables.Tai_Tham],
    ['Tai_Viet', UnicodeTables.Tai_Viet],
    ['Takri', UnicodeTables.Takri],
    ['Tamil', UnicodeTables.Tamil],
    ['Tangsa', UnicodeTables.Tangsa],
    ['Tangut', UnicodeTables.Tangut],
    ['Telugu', UnicodeTables.Telugu],
    ['Thaana', UnicodeTables.Thaana],
    ['Thai', UnicodeTables.Thai],
    ['Tibetan', UnicodeTables.Tibetan],
    ['Tifinagh', UnicodeTables.Tifinagh],
    ['Tirhuta', UnicodeTables.Tirhuta],
    ['Toto', UnicodeTables.Toto],
    ['Ugaritic', UnicodeTables.Ugaritic],
    ['Vai', UnicodeTables.Vai],
    ['Vithkuqi', UnicodeTables.Vithkuqi],
    ['Wancho', UnicodeTables.Wancho],
    ['Warang_Citi', UnicodeTables.Warang_Citi],
    ['Yezidi', UnicodeTables.Yezidi],
    ['Yi', UnicodeTables.Yi],
    ['Zanabazar_Square', UnicodeTables.Zanabazar_Square]
  ])

  static FOLD_CATEGORIES = new Map([
    ['L', UnicodeTables.foldL],
    ['Ll', UnicodeTables.foldLl],
    ['Lt', UnicodeTables.foldLt],
    ['Lu', UnicodeTables.foldLu],
    ['M', UnicodeTables.foldM],
    ['Mn', UnicodeTables.foldMn]
  ])

  static FOLD_SCRIPT = new Map([
    ['Common', UnicodeTables.foldCommon],
    ['Greek', UnicodeTables.foldGreek],
    ['Inherited', UnicodeTables.foldInherited]
  ])
}

export { UnicodeTables }
