import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../styleConstants'

export interface OptionProps extends React.OptionHTMLAttributes<HTMLOptionElement> {
  readonly label: string
  readonly value?: string | number
}

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  readonly options: OptionProps[]
  readonly fluid?: boolean
  readonly className?: string
  readonly error?: boolean
  readonly style?: React.CSSProperties
  readonly ref?: React.RefObject<HTMLSelectElement>
}

export const Select = React.forwardRef<HTMLSelectElement, Props & React.SelectHTMLAttributes<any>>((props, ref) => {
  const { options, fluid, className, style, ...rest } = props
  return (
    <DesignedSelect className={cc([{ fluid }, className])} style={style} ref={ref} {...rest}>
      {options.map((option) => {
        return (
          <option key={option.value} {...option}>
            {option.label}
          </option>
        )
      })}
    </DesignedSelect>
  )
})

const DesignedSelect = styled.select<{ error?: boolean }>`
  padding: 0.5rem calc(1rem + 24px) 0.5rem 1rem;
  color: ${colors.gray800};
  font-size: 1rem;
  border: 1px solid ${colors.gray300};
  border-radius: 2px;
  cursor: pointer;

  ${({ error }) =>
    error &&
    `
    border-color: ${colors.error};
  `}

  &.fluid {
    width: 100%;
  }
`
