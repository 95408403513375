import * as React from 'react'
import { useCookies } from 'react-cookie'
import { navigate } from '@gatsbyjs/reach-router'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

// プロジェクト作成処理
export const createProjectStep = {
  none: 0,
  create: 1,
  confirm: 2,
}

interface State {
  readonly checking: boolean
  readonly baseUrl: string
  readonly userId: number
  readonly projectId: number
  readonly permissions: any
  readonly modalOpened: boolean
  readonly newModalOpened: boolean
  readonly captureMenuOpened: boolean
  readonly accountMenuOpened: boolean
  readonly adminModal: boolean
  readonly createStep: number
  readonly errorMessage?: string
}

class Actions {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly setCookie: any,
  ) {}

  setProject = async (userId: number, projectId: number, permissions: any, baseUrl: string) => {
    this.setState({
      ...this.state,
      checking: false,
      baseUrl: baseUrl,
      userId: userId,
      projectId: projectId,
      permissions: permissions,
    })
  }

  openModal = () => {
    this.setState({ ...this.state, modalOpened: true })
  }

  openAdminModal = () => {
    this.setState({ ...this.state, modalOpened: true, adminModal: true })
  }

  onCancel = () => {
    this.setState({ ...this.state, modalOpened: false, adminModal: false })
  }

  newProject = () => {
    // プロジェクトリストのモーダルを閉じて作成用モーダルを開く
    this.setState({
      ...this.state,
      modalOpened: false,
      adminModal: false,
      newModalOpened: true,
      createStep: createProjectStep.create,
    })
  }

  // プロジェクト作成モーダル閉じる
  closeProject = () => {
    this.setState({
      ...this.state,
      newModalOpened: false,
      createStep: createProjectStep.none,
    })
  }

  createProject = (id: number) => {
    // 確認モーダルへ
    this.setState({
      ...this.state,
      createStep: createProjectStep.confirm,
    })

    // cookie保存
    this.setCookie('projectId', { userId: this.state.userId, id: id }, { path: '/' })
  }

  // プロジェクト作成後の確認
  confirmProject = () => {
    this.setState({
      ...this.state,
      newModalOpened: false,
      createStep: createProjectStep.confirm,
    })
    navigate('/')
  }

  // 縮小キャプチャメニュー選択
  onCaptureClick = () => {
    this.setState({ ...this.state, captureMenuOpened: !this.state.captureMenuOpened })
  }

  onCaptureClose = () => {
    this.setState({ ...this.state, captureMenuOpened: false })
  }

  // 縮小アカウントメニュー選択
  onAccountClick = () => {
    this.setState({ ...this.state, accountMenuOpened: !this.state.accountMenuOpened })
  }

  toAccount = () => {
    // 別タブで開くのでここでは遷移しない
    this.setState({ ...this.state, accountMenuOpened: false })
  }

  toAdmin = () => {
    // 別タブで開くのでここでは遷移しない
    this.setState({ ...this.state, accountMenuOpened: false })
  }

  logout = async () => {
    try {
      this.setState({ ...this.state, accountMenuOpened: false })
      // ログアウト前のページ情報を保持
      navigate(`/logout`, { state: { path: window.location.pathname, search: window.location.search } })
    } catch {
      this.setState({ ...this.state, accountMenuOpened: false })
    }
  }

  onAccountClose = () => {
    this.setState({ ...this.state, accountMenuOpened: false })
  }
}

const initialState: State = {
  checking: true,
  baseUrl: '',
  userId: 0,
  projectId: 0,
  permissions: '',
  modalOpened: false,
  newModalOpened: false,
  captureMenuOpened: false,
  accountMenuOpened: false,
  adminModal: false,
  createStep: createProjectStep.none,
  errorMessage: '',
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const setCookie = useCookies()[1]
  const actions = new Actions(state, setState, setCookie)
  return { state, actions }
}
