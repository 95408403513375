import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'

// クエリ処理ステップ
export const QueryStep = {
  none: 0,
  createExec: 1, // 作成実行
  createComplete: 2, // 作成完了
  editExec: 3, // 編集実行
  editComplete: 4, // 編集完了
  deleteExec: 5, // 削除実行
  deleteComplete: 6, // 削除完了
}

export class Query {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // クエリーパラメータ追加フロー開始
  onAdd = () => {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        editQuery: '',
        step: QueryStep.createExec,
      },
      queryErrorMessage: '',
    })
  }

  // クエリーパラメータ追加キャンセル
  onCancelAdd = () => {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        editQuery: '',
        disabled: true,
        step: QueryStep.none,
      },
      queryErrorMessage: '',
    })
  }

  // 追加クエリパラメータ入力
  onChangeAdd = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        editQuery: event.currentTarget.value,
        disabled: !event.currentTarget.validity.valid,
      },
    })
  }

  // クエリーパラメータ追加実行
  onApplyAdd = async () => {
    try {
      await request(
        'POST',
        `/api/projects/${this.state.project.id}/url_parameters/`,
        true,
        JSON.stringify({ name: this.state.query.editQuery }),
      )
      this.setState({
        ...this.state,
        reload: true,
        query: {
          ...this.state.query,
          editQuery: '',
          disabled: true,
          step: QueryStep.none,
        },
        queryErrorMessage: '',
        toastMessage: 'クエリーパラメータを追加しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        query: {
          ...this.state.query,
          step: QueryStep.none,
        },
        queryErrorMessage: typeof e === 'string' ? e : 'クエリーパラメータの追加に失敗しました。',
      })
    }
  }

  // クエリーパラメータ編集フロー開始
  onEdit = (id: number) => {
    const query = this.state.query.queries.find((query) => query.id === id)
    if (query) {
      this.setState({
        ...this.state,
        query: {
          ...this.state.query,
          selectedId: query.id,
          baseQuery: query.name,
          editQuery: query.name,
          disabled: true,
          step: QueryStep.editExec,
        },
        queryErrorMessage: '',
      })
    }
  }

  // クエリパラメータ内容編集
  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const valid = event.currentTarget.validity.valid && this.state.query.baseQuery !== event.currentTarget.value
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        editQuery: event.currentTarget.value,
        disabled: !valid,
      },
    })
  }

  // クエリ編集キャンセル
  onCancelEdit = () => {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        selectedId: 0,
        baseQuery: '',
        editQuery: '',
        step: QueryStep.none,
      },
    })
  }

  // クエリ編集内容を確定
  onApplyEdit = async () => {
    try {
      await request(
        'PATCH',
        `/api/projects/${this.state.project.id}/url_parameters/${this.state.query.selectedId}/`,
        true,
        JSON.stringify({ name: this.state.query.editQuery }),
      )
      this.setState({
        ...this.state,
        reload: true,
        query: {
          ...this.state.query,
          selectedId: 0,
          baseQuery: '',
          editQuery: '',
          step: QueryStep.none,
        },
        toastMessage: 'クエリーパラメータを変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        query: {
          ...this.state.query,
          step: QueryStep.none,
        },
        queryErrorMessage: typeof e === 'string' ? e : 'クエリーパラメータの変更に失敗しました。',
      })
    }
  }

  // クエリーパラメータ削除フロー開始
  onDelete = (id: number) => {
    const query = this.state.query.queries.find((query) => query.id === id)
    if (query) {
      this.setState({
        ...this.state,
        query: {
          ...this.state.query,
          selectedId: query.id,
          editQuery: query.name,
          step: QueryStep.deleteExec,
        },
        queryErrorMessage: '',
      })
    }
  }

  // クエリーパラメータ削除キャンセル
  onCancelDelete = () => {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        selectedId: 0,
        editQuery: '',
        step: QueryStep.none,
      },
    })
  }

  // クエリーパラメータ削除実行
  onApplyDelete = async () => {
    try {
      await request(
        'DELETE',
        `/api/projects/${this.state.project.id}/url_parameters/${this.state.query.selectedId}/`,
        true,
      )
      this.setState({
        ...this.state,
        reload: true,
        query: {
          ...this.state.query,
          selectedId: 0,
          editQuery: '',
          step: QueryStep.none,
        },
        queryErrorMessage: '',
        toastMessage: 'クエリーパラメータを削除しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        query: {
          ...this.state.query,
          step: QueryStep.none,
        },
        queryErrorMessage: typeof e === 'string' ? e : 'クエリーパラメータの削除に失敗しました。',
      })
    }
  }
}
