import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { GlobalContext } from '../GlobalState'
import { colors } from '../styleConstants'
import { SubActionButton } from '../components/common/Button'
import { CheckCircle } from '@styled-icons/boxicons-regular'
import { SimplePageLayout } from '../components/layout/SimplePageLayout'
import { PageHeader } from '../components/common/PageHeader'

export function CompletePassword(_: RouteComponentProps) {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  return (
    <SimplePageLayout>
      <PageHeader
        title={`パスワード変更完了 | ${AccountInfo.teamName}チーム`}
        description={`パスワード変更完了 | ${AccountInfo.teamName}チーム`}
      />
      <Title>登録完了！</Title>
      <Icon />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SubActionButton to="/login" style={{ width: '100%' }}>
          ログイン画面に進む
        </SubActionButton>
      </div>
    </SimplePageLayout>
  )
}

const Title = styled.h1`
  text-align: center;
  color: ${colors.lightBlue};
`

const Icon = styled(CheckCircle).attrs({
  size: '128',
  color: `${colors.lightBlue}`,
})`
  display: block;
  margin: 3rem auto 3rem;
`
