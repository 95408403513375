import * as React from 'react'
import { navigate, Redirect } from '@gatsbyjs/reach-router'

import { GlobalContext } from '../../../GlobalState'
import { Authority } from '../../../util/Authority'
import { Loading } from '../../../components/common/Loading'
import { ProjectContext } from '../../ProjectRoot'
import { CanNotAddMessage } from '../../../components/warnings/CanNotAddMessage'
import { EditReport } from '../EditReport'
import { useFunnelReports } from '../../../util/hooks/api/FunnelReport/useFunnelReports'
import { useCreateFunnelReport } from '../../../util/hooks/api/FunnelReport/useCreateFunnelReport'
import { INIT_EDIT_DATA, MAX_REPORT_NAME_LENGTH } from '../../../util/hooks/api/FunnelReport/constants'
import { useReportSettings } from '../../../util/hooks/useReportSettings'
import { ErrorBox } from '../../../components/common/ErrorBox'
import { useFunnelReport } from '../../../util/hooks/api/FunnelReport/useFunnelReport'
import { generateCopyName } from '../../../util/hooks/api/FunnelReport/utils'

interface Props {
  duplicateFunnelReportId?: number
}

/**
 * ファネルレポートのレポート新規作成エリアをレンダリングする
 *
 * @param {Props} props - コンポーネントのプロパティ
 * @param {number} [props.duplicateFunnelReportId] - コピー元のファネルレポートID（オプション）
 */
export function CreateReportArea({ duplicateFunnelReportId }: Props) {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { projectId, baseUrl },
  } = React.useContext(ProjectContext)

  const canAdd = React.useMemo(
    () => Authority.canAddFunnelReport(AccountInfo.permissions, projectId),
    [AccountInfo.permissions, projectId],
  )

  const { isChecking: isSettingsChecking, isCompleted: isSettingsCompleted } = useReportSettings({ projectId })

  const {
    data: reportsData,
    isLoading: isReportsLoading,
    resetFunnelReports,
  } = useFunnelReports({ projectId, enabled: canAdd })

  const {
    data: duplicateReportData,
    isLoading: isDuplicateReportLoading,
    isError: isDuplicateReportError,
  } = useFunnelReport({
    projectId,
    funnelReportId: duplicateFunnelReportId ?? 0,
    enabled: !!duplicateFunnelReportId && canAdd,
  })

  React.useEffect(() => {
    if (isDuplicateReportError) {
      resetFunnelReports()
    }
  }, [isDuplicateReportError])

  const {
    mutate: createFunnelReportMutate,
    isLoading: isCreating,
    isError: isCreateError,
  } = useCreateFunnelReport({ projectId })

  const initData = duplicateReportData
    ? {
        ...duplicateReportData,
        name: generateCopyName(duplicateReportData.name, MAX_REPORT_NAME_LENGTH),
      }
    : INIT_EDIT_DATA

  const onInvalidate = () => {
    resetFunnelReports()
  }

  const handleCancel = () => {
    navigate(`${baseUrl}report/funnel/`)
  }

  if (!canAdd) return <CanNotAddMessage />
  if (isSettingsChecking || isReportsLoading || isCreating || (duplicateFunnelReportId && isDuplicateReportLoading))
    return <Loading />
  if (!isSettingsCompleted || reportsData?.reportLimitReached) {
    return <Redirect to={`${baseUrl}report/funnel`} noThrow />
  }
  return (
    <>
      {isCreateError && <ErrorBox>ファネルレポートの保存に失敗しました。</ErrorBox>}
      {isDuplicateReportError && <ErrorBox>コピー元のファネルレポートの取得に失敗しました。</ErrorBox>}
      <EditReport
        data={initData}
        saveMutate={createFunnelReportMutate}
        onInvalidate={onInvalidate}
        onCancel={handleCancel}
      />
    </>
  )
}
