import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { Authority } from '../../../util/Authority'
import { AccountInfo } from '../../../GlobalState'
import { Actions, ProjectSettingsType } from '../../../pages/Settings/state'
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle'
import { CancelButton, NormalButton } from '../../common/Button'
import { Input } from '../../common/Input'
import { AccordionBox } from '../../common/AccordionBox'

interface Props {
  projectId: string
  defaultExpanded: boolean
  projectErrorMessage: string | undefined
  project: ProjectSettingsType
  pageActions: Actions
  AccountInfo: AccountInfo
}

export function ProjectInfoPanel({
  defaultExpanded,
  projectErrorMessage,
  project,
  pageActions,
  AccountInfo,
  projectId,
}: Props) {
  return (
    <AccordionBox title="プロジェクト情報" icon={<InfoCircle />} accordionProps={{ defaultExpanded }}>
      {projectErrorMessage && <ErrorMessage data-testid="project-error-message">{projectErrorMessage}</ErrorMessage>}
      <ProjectContainer>
        <FlexColumnBox>
          <Label>プロジェクト名</Label>
          <DesignedInput
            type="text"
            value={project.name}
            onChange={pageActions.project.updateName}
            required
            readOnly={!Authority.canChangeProject(AccountInfo.permissions, projectId!)}
            className="project-name-input"
          />
        </FlexColumnBox>

        <FlexColumnBox>
          <Label>URL</Label>
          <div>{project.url}</div>
        </FlexColumnBox>

        <FlexColumnBox>
          <Label>プロジェクトID</Label>
          <div>{`ID  :  ${project.id}`}</div>
        </FlexColumnBox>

        <ButtonContainer>
          {!project.disabled && <CancelButton onClick={pageActions.project.onCancel}>キャンセル</CancelButton>}
          {Authority.canChangeProject(AccountInfo.permissions, projectId!) && (
            <NormalButton disabled={project.disabled} onClick={pageActions.project.onApply}>
              保存
            </NormalButton>
          )}
        </ButtonContainer>
      </ProjectContainer>
    </AccordionBox>
  )
}

const DesignedInput = styled(Input)`
  width: 250px;
  height: 30px;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`

const ProjectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0 1rem;
`

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const FlexColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1.5rem;
`
