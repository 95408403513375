import { FunnelStepData } from '../../../util/hooks/api/FunnelReport/types'
import { STEP_TYPES } from '../../../util/hooks/api/FunnelReport/constants'
import { CustomFilterState } from '../../../util/hooks/api/Filter/types'
import { DIMENSION_KEYS } from '../../../util/hooks/api/Filter/constants'

/**
 * ステップで指定したゴールでエラーが発生してないかチェックする
 *
 * @param {FunnelStepData[]} steps - The array of funnel steps to check.
 * @return {boolean} エラーが発生している場合はtrue、そうでない場合はfalse
 */
export function checkStepGoalError(steps: FunnelStepData[]): boolean {
  return steps.some((step) => step.stepType === STEP_TYPES.GOAL && !step.stepGoal?.goalId)
}

/**
 * フィルターで指定したゴールでエラーが発生してないかチェックする
 *
 * @param {CustomFilterState[]} filters - The array of funnel filters to check.
 * @return {boolean} エラーが発生している場合はtrue、そうでない場合はfalse
 */
export function checkFilterGoalError(filters: CustomFilterState[]): boolean {
  return filters.some((filter) =>
    filter.dimensions.some((dimension) =>
      dimension.states.some((state) => state.id === DIMENSION_KEYS.GOAL && !state.goalId),
    ),
  )
}

/**
 * フィルターで指定したカスタムディメンションでエラーが発生してないかチェックする
 *
 * @param {CustomFilterState[]} filters - The array of funnel filters to check.
 * @return {boolean} エラーが発生している場合はtrue、そうでない場合はfalse
 */
export function checkFilterCustomDimensionError(filters: CustomFilterState[]): boolean {
  return filters.some((filter) =>
    filter.dimensions.some((dimension) =>
      dimension.states.some((state) => state.id === DIMENSION_KEYS.CUSTOM_DIMENSION && !state.customDimensionId),
    ),
  )
}

/**
 * フィルターで指定したデータインポートでエラーが発生してないかチェックする
 *
 * @param {CustomFilterState[]} filters - The array of funnel filters to check.
 * @return {boolean} エラーが発生している場合はtrue、そうでない場合はfalse
 */
export function checkFilterDataImportError(filters: CustomFilterState[]): boolean {
  return filters.some((filter) =>
    filter.dimensions.some((dimension) =>
      dimension.states.some((state) => state.id === DIMENSION_KEYS.DATA_IMPORT_FIELD && !state.dataImportFieldId),
    ),
  )
}
