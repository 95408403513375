import * as React from 'react'
import {
  CONDITION_TYPES,
  dimensionNumericWithEmptyConditionOptions,
  dimensionStringWithEmptyConditionOptions,
  dimensionNumericConditionOptions,
  dimensionChoiceConditionOptions,
  dimensionDateWithEmptyConditionOptions,
  dimensionStringConditionOptions,
  dimensionBoolConditionOptions,
} from '../../../util/hooks/api/Filter/constants'
import { ConditionTypes, DimensionFilterState } from '../../../util/hooks/api/Filter/types'
import styled from 'styled-components'
import { Select } from '../../common/Select'
import { CustomFilterAction } from '../../../contexts/CustomFilterContext/state'

function getConditionOptions(conditionType: ConditionTypes) {
  switch (conditionType) {
    case CONDITION_TYPES.NUMERIC:
      return dimensionNumericConditionOptions
    case CONDITION_TYPES.NUMERIC_WITH_EMPTY:
      return dimensionNumericWithEmptyConditionOptions
    case CONDITION_TYPES.STRING:
      return dimensionStringConditionOptions
    case CONDITION_TYPES.STRING_WITH_EMPTY:
      return dimensionStringWithEmptyConditionOptions
    case CONDITION_TYPES.CHOICE:
      return dimensionChoiceConditionOptions
    case CONDITION_TYPES.BOOL:
      return dimensionBoolConditionOptions
    case CONDITION_TYPES.DATE_WITH_EMPTY:
      return dimensionDateWithEmptyConditionOptions
    default:
      return [{ value: '', label: '' }]
  }
}

interface Props {
  readonly actions: CustomFilterAction
  state: DimensionFilterState
  filterIndex: number
  dimensionIndex: number
  stateIndex: number
}

export function DimensionItemMatchType({ actions, state, filterIndex, dimensionIndex, stateIndex }: Props) {
  if (!state.id) return null
  return (
    <DesignedConditionTypeSelect
      value={state.condition}
      options={getConditionOptions(state.conditionType)}
      onChange={actions.onDimensionConditionChange}
      // 正規表現検索を含む場合に、DimensionValueのチェックが必要なため、onBlurでチェック関数を呼び出し
      onBlur={() => actions.validateDimensionValue(filterIndex, dimensionIndex)}
      data-index={filterIndex}
      data-dimensionindex={dimensionIndex}
      data-stateindex={stateIndex}
      data-testid="filter-dimension-match-type"
    />
  )
}

const DesignedSelect = styled(Select)`
  height: 30px;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
`

const DesignedConditionTypeSelect = styled(DesignedSelect)`
  margin-left: 14px;
  width: 120px;
`
