import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../../styleConstants'
import { Checkbox } from '../../../components/common/Checkbox'
import { Select } from '../../../components/common/Select'
import { Paginate } from '../../../components/common/Paginate'
import { EmbeddedLoading } from '../../../components/common/EmbeddedLoading'
import { useCaptureHistoryState } from '../CaptureHistoryState'
import { NormalButton } from '../../../components/common/Button'
import { ConfirmModal } from '../../../components/common/ConfirmModal'
import { ErrorBox } from '../../../components/common/ErrorBox'
import { ScreenshotBox } from '../../../components/CaptureHistory/ScreenshotBox'
import { useResize } from '../../../util/hooks/useResize'
import { StatusItem } from '../../../components/CaptureHistory/StatusItem'
import { useDeviceType } from '../../../util/hooks/useDeviceType'

export const CaptureHistoryTab = ({ projectId, urlId }: { projectId: string; urlId: string }) => {
  const {
    captureHistoryState: {
      loading,
      reload,
      pageChange,
      selectedId,
      checked,
      dispCount,
      currentPage,
      pageCount,
      itemCount,
      options,
      histories,
      disabledCapture,
      errorMessage,
      isConfirmModalOpened,
    },
    captureHistoryActions,
  } = useCaptureHistoryState()

  const { deviceType } = useDeviceType()

  useResize()

  const { getCaptureHistory, reGetCaptureHistory } = captureHistoryActions

  React.useEffect(() => {
    if (loading) {
      getCaptureHistory(Number(projectId), Number(urlId), deviceType)
    }
  }, [])

  React.useEffect(() => {
    if (reload) {
      reGetCaptureHistory()
    }
  }, [reload])

  React.useEffect(() => {
    if (pageChange) {
      captureHistoryActions.fetchNextPage()
    }
  }, [pageChange])

  // インデックス計算
  const topIndex = 1 + currentPage * dispCount
  const maxIndex = itemCount < topIndex + dispCount - 1 ? itemCount : topIndex + dispCount - 1

  return (
    <Box>
      {loading || reload || pageChange ? (
        <EmbeddedLoading />
      ) : (
        <Contents style={{ width: histories.length === 0 ? '100%' : '' }}>
          {/* エラーメッセージ */}
          {errorMessage && <ErrorBox data-testid="error-message">{errorMessage}</ErrorBox>}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <NormalButton
              disabled={disabledCapture}
              onClick={() => {
                captureHistoryActions.onCapture()
              }}
            >
              ページのキャプチャリクエスト
            </NormalButton>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '0.5rem',
            }}
          >
            <Checkboxes onClick={captureHistoryActions.onClickCheckbox}>
              <Checkbox
                checked={checked}
                style={{ width: '16px', height: '16px', marginRight: '0.5rem', backgroundColor: colors.white }}
              />
              <div>自動取得の履歴を表示しない</div>
            </Checkboxes>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                visibility: histories.length === 0 ? 'hidden' : 'visible',
              }}
            >
              <div>表示する件数：</div>
              <DesignedSelect options={options} value={dispCount} onChange={captureHistoryActions.onChangeSelect} />
              <div
                style={{
                  marginLeft: '1rem',
                }}
              >{`${topIndex}～${maxIndex}/${itemCount}件`}</div>
            </div>
          </div>

          <Items>
            <Item className="title" style={{ paddingBottom: '0.5rem' }}>
              <ItemBox className="title" style={{ width: '184px' }}>
                リクエスト日時
              </ItemBox>
              <ItemBox className="title" style={{ width: '184px' }}>
                取得完了日時
              </ItemBox>
              <ItemBox className="title" style={{ width: '226px' }}>
                実行ユーザー名
              </ItemBox>
              <ItemBox className="title" style={{ width: '184px' }}>
                ステータス
              </ItemBox>
            </Item>
            {histories.length === 0 ? (
              <Item
                style={{ pointerEvents: 'none' }}
                onClick={() => {
                  return null
                }}
              >
                <ItemBox>ページキャプチャ履歴がありません</ItemBox>。
              </Item>
            ) : (
              histories.map((history, index) => {
                return (
                  <Item
                    className={cc([
                      selectedId === history.id ? 'on' : 'off',
                      history.completeDate === '' ? 'error' : '',
                    ])}
                    key={`history-${index}`}
                    onClick={
                      history.completeDate === ''
                        ? () => {}
                        : () => captureHistoryActions.onClickItem(history.id, history.url)
                    }
                  >
                    <ItemBox style={{ width: '184px' }}>{history.execDate}</ItemBox>
                    <ItemBox style={{ width: '184px' }}>{history.completeDate}</ItemBox>
                    <ItemBox style={{ width: '226px' }}>{history.user}</ItemBox>
                    <ItemBox style={{ width: '184px' }}>
                      <StatusItem
                        status={history.status}
                        errorType={history.errorType}
                        responseErrorHttpStatusCode={history.responseErrorHttpStatusCode}
                      />
                    </ItemBox>
                  </Item>
                )
              })
            )}
          </Items>

          {histories.length !== 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50px',
                marginTop: '0.5rem',
              }}
            >
              <Paginate
                initialPage={currentPage}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                disableInitialCallback={false}
                onPageChange={captureHistoryActions.onPageChange}
              />
            </div>
          )}
        </Contents>
      )}
      <ConfirmModal
        isOpen={isConfirmModalOpened}
        title="キャプチャリクエスト"
        children={
          <>
            <div>ページキャプチャをリクエストしました。</div>
            <div style={{ marginTop: '0.5rem' }}>新しいコンテンツは取得完了後からイベント計測が開始されます。</div>
            <div style={{ marginTop: '1rem' }}>完了までしばらくお待ちください。</div>
          </>
        }
        onClose={captureHistoryActions.onComplete}
      />
      {!loading && !reload && !pageChange && histories.length !== 0 && (
        <ScreenshotArea>
          <ScreenshotBox histories={histories} selectedId={selectedId} />
        </ScreenshotArea>
      )}
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  width: 100%;
`

const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`

const Checkboxes = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

const Items = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: ${colors.white};
`

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  max-width: 840px;
  min-height: 40px;
  align-items: center;
  padding: 0.6rem 0;
  box-sizing: border-box;
  border-top: 1px solid ${colors.gray300};
  border-left: 1px solid ${colors.white};
  border-right: 1px solid ${colors.white};

  &:not(.title):not(.error) {
    cursor: pointer;
  }

  &:first-child {
    border-top: none;
  }

  &:hover:not(.title) {
    background-color: ${colors.gray50};
    &.on {
      background-color: ${colors.contentOrange.orange1};
    }
  }

  &.on {
    border-top: 1px solid ${colors.orange};
    border-left: 1px solid ${colors.orange};
    border-right: 1px solid ${colors.orange};
    background-color: ${colors.contentOrange.orange1};
  }

  &.on + & {
    border-top: 1px solid ${colors.orange};
  }

  &.on:last-child {
    border-bottom: 1px solid ${colors.orange};
  }
`

const ItemBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;
  color: ${colors.black};

  & + & {
    margin-left: 1rem;
  }

  &.title {
    color: ${colors.gray500};
  }
`

const ScreenshotArea = styled.div`
  width: 300px;
  height: 594px;
  margin-left: 24px;
  top: 77px;
`

const DesignedSelect = styled(Select)`
  width: 60px;
  height: 25px;
  padding: 0;
`
