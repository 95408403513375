// ユーザー名に使用可能な文字
export const UserNameRegex: string = '[A-Za-z0-9_-]*'

// ユーザー名の最低必要文字数
export const UserNameMinLength: number = 2

// 文字列が記号のみでないかをチェックする
export function checkUserNamePattern(userName: string): boolean {
  const result = [userName.match(RegExp('[A-Za-z0-9]')), userName.match(RegExp('[_-]'))]
  return !(result[0] === null && result[1] !== null)
}
