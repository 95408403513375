import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { colors, layout } from '../styleConstants'
import { AdminNormalButton } from '../components/common/Button'
import { SimplePageLayout } from '../components/layout/SimplePageLayout'
import { PageHeader } from '../components/common/PageHeader'
import { Tools } from '@styled-icons/bootstrap/Tools'

export function Maintenance(_: RouteComponentProps) {
  return (
    <SimplePageLayout>
      <PageHeader title="メンテナンス" description="メンテナンス" />
      <Title>サーバーメンテナンス</Title>
      <Icon />
      <MessageContainer>
        <Label>申し訳ありません。</Label>
        <Label>現在システムメンテナンスをおこなっております。</Label>
        <Label>時間をおいてアクセスしてください。</Label>
      </MessageContainer>
      <AdminNormalButton to="/login" style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
        ログインページへ
      </AdminNormalButton>
    </SimplePageLayout>
  )
}

const Title = styled.h1`
  text-align: center;
  color: ${colors.lightBlue};
`

const Label = styled.div`
  color: ${colors.gray600};
  font-weight: ${layout.boldFontWeight};
  font-size: 1.3rem;
  margin-top: 1rem;
`

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 0.5rem;
`

const Icon = styled(Tools).attrs({
  size: '64',
  color: `${colors.lightBlue}`,
})`
  display: block;
  margin: 1rem auto;
`
