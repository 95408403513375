import React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { useReportSettings } from '../../../util/hooks/useReportSettings'
import { WarningBox } from '../../warnings/WarningBox'
import { TrackingCodePvNotice } from '../../messages/TrackingCodePvNotice'
interface Props {
  readonly projectId: number
  readonly baseUrl: string
  readonly warningMessageComponent?: React.ReactNode
}

/**
 * レポートを表示する準備が未完了の場合に、設定誘導のメッセージを表示するコンポーネント
 *
 * @param {object} props - The component props.
 * @param {number} props.projectId - The ID of the project.
 * @param {string} props.baseUrl - `http` から始まり `projects/1/` までのURLをセットする
 * @param {React.ReactNode} props.warningMessageComponent - WarningBoxに表示するメッセージをカスタマイズする際に利用
 */
export const NotSettingsMessages = ({ projectId, baseUrl, warningMessageComponent }: Props) => {
  const { isCompleted, goalsExists, trackingCodeSet, pageCapturesExists } = useReportSettings({
    projectId,
  })

  if (isCompleted) return null

  return (
    <>
      <WarningBox>{warningMessageComponent ? warningMessageComponent : <DefaultWarningMessage />}</WarningBox>
      {!trackingCodeSet && (
        <CautionBox>
          <div>
            <CautionLabel>トラッキングコードの設置</CautionLabel>
            <CautionMessage>
              トラッキングコードを設置していない場合は、コードを挿入してください。
              <br />
              <TrackingCodePvNotice />
            </CautionMessage>
          </div>
          <div>
            <Link href={`${baseUrl}settings/trackingcode`}>トラッキングコード取得へ ＞</Link>
          </div>
        </CautionBox>
      )}
      {!pageCapturesExists && (
        <CautionBox>
          <div>
            <CautionLabel>計測対象URLの設定</CautionLabel>
            <CautionMessage>
              レポートの計測を開始するには、自動クローラー設定で計測対象のURLを登録してください。
            </CautionMessage>
          </div>
          <div>
            <Link href={`${baseUrl}capture/control`}>自動クローラー設定へ ＞</Link>
          </div>
        </CautionBox>
      )}
      {!goalsExists && (
        <CautionBox>
          <div>
            <CautionLabel>ゴールの設定</CautionLabel>
            <CautionMessage>ゴールが０件です。</CautionMessage>
            <CautionMessage>ゴールを１つ以上設定してください。</CautionMessage>
          </div>
          <div>
            <Link href={`${baseUrl}settings/goal`}>ゴールの設定へ ＞</Link>
          </div>
        </CautionBox>
      )}
    </>
  )
}

const DefaultWarningMessage = () => {
  return (
    <>
      <div>レポートはまだありません。</div>
      <div>以下の設定が完了するとレポートが表示されます。</div>
    </>
  )
}

const CautionBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.orange};
  background-color: ${colors.white};
  padding: 14px 28px;
  margin-bottom: 16px;
`

const CautionLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
`

const CautionMessage = styled.div`
  color: ${colors.orange};
  font-weight: 600;
  font-size: 12px;
`

const Link = styled.a`
  font-size: 11px;
  margin: 0 8px;
  color: ${colors.link.base};

  &:visited {
    color: ${colors.link.visited};
  }
`
