import * as React from 'react'
import styled from 'styled-components'
import { CloseCircle } from '@styled-icons/ionicons-sharp/CloseCircle'

import { colors } from '../../../styleConstants'
import { Input } from '../../common/Input'
import { PlusAddButton } from '../../buttons/PlusAddButton'

interface State {
  readonly tagNames: string[]
  readonly setTagNames: (value: string[]) => void
}

export function TagNamesArea({ tagNames, setTagNames }: State) {
  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newNames = [...tagNames]
    newNames[index] = event.target.value
    setTagNames(newNames)
  }

  const handleAddInput = () => {
    setTagNames([...tagNames, ''])
  }

  const handleRemoveInput = (index: number) => {
    const newNames = [...tagNames]
    newNames.splice(index, 1)
    setTagNames(newNames)
  }

  return (
    <>
      <Label>タグ</Label>
      <MainArea>
        {tagNames.map((tag, index) => {
          return (
            <TagInputWrapper key={index}>
              <TagInput
                type="text"
                value={tag}
                onChange={(event) => handleInputChange(index, event)}
                iconRight={<SmallCloseButton onClick={() => handleRemoveInput(index)} />}
                maxLength={20}
                fontSize={16}
                placeholder={'任意のタグ名'}
                // 追加ボタンでタグテキストボックスを増やした際にautoFocusされるよう設定
                autoFocus={true}
              />
            </TagInputWrapper>
          )
        })}
        {tagNames.length < MAX_TAGS_LENGTH && (
          <PlusAddButtonWrapper>
            <PlusAddButton onClick={handleAddInput} />
          </PlusAddButtonWrapper>
        )}
      </MainArea>
    </>
  )
}

const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
`

const SmallCloseButton = styled(CloseCircle).attrs({
  size: 16,
  color: `${colors.gray600}`,
})`
  cursor: pointer;
  margin-right: 8px;
`

const MainArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
`

const TagInputWrapper = styled.div`
  display: flex;
  align-items: center;
  // 幅に少し余裕があったため、多くの文字が見えるようにFWよりwidthを大きくしている。
  width: 200px;
  height: 40px;
`

const TagInput = styled(Input)`
  width: 100%;
  height: 100%;
`

const PlusAddButtonWrapper = styled(TagInputWrapper)`
  width: auto;
`

const MAX_TAGS_LENGTH = 10
