import React from 'react'
import styled from 'styled-components'
import { StepTransaction } from '../EditReport/StepTransaction'
import { colors, DATA_BAR_COLORS } from '../../../styleConstants'
import { DataDataBar } from '../../../components/DataTable'
import { FunnelStepData } from '../../../util/hooks/api/FunnelReport/types'
import { InputErrorMessage } from '../../../components/errors/InputErrorMessage'
import { Popup, PopupContent } from '../../../components/Popup'
import { checkStepGoalError } from '../EditReport/utils'
import {
  STEP_TYPE_OPTIONS,
  STEP_TYPES,
  STEP_URL_MATCH_TYPE_OPTIONS,
} from '../../../util/hooks/api/FunnelReport/constants'

interface Props {
  steps: FunnelStepData[]
  setRefs: (refArray: (HTMLDivElement | null)[]) => void
  maxHeights: number[]
}

export function Steps({ steps, setRefs, maxHeights }: Props) {
  const stepTitleRefs = React.useRef<(HTMLDivElement | null)[]>([])

  React.useEffect(() => {
    setRefs(stepTitleRefs.current)
  }, [setRefs])

  return (
    <>
      {steps.map((step, stepIndex) => {
        const hasGoalError = checkStepGoalError([step])
        const popupContents = hasGoalError ? [] : makeStepPopupContents(step)
        const isLastStep = stepIndex === steps.length - 1

        return (
          <React.Fragment key={stepIndex}>
            {stepIndex > 0 && (
              <StepTransaction transitionCount={step.transitionCount} transitionRate={step.transitionRate} />
            )}

            <StepBox hasError={hasGoalError}>
              <Popup
                contents={popupContents}
                offset={{ x: 8, y: isLastStep ? 10 : -16 }}
                verticalDirection={isLastStep ? 'top' : 'bottom'}
              >
                <StepTitle
                  hasError={hasGoalError}
                  ref={(el) => (stepTitleRefs.current[stepIndex] = el)}
                  style={{ height: maxHeights[stepIndex] }}
                >
                  <div>{stepIndex + 1}.</div>
                  <div>{step.name}</div>
                </StepTitle>
              </Popup>

              {hasGoalError ? (
                <InputErrorMessageWrapper>
                  <InputErrorMessage>指定したゴールが削除されています。</InputErrorMessage>
                </InputErrorMessageWrapper>
              ) : (
                <StepAccessCount>{step.accessCount !== null ? step.accessCount.toLocaleString() : '-'}</StepAccessCount>
              )}

              {step.accessCount !== null && (
                <StyledDataDataBar
                  barPercent={step.accessCountScaledPercent ?? 0}
                  barColor={DATA_BAR_COLORS.FUNNEL_REPORT_COUNT}
                />
              )}
            </StepBox>
          </React.Fragment>
        )
      })}
    </>
  )
}

export const StepBox = styled.div<{ hasError: boolean }>`
  border: 1px solid ${colors.contentBlue.blue5};
  border-radius: 5px 5px 0 0;
  position: relative;
  ${({ hasError }) =>
    hasError &&
    `
      border-color: ${colors.disabled};
  `}
`

export const StepTitle = styled.div<{ hasError: boolean }>`
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  padding: 4px 8px;
  background-color: ${colors.bg};
  color: ${colors.link.base};
  ${({ hasError }) =>
    hasError &&
    `
      background-color: ${colors.gray325};
      color: ${colors.gray750};
  `}
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  word-break: break-all;
`

export const InputErrorMessageWrapper = styled.div`
  padding: 8px 24px;
  background-color: ${colors.white};
`

export const StepAccessCount = styled.div`
  padding: 8px 0;
  background-color: ${colors.white};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`

const StyledDataDataBar = styled(DataDataBar)`
  padding: 0;
  height: 16px;
`

/**
 * ステップの内容を元にポップアップコンテンツを作成する
 *
 * @param {FunnelStepData} step - ステップデータ
 * @return {PopupContent[]} ポップアップコンテンツの配列
 */
const makeStepPopupContents = (step: FunnelStepData): PopupContent[] => {
  const stepTypeLabel = STEP_TYPE_OPTIONS.find((option) => option.value === step.stepType)?.label || ''
  const title = `ステップタイプ : ${stepTypeLabel}`
  const content = (() => {
    if (step.stepType === STEP_TYPES.URL && step.stepUrl) {
      const matchTypeLabel =
        STEP_URL_MATCH_TYPE_OPTIONS.find((option) => option.value === step.stepUrl?.matchType)?.label || ''
      return `"${step.stepUrl.url}"で${matchTypeLabel}`
    } else if (step.stepType === STEP_TYPES.GOAL && step.stepGoal && step.stepGoal.goalName) {
      return `"${step.stepGoal.goalName}"を含む`
    }
    return ''
  })()

  return [
    {
      title,
      items: [content],
    },
  ]
}
