import React from 'react'

import { WarningBox } from '../WarningBox'

/**
 * ユーザーに編集権限がないことを示すメッセージを表示する
 */
export const CanNotChangeMessage = () => {
  return <WarningBox>編集権限がありません。</WarningBox>
}
