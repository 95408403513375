import * as React from 'react'
import { useCookies } from 'react-cookie'
import { navigate } from '@gatsbyjs/reach-router'
import { request } from '../../../util/request'
import { ProjectsData } from '../../../util/Response'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly loading: boolean
  readonly userId: number
  readonly projects: { id: number; name: string; url: string }[]
  readonly errorMessage?: string
}

class Actions {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly setCookie: any,
  ) {}

  fetchProject = async (userId: number) => {
    try {
      const json: ProjectsData = await request('GET', `/api/projects/`, true)
      const projects = json.results.map((project) => {
        return {
          id: project.id,
          name: project.name,
          url: project.url,
        }
      })
      this.setState({
        ...this.state,
        loading: false,
        userId: userId,
        projects: projects,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        errorMessage: typeof e === 'string' ? e : 'プロジェクトの取得に失敗しました。',
      })
    }
  }

  onClick = (id: number) => {
    this.setCookie('projectId', { userId: this.state.userId, id: id }, { path: '/' })
    navigate('/')
  }
}

const initialState: State = {
  loading: true,
  userId: 0,
  projects: [],
  errorMessage: '',
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const setCookie = useCookies()[1]
  const actions = new Actions(state, setState, setCookie)
  return { state, actions }
}
