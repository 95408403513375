import * as React from 'react'
import styled from 'styled-components'
import { State, Actions } from './state'
import { GoalStep } from './goal'
import { QueryStep } from './query'
import { MemberStep } from './member'
import { IpAddressStep } from './ipAddress'
import { CrossDomainStep } from './crossDomain'
import { InviteStep } from './invite'
import { Role, RoleLabel } from '../../util/Authority'
import { GoalType } from '../../util/Goal'
import { colors } from '../../styleConstants'
import { CancelButton, NormalButton } from '../../components/common/Button'
import { Input } from '../../components/common/Input'
import { Select } from '../../components/common/Select'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../../components/common/Modal'
import { ConfirmModal } from '../../components/common/ConfirmModal'
import { DimensionModal } from '../../components/modal/DimensionModal'
import { CreateGoal } from '../../components/modal/CreateGoal'
import { GoalEventEdit } from '../../components/modal/GoalEventEdit'
import { IpAddressSetting } from '../../components/modal/IpAddressSetting'
import { CrossDomainSetting } from '../../components/modal/CrossDomainSetting'
import { WarningBox } from '../../components/common/WarningBox'
import { useGoalList } from '../../util/hooks/api/Goal/useGoalList'

interface Props {
  readonly state: State
  readonly actions: Actions
}

const INVITE_MEMBER_MODAL_TITLE = 'メンバーを招待する'

export function SettingModals(props: Props) {
  const {
    state: { goal, member, query, dimension, ipAddress, crossDomain, invite, modalErrorMessage },
  } = props

  const { invalidate } = useGoalList({ projectId: props.state.project.id })

  const handleCreateGoal = async () => {
    await props.actions.goal.onApplyCreate()
    await invalidate()
  }

  const handleEditGoalUrl = async () => {
    await props.actions.goal.onApplyUrlEdit()
    await invalidate()
  }

  const handleEditGoalEvent = async () => {
    await props.actions.goal.onApplyEventEdit()
    await invalidate()
  }

  const handleDeleteGoal = async () => {
    await props.actions.goal.onApplyDelete()
    await invalidate()
  }

  return (
    <div>
      {/* 新規ゴール作成 */}
      {goal.step === GoalStep.createExec && (
        <CreateGoal
          opened={goal.step === GoalStep.createExec}
          disabled={goal.createDisabled}
          type={goal.type}
          name={goal.name}
          condition={goal.condition}
          url={goal.url}
          eventSelected={goal.eventSelected}
          eventCondition={goal.eventCondition}
          eventValue={goal.eventValue}
          onSetType={props.actions.goal.onSetType}
          onSetName={props.actions.goal.onSetName}
          onSetCondition={props.actions.goal.onSetCondition}
          onSetUrl={props.actions.goal.onSetUrl}
          onSetEventSelected={props.actions.goal.onSetEventSelected}
          onSetEventCondition={props.actions.goal.onSetEventCondition}
          onSetEventValue={props.actions.goal.onSetEventValue}
          onCancel={props.actions.goal.onCancelCreate}
          onApply={handleCreateGoal}
        />
      )}

      {/* ゴールURL編集 */}
      {goal.step === GoalStep.editExec && goal.type === GoalType.url && (
        <Modal
          isOpen={goal.step === GoalStep.editExec}
          width={800}
          title="ゴールの編集"
          onClose={props.actions.goal.onCancelEdit}
        >
          <ModalContentWrapper>
            <WarningBoxWrapper>
              <WarningBox>URLの末尾にスラッシュ（/）がある場合、スラッシュを除外して設定してください。</WarningBox>
            </WarningBoxWrapper>
            <WarningBoxWrapper>
              <WarningBox>
                <div>
                  パラメーターを含めたURLを目標URLにする場合は、「クエリーパラメーター」にそのパラメーターが設定されていることを確認してください。
                </div>
                <div>
                  クエリパラメーターに設定がない場合、URLのクエリパラメーター（?〜）は削除されるため、目標URLにパラメーターを付与しても計測がされません。
                </div>
              </WarningBox>
            </WarningBoxWrapper>
            <EditItem className="title">
              <EditBox style={{ width: '200px' }}>目標名</EditBox>
              <EditBox style={{ width: '150px' }}>条件</EditBox>
              <EditBox>目標URL</EditBox>
            </EditItem>

            <EditItem>
              <EditInput
                type="text"
                value={goal.name}
                required
                onChange={props.actions.goal.updateUrlName}
                style={{ width: '200px' }}
              />
              <EditSelect
                defaultValue={goal.condition}
                options={goal.goalOptions}
                onChange={props.actions.goal.updateUrlCondition}
                style={{ marginLeft: '1.5rem' }}
              />
              <EditInput
                type="text"
                value={goal.url}
                required
                onChange={props.actions.goal.updateUrl}
                style={{ width: '330px', marginLeft: '1.5rem' }}
              />
            </EditItem>
          </ModalContentWrapper>

          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.goal.onCancelEdit}>キャンセル</CancelButton>
            <NormalButton disabled={goal.disabled} onClick={handleEditGoalUrl}>
              変更
            </NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}
      {/* ゴールイベント編集 */}
      {goal.step === GoalStep.editExec && goal.type === GoalType.event && (
        <GoalEventEdit
          opened={goal.step === GoalStep.editExec}
          disabled={goal.disabled}
          name={goal.name}
          selected={goal.eventSelected}
          condition={goal.eventCondition}
          value={goal.eventValue}
          onEditName={props.actions.goal.updateEventName}
          onEditSelected={props.actions.goal.updateEventSelected}
          onEditCondition={props.actions.goal.updateEventCondition}
          onEditValue={props.actions.goal.updateEventValue}
          onCancel={props.actions.goal.onCancelEdit}
          onApply={handleEditGoalEvent}
        />
      )}

      {/* ゴール削除 */}
      {goal.step === GoalStep.deleteExec && (
        <Modal
          isOpen={goal.step === GoalStep.deleteExec}
          onClose={props.actions.goal.onCancelDelete}
          title="ゴールの削除"
          width={450}
        >
          <ModalContentWrapper>
            目標名：{goal.deleteName}
            <br />
            このゴールを削除してもよろしいですか？
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.goal.onCancelDelete}>キャンセル</CancelButton>
            <NormalButton onClick={handleDeleteGoal}>実行</NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* クエリーパラメータ追加 */}
      {query.step === QueryStep.createExec && (
        <Modal
          isOpen={query.step === QueryStep.createExec}
          width={800}
          title="クエリーパラメータの追加"
          onClose={props.actions.query.onCancelAdd}
        >
          <ModalContentWrapper>
            <EditBox style={{ width: '100%', height: '40px' }}>クエリーパラメータ</EditBox>
            <EditInput
              type="text"
              value={query.editQuery}
              required
              onChange={props.actions.query.onChangeAdd}
              style={{ width: '100%' }}
            />
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.query.onCancelAdd}>キャンセル</CancelButton>
            <NormalButton disabled={query.disabled} onClick={props.actions.query.onApplyAdd}>
              追加
            </NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* クエリーパラメータ編集 */}
      {query.step === QueryStep.editExec && (
        <Modal
          isOpen={query.step === QueryStep.editExec}
          width={600}
          title="クエリーパラメータを編集する"
          onClose={props.actions.query.onCancelEdit}
        >
          <ModalContentWrapper>
            <EditItem style={{ height: '30px', marginBottom: '0.5rem' }}>クエリーパラメータ</EditItem>
            <EditInput
              type="text"
              value={query.editQuery}
              required
              onChange={props.actions.query.onChange}
              style={{ width: '100%', height: '40px' }}
            />
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.query.onCancelEdit}>キャンセル</CancelButton>
            <NormalButton disabled={query.disabled} onClick={props.actions.query.onApplyEdit}>
              変更
            </NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* クエリーパラメータ削除 */}
      {query.step === QueryStep.deleteExec && (
        <Modal
          isOpen={query.step === QueryStep.deleteExec}
          onClose={props.actions.query.onCancelDelete}
          title="クエリーパラメータの削除"
          width={450}
        >
          <ModalContentWrapper>
            クエリパラメータ：{query.editQuery}
            <br />
            このクエリーパラメータ削除してもよろしいですか？
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.query.onCancelDelete}>キャンセル</CancelButton>
            <NormalButton onClick={props.actions.query.onApplyDelete}>実行</NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* IPアドレスフィルター作成 */}
      {ipAddress.step === IpAddressStep.createExec && (
        <IpAddressSetting
          created={true}
          opened={ipAddress.step === IpAddressStep.createExec}
          disabled={ipAddress.disabled}
          name={ipAddress.name}
          condition={ipAddress.condition}
          ipAddress={ipAddress.ipAddress}
          onChangeName={props.actions.ipAddress.onCreateName}
          onChangeCondition={props.actions.ipAddress.onCreateCondition}
          onChangeIpAddress={props.actions.ipAddress.onCreateIpAddress}
          onCancel={props.actions.ipAddress.onCreateCancel}
          onApply={props.actions.ipAddress.onCreateApply}
        />
      )}

      {/* IPアドレスフィルター編集 */}
      {ipAddress.step === IpAddressStep.editExec && (
        <IpAddressSetting
          created={false}
          opened={ipAddress.step === IpAddressStep.editExec}
          disabled={ipAddress.disabled}
          name={ipAddress.name}
          condition={ipAddress.condition}
          ipAddress={ipAddress.ipAddress}
          onChangeName={props.actions.ipAddress.onEditName}
          onChangeCondition={props.actions.ipAddress.onEditCondition}
          onChangeIpAddress={props.actions.ipAddress.onEditIpAddress}
          onCancel={props.actions.ipAddress.onEditCancel}
          onApply={props.actions.ipAddress.onEditApply}
        />
      )}

      {/* IPアドレスフィルター削除 */}
      {ipAddress.step === IpAddressStep.deleteExec && (
        <Modal
          isOpen={ipAddress.step === IpAddressStep.deleteExec}
          onClose={props.actions.ipAddress.onDeleteCancel}
          title="IPアドレスの削除"
          width={450}
        >
          <ModalContentWrapper>
            フィルタ名：{ipAddress.name}
            <br />
            IPアドレス：{ipAddress.ipAddress}
            <br />
            このIPアドレスを削除してもよろしいですか？
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.ipAddress.onDeleteCancel}>キャンセル</CancelButton>
            <NormalButton onClick={props.actions.ipAddress.onDeleteApply}>実行</NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* メンバー招待 */}
      {member.step === MemberStep.invite && (
        <Modal
          isOpen={member.step === MemberStep.invite}
          width={600}
          title={INVITE_MEMBER_MODAL_TITLE}
          onClose={props.actions.member.onCancelAdd}
        >
          <ModalContentWrapper>
            <div style={{ fontSize: '1.2rem' }}>招待するメンバーのメールアドレスを入力してください。</div>
            <div style={{ marginTop: '1.5rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>メールアドレス</div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <DesignedInput
                type="email"
                required
                value={member.email}
                style={{ marginBottom: '0' }}
                onChange={props.actions.member.updateInviteEmail}
              />
              <DesignedSelect
                options={[
                  { label: RoleLabel.Project.member, value: Role.Project.member },
                  { label: RoleLabel.Project.admin, value: Role.Project.admin },
                ]}
                defaultValue={member.inviteMemberRole}
                onChange={props.actions.member.updateInviteRole}
              />
            </div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.member.onCancelAdd}>キャンセル</CancelButton>
            <NormalButton disabled={member.inviteDisabled} onClick={props.actions.member.onConfirmAdd}>
              次へ
            </NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}
      {member.step === MemberStep.inviteConfirm && (
        <Modal
          isOpen={member.step === MemberStep.inviteConfirm}
          width={600}
          title={INVITE_MEMBER_MODAL_TITLE}
          onClose={props.actions.member.onCancelAdd}
        >
          <ModalContentWrapper>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '1.2rem', marginTop: '0.5rem' }}>以下の内容でメンバーを招待します。</div>
              <div style={{ marginTop: '1.5rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>メールアドレス</div>
              <div>{member.email}</div>
              <div style={{ marginTop: '1.5rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>権限</div>
              <div>
                {Role.Project.member === member.inviteMemberRole ? RoleLabel.Project.member : RoleLabel.Project.admin}
              </div>
              {modalErrorMessage && (
                <ErrorMessage style={{ marginTop: '1rem' }} data-testid="modal-error-message">
                  {modalErrorMessage}
                </ErrorMessage>
              )}
            </div>
          </ModalContentWrapper>
          <Buttons>
            <Link onClick={props.actions.member.onBackAddInfo}>＜ 戻る</Link>
            <ModalButtonWrapper>
              <CancelButton onClick={props.actions.member.onCancelAdd}>キャンセル</CancelButton>
              <NormalButton disabled={member.inviteDisabled} onClick={props.actions.member.onApplyAdd}>
                招待
              </NormalButton>
            </ModalButtonWrapper>
          </Buttons>
        </Modal>
      )}

      {/* 権限の変更 */}
      {member.step === MemberStep.editExec && (
        <Modal
          isOpen={member.step === MemberStep.editExec}
          width={600}
          title="権限の変更"
          onClose={props.actions.member.onCancelEdit}
        >
          <ModalContentWrapper>
            <div style={{ margin: '0.5rem 0 2rem' }}>権限を選択して「変更する」ボタンを押してください。</div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <div style={{ color: `${colors.gray600}` }}>ユーザー名</div>
                <div style={{ marginTop: '0.3rem', fontWeight: 'bold', fontSize: '1.2rem' }}>{member.selectedName}</div>
              </div>
              <div>
                <DesignedSelect
                  options={[
                    { label: RoleLabel.Project.member, value: Role.Project.member },
                    { label: RoleLabel.Project.admin, value: Role.Project.admin },
                  ]}
                  defaultValue={member.selectedRole}
                  onChange={props.actions.member.updateRole}
                />
              </div>
            </div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.member.onCancelEdit}>キャンセル</CancelButton>
            <NormalButton disabled={member.editDisabled} onClick={props.actions.member.onApplyEdit}>
              変更する
            </NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* メンバー削除 */}
      {member.step === MemberStep.deleteExec && (
        <Modal
          isOpen={member.step === MemberStep.deleteExec}
          width={450}
          title="プロジェクトメンバーの削除"
          onClose={props.actions.member.onCancelDelete}
        >
          <ModalContentWrapper>
            <div style={{ fontWeight: 'bold' }}>{member.selectedName}</div>
            <div style={{ marginTop: '1rem' }}>上記のメンバーをプロジェクトから削除します。</div>
            <div>この操作は取り消せません。</div>
            <div>本当に削除しますか？</div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.member.onCancelDelete}>キャンセル</CancelButton>
            <NormalButton onClick={props.actions.member.onApplyDelete}>削除</NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* カスタムディメンション */}
      {dimension.opened && (
        <DimensionModal
          created={dimension.created}
          opened={dimension.opened}
          disabled={dimension.disabled}
          index={dimension.index}
          name={dimension.name}
          scope={dimension.scope}
          querySourced={dimension.querySourced}
          activated={dimension.activated}
          onNameUpdate={props.actions.dimension.onNameUpdate}
          onScopeChange={props.actions.dimension.onScopeChange}
          onQuerySourcedChange={props.actions.dimension.onQuerySourcedChange}
          onActivatedChange={props.actions.dimension.onActivatedChange}
          onCancel={props.actions.dimension.onCancel}
          onCreate={props.actions.dimension.onCreate}
          onApply={props.actions.dimension.onApply}
        />
      )}

      {/* クロスドメイン追加 */}
      {crossDomain.step === CrossDomainStep.addExec && (
        <CrossDomainSetting
          created={true}
          opened={crossDomain.step === CrossDomainStep.addExec}
          disabled={crossDomain.disabled}
          name={crossDomain.name}
          subdomain={crossDomain.subdomain}
          onChangeName={props.actions.crossDomain.onAddName}
          onClickSubdomain={props.actions.crossDomain.onAddSubdomain}
          onCancel={props.actions.crossDomain.onAddCancel}
          onApply={props.actions.crossDomain.onAddApply}
        />
      )}
      {crossDomain.step === CrossDomainStep.addComplete && (
        <ConfirmModal
          isOpen={crossDomain.step === CrossDomainStep.addComplete}
          title="許可するドメインの追加"
          children={
            <div style={{ textAlign: 'center' }}>
              <p style={{ margin: 0 }}>許可するドメインを追加しました。</p>
            </div>
          }
          onClose={props.actions.crossDomain.onAddComplete}
        />
      )}

      {/* クロスドメイン編集 */}
      {crossDomain.step === CrossDomainStep.editExec && (
        <CrossDomainSetting
          created={false}
          opened={crossDomain.step === CrossDomainStep.editExec}
          disabled={crossDomain.disabled}
          name={crossDomain.name}
          subdomain={crossDomain.subdomain}
          onChangeName={props.actions.crossDomain.onEditName}
          onClickSubdomain={props.actions.crossDomain.onEditSubdomain}
          onCancel={props.actions.crossDomain.onEditCancel}
          onApply={props.actions.crossDomain.onEditApply}
        />
      )}

      {/* 許可するドメイン削除 */}
      {crossDomain.step === CrossDomainStep.deleteExec && (
        <Modal
          isOpen={crossDomain.step === CrossDomainStep.deleteExec}
          onClose={props.actions.crossDomain.onDeleteCancel}
          title="許可するドメインの削除"
          width={450}
        >
          <ModalContentWrapper>
            ドメイン：{crossDomain.name}
            <br />
            このドメインを削除してもよろしいですか？
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.crossDomain.onDeleteCancel}>キャンセル</CancelButton>
            <NormalButton onClick={props.actions.crossDomain.onDeleteApply}>実行</NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* 招待取り消し */}
      {invite.step === InviteStep.confirm && (
        <Modal
          isOpen={invite.step === InviteStep.confirm}
          width={450}
          title="招待の取り消し"
          onClose={props.actions.invite.onCancel}
        >
          <ModalContentWrapper>
            <div style={{ fontWeight: 'bold' }}>{invite.selectedEmail}</div>
            <div style={{ marginTop: '1rem' }}>上記のユーザーの招待を取り消します。</div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.invite.onCancel}>キャンセル</CancelButton>
            <NormalButton onClick={props.actions.invite.onApply}>実行</NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}
    </div>
  )
}

const EditItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 0.5rem 0;

  & + & {
    margin-top: 1rem;
  }

  &.title {
    height: 40px;
    border-bottom: 1px solid ${colors.gray300};
  }
`

const EditBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;
  color: ${colors.gray500};

  & + & {
    margin-left: 1.5rem;
  }
`

const EditInput = styled(Input)`
  height: 30px;
`

const EditSelect = styled(Select)`
  width: 150px;
  height: 30px;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
`

const DesignedInput = styled(Input)`
  width: 250px;
  height: 30px;
  margin-bottom: 1.5rem;
`

const DesignedSelect = styled(Select)`
  width: 180px;
  height: 30px;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
`

const Link = styled.a`
  color: ${colors.link.base};
  text-decoration: underline;
  cursor: pointer;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`
const WarningBoxWrapper = styled.div`
  margin-bottom: 16px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 16px;
`
