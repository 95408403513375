import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { GlobalContext } from '../GlobalState'
import { colors } from '../styleConstants'
import { HELP_LINKS } from '../constants'
import { usePageState } from './CaptureHistory/state'
import { PageLayout } from '../components/layout/PageLayout'
import { Loading } from '../components/common/Loading'
import { PageHeader } from '../components/common/PageHeader'
import { Paginate } from '../components/common/Paginate'
import { Select } from '../components/common/Select'
import { Input } from '../components/common/Input'
import { HelpLink } from '../components/common/HelpLink'
import { CaptureHistoryNarrowDown } from '../components/filter/CaptureHistoryNarrowDown'
import { CaptureHistoryGrid } from '../components/grid/CaptureHistoryGrid'
import { gridSize } from '../components/grid/CaptureHistoryGridItem'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CloseCircle } from '@styled-icons/ionicons-sharp/CloseCircle'
import { Warning } from '@styled-icons/icomoon/Warning'
import { captureHistoryStep } from './CaptureHistory/state'
import { ConfirmModal } from '../components/common/ConfirmModal'
import { CaptureHistory as CaptureHistoryModal } from '../components/modal/CaptureHistory'
import { makeProjectPageHeaderInfo } from '../util/makePageHeaderInfo'
import { DeviceTypeForAPI } from '../util/hooks/useDeviceType'

const PAGE_TITLE = 'ページキャプチャ｜キャプチャ履歴'
interface CaptureHistoryProps extends RouteComponentProps {
  projectId?: string
}

export function CaptureHistory(props: CaptureHistoryProps) {
  const {
    state: { AccountInfo, captureHistoryState, PlanInfo },
    actions: globalActions,
  } = React.useContext(GlobalContext)
  const { state: pageState, actions } = usePageState()
  const {
    loading,
    reload,
    items,
    monthOptions,
    requestCondition,
    captureCount,
    caputureRemaining,
    isPageChange,
    isGridScroll,
    pageCount,
    currentPage,
    itemCount,
    search,
    divRef,
    errorMessage,
    projectId,
    history,
    urlId,
    pageLayout,
  } = pageState

  React.useEffect(() => {
    if (loading) {
      actions.fetch(
        props.projectId!,
        captureHistoryState.searchText,
        captureHistoryState.status,
        captureHistoryState.text,
        captureHistoryState.dispCount,
        PlanInfo.reportDaysLimit,
      )
    }
  }, [])
  React.useEffect(() => {
    if (reload) {
      actions.refetch(captureHistoryState.dispCount)
    }
  }, [reload])
  React.useEffect(() => {
    if (isPageChange) {
      actions.fetchNextPage()
    }
  }, [isPageChange])
  React.useEffect(() => {
    if (isGridScroll) {
      actions.gridScroll()
    }
  }, [isGridScroll])

  // 検索実行
  const onSearch = (event: any) => {
    event.preventDefault()
    actions.search()
    // グローバルステータスに保存
    globalActions.setCaptureHistorySearchText(search.searchText)
  }
  // 検索条件クリア
  const onClear = () => {
    actions.clear()
    globalActions.setCaptureHistorySearchText('')
  }
  // 表示件数変更
  const onDispChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    globalActions.onCaptureHistoryDispChange(e)
    actions.onDispCountChange()
  }
  // 絞り込み実行
  const onApply = () => {
    // 高度な絞り込み実行時には入力フォームはクリアする
    globalActions.setCaptureHistorySearchText('')
    // 条件保存
    globalActions.setCaptureHistoryNarrowDown(search.status, search.text)
    actions.narrowDown.onApply()
  }
  // 絞り込みリセット
  const resetNarrowDown = () => {
    globalActions.resetCaptureHistoryNarrowDown()
    actions.narrowDown.onReset()
  }

  const topIndex = 1 + currentPage * captureHistoryState.dispCount
  const maxIndex =
    itemCount < topIndex + captureHistoryState.dispCount - 1 ? itemCount : topIndex + captureHistoryState.dispCount - 1

  const baseUrl = props.uri?.split(props.path!).join('')

  const pageHeaderInfo = makeProjectPageHeaderInfo(PAGE_TITLE, AccountInfo)

  return (
    <div>
      <PageHeader title={pageHeaderInfo.title} description={pageHeaderInfo.description} />
      {(loading || reload || isPageChange) && <Loading />}
      {search.openNarrowDown && (
        <CaptureHistoryNarrowDown
          opened={search.openNarrowDown}
          disabled={search.disabled}
          status={search.status}
          text={search.text}
          onClose={actions.narrowDown.onClose}
          onCancel={() => {
            // 条件を保存しておいたものに戻す
            actions.narrowDown.onCancel(captureHistoryState.status, captureHistoryState.text)
          }}
          onClick={actions.narrowDown.onClick}
          onReset={() => resetNarrowDown()}
          onApply={() => onApply()}
          onChange={actions.narrowDown.onChange}
        />
      )}
      <PageLayout headerTitle={PAGE_TITLE} optionHidden={true} filterButtonHidden={true} baseUrl={baseUrl}>
        {!loading && !reload && (
          <Container ref={divRef}>
            <div>
              {/* メッセージ表示 */}
              <Box style={{ minWidth: '1100px', marginTop: '2rem' }}>
                {/* 汎用エラーメッセージ */}
                {errorMessage && (
                  <CautionBox style={{ justifyContent: 'flex-start', marginBottom: '2rem' }}>
                    <WarningIcon />
                    <div style={{ marginLeft: '1rem' }}>
                      <CautionLabel style={{ marginBottom: '0' }}>{errorMessage}</CautionLabel>
                    </div>
                  </CautionBox>
                )}
              </Box>

              <Box>
                <Layer style={{ justifyContent: 'flex-between', marginBottom: '1rem' }}>
                  <MessageBox>
                    <span style={{ fontSize: '0.8rem' }}>{`月間キャプチャ数 :`}</span>
                    <span style={{ fontWeight: 'bold', marginLeft: '0.3rem' }}>{captureCount}</span>
                    {/* 当月でない場合、残数表示しない */}
                    {caputureRemaining >= 0 && (
                      <span style={{ fontWeight: 'bold', marginLeft: '0.5rem' }}>{`(残り${caputureRemaining})`}</span>
                    )}
                  </MessageBox>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <HelpLink title={'自動クローラー設定とは'} link={HELP_LINKS.CAPTURE_CONTROL_HOW_TO} />
                  </div>
                </Layer>

                {/* 検索と月指定と表示件数 */}
                <Layer style={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {captureHistoryState.isNarrowDown ? (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <InputMask>検索オプション適用中</InputMask>
                        <LinkMessage onClick={actions.narrowDown.onOpen}>編集</LinkMessage>
                        <LinkMessage onClick={() => resetNarrowDown()}>リセット</LinkMessage>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <form onSubmit={onSearch}>
                          <DesignedInput
                            type="text"
                            value={search.searchText}
                            iconRight={
                              search.isSearch ? (
                                <div style={{ display: 'flex' }}>
                                  <ClearIcon style={{ marginRight: '0.5rem' }} onClick={onClear} />
                                  <SearchIcon onClick={onSearch} />
                                </div>
                              ) : (
                                <SearchIcon onClick={onSearch} />
                              )
                            }
                            onChange={actions.onUpdate}
                          />
                        </form>
                        <LinkMessage onClick={actions.narrowDown.onOpen}>検索オプション</LinkMessage>
                      </div>
                    )}

                    {/* リクエスト月 */}
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '3rem' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ marginRight: '0.3rem' }}>リクエスト月 :</span>
                        <DesignedSelect
                          value={requestCondition}
                          options={monthOptions}
                          onChange={actions.onChange}
                          style={{ width: '100px', padding: '0 0.5rem' }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* 表示件数 */}
                  {items.length > 0 ? (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Label>表示する件数:</Label>
                        <DesignedSelect
                          options={captureHistoryState.dispCountOptions}
                          value={captureHistoryState.dispCount}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onDispChange(e)}
                        />
                      </div>
                      <Label style={{ marginLeft: '1rem' }}>{`${topIndex}～${maxIndex}/${itemCount}件`}</Label>
                    </div>
                  ) : (
                    <div />
                  )}
                </Layer>

                {items.length > 0 && (
                  <CaptureHistoryGrid
                    items={items}
                    onClick={(urlId: string, pageLayout: DeviceTypeForAPI) => actions.onOpen(urlId, pageLayout)}
                  />
                )}

                {/* 表示件数 */}
                {items.length > 0 && (
                  <Layer style={{ margin: '1rem 0 0.5rem 0', justifyContent: 'flex-end' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Label>表示する件数:</Label>
                      <DesignedSelect
                        options={captureHistoryState.dispCountOptions}
                        value={captureHistoryState.dispCount}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onDispChange(e)}
                      />
                    </div>
                    <Label style={{ marginLeft: '1rem' }}>{`${topIndex}～${maxIndex}/${itemCount}件`}</Label>
                  </Layer>
                )}

                {items.length > 0 && (
                  <Paginate
                    initialPage={currentPage}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    disableInitialCallback={false}
                    onPageChange={actions.onPageChange}
                  />
                )}
              </Box>
            </div>
          </Container>
        )}
        <div>
          {/* モーダル */}
          {history.step === captureHistoryStep.confirm && (
            <CaptureHistoryModal
              opened={history.step === captureHistoryStep.confirm}
              projectId={Number(projectId)}
              urlId={urlId}
              pageLayout={pageLayout}
              baseUrl={baseUrl}
              onClose={actions.onClose}
              onCapture={actions.onCapture}
            />
          )}
          {history.step === captureHistoryStep.complete && (
            <ConfirmModal
              isOpen={history.step === captureHistoryStep.complete}
              title="キャプチャリクエスト"
              children={
                <>
                  <div>ページキャプチャをリクエストしました。</div>
                  <div style={{ marginTop: '0.5rem' }}>
                    新しいコンテンツは取得完了後からイベント計測が開始されます。
                  </div>
                  <div style={{ marginTop: '1rem' }}>完了までしばらくお待ちください。</div>
                </>
              }
              onClose={actions.onComplete}
            />
          )}
        </div>
      </PageLayout>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;
  min-width: ${gridSize.minWidth};
  height: 100%;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${gridSize.minWidth};
`

const CautionBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid ${colors.orange};
  background-color: ${colors.white};
  padding: 1rem 2rem;
`

const CautionLabel = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
`

const MessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray300};
  padding: 0 1rem;
`

const LinkMessage = styled.a`
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.link.base};
  margin-left: 0.5rem;

  &:visited {
    color: ${colors.link.visited};
  }
`

const InputMask = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 30px;
  font-size: 0.8rem;
  font-weight: 600;
  color: ${colors.white};
  background-color: ${colors.blue};
  border: 1px solid ${colors.gray300};
`

const DesignedSelect = styled(Select)`
  width: 60px;
  height: 30px;
  font-size: 1rem;
  padding: 0;
`

const DesignedInput = styled(Input)`
  width: 300px;
  height: 30px;
`

const Layer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Label = styled.div`
  font-size: 0.8rem;
`

const WarningIcon = styled(Warning).attrs({
  size: 40,
  color: `${colors.orange}`,
})`
  margin: 0 1rem;
`

const SearchIcon = styled(Search).attrs({
  size: 18,
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const ClearIcon = styled(CloseCircle).attrs({
  size: 18,
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`
