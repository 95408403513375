import * as React from 'react'
import 'rsuite/dist/rsuite-no-reset.min.css'
import './index.less'
import { CustomProvider, DateRangePicker } from 'rsuite'
import { DateRange } from 'rsuite/esm/DateRangePicker'
import jaJP from 'rsuite/locales/ja_JP'
import {
  addDays,
  startOfDay,
  subDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYesterday,
} from 'date-fns'
import { ReportContext } from '../../../contexts/ReportProvider'
import { CALENDER_INIT_END_DATE } from '../../../env'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'

export interface CalenderState {
  readonly startDate: Date
  readonly endDate: Date
}

const locale = {
  ok: '適用',
}

const getRanges = (dayLimit: number) => {
  const ranges = []
  // 先週
  // 最大選択範囲は2週間の14日だが、当日選択できないので1日分引いておく
  if (dayLimit >= 13) {
    ranges.push({
      label: '先週',
      value: [startOfWeek(addDays(new Date(), -7)), endOfWeek(addDays(new Date(), -7))] as DateRange,
      closeOverlay: false,
      appearance: 'default',
    })
  }
  // 先月
  // カレンダーに表示される最大日数は31日+31日の62日だが
  // 当日は選択できないため31日を選択できるのは翌月となるので当日分を引いた61日とする
  if (dayLimit >= 61) {
    ranges.push({
      label: '先月',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))] as DateRange,
      closeOverlay: false,
      appearance: 'default',
    })
  }
  // 過去7日
  if (dayLimit >= 7) {
    ranges.push({
      label: '過去7日間',
      value: [startOfDay(subDays(new Date(), 7)), startOfYesterday()] as DateRange,
      closeOverlay: false,
      appearance: 'default',
    })
  }
  // 過去30日
  if (dayLimit >= 30) {
    ranges.push({
      label: '過去30日間',
      value: [subDays(new Date(), 30), startOfYesterday()] as DateRange,
      closeOverlay: false,
      appearance: 'default',
    })
  }
  return ranges
}

interface Props {
  readonly dayLimit?: number
  readonly onApply?: () => void
}

export function DayPickerRange({ dayLimit = 1, onApply }: Props) {
  const {
    state: { calenderState },
    actions: { setCalenderState, setSearchParamsCalender },
  } = React.useContext(ReportContext)

  const [dateValue, setDateValue] = React.useState<DateRange>([calenderState.startDate, calenderState.endDate])

  const handleCalenderChange = (value: DateRange | null) => {
    if (!value) return
    setDateValue(value)
    setCalenderState(value[0], value[1])
    setSearchParamsCalender(value[0], value[1])
    if (onApply) onApply()
  }

  return (
    <CustomProvider locale={jaJP}>
      <StyledDateRangePicker
        placement="autoVerticalEnd"
        ranges={getRanges(dayLimit)}
        locale={locale}
        value={dateValue}
        onChange={handleCalenderChange}
        cleanable={false}
        shouldDisableDate={DateRangePicker.after ? DateRangePicker.after(CALENDER_INIT_END_DATE) : undefined}
      />
    </CustomProvider>
  )
}

const StyledDateRangePicker = styled(DateRangePicker)`
  .rs-picker-toggle.rs-btn {
    border-radius: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: auto;
  }
  .rs-picker-toggle.rs-btn .rs-picker-toggle-value {
    color: ${colors.black};
  }
  .rs-picker-toggle.rs-btn .rs-picker-toggle-caret.rs-icon {
    top: 3px;
    right: 9px;
    font-size: 16px;
    color: ${colors.gray550};
  }
`
