import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../../styleConstants'

interface Props {
  filterIndex: number
  dimensionIndex: number
  stateIndex: number
  minusOff: boolean
  andOff: boolean
  readonly onMinus?: (index: number, dimensionIndex: number, stateIndex: number) => void // -ボタン
  readonly onOr?: (index: number, dimensionIndex: number) => void // OR条件追加
  readonly onAnd?: (index: number) => void // AND条件追加
}

export function DimensionAddDeleteButton({
  filterIndex,
  dimensionIndex,
  stateIndex,
  minusOff,
  andOff,
  onMinus,
  onOr,
  onAnd,
}: Props) {
  return (
    <div style={{ display: 'flex', marginLeft: 'auto', marginRight: '1rem' }}>
      <SimpleButton
        className={cc({ minusOff })}
        style={{ width: '30px' }}
        onClick={minusOff ? () => {} : () => onMinus!(filterIndex, dimensionIndex, stateIndex)}
      >
        <div>-</div>
      </SimpleButton>
      {/* OR機能は一旦使用しない */}
      {false && (
        <SimpleButton style={{ width: '30px' }} onClick={() => onOr!(filterIndex, dimensionIndex)}>
          <div>OR</div>
        </SimpleButton>
      )}
      <SimpleButton
        className={cc({ andOff })}
        style={{ width: '40px' }}
        onClick={andOff ? () => {} : () => onAnd!(filterIndex)}
      >
        <div>AND</div>
      </SimpleButton>
    </div>
  )
}

const SimpleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  background-color: ${colors.gray50};
  border-top: 1px solid ${colors.gray400};
  border-bottom: 1px solid ${colors.gray400};
  border-left: 1px solid ${colors.gray400};
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;

  &.andOff,
  &.minusOff {
    cursor: default;
    color: ${colors.gray400};
    background-color: ${colors.gray200};
  }

  &:last-child {
    border-right: 1px solid ${colors.gray400};
  }
`
