import * as React from 'react'
import styled from 'styled-components'

import { BaseHeaderCell } from '../cells/BaseCell'
import { BaseRow } from './BaseRow'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'

export function CategoryRow() {
  return (
    <BaseRow>
      <ContentCell>コンテンツ</ContentCell>
      <ViewCell>ビュー</ViewCell>
      <GoalCell>ゴール</GoalCell>
      <ClickCell>クリック</ClickCell>
    </BaseRow>
  )
}

const ContentCell = styled(BaseHeaderCell)`
  grid-column-start: 1;
  grid-column-end: 4;
  background-color: ${CELL_BACKGROUND_COLORS.CONTENT};
`

const ViewCell = styled(BaseHeaderCell)`
  grid-column-start: 4;
  grid-column-end: 7;
  background-color: ${CELL_BACKGROUND_COLORS.VIEW};
`

const GoalCell = styled(BaseHeaderCell)`
  grid-column-start: 7;
  grid-column-end: 9;
  background-color: ${CELL_BACKGROUND_COLORS.GOAL};
`

const ClickCell = styled(BaseHeaderCell)`
  grid-column-start: 9;
  grid-column-end: 11;
  background-color: ${CELL_BACKGROUND_COLORS.CLICK};
`
