import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { DimensionOption, CustomFilterState, AggregateScopes } from '../../../util/hooks/api/Filter/types'
import { AggregateScope } from './AggregateScope'
import { DimensionItems } from './DimensionItems'
import { CustomFilterAction, CustomFilterModalState } from '../../../contexts/CustomFilterContext/state'

interface Props {
  readonly state: CustomFilterModalState
  readonly actions: CustomFilterAction
  readonly dimensionOptions: DimensionOption[]
  readonly scopeOptions?: { value: AggregateScopes; label: string }[]
}

export function FilterItems(props: Props) {
  const { state, actions, dimensionOptions, scopeOptions } = props

  return (
    <FilterItemsWrapper>
      {state.tempCustomFilterState.map((filter: CustomFilterState, filterIndex: number) => {
        return (
          <FilterItem key={`filter-${filterIndex}`}>
            <Scope>
              <ScopeBox>
                {/* 集計単位の設定 */}
                <AggregateScope
                  filter={filter}
                  filterIndex={filterIndex}
                  onAggregateScopeChange={actions.onAggregateScopeChange}
                  onAggregateConditionChange={actions.onAggregateConditionChange}
                  scopeOptions={scopeOptions}
                />
              </ScopeBox>

              <div style={{ display: 'flex' }}>
                <DeleteIcon onClick={() => actions.onDelete!(filterIndex)} />
              </div>
            </Scope>

            <DimensionItems
              actions={actions}
              filter={filter}
              filterIndex={filterIndex}
              dimensionOptions={dimensionOptions}
            />
          </FilterItem>
        )
      })}
    </FilterItemsWrapper>
  )
}

const FilterItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 30px);
  background-color: ${colors.bg};
  padding-left: 1.5rem;
  padding-bottom: 1rem;

  & + & {
    margin-top: 1.5rem;
  }

  &:last-child {
    margin-bottom: 1rem;
  }
`

const Scope = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  margin-bottom: 0.5rem;
`

const ScopeBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const DeleteIcon = styled(CloseOutline).attrs({
  size: 24,
  color: `${colors.gray500}`,
})`
  cursor: pointer;
`
