import * as React from 'react'
import { State } from './state'

export class Search {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // 通常検索
  search = (searchText: string) => {
    this.setState({
      ...this.state,
      searchText,
      isSearch: searchText.length !== 0,
      loadType: 'filter',
      currentPage: 0,
    })
  }

  // 検索クリア
  clear = () => {
    this.setState({
      ...this.state,
      searchText: '',
      isSearch: false,
      currentPage: 0,
    })
  }
}
