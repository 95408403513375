import * as React from 'react'
import styled from 'styled-components'
import { Modal } from './Modal'
import { CancelButton } from './Button'

interface Props {
  readonly children?: React.ReactNode
  readonly isOpen: boolean
  readonly title: string
  readonly onClose: () => void
  readonly width?: number
}

export function ConfirmModal(props: Props) {
  const { children, isOpen, title, onClose, width = 664 } = props
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} width={width}>
      <Content>{children}</Content>
      <ButtonWrapper>
        <CancelButton onClick={onClose}>閉じる</CancelButton>
      </ButtonWrapper>
    </Modal>
  )
}

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-bottom: 24px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
