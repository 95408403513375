import * as React from 'react'
import { request } from '../../util/request'
import { navigate } from '@gatsbyjs/reach-router'
import { State } from './state'

// 処理ステップ
export const ProjectStep = {
  none: 0,
  confirm: 1, // 確認
  complete: 2, // 完了

  createProject: 3, // 新規プロジェクト作成
  createComplete: 4, // 作成完了
}

export class Project {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly setCookie: any,
  ) {}

  // プロジェクトリンク
  navigation = (id: number) => {
    this.setCookie('projectId', { userId: this.state.member.userId, id: id }, { path: '/' })
    navigate(`/projects/${id}/settings/project`)
  }

  // プロジェクト削除
  onDelete = (id: number) => {
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        selectedId: id,
        step: ProjectStep.confirm,
      },
      projectErrorMessage: '',
    })
  }

  // プロジェクト削除のキャンセル
  onCancel = () => {
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        selectedId: 0,
        step: ProjectStep.none,
      },
    })
  }

  // プロジェクト削除
  onApply = async () => {
    try {
      await request('DELETE', `/api/projects/${this.state.project.selectedId}/`, true)
      this.setState({
        ...this.state,
        reload: true,
        project: {
          ...this.state.project,
          selectedId: 0,
          step: ProjectStep.none,
        },
        toastMessage: 'プロジェクトを削除しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        project: {
          ...this.state.project,
          selectedId: 0,
          step: ProjectStep.none,
        },
        projectErrorMessage: typeof e === 'string' ? e : 'プロジェクトの削除に失敗しました。',
      })
    }
  }

  // 新規プロジェクト作成開始
  onCreate = () => {
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        step: ProjectStep.createProject,
      },
    })
  }

  // プロジェクト作成
  onCreateProject = (id: number) => {
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        step: ProjectStep.createComplete,
      },
    })

    // cookieにプロジェクトID保存
    this.setCookie('projectId', { userId: this.state.member.userId, id: id }, { path: '/' })
  }

  // 作成完了
  onCreateComplete = () => {
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        step: ProjectStep.none,
      },
    })

    // 新規プロジェクトページに遷移させる
    navigate('/')
  }

  // 作成キャンセル
  onCancelCreate = () => {
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        step: ProjectStep.none,
      },
    })
  }
}
