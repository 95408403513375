import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Warning } from '@styled-icons/icomoon/Warning'

interface Props {
  readonly children: React.ReactNode
}

export function WarningBox({ children }: Props) {
  return (
    <MessageBox>
      <IconBox>
        <Warning size={16} color={`${colors.orange}`} />
      </IconBox>
      <div>{children}</div>
    </MessageBox>
  )
}

const MessageBox = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: ${colors.errorBg};
  padding: 16px;
  white-space: normal;
`

const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24px;
  margin-right: 8px;
`
