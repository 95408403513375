import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'
import { ProjectData } from '../../util/Response'

export class Project {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly openToast: ({ message }: { message: string }) => void,
  ) {}

  // プロジェクト名更新
  updateName = (event: React.FormEvent<HTMLInputElement>) => {
    const valid = !(event.currentTarget.validity.valid && this.state.project.baseName !== event.currentTarget.value)
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        name: event.currentTarget.value,
        nameDisabled: valid,
        disabled: valid && this.state.project.urlDisabled,
      },
    })
  }

  onCancel = () => {
    // 入力の変更をリセット
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        name: this.state.project.baseName,
        url: this.state.project.baseUrl,
        nameDisabled: true,
        urlDisabled: true,
        disabled: true,
      },
    })
  }

  onApply = async () => {
    try {
      const project: ProjectData = await request(
        'PATCH',
        `/api/projects/${this.state.project.id}/`,
        true,
        JSON.stringify({ name: this.state.project.name, url: this.state.project.url }),
      )
      this.openToast({ message: 'プロジェクト情報を変更しました' })

      this.setState({
        ...this.state,
        project: {
          ...this.state.project,
          name: project.name,
          url: project.url,
          id: project.id,
          baseName: project.name,
          baseUrl: project.url,
          nameDisabled: true,
          urlDisabled: true,
          disabled: true,
          updated: true,
        },
        toastMessage: '',
        projectErrorMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        projectErrorMessage: typeof e === 'string' ? e : 'プロジェクトの更新に失敗しました。',
      })
    }
  }
}
