import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { Content } from '../../../util/hooks/api/useContentReport'
import { Checkbox } from '../../common/Checkbox'
import { BaseHeaderCell } from './BaseCell'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'

interface Props {
  readonly items: Content[]
  readonly selectedIds: number[]
  readonly onClick: React.MouseEventHandler<HTMLDivElement>
}

export function ContentPreviewHeaderCell({ items, selectedIds, onClick }: Props) {
  const allChecked = selectedIds.length === items.length

  return (
    <Cell>
      <StyledCheckbox checked={allChecked} indeterminate={!allChecked && selectedIds.length > 0} onClick={onClick} />
      プレビュー
    </Cell>
  )
}

const StyledCheckbox = styled(Checkbox)`
  width: 16px;
  height: 16px;
  margin-right: 16px;
  background-color: ${colors.white};
`

const Cell = styled(BaseHeaderCell)`
  background-color: ${CELL_BACKGROUND_COLORS.CONTENT};
`
