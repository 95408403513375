import * as React from 'react'
import styled from 'styled-components'

import { SortItemKey, SortState } from '../../../util/hooks/api/useContentReport'
import { SortButton } from '../../common/SortButton'
import { BaseHeaderCell } from './BaseCell'
import { HeaderCellHelp } from './HeaderCellHelp'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'

interface Props {
  readonly sortState: SortState
  readonly onSortClick: (key: SortItemKey) => void
}

export function ViewSecondHeaderCell(props: Props) {
  const { sortState, onSortClick } = props

  return (
    <Cell onClick={() => onSortClick('viewSecond')}>
      <Label>秒数</Label>
      <HeaderCellHelp width={200}>コンテンツの合計閲覧秒数 ÷ ビュー数</HeaderCellHelp>
      <SortButton elementKey={'viewSecond'} sortIcon={sortState['viewSecond']} />
    </Cell>
  )
}

export const Cell = styled(BaseHeaderCell)`
  background-color: ${CELL_BACKGROUND_COLORS.VIEW};
`

export const Label = styled.span`
  text-align: center;
`
