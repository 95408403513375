import * as React from 'react'
import styled from 'styled-components'
import { GRID_MIN_WIDTH } from '..'
import { PageSummary } from '../../../util/hooks/api/useContentReport'
import { PageSummaryBox } from './PageSummaryBox'
import { SCOPE_TYPE, ScopeType, useScopeType } from '../../../util/hooks/useScopeType'
import { HELP_TEXTS } from '../../../constants'
import { RateValue } from '../../values/RateValue'

interface Props {
  readonly pageSummary: PageSummary
}

export function PageSummaryArea({ pageSummary }: Props) {
  const { conversions, conversionsRate } = pageSummary

  const { scopeType, scopeTypeLabel } = useScopeType()

  const viewCount = getViewCount(scopeType, pageSummary)
  const helpTexts = getHelpTexts(scopeType)

  return (
    <Container>
      <PageSummaryBox label={`${scopeTypeLabel}数`} value={viewCount.toLocaleString()} helpText={helpTexts.viewCount} />
      <PageSummaryBox label={'ゴール数'} value={conversions.toLocaleString()} helpText={helpTexts.goalCount} />
      <PageSummaryBox
        label={'ゴール率'}
        value={<RateValue value={conversionsRate} digit={2} />}
        helpText={helpTexts.goalRate}
      />
    </Container>
  )
}

function getViewCount(scopeType: ScopeType, pageSummary: PageSummary) {
  if (scopeType === SCOPE_TYPE.USER) {
    return pageSummary.users
  }

  return pageSummary.sessions
}

function getHelpTexts(scopeType: ScopeType) {
  if (scopeType === SCOPE_TYPE.USER) {
    return {
      viewCount: HELP_TEXTS.USER_COUNT,
      goalCount: HELP_TEXTS.USER_GOAL_COUNT,
      goalRate: HELP_TEXTS.USER_GOAL_RATE,
    }
  }

  return {
    viewCount: HELP_TEXTS.SESSION_COUNT,
    goalCount: HELP_TEXTS.SESSION_GOAL_COUNT,
    goalRate: HELP_TEXTS.SESSION_GOAL_RATE,
  }
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 36px;
  width: 100%;
  min-width: ${GRID_MIN_WIDTH}px;
  height: 40px;
  margin-bottom: 17px;
`
