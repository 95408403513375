import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { request } from '../../../request'
import { DataImportScheduleResponse, DataImportScheduleData } from './types'
import { DATA_IMPORT_SCHEDULE_QUERY_KEY } from '../constants'

type Props = {
  projectId: string
  options?: UseQueryOptions<DataImportScheduleData | null>
}

type apiResponse = {
  results: DataImportScheduleResponse[]
}

export const useDataImportSchedule = ({ projectId, options }: Props) => {
  const queryClient = useQueryClient()
  const queryKey = [
    DATA_IMPORT_SCHEDULE_QUERY_KEY,
    {
      projectId,
    },
  ]

  const invalidate = async () => {
    await queryClient.invalidateQueries({ queryKey })
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const data = await request<apiResponse>('GET', `/api/projects/${projectId}/data_import_schedules/`, true)

      if (data.results.length === 0) {
        return null
      }
      const result: DataImportScheduleResponse = data.results[0]
      const formatted: DataImportScheduleData = {
        scheduleId: result.id,
        enable: result.enable,
        sftpUser: result.sftp_user,
        sftpHost: result.sftp_host,
        sftpPort: result.sftp_port,
        interval: result.interval,
        time: result.time,
        weekday: result.weekday,
        day: result.day,
      }
      return formatted
    },
    ...options,
  })

  return {
    ...queryResult,
    invalidate,
  }
}
