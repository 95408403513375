import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../../styleConstants'

interface Props {
  readonly children?: React.ReactNode
  readonly onClose?: () => void
}

export function PopupMenu({ children, onClose, ...rest }: Props & React.HTMLAttributes<any>) {
  const props = { ...rest }
  const ref = React.useRef<HTMLDivElement>(null)
  // 範囲外をクリックすることで閉じる
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose && onClose()
    }
  }
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })
  return (
    <Container ref={ref} {...props}>
      {children}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${colors.white};
  box-shadow: 0 0 4px ${colors.gray400};
  z-index: ${layout.popupZIndex};
`
