import * as React from 'react'
import { request } from '../../util/request'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly loading: boolean
  readonly errorMessage?: string
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  logout = async () => {
    try {
      await request('POST', '/api/logout/', true)
      // ストレージのtokenを削除しておく
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('syslogToken')
      this.setState({
        ...this.state,
        loading: false,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        errorMessage: typeof e === 'string' ? e : 'ログアウトに失敗しました。',
      })
    }
  }
}

const initialState: State = {
  loading: true,
  errorMessage: '',
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
