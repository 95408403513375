import * as React from 'react'
import { request } from '../../util/request'
import { State } from './state'

// 処理ステップ
export const CaptureLimitStep = {
  none: 0,
  complete: 1, // 完了
}

// 超過率計算
// キャプチャ月間取得回数目安 / ( 組織の月間キャプチャ上限数 * 月間キャプチャ上限割合)
export function CalcExcessRate(standard: number, limit: number, rate: number) {
  if (standard === 0 || limit === 0 || rate === 0) {
    return '0.0'
  }
  return ((standard / (limit * (rate / 100))) * 100).toFixed(1)
}

export class CaptureLimit {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // 割合変更
  onChange = (e: React.FormEvent<HTMLInputElement>, id: number) => {
    // 割合の合計計算
    const totalRate =
      this.state.captureLimit.limits.length === 0
        ? 0
        : this.state.captureLimit.limits.reduce((sum, limit) => {
            if (limit.id === id) {
              return sum + Number(e.currentTarget.value)
            }
            return sum + limit.editRate
          }, 0)
    const idx = this.state.captureLimit.limits.findIndex((limit) => limit.id === id)
    const limits = [
      ...this.state.captureLimit.limits.slice(0, idx),
      {
        ...this.state.captureLimit.limits[idx],
        editRate: Number(e.currentTarget.value),
        excessRate: CalcExcessRate(
          this.state.captureLimit.limits[idx].standard,
          this.state.captureLimit.teamLimit,
          Number(e.currentTarget.value),
        ),
      },
      ...this.state.captureLimit.limits.slice(idx + 1),
    ]
    this.setState({
      ...this.state,
      captureLimit: {
        ...this.state.captureLimit,
        disabled:
          totalRate !== 100 || // 100にならないと設定できない
          limits.map((limit) => limit.editRate).toString() ===
            this.state.captureLimit.limits.map((limit) => limit.rate).toString(),
        limits,
        totalRate: totalRate,
      },
    })
  }

  // リセット
  onReset = () => {
    // 元情報から設定し直し
    this.setState({
      ...this.state,
      captureLimit: {
        ...this.state.captureLimit,
        disabled: true,
        limits:
          this.state.captureLimit.limits.length === 0
            ? []
            : this.state.captureLimit.limits.map((limit) => {
                return {
                  ...limit,
                  editRate: limit.rate,
                  excessRate: CalcExcessRate(limit.standard, this.state.captureLimit.teamLimit, limit.rate),
                }
              }),
        totalRate:
          this.state.captureLimit.limits.length === 0
            ? 0
            : this.state.captureLimit.limits.reduce((sum, limit) => sum + limit.rate, 0),
      },
    })
  }

  // 保存
  onApply = async () => {
    try {
      await request(
        'PATCH',
        `/api/capture_limits/`,
        true,
        JSON.stringify(
          this.state.captureLimit.limits.map((limit) => {
            return {
              id: limit.id,
              ratio: limit.editRate / 100, // 0から1の範囲に変換
            }
          }),
        ),
      )
      this.setState({
        ...this.state,
        reload: true,
        captureLimit: {
          ...this.state.captureLimit,
          step: CaptureLimitStep.none,
        },
        toastMessage: 'ページキャプチャ上限設定を変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        captureLimit: {
          ...this.state.captureLimit,
          step: CaptureLimitStep.none,
        },
        captureLimitErrorMessage: typeof e === 'string' ? e : 'ページキャプチャの上限設定に失敗しました。',
      })
    }
  }
}
