import * as React from 'react'
import styled from 'styled-components'

import { useTeamPv } from '../../../util/hooks/api/PageView/useTeamPv'
import { getDateStringYM } from '../../../util/Date'
import { useTeam } from '../../../util/hooks/api/Team/useTeam'
import { Loading } from '../../../components/common/Loading'
import { EmbeddedLoading } from '../../../components/common/EmbeddedLoading'
import { ProjectPvInfo, ProjectsPvCountChart } from '../../../components/PvCount/ProjectsPvCountChart'
import { YearMonthSelect } from '../../../components/PvCount/YearMonthSelect'

interface Props {
  setErrorMessage: (message: string) => void
}

export function PvCountArea({ setErrorMessage }: Props) {
  const todayYearMonth = getDateStringYM(new Date(), '')
  const [yearMonth, setYearMonth] = React.useState(todayYearMonth)
  const [pvLimit, setPvLimit] = React.useState(0)
  const [isFirstLoading, setIsFirstLoading] = React.useState(true)

  const {
    data: pvData,
    isLoading,
    isError,
  } = useTeamPv({
    yearMonth: yearMonth,
  })
  const { data: teamData, isLoading: isTeamLoading, isError: isTeamError } = useTeam()

  const handleChangeYearMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setYearMonth(e.target.value)
  }

  React.useEffect(() => {
    if (!pvData) return

    // PV上限はAPI再コールしても値変わらないので値がクリアされないようにlocalステートで管理
    setPvLimit(pvData.limit)

    // PV上限がセットされれば画面全体のLoadingを非表示にする
    setIsFirstLoading(false)
  }, [pvData])

  React.useEffect(() => {
    setErrorMessage(isError || isTeamError ? 'PV情報の取得に失敗しました。' : '')
  }, [isError, isTeamError])

  const teamPv = pvData?.pageViewCount ?? 0

  const infos: ProjectPvInfo[] =
    pvData?.projectPageViews?.map((pv) => {
      return {
        name: pv.name,
        count: pv.count,
      }
    }) ?? []

  const fetchedError = isError || isTeamError

  return (
    <Container>
      {((!isError && isFirstLoading) || isTeamLoading) && <Loading />}

      <Item>
        <Label>月間上限PV</Label>
        <div>{`${pvLimit?.toLocaleString()} PV`}</div>
      </Item>

      {!isTeamError && (
        <Item>
          <Label>消費PV</Label>
          <YearMonthSelect plan={teamData?.plan} createdAt={teamData?.createdAt} onChange={handleChangeYearMonth} />
          {!isError && (
            <>
              {!isLoading && teamPv === 0 && <div>{`消費PVはありません`}</div>}
              {teamPv > 0 && <div>{`${teamPv.toLocaleString()} PV`}</div>}
            </>
          )}
        </Item>
      )}

      {/* プロジェクト別消費PVグラフの高さにLoading表示(見栄えが良いので) */}
      {isLoading && (
        <LoadingArea>
          <EmbeddedLoading />
        </LoadingArea>
      )}
      <PvCountExistsVisibleArea isVisible={!fetchedError && teamPv > 0}>
        <Item>
          <>
            <Label>プロジェクト別消費PV</Label>
            <ProjectsPvCountChart infos={infos} teamPv={teamPv} teamPvLimit={pvLimit} targetYearMonth={yearMonth} />
          </>
        </Item>
      </PvCountExistsVisibleArea>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 345px; // 期間変更時のLoading中もパネルの高さが変わらないよう調整
  margin-bottom: 0.5rem;
  gap: 24px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Label = styled.div`
  font-weight: bold;
`

const LoadingArea = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PvCountExistsVisibleArea = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`
