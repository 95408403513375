/**
 * 2つの文字列配列をソート後に比較します。
 *
 * @param a 最初の文字列配列
 * @param b 2番目の文字列配列
 * @returns ソート後に配列が等しい場合は`true`、そうでない場合は`false`を返す
 */
export function compareAndSortArrays(a: string[], b: string[]): boolean {
  if (a.length !== b.length) return false

  const sortedA = [...a].sort()
  const sortedB = [...b].sort()
  return JSON.stringify(sortedA) === JSON.stringify(sortedB)
}
