import { useCookies } from 'react-cookie'

export const useQuadrantChart = () => {
  const cookieKey = `quadrantChartOpened`
  const [cookies, setCookie] = useCookies([cookieKey])

  const chartOpened = cookies[cookieKey] === 'true'

  const setChartOpened = (value: boolean) => {
    setCookie(cookieKey, value, { path: '/' })
  }

  return { chartOpened, setChartOpened }
}
