import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../../styleConstants'
import { PieChart } from '@styled-icons/boxicons-regular/PieChart'
import { BorderAll } from '@styled-icons/boxicons-regular/BorderAll'
import { BorderNone } from '@styled-icons/boxicons-regular/BorderNone'
import { CaretDown } from '@styled-icons/boxicons-regular/CaretDown'
import { CAPTURE_VIEW, CaptureView, useCaptureView } from '../../util/hooks/useCaptureView'

const getCaptureViewText = (captureView: CaptureView) => {
  switch (captureView) {
    case CAPTURE_VIEW.VIEW_COUNT:
      return 'ビュー：数'
    case CAPTURE_VIEW.VIEW_RATE:
      return 'ビュー：率'
    case CAPTURE_VIEW.VIEW_SECOND:
      return 'ビュー：秒数'
    case CAPTURE_VIEW.GOAL_COUNT:
      return 'ゴール：数'
    case CAPTURE_VIEW.GOAL_RATE:
      return 'ゴール：率'
    case CAPTURE_VIEW.CLICK_COUNT:
      return 'クリック：数'
    case CAPTURE_VIEW.CLICK_RATE:
      return 'クリック：率'
    case CAPTURE_VIEW.WITH_BORDER:
      return '枠あり'
    case CAPTURE_VIEW.WITHOUT_BORDER:
      return '枠なし'
    default:
      return ''
  }
}

export function CaptureViewSelector() {
  const [isViewSelectorVisible, setIsViewSelectorVisible] = React.useState(false)
  const { captureView, setCaptureView, isMetricVisible } = useCaptureView()

  return (
    <ViewSelectorContainer>
      <ViewSelector
        onMouseEnter={() => setIsViewSelectorVisible(true)}
        onMouseLeave={() => setIsViewSelectorVisible(false)}
      >
        {isMetricVisible && (
          <PieChart color={`${colors.contentBlue.blue7}`} size={15} style={{ margin: 0, padding: 0 }} />
        )}
        {captureView === CAPTURE_VIEW.WITH_BORDER && (
          <BorderAll color={`${colors.contentBlue.blue7}`} size={15} style={{ margin: 0, padding: 0 }} />
        )}
        {captureView === CAPTURE_VIEW.WITHOUT_BORDER && (
          <BorderNone color={`${colors.contentBlue.blue7}`} size={15} style={{ margin: 0, padding: 0 }} />
        )}

        <ViewSelectorText>{getCaptureViewText(captureView)}</ViewSelectorText>
        <CaretDown color={`${colors.contentBlue.blue7}`} size={15} style={{ margin: 0, padding: 0 }} />
        <ViewSelectorPulldown visible={isViewSelectorVisible}>
          {Object.values(CAPTURE_VIEW).map((captureViewItem) => (
            <ViewSelectorPulldownItem
              key={captureViewItem}
              onClick={(e) => {
                e.stopPropagation()
                setCaptureView(captureViewItem)
                setIsViewSelectorVisible(false)
              }}
              isBold={captureView === captureViewItem}
            >
              {getCaptureViewText(captureViewItem)}
            </ViewSelectorPulldownItem>
          ))}
        </ViewSelectorPulldown>
      </ViewSelector>
    </ViewSelectorContainer>
  )
}

const ViewSelectorContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 8px;
`

const ViewSelector = styled.a`
  display: flex;
  justify-content: space-between;
  height: 30px;
  padding: 8px 16px;
  transition: all 0.2s linear;
  &:hover {
    background-color: ${colors.contentBlue.blue2};
  }
  cursor: default;
`

const ViewSelectorText = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.contentBlue.blue7};
  padding: 0 4px;
  cursor: default;
`

const ViewSelectorPulldown = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 38px;
  right: 8px;
  padding: 8px 0;
  background-color: ${colors.white};
  z-index: ${layout.captureViewSelectorZIndex};
  transition: all 0.2s linear;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

const ViewSelectorPulldownItem = styled.div<{ isBold: boolean }>`
  width: 100%;
  padding: 4px 16px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  &:hover {
    background-color: ${colors.gray100};
  }
`
