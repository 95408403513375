import React from 'react'
import { useToast } from '../../../util/hooks/useToast'
import { useDeleteFunnelReport } from '../../../util/hooks/api/FunnelReport/useDeleteFunnelReport'
import { ProjectContext } from '../../ProjectRoot'
import { Modal, ModalButtonWrapper, ModalContentWrapper, ModalHeadText } from '../../../components/common/Modal'
import { ErrorBox } from '../../../components/common/ErrorBox'
import { CancelButton, NormalButton } from '../../../components/common/Button'
import { useFunnelReports } from '../../../util/hooks/api/FunnelReport/useFunnelReports'
import styled from 'styled-components'

interface Props {
  opened: boolean
  onClose: () => void
  funnelReportId: number | null
}

/**
 * ファネルレポート削除確認用のモーダル
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.opened - 削除モーダルを開くかどうかを指定する
 * @param {Function} props.onClose - 削除モーダルが閉じられたときに呼び出す関数
 * @param {string} props.funnelReportId - 削除対象のファネルレポートID
 */
export function DeleteModal({ opened, onClose, funnelReportId }: Props) {
  if (!funnelReportId) return null

  const { openToast } = useToast()

  const {
    state: { projectId },
  } = React.useContext(ProjectContext)

  const { data, resetFunnelReports } = useFunnelReports({ projectId })
  const {
    mutate: deleteMutate,
    isLoading: isDeleting,
    isError,
  } = useDeleteFunnelReport({
    projectId,
    options: {
      onSuccess: () => {
        resetFunnelReports()
        openToast({ message: 'ファネルレポートを削除しました。' })
        onClose()
      },
    },
  })

  const report = data?.results.find((report) => report.id === funnelReportId)
  if (!report) return null

  const handleDelete = () => deleteMutate({ funnelReportId })

  return (
    <Modal isOpen={opened} onClose={onClose} title="ファネルレポート削除" isLoading={isDeleting}>
      <ModalContentWrapper>
        {isError && <ErrorBox>ファネルレポートの削除に失敗しました。</ErrorBox>}
        <ModalContentWrapper>
          <Label>ファネルレポート名</Label>
          <Value>{report.name}</Value>
        </ModalContentWrapper>
        <ModalContentWrapper>
          <ModalHeadText>このファネルレポートを削除してもよろしいですか？</ModalHeadText>
        </ModalContentWrapper>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton onClick={handleDelete}>実行</NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const Label = styled.p`
  margin: 0 0 8px 0;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
`

const Value = styled(Label)`
  font-weight: normal;
  margin: 0;
`
