import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { request } from '../../../request'

type Props = {
  projectId: string
  importDataLogId: number
  options?: UseMutationOptions<{}>
}

export const useDeleteImportData = ({ projectId, importDataLogId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async () => {
      return await request<{}>('DELETE', `/api/projects/${projectId}/data_import_latests/${importDataLogId}/`, true)
    },
    ...options,
  })
  return mutationResult
}
