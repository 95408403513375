import * as React from 'react'

import { SORT_ICONS, SortIconState } from '../../components/common/SortButton'
import { SortKey } from '../../util/hooks/api/FunnelReport/types'
import { SORT_KEYS } from '../../util/hooks/api/FunnelReport/constants'

export interface ContextType {
  readonly searchText: string
  readonly setSearchText: (value: string) => void
  readonly sortKey: SortKey
  readonly setSortKey: (value: SortKey) => void
  readonly sortIcon: SortIconState
  readonly setSortIcon: (value: SortIconState) => void
}

/**
 * ファネルレポート系画面で使用する state情報を返す
 *
 * @return {ContextType} state and setter
 */
export function useContextState(): ContextType {
  const [searchText, setSearchText] = React.useState('')
  const [sortKey, setSortKey] = React.useState<SortKey>(SORT_KEYS.UPDATED_AT)
  const [sortIcon, setSortIcon] = React.useState<SortIconState>(SORT_ICONS.DOWN)

  return {
    searchText,
    setSearchText,
    sortKey,
    setSortKey,
    sortIcon,
    setSortIcon,
  }
}
