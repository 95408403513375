import * as React from 'react'
import styled from 'styled-components'
import { GRID_TEMPLATE_COLUMNS } from '..'

interface Props {
  readonly children?: React.ReactNode
}

export function BaseRow({ children }: Props) {
  return <Row>{children}</Row>
}

const Row = styled.div`
  display: grid;
  grid-template-columns: ${GRID_TEMPLATE_COLUMNS};
  grid-template-rows: 100%;
  grid-gap: 1px;
`
