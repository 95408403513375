import * as React from 'react'
import { navigate } from '@gatsbyjs/reach-router'
import { useCookies } from 'react-cookie'
import { request } from '../../util/request'
import { InviteVerifyData } from '../../util/Response'
import { checkPasswordPattern, checkPasswordLength } from '../../util/Password'
import { checkUserNamePattern } from '../../util/UserName'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly email: string
  readonly userName: string
  readonly password: string
  readonly isUserNameValid: boolean
  readonly isPasswordValid: boolean
  readonly disabled: boolean
  readonly errorMessage?: string
  readonly loading: boolean
  readonly isUrlValid: boolean
  readonly token: string
  readonly projects: string[]
}

export class Actions {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly setCookie: any,
  ) {
    this.submit = this.submit.bind(this)
  }

  verify = async (token?: string) => {
    try {
      const json: InviteVerifyData = await request(
        'POST',
        '/api/invite/verify/',
        false,
        JSON.stringify({ token: token }),
      )
      this.setState({
        ...this.state,
        email: json.email,
        token: token!,
        loading: false,
        isUrlValid: true,
        projects: json.projects.map((project) => project.name),
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        isUrlValid: false,
        errorMessage: typeof e === 'string' ? e : 'URLの有効期限が切れています。',
      })
    }
  }

  updateUserName = (event: React.FormEvent<HTMLInputElement>) => {
    // 文字列が記号のみでないかチェックする
    if (!checkUserNamePattern(event.currentTarget.value)) {
      event.currentTarget.setCustomValidity('記号のみのユーザー名は設定できません')
    } else {
      event.currentTarget.setCustomValidity('')
    }

    this.setState({
      ...this.state,
      userName: event.currentTarget.value,
      isUserNameValid: event.currentTarget.validity.valid,
      disabled: !(
        (event.currentTarget.validity.valid && this.state.isPasswordValid) // ユーザー名に問題なし // パスワードに問題なし
      ),
    })
  }

  updatePassword = (event: React.FormEvent<HTMLInputElement>) => {
    // 文字列に英数記号が2種類以上含まれるか確認する
    if (!checkPasswordPattern(event.currentTarget.value)) {
      event.currentTarget.setCustomValidity(
        'アルファベット、数字および記号のうち少なくとも２種類以上の組み合わせである必要があります',
      )
    } else {
      event.currentTarget.setCustomValidity('')
    }

    this.setState({
      ...this.state,
      password: event.currentTarget.value,
      isPasswordValid: event.currentTarget.validity.valid,
      disabled: !(
        (this.state.isUserNameValid && event.currentTarget.validity.valid) // ユーザー名に問題なし // パスワードに問題なし
      ),
    })
  }

  // パスワード入力欄からフォーカスが移った際の処理を行う
  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // 文字列が足りているか確認
    if (!checkPasswordLength(event.currentTarget.value)) {
      event.currentTarget.setCustomValidity('パスワードは最低１０文字以上必要です')
      event.currentTarget.reportValidity()
    }
    // 文字列に英数記号が2種類以上含まれるか確認
    else if (!checkPasswordPattern(event.currentTarget.value)) {
      event.currentTarget.setCustomValidity(
        'アルファベット、数字および記号のうち少なくとも２種類以上の組み合わせである必要があります',
      )
      event.currentTarget.reportValidity()
    } else {
      event.currentTarget.setCustomValidity('')
    }
    // フォーカスは外す
    event.currentTarget.blur()
  }

  submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      await request(
        'POST',
        '/api/invite/signup/',
        false,
        JSON.stringify({
          username: this.state.userName,
          email: this.state.email,
          token: this.state.token,
          password1: this.state.password,
          password2: this.state.password,
        }),
      )
      this.setCookie('projectId', { userId: 0, id: 0 }, { path: '/' })
      navigate('/', { state: { firstVisit: true } })
    } catch (e) {
      this.setState({
        ...this.state,
        errorMessage: typeof e === 'string' ? e : '登録に失敗しました。',
      })
    }
  }
}

const initialState: State = {
  email: '',
  userName: '',
  password: '',
  isUserNameValid: false,
  isPasswordValid: false,
  disabled: true,
  loading: true,
  isUrlValid: false,
  token: '',
  projects: [],
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const setCookie = useCookies()[1]
  const actions = new Actions(state, setState, setCookie)
  return { state, actions }
}
