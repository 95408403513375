import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html {
    line-height: 1.2; // Correct the line height in all browsers.
    -webkit-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in iOS.
    box-sizing: border-box; // Set it to border-box to make building CSS layout easier
    height:100%;
    overflow:hidden;
    font-size:14px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit; // Set it to box-sizing in all selectors, but using inherit, you can override it.
  }

  body {
    margin: 0; // Remove the margin in all browsers
    height:100%;
    overflow:hidden;
  }
  .selection-area {
  background: #4f90f22d;
  border: 1px solid #4f90f2;
  }
`
