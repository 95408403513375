import React from 'react'
import { colors } from '../../../../styleConstants'
import { HelpPopupKeyField } from '../../HelpPopups'
import { Link } from '@gatsbyjs/reach-router'
import { Check } from 'styled-icons/boxicons-regular'
import {
  StepSection,
  StepSectionHead,
  StepSectionHeadNo,
  StepSectionHeadBorder,
  StepSectionBody,
  StepSectionBodyTitle,
  FlexBox,
  StepSectionBodyTitleText,
  StepSectionBodyNotes,
  StepSectionBodyField,
  Label,
  DesignedSelect,
  ErrorMessage,
} from '.'
import { KeyFieldData, KeyFieldOption } from '../../../../util/hooks/api/DataImport/types'

interface Props {
  saved: boolean
  disactivatedCustomDimensionExists: boolean
  projectId: string
  allKeyFields: KeyFieldData[]
  editKeyFieldLabel: string | undefined
  setEditKeyFieldLabel: (arg: string | undefined) => void
}

export function KeyFieldStep({
  saved,
  disactivatedCustomDimensionExists,
  projectId,
  allKeyFields,
  editKeyFieldLabel,
  setEditKeyFieldLabel,
}: Props) {
  const keyFieldOptions: KeyFieldOption[] = [
    { label: '未選択', value: '' },
    ...allKeyFields.map((allKeyField) => ({ label: allKeyField.nameLabel, value: allKeyField.nameLabel })),
  ]

  const changeKeyField = (event: React.FormEvent<HTMLSelectElement>) => {
    setEditKeyFieldLabel(event.currentTarget.value)
  }

  return (
    <StepSection>
      <StepSectionHead>
        <StepSectionHeadNo saved={saved}>{saved ? <Check size={20} color={colors.white} /> : '1'}</StepSectionHeadNo>
        <StepSectionHeadBorder />
      </StepSectionHead>
      <StepSectionBody>
        <StepSectionBodyTitle>
          <FlexBox>
            <StepSectionBodyTitleText saved={saved}>キーフィールド</StepSectionBodyTitleText>
            <HelpPopupKeyField />
          </FlexBox>
        </StepSectionBodyTitle>

        {disactivatedCustomDimensionExists && (
          <StepSectionBodyNotes>
            表示されているカスタムディメンションはアクティブなもののみです。 <br />
            無効・アクティブは、
            <Link to={`/projects/${projectId}/settings/dimension`}>プロジェクト設定 | カスタムディメンション</Link>
            から設定を変更できます。
          </StepSectionBodyNotes>
        )}

        <StepSectionBodyField>
          <Label>フィールド名</Label>
          <DesignedSelect
            defaultValue={editKeyFieldLabel}
            options={keyFieldOptions}
            onChange={changeKeyField}
            required
          />
          {!editKeyFieldLabel && <ErrorMessage>キーフィールドを選択してください。</ErrorMessage>}
        </StepSectionBodyField>
      </StepSectionBody>
    </StepSection>
  )
}
