import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { MessageRoundedError } from '@styled-icons/boxicons-solid/MessageRoundedError'

interface Props {
  readonly children: React.ReactNode
}

export const InputErrorMessage = ({ children }: Props) => {
  return (
    <Container>
      <StyledIcon>
        <MessageRoundedError size={14} color={colors.error} />
      </StyledIcon>
      <Message>{children}</Message>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`

const StyledIcon = styled.div`
  padding-top: 1px;
`

const Message = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.error};
`
