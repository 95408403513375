import { useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '../../../request'
import { DataImportSchemasResponse, DataImportSchemasData, DataImportSchemasResultsResponse } from './types'
import { DATA_IMPORT_SCHEMAS_QUERY_KEY } from '../constants'
import { formatResponse } from './formatResponse'

type Props = {
  projectId: string
}

export const useDataImportSchemas = ({ projectId }: Props) => {
  const queryClient = useQueryClient()
  const queryKey = [
    DATA_IMPORT_SCHEMAS_QUERY_KEY,
    {
      projectId,
    },
  ]

  const invalidateDataImportSchemas = async () => {
    await queryClient.invalidateQueries({ queryKey })
  }

  const resetDataImportSchemas = () => {
    queryClient.setQueryData(queryKey, () => {
      return null
    })
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const data = await request<DataImportSchemasResponse>(
        'GET',
        `/api/projects/${projectId}/data_import_schemas/`,
        true,
      )

      if (!data.results.length) {
        return null
      }
      const result: DataImportSchemasResultsResponse = data.results[0]
      const formatted: DataImportSchemasData = formatResponse(result)
      return formatted
    },
  })

  return {
    ...queryResult,
    resetDataImportSchemas,
    invalidateDataImportSchemas,
  }
}
