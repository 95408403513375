import { useQuery } from '@tanstack/react-query'

import { TEAM_QUERY_KEY } from '../constants'
import { request } from '../../../request'
import { TeamData } from '../../../Response'
import { transformTeam } from './utils'

export const useTeam = () => {
  const queryKey = [TEAM_QUERY_KEY]

  return useQuery({
    queryKey: queryKey,
    queryFn: fetch,
    select: transformTeam,
    staleTime: 1000 * 60 * 5,
  })
}

const fetch = async () => {
  return await request<TeamData>('GET', '/api/team/', true)
}
