import React from 'react'
import { Modal, ModalHeadText, ModalContentWrapper, ModalButtonWrapper } from '../../../../common/Modal'
import { NormalButton, CancelButton } from '../../../../common/Button'
import { ErrorBox } from '../../../../common/ErrorBox'

interface Props {
  opened: boolean
  onClose: () => void
  handleDelete: () => void
  isLoading: boolean
  isError: boolean
  error: string | null
}

export function ScheduleDeleteModal({ opened, onClose, handleDelete, isLoading, isError, error }: Props) {
  return (
    <Modal isOpen={opened} onClose={onClose} title="SFTP設定削除" isLoading={isLoading}>
      <ModalContentWrapper>
        {isError && (
          <ErrorBox>
            {typeof error === 'string' ? error : 'スケジュールされたインポートの削除に失敗しました。'}
          </ErrorBox>
        )}
        <ModalHeadText>SFTP設定を削除してよろしいですか？</ModalHeadText>
      </ModalContentWrapper>

      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton onClick={handleDelete}>実行</NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}
