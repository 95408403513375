// ゴールの種類
export const GoalType = {
  url: 1,
  event: 2,
}

// ゴールイベントの種類
export const GoalEventType = {
  category: 0, // カテゴリ
  action: 1, // アクション
  label: 2, // ラベル
  value: 3, // 値
}

// ゴールの状態
export const Condition = {
  perfect: 1, // 完全一致
  head: 2, // 前方一致
  regexp: 3, // 正規表現一致
}

// 数値状態
export const ValueCondition = {
  greater: 1, // より大きい
  orHigher: 2, // 以上
  equal: 3, // に等しい
  orLower: 4, // 以下
  smaller: 5, // より小さい
  exists: 6, // 存在する
  notExists: 7, // 存在しない
}

export const GoalTypeMap: { [key: number]: string } = {
  1: '到達ページ',
  2: 'イベント',
}

export const MatchMap: { [key: number]: string } = {
  1: '完全一致',
  2: '前方一致',
  3: '正規表現一致',
}

export const ValueConditionMap: { [key: number]: string } = {
  1: 'より大きい',
  2: '以上',
  3: 'に等しい',
  4: '以下',
  5: 'より小さい',
  6: '存在する',
  7: '存在しない',
}
