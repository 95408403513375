import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { ContextType, useContextState } from './state'
import { CustomFilterContext } from '../CustomFilterContext'
import { useScopeType } from '../../util/hooks/useScopeType'
import { useDeviceType } from '../../util/hooks/useDeviceType'
import { useGoalId } from '../../util/hooks/useGoalId'
import { useFilterContentEventsExists } from '../../util/hooks/cookie/useFilterContentEventsExists'
import { ProjectContext } from '../../pages/ProjectRoot'
import { ReportContext } from '../ReportProvider'

export const CustomDimensionValueReportContext = React.createContext<ContextType>(null as any)

interface Props extends RouteComponentProps {
  readonly children?: React.ReactNode | React.ReactNode[]
}

/**
 * カスタムディメンションバリューレポートの state, actions をグローバルで使用できるよう Provider で定義する
 *
 * @param {Object} props - The props object containing the children component.
 * @param {React.ReactNode} props.children - The child component(s) to be wrapped.
 */
export function CustomDimensionValueReportProvider({ children }: Props) {
  const {
    state: { projectId },
  } = React.useContext(ProjectContext)
  const {
    state: { calenderState },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
    addonUrlParams,
  } = React.useContext(CustomFilterContext)

  const { state, actions } = useContextState()
  const { cachedReportParams } = state
  const { resetPagerValues } = actions

  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { goalId } = useGoalId({ projectId })
  const { filterContentEventsExists } = useFilterContentEventsExists()

  /**
   * レポート系ページで状態をキャッシュし、ページレポートで状態が変更されたらキャッシュをクリアする。
   * キャッシュ用途は、下層 > ページレポート > 下層に戻った際に状態が違えばページャーをリセットするために利用
   */
  React.useEffect(() => {
    if (!addonUrlParams) {
      actions.clearCachedReportParams()
      return
    }

    actions.cacheReportParams({
      calenderState,
      goalId,
      scopeType,
      deviceType,
      customFilterState,
      filterContentEventsExists,
    })
  }, [addonUrlParams])

  React.useEffect(() => {
    if (!addonUrlParams || !cachedReportParams?.calenderState) return
    const isCachedCalenderStateUnmatched = calenderState !== cachedReportParams?.calenderState
    const isCachedGoalIdUnmatched = goalId !== cachedReportParams?.goalId
    const isCachedScopeTypeUnmatched = scopeType !== cachedReportParams?.scopeType
    const isCachedDeviceTypeUnmatched = deviceType !== cachedReportParams?.deviceType
    const isCachedCustomFilterStateUnmatched =
      JSON.stringify(customFilterState) !== JSON.stringify(cachedReportParams?.customFilterState)
    const isCachedFilterContentEventsExistsUnmatched =
      filterContentEventsExists !== cachedReportParams?.filterContentEventsExists

    if (
      isCachedCalenderStateUnmatched ||
      isCachedGoalIdUnmatched ||
      isCachedScopeTypeUnmatched ||
      isCachedDeviceTypeUnmatched ||
      isCachedCustomFilterStateUnmatched ||
      isCachedFilterContentEventsExistsUnmatched
    ) {
      resetPagerValues({ calenderState, goalId, scopeType, deviceType, customFilterState, filterContentEventsExists })
    }
  }, [calenderState, goalId, scopeType, deviceType, customFilterState, filterContentEventsExists])

  return (
    <CustomDimensionValueReportContext.Provider value={{ state, actions }}>
      {children}
    </CustomDimensionValueReportContext.Provider>
  )
}
