import * as React from 'react'
import { request } from '../../util/request'
import { State, CategoryType } from './state'

// 処理ステップ
export const DataExportStep = {
  none: 0,
  createGcpAccount: 1, // GCPサービスアカウント作成

  addProject: 2, // プロジェクト追加
  addProjectComplete: 3, // プロジェクト追加完了

  addKey: 4, // キー追加
  addKeyComplete: 5, // キー追加完了

  infoProject: 6, // プロジェクト情報表示
  infoKey: 7, // キー情報表示
  saveKeyDescription: 8, // キー説明保存

  deleteAccount: 9, // アカウント削除
  deleteAccountComplete: 10, // アカウント削除完了

  deleteProject: 11, // プロジェクト削除
  deleteProjectComplete: 12, // プロジェクト削除完了

  deleteKey: 13, // キー削除
  deleteKeyComplete: 14, // キー削除完了
}

export class DataExport {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // GCPサービスアカウント作成
  onCreateGcpAccount = () => {
    this.setState({
      ...this.state,
      dataExport: {
        ...this.state.dataExport,
        editString: '',
        step: DataExportStep.createGcpAccount,
      },
    })
  }

  // GCPサービスアカウント作成キャンセル
  onCancelGcpAccount = () => {
    this.setState({
      ...this.state,
      dataExport: {
        ...this.state.dataExport,
        editString: '',
        step: DataExportStep.none,
      },
    })
  }

  // GCPサービスアカウント作成完了
  onCreateGcpAccountComplete = async (gcpId: number) => {
    this.setState({
      ...this.state,
      reload: true,
      scrollCategory: CategoryType.export,
      dataExport: {
        ...this.state.dataExport,
        gcpId: gcpId, // 選択gcpアカウント
        step: DataExportStep.none,
      },
    })
  }

  // アカウント削除
  onDeleteAccount = (id: number) => {
    const account = this.state.dataExport.accounts.find((account) => account.id === id)
    if (account) {
      this.setState({
        ...this.state,
        dataExport: {
          ...this.state.dataExport,
          selectedId: id,
          selectedString: account.gcsBucketName,
          step: DataExportStep.deleteAccount,
        },
      })
    }
  }

  // アカウント削除実行
  onDeleteAccountApply = async () => {
    try {
      await request('DELETE', `/api/gcp_sas/${this.state.dataExport.selectedId}/`, true)
      this.setState({
        ...this.state,
        reload: true,
        scrollCategory: CategoryType.export,
        dataExport: {
          ...this.state.dataExport,
          selectedId: 0,
          selectedString: '',
          step: DataExportStep.none,
        },
        toastMessage: 'サービスアカウントを削除しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        dataExport: {
          ...this.state.dataExport,
          selectedId: 0,
          selectedString: '',
          step: DataExportStep.none,
        },
        exportErrorMessage: typeof e === 'string' ? e : 'サービスアカウントの削除に失敗しました。',
      })
    }
  }

  // プロジェクト追加開始
  onAddProject = () => {
    this.setState({
      ...this.state,
      dataExport: {
        ...this.state.dataExport,
        selectedId:
          this.state.dataExport.selectProjects.length === 0 ? 0 : Number(this.state.dataExport.selectProjects[0].value),
        step: DataExportStep.addProject,
      },
    })
  }

  // 選択プロジェクト変更
  onChangeProject = (e: React.FormEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      dataExport: {
        ...this.state.dataExport,
        selectedId: Number(e.currentTarget.value),
      },
    })
  }

  // プロジェクト追加実行
  onAddApply = async () => {
    try {
      await request(
        'POST',
        `/api/gcp_sas/${this.state.dataExport.gcpId}/gcs_exports/`,
        true,
        JSON.stringify({ project_id: this.state.dataExport.selectedId }),
      )
      this.setState({
        ...this.state,
        reload: true,
        scrollCategory: CategoryType.export,
        dataExport: {
          ...this.state.dataExport,
          selectedId: 0,
          step: DataExportStep.none,
        },
        toastMessage: 'エクスポート対象プロジェクトを追加しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        dataExport: {
          ...this.state.dataExport,
          selectedId: 0,
          step: DataExportStep.none,
        },
        exportErrorMessage: typeof e === 'string' ? e : 'エクスポート対象プロジェクトの追加に失敗しました。',
      })
    }
  }

  // プロジェクト情報表示
  onOpenProjectInfo = (id: number) => {
    const project = this.state.dataExport.projects.find((project) => project.id === id)
    if (project) {
      this.setState({
        ...this.state,
        dataExport: {
          ...this.state.dataExport,
          selectedString: project.name,
          contentMaterData: project.contentsMasterFileUrl,
          logData: project.tagFileUrl,
          contentsTagsFileUrl: project.contentsTagsFileUrl,
          reportDaysLimit: project.reportDaysLimit,
          step: DataExportStep.infoProject,
        },
      })
    }
  }

  // プロジェクト情報閉じる
  onCloseProjectInfo = () => {
    this.setState({
      ...this.state,
      dataExport: {
        ...this.state.dataExport,
        selectedString: '',
        contentMaterData: '',
        logData: '',
        contentsTagsFileUrl: '',
        step: DataExportStep.none,
      },
    })
  }

  // プロジェクト削除
  onDeleteProject = (id: number) => {
    const project = this.state.dataExport.projects.find((project) => project.id === id)
    if (project) {
      this.setState({
        ...this.state,
        dataExport: {
          ...this.state.dataExport,
          selectedId: id,
          selectedString: project.name,
          step: DataExportStep.deleteProject,
        },
      })
    }
  }

  // プロジェクト削除実行
  onDeleteProjectApply = async () => {
    try {
      await request(
        'DELETE',
        `/api/gcp_sas/${this.state.dataExport.gcpId}/gcs_exports/${this.state.dataExport.selectedId}/`,
        true,
      )
      this.setState({
        ...this.state,
        reload: true,
        scrollCategory: CategoryType.export,
        dataExport: {
          ...this.state.dataExport,
          selectedId: 0,
          selectedString: '',
          step: DataExportStep.none,
        },
        toastMessage: 'エクスポート対象プロジェクトを削除しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        dataExport: {
          ...this.state.dataExport,
          selectedId: 0,
          selectedString: '',
          step: DataExportStep.none,
        },
        exportErrorMessage: typeof e === 'string' ? e : 'エクスポート対象プロジェクトの削除に失敗しました。',
      })
    }
  }

  // キー作成開始
  onAddKey = () => {
    this.setState({
      ...this.state,
      dataExport: {
        ...this.state.dataExport,
        editString: '',
        step: DataExportStep.addKey,
      },
    })
  }

  // キー作成実行(処理自体は別の箇所)
  onAddKeyComplete = async (_gcpId: number) => {
    this.setState({
      ...this.state,
      reload: true,
      scrollCategory: CategoryType.export,
      dataExport: {
        ...this.state.dataExport,
        editString: '',
        step: DataExportStep.none,
      },
    })
  }

  // キー情報表示
  onOpenKeyInfo = (id: number) => {
    const k = this.state.dataExport.keys.find((k) => k.id === id)
    if (k) {
      this.setState({
        ...this.state,
        dataExport: {
          ...this.state.dataExport,
          selectedId: id,
          editString: k.description,
          selectedString: k.gcpSaKeyId, // キーID
          step: DataExportStep.infoKey,
        },
      })
    }
  }

  // キー説明の編集
  updateKeyDescription = (event: React.FormEvent<HTMLInputElement>) => {
    const selectedKey = this.state.dataExport.keys.find((key) => key.id === this.state.dataExport.selectedId)
    this.setState({
      ...this.state,
      dataExport: {
        ...this.state.dataExport,
        editString: event.currentTarget.value,
        disabled: !(event.currentTarget.validity.valid && selectedKey?.description !== event.currentTarget.value),
      },
    })
  }

  // キー説明変更実行
  onChangeKeyApply = async () => {
    try {
      await request(
        'PATCH',
        `/api/gcp_sas/${this.state.dataExport.gcpId}/keys/${this.state.dataExport.selectedId}/`,
        true,
        JSON.stringify({ description: this.state.dataExport.editString }),
      )
      this.setState({
        ...this.state,
        reload: true,
        scrollCategory: CategoryType.export,
        dataExport: {
          ...this.state.dataExport,
          editString: '',
          selectedString: '',
          step: DataExportStep.none,
        },
        toastMessage: 'サービスアカウントのキー情報を変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        dataExport: {
          ...this.state.dataExport,
          editString: '',
          selectedString: '',
          step: DataExportStep.none,
        },
        exportErrorMessage: typeof e === 'string' ? e : 'サービスアカウントキーの更新に失敗しました。',
      })
    }
  }

  // キー削除
  onDeleteKey = (id: number) => {
    const k = this.state.dataExport.keys.find((k) => k.id === id)
    if (k) {
      this.setState({
        ...this.state,
        dataExport: {
          ...this.state.dataExport,
          selectedId: id,
          selectedString: k.description === '' ? k.gcpSaKeyId : k.description, // 説明がなければIDを表示しておく
          step: DataExportStep.deleteKey,
        },
      })
    }
  }

  // キー削除実行
  onDeleteKeyApply = async () => {
    try {
      await request(
        'DELETE',
        `/api/gcp_sas/${this.state.dataExport.gcpId}/keys/${this.state.dataExport.selectedId}/`,
        true,
      )
      this.setState({
        ...this.state,
        reload: true,
        scrollCategory: CategoryType.export,
        dataExport: {
          ...this.state.dataExport,
          selectedId: 0,
          selectedString: '',
          editString: '',
          step: DataExportStep.none,
        },
        toastMessage: 'サービスアカウントのキーを削除しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        dataExport: {
          ...this.state.dataExport,
          selectedId: 0,
          selectedString: '',
          step: DataExportStep.none,
        },
        exportErrorMessage: typeof e === 'string' ? e : 'サービスアカウントキーの削除に失敗しました。',
      })
    }
  }

  // キャンセル(アカウント、プロジェクト、キー共通)
  onCancel = () => {
    this.setState({
      ...this.state,
      dataExport: {
        ...this.state.dataExport,
        selectedId: 0,
        selectedString: '',
        editString: '',
        step: DataExportStep.none,
      },
    })
  }
}
