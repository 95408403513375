import { useQuery, useQueryClient } from '@tanstack/react-query'

import { Goal, GoalsData } from '.'
import { GOAL_LIST_QUERY_KEY } from '../constants'
import { request } from '../../../request'

type Props = {
  projectId: number
}

export const useGoalList = ({ projectId }: Props) => {
  const queryClient = useQueryClient()
  const queryKey = [GOAL_LIST_QUERY_KEY, { projectId }]

  const fetchGoalList = async () => {
    return await request<GoalsData>('GET', `/api/projects/${projectId}/goals/`, true)
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: fetchGoalList,
    select: transformGoalList,
    enabled: !!projectId,
  })

  const getGoal = (goalId: number) => {
    return queryResult.data?.find((goal: Goal) => goal.id === goalId)
  }

  const invalidate = async () => await queryClient.invalidateQueries({ queryKey })

  return {
    ...queryResult,
    invalidate,
    getGoal,
  }
}

const transformGoalList = (response: GoalsData): Goal[] => {
  if (response.results.length === 0) return []

  return response.results.map((item) => ({
    id: item.id,
    name: item.name,
    updatedAt: item.updated_at,
  }))
}
