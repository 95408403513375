import * as React from 'react'
import { navigate, Redirect } from '@gatsbyjs/reach-router'

import { GlobalContext } from '../../../GlobalState'
import { Authority } from '../../../util/Authority'
import { Loading } from '../../../components/common/Loading'
import { ProjectContext } from '../../ProjectRoot'
import { ErrorBox } from '../../../components/common/ErrorBox'
import { useFunnelReport } from '../../../util/hooks/api/FunnelReport/useFunnelReport'
import { EditReport } from '../EditReport'
import { useFunnelReports } from '../../../util/hooks/api/FunnelReport/useFunnelReports'
import { useUpdateFunnelReport } from '../../../util/hooks/api/FunnelReport/useUpdateFunnelReport'
import { CanNotChangeMessage } from '../../../components/warnings/CanNotChangeMessage'
import { useReportSettings } from '../../../util/hooks/useReportSettings'

interface Props {
  readonly funnelReportId: number
}

/**
 * ファネルレポート詳細ページのレポート編集エリアをレンダリングする
 *
 * @param {object} param - The input parameters for the method.
 * @param {number} param.funnelReportId - ファネルレポートID
 */
export function EditReportArea({ funnelReportId }: Props) {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { projectId, baseUrl },
  } = React.useContext(ProjectContext)

  const canChange = React.useMemo(
    () => Authority.canChangeFunnelReport(AccountInfo.permissions, projectId),
    [AccountInfo.permissions, projectId],
  )

  const { isChecking: isSettingsChecking, isCompleted: isSettingsCompleted } = useReportSettings({ projectId })

  const {
    data: reportsData,
    isLoading: isReportsLoading,
    resetFunnelReports,
  } = useFunnelReports({ projectId, enabled: canChange })

  const { data, resetFunnelReport, isLoading, isError, errorMessage } = useFunnelReport({
    projectId,
    funnelReportId,
    enabled: canChange,
  })

  const {
    mutate: updateFunnelReportMutate,
    isLoading: isUpdating,
    isError: isUpdateError,
  } = useUpdateFunnelReport({
    projectId,
    funnelReportId,
  })

  const onInvalidate = () => {
    resetFunnelReports()
    resetFunnelReport()
  }

  const handleCancel = () => {
    navigate(`${baseUrl}report/funnel/${funnelReportId}/`)
  }

  if (!canChange) return <CanNotChangeMessage />
  if (isSettingsChecking || isReportsLoading || isLoading || isUpdating) return <Loading />
  if (!isSettingsCompleted || reportsData?.reportLimitExceeded) {
    return <Redirect to={`${baseUrl}report/funnel`} noThrow />
  }

  return (
    <>
      {isError && !errorMessage && <ErrorBox>ファネルレポートの取得に失敗しました。</ErrorBox>}
      {isUpdateError && <ErrorBox>ファネルレポートの保存に失敗しました。</ErrorBox>}
      <EditReport
        data={data}
        saveMutate={updateFunnelReportMutate}
        onInvalidate={onInvalidate}
        onCancel={handleCancel}
      />
    </>
  )
}
