import React from 'react'
import * as Loader from 'react-spinners'
import { colors } from '../../styleConstants'

type loaderType =
  | 'Bar'
  | 'Beat'
  | 'Bounce'
  | 'Circle'
  | 'ClimbingBox'
  | 'Clip'
  | 'Clock'
  | 'Dot'
  | 'Fade'
  | 'Grid'
  | 'Hash'
  | 'Moon'
  | 'Pacman'
  | 'Propagate'
  | 'Pulse'
  | 'Ring'
  | 'Rise'
  | 'Rotate'
  | 'Scale'
  | 'Sync'

const override = `
display: block;
margin: 0 auto;
border-color: ${colors.lightBlue};
`

interface Props {
  readonly type?: loaderType
  readonly loading?: boolean
  readonly size?: number
  readonly height?: number
  readonly width?: number
  readonly radius?: number
  readonly margin?: number
  readonly color?: string
}

export function Spinner(props: Props) {
  const {
    type = 'Bar',
    loading = true,
    size = 30,
    height = 4,
    width = 100,
    radius = 2,
    margin = 2,
    color = `${colors.lightBlue}`,
  } = props
  return (
    <div>
      {type === 'Bar' && (
        <Loader.BarLoader css={override} height={height} width={width} color={color} loading={loading} />
      )}
      {type === 'Beat' && (
        <Loader.BeatLoader css={override} size={size} margin={margin} color={color} loading={loading} />
      )}
      {type === 'Bounce' && <Loader.BounceLoader css={override} size={size} color={color} loading={loading} />}
      {type === 'Circle' && <Loader.CircleLoader css={override} size={size} color={color} loading={loading} />}
      {type === 'ClimbingBox' && (
        <Loader.ClimbingBoxLoader css={override} size={size} color={color} loading={loading} />
      )}
      {type === 'Clip' && <Loader.ClipLoader css={override} size={size} color={color} loading={loading} />}
      {type === 'Clock' && <Loader.ClockLoader css={override} size={size} color={color} loading={loading} />}
      {type === 'Dot' && <Loader.DotLoader css={override} size={size} color={color} loading={loading} />}
      {type === 'Fade' && (
        <Loader.FadeLoader
          css={override}
          height={height}
          width={width}
          radius={radius}
          margin={margin}
          color={color}
          loading={loading}
        />
      )}
      {type === 'Grid' && <Loader.GridLoader css={override} size={size} color={color} loading={loading} />}
      {type === 'Hash' && <Loader.HashLoader css={override} size={size} color={color} loading={loading} />}
      {type === 'Moon' && <Loader.MoonLoader css={override} size={size} color={color} loading={loading} />}
      {type === 'Pacman' && (
        <Loader.PacmanLoader css={override} size={size} margin={margin} color={color} loading={loading} />
      )}
      {type === 'Propagate' && <Loader.PropagateLoader css={override} size={size} color={color} loading={loading} />}
      {type === 'Pulse' && (
        <Loader.PulseLoader css={override} size={size} margin={margin} color={color} loading={loading} />
      )}
      {type === 'Ring' && <Loader.RingLoader css={override} size={size} color={color} loading={loading} />}
      {type === 'Rise' && (
        <Loader.RiseLoader css={override} size={size} margin={margin} color={color} loading={loading} />
      )}
      {type === 'Rotate' && (
        <Loader.RotateLoader css={override} size={size} margin={margin} color={color} loading={loading} />
      )}
      {type === 'Scale' && (
        <Loader.ScaleLoader
          css={override}
          height={height}
          width={width}
          radius={radius}
          margin={margin}
          color={color}
          loading={loading}
        />
      )}
      {type === 'Sync' && <Loader.SyncLoader css={override} size={size} color={color} loading={loading} />}
    </div>
  )
}
