import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { CancelButton, NormalButton } from '../common/Button'
import { Select } from '../common/Select'
import { Input } from '../common/Input'
import { RadioButton } from '../common/RadioButton'
import { ScopeCondition, ScopeConditionMap } from '../list/DimensionList'

const rangeOptions = [
  { value: ScopeCondition.user, label: ScopeConditionMap[ScopeCondition.user] },
  { value: ScopeCondition.session, label: ScopeConditionMap[ScopeCondition.session] },
  { value: ScopeCondition.hit, label: ScopeConditionMap[ScopeCondition.hit] },
]

interface Props {
  readonly created?: boolean
  readonly opened: boolean
  readonly disabled?: boolean
  readonly index: number
  readonly name: string
  readonly scope: number
  readonly querySourced: boolean
  readonly activated: boolean
  readonly onNameUpdate?: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onScopeChange?: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly onQuerySourcedChange?: () => void
  readonly onActivatedChange?: () => void
  readonly onCancel: () => void
  readonly onCreate?: () => void
  readonly onApply?: () => void
}

export function DimensionModal(props: Props) {
  const {
    created = false,
    opened,
    disabled,
    index,
    name,
    scope,
    querySourced,
    activated,
    onNameUpdate,
    onScopeChange,
    onQuerySourcedChange,
    onActivatedChange,
    onCancel,
    onCreate,
    onApply,
  } = props
  return (
    <Modal
      onClose={onCancel}
      isOpen={opened}
      title={created ? 'カスタムディメンションの追加' : 'カスタムディメンションの編集'}
      width={664}
    >
      <ModalContentWrapper>
        <div>
          {!created && (
            <div style={{ marginBottom: '1.3rem' }}>
              <Title>Index</Title>
              <div style={{ fontSize: '1.2rem', color: `${colors.gray500}` }}>{index}</div>
            </div>
          )}

          <Title>名前またはクエリー文字列名</Title>
          <DesignedInput type="text" value={name} required maxLength={50} onChange={onNameUpdate} />

          <Title>範囲</Title>
          <DesignedSelect value={scope} options={rangeOptions} onChange={onScopeChange} />

          <Title>クエリー文字由来</Title>
          <div style={{ display: 'flex', marginBottom: '1.3rem' }}>
            <RadioButton buttonSize="large" checked={querySourced} onChange={onQuerySourcedChange} message="はい" />
            <RadioButton
              buttonSize="large"
              checked={!querySourced}
              onChange={onQuerySourcedChange}
              style={{ marginLeft: '2rem' }}
              message="いいえ"
            />
          </div>

          <Title>状態</Title>
          <div style={{ display: 'flex' }}>
            <RadioButton buttonSize="large" checked={activated} onChange={onActivatedChange} message="アクティブ" />
            <RadioButton
              buttonSize="large"
              checked={!activated}
              onChange={onActivatedChange}
              style={{ marginLeft: '2rem' }}
              message="無効"
            />
          </div>
        </div>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <NormalButton disabled={disabled} onClick={created ? onCreate : onApply}>
          {created ? '追加' : '保存'}
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.3rem;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 40px;
  margin-bottom: 1.3rem;
`

const DesignedSelect = styled(Select)`
  width: 170px;
  height: 40px;
  margin-right: 1rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  margin-bottom: 1.3rem;
`
