import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { CaretDown, CaretUp } from '@styled-icons/boxicons-regular'

export const SORT_ICONS = {
  UP: 'up',
  DOWN: 'down',
  NONE: 'none',
} as const
export type SortIconState = typeof SORT_ICONS[keyof typeof SORT_ICONS]

interface Props {
  readonly onSort?: (key: any) => void
  readonly elementKey: any
  readonly sortIcon: SortIconState
}

export function SortButton({ onSort, elementKey, sortIcon }: Props) {
  const onClick = () => {
    onSort && onSort(elementKey)
  }
  return (
    <StyledSortButton onClick={onClick}>
      <Up color={sortIcon === SORT_ICONS.UP ? colors.black : colors.gray400} />
      <Down color={sortIcon === SORT_ICONS.DOWN ? colors.black : colors.gray400} />
    </StyledSortButton>
  )
}

const StyledSortButton = styled.button`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 0.3rem;
`

const Up = styled(CaretUp).attrs({
  size: '12',
})`
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
`

const Down = styled(CaretDown).attrs({
  size: '12',
})`
  position: absolute;
  top: calc(50% - 2px);
  left: 0;
`
