import * as React from 'react'
import styled from 'styled-components'
import { ConfirmModal } from '../common/ConfirmModal'

interface Props {
  readonly opened: boolean
  readonly projectName: string
  readonly gcsBucket: string
  readonly contentsMaster: string
  readonly logData: string
  readonly contentsTagsFileUrl: string
  readonly reportDaysLimit: number
  readonly onCancel: () => void
}

export function DataExportSetting({
  opened,
  projectName,
  gcsBucket,
  contentsMaster,
  logData,
  contentsTagsFileUrl,
  reportDaysLimit,
  onCancel,
}: Props) {
  return (
    <ConfirmModal isOpen={opened} onClose={onCancel} title="エクスポート設定" width={860}>
      <Box>
        <Field>
          <Title>プロジェクト名</Title>
          <Value>{projectName}</Value>
        </Field>

        <Field>
          <Title>GCSバケット</Title>
          <Value>{gcsBucket}</Value>
        </Field>

        <Field>
          <Title>最新のデータファイル</Title>
          <DataFileFieldArea>
            <DataFileField>
              <DataFileTitle>ログデータ</DataFileTitle>
              <DataFileValue>{logData ? logData : '-'}</DataFileValue>
            </DataFileField>
            <DataFileField>
              <DataFileTitle>コンテンツマスタ</DataFileTitle>
              <DataFileValue>{contentsMaster ? contentsMaster : '-'}</DataFileValue>
            </DataFileField>
            <DataFileField>
              <DataFileTitle>コンテンツタグデータ</DataFileTitle>
              <DataFileValue>{contentsTagsFileUrl ? contentsTagsFileUrl : '-'}</DataFileValue>
            </DataFileField>
          </DataFileFieldArea>
        </Field>

        <div>
          <Message>* サービスアカウントを利用してGCSバケットからデータファイルをダウンロードできます</Message>
          <Message>* 1日1回毎朝6時までに前日までの全件データファイルを日次でエクスポートします</Message>
          <Message>
            * データファイルの保存期間は{reportDaysLimit.toLocaleString()}
            日分となります。それ以前のデータファイルは自動で削除されます
          </Message>
        </div>
      </Box>
    </ConfirmModal>
  )
}

const Field = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

const DataFileField = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 24px;
`

const Value = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 24px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
`

const DataFileFieldArea = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

const Title = styled.div`
  font-weight: bold;
  white-space: nowrap;
  line-height: 21px;
`

const DataFileTitle = styled(Title)`
  display: flex;
  align-items: center;
  font-weight: normal;
  width: 146px;
`

const Message = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;
  line-height: 21px;
`

const DataFileValue = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;

  ::before {
    content: ':';
    padding-right: 8px;
    font-size: 14px;
  }
`
