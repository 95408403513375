import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../../styleConstants'
import { Logo } from '../common/Logo'
import { Footer } from '../common/Footer'

interface Props {
  readonly children?: React.ReactNode
  readonly width?: string
}

export function SimplePageLayout({ children, width }: Props) {
  return (
    <Container>
      <Box>
        <MainBox>
          <Logo size="large" style={{ margin: '0 auto' }} />
          <Main style={{ width: width }}>{children}</Main>
        </MainBox>
        <Footer hidden={false} />
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  padding: 4rem 0 0 0;
  background-color: ${colors.bg};
  overflow-y: auto;
`

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Main = styled.div`
  margin: 2rem auto 4rem;
  max-width: 100%;
  width: ${layout.baseWidth};
  padding: 2rem 3rem;
  background-color: ${colors.white};
`
