import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'

// 処理ステップ
export const InviteStep = {
  none: 0,
  confirm: 1, // 招待取消確認
  complete: 2, // 招待取消完了
}

export class Invite {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // 招待取り消し開始
  onRevoke = (id: number) => {
    const invite = this.state.invite.users.filter((user) => user.id === id)
    if (invite) {
      this.setState({
        ...this.state,
        invite: {
          ...this.state.invite,
          selectedId: invite[0].id,
          selectedEmail: invite[0].email,
          step: InviteStep.confirm,
        },
        inviteErrorMessage: '',
      })
    }
  }

  // 取消キャンセル
  onCancel = () => {
    this.setState({
      ...this.state,
      invite: {
        ...this.state.invite,
        selectedId: 0,
        selectedEmail: '',
        step: InviteStep.none,
      },
      inviteErrorMessage: '',
    })
  }

  // 取消実行
  onApply = async () => {
    try {
      await request(
        'DELETE',
        `/api/projects/${this.state.project.id}/members/invite/${this.state.invite.selectedId}/`,
        true,
      )
      this.setState({
        ...this.state,
        reload: true,
        invite: {
          ...this.state.invite,
          selectedId: 0,
          selectedEmail: '',
          step: InviteStep.none,
        },
        inviteErrorMessage: '',
        toastMessage: '招待を取り消しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        invite: {
          ...this.state.invite,
          selectedId: 0,
          selectedEmail: '',
          step: InviteStep.none,
        },
        inviteErrorMessage: typeof e === 'string' ? e : '招待の取り消しに失敗しました。',
      })
    }
  }
}
