import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../styleConstants'

interface Props {
  readonly buttonSize?: 'large' | 'normal'
  readonly message?: string
  readonly color?: string
  readonly style?: React.CSSProperties
}

export function RadioButton(props: Props & React.InputHTMLAttributes<any>) {
  const { buttonSize = 'normal', message, color, style, ...rest } = props
  return (
    <Container style={style}>
      <label style={{ display: 'flex', cursor: 'pointer' }}>
        <DesignedInput className={cc(buttonSize)} type="radio" {...rest} />
        <MessageBox className={cc(buttonSize)} style={{ color: color }}>
          {message}
        </MessageBox>
      </label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DesignedInput = styled.input`
  &.large {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  &.normal {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  :checked {
    accent-color: ${colors.contentBlue.blue5};
  }
`

const MessageBox = styled.div`
  &.large {
    font-size: 1.2rem;
    margin-top: 0.3rem;
  }

  &.normal {
    font-size: 0.9rem;
    margin-top: 0.2rem;
  }
`

interface RadioButtonRowProps {
  readonly size?: number
  readonly message?: string
  readonly color?: string
  readonly style?: React.CSSProperties
}

export function RadioButtonRow(props: RadioButtonRowProps & React.InputHTMLAttributes<any>) {
  const { size = 14, message, color, style, ...rest } = props
  return (
    <RowContainer style={style}>
      <RowLabel>
        <RowDesignedInput size={size} type="radio" {...rest} />
        <RowMessage size={size} style={{ color: color }}>
          {message}
        </RowMessage>
      </RowLabel>
    </RowContainer>
  )
}

const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const RowLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const RowDesignedInput = styled.input<{ size: number }>`
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
    margin-right: ${size / 4}px;
  `}
  :checked {
    accent-color: ${colors.contentBlue.blue5};
  }
`

const RowMessage = styled.div<{ size: number }>`
  ${({ size }) => `
    font-size: ${size}px;
  `}
`
