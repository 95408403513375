import React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { GlobalContext } from '../GlobalState'
import { layout } from '../styleConstants'
import styled from 'styled-components'
import { PageLayout } from '../components/layout/PageLayout'
import { DataImport } from '../components/DataImport'
import { PageHeader } from '../components/common/PageHeader'
import { makeProjectPageHeaderInfo } from '../util/makePageHeaderInfo'

const PAGE_TITLE = '外部データインポート'

interface DataImportProps extends RouteComponentProps {
  projectId?: string
}

export function DataImportPage(props: DataImportProps) {
  if (!props.projectId) return <></>

  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)

  const pageHeaderInfo = makeProjectPageHeaderInfo(PAGE_TITLE, AccountInfo)
  return (
    <>
      <PageHeader title={pageHeaderInfo.title} description={pageHeaderInfo.description} />
      <PageLayout
        headerTitle={PAGE_TITLE}
        optionHidden={true}
        filterButtonHidden={true}
        baseUrl={props.uri?.split(props.path!).join('')}
      >
        <Container>
          <DataImport projectId={props.projectId} permissions={AccountInfo.permissions} />
        </Container>
      </PageLayout>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 2rem;
  width: ${layout.dataImportPageWidth};
`
