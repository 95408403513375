import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'

export const FilterButton: React.FC<{ alreadyHasTargetDimension: boolean } & React.ButtonHTMLAttributes<any>> = ({
  children,
  ...props
}) => {
  return <Button {...props}>{children}</Button>
}

const Button = styled.button<{ alreadyHasTargetDimension: boolean }>`
  border-radius: 15px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  padding: 6px 12px;
  border: 1px solid ${colors.contentBlue.blue1};
  background-color: ${colors.contentBlue.blue3};
  color: ${colors.contentBlue.blue7};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.contentBlue.blue2};
  }

  ${({ alreadyHasTargetDimension }) =>
    alreadyHasTargetDimension &&
    `
    border: 1px solid ${colors.contentBlue.blue1};
    background-color: ${colors.contentBlue.blue2};
    color: ${colors.contentBlue.blue7};
    &:hover {
      background-color: ${colors.contentBlue.blue3};
    }
  `};

  &:disabled {
    color: ${colors.gray750};
    border: 1px solid ${colors.gray750};
    &:hover {
      background-color: ${colors.contentBlue.blue3};
    }
  }
`
