import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../styleConstants'
import { SubActionIconButton } from '../../../common/Button'
import { Authority } from '../../../../util/Authority'
import { ImportField } from '../../../../util/hooks/api/DataImport/types'
import { Check } from 'styled-icons/boxicons-regular'
import { DATA_TYPE_OPTIONS } from '..'
import { KeyFieldUnit } from '../KeyFieldUnit'
import { CheckCircle } from '../../../common/CheckCircle'

interface Props {
  projectId: string
  permissions: any
  keyFieldLabel: string | undefined
  importFields: ImportField[]
  openEditPanel: () => void
}

export function FieldReferencePanel({ projectId, permissions, keyFieldLabel, importFields, openEditPanel }: Props) {
  return (
    <>
      {Authority.canChangeDataImportSchema(permissions, projectId) && (
        <EditButtonWrapper>
          <SubActionIconButton onClick={openEditPanel}>編集</SubActionIconButton>
        </EditButtonWrapper>
      )}
      {/* step 1: キーフィールド */}
      <KeyFieldUnit value={keyFieldLabel || ''} />

      {/* step 2: インポートフィールド */}
      <StepSection>
        <StepSectionHeadNo>{<Check size={20} color={colors.white} />}</StepSectionHeadNo>
        <StepSectionBody>
          <StepSectionBodyTitle>
            <StepSectionBodyTitleText>インポートフィールド</StepSectionBodyTitleText>
          </StepSectionBodyTitle>
          <RowsTableWrapper>
            <RowsTable>
              <RowsTableHead>
                <RowsTableTr>
                  <PrimaryKeyRowsTableTh>キー</PrimaryKeyRowsTableTh>
                  <FieldNameRowsTableTh>フィールド名</FieldNameRowsTableTh>
                  <DataTypeRowsTableTh>データタイプ</DataTypeRowsTableTh>
                </RowsTableTr>
              </RowsTableHead>
              <RowsTableTbody>
                {importFields.map((importField) => {
                  const { primaryKey, fieldName, dataType } = importField
                  return (
                    <RowsTableTr key={fieldName}>
                      <PrimaryKeyRowsTableTd>
                        {primaryKey && (
                          <IconCenter>
                            <CheckCircle borderColor={colors.headerBg} iconSize={16} iconColor={colors.headerBg} />
                          </IconCenter>
                        )}
                      </PrimaryKeyRowsTableTd>
                      <FieldNameRowsTableTd>{fieldName}</FieldNameRowsTableTd>
                      <DataTypeRowsTableTd>
                        {DATA_TYPE_OPTIONS.find((option) => dataType === option.value)?.label}
                      </DataTypeRowsTableTd>
                    </RowsTableTr>
                  )
                })}
              </RowsTableTbody>
            </RowsTable>
          </RowsTableWrapper>
        </StepSectionBody>
      </StepSection>
    </>
  )
}

const EditButtonWrapper = styled.div`
  margin-bottom: 25px;
`

const StepSection = styled.div`
  display: flex;
  font-size: 16px;
  padding: 0 0 20px;
`

const StepSectionHeadNo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 10px 0 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${colors.headerBg};
  color: ${colors.white};
  font-size: 1rem;
  font-weight: bold;
  z-index: 2;
`

const StepSectionBody = styled.div`
  flex: 1;
  padding-top: 3px;
  color: ${colors.gray750};
`
const StepSectionBodyTitle = styled.div``

const StepSectionBodyTitleText = styled.div`
  margin: 0 5px 0 0;
  font-weight: bold;
  color: ${colors.headerBg};
`

const RowsTableWrapper = styled.div`
  margin: 15px 0 0;
  font-size: 14px;
`
const RowsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`
const RowsTableHead = styled.thead``
const RowsTableTbody = styled.tbody``
const RowsTableTr = styled.tr``
const RowsTableTh = styled.th`
  padding: 10px 16px;
  border-bottom: 1px solid #ccc;
  font-weight: normal;
  text-align: left;
`
const PrimaryKeyRowsTableTh = styled(RowsTableTh)`
  width: 0;
  white-space: nowrap;
  text-align: center;
`
const FieldNameRowsTableTh = styled(RowsTableTh)`
  width: 340px;
`
const DataTypeRowsTableTh = styled(RowsTableTh)``

const RowsTableTd = styled.td`
  padding: 10px 16px;
  border-bottom: 1px solid #ddd;
  color: ${colors.black2};
`
const PrimaryKeyRowsTableTd = styled(RowsTableTd)`
  width: 0;
  white-space: nowrap;
  text-align: center;
`
const FieldNameRowsTableTd = styled(RowsTableTd)`
  width: 340px;
`
const DataTypeRowsTableTd = styled(RowsTableTd)``

const IconCenter = styled.div`
  display: flex;
  justify-content: center;
`
