import * as React from 'react'
import styled from 'styled-components'

import { SortItemKey, SortState } from '../../../util/hooks/api/useContentReport'
import { SortButton } from '../../common/SortButton'
import { BaseHeaderCell } from './BaseCell'
import { HeaderCellHelp } from './HeaderCellHelp'
import { SCOPE_TYPE, useScopeType } from '../../../util/hooks/useScopeType'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'

interface Props {
  readonly sortState: SortState
  readonly onSortClick: (key: SortItemKey) => void
}

export function ClickCountHeaderCell({ sortState, onSortClick }: Props) {
  const { scopeType, scopeTypeLabel } = useScopeType()

  return (
    <Cell onClick={() => onSortClick('clickCount')}>
      数
      <HeaderCellHelp width={240} left={-120}>
        <div>コンテンツをクリックした{scopeTypeLabel}数。</div>
        <div>
          {scopeType === SCOPE_TYPE.USER ? '同一ユーザーに' : '同一セッション内で'}
          複数回クリックされても、1カウント。
        </div>
      </HeaderCellHelp>
      <SortButton elementKey={'clickCount'} sortIcon={sortState['clickCount']} />
    </Cell>
  )
}

const Cell = styled(BaseHeaderCell)`
  background-color: ${CELL_BACKGROUND_COLORS.CLICK};
`
