import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'
import { CreateDataImportSchemasPayload, DataImportSchemasResultsResponse, DataImportSchemasData } from './types'
import { formatResponse } from './formatResponse'

type Props = {
  projectId: string
  options: UseMutationOptions<DataImportSchemasData, string, CreateDataImportSchemasPayload, void>
}

export const useCreateDataImportSchemas = ({ projectId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async (payload: CreateDataImportSchemasPayload) => {
      const { importFields, keyField } = payload
      const formattedPayload = {
        data_import_key_field: {
          table: keyField.table,
          custom_dimension_id: keyField.customDimensionId,
        },
        data_import_fields: importFields.map((importField) => ({
          for_key: importField.primaryKey,
          name: importField.fieldName,
          type: importField.dataType,
        })),
      }

      const result = await request<DataImportSchemasResultsResponse>(
        'POST',
        `/api/projects/${projectId}/data_import_schemas/`,
        true,
        JSON.stringify(formattedPayload),
      )

      const formattedResponse: DataImportSchemasData = formatResponse(result)
      return formattedResponse
    },
    ...options,
  })
  return mutationResult
}
