import * as React from 'react'
import { GlobalContext } from '../GlobalState'
import { usePageState } from './ProjectRoot/state'
import { GlobalContextType, useContextState } from '../contexts/ProjectContext/state'

export const ProjectContext = React.createContext<GlobalContextType>(null as any)

export function ProjectRoot(props: any): JSX.Element {
  // TODO: 本コンポーネントでglobalActionsとusePageStateは使わずにuseContextStateで完結するようにリファクタする
  const { actions: globalActions } = React.useContext(GlobalContext)
  const {
    state: { loading },
    actions,
  } = usePageState()

  const { state: contextState, actions: contextActions } = useContextState()

  React.useEffect(() => {
    const projectId = Number(props?.projectId)
    if (isNaN(projectId)) return
    contextActions.setValues(projectId, props.uri)
    actions.fetch(props.projectId, globalActions.setProjectInfo)
  }, [props.projectId])

  if (loading || !contextState.projectId) {
    return <div />
  }

  return (
    <ProjectContext.Provider value={{ state: contextState, actions: contextActions }}>
      {props.children}
    </ProjectContext.Provider>
  )
}
