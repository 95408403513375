import React from 'react'
import styled from 'styled-components'

import { colors } from '../../styleConstants'
import { ErrorBox } from './ErrorBox'

interface Props {
  readonly pvLimit: number
}

export const PvLimitErrorBox = ({ pvLimit }: Props) => {
  return (
    <ErrorBox>
      <MessageArea>
        <div>組織の合計PV数が月間上限PVに達しました。</div>
        <PvArea>
          <PvCount>{`${pvLimit.toLocaleString()}PV`}</PvCount>
          <span>{`/${pvLimit.toLocaleString()}PV`}</span>
        </PvArea>
        <div>PV数の上限を超えているため、トラッキングできません。</div>
      </MessageArea>
    </ErrorBox>
  )
}

const MessageArea = styled.div`
  font-size: 14px;
  line-height: 21px;
`

const PvArea = styled.div`
  font-weight: bold;
  padding-bottom: 8px;
`

const PvCount = styled.span`
  color: ${colors.red};
`
