import React, { useState } from 'react'
import styled from 'styled-components'
import { HelpLink } from '../common/HelpLink'
import { HELP_LINKS } from '../../constants'
import { ImportFieldArea } from './ImportFieldArea'
import { ImportExecutionArea } from './ImportExecutionArea'
import { ImportedDataArea } from './ImportedDataArea'
import { colors } from '../../styleConstants'
import { Authority } from '../../util/Authority'

export const IMPORTED_HISTORY_AREA_ID = 'target-area-imported-history'
export const IMPORT_FIELDS_AREA_ID = 'target-area-import-fields'

interface Props {
  projectId: string
  permissions: any
}

export function DataImport({ projectId, permissions }: Props) {
  const [hasImportField, setHasImportField] = useState(true)
  const [isImportFieldEditing, setIsImportFieldEditing] = useState(false)

  return (
    <Box>
      <HintSection>
        <HintSectionText>
          外部データインポートとは、外部データをContent&nbsp;Analyticsに取り込み、コンテンツレポート内のフィルタ機能を使ってセグメント別のコンテンツ傾向を見ることができるようになる機能です。
        </HintSectionText>
        <HelpLink title={'外部データインポートとは'} link={HELP_LINKS.DATA_IMPORT} />
      </HintSection>

      <OuterArea>
        <ImportFieldArea
          projectId={projectId}
          permissions={permissions}
          isEditing={isImportFieldEditing}
          setIsEditing={setIsImportFieldEditing}
          setHasImportField={setHasImportField}
        />
      </OuterArea>

      {/* データインポートエリアはセキュアな情報があるため(サーバー情報など)、編集権限がない場合は非表示とする */}
      {Authority.canChangeDataImportSchedule(permissions, projectId) && (
        <OuterArea>
          <ImportExecutionArea
            projectId={projectId}
            hasImportField={hasImportField}
            isImportFieldEditing={isImportFieldEditing}
          />
        </OuterArea>
      )}

      {Authority.canViewImportedData(permissions, projectId) && (
        <OuterArea>
          <ImportedDataArea
            projectId={projectId}
            permissions={permissions}
            hasImportField={hasImportField}
            isImportFieldEditing={isImportFieldEditing}
          />
        </OuterArea>
      )}
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`

const HintSection = styled.div`
  margin-bottom: 24px;
  line-height: 1.4;
  font-size: 14px;
  color: #666666;
`
const HintSectionText = styled.div`
  margin: 0 0 15px;
  font-size: 16px;
`

const OuterArea = styled.div`
  & + & {
    margin-top: 2rem;
  }
`

export const AccordionHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.headerBg};
`
