import React from 'react'
import styled from 'styled-components'
import { FunnelStepData, FunnelReportEditData, EditingStepIndex } from '../../../util/hooks/api/FunnelReport/types'
import { Step } from './Step'
import { AddButton } from '../../../components/common/Button'
import { INIT_STEP, MAX_STEP_LENGTH } from '../../../util/hooks/api/FunnelReport/constants'

interface Props {
  steps: FunnelStepData[]
  funnelIndex: number
  updateEditingData: (newData: (draft: FunnelReportEditData) => void) => void
  setEditingStepIndex: React.Dispatch<React.SetStateAction<EditingStepIndex | null>>
  setEditingStep: React.Dispatch<React.SetStateAction<FunnelStepData>>
  setIsStepModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  resetAccessCount: (funnelIndex: number, stepIndex: number) => void
  setRefs: (refArray: (HTMLDivElement | null)[]) => void
  maxHeights: number[]
}

export const Steps = ({
  steps,
  funnelIndex,
  updateEditingData,
  setEditingStepIndex,
  setEditingStep,
  setIsStepModalOpened,
  resetAccessCount,
  setRefs,
  maxHeights,
}: Props) => {
  const stepTitleRefs = React.useRef<(HTMLDivElement | null)[]>([])

  const handleOpenEditStepModal = (stepIndex: number, isInsertNewStep: boolean = false) => {
    const targetStep = isInsertNewStep ? INIT_STEP : steps[stepIndex]
    setEditingStep(targetStep)
    setEditingStepIndex({ funnelIndex, stepIndex, isInsertNewStep })
    setIsStepModalOpened(true)
  }

  const handleDeleteStep = (stepIndex: number) => {
    updateEditingData((draft) => {
      draft.funnels[funnelIndex].steps.splice(stepIndex, 1)
    })
    resetAccessCount(funnelIndex, stepIndex)
  }

  React.useEffect(() => {
    setRefs(stepTitleRefs.current)
  }, [setRefs])

  const canAddStep = steps.length < MAX_STEP_LENGTH
  const lastStepIndex = steps.length - 1

  return (
    <>
      {steps.map((step, stepIndex) => (
        <Step
          key={stepIndex}
          step={step}
          stepIndex={stepIndex}
          lastStepIndex={lastStepIndex}
          canAddStep={canAddStep}
          handleOpenEditStepModal={handleOpenEditStepModal}
          handleDeleteStep={handleDeleteStep}
          stepTitleRefs={stepTitleRefs}
          maxHeights={maxHeights}
        />
      ))}
      {canAddStep && (
        <AddStepArea>
          <AddButton onClick={() => handleOpenEditStepModal(lastStepIndex, true)}>ステップを追加</AddButton>
        </AddStepArea>
      )}
    </>
  )
}

const AddStepArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 21px 0 5px;
`
