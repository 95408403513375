import { CalenderState } from '../../../components/common/DayPickerRange'
import { useScopeType } from '../useScopeType'
import { CustomFilterState } from './Filter/types'
import { request } from '../../request'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { useDeviceType } from '../useDeviceType'
import { useFilterContentEventsExists } from '../cookie/useFilterContentEventsExists'
import { useGoalId } from '../useGoalId'
import { downloadBlobFile } from '../../downloadUtils'
import { PageReportSortState } from '../../../components/grid/PageGridItem'
import { NarrowDownState } from '../../../components/filter/PageReportNarrowDown'
import { makePageReportRequestBody } from './usePageReport'

export interface PageReportsDownloadResponse {
  readonly download_url: string
}

interface Props {
  projectId: string
  calenderState: CalenderState
  sortState: PageReportSortState
  searchText: string
  narrowDownState: NarrowDownState[]
  customFilterState: CustomFilterState[]
}

export const usePageReportCsvDownload = ({
  projectId,
  calenderState,
  sortState,
  searchText,
  narrowDownState,
  customFilterState,
}: Props): UseMutationResult<void, unknown, void, unknown> => {
  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { filterContentEventsExists } = useFilterContentEventsExists()
  const { goalId } = useGoalId({ projectId: Number(projectId) })

  return useMutation({
    mutationFn: async () => {
      const requestBody = makePageReportRequestBody(
        scopeType,
        deviceType,
        goalId,
        calenderState,
        undefined,
        undefined,
        sortState,
        searchText,
        narrowDownState,
        customFilterState,
        filterContentEventsExists,
      )
      const res = await request<PageReportsDownloadResponse>(
        'POST',
        `/api/projects/${projectId}/page_reports/download/`,
        true,
        requestBody,
      )
      await downloadBlobFile({
        downloadUrl: res.download_url,
        defaultFilename: 'contentanalytics_pagereport.csv',
      })
    },
  })
}
