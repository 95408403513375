import { useCallback } from 'react'
import { useDispatch } from '../../redux/Store'
import { click, hover, close } from '../../redux/navigationSlice'

export const useNavigation = () => {
  const dispatch = useDispatch()

  const clickNavigation = useCallback(() => {
    dispatch(click())
  }, [dispatch])

  const hoverNavigation = useCallback(() => {
    dispatch(hover())
  }, [dispatch])

  const closeNavigation = useCallback(() => {
    dispatch(close())
  }, [dispatch])

  return {
    clickNavigation,
    hoverNavigation,
    closeNavigation,
  }
}
