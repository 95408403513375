import { UseMutationResult, useMutation } from '@tanstack/react-query'

import { CalenderState } from '../../../components/common/DayPickerRange'
import { TAB_INDEX, TabIndex } from '../../../pages/ContentReport/state'
import { request } from '../../request'
import { downloadBlobFile } from '../../downloadUtils'
import { useFilterContentEventsExists } from '../cookie/useFilterContentEventsExists'
import { getDeviceLayoutNumber, useDeviceType } from '../useDeviceType'
import { useGoalId } from '../useGoalId'
import { ScopeType, useScopeType } from '../useScopeType'
import { CustomFilterState } from './Filter/types'
import { makeRequestJson } from './useContentReport'

const REPORT_TYPES = {
  ALL_CONTENTS: 1,
  CONTENT_GROUP: 2,
} as const

// APIからファイル名が取得できなかった場合は以下のファイル名を使用する
const DEFAULT_FILE_NAME = 'contentanalytics_pagereport.csv'

interface Response {
  readonly download_url: string
}

type Payload = {
  calenderState: CalenderState
  customFilterState: CustomFilterState[]
  tabIndex: TabIndex
}

interface Props {
  projectId: string
  pageReportId: number
}

export const useContentReportCsvDownload = ({
  projectId,
  pageReportId,
}: Props): UseMutationResult<void, unknown, Payload> => {
  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { filterContentEventsExists } = useFilterContentEventsExists()
  const { goalId } = useGoalId({ projectId: Number(projectId) })

  return useMutation({
    mutationFn: async ({ calenderState, customFilterState, tabIndex }: Payload) => {
      const requestBody = makeRequestJsonForDownload(
        getDeviceLayoutNumber(deviceType),
        goalId,
        calenderState,
        scopeType,
        filterContentEventsExists,
        customFilterState,
        tabIndex,
      )
      const res = await request<Response>(
        'POST',
        `/api/projects/${projectId}/page_reports/${pageReportId}/download/`,
        true,
        requestBody,
      )

      await downloadBlobFile({
        downloadUrl: res.download_url,
        defaultFilename: DEFAULT_FILE_NAME,
      })
    },
  })
}

function makeRequestJsonForDownload(
  pageLayout: number,
  goalId: number,
  calenderState: CalenderState,
  scopeType: ScopeType,
  filterContentEventsExists: boolean,
  customFilterState: CustomFilterState[],
  tabIndex: TabIndex,
) {
  // CSVダウンロード時はSortStateは渡さなくてOK。
  // ファイル上でソートできるし、逆に画面表示順に戻すことができないため。
  // see. https://github.com/uncovertruth/ca-project/issues/140#issuecomment-1403042236
  const commonJson = makeRequestJson(
    pageLayout,
    goalId,
    calenderState,
    scopeType,
    filterContentEventsExists,
    customFilterState,
  )
  const json = {
    ...commonJson,
    report_type: tabIndex === TAB_INDEX.CONTENT_GROUP ? REPORT_TYPES.CONTENT_GROUP : REPORT_TYPES.ALL_CONTENTS,
  }

  return JSON.stringify(json)
}
