import React from 'react'
import { Modal, ModalButtonWrapper, ModalHeadText, ModalContentWrapper } from '../../../../common/Modal'
import { NormalButton, CancelButton } from '../../../../common/Button'
import { ErrorBox } from '../../../../common/ErrorBox'

interface Props {
  opened: boolean
  onClose: () => void
  onCheckOverwrite: () => void
  existCsv: boolean
  onChangeCsvFile: (event: React.FormEvent<HTMLInputElement>) => Promise<void>
  isDataImportFieldSuggestionMutateError: boolean
  errorMessage: string
  isLoadingDataImportFieldSuggestion: boolean
}

export function CsvSuggestionFormModal({
  opened,
  onClose,
  onCheckOverwrite,
  existCsv,
  onChangeCsvFile,
  isDataImportFieldSuggestionMutateError,
  errorMessage,
  isLoadingDataImportFieldSuggestion,
}: Props) {
  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      title="インポートフィールドを読み取り"
      isLoading={isLoadingDataImportFieldSuggestion}
    >
      <ModalContentWrapper>
        {isDataImportFieldSuggestionMutateError && <ErrorBox>{errorMessage}</ErrorBox>}
        <ModalHeadText>インポートフィールドをCSVファイルから自動的に読み取ります。</ModalHeadText>
      </ModalContentWrapper>
      <ModalContentWrapper>
        <ModalHeadText>
          <input
            type="file"
            onChange={onChangeCsvFile}
            placeholder="CSVインポート"
            accept="text/csv"
            style={{
              background: '#F5F5F5',
              padding: '20px',
              width: '100%',
            }}
          />
        </ModalHeadText>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton onClick={onCheckOverwrite} disabled={!existCsv}>
          実行
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}
