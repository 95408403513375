import * as React from 'react'
import { Warning } from '@styled-icons/icomoon/Warning'

import { colors } from '../../../styleConstants'
import { BaseIcon } from '../BaseIcon'

interface Props {
  readonly size?: number
  readonly color?: string
}

export function WarningIcon({ size = 40, color = colors.orange }: Props) {
  return <BaseIcon as={Warning} size={size} color={color} />
}
