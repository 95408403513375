import React, { useState } from 'react'
import styled from 'styled-components'
import { DeleteButton } from '../../ImportFieldArea/DeleteButton'
import { DeleteImportedDataModal } from './DeleteImportedDataModal'
import { useLatestImportData } from '../../../../util/hooks/api/DataImport/useLatestImportData'
import { ImportDataTable } from './ImportDataTable'
import { ErrorBox } from '../../../common/ErrorBox'
import { EmbeddedLoading } from '../../../common/EmbeddedLoading'
import { Authority } from '../../../../util/Authority'

interface Props {
  projectId: string
  permissions: any
}

export function RecentImportData({ projectId, permissions }: Props) {
  const { data, isLoading, isError, isSuccess } = useLatestImportData({ projectId })
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <TitleWrapper>
        <Title>直近のインポートデータ</Title>
        {data && Authority.canDeleteImportedData(permissions, projectId) && (
          <DeleteButton
            openDeleteModal={() => {
              setModalOpen(true)
            }}
          />
        )}
      </TitleWrapper>
      {isLoading && <EmbeddedLoading />}
      {isError && <ErrorBox>直近のインポートデータの取得に失敗しました。</ErrorBox>}
      {data && (
        <>
          <ImportDataTable importData={data.importData} />
          <DeleteImportedDataModal
            projectId={projectId}
            importMetaData={data.metaData}
            opened={modalOpen}
            onClose={() => {
              setModalOpen(false)
            }}
          />
        </>
      )}
      {isSuccess && !data && <NoDataText>インポートされたデータはありません。</NoDataText>}
    </>
  )
}

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const NoDataText = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
`
