import { useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '../../../request'
import { DataImportAllKeyFieldsResponse, DataImportAllKeyFieldsData } from '../DataImport/types'
import { DATA_IMPORT_ALL_KEY_FIELDS_QUERY_KEY } from '../constants'

type Props = {
  projectId: string
}

export const useDataImportAllKeyFields = ({ projectId }: Props) => {
  const queryClient = useQueryClient()
  const queryKey = [
    DATA_IMPORT_ALL_KEY_FIELDS_QUERY_KEY,
    {
      projectId,
    },
  ]

  const invalidate = async () => {
    await queryClient.invalidateQueries({ queryKey })
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const result = await request<DataImportAllKeyFieldsResponse>(
        'GET',
        `/api/projects/${projectId}/data_import_all_key_fields/`,
        true,
      )

      const formattedResponse: DataImportAllKeyFieldsData = {
        dataImportKeyFields: result.data_import_key_fields.map((keyField) => {
          return {
            nameLabel: keyField.name_label,
            table: keyField.table,
            customDimensionId: keyField.custom_dimension?.id,
          }
        }),
        disactivatedCustomDimensionExists: result.disactivated_custom_dimension_exists,
        fieldRemaining: result.field_remaining,
      }
      return formattedResponse
    },
  })

  return {
    ...queryResult,
    invalidate,
  }
}
