import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'
import { DeleteDataImportFieldSuggestionPayload } from './types'

type Props = {
  projectId: string
  options?: UseMutationOptions<{}, string, DeleteDataImportFieldSuggestionPayload, void>
}

export const useDeleteDataImportFieldSuggestion = ({ projectId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async ({ csvSuggestionId }: DeleteDataImportFieldSuggestionPayload) => {
      const result = await request<{}>(
        'DELETE',
        `/api/projects/${projectId}/data_import_field_suggestion/${csvSuggestionId}/`,
        true,
      )
      return result
    },
    ...options,
  })
  return mutationResult
}
