import * as React from 'react'
import styled from 'styled-components'
import { Detail } from '@styled-icons/boxicons-regular/Detail'

import { colors } from '../../../styleConstants'
import { Content } from '../../../util/hooks/api/useContentReport'
import { Checkbox } from '../../common/Checkbox'
import { Tooltip } from '../../Tooltip'
import { BaseCell } from './BaseCell'

interface Props {
  readonly item: Content
  readonly onCheckboxClick: React.MouseEventHandler<HTMLDivElement>
  readonly onEdit: (id: number) => void
  readonly canViewPartialContent?: boolean
  readonly checked?: boolean
}

export function ContentPreviewCell({ item, onCheckboxClick, onEdit, canViewPartialContent, checked = false }: Props) {
  return (
    <Cell data-id={item.id} style={{ justifyContent: canViewPartialContent ? 'space-between' : 'center' }}>
      <StyledCheckbox
        checked={checked}
        data-id={item.id}
        data-testid={`content-row-checkbox-${item.id}`}
        onClick={onCheckboxClick}
      />
      {canViewPartialContent && (
        <DetailIcon
          onClick={(e) => {
            e.stopPropagation()
            onEdit(item.id)
          }}
        />
      )}
      <Tooltip
        content={<img src={item.contentScreenshot.imageUrl} style={{ maxWidth: '300px' }} />}
        borderColor={`${colors.black}`}
      >
        <ImageContainer>
          <Img src={item.contentScreenshot.imageUrl} />
        </ImageContainer>
      </Tooltip>
    </Cell>
  )
}

const Cell = styled(BaseCell)`
  justify-content: space-between;
  padding: 0 12px;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`

const Img = styled.img`
  max-width: 25px;
  max-height: 25px;
`

const DetailIcon = styled(Detail).attrs({
  size: '22',
  color: `${colors.blue}`,
})`
  &:hover {
    color: ${colors.contentBlue.blue5};
  }
`

const StyledCheckbox = styled(Checkbox)`
  width: 16px;
  height: 16px;
  background-color: ${colors.white};
`
