import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { ReportArea } from './ReportArea'
import { ReportPageLayout } from '../../components/layout/ReportPageLayout'
import { PAGE_TITLES } from '../../constants'
import { CustomFilterContext } from '../../contexts/CustomFilterContext'
import { CustomDimensionValueReportContext } from '../../contexts/CustomDimentionValueReportContext'

export const CUSTOMDIMENSION_REPORT_MAX_WIDTH = 1160

export function CustomDimensionReportPage(_: RouteComponentProps) {
  const { addonUrlParams, setAddonUrlParams } = React.useContext(CustomFilterContext)
  const {
    actions: { resetPagerIndex: resetCustomDimensionValueReportPagerIndex },
  } = React.useContext(CustomDimensionValueReportContext)

  /**
   * カスタムディメンション経由で追加したカスタムフィルターのキャッシュを削除する
   * ページレポートから下層ページに戻す時にフィルラーを元に戻すためにキャッシュしていた。
   * 上層->下層に移動する際は戻す必要がなくなるので削除
   */
  React.useEffect(() => {
    if (addonUrlParams) setAddonUrlParams(undefined)
  }, [addonUrlParams])

  /**
   * 下層ページのぺージャーをリセットする
   * 下層>ページレポート>下層に戻った時にページャーを保持するため下層ページではリセットしていないが
   * 上層->下層に移動する際は常にリセットしたいのでこの上層でリセット
   */
  React.useEffect(() => {
    resetCustomDimensionValueReportPagerIndex()
  }, [])

  const mainAreaRef = React.useRef<HTMLDivElement>(null)

  return (
    <ReportPageLayout
      pageTitle={PAGE_TITLES.CUSTOM_DIMENSION_REPORT}
      mainAreaRef={mainAreaRef}
      reportAreaMaxWidth={`${CUSTOMDIMENSION_REPORT_MAX_WIDTH}px`}
    >
      <ReportArea mainAreaRef={mainAreaRef} />
    </ReportPageLayout>
  )
}
