import { parseDate } from '../../../Date'
import { TeamData } from '../../../Response'
import { Team } from './types'

export const transformTeam = (response: TeamData): Team => {
  return {
    name: response.name,
    createdAt: parseDate(response.created_at),
    plan: {
      name: response.plan.display_name,
      reportDaysLimit: response.plan.report_days_limit,
      displayMonthlyCrawlLimit: response.plan.display_monthly_crawl_limit,
      canTrackContentEvent: response.plan.can_track_content_event,
    },
  }
}
