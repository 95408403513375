import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { Tooltip } from '../../../components/Tooltip'
import { Cog } from '@styled-icons/boxicons-solid/Cog'
import Popup from 'reactjs-popup'
import { MessageRoundedError } from '@styled-icons/boxicons-solid/MessageRoundedError'
import { getDateYYYYMMDDhhmi } from '../../../util/Date'
import { ErrorBox } from '../../../components/common/ErrorBox'
import { useTargetContentsData } from '../../../util/hooks/api/useTargetContentsData'
import { AddButton } from '../../../components/common/Button'
import { EmbeddedLoading } from '../../../components/common/EmbeddedLoading'
import { HelpLink } from '../../../components/common/HelpLink'
import { HELP_LINKS } from '../../../constants'
import { ModalKey, PartialResultType } from '../../PageCapture'
import { Authority } from '../../../util/Authority'
import { GlobalContext } from '../../../GlobalState'

export const TargetContentsTab = ({
  openModal,
  projectId,
  urlId,
}: {
  openModal: (key: ModalKey, targetContent: PartialResultType | null) => void
  projectId: string
  urlId: string
  isProcessing: boolean
}) => {
  const ref = React.useRef<any>()
  const closeTooltip = () => ref.current?.close()

  const { data, isError } = useTargetContentsData({
    projectId: projectId || '',
    urlId: urlId || '',
  })
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)

  const canAdd = React.useMemo(() => Authority.canAddTargetContent(AccountInfo.permissions, projectId), [projectId])
  const canChange = React.useMemo(
    () => Authority.canChangeTargetContent(AccountInfo.permissions, projectId),
    [projectId],
  )
  const canDelete = React.useMemo(
    () => Authority.canDeleteTargetContent(AccountInfo.permissions, projectId),
    [projectId],
  )

  return (
    <>
      {isError && <ErrorBox>ターゲットコンテンツ情報の取得に失敗しました。</ErrorBox>}
      <p style={{ color: '#666', margin: '0 0 16px 0', fontSize: '16px' }}>
        CSSセレクターを指定することで、任意のコンテンツを取得することが可能です。
        <br />
        コンテンツ追加後は、「キャプチャ履歴詳細」から「ページのキャプチャリクエスト」を行ってください。
      </p>
      <div style={{ marginBottom: '24px', display: 'flex', alignItems: 'center' }}>
        <HelpLink title={'ターゲットコンテンツ機能とは'} link={HELP_LINKS.TARGET_CONTENT_HOW_TO} />
      </div>
      {canAdd && <AddButton onClick={() => openModal('add', null)}>コンテンツを追加</AddButton>}

      <ItemsWrapper>
        <ItemsHead>
          <HeadItems>
            <HeadItem style={{ width: '168px' }}>CSSセレクター名</HeadItem>
            <HeadItem style={{ minWidth: '226px', flex: '1' }}>CSSセレクター</HeadItem>
            <HeadItem style={{ width: '154px' }}>追加日時</HeadItem>
            <HeadItem style={{ width: '154px' }}>更新日時</HeadItem>
            <HeadItem style={{ width: '64px' }}>デバイス</HeadItem>
            <HeadItem style={{ width: '76px' }}>プレビュー</HeadItem>
            <HeadItem style={{ width: '76px' }}>ステータス</HeadItem>
            <HeadItem style={{ width: '152px' }}>最終取得完了日時</HeadItem>
            {(canChange || canDelete) && <HeadItem style={{ width: '56px' }}></HeadItem>}
          </HeadItems>
        </ItemsHead>
        {isError ? null : data ? (
          data.results.length ? (
            <ItemsBody>
              {data.results.map((targetContent, i) => {
                const { content_name, css_selector, created_at, updated_at, target_content_results } = targetContent
                return (
                  <BodyItems key={`${content_name}${i}`}>
                    <BodyItem style={{ width: '168px' }}>{content_name}</BodyItem>
                    <BodyItem style={{ minWidth: '226px', flex: '1' }}>{css_selector}</BodyItem>
                    <BodyItem style={{ width: '154px' }}>{getDateYYYYMMDDhhmi(created_at)}</BodyItem>
                    <BodyItem style={{ width: '154px' }}>{getDateYYYYMMDDhhmi(updated_at)}</BodyItem>
                    <BodyItem style={{ padding: 0, width: '64px' }}>
                      <BodyItemChildItems>
                        <BodyItemChildItem style={{ width: '64px' }}>
                          {target_content_results[0].page_layout_name}
                        </BodyItemChildItem>
                        <BodyItemChildItem style={{ width: '64px' }}>
                          {target_content_results[1].page_layout_name}
                        </BodyItemChildItem>
                      </BodyItemChildItems>
                    </BodyItem>
                    <BodyItem style={{ padding: 0, width: '76px' }}>
                      <BodyItemChildItems>
                        <PreviewCell imageUrl={target_content_results[0]?.content_screenshot?.image_url} />
                        <PreviewCell imageUrl={target_content_results[1]?.content_screenshot?.image_url} />
                      </BodyItemChildItems>
                    </BodyItem>
                    <BodyItem style={{ padding: 0, width: '76px' }}>
                      <BodyItemChildItems>
                        <StatusCell
                          statusName={target_content_results[0].status_name}
                          errorTypeName={target_content_results[0].error_type_name}
                        />
                        <StatusCell
                          statusName={target_content_results[1].status_name}
                          errorTypeName={target_content_results[1].error_type_name}
                        />
                      </BodyItemChildItems>
                    </BodyItem>
                    <BodyItem style={{ padding: 0, width: '152px' }}>
                      <BodyItemChildItems>
                        <BodyItemChildItem style={{ width: '152px' }}>
                          {target_content_results[0].updated_at
                            ? getDateYYYYMMDDhhmi(target_content_results[0].updated_at)
                            : '-'}
                        </BodyItemChildItem>
                        <BodyItemChildItem style={{ width: '152px' }}>
                          {target_content_results[1].updated_at
                            ? getDateYYYYMMDDhhmi(target_content_results[1].updated_at)
                            : '-'}
                        </BodyItemChildItem>
                      </BodyItemChildItems>
                    </BodyItem>
                    {(canChange || canDelete) && (
                      <BodyItem style={{ width: '56px', justifyContent: 'center', padding: 0 }}>
                        <Popup
                          key={`${content_name}${i}`}
                          ref={ref}
                          trigger={<StyledCog />}
                          position={'bottom right'}
                          contentStyle={{ width: '100px' }}
                          on={['click']}
                          arrow={false}
                          closeOnDocumentClick
                        >
                          <PopupList>
                            {canChange && (
                              <PopupListItem>
                                <PopupLink
                                  onClick={() => {
                                    closeTooltip()
                                    openModal('edit', targetContent)
                                  }}
                                >
                                  編集
                                </PopupLink>
                              </PopupListItem>
                            )}
                            {canDelete && (
                              <PopupListItem>
                                <PopupLink
                                  onClick={() => {
                                    closeTooltip()
                                    openModal('delete', targetContent)
                                  }}
                                >
                                  削除
                                </PopupLink>
                              </PopupListItem>
                            )}
                          </PopupList>
                        </Popup>
                      </BodyItem>
                    )}
                  </BodyItems>
                )
              })}
            </ItemsBody>
          ) : (
            <ItemsBody>
              <BodyItems>
                <BodyItem style={{ width: '100%' }}>ターゲットコンテンツがありません。</BodyItem>
              </BodyItems>
            </ItemsBody>
          )
        ) : (
          <ItemsBody>
            <BodyItems>
              <BodyItem style={{ width: '100%' }}>
                <EmbeddedLoading />
              </BodyItem>
            </BodyItems>
          </ItemsBody>
        )}
      </ItemsWrapper>
    </>
  )
}

const PreviewCell = ({ imageUrl }: { imageUrl?: string }) => {
  return (
    <BodyItemChildItem style={{ width: '76px' }}>
      {imageUrl ? (
        <Tooltip
          content={<img src={imageUrl || ''} style={{ maxWidth: '300px', maxHeight: '300px' }} />}
          borderColor={`${colors.black}`}
        >
          <TooltipImageContainer>
            <img style={{ maxWidth: '25px', maxHeight: '25px' }} src={imageUrl || ''} />
          </TooltipImageContainer>
        </Tooltip>
      ) : (
        '-'
      )}
    </BodyItemChildItem>
  )
}

const StatusCell = ({ statusName, errorTypeName }: { statusName: string; errorTypeName?: string }) => {
  return (
    <BodyItemChildItem style={{ width: '76px' }}>
      {statusName === '成功' ? (
        '成功'
      ) : statusName === '取得待ち' ? (
        '取得待ち'
      ) : (
        <>
          <p style={{ margin: '0', display: 'inline-block' }}>エラー</p>
          <Popup
            key={'tp-error'}
            trigger={<MessageRoundedError size={20} color={colors.error} />}
            position={'bottom center'}
            contentStyle={{ width: '168px', padding: 0 }}
            on={['hover']}
            closeOnDocumentClick
            arrow={false}
          >
            <div
              style={{
                backgroundColor: '#142830',
                color: colors.white,
                padding: '8px',
                fontSize: '10px',
              }}
            >
              <p style={{ margin: 0 }}>{errorTypeName || ''}</p>
            </div>
          </Popup>
        </>
      )}
    </BodyItemChildItem>
  )
}

const ItemsWrapper = styled.div`
  width: fit-content;
  margin-top: 30px;
`

const ItemsHead = styled.div`
  background-color: ${colors.lightCyan};
`

const ItemsBody = styled.div`
  background-color: ${colors.white};
`

const HeadItems = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  font-size: 12px;
`

const HeadItem = styled.li`
  list-style: none;
  font-weight: bold;
  padding: 8px 0 8px 8px;
  border: 1px solid ${colors.gray300};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  word-break: break-all;

  & ~ & {
    border-left: none;
    border-bottom: none;
  }
`

const BodyItems = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  font-size: 14px;
  & ~ & {
    border-top: none;
  }
`

const BodyItem = styled.li`
  list-style: none;
  padding: 8px;
  border: 1px solid ${colors.gray300};
  min-height: 74px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  word-break: break-all;
  white-space: wrap;
  border-top: none;
  & ~ & {
    border-left: none;
  }
`

const BodyItemChildItems = styled.ul`
  padding: 0;
  margin: 0;
  border: none;
  height: 100%;
`

const BodyItemChildItem = styled.li`
  list-style: none;
  min-height: 37px;
  height: calc(100% / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  word-break: break-all;

  & ~ & {
    border-top: 1px solid ${colors.gray300};
  }
`
const TooltipImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 25px;
  height: 25px;
`
const PopupList = styled.ul`
  background-color: ${colors.white};
  list-style: none;
  padding: 0;
  margin: 0;
`
const PopupListItem = styled.li`
  &:hover {
    background-color: #ebe8e8;
  }
`

const PopupLink = styled.a`
  color: ${colors.link.base};
  padding: 4px 16px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
`
const StyledCog = styled(Cog)`
  width: 20px;
  height: 20px;
  color: ${colors.gray600};
  cursor: pointer;
`
