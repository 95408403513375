import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { GlobalContextType, useContextState } from './state'
import { CustomFilterUrlParams } from '../../util/hooks/api/Filter/types'
import { useReportFilterSelects } from '../../util/hooks/api/Filter/useReportFilterSelects'
import { ProjectContext } from '../../pages/ProjectRoot'
import { UrlParams } from '../../util/UrlParams'

import { ADDON_FILTERS_PARAM_NAME } from '../../util/hooks/api/Filter/constants'

export const CustomFilterContext = React.createContext<GlobalContextType>(null as any)

interface Props extends RouteComponentProps {
  readonly children?: React.ReactNode | React.ReactNode[]
}

/**
 * レポートのカスタムフィルターで共通使用する state, actions をグローバルで使用できるよう Provider で定義する
 * actionsのコードがボリュームあるため、ReportContextとは別で管理する。
 *
 * @param {Object} props - The props object containing the children component.
 * @param {React.ReactNode} props.children - The child component(s) to be wrapped.
 */
export function CustomFilterProvider(props: Props) {
  const { state, actions, addonUrlParams, setAddonUrlParams } = useContextState()

  const {
    state: { projectId },
  } = React.useContext(ProjectContext)

  const { data, isLoading } = useReportFilterSelects({ projectId: projectId.toString() })

  /**
   * 初期化処理
   * URLパラメータ関連の処理で必要な情報をセットする
   */
  React.useEffect(() => {
    if (isLoading) return

    if (data) actions.setReportFilterSelects(data)
  }, [data])

  /**
   * URLパラメータからFilterStateに情報を追加する
   */
  const url = new URL(location.href)
  const filters = url.searchParams.get(ADDON_FILTERS_PARAM_NAME)
  if (state.selectsData && filters) {
    const newAddonUrlParams = JSON.parse(filters) as CustomFilterUrlParams[]
    const dimensionFilters = actions.convertUrlParamsToCustomDimensionFilterState(newAddonUrlParams)

    actions.addCustomFilterState(dimensionFilters)
    setAddonUrlParams(newAddonUrlParams)

    // パラメータは削除する
    const paramDeletedUrl = UrlParams.deleteParams(url, ADDON_FILTERS_PARAM_NAME)
    history.replaceState({}, '', paramDeletedUrl)
  }

  if (!state.selectsData) return null

  return (
    <CustomFilterContext.Provider value={{ state, actions, addonUrlParams, setAddonUrlParams }}>
      {props.children}
    </CustomFilterContext.Provider>
  )
}
