import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'

// サイトレスポンシブ処理ステップ
export const SiteResponsiveStep = {
  none: 0,
  saveExec: 1, // 保存実行
  saveComplete: 2, // 保存完了
}

export class SiteResponsive {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // 保存可能かチェック
  checkDisabled = (isUserAgent: boolean, mobileMaxWidth: number, pcMinWidth: number) => {
    // UserAgent使用時は特にチェックなし
    if (isUserAgent) {
      return false
    }
    // 正常な数値が設定されているか
    if (0 < mobileMaxWidth && mobileMaxWidth < pcMinWidth) {
      return false
    }
    return true
  }

  // リセット可能かチェック
  checkReset = (isUserAgent: boolean, mobileMaxWidth: number | string, pcMintWidth: number | string) => {
    if (this.state.siteResponsive.baseUserAgent !== isUserAgent) {
      return true
    }
    if (!isUserAgent && this.state.siteResponsive.baseMobileMaxWidth !== mobileMaxWidth) {
      return true
    }
    if (!isUserAgent && this.state.siteResponsive.basePcMinWidth !== pcMintWidth) {
      return true
    }
    return false
  }

  // ラジオボタンでの種類選択
  onClick = () => {
    const disabled = this.checkDisabled(
      !this.state.siteResponsive.isUserAgent,
      Number(this.state.siteResponsive.mobileMaxWidth),
      Number(this.state.siteResponsive.pcMinWidth),
    )
    const isReset = this.checkReset(
      !this.state.siteResponsive.isUserAgent,
      this.state.siteResponsive.mobileMaxWidth,
      this.state.siteResponsive.pcMinWidth,
    )
    this.setState({
      ...this.state,
      siteResponsive: {
        ...this.state.siteResponsive,
        isUserAgent: !this.state.siteResponsive.isUserAgent,
        disabled: !(!disabled && isReset),
        isReset: isReset,
      },
    })
  }

  // タブレットの幅を計算する
  getTabletWidth(mobileMaxWidth: number | string | null, pcMinWidth: number | string | null) {
    // データなしの状態
    if (mobileMaxWidth === null || pcMinWidth === null) {
      return { minWidth: '', maxWidth: '' }
    }

    const mobileMax = Number(mobileMaxWidth)
    const pcMin = Number(pcMinWidth)
    if (mobileMax === 0 && pcMin === 0) {
      return { minWidth: '', maxWidth: '' }
    }
    return { minWidth: mobileMax + 1, maxWidth: pcMin - 1 }
  }

  // Mobile幅設定
  onChangeMobileWidth = (event: React.FormEvent<HTMLInputElement>) => {
    const num = Number(event.currentTarget.value)
    const { minWidth, maxWidth } = this.getTabletWidth(num, this.state.siteResponsive.pcMinWidth)
    const disabled = this.checkDisabled(
      this.state.siteResponsive.isUserAgent,
      num,
      Number(this.state.siteResponsive.pcMinWidth),
    )
    const isReset = this.checkReset(this.state.siteResponsive.isUserAgent, num, this.state.siteResponsive.pcMinWidth)
    this.setState({
      ...this.state,
      siteResponsive: {
        ...this.state.siteResponsive,
        mobileMaxWidth: num === 0 ? '' : num,
        tabletMinWidth: minWidth,
        tabletMaxWidth: maxWidth,
        disabled: !(!disabled && isReset),
        isReset: isReset,
      },
    })
  }

  // PC幅設定
  onChangePcWidth = (event: React.FormEvent<HTMLInputElement>) => {
    const num = Number(event.currentTarget.value)
    const { minWidth, maxWidth } = this.getTabletWidth(this.state.siteResponsive.mobileMaxWidth, num)
    const disabled = this.checkDisabled(
      this.state.siteResponsive.isUserAgent,
      Number(this.state.siteResponsive.mobileMaxWidth),
      num,
    )
    const isReset = this.checkReset(
      this.state.siteResponsive.isUserAgent,
      this.state.siteResponsive.mobileMaxWidth,
      num,
    )
    this.setState({
      ...this.state,
      siteResponsive: {
        ...this.state.siteResponsive,
        pcMinWidth: num === 0 ? '' : num,
        tabletMinWidth: minWidth,
        tabletMaxWidth: maxWidth,
        disabled: !(!disabled && isReset),
        isReset: isReset,
      },
    })
  }

  // 変更を破棄
  onReset = () => {
    this.setState({
      ...this.state,
      siteResponsive: {
        ...this.state.siteResponsive,
        isUserAgent: this.state.siteResponsive.baseUserAgent,
        mobileMaxWidth: this.state.siteResponsive.baseMobileMaxWidth,
        pcMinWidth: this.state.siteResponsive.basePcMinWidth,
        disabled: true,
        isReset: false,
      },
    })
  }

  // 変更を保存
  onSave = async () => {
    try {
      if (this.state.siteResponsive.isUserAgent) {
        await request('DELETE', `/api/projects/${this.state.project.id}/site_responsive/`, true)
      } else {
        await request(
          'PUT',
          `/api/projects/${this.state.project.id}/site_responsive/`,
          true,
          JSON.stringify({
            mobile_max_width: this.state.siteResponsive.mobileMaxWidth,
            pc_min_width: this.state.siteResponsive.pcMinWidth,
          }),
        )
      }
      this.setState({
        ...this.state,
        reload: true,
        siteResponsive: {
          ...this.state.siteResponsive,
          step: SiteResponsiveStep.none,
        },
        toastMessage: 'デバイス振り分け設定を変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        siteResponsiveErrorMessage: typeof e === 'string' ? e : '設定の変更に失敗しました。',
      })
    }
  }
}
