import * as React from 'react'
import styled from 'styled-components'
import { GRID_BORDER_COLOR, colors } from '../../../styleConstants'
import { toLocaleStringWithCeil } from '../../../util/toLocaleStringWithCeil'
import { gridLayout, gridLayoutRowGroups } from '../../grid/PageGridItem'

interface Props {
  readonly allViewCount: number
  readonly allLandingCount: number
  readonly allBounceRate: number
  readonly allExitRate: number
  readonly allConversions: number
  readonly allConversionsRate: number
}
export function SummaryRow({
  allViewCount,
  allLandingCount,
  allBounceRate,
  allExitRate,
  allConversions,
  allConversionsRate,
}: Props) {
  return (
    <Rows>
      <RowUrl>
        <RowUrlInner>
          <Main>全体</Main>
        </RowUrlInner>
      </RowUrl>

      <RowNarrow width={gridLayout.row.viewCount}>
        <Main>{allViewCount && toLocaleStringWithCeil(allViewCount)}</Main>
        <Sub>(100.0%)</Sub>
      </RowNarrow>

      <RowNarrow width={gridLayout.row.pageLandingCount}>
        <Main>{toLocaleStringWithCeil(allLandingCount)}</Main>
        <Sub>(100.0%)</Sub>
      </RowNarrow>

      <RowNarrow width={gridLayout.row.pageBounceRate}>
        <Main>{allBounceRate.toFixed(1)}%</Main>
      </RowNarrow>

      <RowNarrow width={gridLayout.row.pageExitRate}>
        <Main>{allExitRate.toFixed(1)}%</Main>
      </RowNarrow>

      <RowNarrow width={gridLayout.row.pageConversions}>
        <Main>{toLocaleStringWithCeil(allConversions)}</Main>
        <Sub>(100.0%)</Sub>
      </RowNarrow>

      <RowNarrow width={gridLayout.row.pageConversionsRate}>
        <Main>{allConversionsRate.toFixed(2)}%</Main>
      </RowNarrow>
    </Rows>
  )
}

const Main = styled.div``
const Sub = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: ${colors.gray750};
`

const RowUrlInner = styled.div`
  margin-left: ${gridLayout.row.index};
`

const Rows = styled.div`
  display: flex;
  background-color: ${colors.gray100};
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${gridLayout.row.url};
  height: 100%;
  min-height: ${gridLayout.height};
  font-size: 14px;
  font-weight: bold;
  border-left: 1px solid ${GRID_BORDER_COLOR};
  border-bottom: 1px solid ${GRID_BORDER_COLOR};
  box-sizing: border-box;
  &:last-child {
    border-right: 1px solid ${GRID_BORDER_COLOR};
  }
`

const RowUrl = styled(Row)`
  justify-content: space-between;
  width: 100%;
  min-width: ${gridLayoutRowGroups.url};
  background-color: inherit;
`

const RowNarrow = styled(Row)<{ width: string }>`
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  white-space: nowrap;
  padding: 0 14px;
  width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  background-color: inherit;
`
