import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { SubActionIconButton } from './Button'
import cc from 'classcat'
import { Clipboard } from '@styled-icons/boxicons-regular/Clipboard'

export interface Props {
  readonly copyButton?: boolean
  readonly noneResize?: boolean
}

export function Textarea({ copyButton, noneResize, children, ...rest }: Props & React.TextareaHTMLAttributes<any>) {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)
  const [copyState, setCopyState] = React.useState('Copy')
  const copyToClipboard = () => {
    if (copyState === 'Copied' || !textareaRef || !textareaRef.current) {
      return
    }
    textareaRef.current.select()
    document.execCommand('copy')
    setCopyState('Copied')
    const intervalId = setInterval(() => {
      setCopyState('Copy')
      clearInterval(intervalId)
    }, 2000)
  }

  return (
    <Container>
      <DesignedTextarea className={cc({ noneResize })} ref={textareaRef} {...rest}>
        {children}
      </DesignedTextarea>
      {copyButton && document.queryCommandSupported('copy') && (
        <div style={{ marginTop: '1rem' }}>
          <SubActionIconButton onClick={copyToClipboard} iconLeft={<Clipboard size="16" />}>
            {copyState === 'Copy' ? 'トラッキングコードをコピー' : 'コピーしました'}
          </SubActionIconButton>
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
`

const DesignedTextarea = styled.textarea`
  border: 1px solid ${colors.gray400};

  &.noneResize {
    resize: none;
  }
`
