import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../styleConstants'
import { GlobalContext } from '../../GlobalState'
import { Modal, ModalContentWrapper } from '../common/Modal'
import { AddButton, CancelButton } from '../common/Button'
import { usePageState } from './state/ProjectListModalState'

interface Props {
  readonly opened: boolean
  readonly userId: number
  readonly projectId: number
  readonly canAddProject?: boolean // プロジェクト追加できる権限
  readonly canViewAddProject?: boolean // プロジェクト追加ボタンを表示できる権限
  readonly adminLayout?: boolean
  readonly newProject: () => void
  readonly onCancel: () => void
}

export function ProjectListModal(props: Props) {
  const { actions: globalActions } = React.useContext(GlobalContext)
  const {
    opened,
    userId,
    projectId,
    canAddProject,
    canViewAddProject,
    adminLayout = false,
    newProject,
    onCancel,
  } = props
  const {
    state: { projects, loading, errorMessage },
    actions,
  } = usePageState()
  if (loading) {
    actions.fetchProject(userId)
  }
  return (
    <Modal isOpen={opened} onClose={onCancel} title="プロジェクトの切り替え" width={600}>
      <ModalContentWrapper>
        <ScrollArea>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          {!loading &&
            projects.map((project) => {
              const selected = project.id === projectId
              // 組織管理画面用レイアウト
              if (adminLayout) {
                return (
                  <Item
                    data-id={project.id}
                    key={project.id}
                    onClick={() => {
                      globalActions.clearState()
                      actions.onClick(project.id)
                    }}
                  >
                    <Link
                      href="#"
                      onClick={() => {
                        globalActions.clearState()
                        actions.onClick(project.id)
                      }}
                    >
                      {project.name}
                    </Link>
                    <div style={{ fontSize: '0.9rem', marginTop: '0.2rem' }}>{project.url}</div>
                    <div
                      style={{
                        fontSize: '0.9rem',
                        marginTop: '0.2rem',
                        color: `${colors.gray500}`,
                      }}
                    >{`プロジェクトID : ${project.id}`}</div>
                  </Item>
                )
              }
              return (
                <Item
                  className={cc({ selected })}
                  data-id={project.id}
                  key={project.id}
                  onClick={
                    !selected
                      ? () => {
                          globalActions.clearState()
                          actions.onClick(project.id)
                        }
                      : () => {}
                  }
                >
                  {selected ? (
                    <div style={{ fontSize: '1.2rem' }}>{project.name}</div>
                  ) : (
                    <Link
                      href="#"
                      onClick={() => {
                        globalActions.clearState()
                        actions.onClick(project.id)
                      }}
                    >
                      {project.name}
                    </Link>
                  )}
                  <div style={{ fontSize: '0.9rem', marginTop: '0.2rem' }}>{project.url}</div>
                  <div
                    style={{
                      fontSize: '0.9rem',
                      marginTop: '0.2rem',
                      color: `${colors.gray500}`,
                    }}
                  >{`プロジェクトID : ${project.id}`}</div>
                </Item>
              )
            })}
        </ScrollArea>
      </ModalContentWrapper>
      <Buttons canViewAddProject={!!canViewAddProject}>
        {/* 権限による表示制限あり */}
        {canViewAddProject && (
          <AddButton
            // プランによる制限あり
            disabled={!canAddProject}
            onClick={canAddProject ? newProject : () => alert('現在のプランではご利用いただけません')}
          >
            新規プロジェクト追加
          </AddButton>
        )}
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
      </Buttons>
    </Modal>
  )
}

const ScrollArea = styled.div`
  max-height: 200px;
  overflow-y: auto;
`

const Item = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  border: 1px solid ${colors.gray100};
  background-color: ${colors.white};

  &:hover {
    background-color: ${colors.gray50};
    border: 1px solid ${colors.gray300};
  }

  &.selected {
    cursor: default;
    background-color: ${colors.white};
    border: 1px solid ${colors.orange};
  }

  & + & {
    margin-top: 0.5rem;
  }
`

const Link = styled.a`
  font-size: 1.2rem;
  text-decoration: none;
  color: ${colors.link.base};

  &:visited {
    color: ${colors.link.visited};
  }
`

const Buttons = styled.div<{ canViewAddProject: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${({ canViewAddProject }) => (canViewAddProject ? 'space-between' : 'flex-end')};
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`
