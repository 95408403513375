import * as React from 'react'
import { navigate } from '@gatsbyjs/reach-router'
import styled from 'styled-components'

import { GlobalContext } from '../../../../GlobalState'
import { ProjectContext } from '../../../ProjectRoot'
import { FunnelReportContext } from '../../../../contexts/FunnelReportContext'
import { useFunnelReports } from '../../../../util/hooks/api/FunnelReport/useFunnelReports'
import { Authority } from '../../../../util/Authority'
import { AddButton } from '../../../../components/common/Button'
import { SearchText } from '../../../../components/textbox/SearchText'
import { colors, FONT_COLOR_SUB } from '../../../../styleConstants'
import { HelpLink } from '../../../../components/common/HelpLink'
import { HELP_LINKS } from '../../../../constants'

/**
 * ファネルレポート一覧のレポート追加・レポート検索などのレポートを操作するコンポーネントをレンダリングする
 */
export function ReportsControlArea() {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)

  const {
    state: { projectId },
  } = React.useContext(ProjectContext)

  const { searchText, setSearchText } = React.useContext(FunnelReportContext)

  const { data } = useFunnelReports({ projectId })
  if (!data) return null

  const canAdd = Authority.canAddFunnelReport(AccountInfo.permissions, projectId)
  const handleAddReport = () => navigate(`/projects/${projectId}/report/funnel/new`)

  const handleSearch = (value: string) => setSearchText(value)
  const handleSearchClear = () => setSearchText('')

  return (
    <Container>
      <LeftArea>
        {canAdd && (
          <div>
            <AddButton onClick={handleAddReport} disabled={data.reportLimitReached}>
              ファネルレポートを追加
            </AddButton>
          </div>
        )}
        {!data.hasReports && !searchText && <InformationText>ファネルレポートが登録されていません。</InformationText>}
        {(data.hasReports || searchText) && (
          <SearchTextWrapper>
            <SearchText value={searchText} onClear={handleSearchClear} onSearch={handleSearch} />
          </SearchTextWrapper>
        )}
      </LeftArea>
      <RightArea>
        <HelpBox>
          <HelpLink title="ファネルレポートとは" link={`${HELP_LINKS.FUNNEL_REPORT}`} />
        </HelpBox>
        <TeamReportCountBox>
          <TeamReportCountLabel>ファネルレポート作成数（組織合計）：</TeamReportCountLabel>
          <div>
            <TeamReportCount exceeded={data.reportLimitExceeded} reached={data.reportLimitReached}>
              {data.teamReportsCount}
            </TeamReportCount>
            <TeamReportLimit>/{data.funnelReportsLimit}</TeamReportLimit>
          </div>
        </TeamReportCountBox>
      </RightArea>
    </Container>
  )
}

const TeamReportCountBox = styled.div`
  height: 30px;
  display: inline-flex;
  align-items: center;
  background-color: ${colors.white};
  padding: 0 12px;
  justify-content: space-between;
`

const TeamReportCountLabel = styled.span`
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
`

const TeamReportCount = styled.span<{ exceeded?: boolean; reached?: boolean }>`
  font-size: 16px;
  line-height: 24px;
  ${({ reached }) => reached && `font-weight: bold;`}
  ${({ exceeded }) => exceeded && `color: red;`}
`

const TeamReportLimit = styled(TeamReportCount)`
  color: ${FONT_COLOR_SUB};
`

const InformationText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${FONT_COLOR_SUB};
`

const SearchTextWrapper = styled.div`
  width: 416px;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 110px;
  margin-bottom: 16px;
`

const LeftArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end; // 追加ボタン非表示時に検索テキストが垂直方向で下揃えになるよう調整
  gap: 16px;
`

const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`

const HelpBox = styled.div`
  margin-bottom: 16px;
`
