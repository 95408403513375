import { SCOPE_TYPE, ScopeType } from './hooks/useScopeType'

const SCOPE_TYPE_API_VALUE = {
  SESSION: 1,
  USER: 2,
} as const
export type ScopeTypeApiValue = typeof SCOPE_TYPE_API_VALUE[keyof typeof SCOPE_TYPE_API_VALUE]

export const getScopeTypeApiValue: (scopeType: ScopeType) => ScopeTypeApiValue = (scopeType) => {
  if (scopeType === SCOPE_TYPE.SESSION) {
    return SCOPE_TYPE_API_VALUE.SESSION
  }
  if (scopeType === SCOPE_TYPE.USER) {
    return SCOPE_TYPE_API_VALUE.USER
  }
  return SCOPE_TYPE_API_VALUE.SESSION
}
