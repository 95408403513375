import * as React from 'react'
import styled from 'styled-components'
import { MessageRoundedError } from '@styled-icons/boxicons-solid/MessageRoundedError'
import { colors } from '../../../styleConstants'
import Popup from 'reactjs-popup'
import { PageCaptureInfoData } from '../../../util/Response'
import { EmbeddedLoading } from '../../../components/common/EmbeddedLoading'

interface PageCaptureInfoTabType {
  data?: PageCaptureInfoData
}

export const PageCaptureInfoTab = ({ data }: PageCaptureInfoTabType) => {
  if (!data) return <EmbeddedLoading />
  return (
    <>
      <InfoWrap>
        <InfoTitle>{'ページURL'}</InfoTitle>
        <InfoConetnt>{data.url || '-'}</InfoConetnt>
      </InfoWrap>
      <InfoWrap>
        <InfoTitle>{'ページタイトル'}</InfoTitle>
        <InfoConetnt>{data.title || '-'}</InfoConetnt>
      </InfoWrap>
      <InfoWrap>
        <InfoTitle>{'取得可否'}</InfoTitle>
        <div style={{ display: 'flex', alignItems: 'cebter' }}>
          <InfoConetnt style={{ display: 'inline-block', marginRight: '4px' }}>
            {data.page_capture.event_agg_type_name || '-'}
          </InfoConetnt>
          {data.page_capture.event_agg_type_name === '取得しない' && (
            <Popup
              key={'tp-error'}
              trigger={<MessageRoundedError size={20} color={colors.error} />}
              position={'bottom center'}
              contentStyle={{ width: '168px', padding: 0 }}
              on={['hover']}
              closeOnDocumentClick
              arrow={false}
            >
              <div
                style={{
                  backgroundColor: '#142830',
                  color: colors.white,
                  padding: '8px',
                  fontSize: '10px',
                }}
              >
                <p style={{ margin: 0 }}>{'現在の設定だとコンテンツデータは計測されません'}</p>
              </div>
            </Popup>
          )}
        </div>
      </InfoWrap>
      <InfoWrap>
        <InfoTitle>{'頻度'}</InfoTitle>
        <InfoConetnt>{data.page_capture.interval_type_name || '-'}</InfoConetnt>
      </InfoWrap>
    </>
  )
}

const InfoWrap = styled.div`
  margin-bottom: 24px;
`
const InfoTitle = styled.p`
  font-weight: bold;
  margin: 0 0 8px 0;
`
const InfoConetnt = styled.p`
  font-size: 16px;
  margin: 0;
`
