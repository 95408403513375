import * as React from 'react'
import { addMonths } from 'date-fns'
import { useCookies } from 'react-cookie'
import { layout } from '../../styleConstants'
import { request } from '../../util/request'
import { getDateStringYM, getDateYYYYMMDDhhmi } from '../../util/Date'
import { sleep } from '../../util/Timer'
import { Role } from '../../util/Authority'
import { convertRemToPx } from '../../util/Parse'
import {
  UserData,
  UsersData,
  TeamData,
  InviteData,
  RestrictedIpsData,
  AuditLogData,
  CaptureLimitsData,
  GcpSasData,
  GcpSasKeysData,
  GcpSasGcsExportsData,
} from '../../util/Response'
import { OptionProps } from '../../components/common/Select'
import { Info } from './info'
import { Project, ProjectStep } from './project'
import { CaptureLimit, CaptureLimitStep, CalcExcessRate } from './captureLimit'
import { Member, MemberStep } from './member'
import { Invite, InviteStep } from './invite'
import { Inactive, InactiveStep } from './inactive'
import { IpLimit, IpLimitStep } from './ipLimit'
import { AuditLog } from './auditLog'
import { DataExport, DataExportStep } from './dataExport'
import { UserState } from '../../components/list/UserList'
import { IpLimitState } from '../../components/list/IpLimitList'
import { AuditLogState } from '../../components/list/AuditLogList'
import { CaptureLimitState } from '../../components/list/CaptureLimitList'
import { GcpAccountState, AccountKeyState, DataExportState } from '../../components/list/DataExportList'
import { useToast } from '../../util/hooks/useToast'

export const CategoryType = {
  info: 0,
  project: 0,
  captureLimit: 1,
  member: 0,
  invite: 1,
  inactive: 2,
  audit: 0,
  ipLimit: 0,
  export: 0,

  unknown: 9,
}

const CATEGORY_TITLE = [
  { category: 'info', title: '基本情報' },
  { category: 'project', title: 'プロジェクト' },
  { category: 'captureLimit', title: 'プロジェクト' },
  { category: 'member', title: 'メンバー' },
  { category: 'invite', title: 'メンバー' },
  { category: 'inactive', title: 'メンバー' },
  { category: 'audit', title: 'ログ' },
  { category: 'ipLimit', title: 'IPアドレス制限' },
  { category: 'export', title: 'データエクスポート' },
]
interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

export interface State {
  // 汎用パラメータ
  readonly loading: boolean
  readonly reload: boolean
  readonly selectedUserId: number
  readonly selectedUserName: string
  readonly selectedUserRole: number
  readonly isScroll: boolean
  readonly errorMessage?: string
  readonly teamErrorMessage?: string
  readonly projectErrorMessage?: string
  readonly captureLimitErrorMessage?: string
  readonly memberErrorMessage?: string
  readonly inactiveErrorMessage?: string
  readonly inviteErrorMessage?: string
  readonly ipLimitErrorMessage?: string
  readonly auditLogErrorMessage?: string
  readonly exportErrorMessage?: string
  readonly toastMessage: string

  readonly contentRef: React.RefObject<HTMLElement> // スクロール制御する親コンテナのエレメント
  readonly divRef: React.RefObject<HTMLDivElement>[] // 各項目のコンテナ
  readonly scrollCategory: number

  // 組織情報パラメータ
  readonly info: {
    readonly teamName: string
    readonly tempTeamName: string
    readonly disabled: boolean
  }

  // プロジェクト用パラメータ
  readonly project: {
    readonly projects: { id: number; name: string; role: number }[]
    readonly tempProjects: { id: number; name: string; role: number }[] // 権限設定用プロジェクト情報
    readonly selectedId: number
    readonly step: number // 削除処理ステップ
  }

  // キャプチャ上限設定用パラメータ
  readonly captureLimit: {
    readonly disabled: boolean
    readonly limits: CaptureLimitState[]
    readonly teamLimit: number
    readonly totalRate: number // 割合合計
    readonly step: number // 削除処理ステップ
  }

  // メンバー管理用パラメータ
  readonly member: {
    readonly userId: number
    readonly users: UserState[]
    readonly email: string
    readonly emailDisabled: boolean
    readonly inviteDisabled: boolean
    readonly editDisabled: boolean // 編集実行可否
    readonly tempRole: number // 権限設定用権限情報
    readonly step: number // 処理ステップ
  }

  // 招待中ユーザー
  readonly invite: {
    readonly users: UserState[]
    readonly step: number // メンバー招待取り消し処理ステップ
  }

  // 無効化ユーザー
  readonly inactive: {
    readonly users: UserState[]
    readonly step: number // メンバー復帰処理ステップ
  }

  // IP制限
  readonly ipLimit: {
    readonly ipLimits: IpLimitState[]
    readonly address: string
    readonly memo: string
    readonly editAddress: string
    readonly editMemo: string
    readonly selectedId: number
    readonly disabled: boolean
    readonly disabledAddress: boolean
    readonly disabledMemo: boolean
    readonly step: number // 処理ステップ
  }

  // 監査ログ
  readonly auditLog: {
    readonly logs: AuditLogState[]
    readonly options: OptionProps[]
    readonly selectedDate: string
    readonly divRef: React.RefObject<HTMLDivElement>
  }

  // データエクスポート
  readonly dataExport: {
    readonly accounts: GcpAccountState[]
    readonly projects: DataExportState[]
    readonly keys: AccountKeyState[]
    readonly selectedId: number // 選択ID
    readonly selectedString: string // 選択文字列
    readonly editString: string // 編集文字列
    readonly selectProjects: OptionProps[] // プロジェクトリスト
    readonly gcpId: number
    readonly gcsBucket: string
    readonly contentMaterData: string
    readonly logData: string
    readonly contentsTagsFileUrl: string
    readonly reportDaysLimit: number
    readonly restKey: number // キー残り作成可能数
    readonly step: number // 処理ステップ
    readonly disabled: boolean
  }
}

interface ProjectData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly name: string
    readonly url: string
    readonly tag: string
    readonly updated_at: string
  }>
}

export class Actions {
  info: Info
  project: Project
  captureLimit: CaptureLimit
  member: Member
  invite: Invite
  inactive: Inactive
  ipLimit: IpLimit
  auditLog: AuditLog
  dataExport: DataExport

  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly setCookie: any,
    private readonly openToast: ({ message }: { message: string }) => void,
  ) {
    this.info = new Info(state, setState, openToast)
    this.project = new Project(state, setState, this.setCookie)
    this.captureLimit = new CaptureLimit(state, setState)
    this.member = new Member(state, setState)
    this.invite = new Invite(state, setState)
    this.inactive = new Inactive(state, setState)
    this.ipLimit = new IpLimit(state, setState)
    this.auditLog = new AuditLog(state, setState)
    this.dataExport = new DataExport(state, setState)
  }

  fetch = async (category: string, canViewGcpSa: boolean) => {
    try {
      const date = new Date()
      const user: UserData = await request('GET', `/api/user/`, true)
      const users: UsersData = await request('GET', `/api/users/`, true)
      const inactiveUsers: UsersData = await request('GET', `/api/users/?inactive_only=true`, true)
      const teamData: TeamData = await request('GET', `/api/team/`, true)
      const projectData: ProjectData = await request('GET', `/api/projects/`, true)
      const inviteUsers: InviteData = await request('GET', `/api/invite/`, true)
      const ipLimits: RestrictedIpsData = await request('GET', `/api/restricted_ips/`, true)
      const auditLogs: AuditLogData = await request(
        'GET',
        `/api/auditlog/?yearmonth=${getDateStringYM(date, '')}`,
        true,
      )
      const captureLimits: CaptureLimitsData = await request('GET', `/api/capture_limits/`, true)

      // 実行にはデータエクスポートの権限が必要
      const gcpAccounts: GcpSasData = canViewGcpSa
        ? await request('GET', `/api/gcp_sas/`, true)
        : { count: 0, results: [] }

      const projects =
        projectData.results.length === 0
          ? []
          : projectData.results.map((project) => {
              return { id: project.id, name: project.name, role: Role.Project.none }
            })

      // サービスアカウントの情報作成
      const { saKeys, exportProjects, exportOptions } = await this.createServiceAccountInfo(gcpAccounts, projectData)

      this.setState({
        ...this.state,
        loading: false,
        reload: false,
        isScroll: true,
        scrollCategory:
          this.getCategory(category) === CategoryType.info ? CategoryType.unknown : this.getCategory(category), // info指定の際はスクロールしなくて良い
        info: {
          ...this.state.info,
          teamName: teamData.name,
          tempTeamName: teamData.name,
        },
        project: {
          ...this.state.project,
          projects: projects,
          tempProjects: projects,
        },
        captureLimit: {
          ...this.state.captureLimit,
          limits:
            captureLimits.count === 0
              ? []
              : captureLimits.results.map((result) => {
                  return {
                    id: result.id,
                    name: result.project_name,
                    rate: result.ratio * 100, // 0から1になっている
                    editRate: result.ratio * 100, // 0から1になっている
                    standard: result.estimate_count,
                    excessRate: CalcExcessRate(
                      result.estimate_count,
                      captureLimits.team_monthly_limit,
                      result.ratio * 100,
                    ),
                  }
                }),
          teamLimit: captureLimits.team_monthly_limit,
          totalRate: 100,
        },
        member: {
          ...this.state.member,
          userId: user.id,
          users:
            users.results.length === 0
              ? []
              : users.results.map((user) => {
                  return {
                    id: user.id,
                    userName: user.username,
                    email: user.email,
                    role: user.role,
                    roleName: user.role_name,
                  }
                }),
        },
        invite: {
          ...this.state.invite,
          users:
            inviteUsers.results.length === 0
              ? []
              : inviteUsers.results.map((user) => {
                  return {
                    id: user.id,
                    userName: user.email, // ユーザー名はないのでメールアドレス使用
                    email: user.email,
                    role: Role.User.none,
                    roleName: '',
                  }
                }),
        },
        inactive: {
          ...this.state.inactive,
          users:
            inactiveUsers.results.length === 0
              ? []
              : inactiveUsers.results.map((user) => {
                  return {
                    id: user.id,
                    userName: user.username,
                    email: user.email,
                    role: user.role,
                    roleName: user.role_name,
                  }
                }),
        },
        ipLimit: {
          ...this.state.ipLimit,
          ipLimits:
            ipLimits.results.length === 0
              ? []
              : ipLimits.results.map((result) => {
                  return {
                    id: result.id,
                    address: result.address,
                    memo: result.memo,
                    updatedUser: result.user.username,
                    updated: result.updated_at,
                  }
                }),
        },
        auditLog: {
          ...this.state.auditLog,
          logs:
            auditLogs.results.length === 0
              ? []
              : auditLogs.results.map((log) => {
                  return {
                    id: log.id,
                    date: getDateYYYYMMDDhhmi(log.created_at),
                    user: log.user.username,
                    project: log.project.name,
                    ip: log.remote_addr,
                    event: log.event,
                    log: log.message,
                  }
                }),
          options: [0, 1, 2, 3, 4, 5].map((value) => {
            return {
              label: getDateStringYM(addMonths(date, -value)),
              value: getDateStringYM(addMonths(date, -value), ''),
            }
          }),
          selectedDate: getDateStringYM(date, ''), // 今月を選択
        },
        dataExport: {
          ...this.state.dataExport,
          accounts:
            gcpAccounts.results.length === 0
              ? []
              : gcpAccounts.results.map((account) => {
                  return {
                    id: account.id,
                    user: account.user.username,
                    gcpSaId: account.email,
                    gcsBucketName: account.gcs_bucket_name,
                    created: account.created_at,
                    updated: account.updated_at,
                  }
                }),
          keys: saKeys,
          projects: exportProjects,
          selectProjects: exportOptions,
          gcpId: gcpAccounts.results.length === 0 ? 0 : gcpAccounts.results[0].id,
          gcsBucket: gcpAccounts.results.length === 0 ? '' : gcpAccounts.results[0].gcs_bucket_name,
          restKey: gcpAccounts.results.length === 0 ? 0 : Number(gcpAccounts.results[0].sa_key_remaining),
        },
        teamErrorMessage: '',
        projectErrorMessage: '',
        memberErrorMessage: '',
        inactiveErrorMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        errorMessage: typeof e === 'string' ? e : '組織情報の取得に失敗しました。',
      })
    }
  }

  refetch = async (canViewGcpSa: boolean) => {
    try {
      const users: UsersData = await request('GET', `/api/users/`, true)
      const inactiveUsers: UsersData = await request('GET', `/api/users/?inactive_only=true`, true)
      const inviteUsers: InviteData = await request('GET', `/api/invite/`, true)
      const ipLimits: RestrictedIpsData = await request('GET', `/api/restricted_ips/`, true)
      const auditLogs: AuditLogData = await request(
        'GET',
        `/api/auditlog/?yearmonth=${this.state.auditLog.selectedDate}`,
        true,
      )
      const projectData: ProjectData = await request('GET', `/api/projects/`, true)
      const captureLimits: CaptureLimitsData = await request('GET', `/api/capture_limits/`, true)

      // 実行にはデータエクスポートの権限が必要
      const gcpAccounts: GcpSasData = canViewGcpSa
        ? await request('GET', `/api/gcp_sas/`, true)
        : { count: 0, results: [] }

      const projects =
        projectData.results.length === 0
          ? []
          : projectData.results.map((project) => {
              return { id: project.id, name: project.name, role: Role.Project.none }
            })

      // サービスアカウントの情報作成
      const { saKeys, exportProjects, exportOptions } = await this.createServiceAccountInfo(gcpAccounts, projectData)

      this.state.toastMessage && this.openToast({ message: this.state.toastMessage })
      this.setState({
        ...this.state,
        reload: false,
        isScroll: this.state.scrollCategory !== CategoryType.unknown, // スクロールする項目が選択されている
        toastMessage: '',
        member: {
          ...this.state.member,
          users:
            users.results.length === 0
              ? []
              : users.results.map((user) => {
                  return {
                    id: user.id,
                    userName: user.username,
                    email: user.email,
                    role: user.role,
                    roleName: user.role_name,
                  }
                }),
        },
        project: {
          ...this.state.project,
          projects: projects,
          tempProjects: projects,
        },
        captureLimit: {
          ...this.state.captureLimit,
          limits:
            captureLimits.count === 0
              ? []
              : captureLimits.results.map((result) => {
                  return {
                    id: result.id,
                    name: result.project_name,
                    rate: result.ratio * 100, // 0から1になっている
                    editRate: result.ratio * 100, // 0から1になっている
                    standard: result.estimate_count,
                    excessRate: CalcExcessRate(
                      result.estimate_count,
                      captureLimits.team_monthly_limit,
                      result.ratio * 100,
                    ),
                  }
                }),
          teamLimit: captureLimits.team_monthly_limit,
          totalRate: 100,
        },
        invite: {
          ...this.state.invite,
          users:
            inviteUsers.results.length === 0
              ? []
              : inviteUsers.results.map((user) => {
                  return {
                    id: user.id,
                    userName: user.email, // ユーザー名はないのでメールアドレス使用
                    email: user.email,
                    role: Role.User.none,
                    roleName: '',
                  }
                }),
        },
        inactive: {
          ...this.state.inactive,
          users:
            inactiveUsers.results.length === 0
              ? []
              : inactiveUsers.results.map((user) => {
                  return {
                    id: user.id,
                    userName: user.username,
                    email: user.email,
                    role: user.role,
                    roleName: user.role_name,
                  }
                }),
        },
        ipLimit: {
          ...this.state.ipLimit,
          ipLimits:
            ipLimits.results.length === 0
              ? []
              : ipLimits.results.map((result) => {
                  return {
                    id: result.id,
                    address: result.address,
                    memo: result.memo,
                    updatedUser: result.user.username,
                    updated: result.updated_at,
                  }
                }),
        },
        auditLog: {
          ...this.state.auditLog,
          logs:
            auditLogs.results.length === 0
              ? []
              : auditLogs.results.map((log) => {
                  return {
                    id: log.id,
                    date: getDateYYYYMMDDhhmi(log.created_at),
                    user: log.user.username,
                    project: log.project.name,
                    ip: log.remote_addr,
                    event: log.event,
                    log: log.message,
                  }
                }),
        },
        dataExport: {
          ...this.state.dataExport,
          accounts:
            gcpAccounts.results.length === 0
              ? []
              : gcpAccounts.results.map((account) => {
                  return {
                    id: account.id,
                    user: account.user.username,
                    gcpSaId: account.email,
                    gcsBucketName: account.gcs_bucket_name,
                    created: account.created_at,
                    updated: account.updated_at,
                  }
                }),
          keys: saKeys,
          projects: exportProjects,
          selectProjects: exportOptions,
          gcpId: gcpAccounts.results.length === 0 ? 0 : gcpAccounts.results[0].id,
          gcsBucket: gcpAccounts.results.length === 0 ? '' : gcpAccounts.results[0].gcs_bucket_name,
          restKey: gcpAccounts.results.length === 0 ? 0 : Number(gcpAccounts.results[0].sa_key_remaining),
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        reload: false,
        errorMessage: typeof e === 'string' ? e : '組織情報の取得に失敗しました。',
      })
    }
  }

  getCategory = (category: string): number => {
    if (category === 'info') return CategoryType.info
    if (category === 'project') return CategoryType.project
    if (category === 'captureLimit') return CategoryType.captureLimit
    if (category === 'ipLimit') return CategoryType.ipLimit
    if (category === 'member') return CategoryType.member
    if (category === 'invite') return CategoryType.invite
    if (category === 'inactive') return CategoryType.inactive
    if (category === 'audit') return CategoryType.audit
    if (category === 'export') return CategoryType.export
    return CategoryType.info
  }

  getCategoryTitle = (categoryStr: string) => {
    return CATEGORY_TITLE.find(({ category }) => category === categoryStr)?.title || ''
  }
  // 日時の文字列をラベル用に変換
  changeTimeString = (str: string) => {
    return str.slice(0, 4) + '/' + str.slice(4)
  }

  // サービスアカウントの情報作成
  createServiceAccountInfo = async (gcpAccounts: GcpSasData, projectData: ProjectData) => {
    let saKeys: AccountKeyState[] = []
    let exportProjects: DataExportState[] = []
    let exportOptions: OptionProps[] = []
    if (gcpAccounts.count !== 0) {
      const saId = gcpAccounts.results[0].id
      const keys: GcpSasKeysData = await request('GET', `/api/gcp_sas/${saId}/keys/`, true)
      saKeys =
        keys.count === 0
          ? []
          : keys.results.map((k) => {
              return {
                id: k.id,
                description: k.description,
                gcpSaKeyId: k.gcp_sa_key_id,
                author: k.user.username,
                changer: k.user.username,
                created: k.created_at,
                updated: k.updated_at,
              }
            })

      // 登録されているプロジェクト
      const gcpProjects: GcpSasGcsExportsData = await request('GET', `/api/gcp_sas/${saId}/gcs_exports/`, true)
      exportProjects =
        gcpProjects.count === 0
          ? []
          : gcpProjects.results.map((result) => {
              return {
                id: result.id,
                projectId: result.project.id,
                name: result.project.name,
                latestDate: result.latest_exported_data_date,
                author: result.user.username,
                date: result.created_at,
                contentsMasterFileUrl: result.latest_contents_master_file_url,
                tagFileUrl: result.latest_tag_data_file_url,
                contentsTagsFileUrl: result.latest_contents_tags_file_url,
                reportDaysLimit: result.report_days_limit,
              }
            })

      // プロジェクトリストからエクスポートに登録されているプロジェクトを除外
      const validProjects: {
        id: number
        name: string
        url: string
        tag: string
        updated_at: string
      }[] = projectData.results.filter((result) => {
        if (exportProjects.filter((project) => project.projectId === result.id).length > 0) {
          return false
        }
        return true
      })

      // 登録可能プロジェクト
      exportOptions =
        validProjects.length === 0
          ? []
          : validProjects.map((project) => {
              return {
                label: project.name,
                value: project.id,
              }
            })
    }

    return { saKeys, exportProjects, exportOptions }
  }

  // 指定した項目へスクロール
  scroll = async () => {
    if (this.state.isScroll && this.state.scrollCategory !== CategoryType.unknown) {
      // アコーディオンの動作時間があるので待機が必要
      await sleep(1)
      const top = this.state.divRef[this.state.scrollCategory].current?.offsetTop
        ? this.state.divRef[this.state.scrollCategory].current?.offsetTop
        : 0
      // padding 2rem分を考慮しておく
      this.state.contentRef?.current?.scrollTo({
        top: top! - Number(layout.headerHeightNumber) - convertRemToPx(2),
      })
    }
    this.setState({ ...this.state, isScroll: false, scrollCategory: CategoryType.unknown })
  }
}

const initialState: State = {
  loading: true,
  reload: false,
  selectedUserId: 0,
  selectedUserName: '',
  selectedUserRole: Role.User.none,
  isScroll: false,
  toastMessage: '',
  contentRef: React.createRef<HTMLElement>(),
  divRef: [
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
  ],
  scrollCategory: CategoryType.unknown,
  info: {
    teamName: '',
    tempTeamName: '',
    disabled: true,
  },
  project: {
    projects: [],
    tempProjects: [],
    selectedId: 0,
    step: ProjectStep.none,
  },
  captureLimit: {
    disabled: true,
    limits: [],
    teamLimit: 0,
    totalRate: 0,
    step: CaptureLimitStep.none,
  },
  member: {
    userId: 0,
    users: [],
    email: '',
    emailDisabled: true,
    inviteDisabled: false,
    editDisabled: true,
    tempRole: Role.User.admin,
    step: MemberStep.none,
  },
  invite: {
    users: [],
    step: InviteStep.none,
  },
  inactive: {
    users: [],
    step: InactiveStep.none,
  },
  ipLimit: {
    ipLimits: [],
    address: '',
    memo: '',
    editAddress: '',
    editMemo: '',
    selectedId: 0,
    disabled: true,
    disabledAddress: true,
    disabledMemo: true,
    step: IpLimitStep.none,
  },
  auditLog: {
    logs: [],
    options: [],
    selectedDate: '',
    divRef: React.createRef<HTMLDivElement>(),
  },
  dataExport: {
    accounts: [],
    projects: [],
    keys: [],
    selectedId: 0,
    selectedString: '',
    editString: '',
    selectProjects: [],
    gcpId: 0,
    gcsBucket: '',
    contentMaterData: '',
    logData: '',
    contentsTagsFileUrl: '',
    reportDaysLimit: 0,
    restKey: 0,
    step: DataExportStep.none,
    disabled: true,
  },
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const { openToast } = useToast()
  const setCookie = useCookies()[1]
  const actions = new Actions(state, setState, setCookie, openToast)
  return { state, actions }
}
