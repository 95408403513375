import { DIMENSION_PAGE_TARGET_COLUMN_KEY_TYPES, DIMENSION_PAGE_TARGET_COLUMNS } from '../Filter/constants'
import { DimensionPageTargetColumns } from '../Filter/types'

/**
 * 指定されたディメンションがページ系の場合、「タイトル」か「URL」かのカラム種類を取得する
 *
 * @param {DimensionKeys} key - The filter dimension key.
 * @return {DimensionPageTargetColumns | undefined} - 「タイトル」か「URL」かのカラム種類、一致しなければ undefined を返す
 */
export function getFilterDimensionPageTargetColumn(key: string): DimensionPageTargetColumns | undefined {
  if (!DIMENSION_PAGE_TARGET_COLUMN_KEY_TYPES.find((targetKey) => key.includes(targetKey))) return undefined

  if (key.endsWith('_title')) return DIMENSION_PAGE_TARGET_COLUMNS.TITLE
  if (key.endsWith('_url')) return DIMENSION_PAGE_TARGET_COLUMNS.URL
  return undefined
}
