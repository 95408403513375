import * as React from 'react'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { request } from '../../../request'
import { REPORT_FILTER_SELECTS_QUERY_KEY } from '../constants'
import { AggregateScopes, CustomDimensionOption, DimensionOption } from './types'
import { AGGREGATE_SCOPES, CONDITION_TYPES, DIMENSION_CATEGORIES, DIMENSION_KEYS } from './constants'
import { IMPORT_FIELD_TYPE_TABLE_TYPES } from '../DataImport/constants'
import { ImportFieldTypeTableTypes } from '../DataImport/types'
import { ReportContext } from '../../../../contexts/ReportProvider'
import { ScopeCondition } from '../../../../components/list/DimensionList'
import { GoalType } from '../Goal/types'
import { GOAL_TYPES } from '../Goal/constants'

export type ReportFilterSelectsResponse = {
  goals: GoalFilter[]
  custom_dimensions: CustomDimensionFilter[]
  data_import_fields: DataImportFieldFilter[]
}
export type GoalFilter = {
  id: number
  name: string
  goal_type: GoalType
}
export type CustomDimensionFilter = {
  id: number
  index: number
  name: string
  scope: number
}
export type DataImportFieldFilter = {
  id: number
  name: string
  type: ImportFieldTypeTableTypes
}

export type ReportFilterSelectsData = {
  goals: DimensionOption[]
  customDimensions: CustomDimensionOption[]
  dataImportFields: DimensionOption[]
  hasUtmMediumDimension: boolean
  utmMediumDimensionId: number | undefined
}

type Props = {
  projectId: string
  options?: UseQueryOptions<ReportFilterSelectsData | null>
}

export const importFieldToConditionTypes = {
  [IMPORT_FIELD_TYPE_TABLE_TYPES.NUMERIC]: CONDITION_TYPES.NUMERIC_WITH_EMPTY,
  [IMPORT_FIELD_TYPE_TABLE_TYPES.STRING]: CONDITION_TYPES.STRING_WITH_EMPTY,
  [IMPORT_FIELD_TYPE_TABLE_TYPES.TIMESTAMP]: CONDITION_TYPES.DATE_WITH_EMPTY,
  [IMPORT_FIELD_TYPE_TABLE_TYPES.DATE]: CONDITION_TYPES.DATE_WITH_EMPTY,
}

export const useReportFilterSelects = ({ projectId, options }: Props) => {
  const {
    state: { uuid },
  } = React.useContext(ReportContext)

  const queryKey = [REPORT_FILTER_SELECTS_QUERY_KEY, { uuid, projectId }]

  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<ReportFilterSelectsData> => {
      const response: ReportFilterSelectsResponse = await request(
        'GET',
        `/api/projects/${projectId}/report_filter_name_choices/`,
        true,
      )

      return {
        goals: response.goals.map((goal) => {
          const targetScopes: AggregateScopes[] = [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION]
          if (goal.goal_type === GOAL_TYPES.EVENT) targetScopes.push(AGGREGATE_SCOPES.PAGE_VIEW)

          return {
            id: DIMENSION_KEYS.GOAL,
            name: goal.name,
            dimensionCategory: DIMENSION_CATEGORIES.GOAL,
            conditionType: CONDITION_TYPES.CHOICE,
            targetScopes,
            goalId: goal.id,
          }
        }),
        customDimensions: response.custom_dimensions.map((customDimension) => {
          const targetScopes: AggregateScopes[] = [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION]
          if (customDimension.scope === ScopeCondition.hit) targetScopes.push(AGGREGATE_SCOPES.PAGE_VIEW)

          return {
            id: DIMENSION_KEYS.CUSTOM_DIMENSION,
            name: customDimension.name,
            dimensionCategory: DIMENSION_CATEGORIES.CUSTOM_DIMENSION,
            conditionType: CONDITION_TYPES.STRING_WITH_EMPTY,
            customDimensionId: customDimension.id,
            targetScopes: targetScopes,
          }
        }),
        dataImportFields: response.data_import_fields.map((dataImportField) => ({
          id: DIMENSION_KEYS.DATA_IMPORT_FIELD,
          name: dataImportField.name,
          dimensionCategory: DIMENSION_CATEGORIES.DATA_IMPORT_FIELD,
          conditionType: importFieldToConditionTypes[dataImportField.type],
          targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
          dataImportFieldId: dataImportField.id,
        })),
        hasUtmMediumDimension: response.custom_dimensions.some(
          (customDimension) => customDimension.name === 'utm_medium',
        ),
        utmMediumDimensionId: response.custom_dimensions.find(
          (customDimension) => customDimension.name === 'utm_medium',
        )?.id,
      }
    },
    ...options,
  })

  return {
    ...queryResult,
  }
}
