import * as React from 'react'
import cc from 'classcat'
import styled from 'styled-components'
import { colors } from '../../styleConstants'

interface Props {
  readonly className?: string
  readonly style?: React.CSSProperties
  readonly checked: boolean | undefined
  readonly indeterminate?: boolean | undefined
  readonly disabled?: boolean
  readonly onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function Checkbox({
  className,
  style,
  checked,
  indeterminate,
  onClick,
  disabled,
  ...rest
}: Props & React.InputHTMLAttributes<any>) {
  return (
    <CheckboxContainer className={className} style={style}>
      <HiddenCheckbox type="checkbox" />
      <DesignedCheckbox
        className={cc([{ disabled }])}
        indeterminate={indeterminate}
        checked={checked}
        onClick={onClick}
        aria-checked={checked}
        data-testid="designed-checkbox"
        {...rest}
      >
        <Icon viewBox="0 0 24 24">
          {indeterminate ? <polyline points="20 12 4 12" /> : <polyline points="20 6 9 17 4 12" />}
        </Icon>
      </DesignedCheckbox>
    </CheckboxContainer>
  )
}

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: black;
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
`

const DesignedCheckbox = styled.div<Props>`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${(props) => (props.checked || props.indeterminate ? `${colors.gray100}` : `${colors.white}`)};
  border: 1px ${colors.gray400};
  border-radius: 2px;
  box-shadow: 0 0 0 1px ${colors.gray400};
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 1px ${colors.lightBlue};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked || props.indeterminate ? 'visible' : 'hidden')};
  }

  &.disabled {
    background-color: #ebebeb;
    cursor: initial;
    border: none;
    &:hover {
      box-shadow: 0 0 0 1px ${colors.gray400};
    }
    ${Icon} {
      stroke: ${colors.disabled};
    }
  }
`
