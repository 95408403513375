import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../styleConstants'
import {
  GoalType,
  GoalEventType,
  Condition,
  ValueCondition,
  GoalTypeMap,
  MatchMap,
  ValueConditionMap,
} from '../../util/Goal'
import { Input } from '../../components/common/Input'
import { Select } from '../../components/common/Select'
import { RadioButton } from '../../components/common/RadioButton'
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle'
import { WarningBox } from '../common/WarningBox'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { CancelButton, NormalButton } from '../common/Button'
import { HelpLink } from '../common/HelpLink'
import { HELP_LINKS } from '../../constants'

export const ConditionOptions = [
  { label: MatchMap[Condition.perfect], value: Condition.perfect },
  { label: MatchMap[Condition.head], value: Condition.head },
  { label: MatchMap[Condition.regexp], value: Condition.regexp },
]

export const ValueConditionOptions = [
  { label: ValueConditionMap[ValueCondition.greater], value: ValueCondition.greater },
  { label: ValueConditionMap[ValueCondition.orHigher], value: ValueCondition.orHigher },
  { label: ValueConditionMap[ValueCondition.equal], value: ValueCondition.equal },
  { label: ValueConditionMap[ValueCondition.orLower], value: ValueCondition.orLower },
  { label: ValueConditionMap[ValueCondition.smaller], value: ValueCondition.smaller },
  { label: ValueConditionMap[ValueCondition.exists], value: ValueCondition.exists },
  { label: ValueConditionMap[ValueCondition.notExists], value: ValueCondition.notExists },
]

interface Props {
  readonly disabled?: boolean
  readonly type: number
  readonly name: string
  readonly condition: number
  readonly url: string
  readonly eventSelected: boolean[]
  readonly eventCondition: (number | null)[]
  readonly eventValue: string[]
  readonly onSetType: () => void
  readonly onSetName: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onSetCondition: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly onSetUrl: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onSetEventSelected: (index: number) => void
  readonly onSetEventCondition: (event: React.FormEvent<HTMLSelectElement>, index: number) => void
  readonly onSetEventValue: (event: React.FormEvent<HTMLInputElement>, index: number) => void
  readonly opened: boolean
  readonly onCancel: () => void // キャンセル
  readonly onApply: () => void // 次へ、または作成
}

export function CreateGoal(props: Props) {
  const {
    disabled,
    type,
    name,
    condition,
    url,
    eventSelected,
    eventCondition,
    eventValue,
    onSetType,
    onSetName,
    onSetCondition,
    onSetUrl,
    onSetEventSelected,
    onSetEventCondition,
    onSetEventValue,
    opened,
    onCancel,
    onApply,
  } = props

  // ゴールイベントが一つでも選択されているか
  const isSelectedEvent = eventSelected.filter((type) => type === true).length > 0

  return (
    <Modal isOpen={opened} onClose={onCancel} title="ゴールの追加">
      <ModalContentWrapper>
        <Container>
          <CategoryBox>
            <Category>目標名</Category>
            <DesignedInput
              type="text"
              value={name}
              required
              placeholder={'目標名'}
              style={{ width: '220px', marginLeft: '0' }}
              onChange={onSetName}
            />
          </CategoryBox>

          <CategoryBox>
            <Category>目標タイプ</Category>
            <div style={{ display: 'flex' }}>
              <RadioButton
                buttonSize="normal"
                message={GoalTypeMap[GoalType.url]}
                color={`${colors.gray700}`}
                style={{ justifyContent: 'flex-start' }}
                checked={type === GoalType.url}
                onChange={onSetType}
              />
              <RadioButton
                buttonSize="normal"
                message={GoalTypeMap[GoalType.event]}
                color={`${colors.gray700}`}
                style={{ justifyContent: 'flex-start', marginLeft: '2rem' }}
                checked={type === GoalType.event}
                onChange={onSetType}
              />
            </div>
            {type === GoalType.url && (
              <WarningBoxContainer>
                <WarningBoxWrapper>
                  <WarningBox>URLの末尾にスラッシュ（/）がある場合、スラッシュを除外して設定してください。</WarningBox>
                </WarningBoxWrapper>
                <WarningBoxWrapper>
                  <WarningBox>
                    <div>
                      パラメーターを含めたURLを目標URLにする場合は、「クエリーパラメーター」にそのパラメーターが設定されていることを確認してください。
                    </div>
                    <div>
                      クエリパラメーターに設定がない場合、URLのクエリパラメーター（?〜）は削除されるため、目標URLにパラメーターを付与しても計測がされません。
                    </div>
                  </WarningBox>
                </WarningBoxWrapper>
              </WarningBoxContainer>
            )}
            {type === GoalType.event && (
              <WarningBoxContainer>
                <WarningBoxWrapper>
                  <WarningBox>
                    自動送信されるカスタムイベントをゴール設定する場合、アクションやラベルに入力するURLの末尾のスラッシュ（/）は除外して設定してください。
                  </WarningBox>
                </WarningBoxWrapper>
              </WarningBoxContainer>
            )}
          </CategoryBox>

          {/* 目標URL */}
          {type === GoalType.url && (
            <CategoryBox>
              <Category>目標URL</Category>
              <Item>
                <DesignedSelect options={ConditionOptions} defaultValue={condition} onChange={onSetCondition} />
                <DesignedInput type="text" value={url} placeholder={'目標URL'} required onChange={onSetUrl} />
              </Item>
            </CategoryBox>
          )}

          {/* イベント条件 */}
          {type === GoalType.event && (
            <CategoryBox className={cc({ bordered: !isSelectedEvent })}>
              <EventConditionLabel>
                イベント条件
                <HelpBox>
                  <HelpLink title={'カスタムイベントのゴール設定方法'} link={HELP_LINKS.CUSTOM_EVENT_GOAL_HOW_TO} />
                </HelpBox>
              </EventConditionLabel>
              <Item>
                <CheckIcon
                  className={cc({ checked: eventSelected[GoalEventType.category] })}
                  onClick={() => onSetEventSelected(GoalEventType.category)}
                />
                <Label className={cc({ checked: eventSelected[GoalEventType.category] })}>カテゴリ</Label>
                <DesignedSelect
                  options={ConditionOptions}
                  defaultValue={eventCondition[GoalEventType.category] || ''}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSetEventCondition(e, GoalEventType.category)}
                />
                <DesignedInput
                  type="text"
                  value={eventValue[GoalEventType.category]}
                  placeholder={'カテゴリ'}
                  required={eventSelected[GoalEventType.category]}
                  onChange={(e) => onSetEventValue(e, GoalEventType.category)}
                />
              </Item>
              <Item>
                <CheckIcon
                  className={cc({ checked: eventSelected[GoalEventType.action] })}
                  onClick={() => onSetEventSelected(GoalEventType.action)}
                />
                <Label className={cc({ checked: eventSelected[GoalEventType.action] })}>アクション</Label>
                <DesignedSelect
                  options={ConditionOptions}
                  defaultValue={eventCondition[GoalEventType.action] || ''}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSetEventCondition(e, GoalEventType.action)}
                />
                <DesignedInput
                  type="text"
                  value={eventValue[GoalEventType.action]}
                  placeholder={'アクション'}
                  required={eventSelected[GoalEventType.action]}
                  onChange={(e) => onSetEventValue(e, GoalEventType.action)}
                />
              </Item>
              <Item>
                <CheckIcon
                  className={cc({ checked: eventSelected[GoalEventType.label] })}
                  onClick={() => onSetEventSelected(GoalEventType.label)}
                />
                <Label className={cc({ checked: eventSelected[GoalEventType.label] })}>ラベル</Label>
                <DesignedSelect
                  options={ConditionOptions}
                  defaultValue={eventCondition[GoalEventType.label] || ''}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSetEventCondition(e, GoalEventType.label)}
                />
                <DesignedInput
                  type="text"
                  value={eventValue[GoalEventType.label]}
                  placeholder={'ラベル'}
                  required={eventSelected[GoalEventType.label]}
                  onChange={(e) => onSetEventValue(e, GoalEventType.label)}
                />
              </Item>
              <Item>
                <CheckIcon
                  className={cc({ checked: eventSelected[GoalEventType.value] })}
                  onClick={() => onSetEventSelected(GoalEventType.value)}
                />
                <Label className={cc({ checked: eventSelected[GoalEventType.value] })}>値</Label>
                <DesignedSelect
                  options={ValueConditionOptions}
                  defaultValue={eventCondition[GoalEventType.value] || ''}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSetEventCondition(e, GoalEventType.value)}
                />
                <DesignedInput
                  type="number"
                  value={eventValue[GoalEventType.value]}
                  min={0}
                  required={eventSelected[GoalEventType.value]}
                  onChange={(e) => onSetEventValue(e, GoalEventType.value)}
                />
              </Item>
              {!isSelectedEvent && <ErrorMessage>少なくとも１つのイベント条件を指定してください。</ErrorMessage>}
            </CategoryBox>
          )}
        </Container>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <NormalButton disabled={disabled} onClick={onApply}>
          追加
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 800px;
  height: 100%;
`

const CategoryBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 2px solid ${colors.white};

  &.bordered {
    border-left: 2px solid ${colors.error};
  }

  & + & {
    margin-top: 1.5rem;
  }
`

const Category = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const EventConditionLabel = styled(Category)`
  display: flex;
  align-items: center;
`

const HelpBox = styled.div`
  margin-left: 50px;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  & + & {
    margin-top: 0.5rem;
  }
`

const Label = styled.div`
  font-weight: 600;
  width: 90px;

  &:not(.checked) {
    color: ${colors.gray400};
  }
`

const DesignedInput = styled(Input)`
  flex: 1;
  width: 100%;
  height: 30px;
  margin-left: 1rem;
  background: #ddd;
`

const DesignedSelect = styled(Select)`
  width: 130px;
  height: 30px;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
`

const CheckIcon = styled(CheckCircle).attrs({
  size: 24,
  color: `${colors.gray400}`,
})`
  width: 30px;
  cursor: pointer;
  border-radius: 30px;
  margin-right: 1rem;

  &.checked {
    color: ${colors.orange};
  }
`

const ErrorMessage = styled.div`
  color: ${colors.error};
  margin: 1rem 0 0.5rem;
`
const WarningBoxContainer = styled.div`
  margin-top: 8px;
`
const WarningBoxWrapper = styled.div`
  margin-top: 16px;
`
