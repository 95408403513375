import * as React from 'react'
import { request } from '../../../util/request'
import { ProjectData } from '../../../util/Response'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly name: string
  readonly url: string
  readonly disabled: boolean
  readonly inputDisabled: boolean
  readonly urlDisabled: boolean
  readonly errorMessage?: string
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  updateName = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      name: event.currentTarget.value,
      inputDisabled: !event.currentTarget.validity.valid,
      disabled: this.state.urlDisabled || !event.currentTarget.validity.valid,
    })
  }

  updateUrl = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      url: event.currentTarget.value,
      urlDisabled: !event.currentTarget.validity.valid,
      disabled: this.state.inputDisabled || !event.currentTarget.validity.valid,
    })
  }

  createProject = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, onCreate: (id: number) => void) => {
    event.preventDefault()
    try {
      this.setState({ ...this.state, disabled: true })
      const json: ProjectData = await request(
        'POST',
        `/api/projects/`,
        true,
        JSON.stringify({ name: this.state.name, url: this.state.url }),
      )
      onCreate(json.id)
    } catch (e) {
      this.setState({
        ...this.state,
        errorMessage: typeof e === 'string' ? e : 'プロジェクトの作成に失敗しました。',
      })
    }
  }
}

const initialState: State = {
  name: '',
  url: '',
  disabled: true,
  inputDisabled: true,
  urlDisabled: true,
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
