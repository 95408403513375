import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { HELP_LINKS } from '../../../constants'
import { BaseHeaderCell } from './BaseCell'
import { HeaderCellHelp } from './HeaderCellHelp'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'

export function ContentCapturedAtHeaderCell() {
  return (
    <Cell>
      集計期間
      <HeaderCellHelp width={230}>
        <div>コンテンツのデータ集計期間。</div>
        <div>
          指定した期間内で計測が開始または停止している場合は、太字で表しています。ただし、実際のコンテンツ掲載期間と表示されている集計期間は異なる場合があります。詳細は
          <HelpLink href={HELP_LINKS.CONTENT_REPORT_AGGREGATION_PERIOD} target="_blank">
            ヘルプ
          </HelpLink>
          を確認ください。
        </div>
      </HeaderCellHelp>
    </Cell>
  )
}

const Cell = styled(BaseHeaderCell)`
  background-color: ${CELL_BACKGROUND_COLORS.CONTENT};
`

const HelpLink = styled.a`
  color: ${colors.contentBlue.blue3};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
`
