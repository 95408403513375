import * as React from 'react'

import { useScopeType } from '../useScopeType'
import { request } from '../../request'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { useDeviceType } from '../useDeviceType'
import { useFilterContentEventsExists } from '../cookie/useFilterContentEventsExists'
import { useGoalId } from '../useGoalId'
import { downloadBlobFile } from '../../downloadUtils'
import { NarrowDownState } from '../../../components/filter/PageReportNarrowDown'
import { makeTimelineRequestBody } from './useTimelineReport'
import { ReportContext } from '../../../contexts/ReportProvider'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'
import { useTimelineAggregationDateType } from '../cookie/useTimelineAggregationDateType'

interface TimelineDownloadResponse {
  readonly download_url: string
}

interface Props {
  projectId: string
  searchText: string
  narrowDownState: NarrowDownState[]
}

/**
 * 時系列グラフデータのCSVダウンロードAPIのhook
 *
 * @param {Object} props - The props object containing the necessary parameters.
 * @param {number} props.projectId - The ID of the project.
 * @param {string} props.searchText - The search text.
 * @param {object} props.narrowDownState - List of search options for page reports
 * @returns {UseMutationResult} - The mutation result object.
 */
export const usePageReportTimelineCsvDownload = ({
  projectId,
  searchText,
  narrowDownState,
}: Props): UseMutationResult<void, unknown, void, unknown> => {
  const {
    state: { calenderState },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)

  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { goalId } = useGoalId({ projectId: Number(projectId) })
  const { filterContentEventsExists } = useFilterContentEventsExists()
  const { timelineAggregationDateType: aggregationDateType } = useTimelineAggregationDateType()

  return useMutation({
    mutationFn: async () => {
      const requestBody = makeTimelineRequestBody(
        aggregationDateType,
        scopeType,
        deviceType,
        goalId,
        calenderState,
        searchText,
        narrowDownState,
        customFilterState,
        filterContentEventsExists,
      )
      const res = await request<TimelineDownloadResponse>(
        'POST',
        `/api/projects/${projectId}/timeline_report/download/`,
        true,
        JSON.stringify(requestBody),
      )
      await downloadBlobFile({
        downloadUrl: res.download_url,
        defaultFilename: 'contentanalytics_timeline_report.csv',
      })
    },
  })
}
