import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../../styleConstants'
import { Link } from '@gatsbyjs/reach-router'
import { Plus } from '@styled-icons/boxicons-regular/Plus'
import { PersonAdd } from '@styled-icons/material-outlined/PersonAdd'

const base = css`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  border-radius: 2px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  user-select: none;
  outline: none;
  text-decoration: none;
  border-style: none;
  border-width: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: all 0.2s linear;

  &:disabled {
    background-color: ${colors.disabled};
  }

  [data-icon='left'] {
    display: inline-block;
    margin-right: 1rem;
  }

  [data-icon='right'] {
    display: inline-block;
    margin-left: 1rem;
  }
`

const look = {
  white: css`
    color: ${colors.black};
    background-color: ${colors.white};
    border-color: ${colors.gray300};
    transition: opacity filter 0.3s;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      transition: none;
    }
  `,
  darkBlue: css`
    color: ${colors.white};
    background-color: ${colors.contentBlue.blue7};
    border-color: ${colors.contentBlue.blue7};
    transition: opacity filter 0.3s;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      transition: none;
    }
  `,
}

export interface Props {
  readonly look: 'white' | 'darkBlue'
  readonly iconLeft?: JSX.Element
  readonly iconRight?: JSX.Element
}

const BaseButton = styled.button<Props>`
  ${base}
  ${(props) => look[props.look]}
`

export function Button({ iconLeft, iconRight, children, ...rest }: Props & React.ButtonHTMLAttributes<any>) {
  return (
    <BaseButton {...rest}>
      {iconLeft && React.cloneElement(iconLeft, { 'data-icon': 'left' })}
      {children}
      {iconRight && React.cloneElement(iconRight, { 'data-icon': 'right' })}
    </BaseButton>
  )
}

// --デザインガイド適用ボタン--

const baseStyle = css`
  display: block;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  user-select: none;
  outline: none;
  text-decoration: none;
  min-width: 96px;
  height: 30px;
  padding: 0 16px;
  border: none;
  border-radius: 2px;
  color: ${colors.white};
  box-shadow: 0px 2px 4px #00000033;
  transition: all 0.2s linear;
  &:disabled {
    color: ${colors.disabled};
    background-color: ${colors.gray350};
    box-shadow: none;
  }
  &:active {
    transition: none;
  }
  &:hover {
    cursor: pointer;
    &:disabled {
      background-color: ${colors.gray350};
      cursor: auto;
    }
  }
`
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly limited?: boolean
}

interface LinkProps extends ButtonProps {
  readonly to: string
}

const ActionButtonBase = styled.button<ButtonProps>`
  ${baseStyle}
  background-color: ${(props) => (props.limited ? colors.gray750 : colors.contentBlue.blue7)};
  &:hover {
    background-color: ${(props) => (props.limited ? colors.disabled : colors.contentBlue.blue6)};
  }
`

const LinkButtonBase = styled(Link)<LinkProps>`
  ${baseStyle}
  background-color: ${(props) => (props.limited ? colors.gray750 : colors.contentBlue.blue7)};
  &:hover {
    background-color: ${(props) => (props.limited ? colors.disabled : colors.contentBlue.blue6)};
  }
`

const ButtonBase = ({
  to,
  ...rest
}: React.ButtonHTMLAttributes<any> & {
  readonly limited?: boolean
  readonly to?: string
}) => {
  return to ? <LinkButtonBase {...rest} to={to} /> : <ActionButtonBase {...rest} />
}

export const NormalButton = ButtonBase

export const AdminNormalButton = styled(ButtonBase)`
  background-color: ${colors.contentOrange.orange6};
  &:hover {
    background-color: ${colors.contentOrange.orange5};
  }
`

export const CancelButton = styled(ButtonBase)`
  color: ${colors.black2};
  background-color: ${colors.gray350};
  font-weight: normal;
  &:hover {
    background-color: ${colors.gray100};
  }
`

export const WarningButton = styled(ButtonBase)`
  color: ${colors.error};
  background-color: ${colors.white};
  border: 1px solid ${colors.error};
  &:hover {
    color: ${colors.white};
    background-color: ${colors.error};
  }
`

const AddButtonBase = styled(ButtonBase)`
  font-size: 14px;
  border-radius: 16px;
  [data-icon='left'] {
    display: inline-block;
    vertical-align: top;
  }
  &:disabled {
    color: ${colors.disabled};
    background-color: ${colors.gray350};
    box-shadow: none;
    [data-icon='left'] {
      color: ${colors.disabled};
    }
  }
`

const AddButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const AddButton = (props: ButtonProps) => (
  <AddButtonBase {...props}>
    <AddButtonContent>
      <Plus
        size={16}
        color={`${colors.white}`}
        data-icon="left"
        // marginでテキストが右寄りになりすぎないよう調整
        style={{
          margin: '0 0 0 -6px',
        }}
      />
      {props.children}
    </AddButtonContent>
  </AddButtonBase>
)

export const AddMemberButton = (props: ButtonProps) => (
  <AddButtonBase {...props}>
    <PersonAdd
      size={20}
      color={`${colors.white}`}
      data-icon="left"
      style={{
        margin: '1px 4px 0 0',
      }}
    />
    {props.children}
  </AddButtonBase>
)

interface IconButtonProps extends ButtonProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly iconLeft?: JSX.Element
  readonly iconRight?: JSX.Element
}

const IconButtonBase = styled(ButtonBase)`
  [data-icon='left'] {
    display: inline-block;
    margin-right: 6px;
    vertical-align: sub;
  }
  [data-icon='right'] {
    display: inline-block;
    margin-left: 6px;
    vertical-align: sub;
  }
`

export const IconButton = ({ iconLeft, iconRight, children, ...rest }: IconButtonProps) => (
  <IconButtonBase {...rest}>
    {iconLeft && React.cloneElement(iconLeft, { 'data-icon': 'left' })}
    {children}
    {iconRight && React.cloneElement(iconRight, { 'data-icon': 'right' })}
  </IconButtonBase>
)

export const SubActionButton = styled(LinkButtonBase)`
  color: ${(props) => (props.limited ? colors.white : colors.contentBlue.blue7)};
  border: ${(props) => (props.limited ? 'none' : `1px solid ${colors.contentBlue.blue7}`)};
  background-color: ${(props) => (props.limited ? colors.gray750 : colors.white)};
  &:hover {
    color: ${colors.white};
    background-color: ${(props) => (props.limited ? colors.disabled : colors.contentBlue.blue6)};
    border-color: ${colors.contentBlue.blue6};
    &:disabled {
      color: ${colors.disabled};
      background-color: ${colors.white};
      border-color: ${colors.gray350};
    }
  }
  &:disabled {
    background-color: ${colors.white};
    border-color: ${colors.gray350};
  }
`

const SubActionIconButtonBase = styled(IconButtonBase)`
  color: ${(props) => (props.limited ? colors.white : colors.contentBlue.blue7)};
  border: ${(props) => (props.limited ? 'none' : `1px solid ${colors.contentBlue.blue7}`)};
  background-color: ${(props) => (props.limited ? colors.gray750 : colors.white)};
  &:hover {
    color: ${colors.white};
    background-color: ${(props) => (props.limited ? colors.disabled : colors.contentBlue.blue6)};
    border-color: ${colors.contentBlue.blue6};
    [data-icon] {
      color: ${colors.white};
    }
    &:disabled {
      color: ${colors.disabled};
      background-color: ${colors.white};
      border-color: ${colors.gray350};
      [data-icon] {
        color: ${colors.disabled};
      }
    }
  }
  &:disabled {
    background-color: ${colors.white};
    border-color: ${colors.gray350};
  }
`
export const SubActionIconButton = ({ iconLeft, iconRight, children, ...rest }: IconButtonProps) => (
  <SubActionIconButtonBase {...rest}>
    {iconLeft && React.cloneElement(iconLeft, { 'data-icon': 'left' })}
    {children}
    {iconRight && React.cloneElement(iconRight, { 'data-icon': 'right' })}
  </SubActionIconButtonBase>
)
