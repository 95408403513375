import { PAGE_REPORT_TIMELINE_OPENED_COOKIE_KEY } from './constants'
import { useCookieState } from './useCookieState'

/**
 * ページレポートのタイムラインのアコーディオン開閉状態を管理するカスタムフック
 *
 * @returns {object} - タイムラインが開かれているかどうかの状態と、それを更新する関数を返す
 */
export const useTimelineOpened = () => {
  const [timelineOpened, setTimelineOpened] = useCookieState<boolean>({
    cookieKey: PAGE_REPORT_TIMELINE_OPENED_COOKIE_KEY,
    initialValue: true,
  })
  return { timelineOpened, setTimelineOpened }
}
