import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { IpAddressConditionMap } from '../../util/IpAddress'
import { AddButton } from '../common/Button'
import { PopupMenu } from '../common/PopupMenu'
import { Settings } from '@styled-icons/material/Settings'

export interface IpAddressState {
  readonly id: number
  readonly name: string
  readonly condition: number
  readonly ipAddress: string
}

interface Props {
  readonly ipAddresses: IpAddressState[]
  readonly canAddIpAddressFilter?: boolean
  readonly canChangeIpAddressFilter?: boolean
  readonly canDeleteIpAddressFilter?: boolean
  readonly onCreate?: () => void
  readonly onEdit?: (id: number) => void
  readonly onDelete?: (id: number) => void
}

export function IpAddressList(props: Props) {
  const {
    ipAddresses,
    canAddIpAddressFilter,
    canChangeIpAddressFilter,
    canDeleteIpAddressFilter,
    onCreate,
    onEdit,
    onDelete,
  } = props

  const [state, setState] = React.useState({ id: 0, opened: false })
  const onClick = (id: number) => {
    setState({ ...state, id: id, opened: true })
  }
  const popupClose = () => {
    setState({ ...state, id: 0, opened: false })
  }

  return (
    <Container>
      {canAddIpAddressFilter && (
        <div style={{ margin: '1rem 0' }}>
          <AddButton onClick={onCreate}>IPアドレスを追加</AddButton>
        </div>
      )}
      {ipAddresses.length === 0 ? (
        <div style={{ marginBottom: '1rem' }}>IPアドレスが登録されていません</div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Item style={{ paddingBottom: '0.5rem' }}>
            <Box className="title" style={{ width: '200px' }}>
              フィルタ名
            </Box>
            <Box className="title" style={{ width: '150px' }}>
              条件
            </Box>
            <Box className="title">IPアドレス</Box>
          </Item>
          {ipAddresses.map((address, index) => {
            return (
              <Item key={index}>
                <Box style={{ width: '200px' }}>{address.name}</Box>
                <Box style={{ width: '150px' }}>{IpAddressConditionMap[address.condition]}</Box>
                <Box>{address.ipAddress}</Box>
                {(canChangeIpAddressFilter || canDeleteIpAddressFilter) && (
                  <SettingBox>
                    <SettingIcon onClick={() => onClick(address.id)} />
                    {state.opened && state.id === address.id && (
                      <PopupMenu
                        onClose={popupClose}
                        style={{
                          right: '40px',
                          bottom: canChangeIpAddressFilter && canDeleteIpAddressFilter ? '-25px' : '-10px',
                          width: '80px',
                        }}
                      >
                        <Links>
                          {canChangeIpAddressFilter && (
                            <DesignedLink onClick={() => onEdit && onEdit(address.id)}>編集</DesignedLink>
                          )}
                          {canDeleteIpAddressFilter && (
                            <DesignedLink onClick={() => onDelete && onDelete(address.id)}>削除</DesignedLink>
                          )}
                        </Links>
                      </PopupMenu>
                    )}
                  </SettingBox>
                )}
              </Item>
            )
          })}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div``

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  height: 100%;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${colors.gray300};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;

  & + & {
    margin-left: 1.5rem;
  }

  &.title {
    color: ${colors.gray500};
  }
`

const SettingBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
`

const SettingIcon = styled(Settings).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  margin-left: auto;
  margin-right: 1rem;
  cursor: pointer;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const DesignedLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
    background-color: ${colors.gray200};
  }

  & + & {
    margin-top: 0.1rem;
  }
`
