import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../../styleConstants'
import { Spinner } from './Spinner'

export function Loading() {
  return (
    <Backdrop>
      <Content>
        <Container>
          <Label>Loading</Label>
          <Spinner type="Bar" loading={true} width={250} height={25} />
        </Container>
      </Content>
    </Backdrop>
  )
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${layout.modalBackdropZIndex};
`

const Content = styled.div`
  background-color: ${colors.white};
  padding: 32px;
  text-align: left;
  border-radius: 0 0 2px 2px;
  position: relative;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const Label = styled.div`
  color: ${colors.lightBlue};
  font-size: 1.5rem;
  font-weight: ${layout.boldFontWeight};
  margin-bottom: 0.5rem;
`
