import { CustomFilterState, DimensionFilterState, ReportFilter, ReportFilterCondition } from './hooks/api/Filter/types'
import { AGGREGATE_CONDITIONS, DIMENSION_PAGE_TARGET_COLUMN_KEY_TYPES } from './hooks/api/Filter/constants'

export const makeReportFilters = (filterStates: CustomFilterState[]): Array<ReportFilter> => {
  if (filterStates.length === 0) {
    return []
  }
  return filterStates.map((filter: CustomFilterState) => {
    return {
      scope: filter.aggregate.unit,
      included: filter.aggregate.condition === AGGREGATE_CONDITIONS.INCLUDE,
      conditions: filter.dimensions.map((dimension): ReportFilterCondition => {
        // 現状ANDのみ
        return getCustomFilterQuery(dimension.states[0])
      }),
    }
  })
}

const getCustomFilterQuery = (state: DimensionFilterState): ReportFilterCondition => {
  if (!state.id) throw Error('ディメンションがセットされていません')
  const name = DIMENSION_PAGE_TARGET_COLUMN_KEY_TYPES.includes(state.id) ? state.id + state.target : state.id
  const match_type = state.condition
  const value: string = state.value || ''
  const from_value: string = state.fromValue?.toString() || ''
  const to_value: string = state.toValue?.toString() || ''
  return {
    name,
    match_type,
    value,
    from_value,
    to_value,
    goal_id: state.goalId,
    custom_dimension_id: state.customDimensionId,
    data_import_field_id: state.dataImportFieldId,
  }
}
