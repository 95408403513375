import { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '../../../request'
import { DATA_IMPORT_LOGS_QUERY_KEY } from '../constants'
import { DataImportLog, DataImportLogsResponse } from './types'
import { DATA_IMPORT_STATUS, IMPORT_LOG_POLLING_INTERVAL } from './constants'

type Props = {
  projectId: string
}

export const useDataImportLogs = ({ projectId }: Props) => {
  const queryClient = useQueryClient()
  const queryKey = [DATA_IMPORT_LOGS_QUERY_KEY, { projectId }]

  const [isManualRefetching, setIsManualRefetching] = useState(false)
  const invalidate = async () => {
    setIsManualRefetching(true)
    // 連打防止のため1秒pendする
    await setTimeout(async () => {
      await queryClient.invalidateQueries({ queryKey })
      setIsManualRefetching(false)
    }, 1000)
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await request<DataImportLogsResponse>(
        'GET',
        `/api/projects/${projectId}/data_import_logs/`,
        true,
      )

      const formatted: DataImportLog[] = response.results.map((item) => {
        return {
          id: item.id,
          // 91番以上のステータスは全てFailedとして扱う
          status: item.status >= DATA_IMPORT_STATUS.FAILED ? DATA_IMPORT_STATUS.FAILED : item.status,
          user: {
            // TODO: APIのuser_uploadedの値が全てfalseで返ってくるので、
            //  https://github.com/uncovertruth/ca-infra/issues/1641 が解消されるまで暫定対応する
            // userName: item.user_uploaded ? item.user?.username || '削除されたユーザー' : 'システム',
            userName: item.user?.username || 'システム',
          },
          createdAt: item.created_at,
          summarySuccessedDataCount: item.summary_successed_data_count,
          summaryFailedDataCount: item.summary_failed_data_count,
          summaryErrorLog: item.summary_error_log.map((errorLog) => {
            return {
              rowNumber: errorLog.row_number,
              messages: errorLog.messages,
            }
          }),
          generalErrorMessage: item.general_error_message,
        }
      })
      return formatted
    },
    enabled: projectId !== '',
    refetchInterval: IMPORT_LOG_POLLING_INTERVAL,
  })
  return {
    ...queryResult,
    isManualRefetching,
    invalidate,
  }
}
