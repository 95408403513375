import * as React from 'react'
import styled from 'styled-components'
import { ContentsSummary, SortItemKey, SortState } from '../../../../util/hooks/api/useContentReport'
import { SummaryRow } from '../../rows/SummaryRow'
import { CategoryRow } from '../../rows/CategoryRow'
import { ContentGroupHeaderRow } from '../../rows/ContentGroupHeaderRow'
import { GRID_HEADER_ROWS_CHILD_HEIGHT, GRID_HEADER_ROWS_MIN_HEIGHT, GRID_MIN_WIDTH } from '../..'
import { GRID_BORDER_COLOR } from '../../../../styleConstants'

interface Props {
  readonly contentsSummary: ContentsSummary
  readonly sortState: SortState
  readonly onSort: (key: SortItemKey) => void
}

export function ContentGroupGridHeader({ contentsSummary, sortState, onSort }: Props) {
  const onSortClick = (elementKey: any) => {
    onSort(elementKey)
  }

  return (
    <Container>
      <ThreeRowsBox>
        <CategoryRow />
        <ContentGroupHeaderRow sortState={sortState} onSortClick={onSortClick} />
        <SummaryRow contentsSummary={contentsSummary} />
      </ThreeRowsBox>
    </Container>
  )
}

const Container = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: ${GRID_MIN_WIDTH}px;
  min-height: ${GRID_HEADER_ROWS_MIN_HEIGHT};
`

const ThreeRowsBox = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: ${GRID_HEADER_ROWS_CHILD_HEIGHT};
  grid-auto-rows: 36px;
  border-color: ${GRID_BORDER_COLOR};
  border-style: solid;
  border-width: 1px 1px 0px 1px;
`
