import { DeviceType, getDeviceLayoutNumber, useDeviceType } from '../useDeviceType'
import { CalenderState } from '../../../components/common/DayPickerRange'
import { CustomFilterState } from './Filter/types'
import { request } from '../../request'
import { Percent } from '../../Parse'
import { sortBy } from '../../sortBy'
import { QueryFilters, useQuery, useQueryClient } from '@tanstack/react-query'
import { ContentsSummary, getRequestBody, PageHistory, PageSummary, SortState } from './useContentReport'
import { ContentGroupReportResponse } from '../../responses/ContentGroupReportResponse'
import { CONTENT_GROUP_REPORT_QUERY_KEY } from './constants'
import { getScaledPercent } from '../../getScaledPercent'
import { useScopeType } from '../useScopeType'
import { useFilterContentEventsExists } from '../cookie/useFilterContentEventsExists'
import { useGoalList } from './Goal/useGoalList'
import { useGoalId } from '../useGoalId'

export type ContentGroup = {
  id: number
  name: string
  linkPageHistoryId: number // 表エリアのコンテンツクリックと連動してキャプチャ側で表示するページ更新履歴ID
  linkContentLocationId: number // 表エリアのコンテンツクリックと連動してキャプチャ側で選択するコンテンツ表示位置ID
  viewCount: number
  viewCountRank: number
  viewCountScaledPercent: number
  viewRate: number
  viewRateRank: number
  viewRateScaledPercent: number
  viewSecond: number
  viewSecondRank: number
  viewSecondScaledPercent: number
  goalCount: number
  goalCountRank: number
  goalCountScaledPercent: number
  goalRate: number
  goalRateRank: number
  goalRateScaledPercent: number
  clickCount: number
  clickCountRank: number
  clickCountScaledPercent: number
  clickRate: number
  clickRateRank: number
  clickRateScaledPercent: number
}

export type ContentGroupReportData = {
  pageId: number
  urlId: number
  url: string
  urlTitle: string
  isCaptureFailed: boolean
  pageSummary: PageSummary
  contentsSummary: ContentsSummary
  contentGroups: ContentGroup[]
  pageHistories: PageHistory[]
  initPageHistoryId: number
  isManagedContentExists: boolean
}

export const useContentGroupReport = ({
  projectId,
  reportId,
  calenderState,
  customFilterState,
  sortState,
  enabled = true,
}: {
  projectId: string
  reportId: string
  calenderState: CalenderState
  customFilterState: CustomFilterState[]
  sortState: SortState
  enabled?: boolean
}) => {
  const queryClient = useQueryClient()
  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { goalId } = useGoalId({ projectId: Number(projectId) })
  const { filterContentEventsExists } = useFilterContentEventsExists()

  const { getGoal } = useGoalList({ projectId: Number(projectId) })
  const goal = getGoal(goalId)

  const queryKey = [
    CONTENT_GROUP_REPORT_QUERY_KEY,
    {
      projectId,
      reportId,
      deviceType,
      goal,
      startDate: calenderState.startDate,
      endDate: calenderState.endDate,
      scopeType,
      customFilterState,
    },
  ]

  const setContentGroupReport = (
    updater:
      | ContentGroupReportData
      | undefined
      | ((oldData: ContentGroupReportData | undefined) => ContentGroupReportData | undefined),
  ) => queryClient.setQueryData<ContentGroupReportData>(queryKey, updater)

  const resetContentGroupReport = async (queryFilters?: Omit<QueryFilters, 'queryKey'>) => {
    await queryClient.removeQueries({ queryKey: [CONTENT_GROUP_REPORT_QUERY_KEY], ...queryFilters })
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const data = await request<ContentGroupReportResponse>(
        'POST',
        `/api/projects/${projectId}/content_group_reports/${reportId}/`,
        true,
        getRequestBody(
          getDeviceLayoutNumber(deviceType),
          goalId,
          calenderState,
          scopeType,
          filterContentEventsExists,
          customFilterState,
          sortState,
        ),
      )
      const contentGroups = makeContentGroups(data)
      const pageHistories = makePageHistories(data, contentGroups)

      const formatted: ContentGroupReportData = {
        pageId: data.page_id,
        urlId: data.url_id,
        url: data.url,
        urlTitle: data.title,
        isCaptureFailed: data.is_last_failed_cs,
        pageSummary: {
          sessions: data.page_sessions,
          users: data.page_users,
          conversions: data.page_conversions,
          conversionsRate: Percent.parse(data.page_conversions_rate),
        },
        contentsSummary: {
          viewCount: data.page_valid_views,
          viewRate: Percent.parse(data.page_valid_views_rate),
          viewSecond: data.page_second,
          goalCount: data.page_goal_contributions,
          goalRate: Percent.parse(data.page_goal_contributions_rate),
          clickCount: data.page_clicks,
          clickRate: Percent.parse(data.page_clicks_rate),
        },
        initPageHistoryId: pageHistories[0]?.id,
        contentGroups: contentGroups,
        pageHistories: pageHistories,
        isManagedContentExists: data.managed_contents.length > 0,
      }
      return formatted
    },
    staleTime: 1000 * 60 * 5,
    refetchInterval: 1000 * 60 * 5,
    enabled: enabled && !!goal,
  })
  return {
    ...queryResult,
    setContentGroupReport,
    resetContentGroupReport,
  }
}

export interface GetContentReportDataProps {
  readonly projectId: string
  readonly pageReportId: string
  readonly goalId: number
  readonly calenderState: CalenderState
  readonly customFilterState: CustomFilterState[]
  readonly device: DeviceType
  readonly sortState: SortState
}

const makeContentGroups = (response: ContentGroupReportResponse): Array<ContentGroup> => {
  if (response.content_groups.length === 0) {
    return []
  }

  const viewCountRankList = [
    ...new Set(sortBy(response.content_groups, 'valid_views').map((content) => content.valid_views)),
  ]
  const viewRateRankList = [
    ...new Set(sortBy(response.content_groups, 'valid_views_rate').map((content) => content.valid_views_rate)),
  ]
  const viewSecondRankList = [...new Set(sortBy(response.content_groups, 'second').map((content) => content.second))]
  const goalCountRankList = [
    ...new Set(sortBy(response.content_groups, 'goal_contributions').map((content) => content.goal_contributions)),
  ]
  const goalRateRankList = [
    ...new Set(
      sortBy(response.content_groups, 'goal_contributions_rate').map((content) => content.goal_contributions_rate),
    ),
  ]
  const clickCountRankList = [...new Set(sortBy(response.content_groups, 'clicks').map((content) => content.clicks))]
  const clickRateRankList = [
    ...new Set(sortBy(response.content_groups, 'clicks_rate').map((content) => content.clicks_rate)),
  ]

  const minViewCount: number = Math.min(...response.content_groups.map((content) => content.valid_views))
  const maxViewCount: number = Math.max(...response.content_groups.map((content) => content.valid_views))
  const minViewRate: number = Math.min(...response.content_groups.map((content) => content.valid_views_rate))
  const maxViewRate: number = Math.max(...response.content_groups.map((content) => content.valid_views_rate))
  const minViewSecond: number = Math.min(...response.content_groups.map((content) => content.second))
  const maxViewSecond: number = Math.max(...response.content_groups.map((content) => content.second))
  const minGoalCount: number = Math.min(...response.content_groups.map((content) => content.goal_contributions))
  const maxGoalCount: number = Math.max(...response.content_groups.map((content) => content.goal_contributions))
  const minGoalRate: number = Math.min(...response.content_groups.map((content) => content.goal_contributions_rate))
  const maxGoalRate: number = Math.max(...response.content_groups.map((content) => content.goal_contributions_rate))
  const minClickCount: number = Math.min(...response.content_groups.map((content) => content.clicks))
  const maxClickCount: number = Math.max(...response.content_groups.map((content) => content.clicks))
  const minClickRate: number = Math.min(...response.content_groups.map((content) => content.clicks_rate))
  const maxClickRate: number = Math.max(...response.content_groups.map((content) => content.clicks_rate))

  return response.content_groups.map((content) => {
    return {
      id: content.id,
      name: content.name,
      linkPageHistoryId: content.page_history_id,
      linkContentLocationId: content.content_group_location_id,
      viewCount: content.valid_views,
      viewCountRank: viewCountRankList.findIndex((count) => count === content.valid_views) + 1,
      viewCountScaledPercent: getScaledPercent(content.valid_views, minViewCount, maxViewCount),
      viewRate: Percent.parse(content.valid_views_rate),
      viewRateRank: viewRateRankList.findIndex((rate) => rate === content.valid_views_rate) + 1,
      viewRateScaledPercent: getScaledPercent(content.valid_views_rate, minViewRate, maxViewRate),
      viewSecond: content.second,
      viewSecondRank: viewSecondRankList.findIndex((second) => second === content.second) + 1,
      viewSecondScaledPercent: getScaledPercent(content.second, minViewSecond, maxViewSecond),
      goalCount: content.goal_contributions,
      goalCountRank: goalCountRankList.findIndex((count) => count === content.goal_contributions) + 1,
      goalCountScaledPercent: getScaledPercent(content.goal_contributions, minGoalCount, maxGoalCount),
      goalRate: Percent.parse(content.goal_contributions_rate),
      goalRateRank: goalRateRankList.findIndex((rate) => rate === content.goal_contributions_rate) + 1,
      goalRateScaledPercent: getScaledPercent(content.goal_contributions_rate, minGoalRate, maxGoalRate),
      clickCount: content.clicks,
      clickCountRank: clickCountRankList.findIndex((count) => count === content.clicks) + 1,
      clickCountScaledPercent: getScaledPercent(content.clicks, minClickCount, maxClickCount),
      clickRate: Percent.parse(content.clicks_rate),
      clickRateRank: clickRateRankList.findIndex((rate) => rate === content.clicks_rate) + 1,
      clickRateScaledPercent: getScaledPercent(content.clicks_rate, minClickRate, maxClickRate),
    }
  })
}

const makePageHistories = (response: ContentGroupReportResponse, contentGroups: ContentGroup[]): Array<PageHistory> => {
  if (response.page_history.length === 0) {
    return []
  }

  return response.page_history.map((history) => {
    return {
      id: history.id,
      capturedAt: history.created_at,
      screenshotUrl: history.screenshot_url,
      screenshotWidth: history.screenshot_width,
      contentLocations: history.content_group_locations.map((location) => {
        const group = contentGroups.find((group) => group.id === location.content_group_id)
        return {
          id: location.id,
          linkContentId: location.content_group_id,
          topLeftX: location.top_left_x,
          topLeftY: location.top_left_y,
          width: location.width,
          height: location.height,
          name: group?.name || '',
          viewCount: group?.viewCount || 0,
          viewCountRank: group?.viewCountRank || 0,
          viewRate: group?.viewRate || 0,
          viewRateRank: group?.viewRateRank || 0,
          viewSecond: group?.viewSecond || 0,
          viewSecondRank: group?.viewSecondRank || 0,
          goalCount: group?.goalCount || 0,
          goalCountRank: group?.goalCountRank || 0,
          goalRate: group?.goalRate || 0,
          goalRateRank: group?.goalRateRank || 0,
          clickCount: group?.clickCount || 0,
          clickCountRank: group?.clickCountRank || 0,
          clickRate: group?.clickRate || 0,
          clickRateRank: group?.clickRateRank || 0,
        }
      }),
      downloadFilename: history.download_filename,
    }
  })
}

export const makeContentGroupErrorMessage = (
  data: ContentGroupReportData,
  filterStates: CustomFilterState[],
): string => {
  if (data.contentGroups.length > 0) {
    return ''
  }
  if (filterStates.length > 0) {
    return 'ご指定の条件に一致するデータはありません。条件を変えて再度検索してください。'
  }
  return '検索対象期間にはデータがありません。'
}
