import * as React from 'react'
import { addDays, startOfMonth, endOfMonth, addMonths, add } from 'date-fns'
import { request } from '../../util/request'
import { getDateStringYM, getDateYYYYMMDDhhmi, isSameOrAfterByDay, isSameOrBeforeByDay } from '../../util/Date'
import { ContentSegmentationHistoriesData } from '../../util/Response'
import { DeviceTypeForAPI } from '../../util/hooks/useDeviceType'
import { NarrowDown } from './narrowDown'
import { CaptureHistoryItem } from '../../components/grid/CaptureHistoryGridItem'
import { OptionProps } from '../../components/common/Select'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

export const StatusType = {
  wait: 1, // 実行待ち
  success: 3, // 成功
  error: 4, // エラー
  skip: 7, // スキップ
}

// 処理ステップ
export const captureHistoryStep = {
  none: 0,
  confirm: 1, // 確認
  complete: 2, // 完了
}

export interface State {
  readonly loading: boolean
  readonly reload: boolean
  readonly projectId: string
  readonly urlId: number
  readonly pageLayout: DeviceTypeForAPI
  readonly items: CaptureHistoryItem[]
  readonly captureCount: number // キャプチャ数
  readonly caputureRemaining: number // キャプチャ残数
  readonly selectedId: number
  readonly monthOptions: OptionProps[]
  readonly requestMonth: string // リクエスト月
  readonly requestCondition: number // リクエスト月選択値
  readonly pageCount: number // ページ数
  readonly currentPage: number // コンテンツの選択中のページ
  readonly itemCount: number // 総アイテム数
  readonly dispCount: number // 表示アイテム数
  readonly selectedItem: number // ページネーションに使用する変数
  readonly isPageChange: boolean // ページ切り替えフラグ
  readonly isGridScroll: boolean
  readonly divRef: React.RefObject<HTMLDivElement> // 件数変更時のスクロール用
  readonly errorMessage?: string

  readonly search: {
    readonly disabled: boolean // 検索可能か
    readonly isSearch: boolean // 検索状態
    readonly searchText: string // 検索文字列
    readonly status: boolean[] // 検索オプションステータス
    readonly tempStatus: boolean[] // 退避用検索オプションステータス
    readonly text: string // TODO:検索オプション一時対応用
    readonly tempText: string // TODO:検索オプション一時対応用
    readonly openNarrowDown: boolean // 検索オプション開閉
  }

  // キャプチャ履歴用パラメータ
  readonly history: {
    readonly step: number
  }
}

export class Actions {
  narrowDown: NarrowDown

  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {
    this.narrowDown = new NarrowDown(state, setState)
  }

  getApiPath = (
    projectId: string,
    searchText: string,
    status: boolean[],
    text: string,
    disp: number,
    offset: number,
    date: string,
  ) => {
    let param = `/api/projects/${projectId}/content_segmentation/histories/?limit=${disp}&offset=${offset}&yearmonth=${date}`
    // text優先
    if (text) {
      param += `&page=${text}`
    } else if (searchText && text === '') {
      param += `&page=${searchText}`
    }

    if (status[0]) {
      param += `&status=1`
    }
    if (status[1]) {
      param += `&status=3`
    }
    if (status[2]) {
      param += `&status=4`
    }
    if (status[3]) {
      param += `&status=7`
    }

    return param
  }

  fetch = async (
    projectId: string,
    searchText: string,
    status: boolean[],
    text: string,
    disp: number,
    reportDaysLimit: number,
  ) => {
    try {
      const monthString = getDateStringYM(new Date(), '')
      const histories: ContentSegmentationHistoriesData = await request(
        'GET',
        this.getApiPath(projectId, searchText, status, text, disp, 0, monthString),
        true,
      )

      // リクエスト月のリストを作成
      const limit = addDays(new Date(), -reportDaysLimit)
      // 月初と月末
      let start = startOfMonth(new Date())
      let end = endOfMonth(new Date())
      let index = 1
      let options: OptionProps[] = []
      while (1) {
        const month = add(new Date(), { months: -(index - 1) })
        options.push({ label: getDateStringYM(month), value: index })

        // start以上end以下となればその月に含まれる
        if (isSameOrAfterByDay(limit, start) && isSameOrBeforeByDay(limit, end)) {
          break
        }

        // 先月
        start = startOfMonth(addMonths(start, -1))
        end = endOfMonth(addMonths(end, -1))
        ++index
      }

      let errorMessage = ''
      if (histories.count === 0) {
        errorMessage = '検索対象期間にはデータがありません。'

        // 検索条件がある場合
        if (status.filter((state) => state).length > 0 || text || searchText) {
          errorMessage = 'ご指定の条件に一致するデータはありません。条件を変えて再度検索してください。'
        }
      }

      this.setState({
        ...this.state,
        loading: false,
        projectId: projectId,
        items:
          histories.count === 0
            ? []
            : histories.results.map((history) => {
                const pcHistory = history.pc_cs_history
                const mobileHistory = history.mobile_cs_history
                const pcPageHistory = pcHistory === null ? undefined : pcHistory.page_history
                const mobilePageHistory = mobileHistory === null ? undefined : mobileHistory.page_history
                return {
                  id: history.id,
                  page: history.url_value,
                  title: history.title,
                  date: getDateYYYYMMDDhhmi(history.requested_at),
                  mobileStatus: mobileHistory === null ? '' : mobileHistory.status_name,
                  pcStatus: pcHistory === null ? '' : pcHistory.status_name,
                  mobileCompletionDate: mobilePageHistory ? getDateYYYYMMDDhhmi(mobilePageHistory.created_at) : '',
                  pcCompletionDate: pcPageHistory ? getDateYYYYMMDDhhmi(pcPageHistory.created_at) : '',
                  mobilePageId: mobileHistory === null ? '' : mobileHistory.page_id,
                  pcPageId: pcHistory === null ? '' : pcHistory.page_id,
                  mobileCsHistory: {
                    errorType: mobileHistory?.error_type || null,
                    responseErrorHttpStatusCode: mobileHistory?.response_error_http_status_code || null,
                  },
                  pcCsHistory: {
                    errorType: pcHistory?.error_type || null,
                    responseErrorHttpStatusCode: pcHistory?.response_error_http_status_code || null,
                  },
                  urlId: history.url_id,
                }
              }),
        monthOptions: options,
        requestMonth: monthString,
        requestCondition: 1,
        captureCount: histories.page_capture_count,
        caputureRemaining: histories.page_capture_remaining === null ? -1 : histories.page_capture_remaining,
        pageCount: Math.ceil(histories.count / disp),
        currentPage: 0,
        itemCount: histories.count,
        dispCount: disp,
        search: {
          ...this.state.search,
          searchText: searchText,
          isSearch: searchText === '' ? false : true,
          status: status,
          text: text,
        },
        errorMessage: errorMessage,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        errorMessage: typeof e === 'string' ? e : 'キャプチャ履歴の取得に失敗しました。',
      })
    }
  }

  refetch = async (disp: number) => {
    try {
      const histories: ContentSegmentationHistoriesData = await request(
        'GET',
        this.getApiPath(
          this.state.projectId,
          this.state.search.searchText,
          this.state.search.status,
          this.state.search.text,
          disp,
          0,
          this.state.requestMonth,
        ),
        true,
      )

      let errorMessage = ''
      if (histories.count === 0) {
        errorMessage = '検索対象期間にはデータがありません。'

        // 検索条件がある場合
        if (
          this.state.search.status.filter((state) => state).length > 0 ||
          this.state.search.text ||
          this.state.search.searchText
        ) {
          errorMessage = 'ご指定の条件に一致するデータはありません。条件を変えて再度検索してください。'
        }
      }

      this.setState({
        ...this.state,
        reload: false,
        items:
          histories.count === 0
            ? []
            : histories.results.map((history) => {
                const pcHistory = history.pc_cs_history
                const mobileHistory = history.mobile_cs_history
                const pcPageHistory = pcHistory === null ? undefined : pcHistory.page_history
                const mobilePageHistory = mobileHistory === null ? undefined : mobileHistory.page_history
                return {
                  id: history.id,
                  page: history.url_value,
                  title: history.title,
                  date: getDateYYYYMMDDhhmi(history.requested_at),
                  mobileStatus: mobileHistory === null ? '' : mobileHistory.status_name,
                  pcStatus: pcHistory === null ? '' : pcHistory.status_name,
                  mobileCompletionDate: mobilePageHistory ? getDateYYYYMMDDhhmi(mobilePageHistory.created_at) : '',
                  pcCompletionDate: pcPageHistory ? getDateYYYYMMDDhhmi(pcPageHistory.created_at) : '',
                  mobilePageId: mobileHistory === null ? '' : mobileHistory.page_id,
                  pcPageId: pcHistory === null ? '' : pcHistory.page_id,
                  mobileCsHistory: {
                    errorType: mobileHistory?.error_type || null,
                    responseErrorHttpStatusCode: mobileHistory?.response_error_http_status_code || null,
                  },
                  pcCsHistory: {
                    errorType: pcHistory?.error_type || null,
                    responseErrorHttpStatusCode: pcHistory?.response_error_http_status_code || null,
                  },
                  urlId: history.url_id,
                }
              }),
        captureCount: histories.page_capture_count,
        caputureRemaining: histories.page_capture_remaining === null ? -1 : histories.page_capture_remaining,
        pageCount: Math.ceil(histories.count / disp),
        currentPage: 0,
        itemCount: histories.count,
        dispCount: disp,
        errorMessage: errorMessage,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        reload: false,

        errorMessage: typeof e === 'string' ? e : 'キャプチャ履歴の取得に失敗しました。',
      })
    }
  }

  fetchNextPage = async () => {
    try {
      const histories: ContentSegmentationHistoriesData = await request(
        'GET',
        this.getApiPath(
          this.state.projectId,
          this.state.search.searchText,
          this.state.search.status,
          this.state.search.text,
          this.state.dispCount,
          this.state.selectedItem * this.state.dispCount,
          this.state.requestMonth,
        ),
        true,
      )

      this.setState({
        ...this.state,
        isPageChange: false,
        isGridScroll: true,
        items:
          histories.count === 0
            ? []
            : histories.results.map((history) => {
                const pcHistory = history.pc_cs_history
                const mobileHistory = history.mobile_cs_history
                const pcPageHistory = pcHistory === null ? undefined : pcHistory.page_history
                const mobilePageHistory = mobileHistory === null ? undefined : mobileHistory.page_history
                return {
                  id: history.id,
                  page: history.url_value,
                  title: history.title,
                  date: getDateYYYYMMDDhhmi(history.requested_at),
                  mobileStatus: mobileHistory === null ? '' : mobileHistory.status_name,
                  pcStatus: pcHistory === null ? '' : pcHistory.status_name,
                  mobileCompletionDate: mobilePageHistory ? getDateYYYYMMDDhhmi(mobilePageHistory.created_at) : '',
                  pcCompletionDate: pcPageHistory ? getDateYYYYMMDDhhmi(pcPageHistory.created_at) : '',
                  mobilePageId: mobileHistory === null ? '' : mobileHistory.page_id,
                  pcPageId: pcHistory === null ? '' : pcHistory.page_id,
                  mobileCsHistory: {
                    errorType: mobileHistory?.error_type || null,
                    responseErrorHttpStatusCode: mobileHistory?.response_error_http_status_code || null,
                  },
                  pcCsHistory: {
                    errorType: pcHistory?.error_type || null,
                    responseErrorHttpStatusCode: pcHistory?.response_error_http_status_code || null,
                  },
                  urlId: history.url_id,
                }
              }),
        captureCount: histories.page_capture_count,
        caputureRemaining: histories.page_capture_remaining === null ? -1 : histories.page_capture_remaining,
        pageCount: Math.ceil(histories.count / this.state.dispCount),
        currentPage: this.state.selectedItem,
        itemCount: histories.count,
        errorMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        errorMessage: typeof e === 'string' ? e : 'キャプチャ履歴の取得に失敗しました。',
      })
    }
  }

  // 表示件数変更
  onDispCountChange = () => {
    // 先頭までスクロール
    this.state.divRef?.current?.scrollIntoView({
      block: 'start',
    })
    this.setState({ ...this.state, reload: true })
  }

  // 検索文字列入力
  onUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        searchText: event.currentTarget.value,
      },
    })
  }

  // 通常検索
  search = () => {
    this.setState({
      ...this.state,
      reload: true,
      search: {
        ...this.state.search,
        isSearch: this.state.search.searchText.length === 0 ? false : true,
      },
    })
  }

  // 検索クリア
  clear = () => {
    this.setState({
      ...this.state,
      reload: true,
      search: {
        ...this.state.search,
        searchText: '',
        isSearch: false,
      },
    })
  }

  // リクエスト月変更
  onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!event.currentTarget.value) {
      return
    }
    let str = event.target.options[event.target.selectedIndex].label
    this.setState({
      ...this.state,
      reload: true,
      requestMonth: str.replace('/', ''), // 不要部分を取り除く
      requestCondition: Number(event.currentTarget.value),
    })
  }

  // ページ切り替え後のグリッドスクロール
  gridScroll = () => {
    // 先頭までスクロール
    this.state.divRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    this.setState({ ...this.state, isGridScroll: false })
  }

  // グリッドの表示ページを変更
  onPageChange = async (selectedItem: { selected: number }) => {
    // ページが切り替わっていない場合は処理しない
    if (this.state.selectedItem === selectedItem.selected) {
      return
    }

    this.setState({
      ...this.state,
      isPageChange: true,
      selectedItem: selectedItem.selected,
    })
  }

  // モーダル開く
  onOpen = (urlId: string, pageLayout: DeviceTypeForAPI) => {
    this.setState({
      ...this.state,
      urlId: Number(urlId),
      pageLayout,
      history: {
        ...this.state.history,
        step: captureHistoryStep.confirm,
      },
    })
  }

  // 閉じる
  onClose = () => {
    this.setState({
      ...this.state,
      urlId: 0,
      history: {
        ...this.state.history,
        step: captureHistoryStep.none,
      },
    })
  }

  // 手動実行
  onCapture = async (success: boolean) => {
    if (success) {
      this.setState({
        ...this.state,
        history: {
          ...this.state.history,
          step: captureHistoryStep.complete,
        },
      })
    } else {
      // 失敗したらモーダルはそのまま
      this.setState({
        ...this.state,
        history: {
          ...this.state.history,
        },
      })
    }
  }

  onComplete = () => {
    this.setState({
      ...this.state,
      reload: true,
      history: {
        ...this.state.history,
        step: captureHistoryStep.none,
      },
    })
  }
}

export const initialState: State = {
  loading: true,
  reload: false,
  projectId: '',
  urlId: 0,
  pageLayout: 1,
  items: [],
  captureCount: 0,
  caputureRemaining: 0,
  selectedId: 0,
  monthOptions: [],
  requestMonth: '',
  requestCondition: 0,
  pageCount: 0,
  currentPage: 0,
  itemCount: 0,
  dispCount: 0,
  selectedItem: 0,
  isPageChange: false,
  isGridScroll: false,
  divRef: React.createRef<HTMLDivElement>(),
  search: {
    disabled: false,
    isSearch: false,
    searchText: '',
    status: [false, false, false, false],
    tempStatus: [false, false, false, false],
    text: '', // TODO:一時対応用
    tempText: '', // TODO:一時対応用
    openNarrowDown: false,
  },
  history: {
    step: captureHistoryStep.none,
  },
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
