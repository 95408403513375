import * as React from 'react'
import styled from 'styled-components'
import { usePageState } from './state/CreateGcpAccountState'
import { colors, layout } from '../../styleConstants'
import { Modal } from '../common/Modal'
import { AdminNormalButton, CancelButton } from '../common/Button'
import { Input } from '../common/Input'
import { Spinner } from '../common/Spinner'
import { Warning } from '@styled-icons/icomoon/Warning'
import { CheckCircle } from 'styled-icons/boxicons-regular'

interface Props {
  readonly opened: boolean
  readonly disabled?: boolean
  readonly keyOnly?: boolean
  readonly gcpServiceAccountId?: number
  readonly onCancel: () => void
  readonly onComplete?: (gcpId: number) => void // 作成完了
}

export function CreateGcpAccount(props: Props) {
  const { opened, disabled, keyOnly, gcpServiceAccountId, onCancel, onComplete } = props
  const {
    state: { creating, complete, gcpId, description, errorMessage },
    actions,
  } = usePageState()

  React.useEffect(() => {
    if (creating) {
      // キー作成のみ行う
      if (keyOnly) {
        actions.createGcpAccountKey(gcpServiceAccountId!)
      }
      // アカウント作成
      else {
        actions.createGcpAccount()
      }
    }
  }, [creating])

  return (
    <Modal isOpen={opened} onClose={onCancel} title={keyOnly ? 'キーを追加' : 'GCPサービスアカウントの作成'}>
      <Box>
        {/* 作成中表示 */}
        {creating && (
          <LoadingContainer>
            <LoadingLabel>Creating</LoadingLabel>
            <Spinner type="Moon" loading={true} width={250} height={25} />
          </LoadingContainer>
        )}
        {/* エラーが発生している場合 */}
        {!creating && errorMessage && (
          <Contents style={{ justifyContent: 'center' }}>
            <LoadingLabel style={{ color: `${colors.error}` }}>{errorMessage}</LoadingLabel>
          </Contents>
        )}
        {/* 作成前 */}
        {!creating && !complete && !errorMessage && (
          <Contents>
            <Message>
              {keyOnly
                ? 'キーファイルをダウンロードしますか？'
                : 'GCPサービスアカウントを作成して、キーファイルをダウンロードしますか？'}
            </Message>

            <CautionBox>
              <Warning size={30} />
              <div style={{ marginLeft: '1rem' }}>
                <Message>{'秘密鍵を含むファイルをダウンロードします。'}</Message>
                <Message>{'この鍵を紛失すると復元できなくなるため、ファイルは大切に保管してください。'}</Message>
              </div>
            </CautionBox>

            {/* 説明文入力 */}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
              <div style={{ width: '40px', fontWeight: 'bold', fontSize: '1.1rem', marginRight: '0.5rem' }}>
                {'説明'}
              </div>
              <DesignedInput
                type="text"
                value={description}
                placeholder="キーの説明を入力してください"
                onChange={actions.updateDescription}
              />
            </div>
          </Contents>
        )}
        {/* 作成完了 */}
        {!creating && complete && !errorMessage && (
          <Contents>
            <Message style={{ fontWeight: 'bold', fontSize: '1.2rem', margin: 'auto' }}>
              {keyOnly ? 'サービスアカウントのキーを追加しました。' : 'サービスアカウントを追加しました。'}
            </Message>
            <Icon />
          </Contents>
        )}
      </Box>

      <Buttons>
        {/* エラーの有無でボタン変更 */}
        {errorMessage && (
          <CancelButton style={{ visibility: creating ? 'hidden' : 'visible' }} onClick={onCancel}>
            閉じる
          </CancelButton>
        )}
        {/* 作成前 */}
        {!errorMessage && !complete && (
          <ConversionButtons style={{ visibility: creating ? 'hidden' : 'visible' }}>
            <CancelButton onClick={onCancel}>キャンセル</CancelButton>
            <AdminNormalButton disabled={disabled} onClick={actions.onCreate}>
              {keyOnly ? 'ダウンロード' : '作成'}
            </AdminNormalButton>
          </ConversionButtons>
        )}
        {/* エラーなく作成完了 */}
        {!errorMessage && complete && (
          <CancelButton style={{ visibility: creating ? 'hidden' : 'visible' }} onClick={() => onComplete!(gcpId)}>
            閉じる
          </CancelButton>
        )}
      </Buttons>
    </Modal>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const LoadingLabel = styled.div`
  color: ${colors.lightBlue};
  font-size: 2rem;
  font-weight: ${layout.boldFontWeight};
  margin-right: 0.5rem;
`

const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Message = styled.div`
  & + & {
    margin-top: 0.5rem;
  }
`

const CautionBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: ${colors.error};
  border: 2px solid ${colors.error};
  border-radius: 20px;
  background-color: ${colors.white};
  padding: 1.5rem 2rem;
  margin-top: 2rem;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const ConversionButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 40px;
`

const Icon = styled(CheckCircle).attrs({
  size: '64',
  color: `${colors.lightBlue}`,
})`
  display: block;
  margin: 2rem auto;
`
