import { isLocalOrDevelopment } from './util/getEnv'
import { addDays } from 'date-fns'

export function getBaseURL() {
  switch (process.env.API_ENV) {
    case 'local':
      return 'http://localhost:8000'
    case 'development':
      return 'https://infra.contentanalytics.io'
    case 'staging':
      return 'https://infra.contentanalytics.io'
    case 'production':
      return 'https://infra.contentanalytics.jp'
    case 'mock':
    case 'test':
      return ''
    default:
      throw new Error('Invalid environment')
  }
}

export function getGtmId(): string {
  switch (process.env.API_ENV) {
    case 'development':
    case 'staging':
    case 'production':
      return 'GTM-NR24S2LS'
    default:
      return ''
  }
}

const IS_TODAY_INCLUDED_IN_CALENDAR = isLocalOrDevelopment()
export const CALENDER_INIT_START_DATE = addDays(new Date(), IS_TODAY_INCLUDED_IN_CALENDAR ? -6 : -7)
export const CALENDER_INIT_END_DATE = addDays(new Date(), IS_TODAY_INCLUDED_IN_CALENDAR ? 0 : -1)
