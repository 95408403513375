import * as React from 'react'
import styled from 'styled-components'
import { DEVICE_TYPE, DeviceType } from '../../../util/hooks/useDeviceType'
import { Mobile } from '@styled-icons/boxicons-regular/Mobile'
import { CaretDown } from '@styled-icons/boxicons-regular/CaretDown'
import { Desktop } from '@styled-icons/boxicons-regular/Desktop'
import { colors } from '../../../styleConstants'

interface Props {
  readonly selectedDevice: DeviceType
  readonly onChangeDevice: (deviceType: DeviceType) => void
}

export function HeaderDeviceTypeButtons({ selectedDevice, onChangeDevice }: Props) {
  const [isPopupVisible, setIsPopupVisible] = React.useState(false)

  const handleMouseEnter = () => {
    setIsPopupVisible(true)
  }

  const handleMouseLeave = () => {
    setIsPopupVisible(false)
  }

  return (
    <ButtonContainer onMouseLeave={handleMouseLeave}>
      <ButtonIcon onMouseEnter={handleMouseEnter}>
        {selectedDevice === DEVICE_TYPE.MOBILE && (
          <>
            <Mobile size={16} color={colors.white} />
            <ButtonIconText>Mobile</ButtonIconText>
            <CaretDown size={16} color={colors.white} />
          </>
        )}
        {selectedDevice === DEVICE_TYPE.PC && (
          <>
            <Desktop size={16} color={colors.white} />
            <ButtonIconText>PC</ButtonIconText>
            <CaretDown size={16} color={colors.white} />
          </>
        )}
      </ButtonIcon>

      {isPopupVisible && (
        <ButtonPopup>
          <ButtonPopupButton onClick={() => onChangeDevice(DEVICE_TYPE.MOBILE)}>Mobile版表示</ButtonPopupButton>
          <ButtonPopupButton onClick={() => onChangeDevice(DEVICE_TYPE.PC)}>PC版表示</ButtonPopupButton>
        </ButtonPopup>
      )}
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  position: relative;
`

const ButtonIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-width: 110px;
  height: 30px;
  background-color: ${colors.contentBlue.blue6};
  border: 1px solid ${colors.white};
  cursor: pointer;
  ${ButtonContainer}:hover & {
    background: ${colors.contentBlue.blue5};
  }
`

const ButtonIconText = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.white};
`

const ButtonPopup = styled.div`
  position: absolute;
  top: calc(100%);
  left: 0;
  z-index: 1;
  padding: 8px 0;
  background: ${colors.white};
`
const ButtonPopupButton = styled.a`
  display: block;
  padding: 8px 16px;
  width: 160px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  &:hover {
    background: ${colors.gray100};
  }
`
