import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { ContextType, useContextState } from './state'

export const FunnelReportContext = React.createContext<ContextType>(null as any)

interface Props extends RouteComponentProps {
  readonly children?: React.ReactNode | React.ReactNode[]
}

/**
 * ファネルレポートの state情報 をグローバルで使用できるよう Provider で定義する
 *
 * @param {Object} props - The props object containing the children component.
 * @param {React.ReactNode} props.children - The child component(s) to be wrapped.
 */
export function FunnelReportProvider({ children }: Props) {
  const props = useContextState()

  return <FunnelReportContext.Provider value={props}>{children}</FunnelReportContext.Provider>
}
