import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../../styleConstants'
import { CloseSolid } from '@styled-icons/zondicons/CloseSolid'
import { Spinner } from './Spinner'
interface Props {
  readonly children: React.ReactNode
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly title: string
  readonly isLoading?: boolean
  readonly width?: number
}

export const Modal = ({ children, isOpen, onClose, title, isLoading = false, width = 700 }: Props) => {
  if (!isOpen) return null
  return (
    <Backdrop>
      <ModalWrapper width={width}>
        {!isLoading && <StyledCloseSolid onClick={onClose} />}
        <Header>{title}</Header>
        <ScrollArea>
          <Container>
            {isLoading && (
              <LoadingContainer>
                <LoadingLabel>Loading</LoadingLabel>
                <Spinner type="Moon" loading={true} width={250} height={25} />
              </LoadingContainer>
            )}
            {children}
          </Container>
        </ScrollArea>
      </ModalWrapper>
    </Backdrop>
  )
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${layout.modalBackdropZIndex};
`

const ModalWrapper = styled.div<{
  width: number
}>`
  position: relative;
  width: ${({ width }) => width}px;
  z-index: ${layout.modalZIndex};
`

const StyledCloseSolid = styled(CloseSolid)`
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${colors.white};
  color: ${colors.gray500};
  cursor: pointer;
  z-index: ${layout.modalCloseButtonZIndex};
`

const ScrollArea = styled.div`
  max-height: calc(100vh - 30px);
  overflow-y: auto;
`

const Header = styled.div`
  background-color: ${colors.lightBlue};
  color: ${colors.white};
  padding: 12px 32px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  border-radius: 2px 2px 0 0;
`

const Container = styled.div`
  position: relative;
  background-color: ${colors.white};
  padding: 24px 32px 32px;
  text-align: left;
  border-radius: 0 0 2px 2px;
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: ${colors.white};
  z-index: ${layout.modalLoadingZIndex};
`

const LoadingLabel = styled.div`
  color: ${colors.lightBlue};
  font-size: 2rem;
  font-weight: ${layout.boldFontWeight};
  margin-right: 0.5rem;
`

export const ModalContentWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`

export const ModalHeadText = styled.p`
  margin: 0;
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black2};
`
