// オブジェクトの配列を指定したkeyでソートし、新しい配列として返す デフォルトは降順
export const sortBy = <T extends { [key: string]: any }>(
  list: Array<T>,
  key: keyof T,
  order: SortOrder = SORT_ORDER.DESC,
) => {
  return [...list].sort((a, b) => {
    if (order === SORT_ORDER.ASC) {
      return a[key] > b[key] ? 1 : -1
    } else {
      return a[key] < b[key] ? 1 : -1
    }
  })
}

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
} as const

export type SortOrder = typeof SORT_ORDER[keyof typeof SORT_ORDER]
