import * as React from 'react'
import styled from 'styled-components'
import { State, Actions } from './state'
import { ProjectStep } from './project'
import { MemberStep } from './member'
import { InactiveStep } from './inactive'
import { InviteStep } from './invite'
import { IpLimitStep } from './ipLimit'
import { DataExportStep } from './dataExport'
import { Role, RoleLabel } from '../../util/Authority'
import { colors } from '../../styleConstants'
import { AdminNormalButton, CancelButton, NormalButton } from '../../components/common/Button'
import { Select } from '../../components/common/Select'
import { Input } from '../../components/common/Input'
import { Link } from '../../components/common/Link'
import { SendInviteModal } from '../../components/modal/SendInviteModal'
import { AuthorityModal } from '../../components/modal/AuthorityModal'
import { InviteConfirmModal } from '../../components/modal/InviteConfirmModal'
import { ConfirmModal } from '../../components/common/ConfirmModal'
import { Modal, ModalButtonWrapper, ModalHeadText, ModalContentWrapper } from '../../components/common/Modal'
import { IpLimitEdit } from '../../components/modal/IpLimitEdit'
import { ProjectModal } from '../../components/modal/ProjectModal'
import { CreateGcpAccount } from '../../components/modal/CreateGcpAccount'
import { DataExportSetting } from '../../components/modal/DataExportSetting'

interface Props {
  readonly state: State
  readonly actions: Actions
}

const INVITE_ADMIN_MEMBER_MODAL_TITLE = 'メンバーを招待する'

export function AdminModals(props: Props) {
  const { selectedUserName, selectedUserRole, project, member, invite, inactive, ipLimit, dataExport } = props.state
  return (
    <div>
      {/* プロジェクト削除 */}
      {project.step === ProjectStep.confirm && (
        <Modal
          isOpen={project.step === ProjectStep.confirm}
          onClose={props.actions.project.onCancel}
          title="プロジェクトの削除"
          width={450}
        >
          <ModalContentWrapper>
            <ModalHeadText>
              全てのデータが失われることになります。
              <br />
              削除してよろしいですか？
            </ModalHeadText>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.project.onCancel}>キャンセル</CancelButton>
            <NormalButton onClick={props.actions.project.onApply}>削除</NormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* プロジェクト新規追加 */}
      {project.step === ProjectStep.createProject && (
        <ProjectModal
          opened={project.step === ProjectStep.createProject}
          onCreate={props.actions.project.onCreateProject}
          onClose={props.actions.project.onCancelCreate}
        />
      )}
      {project.step === ProjectStep.createComplete && (
        <ConfirmModal
          isOpen={project.step === ProjectStep.createComplete}
          title="新規プロジェクトの作成"
          children={
            <>
              <div>プロジェクトを追加しました。</div>
              <Link href="/admin/captureLimit" target="_blank" rel="noopener noreferrer" style={{ marginTop: '1rem' }}>
                こちらからページキャプチャの取得上限設定を行ってください。
              </Link>
              <div style={{ marginTop: '1rem' }}>ページキャプチャの取得上限設定を行うことで計測が開始されます。</div>
            </>
          }
          onClose={props.actions.project.onCreateComplete}
        />
      )}

      {/* 信頼できるIPアドレス追加 */}
      {ipLimit.step === IpLimitStep.create && (
        <IpLimitEdit
          created={true}
          opened={ipLimit.step === IpLimitStep.create}
          disabled={ipLimit.disabled}
          address={ipLimit.address}
          memo={ipLimit.memo}
          updateAddress={props.actions.ipLimit.updateAddress}
          updateMemo={props.actions.ipLimit.updateMemo}
          onCancel={props.actions.ipLimit.onAddCancel}
          onApply={props.actions.ipLimit.onAddApply}
        />
      )}

      {/* 信頼できるIPアドレス編集 */}
      {ipLimit.step === IpLimitStep.edit && (
        <IpLimitEdit
          created={false}
          opened={ipLimit.step === IpLimitStep.edit}
          disabled={ipLimit.disabled}
          address={ipLimit.editAddress}
          memo={ipLimit.editMemo}
          updateAddress={props.actions.ipLimit.updateEditAddress}
          updateMemo={props.actions.ipLimit.updateEditMemo}
          onCancel={props.actions.ipLimit.onEditCancel}
          onApply={props.actions.ipLimit.onEditApply}
        />
      )}

      {/* 信頼できるIPアドレス削除 */}
      {ipLimit.step === IpLimitStep.deleteComfirm && (
        <Modal
          isOpen={ipLimit.step === IpLimitStep.deleteComfirm}
          width={450}
          title="信頼できるIPアドレスの削除"
          onClose={props.actions.ipLimit.onDeleteCancel}
        >
          <ModalContentWrapper>
            <div style={{ fontWeight: 'bold' }}>{ipLimit.address}</div>
            <div style={{ marginTop: '1rem' }}>上記のIPアドレスを削除します。</div>
            <div>削除してよろしいですか？</div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.ipLimit.onDeleteCancel}>キャンセル</CancelButton>
            <AdminNormalButton onClick={props.actions.ipLimit.onDeleteApply}>削除</AdminNormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* ユーザーの招待 */}
      {member.step === MemberStep.sendInvite && (
        <SendInviteModal
          opened={member.step === MemberStep.sendInvite}
          email={member.email}
          disabled={member.emailDisabled}
          updateEmail={props.actions.member.updateInviteEmail}
          onCancel={props.actions.member.onCancelModal}
          onApply={props.actions.member.onApplySendInvite}
          title={INVITE_ADMIN_MEMBER_MODAL_TITLE}
        />
      )}
      {member.step === MemberStep.setRole && (
        <AuthorityModal
          opened={member.step === MemberStep.setRole}
          role={member.tempRole}
          projects={project.tempProjects}
          inviteDisabled={member.inviteDisabled}
          onSelect={props.actions.member.selectRole}
          onChange={props.actions.member.changeRole}
          onBack={props.actions.member.onBackSendInvite}
          onCancel={props.actions.member.onCancelModal}
          onApply={props.actions.member.onApplyRole}
          title={INVITE_ADMIN_MEMBER_MODAL_TITLE}
        />
      )}
      {member.step === MemberStep.inviteConfirm && (
        <InviteConfirmModal
          opened={member.step === MemberStep.inviteConfirm}
          email={member.email}
          role={member.tempRole}
          projects={project.tempProjects}
          onBack={props.actions.member.onBackRole}
          onCancel={props.actions.member.onCancelModal}
          onApply={props.actions.member.onApplyInviteConfirm}
          title={INVITE_ADMIN_MEMBER_MODAL_TITLE}
        />
      )}

      {/* 権限の変更 */}
      {member.step === MemberStep.editExec && (
        <Modal
          isOpen={member.step === MemberStep.editExec}
          width={600}
          title="権限の変更"
          onClose={props.actions.member.onCancelEditUser}
        >
          <ModalContentWrapper>
            <div style={{ margin: '0.5rem 0 2rem' }}>権限を選択して「変更する」ボタンを押してください。</div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <div style={{ color: `${colors.gray600}` }}>ユーザー名</div>
                <div style={{ marginTop: '0.3rem', fontWeight: 'bold', fontSize: '1.2rem' }}>{selectedUserName}</div>
              </div>
              <div>
                <DesignedSelect
                  options={[
                    { label: RoleLabel.User.member, value: Role.User.member },
                    { label: RoleLabel.User.admin, value: Role.User.admin },
                  ]}
                  defaultValue={selectedUserRole}
                  onChange={props.actions.member.onChangeEditUser}
                />
              </div>
            </div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.member.onCancelEditUser}>キャンセル</CancelButton>
            <AdminNormalButton disabled={member.editDisabled} onClick={props.actions.member.onApplyEditUser}>
              変更する
            </AdminNormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* ユーザーの削除 */}
      {member.step === MemberStep.deleteExec && (
        <Modal
          isOpen={member.step === MemberStep.deleteExec}
          width={450}
          title="組織メンバーの削除"
          onClose={props.actions.member.onCancelDeleteUser}
        >
          <ModalContentWrapper>
            <div style={{ fontWeight: 'bold' }}>{selectedUserName}</div>
            <div style={{ marginTop: '1rem' }}>上記のユーザーを組織から削除します。</div>
            <div>この操作は取り消せません。</div>
            <div>本当に削除しますか？</div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.member.onCancelDeleteUser}>キャンセル</CancelButton>
            <AdminNormalButton onClick={props.actions.member.onApplyDeleteUser}>削除</AdminNormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* 招待取り消し */}
      {invite.step === InviteStep.confirm && (
        <Modal
          isOpen={invite.step === InviteStep.confirm}
          width={450}
          title="招待の取り消し"
          onClose={props.actions.invite.onCancel}
        >
          <ModalContentWrapper>
            <div style={{ fontWeight: 'bold' }}>{selectedUserName}</div>
            <div style={{ marginTop: '1rem' }}>上記のユーザーの招待を取り消します。</div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.invite.onCancel}>キャンセル</CancelButton>
            <AdminNormalButton onClick={props.actions.invite.onApply}>実行</AdminNormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* ユーザーの復帰 */}
      {inactive.step === InactiveStep.confirm && (
        <Modal
          isOpen={inactive.step === InactiveStep.confirm}
          width={450}
          title="組織メンバーの復帰"
          onClose={props.actions.inactive.onCancel}
        >
          <ModalContentWrapper>
            <div style={{ fontWeight: 'bold' }}>{selectedUserName}</div>
            <div style={{ marginTop: '1rem' }}>上記のユーザーを組織に追加します。</div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.inactive.onCancel}>キャンセル</CancelButton>
            <AdminNormalButton onClick={props.actions.inactive.onApply}>復帰する</AdminNormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* GCPサービスアカウント作成とキーのダウンロード */}
      {dataExport.step === DataExportStep.createGcpAccount && (
        <CreateGcpAccount
          opened={dataExport.step === DataExportStep.createGcpAccount}
          onCancel={props.actions.dataExport.onCancelGcpAccount}
          onComplete={props.actions.dataExport.onCreateGcpAccountComplete}
        />
      )}

      {/* サービスアカウント削除 */}
      {dataExport.step === DataExportStep.deleteAccount && (
        <Modal
          isOpen={dataExport.step === DataExportStep.deleteAccount}
          width={550}
          title="サービスアカウントの削除"
          onClose={props.actions.dataExport.onCancel}
        >
          <ModalContentWrapper>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ marginBottom: '0.5rem' }}>サービスアカウント</div>
              <Message style={{ fontWeight: 'bold' }}>{dataExport.selectedString}</Message>
              <div style={{ marginTop: '2rem' }}>上記のサービスアカウントを削除すると、</div>
              <div style={{ marginTop: '0.3rem' }}>発行済みのキーと専用のデータ連携バケットが削除されるため、</div>
              <div style={{ marginTop: '0.3rem' }}>これまで出力されたファイルにアクセスできなくなります。</div>
              <div style={{ marginTop: '1rem' }}>削除してもよろしいですか？</div>
            </div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.dataExport.onCancel}>キャンセル</CancelButton>
            <AdminNormalButton onClick={props.actions.dataExport.onDeleteAccountApply}>削除</AdminNormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* エクスポート対象プロジェクトの追加 */}
      {dataExport.step === DataExportStep.addProject && (
        <Modal
          isOpen={dataExport.step === DataExportStep.addProject}
          width={600}
          title="エクスポート対象プロジェクトの追加"
          onClose={props.actions.dataExport.onCancel}
        >
          <ModalContentWrapper>
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '1rem', width: '100%' }}>
              <div>プロジェクトを選択してください</div>
            </div>
            <DesignedSelect
              options={dataExport.selectProjects}
              style={{ fontSize: '1rem', width: '100%', height: '40px' }}
              onChange={props.actions.dataExport.onChangeProject}
            />
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.dataExport.onCancel}>キャンセル</CancelButton>
            <AdminNormalButton onClick={props.actions.dataExport.onAddApply}>追加</AdminNormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* プロジェクト詳細 */}
      {dataExport.step === DataExportStep.infoProject && (
        <DataExportSetting
          opened={dataExport.step === DataExportStep.infoProject}
          projectName={dataExport.selectedString}
          gcsBucket={dataExport.gcsBucket}
          contentsMaster={dataExport.contentMaterData}
          logData={dataExport.logData}
          contentsTagsFileUrl={dataExport.contentsTagsFileUrl}
          reportDaysLimit={dataExport.reportDaysLimit}
          onCancel={props.actions.dataExport.onCloseProjectInfo}
        />
      )}

      {/* エクスポート対象プロジェクト削除 */}
      {dataExport.step === DataExportStep.deleteProject && (
        <Modal
          isOpen={dataExport.step === DataExportStep.deleteProject}
          width={550}
          title="エクスポート対象プロジェクトの削除"
          onClose={props.actions.dataExport.onCancel}
        >
          <ModalContentWrapper>
            <Message style={{ fontWeight: 'bold' }}>{dataExport.selectedString}</Message>
            <div style={{ marginTop: '1rem' }}>上記のエクスポート対象プロジェクトを削除してよろしいですか？</div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.dataExport.onCancel}>キャンセル</CancelButton>
            <AdminNormalButton onClick={props.actions.dataExport.onDeleteProjectApply}>削除</AdminNormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* キー作成 */}
      {dataExport.step === DataExportStep.addKey && (
        <CreateGcpAccount
          opened={dataExport.step === DataExportStep.addKey}
          keyOnly={true}
          gcpServiceAccountId={dataExport.gcpId}
          onCancel={props.actions.dataExport.onCancelGcpAccount}
          onComplete={props.actions.dataExport.onAddKeyComplete}
        />
      )}

      {/* キー詳細(説明変更) */}
      {dataExport.step === DataExportStep.infoKey && (
        <Modal
          isOpen={dataExport.step === DataExportStep.infoKey}
          width={600}
          title="キー詳細"
          onClose={props.actions.dataExport.onCancel}
        >
          <ModalContentWrapper>
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1rem', width: '100%' }}>
              <div>キーID</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '0.5rem', width: '100%' }}>
              <div>{dataExport.selectedString}</div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '2rem', width: '100%' }}>
              <div>説明</div>
            </div>
            <DesignedInput
              type="text"
              value={dataExport.editString}
              placeholder="キーの説明を入力してください"
              onChange={props.actions.dataExport.updateKeyDescription}
            />
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.dataExport.onCancel}>キャンセル</CancelButton>
            <AdminNormalButton disabled={dataExport.disabled} onClick={props.actions.dataExport.onChangeKeyApply}>
              保存
            </AdminNormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}

      {/* キー削除 */}
      {dataExport.step === DataExportStep.deleteKey && (
        <Modal
          isOpen={dataExport.step === DataExportStep.deleteKey}
          width={550}
          title="キーの削除"
          onClose={props.actions.dataExport.onCancel}
        >
          <ModalContentWrapper>
            <Message style={{ fontWeight: 'bold' }}>{dataExport.selectedString}</Message>
            <div style={{ marginTop: '1rem' }}>上記のキーを削除してよろしいですか？</div>
          </ModalContentWrapper>
          <ModalButtonWrapper>
            <CancelButton onClick={props.actions.dataExport.onCancel}>キャンセル</CancelButton>
            <AdminNormalButton onClick={props.actions.dataExport.onDeleteKeyApply}>削除</AdminNormalButton>
          </ModalButtonWrapper>
        </Modal>
      )}
    </div>
  )
}

const DesignedSelect = styled(Select)`
  width: 180px;
  height: 30px;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 30px;
`

const Message = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
`
