export class Percent {
  static parse(value: number): number {
    return value * 100 || 0
  }
}

// 秒から00:00の形式に変換する
export function toMs(seconds: number) {
  let ms = ''
  const m = ((seconds % 3600) / 60) | 0
  const s = seconds % 60

  ms = padZero(m) + ':' + padZero(s)
  return ms

  function padZero(time: number) {
    if (time < 10) {
      return '0' + time
    } else {
      return time
    }
  }
}

// rem から px数へ変更
export function convertRemToPx(rem: number) {
  const fontSize = getComputedStyle(document.documentElement).fontSize
  return rem * parseFloat(fontSize)
}

export function forceInt(value: string): number | undefined {
  if (value === '') return undefined

  try {
    return parseInt(value, 10)
  } catch (error) {
    return undefined
  }
}
