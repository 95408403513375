import * as React from 'react'
import { NarrowDown } from './narrowdown'
import { Search } from './search'
import { PageReportSortKey, PageReportSortState } from '../../components/grid/PageGridItem'
import { NarrowDownState } from '../../components/filter/PageReportNarrowDown'
import { AdvancedPageFilterError, INIT_SORT_STATE } from '../../util/hooks/api/usePageReport'
import { deepCopy } from '../../util/copy'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

export interface State {
  readonly initialized: boolean // ゴールAPIなど数個のAPI呼び出しが完了し、ページレポートAPIを呼び出す準備ができたか
  readonly loading: boolean // 画面がローディング中か
  readonly loadType: 'filter' | 'goal' | ''
  readonly sortState: PageReportSortState
  readonly page: number // コンテンツページ数
  readonly currentPage: number // コンテンツの選択中のページ
  readonly itemCount: number // 総アイテム数
  readonly dispCount: number // 表示アイテム数
  readonly openedNarrowDown: boolean // 絞り込み表示状態
  readonly disabledNarrowDown: boolean // 絞り込みボタンの有効化
  readonly searchText: string
  readonly isSearch: boolean // 検索されている状態
  readonly narrowDown: NarrowDownState[] // 絞り込み状態
  readonly narrowDownTemp: NarrowDownState[] // 絞り込み状態管理用
  readonly projectId: string
}

export class Actions {
  narrowdown: NarrowDown
  search: Search

  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {
    this.narrowdown = new NarrowDown(state, setState)
    this.search = new Search(state, setState)
  }

  initialize = async (
    projectId: string,
    disp: number,
    sortState: PageReportSortState,
    searchText: string,
    narrowDownState: NarrowDownState[],
    isSettingsCompleted: boolean,
  ) => {
    this.setState({ ...this.state, loading: true })

    // レポート表示の準備ができてない場合はここまで
    if (!isSettingsCompleted) {
      this.setState({
        ...this.state,
        loading: false,
      })
      return
    }

    this.setState({
      ...this.state,
      initialized: true,
      loadType: '',
      currentPage: 0,
      dispCount: disp,
      isSearch: !!searchText,
      projectId,
      sortState,
      searchText,
      narrowDown: narrowDownState,
    })
  }

  onSuccessFetchPageReports = (pageReportsDataCount: number) => {
    this.setState({
      ...this.state,
      loading: false,
      page: Math.ceil(pageReportsDataCount / this.state.dispCount),
      itemCount: pageReportsDataCount,
    })
  }

  onErrorFetchPageReports = () => {
    this.setState({
      ...this.state,
      loading: false,
    })
  }

  /**
   * 検索オプションのエラー情報をセットする
   *
   * @param {AdvancedPageFilterError[]} errors - The array of errors for each advanced page filter.
   * @returns {void}
   */
  setAdvancedPageFiltersError = (errors: AdvancedPageFilterError[]) => {
    const newValues = deepCopy(this.state.narrowDown)

    // narrowDownとerrorsのそれぞれのリストはDjangoの仕様上、要素の数・エラー箇所が一致しているため、indexでエラーをコピーする
    errors.forEach((err, index) => {
      newValues[index].error = err
    })

    this.setState({
      ...this.state,
      narrowDown: newValues,
      narrowDownTemp: deepCopy(newValues),
      // 入力エラーを確認してもらうためModalを再度開く
      openedNarrowDown: true,
    })
  }

  // ソートを実行
  onSort = (key: PageReportSortKey) => {
    // 一度全ての項目のソート状態をリセットする
    for (const e in this.state.sortState) {
      const elem = e as PageReportSortKey
      if (key !== elem) {
        this.state.sortState[elem] = 'none'
      }
    }

    if (this.state.sortState[key] === 'down') {
      this.state.sortState[key] = 'up'
    } else {
      this.state.sortState[key] = 'down'
    }
    this.setState({
      ...this.state,
      currentPage: 0,
    })
    return this.state.sortState
  }

  // グリッドの表示ページを変更
  onPaginateChange = async (selected: number) => {
    // ページが切り替わっていない場合は処理しない
    if (this.state.currentPage === selected) return
    this.setState({ ...this.state, currentPage: selected })
  }

  // 表示件数変更
  onDispCountChange = (dispCount: number) => {
    this.setState({
      ...this.state,
      dispCount: dispCount,
      currentPage: 0,
    })
  }

  // 表示デバイス切り替え
  changeDevice = () => {
    this.setState({
      ...this.state,
      currentPage: 0,
    })
  }

  resetPaginate = () => {
    this.setState({
      ...this.state,
      currentPage: 0,
    })
  }
}

const initialState: State = {
  initialized: false,
  loading: true,
  loadType: '',
  sortState: INIT_SORT_STATE,
  page: 1,
  currentPage: 0,
  itemCount: 0,
  dispCount: 20,
  openedNarrowDown: false,
  disabledNarrowDown: true,
  searchText: '',
  isSearch: false,
  narrowDown: [],
  narrowDownTemp: [],
  projectId: '',
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
