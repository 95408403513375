import { SortKey } from './types'
import { SCOPE_TYPE, ScopeType } from '../../useScopeType'
import { SORT_KEYS, SORT_ORDERS } from './constants'
import { SORT_ICONS, SortIconState } from '../../../../components/common/SortButton'
import { SortOrder } from '../../../sortBy'
import { CustomFilterState } from '../Filter/types'

/**
 * ソートキーとスコープタイプを照合し、互換性があるかどうかを判断する
 *
 * @param {SortKey} sortKey - The sort key
 * @param {ScopeType} scopeType - The scope type
 * @return {boolean} - ソートキーとスコープタイプに互換性があればtrue、なければfalse
 */
export function matchSortKeyAndScopeType(sortKey: SortKey, scopeType: ScopeType): boolean {
  // ソートキーがセッション数・ユーザー数ではない場合、チェック不要
  if (sortKey !== SORT_KEYS.SESSION_COUNT && sortKey !== SORT_KEYS.USER_COUNT) return true

  return (
    (sortKey === SORT_KEYS.SESSION_COUNT && scopeType === SCOPE_TYPE.SESSION) ||
    (sortKey === SORT_KEYS.USER_COUNT && scopeType === SCOPE_TYPE.USER)
  )
}

/**
 * ソートアイコン状態に応じてAPI向けのSortOrderに変換する
 *
 * @param {SortIconState} sortIcon - The sort icon.
 * @return {SortOrder} The sort order for API.
 */
export function convertSortOrder(sortIcon: SortIconState): SortOrder {
  return sortIcon === SORT_ICONS.DOWN ? SORT_ORDERS.DESC : SORT_ORDERS.ASC
}
export const makeItemsNotExistsMessage = (searchText: string, customFilterState: CustomFilterState[]): string => {
  if (searchText.length > 0 || customFilterState.length > 0) {
    return 'ご指定の条件に一致するデータはありません。条件を変えて再度検索してください。'
  }
  return '検索対象期間にはデータがありません。カスタムディメンションの設定状況をご確認ください。'
}
