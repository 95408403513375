import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'

// 処理ステップ
export const CrossDomainStep = {
  none: 0,
  addExec: 1, // 作成実行
  addComplete: 2, // 作成完了
  editExec: 3, // 編集実行
  editComplete: 4, // 編集完了
  deleteExec: 5, // 削除実行
  deleteComplete: 6, // 削除完了
}

export class CrossDomain {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // ドメインを追加
  onAdd = () => {
    this.setState({
      ...this.state,
      crossDomain: {
        ...this.state.crossDomain,
        disabled: true,
        name: '',
        subdomain: false,
        step: CrossDomainStep.addExec,
      },
      crossDomainErrorMessage: '',
    })
  }

  // 追加キャンセル
  onAddCancel = () => {
    this.setState({
      ...this.state,
      crossDomain: {
        ...this.state.crossDomain,
        step: CrossDomainStep.none,
      },
    })
  }

  // ドメイン設定
  onAddName = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      crossDomain: {
        ...this.state.crossDomain,
        name: event.currentTarget.value,
        disabled: !event.currentTarget.validity.valid,
      },
    })
  }

  // サブドメインの許可設定
  onAddSubdomain = () => {
    this.setState({
      ...this.state,
      crossDomain: {
        ...this.state.crossDomain,
        subdomain: !this.state.crossDomain.subdomain,
      },
    })
  }

  // ドメイン作成
  onAddApply = async () => {
    try {
      await request(
        'POST',
        `/api/projects/${this.state.project.id}/cross_domains/`,
        true,
        JSON.stringify({
          name: this.state.crossDomain.name,
          allow_subdomain: this.state.crossDomain.subdomain,
        }),
      )
      this.setState({
        ...this.state,
        crossDomain: {
          ...this.state.crossDomain,
          step: CrossDomainStep.addComplete,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        crossDomain: { ...this.state.crossDomain, step: CrossDomainStep.none },
        crossDomainErrorMessage: typeof e === 'string' ? e : '許可するドメインの追加に失敗しました。',
      })
    }
  }

  // ドメイン追加完了
  onAddComplete = () => {
    this.setState({
      ...this.state,
      reload: true,
      crossDomain: {
        ...this.state.crossDomain,
        step: CrossDomainStep.none,
      },
    })
  }

  // 編集開始
  onEdit = (id: number) => {
    const crossDomain = this.state.crossDomain.crossDomains.find((crossDomain) => crossDomain.id === id)
    if (crossDomain) {
      this.setState({
        ...this.state,
        crossDomain: {
          ...this.state.crossDomain,
          step: CrossDomainStep.editExec,
          selectedId: id,
          disabled: true,
          name: crossDomain.name,
          subdomain: crossDomain.allow_subdomain,
          baseName: crossDomain.name, // 比較用
          baseSubdomain: crossDomain.allow_subdomain, // 比較用
          isDomainValid: false,
          isSubdomainValid: false,
        },
        crossDomainErrorMessage: '',
      })
    }
  }

  // 編集キャンセル
  onEditCancel = () => {
    this.setState({
      ...this.state,
      crossDomain: {
        ...this.state.crossDomain,
        step: CrossDomainStep.none,
      },
    })
  }

  // ドメイン編集
  onEditName = (event: React.FormEvent<HTMLInputElement>) => {
    const valid = event.currentTarget.validity.valid && this.state.crossDomain.baseName !== event.currentTarget.value
    this.setState({
      ...this.state,
      crossDomain: {
        ...this.state.crossDomain,
        name: event.currentTarget.value,
        disabled: !(valid || this.state.crossDomain.isSubdomainValid),
        isDomainValid: valid,
      },
    })
  }

  // サブドメイン編集
  onEditSubdomain = () => {
    const check = !this.state.crossDomain.subdomain
    const valid = this.state.crossDomain.baseSubdomain !== check
    this.setState({
      ...this.state,
      crossDomain: {
        ...this.state.crossDomain,
        subdomain: check,
        disabled: !(valid || this.state.crossDomain.isDomainValid),
        isSubdomainValid: valid,
      },
    })
  }

  // 編集内容を適用
  onEditApply = async () => {
    try {
      await request(
        'PATCH',
        `/api/projects/${this.state.project.id}/cross_domains/${this.state.crossDomain.selectedId}/`,
        true,
        JSON.stringify({
          name: this.state.crossDomain.name,
          allow_subdomain: this.state.crossDomain.subdomain,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        crossDomain: {
          ...this.state.crossDomain,
          step: CrossDomainStep.none,
        },
        toastMessage: '許可するドメインを変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        crossDomain: { ...this.state.crossDomain, step: CrossDomainStep.none },
        crossDomainErrorMessage: typeof e === 'string' ? e : '許可するドメインの編集に失敗しました。',
      })
    }
  }

  // 削除開始
  onDelete = (id: number) => {
    const crossDomain = this.state.crossDomain.crossDomains.find((crossDomain) => crossDomain.id === id)
    if (crossDomain) {
      this.setState({
        ...this.state,
        crossDomain: {
          ...this.state.crossDomain,
          step: CrossDomainStep.deleteExec,
          selectedId: id,
          name: crossDomain.name,
        },
        ipAddressErrorMessage: '',
      })
    }
  }

  // 削除キャンセル
  onDeleteCancel = () => {
    this.setState({
      ...this.state,
      crossDomain: {
        ...this.state.crossDomain,
        step: CrossDomainStep.none,
      },
    })
  }

  // 削除を適用
  onDeleteApply = async () => {
    try {
      await request(
        'DELETE',
        `/api/projects/${this.state.project.id}/cross_domains/${this.state.crossDomain.selectedId}/`,
        true,
      )
      this.setState({
        ...this.state,
        reload: true,
        crossDomain: {
          ...this.state.crossDomain,
          step: CrossDomainStep.none,
        },
        toastMessage: '許可するドメインを削除しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        crossDomain: { ...this.state.crossDomain, step: CrossDomainStep.none },
        crossDomainErrorMessage: typeof e === 'string' ? e : '許可するドメインの削除に失敗しました。',
      })
    }
  }
}
