import { RE2JS } from 're2js'

/**
 * 指定された正規表現が、BigQueryの正規表現ライブラリRE2でサポートされているか検証する
 * see. https://github.com/google/re2/wiki/Syntax
 *
 * @param {string} pattern - 検証するパターン
 * @return {string} - 無効な場合はエラーメッセージを返し、有効なら空文字列を返します。
 */
export function validateBigQueryRegexPattern(pattern: string): string {
  try {
    RE2JS.compile(pattern)
  } catch (e) {
    // RE2コンストラクタでエラーになれば非対応
    // エラーメッセージサンプル: error parsing regexp: invalid or unsupported Perl syntax: `(?=`
    const detailErrorMessage = (e as Error).message
    const match = detailErrorMessage.match(/`(.+?)`/)
    const errorMessage = 'サポート外の正規表現が含まれています。'
    if (!match) {
      return errorMessage
    }

    return `${errorMessage}(unsupported: \`${match[1]}\`)`
  }

  return ''
}
