import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'
import { DataImportCsvData } from './types'

type payload = {
  formData: FormData
}

type apiResponse = {
  enable: boolean
}

type Props = {
  projectId: string
  options?: UseMutationOptions<DataImportCsvData, string, payload, void>
}

export const useUploadDataImportCsv = ({ projectId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async (payload: payload) => {
      const { formData } = payload
      const result: apiResponse = await request(
        'POST',
        `/api/projects/${projectId}/data_import_csv_uploads/`,
        true,
        formData,
      )

      const formattedResponse: DataImportCsvData = {
        isImporting: result.enable,
      }

      return formattedResponse
    },
    ...options,
  })
  return mutationResult
}
