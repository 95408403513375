import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'
import { GoalType, Condition, ValueCondition, GoalEventType } from '../../util/Goal'

// 処理ステップ
export const GoalStep = {
  none: 0,
  createExec: 1, // 作成実行
  createComplete: 2, // 作成完了
  editExec: 3, // 編集実行
  editComplete: 4, // 編集完了
  deleteExec: 5, // 削除実行
  deleteComplete: 6, // 削除完了
}

export class Goal {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // ゴール作成モーダル表示
  onCreate = () => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        step: GoalStep.createExec,
        type: GoalType.url,
        name: '',
        condition: 1,
        url: '',
        eventSelected: [true, true, true, true],
        eventCondition: [Condition.perfect, Condition.perfect, Condition.perfect, ValueCondition.greater],
        eventValue: ['', '', '', ''],
        isNameValid: false,
        isUrlValid: false,
        isEventValid: [false, false, false, false],
        createDisabled: true,
      },
    })
  }

  // 作成するゴールの種類設定
  onSetType = () => {
    const type = this.state.goal.type === GoalType.url ? GoalType.event : GoalType.url

    // 作成可能か判定
    let valid = true
    if (type === GoalType.url) {
      valid = this.state.goal.isUrlValid
    } else {
      valid = this.canEventCreate(this.state.goal.eventSelected, this.state.goal.isEventValid)
    }

    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        type: type,
        createDisabled: !(this.state.goal.isNameValid && valid),
      },
    })
  }

  // ゴール作成モーダルでのゴール名設定
  onSetName = (event: React.FormEvent<HTMLInputElement>) => {
    // 作成可能か判定
    let valid = true
    if (this.state.goal.type === GoalType.url) {
      valid = this.state.goal.isUrlValid
    } else {
      valid = this.canEventCreate(this.state.goal.eventSelected, this.state.goal.isEventValid)
    }

    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        isNameValid: event.currentTarget.validity.valid,
        createDisabled: !(event.currentTarget.validity.valid && valid),
        name: event.currentTarget.value,
      },
    })
  }

  // ゴール作成モーダルでのゴール条件設定
  onSetCondition = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        condition: Number(event.currentTarget.value),
      },
    })
  }

  // ゴール作成モーダルでのゴールURL設定
  onSetUrl = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        isUrlValid: event.currentTarget.validity.valid,
        createDisabled: !(
          this.state.goal.type === GoalType.url &&
          event.currentTarget.validity.valid &&
          this.state.goal.isNameValid
        ),
        url: event.currentTarget.value,
      },
    })
  }

  // 作成イベントの種類選択
  onSetEventSelected = (index: number) => {
    const temp = this.state.goal.eventSelected.slice()
    temp[index] = !temp[index]
    const created = this.canEventCreate(temp, this.state.goal.isEventValid)
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        eventSelected: temp,
        createDisabled: !(this.state.goal.type === GoalType.event && this.state.goal.isNameValid && created),
      },
    })
  }

  // 作成イベントの条件設定
  onSetEventCondition = (event: React.FormEvent<HTMLSelectElement>, index: number) => {
    const temp = this.state.goal.eventCondition.slice()
    temp[index] = Number(event.currentTarget.value)
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        eventCondition: temp,
      },
    })
  }

  // 作成イベントの入力
  onSetEventValue = (event: React.FormEvent<HTMLInputElement>, index: number) => {
    const temp = this.state.goal.eventValue.slice()
    temp[index] = event.currentTarget.value
    const tempValid = this.state.goal.isEventValid.slice()
    tempValid[index] = event.currentTarget.validity.valid
    const created = this.canEventCreate(this.state.goal.eventSelected, tempValid)
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        isEventValid: tempValid,
        eventValue: temp,
        createDisabled: !(this.state.goal.type === GoalType.event && this.state.goal.isNameValid && created),
      },
    })
  }

  // ゴール作成モーダル閉じる
  onCancelCreate = () => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        step: GoalStep.none,
      },
    })
  }

  // ゴール作成
  onApplyCreate = async () => {
    try {
      await request('POST', `/api/projects/${this.state.project.id}/goals/`, true, this.getJson())
      this.setState({
        ...this.state,
        reload: true,
        goal: {
          ...this.state.goal,
          step: GoalStep.none,
        },
        goalErrorMessage: '',
        toastMessage: 'ゴールを追加しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        goal: {
          ...this.state.goal,
          step: GoalStep.none,
        },
        goalErrorMessage: typeof e === 'string' ? e : 'ゴールの作成に失敗しました。',
      })
    }
  }

  // ゴールURL編集フロー開始
  onUrlEdit = (id: number) => {
    const goal = this.state.goal.goals.find((goal) => goal.id === id)
    if (goal && goal.url) {
      this.setState({
        ...this.state,
        goal: {
          ...this.state.goal,
          selectedId: id,
          step: GoalStep.editExec,
          disabled: true,
          type: GoalType.url,
          name: goal.name,
          condition: goal.url.condition,
          url: goal.url.url,
          baseName: goal.name, // 比較用となる元の情報
          baseCondition: goal.url.condition,
          baseUrl: goal.url.url,
          isNameValid: false,
          isUrlValid: false,
          isConditionValid: false,
        },
      })
    }
  }

  // ゴールイベント編集フロー開始
  onEventEdit = (id: number) => {
    const goal = this.state.goal.goals.find((goal) => goal.id === id)
    if (goal && goal.event) {
      this.setState({
        ...this.state,
        goal: {
          ...this.state.goal,
          selectedId: id,
          step: GoalStep.editExec,
          disabled: true,
          type: GoalType.event,
          name: goal.name,
          eventSelected: [
            goal.event.category !== '',
            goal.event.action !== '',
            goal.event.label !== '',
            goal.event.value !== 0,
          ],
          eventCondition: [
            goal.event.category_condition,
            goal.event.action_condition,
            goal.event.label_condition,
            goal.event.value_condition,
          ],
          eventValue: [
            goal.event.category ?? '',
            goal.event.action ?? '',
            goal.event.label ?? '',
            goal.event.value?.toString() ?? '',
          ],
          baseName: goal.name, // 比較用となる元の情報
          baseEventSelected: [
            goal.event.category !== '',
            goal.event.action !== '',
            goal.event.label !== '',
            goal.event.value !== 0,
          ],
          baseEventCondition: [
            goal.event.category_condition,
            goal.event.action_condition,
            goal.event.label_condition,
            goal.event.value_condition,
          ],
          baseEventValue: [
            goal.event.category ?? '',
            goal.event.action ?? '',
            goal.event.label ?? '',
            goal.event.value?.toString() ?? '',
          ],
          isNameValid: false,
          isEventValid: [
            goal.event.category !== '',
            goal.event.action !== '',
            goal.event.label !== '',
            goal.event.value !== 0,
          ],
        },
      })
    }
  }

  // 編集状態を解除
  onCancelEdit = () => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        selectedId: 0,
        step: GoalStep.none,
        disabled: true,
        type: GoalType.url,
        name: '',
        condition: 0,
        url: '',
        eventSelected: [true, true, true, true],
        eventCondition: [Condition.perfect, Condition.perfect, Condition.perfect, ValueCondition.greater],
        eventValue: ['', '', '', ''],
        baseName: '',
        baseCondition: 0,
        baseUrl: '',
        baseEventSelected: [],
        baseEventCondition: [],
        baseEventValue: [],
        isNameValid: false,
        isUrlValid: false,
        isConditionValid: false,
        isEventValid: [false, false, false, false],
      },
    })
  }

  // ゴールURLの目標名編集
  updateUrlName = (event: React.FormEvent<HTMLInputElement>) => {
    // 入力が正常で元の名前と違う場合に変更が有効
    const valid = event.currentTarget.validity.valid && this.state.goal.baseName !== event.currentTarget.value
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        name: event.currentTarget.value,
        isNameValid: valid,
        disabled: !(valid || this.state.goal.isUrlValid || this.state.goal.isConditionValid),
      },
    })
  }

  // ゴールURLの条件編集
  updateUrlCondition = (event: React.FormEvent<HTMLSelectElement>) => {
    // 元のインデックスと違えば変更が有効
    const valid = this.state.goal.baseCondition !== Number(event.currentTarget.value)
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        condition: Number(event.currentTarget.value),
        isConditionValid: valid,
        disabled: !(valid || this.state.goal.isNameValid || this.state.goal.isUrlValid),
      },
    })
  }

  // ゴールURLのURL編集
  updateUrl = (event: React.FormEvent<HTMLInputElement>) => {
    // 元のURLと違えば変更が有効
    const valid = event.currentTarget.validity.valid && this.state.goal.baseUrl !== event.currentTarget.value
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        url: event.currentTarget.value,
        isUrlValid: valid,
        disabled: !(valid || this.state.goal.isNameValid || this.state.goal.isConditionValid),
      },
    })
  }

  // ゴールURL更新
  onApplyUrlEdit = async () => {
    try {
      await request(
        'PATCH',
        `/api/projects/${this.state.project.id}/goals/${this.state.goal.selectedId}/`,
        true,
        JSON.stringify({
          name: this.state.goal.name,
          goal_type: GoalType.url,
          goal_url: {
            condition: this.state.goal.condition,
            url: this.state.goal.url,
          },
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        goal: {
          ...this.state.goal,
          selectedId: 0,
          step: GoalStep.none,
          disabled: true,
          type: GoalType.url,
          name: '',
          condition: 0,
          url: '',
          eventSelected: [true, true, true, true],
          eventCondition: [Condition.perfect, Condition.perfect, Condition.perfect, ValueCondition.greater],
          eventValue: ['', '', '', ''],
          baseName: '',
          baseCondition: 0,
          baseUrl: '',
          isNameValid: false,
          isUrlValid: false,
          isConditionValid: false,
        },
        toastMessage: 'ゴールを変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        goalErrorMessage: typeof e === 'string' ? e : 'ゴールの編集に失敗しました。',
      })
    }
  }

  // ゴールイベントの目標名編集
  updateEventName = (event: React.FormEvent<HTMLInputElement>) => {
    // 入力が正常で元の名前と違う場合に変更が有効
    const valid = event.currentTarget.validity.valid && this.state.goal.baseName !== event.currentTarget.value
    // 作成可能な設定がされているか
    const created = this.canEventCreate(this.state.goal.eventSelected, this.state.goal.isEventValid)
    // 入力内容が変更されているか
    const changed = this.isChangeInput(
      this.state.goal.eventSelected,
      this.state.goal.eventCondition,
      this.state.goal.eventValue,
    )
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        name: event.currentTarget.value,
        isNameValid: valid,
        disabled: !(created && (valid || !changed)),
      },
    })
  }

  // ゴールイベントの選択状態更新
  updateEventSelected = (index: number) => {
    const temp = this.state.goal.eventSelected.slice()
    temp[index] = !temp[index]
    const created = this.canEventCreate(temp, this.state.goal.isEventValid)
    const changed = this.isChangeInput(temp, this.state.goal.eventCondition, this.state.goal.eventValue)
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        eventSelected: temp,
        disabled: !(created && (this.state.goal.isNameValid || !changed)),
      },
    })
  }

  // ゴールイベントの条件更新
  updateEventCondition = (event: React.FormEvent<HTMLSelectElement>, index: number) => {
    const temp = this.state.goal.eventCondition.slice()
    temp[index] = Number(event.currentTarget.value)
    const created = this.canEventCreate(this.state.goal.eventSelected, this.state.goal.isEventValid)
    const changed = this.isChangeInput(this.state.goal.eventSelected, temp, this.state.goal.eventValue)
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        eventCondition: temp,
        disabled: !(created && (this.state.goal.isNameValid || !changed)),
      },
    })
  }

  // ゴールイベントの値更新
  updateEventValue = (event: React.FormEvent<HTMLInputElement>, index: number) => {
    const temp = this.state.goal.eventValue.slice()
    temp[index] = event.currentTarget.value
    const tempValid = this.state.goal.isEventValid.slice()
    tempValid[index] = event.currentTarget.validity.valid
    const created = this.canEventCreate(this.state.goal.eventSelected, tempValid)
    const changed = this.isChangeInput(this.state.goal.eventSelected, this.state.goal.eventCondition, temp)
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        isEventValid: tempValid,
        eventValue: temp,
        disabled: !(created && (this.state.goal.isNameValid || !changed)),
      },
    })
  }

  // ゴールイベント更新
  onApplyEventEdit = async () => {
    try {
      await request(
        'PATCH',
        `/api/projects/${this.state.project.id}/goals/${this.state.goal.selectedId}/`,
        true,
        this.getJson(),
      )
      this.setState({
        ...this.state,
        reload: true,
        goal: {
          ...this.state.goal,
          selectedId: 0,
          step: GoalStep.none,
          disabled: true,
          type: GoalType.url,
          name: '',
          condition: 0,
          url: '',
          eventSelected: [true, true, true, true],
          eventCondition: [Condition.perfect, Condition.perfect, Condition.perfect, ValueCondition.greater],
          eventValue: ['', '', '', ''],
          baseName: '',
          baseCondition: 0,
          baseUrl: '',
          isNameValid: false,
          isUrlValid: false,
          isConditionValid: false,
        },
        toastMessage: 'ゴールを変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        goalErrorMessage: typeof e === 'string' ? e : 'ゴールの編集に失敗しました。',
      })
    }
  }

  // ゴール削除フロー開始
  onDelete = (id: number) => {
    const goal = this.state.goal.goals.find((goal) => goal.id === id)
    if (goal) {
      this.setState({
        ...this.state,
        goal: {
          ...this.state.goal,
          deleteId: goal.id,
          deleteName: goal.name,
          step: GoalStep.deleteExec,
        },
      })
    }
  }

  // ゴール削除キャンセル
  onCancelDelete = () => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        deleteId: 0,
        deleteName: '',
        step: GoalStep.none,
      },
    })
  }

  // ゴール削除実行
  onApplyDelete = async () => {
    const goal = this.state.goal.goals.find((goal) => goal.id === this.state.goal.deleteId)
    if (goal) {
      try {
        await request('DELETE', `/api/projects/${this.state.project.id}/goals/${this.state.goal.deleteId}/`, true)
        this.setState({
          ...this.state,
          reload: true,
          goal: {
            ...this.state.goal,
            deleteId: 0,
            deleteName: '',
            step: GoalStep.none,
          },
          toastMessage: 'ゴールを削除しました',
        })
      } catch (e) {
        this.setState({
          ...this.state,
          goal: {
            ...this.state.goal,
            deleteId: 0,
            deleteName: '',
            step: GoalStep.none,
          },
          goalErrorMessage: typeof e === 'string' ? e : 'ゴールの削除に失敗しました。',
        })
      }
    }
  }

  // ゴールイベントを作成可能か
  canEventCreate = (type: boolean[], valid: boolean[]) => {
    // 作成可能かを判定する
    let created = true
    for (let i = GoalEventType.category; i <= GoalEventType.value; ++i) {
      if (type[i] && !valid[i]) {
        created = false
      }
    }
    // いずれかにチェックが入っている
    const checked = type.filter((type) => type === true).length > 0
    return created && checked
  }

  // 入力内容が変更されている
  isChangeInput = (selected: boolean[], condition: (number | null)[], value: string[]) => {
    let created = true
    for (let i = GoalEventType.category; i <= GoalEventType.value; ++i) {
      if (selected[i] !== this.state.goal.baseEventSelected[i]) {
        created = false
      }
      if (condition[i] !== this.state.goal.baseEventCondition[i]) {
        created = false
      }
      if (value[i] !== this.state.goal.baseEventValue[i]) {
        created = false
      }
    }
    return created
  }

  // APIに使用するパラメータを作成
  getJson = () => {
    let json = {
      name: this.state.goal.name,
      goal_type: this.state.goal.type,
    }

    // URL
    if (this.state.goal.type === GoalType.url) {
      const url = {
        goal_url: {
          condition: this.state.goal.condition,
          url: this.state.goal.url,
        },
      }
      json = { ...json, ...url }
    }
    // EVENT
    else {
      const event = {
        goal_event: {
          category: this.state.goal.eventSelected[GoalEventType.category]
            ? this.state.goal.eventValue[GoalEventType.category]
            : '',
          action: this.state.goal.eventSelected[GoalEventType.action]
            ? this.state.goal.eventValue[GoalEventType.action]
            : '',
          label: this.state.goal.eventSelected[GoalEventType.label]
            ? this.state.goal.eventValue[GoalEventType.label]
            : '',
          value: this.state.goal.eventSelected[GoalEventType.value]
            ? Number(this.state.goal.eventValue[GoalEventType.value])
            : null,
          category_condition: this.state.goal.eventSelected[GoalEventType.category]
            ? this.state.goal.eventCondition[GoalEventType.category]
            : null,
          action_condition: this.state.goal.eventSelected[GoalEventType.action]
            ? this.state.goal.eventCondition[GoalEventType.action]
            : null,
          label_condition: this.state.goal.eventSelected[GoalEventType.label]
            ? this.state.goal.eventCondition[GoalEventType.label]
            : null,
          value_condition: this.state.goal.eventSelected[GoalEventType.value]
            ? this.state.goal.eventCondition[GoalEventType.value]
            : null,
        },
      }
      json = { ...json, ...event }
    }
    return JSON.stringify(json)
  }
}
