import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'

type Payload = {
  targetYearMonth: string
}

type FormattedPayload = {
  target_yearmonth: string
}

type Response = {
  download_url: string
}

type Props = {
  projectId: number
  options: UseMutationOptions<string, string, Payload, void>
}

export const useDownloadPvByUrl = ({ projectId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async (payload: Payload) => {
      const formattedPayload: FormattedPayload = {
        target_yearmonth: payload.targetYearMonth.replace('/', ''),
      }

      const result: Response = await request(
        'POST',
        `/api/projects/${projectId}/pv/download/`,
        true,
        JSON.stringify(formattedPayload),
      )

      return result.download_url
    },
    ...options,
  })
  return mutationResult
}
