// パスワードに使用可能な文字
export const PasswordRegex: string = '[A-Za-z0-9!-/:-@¥[-`{-~]*'

// パスワードの最低必要文字数
export const PasswordMinLength: number = 10

// 文字列に英数記号のうち最低2種類は使用されているかをチェックする
export function checkPasswordPattern(password: string): boolean {
  const result = [
    password.match(RegExp('[A-Za-z]')),
    password.match(RegExp('[0-9]')),
    password.match(RegExp('[!-/:-@¥[-`{-~]')),
  ]
  return result.filter((data) => data !== null).length >= 2
}

// 文字数が足りているかチェックする
export function checkPasswordLength(password: string): boolean {
  return password.length >= PasswordMinLength
}
