import * as React from 'react'

import { CsErrorType } from '../../util/Response'
import { CaptureErrorIcon } from './CaptureErrorIcon'

interface Props {
  readonly status: string
  readonly errorType: CsErrorType | null
  readonly responseErrorHttpStatusCode: number | null
}

export function StatusItem({ status, errorType, responseErrorHttpStatusCode }: Props) {
  return (
    <>
      {status}
      {errorType && (
        <CaptureErrorIcon errorType={errorType} responseErrorHttpStatusCode={responseErrorHttpStatusCode} />
      )}
    </>
  )
}
