import * as React from 'react'
import { SORT_ICONS, SortIconState } from '../../components/common/SortButton'

import { SORT_KEYS } from '../../util/hooks/api/CustomDimensionReport/constants'
import { SortKey } from '../../util/hooks/api/CustomDimensionReport/types'

export interface ContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly searchText: string
  readonly sortKey: SortKey
  readonly sortIcon: SortIconState
}

const initialState: State = {
  searchText: '',
  sortKey: SORT_KEYS.SESSION_COUNT,
  sortIcon: SORT_ICONS.DOWN,
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  setSearchText = (value: string): void => {
    this.setState({
      ...this.state,
      searchText: value,
    })
  }

  /**
   * コンポーネントのソート値を設定する
   *
   * @param {string} sortKey - The sort key. (SortControl.onSortClickとの互換性のためstringで定義している)
   * @param {SortIconState} [sortIcon] - The sort icon.
   * @returns {void}
   */
  setSortValues = (sortKey: string, sortIcon?: SortIconState): void => {
    this.setState({
      ...this.state,
      sortKey: sortKey as SortKey,
      sortIcon: sortIcon ?? this.state.sortIcon,
    })
  }

  /**
   * 現在選択中のソートキーから、与えられたソートキーに基づくソートアイコンの状態を返す
   *
   * @param {string} sortKey - ソートアイコンを識別するためのキー
   * @return {SortIconState} - ソートキーに基づくソートアイコンの状態
   */
  getSortIcon = (sortKey: SortKey): SortIconState => {
    return this.state.sortKey === sortKey ? this.state.sortIcon : SORT_ICONS.NONE
  }
}

/**
 * カスタムディメンションレポート系画面で使用する state と actions を返す
 *
 * @return {ContextType} The context state and actions.
 */
export function useContextState(): ContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)

  return { state, actions }
}
