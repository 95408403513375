import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'
import { UpdateDataImportSchemasPayload, DataImportSchemasResultsResponse, DataImportSchemasData } from './types'
import { formatResponse } from './formatResponse'

type Props = {
  projectId: string
  options: UseMutationOptions<DataImportSchemasData, string, UpdateDataImportSchemasPayload, void>
}

export const useUpdateDataImportSchemas = ({ projectId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async (payload: UpdateDataImportSchemasPayload) => {
      const { schemasId, importFields, keyField } = payload
      const formattedPayload = {
        data_import_key_field: {
          table: keyField.table,
          custom_dimension_id: keyField.customDimensionId,
        },
        data_import_fields: importFields.map((importField) => ({
          for_key: importField.primaryKey,
          name: importField.fieldName,
          type: importField.dataType,
        })),
      }

      const result = await request<DataImportSchemasResultsResponse>(
        'PUT',
        `/api/projects/${projectId}/data_import_schemas/${schemasId}/`,
        true,
        JSON.stringify(formattedPayload),
      )

      const formattedResponse: DataImportSchemasData = formatResponse(result)
      return formattedResponse
    },
    ...options,
  })
  return mutationResult
}
