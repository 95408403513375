import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'

type Props = {
  projectId: number
  options?: UseMutationOptions<{}, unknown, { funnelReportId: number }>
}

export const useDeleteFunnelReport = ({ projectId, options }: Props) => {
  return useMutation({
    mutationFn: async ({ funnelReportId }) => {
      return await request<{}>('DELETE', `/api/projects/${projectId}/funnel_reports/${funnelReportId}/`, true)
    },
    ...options,
  })
}
