import * as React from 'react'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { getDateYYYYMMDDhhmi } from '../../../util/Date'
import { useDeviceType } from '../../../util/hooks/useDeviceType'
import { usePageState } from '../../modal/state/ContentRegistState'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../../common/Modal'
import { CancelButton, NormalButton } from '../../common/Button'
import { Input } from '../../common/Input'
import { Content, ContentTag } from '../../../util/hooks/api/useContentReport'
import { useSaveContent } from '../../../util/hooks/api/useSaveContent'
import { isMobile, isPc } from '../../../util/device'
import { compareAndSortArrays } from '../../../util/ArrayUtils'
import { TagNamesArea } from './TagNamesArea'

export const getInitialTagNames = (
  selectedContents: Content[],
): { initialTagNames: string[]; isAllTagsMatched: boolean } => {
  if (selectedContents.length === 0) return { initialTagNames: [''], isAllTagsMatched: true }

  const selectedContentsTags = selectedContents.map((con) => con.tags.map((tag) => tag.name))
  const initialTagNames = selectedContentsTags[0]

  const isAllTagsMatched = selectedContentsTags.every((tagNames) => compareAndSortArrays(tagNames, initialTagNames))
  if (!isAllTagsMatched) return { initialTagNames: [''], isAllTagsMatched: false }

  if (initialTagNames.length === 0) return { initialTagNames: [''], isAllTagsMatched: true }
  return { initialTagNames: initialTagNames, isAllTagsMatched: true }
}

/**
 * 有効なタグ名配列にフィルタリングする（空の文字列除去）
 *
 * @param values - タグ名配列
 * @returns フィルタリング済みのタグ名配列
 */
export const filterValidTagNames = (values: string[]): string[] => {
  return values.filter((value) => value !== '')
}

interface State {
  readonly projectId: string
  readonly selectedContent: Content
  readonly canSave: boolean | undefined // 保存ボタンの表示ステータス
  readonly onClose: () => void // 後処理
  readonly onSuccess: (name: string, tags: ContentTag[], message: string) => void
}

// コンテンツ情報の登録と編集
export function SingleContentRegister(props: State) {
  const { projectId, selectedContent, canSave, onClose, onSuccess } = props
  const { state, actions } = usePageState()
  const { loading, contentId, imageHeight } = state

  const [errorMessage, setErrorMessage] = React.useState('')
  const { deviceType } = useDeviceType()
  const { saveContentMutate, isSavingContent } = useSaveContent({
    projectId,
    contentId: selectedContent.id,
    options: {
      onSuccess: (response) => {
        onSuccess(response.name, response.tags, 'コンテンツ情報を保存しました')
      },
      onError: (e: string) => {
        setErrorMessage(typeof e === 'string' ? e : 'コンテンツ情報の保存に失敗しました。')
      },
    },
  })

  React.useEffect(() => {
    if (loading) {
      actions.fetch(projectId, selectedContent.id, deviceType)
    }
  }, [])

  const onClickSaveButton = () => {
    saveContentMutate({ name: name, tagNames: filterValidTagNames(tagNames) })
  }

  // デバイスの有効が確認できていない場合は非表示とする
  const visible = !loading && !isSavingContent && (isMobile(deviceType) || isPc(deviceType))

  const initialName = selectedContent.name
  const { initialTagNames } = useMemo(() => getInitialTagNames([selectedContent]), [selectedContent])
  const [name, setName] = useState(initialName)
  const [tagNames, setTagNames] = useState(initialTagNames)

  const contentNameRef = React.useRef<HTMLInputElement>(null)
  React.useEffect(() => {
    // レンダリングが完了後にfocusを実施する必要があるため、少し遅延させる
    setTimeout(() => {
      if (!contentNameRef.current) return
      contentNameRef.current.focus()
    }, 500)
  }, [])

  return (
    <Modal isOpen={true} onClose={onClose} title="コンテンツ詳細" isLoading={loading || isSavingContent}>
      <ModalContentWrapper>
        <ScrollArea>
          <Box>
            <InnerBox visible={visible}>
              {errorMessage && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}

              <ImageLayer>
                <ImageBox>
                  <Img src={selectedContent?.contentScreenshot.imageUrl || ''} height={imageHeight} />
                </ImageBox>
              </ImageLayer>

              <ContentDetail>
                <Label>コンテンツID</Label>
                <Value>{contentId}</Value>

                <Label>コンテンツ名</Label>
                <DesignedInput
                  type="text"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value)
                  }}
                  fontSize={16}
                  ref={contentNameRef}
                />

                <TagNamesAreaWrapper>
                  <TagNamesArea tagNames={tagNames} setTagNames={setTagNames} />
                </TagNamesAreaWrapper>

                <Label>CSSセレクター</Label>
                <Value>{selectedContent?.contentScreenshot.cssSelector || ''}</Value>

                <Label>キャプチャ取得パターン</Label>
                <Value>{selectedContent?.contentScreenshot.targetTypeName || ''}</Value>

                <Label>計測開始日</Label>
                <Value>
                  {selectedContent ? getDateYYYYMMDDhhmi(selectedContent.contentScreenshot.capturedAt) : ''}
                </Value>
              </ContentDetail>
            </InnerBox>
          </Box>
        </ScrollArea>
      </ModalContentWrapper>
      {visible && (
        <ModalButtonWrapper>
          <CancelButton onClick={onClose}>キャンセル</CancelButton>
          {canSave && (
            <NormalButton
              disabled={
                initialName === name &&
                // 未入力は除外してチェックする
                compareAndSortArrays(filterValidTagNames(initialTagNames), filterValidTagNames(tagNames))
              }
              onClick={onClickSaveButton}
            >
              保存
            </NormalButton>
          )}
        </ModalButtonWrapper>
      )}
    </Modal>
  )
}

const SCROLL_AREA_MAX_HEIGHT = 610

const ScrollArea = styled.div`
  width: 100%;
  height: 100%;
  max-height: ${SCROLL_AREA_MAX_HEIGHT}px;
  overflow-y: auto;
`

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  min-height: ${SCROLL_AREA_MAX_HEIGHT}px;
`

const InnerBox = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

const ContentDetail = styled.div`
  margin-top: 1.5rem;
`

const Value = styled.div`
  margin-bottom: 2rem;
  font-size: 16px;
`

const ImageLayer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray50};
  border: 2px solid ${colors.gray300};
  width: 100%;
  margin-top: 1rem;
`

const ImageBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 180px;
  padding: 1rem;
`

const Img = styled.img<{ height: number }>`
  margin: auto;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  object-fit: contain;
`

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 40px;
  margin-bottom: 2rem;
`

const TagNamesAreaWrapper = styled.div`
  margin-bottom: 32px;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`
