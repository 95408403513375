import * as React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FUNNEL_REPORTS_QUERY_KEY } from '../constants'
import { request } from '../../../request'
import { FunnelReportsResponse, FunnelReportsData, SortKey } from './types'
import { useScopeType } from '../../useScopeType'
import { getDateYYYYMMDDhhmi } from '../../../Date'
import { FunnelReportContext } from '../../../../contexts/FunnelReportContext'
import { SortIconState } from '../../../../components/common/SortButton'
import { convertSortOrder } from '../CustomDimensionReport/utils'

export interface FunnelReportsProps {
  readonly projectId: number
  readonly enabled?: boolean
}

export const useFunnelReports = ({ projectId, enabled = true }: FunnelReportsProps) => {
  const { searchText, sortKey, sortIcon } = React.useContext(FunnelReportContext)

  const queryClient = useQueryClient()

  const { scopeType } = useScopeType()

  const queryKey = [
    FUNNEL_REPORTS_QUERY_KEY,
    {
      projectId,
      searchText,
      sortKey,
      sortIcon,
      // FIXME:
      // see: https://github.com/uncovertruth/content-analytics/issues/2551
      // 一覧画面でscopeType切り替え時にlocation.searchが変わるが、
      // ReportsTableのリンクのクエリパラメータが変わらない問題が起きたため一時的な対応としてscopeTypeを追加。
      // 実際は必要なく無駄なリクエストが発生してしまうので、後日 #2551 で修正する
      scopeType,
    },
  ]

  const resetFunnelReports = async () => {
    await queryClient.resetQueries({ queryKey: [FUNNEL_REPORTS_QUERY_KEY] })
  }

  const fetchReports = async () => {
    const queryParams = makeQueryParams(searchText, sortKey, sortIcon)
    return await request<FunnelReportsResponse>(
      'GET',
      `/api/projects/${projectId}/funnel_reports/?${queryParams}`,
      true,
    )
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: fetchReports,
    select: (response) => {
      return transformReports(response)
    },
    enabled: !!enabled,
  })

  return {
    ...queryResult,
    resetFunnelReports,
  }
}

const transformReports = (response: FunnelReportsResponse): FunnelReportsData => {
  const results = response.results.map((report) => ({
    id: report.id,
    name: report.name,
    updatedAt: getDateYYYYMMDDhhmi(report.updated_at),
    updatedUserName: report.updated_user?.username ?? '-', // NOTE: ハイフンはuserが削除されている場合の表示対応
  }))

  return {
    count: response.count,
    funnelReportsLimit: response.funnel_reports_limit,
    teamReportsCount: response.team_reports_count,
    reportLimitReached: response.team_reports_count >= response.funnel_reports_limit,
    reportLimitExceeded: response.team_reports_count > response.funnel_reports_limit,
    hasReports: results.length >= 1,
    results,
  }
}

const makeQueryParams = (searchText?: string, sortKey?: SortKey, sortIcon?: SortIconState) => {
  const params = new URLSearchParams()

  if (searchText) params.append('search_text', searchText)
  if (sortKey) params.append('sort_key', sortKey)
  if (sortIcon) params.append('sort_order', convertSortOrder(sortIcon))

  return params.toString()
}
