import * as React from 'react'
import { request } from '../../../util/request'
import { getDateYYYYMMDDhhmi } from '../../../util/Date'
import { PageContentSegmentationHistoriesData } from '../../../util/Response'
import { OptionProps } from '../../common/Select'
import { CaptureHistoryData } from '../../../pages/PageCapture/CaptureHistoryState'
import { DeviceTypeForAPI } from '../../../util/hooks/useDeviceType'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly loading: boolean
  readonly reload: boolean
  readonly pageChange: boolean
  readonly projectId: number
  readonly urlId: number
  readonly pageLayout: DeviceTypeForAPI
  readonly selectedId: number
  readonly checked: boolean
  readonly dispCount: number
  readonly currentPage: number
  readonly pageCount: number // コンテンツページ数
  readonly itemCount: number // アイテム総数
  readonly selectedItem: number
  readonly options: OptionProps[]
  readonly histories: CaptureHistoryData[]
  readonly imageUrl: string
  readonly disabledCapture: boolean
  readonly errorMessage?: string
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  fetch = async (projectId: number, urlId: number, pageLayout: DeviceTypeForAPI) => {
    try {
      // 初期表示数
      const dispCount = 10
      const segmentations: PageContentSegmentationHistoriesData = await request(
        'GET',
        this.getApiPath(projectId, urlId, dispCount, 0, false, pageLayout),
        true,
      )

      this.setState({
        ...this.state,
        loading: false,
        projectId: projectId,
        urlId: urlId,
        dispCount: dispCount, // 初期表示数
        currentPage: 0,
        pageCount: Math.ceil(segmentations.count / dispCount),
        itemCount: segmentations.count, // 総数
        selectedItem: 0,
        options: [
          { label: '10', value: 10 },
          { label: '20', value: 20 },
          { label: '30', value: 30 },
          { label: '50', value: 50 },
          { label: '100', value: 100 },
        ],
        histories:
          segmentations.results.length === 0
            ? []
            : segmentations.results.map((segmentation) => {
                return {
                  id: segmentation.id,
                  execDate: segmentation.requested_at === null ? '' : getDateYYYYMMDDhhmi(segmentation.requested_at),
                  completeDate:
                    segmentation.page_history === null ? '' : getDateYYYYMMDDhhmi(segmentation.page_history.created_at),
                  user: segmentation.username,
                  status: segmentation.status_name,
                  errorType: segmentation.error_type,
                  responseErrorHttpStatusCode: segmentation.response_error_http_status_code,
                  url: segmentation.page_history === null ? '' : segmentation.page_history.screenshot_url,
                  screenshotWidth: segmentation.page_history === null ? 0 : segmentation.page_history.screenshot_width,
                  contentLocations:
                    segmentation.page_history === null ? [] : segmentation.page_history.content_locations,
                }
              }),
        disabledCapture: segmentations.is_locked_cs_manually,
        pageLayout,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        errorMessage: typeof e === 'string' ? e : 'ページキャプチャの取得履歴の取得に失敗しました。',
      })
    }
  }

  refetch = async () => {
    try {
      const segmentations: PageContentSegmentationHistoriesData = await request(
        'GET',
        this.getApiPath(
          this.state.projectId,
          this.state.urlId,
          this.state.dispCount,
          0,
          this.state.checked,
          this.state.pageLayout,
        ),
        true,
      )

      this.setState({
        ...this.state,
        reload: false,
        selectedId: 0, // 選択解除
        imageUrl: '', // 選択解除
        currentPage: 0,
        pageCount: Math.ceil(segmentations.count / this.state.dispCount),
        itemCount: segmentations.count, // 総数
        selectedItem: 0,
        histories:
          segmentations.results.length === 0
            ? []
            : segmentations.results.map((segmentation) => {
                return {
                  id: segmentation.id,
                  execDate: segmentation.requested_at === null ? '' : getDateYYYYMMDDhhmi(segmentation.requested_at),
                  completeDate:
                    segmentation.page_history === null ? '' : getDateYYYYMMDDhhmi(segmentation.page_history.created_at),
                  user: segmentation.username,
                  status: segmentation.status_name,
                  errorType: segmentation.error_type,
                  responseErrorHttpStatusCode: segmentation.response_error_http_status_code,
                  url: segmentation.page_history === null ? '' : segmentation.page_history.screenshot_url,
                  screenshotWidth: segmentation.page_history === null ? 0 : segmentation.page_history.screenshot_width,
                  contentLocations:
                    segmentation.page_history === null ? [] : segmentation.page_history.content_locations,
                }
              }),
        disabledCapture: segmentations.is_locked_cs_manually,
        errorMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        reload: false,
        errorMessage: typeof e === 'string' ? e : 'ページキャプチャの取得履歴の取得に失敗しました。',
      })
    }
  }

  fetchNextPage = async () => {
    try {
      const segmentations: PageContentSegmentationHistoriesData = await request(
        'GET',
        this.getApiPath(
          this.state.projectId,
          this.state.urlId,
          this.state.dispCount,
          this.state.selectedItem * this.state.dispCount,
          this.state.checked,
          this.state.pageLayout,
        ),
        true,
      )

      this.setState({
        ...this.state,
        pageChange: false,
        selectedId: 0, // 選択解除
        imageUrl: '', // 選択解除
        pageCount: Math.ceil(segmentations.count / this.state.dispCount),
        itemCount: segmentations.count, // 総数
        currentPage: this.state.selectedItem, // このタイミングで表示を切り替える
        histories:
          segmentations.results.length === 0
            ? []
            : segmentations.results.map((segmentation) => {
                return {
                  id: segmentation.id,
                  execDate: segmentation.requested_at === null ? '' : getDateYYYYMMDDhhmi(segmentation.requested_at),
                  completeDate:
                    segmentation.page_history === null ? '' : getDateYYYYMMDDhhmi(segmentation.page_history.created_at),
                  user: segmentation.username,
                  status: segmentation.status_name,
                  errorType: segmentation.error_type,
                  responseErrorHttpStatusCode: segmentation.response_error_http_status_code,
                  url: segmentation.page_history === null ? '' : segmentation.page_history.screenshot_url,
                  screenshotWidth: segmentation.page_history === null ? 0 : segmentation.page_history.screenshot_width,
                  contentLocations:
                    segmentation.page_history === null ? [] : segmentation.page_history.content_locations,
                }
              }),
        disabledCapture: segmentations.is_locked_cs_manually,
        errorMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        pageChange: false,
        errorMessage: typeof e === 'string' ? e : 'ページキャプチャの取得履歴の取得に失敗しました。',
      })
    }
  }

  getApiPath = (
    projectId: number,
    urlId: number,
    limit: number,
    offset: number,
    hideAuto: boolean,
    pageLayout: DeviceTypeForAPI,
  ) => {
    return `/api/projects/${projectId}/urls/${urlId}/content_segmentation/histories/?limit=${limit}&offset=${offset}&is_hide_autorun=${
      hideAuto ? 'true' : 'false'
    }&page_layout=${pageLayout}`
  }

  // 手動実行
  onCapture = async () => {
    try {
      await request(
        'POST',
        `/api/projects/${this.state.projectId}/urls/${this.state.urlId}/content_segmentation/`,
        true,
      )
      this.setState({
        ...this.state,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        errorMessage: typeof e === 'string' ? e : 'ページキャプチャの取得履歴の取得に失敗しました。',
      })
      return false
    }
    return true
  }

  // 自動取得の履歴を表示しないチェック
  onClickCheckbox = () => {
    this.setState({ ...this.state, checked: !this.state.checked, reload: true })
  }

  // 表示する件数変更
  onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ ...this.state, dispCount: Number(e.target.value), reload: true })
  }

  // アイテム選択
  onClickItem = (id: number, url: string) => {
    this.setState({ ...this.state, selectedId: id, imageUrl: url })
  }

  // グリッドの表示ページを変更
  onPageChange = async (selectedItem: { selected: number }) => {
    // ページが切り替わっていない場合は処理しない
    if (this.state.selectedItem === selectedItem.selected) {
      return
    }
    this.setState({
      ...this.state,
      pageChange: true,
      selectedItem: selectedItem.selected,
    })
  }
}

const initialState: State = {
  loading: true,
  reload: false,
  pageChange: false,
  projectId: 0,
  urlId: 0,
  pageLayout: 1,
  selectedId: 0,
  checked: false, // 自動取得履歴を取得しないのチェックは外しておく
  dispCount: 0,
  currentPage: 0,
  pageCount: 0,
  itemCount: 0,
  selectedItem: 0,
  options: [],
  histories: [],
  imageUrl: '',
  disabledCapture: false,
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
