import * as React from 'react'
import styled from 'styled-components'
import { aggregateScopeOptions, aggregateConditionOptions } from '../../../util/hooks/api/Filter/constants'
import { AggregateScopes, CustomFilterState } from '../../../util/hooks/api/Filter/types'
import { Select } from '../../common/Select'

interface AggregateScopeProps {
  filter: CustomFilterState
  filterIndex: number
  readonly onAggregateScopeChange: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly onAggregateConditionChange: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly scopeOptions?: { value: AggregateScopes; label: string }[]
}

export function AggregateScope({
  filter,
  filterIndex,
  onAggregateScopeChange,
  onAggregateConditionChange,
  scopeOptions,
}: AggregateScopeProps) {
  return (
    <>
      <Title>集計単位</Title>
      <ContentRow>
        <DesignedSelectAggregateScope
          value={filter.aggregate.unit}
          options={scopeOptions ?? aggregateScopeOptions}
          data-index={filterIndex}
          onChange={onAggregateScopeChange}
          data-testid="filter-aggregate-scope"
        />

        <DesignedSelectAggregateCondition
          value={filter.aggregate.condition}
          options={aggregateConditionOptions}
          data-index={filterIndex}
          onChange={onAggregateConditionChange}
          data-testid="filter-aggregate-included"
        />
      </ContentRow>
    </>
  )
}

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
`

const DesignedSelect = styled(Select)`
  height: 30px;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;

  & + & {
    margin-left: 1rem;
  }
`

const DesignedSelectAggregateScope = styled(DesignedSelect)`
  width: 150px;
`

const DesignedSelectAggregateCondition = styled(DesignedSelect)`
  width: 100px;
`

const ContentRow = styled.div`
  display: flex;
`
