import * as React from 'react'
import { RefObject } from 'react'
import styled from 'styled-components'

import { Toolbar } from '../../common/Toolbar'
import { Authority } from '../../../util/Authority'
import { PageLayout } from '../PageLayout'
import { ProjectContext } from '../../../pages/ProjectRoot'
import { GlobalContext } from '../../../GlobalState'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'
import { DeviceType, setSearchParamsDeviceType, useDeviceType } from '../../../util/hooks/useDeviceType'
import type { Item } from '../../common/ToolMenu'
import { makeProjectPageHeaderInfo } from '../../../util/makePageHeaderInfo'
import { PageHeader } from '../../common/PageHeader'
import { useReportSettings } from '../../../util/hooks/useReportSettings'
import { BreadcrumbsItemType } from '../../breadcrumbs/Breadcrumbs'
import { CustomFilterUseContext } from '../../filter/CustomFilter'

interface Props {
  readonly children: React.ReactNode
  readonly pageTitle: string
  readonly optionHidden?: boolean
  readonly goalHidden?: boolean
  readonly toolbarHidden?: boolean
  readonly filterHidden?: boolean
  readonly breadcrumbsItems?: BreadcrumbsItemType[]
  readonly mainAreaRef?: RefObject<HTMLDivElement> // 条件変更時のsmoothスクロールする際に使用する
  readonly reportAreaMaxWidth?: string
  readonly toolMenuItems?: Item[]
  readonly toolMenuLeftSideElement?: React.ReactElement
  readonly footerHidden?: boolean
  readonly footerAreaPadding?: number
  readonly onDateRangeApply?: () => void
  readonly onGoalChange?: () => void
  readonly onFilterReset?: () => void
  readonly onFilterApply?: () => void
  readonly onDeviceChange?: () => void
  readonly onScopeTypeChange?: () => void
}

/**
 * レポート関連ページのレイアウトをレンダリングする(PageLayoutのラッパー)
 *
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The child components to be rendered.
 * @param {string} props.pageTitle - The title of the page.
 * @param {boolean} props.optionHidden - ヘッダーのオプション(カレンダーやゴール選択ボタン)の非表示フラグ
 * @param {boolean} props.goalHidden - ゴール選択ボタンの非表示フラグ
 * @param {boolean} props.toolbarHidden - ツールバーの非表示フラグ
 * @param {boolean} props.filterHidden - フィルターボタンの非表示フラグ
 * @param {Array} props.breadcrumbsItems - パンくずリストの項目リスト
 * @param {React.RefObject} props.mainAreaRef - MainAreaの参照オブジェクト（各ページのスクロール処理に使用）
 * @param {string} props.reportAreaMaxWidth - ReportAreaの最大幅
 * @param {Array} props.toolMenuItems - ツールバーのツールメニュー項目リスト
 * @param {React.ReactElement} props.toolMenuLeftSideElement - ツールバーの左側に表示する要素
 * @param {boolean} props.footerHidden - フッターの非表示フラグ
 * @param {number} props.footerAreaPadding - フッターエリアのパディング
 * @param {Function} props.onDateRangeApply - カレンダー範囲の適用時に呼び出される関数
 * @param {Function} props.onGoalChange - ゴールが変更されたときに呼び出される関数
 * @param {Function} props.onFilterReset - カスタムフィルターのリセット時に呼び出される関数
 * @param {Function} props.onFilterApply - カスタムフィルターの適用時に呼び出される関数
 * @param {Function} props.onDeviceChange - デバイスが変更されたときに呼び出される関数
 * @param {Function} props.onScopeTypeChange - 集計範囲が変更されたときに呼び出される関数
 */
export function ReportPageLayout({
  children,
  pageTitle,
  optionHidden = false,
  goalHidden = false,
  toolbarHidden = false,
  filterHidden = false,
  breadcrumbsItems,
  mainAreaRef,
  reportAreaMaxWidth,
  toolMenuItems,
  toolMenuLeftSideElement,
  footerHidden = false,
  footerAreaPadding,
  onDateRangeApply,
  onGoalChange,
  onFilterReset,
  onFilterApply,
  onDeviceChange,
  onScopeTypeChange,
}: Props) {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { projectId, baseUrl },
  } = React.useContext(ProjectContext)
  const {
    state: { openedFilter },
    actions: customFilterActions,
  } = React.useContext(CustomFilterContext)

  const { deviceType, setDeviceType } = useDeviceType()

  const { isCompleted: isSettingsCompleted } = useReportSettings({ projectId })

  const handleDeviceChange = (deviceType: DeviceType) => {
    setSearchParamsDeviceType(deviceType)
    setDeviceType(deviceType)
    onDeviceChange && onDeviceChange()
  }

  const pageHeaderInfo = makeProjectPageHeaderInfo(pageTitle, AccountInfo)

  return (
    <>
      <PageHeader title={pageHeaderInfo.title} description={pageHeaderInfo.description} />
      <PageLayout
        headerTitle={pageTitle}
        optionHidden={optionHidden}
        goalHidden={goalHidden}
        baseUrl={baseUrl}
        breadcrumbsItems={breadcrumbsItems}
        isReportPage={true}
        footerHidden={footerHidden}
        footerAreaPadding={footerAreaPadding}
        toolbar={
          !toolbarHidden &&
          isSettingsCompleted && (
            <Toolbar
              canView={Authority.canViewReport(AccountInfo.permissions, projectId)}
              filterHidden={filterHidden}
              selectedDevice={deviceType}
              onFilterClick={customFilterActions.open}
              onChangeDevice={handleDeviceChange}
              toolMenuItems={toolMenuItems}
              onQuickFilterClick={onFilterApply}
              toolMenuLeftSideElement={toolMenuLeftSideElement}
            />
          )
        }
        onDateRangeApply={onDateRangeApply}
        onGoalChange={onGoalChange}
        onScopeTypeChange={onScopeTypeChange}
      >
        <MainArea ref={mainAreaRef}>
          {openedFilter && <CustomFilterUseContext onReset={onFilterReset} onApply={onFilterApply} />}
          <ReportAreaWrapper maxWidth={reportAreaMaxWidth}>{children}</ReportAreaWrapper>
        </MainArea>
      </PageLayout>
    </>
  )
}

const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ReportAreaWrapper = styled.div<{ maxWidth?: string }>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  padding: 32px;
`
