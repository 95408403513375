import React from 'react'
import { Modal, ModalButtonWrapper, ModalHeadText, ModalContentWrapper } from '../../common/Modal'
import { NormalButton, CancelButton } from '../../common/Button'
import { ErrorBox } from '../../common/ErrorBox'
import { useDataImportAllKeyFields } from '../../../util/hooks/api/DataImport/useDataImportAllKeyFields'
import { useDataImportSchemas } from '../../../util/hooks/api/DataImport/useDataImportSchemas'
import { useDataImportLogs } from '../../../util/hooks/api/DataImport/useDataImportLogs'
import { useDeleteDataImportSchemas } from '../../../util/hooks/api/DataImport/useDeleteDataImportSchemas'
import { useDeleteDataImportFieldSuggestion } from '../../../util/hooks/api/DataImport/useDeleteDataImportFieldSuggestion'
import { useLatestImportData } from '../../../util/hooks/api/DataImport/useLatestImportData'
import { useToast } from '../../../util/hooks/useToast'

interface Props {
  opened: boolean
  onClose: () => void
  setIsEditing: (value: boolean) => void
  projectId: string
  csvSuggestionId: number | null
  setCsvSuggestionId: React.Dispatch<React.SetStateAction<number | null>>
}

export function DeleteModal({ opened, onClose, setIsEditing, projectId, csvSuggestionId, setCsvSuggestionId }: Props) {
  const { openToast } = useToast()
  const { data, resetDataImportSchemas } = useDataImportSchemas({
    projectId,
  })
  const { resetLatestImportData } = useLatestImportData({ projectId })
  const { invalidate: invalidateImportLogs } = useDataImportLogs({
    projectId,
  })
  const { invalidate: invalidateAllKeyFields } = useDataImportAllKeyFields({
    projectId,
  })
  const { mutate: deleteSuggestionMutate } = useDeleteDataImportFieldSuggestion({
    projectId,
  })

  const {
    mutate: deleteDataImportSchemasMutate,
    isLoading: isDeletingDataImportSchemas,
    isError: isDeleteDataImportSchemasError,
    error: deleteError,
  } = useDeleteDataImportSchemas({
    projectId: projectId,
    options: {
      onSuccess: () => {
        if (csvSuggestionId) {
          deleteSuggestionMutate({ csvSuggestionId: csvSuggestionId })
          setCsvSuggestionId(null)
        }
        resetLatestImportData()
        resetDataImportSchemas()
        invalidateAllKeyFields()
        invalidateImportLogs()
        openToast({ message: 'インポートフィールドを削除しました。' })
        setIsEditing(false)
        onClose()
      },
    },
  })

  const onDelete = () => {
    const schemasId = data?.schemasId
    if (!schemasId) return
    deleteDataImportSchemasMutate({ schemasId })
  }

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      title="インポートフィールド設定削除"
      isLoading={isDeletingDataImportSchemas}
    >
      <ModalContentWrapper>
        {isDeleteDataImportSchemasError && (
          <ErrorBox>
            {typeof deleteError === 'string' ? deleteError : 'インポートフィールドの削除に失敗しました。'}
          </ErrorBox>
        )}
        <ModalHeadText>インポートフィールド設定を削除してよろしいですか？</ModalHeadText>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton onClick={onDelete}>実行</NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}
