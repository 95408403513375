import { useGenericMutation } from '../useApi'
import { EditTargetContentsData } from '../../Response'

type EditTargetContentRequestType = {
  content_name: string
  css_selector: string
}
export const useEditTargetContent = ({
  projectId,
  urlId,
  contentId,
}: {
  projectId: string
  urlId: string
  contentId: number
}) => {
  const mutationResults = useGenericMutation<EditTargetContentRequestType, string, EditTargetContentsData>(
    'PUT',
    `/projects/${projectId}/urls/${urlId}/target_contents/${contentId}/`,
    true,
  )
  return {
    editTargetContentMutate: mutationResults.mutate,
  }
}
