import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { FunnelReportPayloadData, FunnelReportResponse } from './types'
import { request } from '../../../request'
import { makeRequestBodyFromPayload } from './utils'

type Props = {
  projectId: number
  options?: UseMutationOptions<number, unknown, FunnelReportPayloadData>
}

export const useCreateFunnelReport = ({ projectId, options }: Props) => {
  return useMutation({
    mutationFn: async (payload) => {
      const requestBody = makeRequestBodyFromPayload(payload)
      const response = await request<FunnelReportResponse>(
        'POST',
        `/api/projects/${projectId}/funnel_reports/`,
        true,
        JSON.stringify(requestBody),
      )

      // 保存後に詳細ページに戻るためidのみ返す
      return response.id
    },
    ...options,
  })
}
