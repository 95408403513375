import * as React from 'react'
import { colors } from '../../styleConstants'
import { useToast } from '../../util/hooks/useToast'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useSelector } from '../../redux/Store'
import { toastState } from '../../redux/toastSlice'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const Toast = React.memo(() => {
  const { isOpen, message, duration } = useSelector(toastState)
  const { closeToast } = useToast()
  const onClose: (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => void = (_, reason) => {
    if (reason === 'clickaway') return
    closeToast()
  }
  return (
    <Snackbar
      transitionDuration={{ enter: 325, exit: 195 }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isOpen}
      autoHideDuration={duration}
      onClose={onClose}
      key={'bottom' + 'center'}
      sx={{
        '&.MuiPaper-root-MuiSnackbarContent-root': {
          borderRadius: 2,
        },
      }}
    >
      <Alert
        sx={{
          fontSize: 16,
          backgroundColor: colors.darkBlue,
          minWidth: 360,
          minHeight: 48,
          '& .MuiAlert-action': {
            padding: '6px 0 0 0',
          },
        }}
        icon={false}
        onClose={closeToast}
      >
        {message}
      </Alert>
    </Snackbar>
  )
})
