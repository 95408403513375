import { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '../../../request'
import { LATEST_IMPORT_DATA_QUERY_KEY } from '../constants'
import { LatestDataImportLogsResponse, ImportData } from './types'
import { IMPORT_LOG_POLLING_INTERVAL } from './constants'

type Props = {
  projectId: string
}

export const useLatestImportData = ({ projectId }: Props) => {
  const queryClient = useQueryClient()
  const queryKey = [LATEST_IMPORT_DATA_QUERY_KEY, { projectId }]

  const [isManualRefetching, setIsManualRefetching] = useState(false)
  const invalidate = async () => {
    setIsManualRefetching(true)
    // 連打防止のため1秒pendする
    await setTimeout(async () => {
      await queryClient.invalidateQueries({ queryKey })
      setIsManualRefetching(false)
    }, 1000)
  }
  const resetLatestImportData = () => {
    queryClient.setQueryData(queryKey, () => {
      return null
    })
  }

  const queryResult = useQuery<ImportData | null>({
    queryKey,
    queryFn: async () => {
      const response = await request<LatestDataImportLogsResponse>(
        'GET',
        `/api/projects/${projectId}/data_import_latests/`,
        true,
      )
      if (!response.results.length) return null
      const latestData = response.results[0]
      return {
        importData: latestData.data,
        metaData: {
          id: latestData.id,
          createdAt: latestData.data_import_log.created_at,
          summarySuccessedDataCount: latestData.data_import_log.summary_successed_data_count,
        },
      }
    },
    enabled: projectId !== '',
    refetchInterval: IMPORT_LOG_POLLING_INTERVAL,
  })
  return {
    ...queryResult,
    isManualRefetching,
    invalidate,
    resetLatestImportData,
  }
}
