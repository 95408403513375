import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Content } from '../../../util/hooks/api/useContentReport'
import { BaseCell } from './BaseCell'
import { colors } from '../../../styleConstants'
import { InteractiveTooltip, TOOLTIP_DELAY } from '../../Tooltip'

interface Props {
  readonly item: Content
}

export function ContentNameCell({ item }: Props) {
  const [ellipsisIndex, setEllipsisIndex] = useState<number | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  // ブラウザサイズ変更により要素の幅が変わる場合、どの要素から省略記号(3点リーダー)表示するか判定する
  useEffect(() => {
    const containerElement = containerRef.current
    if (!containerElement) return

    // containerElement内の最後の要素はタグではなく省略記号用のDiv要素(TagHiddenEllipsis)のため除外して計算する必要あり
    const tagHiddenEllipsis = containerElement.lastElementChild
    const tagsCount = containerElement.childElementCount - 1

    const observer = new ResizeObserver(() => {
      let totalWidth = 0
      let ellipsisIndex: number | null = null

      containerElement.childNodes.forEach((item, index) => {
        if (ellipsisIndex !== null) return

        if (tagHiddenEllipsis === item || !(item instanceof HTMLDivElement)) return

        const lastElemReached = index + 1 === tagsCount
        totalWidth += item.offsetWidth + (lastElemReached ? 0 : TAGS_GAP_SIZE)

        // タグが1つ以上非表示を知らせるLastEllipsisの幅を考慮してチェックする
        if (containerElement.offsetWidth - TAG_HIDDEN_ELLIPSIS_WIDTH_WITH_GAP < totalWidth) {
          ellipsisIndex = index
        }
      })

      setEllipsisIndex(ellipsisIndex)
    })

    observer.observe(containerElement)

    return () => observer.unobserve(containerElement)
  }, [item.tags])

  const name = item.name || '-'
  return (
    <Cell data-id={item.id}>
      <NameWrapper>
        {item.isAutoSetName && <AutoLabel>自動</AutoLabel>}
        <InteractiveTooltip content={name} delay={TOOLTIP_DELAY}>
          <Name>{name}</Name>
        </InteractiveTooltip>
      </NameWrapper>

      {0 < item.tags.length && (
        <Tags ref={containerRef}>
          {item.tags.map((tag, index) => (
            <Tag
              key={tag.id}
              ellipsis={index === ellipsisIndex}
              hidden={ellipsisIndex !== null && index > ellipsisIndex}
            >
              {tag.name}
            </Tag>
          ))}
          <TagHiddenEllipsis hidden={ellipsisIndex === null || item.tags.length === ellipsisIndex + 1}>
            …
          </TagHiddenEllipsis>
        </Tags>
      )}
    </Cell>
  )
}

const Cell = styled(BaseCell)`
  justify-content: center;
  flex-direction: column;
  padding: 6px 8px;
  gap: 2px;
`

const Name = styled.div`
  width: 100%;
  display: block;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TAGS_GAP_SIZE = 6

const Tags = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: ${TAGS_GAP_SIZE}px;
`

const NameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
`

const AutoLabel = styled.div`
  font-size: 10px;
  color: ${colors.purple};
  font-weight: bold;
  border: 1px solid ${colors.purple};
  padding: 1px 4px;
`

interface TagProps {
  ellipsis?: boolean
  hidden?: boolean
}

const Tag = styled.div<TagProps>`
  font-size: 10px;
  line-height: 15px;
  background-color: ${colors.green + colors.opacity[40]};
  border-radius: 3px;
  padding: 0 4px;
  white-space: nowrap;

  ${(props) =>
    props.ellipsis &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${(props) => props.hidden && `display: none;`}
`

const TAG_HIDDEN_ELLIPSIS_WIDTH = 20
const TAG_HIDDEN_ELLIPSIS_WIDTH_WITH_GAP = TAG_HIDDEN_ELLIPSIS_WIDTH + TAGS_GAP_SIZE

// タグ非表示を知らせる省略記号
const TagHiddenEllipsis = styled.div<{ hidden: boolean }>`
  font-size: 10px;
  line-height: 15px;
  width: ${TAG_HIDDEN_ELLIPSIS_WIDTH}px;

  ${(props) => props.hidden && `display: none;`}
`
