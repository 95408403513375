import * as React from 'react'
import styled from 'styled-components'

import { GRID_MIN_WIDTH } from '..'
import { ReportContext } from '../../../contexts/ReportProvider'
import { Actions, State, TAB_INDEX } from '../../../pages/ContentReport/state'
import { PvLimitErrorBox } from '../../common/PvLimitErrorBox'
import { TabPanel } from '../../tab/TabPanel'
import { TabArea } from '../TabArea'
import { AllContentsTab } from './AllContentsTab'
import { ContentGroupTab } from './ContentGroupTab'
import { useQuadrantChart } from '../../../util/hooks/cookie/useQuadrantChart'
import { UrlParamsErrorMessages } from '../../reportMessages/UrlParamsErrorMessages'

interface ContentReportTableAreaProps {
  pageState: State
  actions: Actions
  readonly setAllContentsDisplayHistoryId: (newValue: number) => void
  readonly setContentsGroupDisplayHistoryId: (newValue: number) => void
  readonly projectId: string
  readonly reportId: string
}
export function TableArea(props: ContentReportTableAreaProps) {
  const {
    state: { teamPvLimit, teamPvCount },
  } = React.useContext(ReportContext)

  const { selectedTabIndex } = props.pageState
  const { setChartOpened } = useQuadrantChart()

  React.useEffect(() => {
    setChartOpened(false)
  }, [])

  return (
    <Container>
      <TabArea tabIndex={selectedTabIndex} onChange={props.actions.onTabChange} />

      <TabPanelArea>
        {teamPvLimit <= teamPvCount && (
          <ErrorMessageArea>
            <PvLimitErrorBox pvLimit={teamPvLimit} />
          </ErrorMessageArea>
        )}
        <UrlParamsErrorMessages />

        <TabPanel value={selectedTabIndex} index={TAB_INDEX.ALL_CONTENTS}>
          <AllContentsTab {...props} />
        </TabPanel>

        <TabPanel value={selectedTabIndex} index={TAB_INDEX.CONTENT_GROUP}>
          <ContentGroupTab {...props} />
        </TabPanel>
      </TabPanelArea>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 100%;
`

const ErrorMessageArea = styled.div`
  width: 100%;
  min-width: ${GRID_MIN_WIDTH}px;
`

const TabPanelArea = styled.div`
  height: calc(100% - 52px);
  padding: 16px 32px 32px;
  overflow-x: auto;
`
