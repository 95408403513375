import * as React from 'react'
import styled from 'styled-components'
import { PageSummary } from '../../../util/hooks/api/useContentReport'
import { PageSummaryArea } from './PageSummaryArea'

interface Props {
  readonly pageSummary: PageSummary
  readonly children: React.ReactNode
}

export function GridArea({ pageSummary, children }: Props) {
  return (
    <Container>
      <PageSummaryArea pageSummary={pageSummary} />
      <GridWrapper>{children}</GridWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  z-index: 0;
  height: 100%;
`

const GridWrapper = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
`
