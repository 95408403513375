import { useQuery } from '@tanstack/react-query'

import { PROJECT_QUERY_KEY } from '../constants'
import { request } from '../../../request'
import { Project, ProjectResponse } from './types'
import { parseDate } from '../../../Date'
import { transformTeam } from '../Team/utils'

interface Props {
  projectId: number
}

export const useProject = ({ projectId }: Props) => {
  const queryKey = [PROJECT_QUERY_KEY, projectId]

  return useQuery({
    queryKey: queryKey,
    queryFn: () => fetch(projectId),
    select: transform,
    staleTime: 1000 * 60 * 5,
  })
}

const fetch = async (projectId: number) => {
  return await request<ProjectResponse>('GET', `/api/projects/${projectId}/`, true)
}

const transform = (response: ProjectResponse): Project => {
  return {
    id: response.id,
    name: response.name,
    url: response.url,
    tag: response.tag,
    createdAt: parseDate(response.created_at),
    team: transformTeam(response.team),
  }
}
