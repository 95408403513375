import * as React from 'react'
import { request } from '../../util/request'
import { getDateYYYYMMDDhhmi } from '../../util/Date'
import { CsErrorType, PageContentSegmentationHistoriesData } from '../../util/Response'
import { OptionProps } from '../../components/common/Select'
import { DeviceType } from '../../util/hooks/useDeviceType'

export type CaptureHistoryData = {
  readonly id: number
  readonly execDate: string
  readonly completeDate: string
  readonly user: string
  readonly status: string
  readonly errorType: CsErrorType | null
  readonly responseErrorHttpStatusCode: number | null
  readonly url: string
  readonly screenshotWidth: number
  readonly contentLocations: Array<{
    readonly id: number
    readonly partial_content_id: string
    readonly top_left_y: number
    readonly top_left_x: number
    readonly height: number
    readonly width: number
  }>
}

export interface CaptureHistoryState {
  readonly loading: boolean
  readonly reload: boolean
  readonly pageChange: boolean
  readonly projectId: number
  readonly urlId: number
  readonly selectedId: number
  readonly checked: boolean
  readonly dispCount: number
  readonly currentPage: number
  readonly pageCount: number // コンテンツページ数
  readonly itemCount: number // アイテム総数
  readonly selectedItem: number
  readonly options: OptionProps[]
  readonly histories: CaptureHistoryData[]
  readonly imageUrl: string
  readonly disabledCapture: boolean
  readonly errorMessage?: string
  readonly deviceType: DeviceType
  readonly isConfirmModalOpened: boolean
}

export class CaptureHistoryActions {
  constructor(
    private readonly state: CaptureHistoryState,
    private readonly setState: React.Dispatch<React.SetStateAction<CaptureHistoryState>>,
  ) {}
  getCaptureHistory = async (projectId: number, urlId: number, deviceType: DeviceType) => {
    try {
      // 初期表示数

      const dispCount = 10

      const segmentations: PageContentSegmentationHistoriesData = await request(
        'GET',
        this.getApiPath(projectId, urlId, dispCount, 0, false, deviceType),
        true,
      )
      this.setState({
        ...this.state,
        loading: false,
        projectId: projectId,
        urlId: urlId,
        dispCount: dispCount, // 初期表示数
        currentPage: 0,
        pageCount: Math.ceil(segmentations.count / dispCount),
        itemCount: segmentations.count, // 総数
        selectedItem: 0,
        options: [
          { label: '10', value: 10 },
          { label: '20', value: 20 },
          { label: '30', value: 30 },
          { label: '50', value: 50 },
          { label: '100', value: 100 },
        ],
        histories:
          segmentations.results.length === 0
            ? []
            : segmentations.results.map((segmentation) => {
                return {
                  id: segmentation.id,
                  execDate: segmentation.requested_at === null ? '' : getDateYYYYMMDDhhmi(segmentation.requested_at),
                  completeDate:
                    segmentation.page_history === null ? '' : getDateYYYYMMDDhhmi(segmentation.page_history.created_at),
                  user: segmentation.username,
                  status: segmentation.status_name,
                  errorType: segmentation.error_type,
                  responseErrorHttpStatusCode: segmentation.response_error_http_status_code,
                  url: segmentation.page_history === null ? '' : segmentation.page_history.screenshot_url,
                  screenshotWidth: segmentation.page_history === null ? 0 : segmentation.page_history.screenshot_width,
                  contentLocations:
                    segmentation.page_history === null ? [] : segmentation.page_history.content_locations,
                }
              }),
        disabledCapture: segmentations.is_locked_cs_manually,
        deviceType,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        errorMessage: typeof e === 'string' ? e : 'ページキャプチャの取得履歴の取得に失敗しました。',
      })
    }
  }

  reGetCaptureHistory = async () => {
    try {
      const segmentations: PageContentSegmentationHistoriesData = await request(
        'GET',
        this.getApiPath(
          this.state.projectId,
          this.state.urlId,
          this.state.dispCount,
          0,
          this.state.checked,
          this.state.deviceType,
        ),
        true,
      )

      this.setState({
        ...this.state,
        reload: false,
        selectedId: 0, // 選択解除
        imageUrl: '', // 選択解除
        currentPage: 0,
        pageCount: Math.ceil(segmentations.count / this.state.dispCount),
        itemCount: segmentations.count, // 総数
        selectedItem: 0,
        histories:
          segmentations.results.length === 0
            ? []
            : segmentations.results.map((segmentation) => {
                return {
                  id: segmentation.id,
                  execDate: segmentation.requested_at === null ? '' : getDateYYYYMMDDhhmi(segmentation.requested_at),
                  completeDate:
                    segmentation.page_history === null ? '' : getDateYYYYMMDDhhmi(segmentation.page_history.created_at),
                  user: segmentation.username,
                  status: segmentation.status_name,
                  errorType: segmentation.error_type,
                  responseErrorHttpStatusCode: segmentation.response_error_http_status_code,
                  url: segmentation.page_history === null ? '' : segmentation.page_history.screenshot_url,
                  screenshotWidth: segmentation.page_history === null ? 0 : segmentation.page_history.screenshot_width,
                  contentLocations:
                    segmentation.page_history === null ? [] : segmentation.page_history.content_locations,
                }
              }),
        disabledCapture: segmentations.is_locked_cs_manually,

        errorMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        reload: false,
        errorMessage: typeof e === 'string' ? e : 'ページキャプチャの取得履歴の取得に失敗しました。',
      })
    }
  }

  fetchNextPage = async () => {
    try {
      const segmentations: PageContentSegmentationHistoriesData = await request(
        'GET',
        this.getApiPath(
          this.state.projectId,
          this.state.urlId,
          this.state.dispCount,
          this.state.selectedItem * this.state.dispCount,
          this.state.checked,
          this.state.deviceType,
        ),
        true,
      )

      this.setState({
        ...this.state,
        pageChange: false,
        selectedId: 0, // 選択解除
        imageUrl: '', // 選択解除
        pageCount: Math.ceil(segmentations.count / this.state.dispCount),
        itemCount: segmentations.count, // 総数
        currentPage: this.state.selectedItem, // このタイミングで表示を切り替える
        histories:
          segmentations.results.length === 0
            ? []
            : segmentations.results.map((segmentation) => {
                return {
                  id: segmentation.id,
                  execDate: segmentation.requested_at === null ? '' : getDateYYYYMMDDhhmi(segmentation.requested_at),
                  completeDate:
                    segmentation.page_history === null ? '' : getDateYYYYMMDDhhmi(segmentation.page_history.created_at),
                  user: segmentation.username,
                  status: segmentation.status_name,
                  errorType: segmentation.error_type,
                  responseErrorHttpStatusCode: segmentation.response_error_http_status_code,
                  url: segmentation.page_history === null ? '' : segmentation.page_history.screenshot_url,
                  screenshotWidth: segmentation.page_history === null ? 0 : segmentation.page_history.screenshot_width,
                  contentLocations:
                    segmentation.page_history === null ? [] : segmentation.page_history.content_locations,
                }
              }),
        disabledCapture: segmentations.is_locked_cs_manually,
        errorMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        pageChange: false,
        errorMessage: typeof e === 'string' ? e : 'ページキャプチャの取得履歴の取得に失敗しました。',
      })
    }
  }

  getApiPath = (
    projectId: number,
    urlId: number,
    limit: number,
    offset: number,
    hideAuto: boolean,
    deviceType: DeviceType,
  ) => {
    return `/api/projects/${projectId}/urls/${urlId}/content_segmentation/histories/?limit=${limit}&offset=${offset}&is_hide_autorun=${
      hideAuto ? 'true' : 'false'
    }&page_layout=${deviceType === 'pc' ? 2 : 1}`
  }

  // 手動実行
  onCapture = async () => {
    try {
      await request(
        'POST',
        `/api/projects/${this.state.projectId}/urls/${this.state.urlId}/content_segmentation/`,
        true,
      )
      this.setState({
        ...this.state,
        isConfirmModalOpened: true,
        reload: true,
      })
    } catch (e) {
      this.setState({
        ...this.state,
        errorMessage: typeof e === 'string' ? e : 'ページキャプチャの取得履歴の取得に失敗しました。',
      })
      return false
    }
    return true
  }

  // 自動取得の履歴を表示しないチェック
  onClickCheckbox = () => {
    this.setState({ ...this.state, checked: !this.state.checked, reload: true })
  }

  // 表示する件数変更
  onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ ...this.state, dispCount: Number(e.target.value), reload: true })
  }

  // アイテム選択
  onClickItem = (id: number, url: string) => {
    this.setState({ ...this.state, selectedId: id, imageUrl: url })
  }

  // グリッドの表示ページを変更
  onPageChange = async (selectedItem: { selected: number }) => {
    // ページが切り替わっていない場合は処理しない
    if (this.state.selectedItem === selectedItem.selected) {
      return
    }
    this.setState({
      ...this.state,
      pageChange: true,
      selectedItem: selectedItem.selected,
    })
  }
  onComplete = () => {
    this.setState({
      ...this.state,
      isConfirmModalOpened: false,
    })
  }
}

export const captureHistoryInitialState = {
  loading: true,
  reload: false,
  pageChange: false,
  projectId: 0,
  urlId: 0,
  selectedId: 0,
  checked: false, // 自動取得履歴を取得しないのチェックは外しておく
  dispCount: 0,
  currentPage: 0,
  pageCount: 0,
  itemCount: 0,
  selectedItem: 0,
  options: [],
  histories: [],
  imageUrl: '',
  disabledCapture: false,
  deviceType: 'mobile' as DeviceType,
  isConfirmModalOpened: false,
}

export function useCaptureHistoryState() {
  const [state, setState] = React.useState<CaptureHistoryState>(captureHistoryInitialState)
  return {
    captureHistoryState: state,
    captureHistoryActions: new CaptureHistoryActions(state, setState),
  }
}
