import React from 'react'
import styled from 'styled-components'
import { Warning } from '@styled-icons/icomoon/Warning'

import { colors } from '../../styleConstants'

interface Props {
  readonly children: React.ReactNode
}

export const WarningBigBox = ({ children }: Props) => {
  return (
    <Container>
      <IconArea>
        <Warning size={30} color={colors.white} />
      </IconArea>
      <MessageArea>{children}</MessageArea>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  border: solid 2px ${colors.contentOrange.orange6};
  background-color: ${colors.contentOrange.orange6};
`

const IconArea = styled.div`
  padding: 0 12px;
`

const MessageArea = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${colors.white};
`
