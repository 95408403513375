import React from 'react'
import styled from 'styled-components'
import {
  FunnelData,
  FunnelReportEditData,
  FunnelStepData,
  EditingStepIndex,
} from '../../../util/hooks/api/FunnelReport/types'
import { FunnelStepRequired } from './FunnelStepRequired'
import { Steps } from './Steps'
import { Input } from '../../../components/common/Input'
import { colors } from '../../../styleConstants'
import { CustomFilterState } from '../../../util/hooks/api/Filter/types'
import { MAX_FUNNEL_LENGTH, MAX_FUNNEL_NAME_LENGTH } from '../../../util/hooks/api/FunnelReport/constants'
import { FunnelContainer, InputErrorMessages } from '../Report/Funnels'
import { AddToQueue } from 'styled-icons/boxicons-regular'
import { DeleteBin5 } from 'styled-icons/remix-line'
import { SolidCircleButton, SolidCircleButtonGray } from '../../../components/common/CircleButton'
import { InputErrorMessage } from '../../../components/errors/InputErrorMessage'
import {
  checkFilterCustomDimensionError,
  checkFilterDataImportError,
  checkFilterGoalError,
  checkStepGoalError,
} from './utils'
import { FunnelFilterEditButton } from '../../../components/FunnelFilterEditButton'
import { deepCopy } from '../../../util/copy'
import { generateCopyName } from '../../../util/hooks/api/FunnelReport/utils'

interface Props {
  funnel: FunnelData
  funnelIndex: number
  isExceedFunnel: boolean
  updateEditingData: (newData: (draft: FunnelReportEditData) => void) => void
  setEditingStepIndex: React.Dispatch<React.SetStateAction<EditingStepIndex | null>>
  setEditingStep: React.Dispatch<React.SetStateAction<FunnelStepData>>
  setIsStepModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  setIsFilterModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  setFunnelIndexForEditingFilter: React.Dispatch<React.SetStateAction<number | null>>
  setEditingFilter: React.Dispatch<React.SetStateAction<CustomFilterState[] | null>>
  resetAccessCount: (funnelIndex: number, stepIndex: number) => void
  stepTitleRefs: React.MutableRefObject<(HTMLDivElement | null)[][]>
  maxHeights: number[]
}

export const Funnel = ({
  funnel,
  funnelIndex,
  isExceedFunnel,
  updateEditingData,
  setEditingStepIndex,
  setEditingStep,
  setIsStepModalOpened,
  setIsFilterModalOpened,
  setFunnelIndexForEditingFilter,
  setEditingFilter,
  resetAccessCount,
  stepTitleRefs,
  maxHeights,
}: Props) => {
  const hasStepGoalError = checkStepGoalError(funnel.steps)
  const hasFilterGoalError = checkFilterGoalError(funnel.filters)
  const hasFilterCustomDimensionError = checkFilterCustomDimensionError(funnel.filters)
  const hasFilterDataImportError = checkFilterDataImportError(funnel.filters)

  // エラーメッセージは詳細画面の表示と合わせるため、バックエンドから返ってきたerrorsを使用する
  const editErrors =
    funnel.errors?.filter((message) => {
      if (hasStepGoalError && message.includes('ステップで指定したゴール')) return true
      if (hasFilterGoalError && message.includes('フィルタで指定したゴール')) return true
      if (hasFilterCustomDimensionError && message.includes('フィルタで指定したカスタムディメンション')) return true
      if (hasFilterDataImportError && message.includes('フィルタで指定したインポートデータ')) return true
      return false
    }) || []
  const hasError = editErrors.length > 0
  const hasFilterError = hasFilterGoalError || hasFilterCustomDimensionError || hasFilterDataImportError

  const handleChangeFunnelName = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditingData((draft) => {
      draft.funnels[funnelIndex].name = e.target.value
    })
  }

  const handleChangeFunnelStepRequired = () => {
    updateEditingData((draft) => {
      draft.funnels[funnelIndex].stepRequired = !draft.funnels[funnelIndex].stepRequired
    })
    resetAccessCount(funnelIndex, 0)
  }

  const handleDeleteFunnel = () => {
    updateEditingData((draft) => {
      draft.funnels.splice(funnelIndex, 1)
    })
  }

  const handleDuplicateFunnel = () => {
    const duplicateFunnel = deepCopy(funnel) as FunnelReportEditData['funnels'][number] // NOTE: structuredClone メソッドはテストでサポートされていないため使えなかった
    duplicateFunnel.name = generateCopyName(duplicateFunnel.name, MAX_FUNNEL_NAME_LENGTH)

    updateEditingData((draft) => {
      draft.funnels.push(duplicateFunnel)
    })
  }

  const handleOpenFilterModal = () => {
    setEditingFilter(funnel.filters)
    setFunnelIndexForEditingFilter(funnelIndex)
    setIsFilterModalOpened(true)
  }

  return (
    <FunnelContainer hasError={hasError} data-testid={`funnel-${funnelIndex + 1}`}>
      <FunnelControlButtons>
        {!isExceedFunnel && (
          <SolidCircleButton title="ファネルをコピー" onClick={handleDuplicateFunnel} size={36}>
            <AddToQueue size={20} />
          </SolidCircleButton>
        )}

        <SolidCircleButtonGray title="ファネルを削除" onClick={handleDeleteFunnel} size={36}>
          <DeleteBin5 size={20} />
        </SolidCircleButtonGray>
      </FunnelControlButtons>

      <FunnelName>
        <label>
          <FunnelNameHead>
            <FunnelNameTitle>ファネル名</FunnelNameTitle>
            <FunnelNameIndex>
              （{funnelIndex + 1}/{MAX_FUNNEL_LENGTH}）
            </FunnelNameIndex>
          </FunnelNameHead>
          <FunnelNameInput
            value={funnel.name}
            onChange={handleChangeFunnelName}
            placeholder="任意項目"
            maxLength={MAX_FUNNEL_NAME_LENGTH}
          />
        </label>
      </FunnelName>

      {hasError && (
        <InputErrorMessages>
          {editErrors.map((message, index) => (
            <InputErrorMessage key={index}>{message}</InputErrorMessage>
          ))}
        </InputErrorMessages>
      )}

      <FunnelFilterEditButton
        filterLength={funnel.filters.length}
        hasFilterError={hasFilterError}
        onClick={handleOpenFilterModal}
      />

      <Border />

      <FunnelStepRequired checked={funnel.stepRequired} onChange={handleChangeFunnelStepRequired} />

      <Steps
        steps={funnel.steps}
        funnelIndex={funnelIndex}
        updateEditingData={updateEditingData}
        setEditingStepIndex={setEditingStepIndex}
        setEditingStep={setEditingStep}
        setIsStepModalOpened={setIsStepModalOpened}
        resetAccessCount={resetAccessCount}
        setRefs={(refArray) => (stepTitleRefs.current[funnelIndex] = refArray)}
        maxHeights={maxHeights}
      />
    </FunnelContainer>
  )
}

const FunnelControlButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -8px -8px 0;
`

const FunnelName = styled.div`
  margin-bottom: 16px;
`

const FunnelNameHead = styled.div`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
`
const FunnelNameTitle = styled.span`
  color: ${colors.black};
`
const FunnelNameIndex = styled.span`
  color: ${colors.gray750};
`

const FunnelNameInput = styled(Input)`
  width: 100%;
`

const Border = styled.div`
  margin-bottom: 18px;
  border-bottom: 1px solid ${colors.blue};
`
