import * as React from 'react'
import styled from 'styled-components'
import SplitPane from 'react-split-pane/lib/SplitPane'
import Pane from 'react-split-pane/lib/Pane'

import { Actions, State } from '../../pages/ContentReport/state'
import { calcImageWidth } from '../../pages/ContentReport/resizeState'
import { CaptureArea } from './CaptureArea'
import { TableArea } from './TableArea'
import { useDeviceType } from '../../util/hooks/useDeviceType'
import { useContentReportSplitWidth } from '../../util/hooks/cookie/useContentReportSplitWidth'

interface Props {
  readonly actions: Actions
  readonly gridRef: React.RefObject<HTMLDivElement>
  readonly pageState: State
  readonly pageId?: number
  readonly allContentsDisplayHistoryId: number
  readonly contentsGroupDisplayHistoryId: number
  readonly setAllContentsDisplayHistoryId: (newValue: number) => void
  readonly setContentsGroupDisplayHistoryId: (newValue: number) => void
  readonly projectId: string
  readonly reportId: string
}

export function ReportArea({
  actions,
  gridRef,
  pageState,
  allContentsDisplayHistoryId,
  contentsGroupDisplayHistoryId,
  setAllContentsDisplayHistoryId,
  setContentsGroupDisplayHistoryId,
  projectId,
  reportId,
}: Props) {
  const { deviceType } = useDeviceType()
  const { splitWidth, setSplitWidth } = useContentReportSplitWidth({ deviceType, reportId })

  // 表示位置計算
  const imageWidth = calcImageWidth(splitWidth)

  return (
    <Container ref={gridRef}>
      <SplitPane
        split="vertical"
        onResizeEnd={([width]) => {
          const newWidth = parseInt(width.replace(/px/g, ''), 10)
          setSplitWidth(newWidth, reportId)
        }}
      >
        <Pane initialSize={`${splitWidth || imageWidth}px`}>
          {/* コンテンツ表示にコンテナの情報が必要なため、ロード中にも見えないように表示は行っておく */}
          {/* ロード中完全に非表示にしてしまうとdivのパラメータが取得できない問題が発生するため */}
          <CaptureArea
            pageState={pageState}
            actions={actions}
            imageWidth={imageWidth}
            allContentsDisplayHistoryId={allContentsDisplayHistoryId}
            contentsGroupDisplayHistoryId={contentsGroupDisplayHistoryId}
            projectId={projectId}
            reportId={reportId}
          />
        </Pane>
        <Pane>
          <TableArea
            pageState={pageState}
            actions={actions}
            setAllContentsDisplayHistoryId={setAllContentsDisplayHistoryId}
            setContentsGroupDisplayHistoryId={setContentsGroupDisplayHistoryId}
            projectId={projectId}
            reportId={reportId}
          />
        </Pane>
      </SplitPane>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  height: 100%;
  overflow-y: hidden;
`
