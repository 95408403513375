import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'

type Props = {
  projectId: string
  options: UseMutationOptions<void, string, number, void>
}

export const useUpdateDataImportFieldSuggestion = ({ projectId, options }: Props) => {
  return useMutation({
    mutationFn: async (id: number) => {
      // APIにbodyを渡さなくてOK（APIで有効化するような仕様となっている）
      await request<{}>('PATCH', `/api/projects/${projectId}/data_import_field_suggestion/${id}/`, true)
    },
    ...options,
  })
}
