import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../styleConstants'
import { CaptureHistoryData } from '../../pages/PageCapture/CaptureHistoryState'

interface Props {
  readonly selectedId: number
  readonly histories: CaptureHistoryData[]
}

export function ScreenshotBox({ histories, selectedId }: Props) {
  const getScreenshot = () => {
    const history = histories?.find((history) => history.id === selectedId)
    if (!history) return { screenshotWidth: 0, imageUrl: '' }

    return { screenshotWidth: history.screenshotWidth, imageUrl: history.url }
  }

  const { screenshotWidth, imageUrl } = getScreenshot()
  const targetAreaRef = React.useRef<HTMLDivElement>(null)

  // スクロールバーを含まない幅(実数値)
  // 実数値でないとページが長いと後半部分のコンテンツ位置がずれます
  // see. https://github.com/uncovertruth/content-analytics/issues/1925#issuecomment-1473480469
  const inner = targetAreaRef.current?.getBoundingClientRect().width

  const ratio = inner! / screenshotWidth
  const contents = histories?.find((history) => history.id === selectedId)?.contentLocations

  return (
    <Container>
      <ScrollBox>
        <ImageBox ref={targetAreaRef}>
          {imageUrl === '' ? (
            <NoImageMessageBox>
              ステータスが成功の履歴を選択すると、
              <br />
              取得済み情報をプレビュー表示します
            </NoImageMessageBox>
          ) : (
            <>
              <Image src={imageUrl} />
              {contents &&
                contents.map((content) => {
                  return (
                    <ContentFrame
                      id={`${content.id}-box`}
                      key={`${content.id}-key`}
                      width={content.width}
                      height={content.height}
                      topLeftY={content.top_left_y}
                      topLeftX={content.top_left_x}
                      ratio={ratio}
                    />
                  )
                })}
            </>
          )}
        </ImageBox>
      </ScrollBox>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.gray300};
  background-color: ${colors.white};
`

const ScrollBox = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
`

const ImageBox = styled.div`
  width: 100%;
  height: 100%;
`

const NoImageMessageBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`

const Image = styled.img`
  width: 100%;
`

const ContentFrame = styled.div<{
  width: number
  height: number
  topLeftY: number
  topLeftX: number
  ratio: number
}>`
  position: absolute;
  width: ${({ width, ratio }) => width * ratio}px;
  height: ${({ height, ratio }) => height * ratio}px;
  top: ${({ topLeftY, ratio }) => topLeftY * ratio}px;
  left: ${({ topLeftX, ratio }) => topLeftX * ratio}px;
  z-index: 20;
  background-color: rgba(255, 30, 0, 0.1);
  border: 2px solid ${colors.orange};
`
