import React from 'react'

import { WarningBox } from '../WarningBox'

/**
 * ユーザーに閲覧権限がないことを示すメッセージを表示する
 */
export const CanNotViewMessage = () => {
  return <WarningBox>閲覧権限がありません。</WarningBox>
}
