import * as React from 'react'
import styled from 'styled-components'

import { SortItemKey, SortState } from '../../../util/hooks/api/useContentReport'
import { SortButton } from '../../common/SortButton'
import { BaseHeaderCell } from './BaseCell'
import { HeaderCellHelp } from './HeaderCellHelp'
import { useScopeType } from '../../../util/hooks/useScopeType'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'

interface Props {
  readonly sortState: SortState
  readonly onSortClick: (key: SortItemKey) => void
}

export function GoalCountHeaderCell({ sortState, onSortClick }: Props) {
  const { scopeTypeLabel } = useScopeType()

  return (
    <Cell onClick={() => onSortClick('goalCount')}>
      数<HeaderCellHelp>コンテンツを閲覧後にゴールに到達した{scopeTypeLabel}数</HeaderCellHelp>
      <SortButton elementKey={'goalCount'} sortIcon={sortState['goalCount']} />
    </Cell>
  )
}

const Cell = styled(BaseHeaderCell)`
  background-color: ${CELL_BACKGROUND_COLORS.GOAL};
`
