import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { DeleteBin5 } from '@styled-icons/remix-line'

interface BaseProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const BorderBase = styled.button`
  display: block;
  padding: 7px;
  border: 1px solid ${colors.gray550};
  border-radius: 99px;
  background: #fff;
  box-shadow: 0px 2px 4px #00000033;
  cursor: pointer;
  &:disabled {
    background-color: ${colors.gray350};
    box-shadow: none;
    cursor: auto;
  }
`

interface BorderProps extends BaseProps {
  readonly onClick: () => void
  readonly disabled?: boolean
}

export const BorderCircleButton = ({ ...props }: BorderProps) => {
  return <BorderBase {...props} />
}

export const BorderCircleButtonDelete = ({ onClick, disabled = false }: BorderProps) => {
  return (
    <BorderCircleButton onClick={onClick} disabled={disabled}>
      <DeleteBin5 size={20} color={colors.gray550} />
    </BorderCircleButton>
  )
}

const SolidBase = styled.button<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
  `}
  border-radius: 50%;
  transition: all 0.2s linear;
  color: ${colors.contentBlue.blue7};
  background-color: transparent;
  &:hover {
    background-color: ${colors.contentBlue.blue5};
    color: ${colors.white};
  }
`

interface SolidProps extends BaseProps {
  readonly onClick: () => void
  readonly size: number
}

export const SolidCircleButton = ({ ...props }: SolidProps) => {
  return <SolidBase {...props} />
}

export const SolidCircleButtonGray = styled(SolidCircleButton)`
  color: ${colors.gray750};
  &:hover {
    background-color: ${colors.gray550};
    color: ${colors.white};
  }
`

export const SolidCircleButtonDarkBlue = styled(SolidCircleButton)`
  background-color: ${colors.contentBlue.blue7};
  color: ${colors.white};
  &:hover {
    background-color: ${colors.contentBlue.blue10};
    color: ${colors.white};
  }
`
