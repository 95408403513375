import * as React from 'react'
import styled from 'styled-components'
import { BaseCell } from './BaseCell'
import { DATA_BAR_COLORS } from '../../../styleConstants'
import { RateValue } from '../../values/RateValue'

interface Props {
  readonly dataId: number
  readonly value: number
  readonly barPercent: number
}

export function ViewRateCell({ dataId, value, barPercent }: Props) {
  return (
    <Cell data-id={dataId} barPercent={barPercent} barColor={DATA_BAR_COLORS.RATE}>
      <RateValue value={value} />
    </Cell>
  )
}

const Cell = styled(BaseCell)`
  justify-content: flex-end;
`
