import * as React from 'react'
import { RefObject } from 'react'

import { GlobalContext } from '../../../GlobalState'
import { Authority } from '../../../util/Authority'
import { ReportTable } from '../ReportTable'
import { SearchArea } from './SearchArea'
import { useCustomDimensionReport } from '../../../util/hooks/api/CustomDimensionReport/useCustomDimensionReport'
import { makeItemsNotExistsMessage } from '../../../util/hooks/api/CustomDimensionReport/utils'
import { Loading } from '../../../components/common/Loading'
import { ProjectContext } from '../../ProjectRoot'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'
import { CanNotViewMessage } from '../../../components/warnings/CanNotViewMessage'
import { ErrorBox } from '../../../components/common/ErrorBox'
import { WarningBox } from '../../../components/warnings/WarningBox'
import { CustomDimensionReportContext } from '../../../contexts/CustomDimentionReportContext'
import { scrollTop } from '../../../util/scrollToElement'
import { UrlParamsErrorMessages } from '../../../components/reportMessages/UrlParamsErrorMessages'
import { NotSettingsMessages } from '../../../components/reportMessages/NotSettingsMessages'
import { useReportSettings } from '../../../util/hooks/useReportSettings'

interface Props {
  readonly mainAreaRef: RefObject<HTMLDivElement>
}

/**
 * カスタムディメンションレポートのレポートエリアをレンダリングする
 *
 * @param {object} param - The input parameters for the method.
 * @param {React.RefObject} param.mainAreaRef - MainAreaの参照オブジェクト（各ページのスクロール処理に使用）
 */
export function ReportArea({ mainAreaRef }: Props) {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { projectId, baseUrl },
  } = React.useContext(ProjectContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)
  const {
    state: { searchText },
  } = React.useContext(CustomDimensionReportContext)

  const canView = React.useMemo(
    () => Authority.canViewReport(AccountInfo.permissions, projectId),
    [AccountInfo.permissions, projectId],
  )

  const { isCompleted: isSettingsCompleted, isChecking: isSettingsChecking } = useReportSettings({
    projectId: projectId,
  })

  const { isError, isLoading, data, errorMessage } = useCustomDimensionReport({
    projectId,
    enabled: canView && isSettingsCompleted,
  })

  React.useEffect(() => scrollTop(mainAreaRef), [data])

  if (!canView) return <CanNotViewMessage />
  if (!isSettingsChecking && !isSettingsCompleted)
    return <NotSettingsMessages projectId={projectId} baseUrl={baseUrl} />
  if (isSettingsChecking || isLoading) return <Loading />

  const warningMessage = data?.length === 0 ? makeItemsNotExistsMessage(searchText, customFilterState) : ''

  return (
    <>
      <UrlParamsErrorMessages />
      {isError && !errorMessage && <ErrorBox>カスタムディメンションレポートの取得に失敗しました。</ErrorBox>}
      {errorMessage !== '' && <ErrorBox>{errorMessage}</ErrorBox>}
      {warningMessage !== '' && <WarningBox>{warningMessage}</WarningBox>}
      <SearchArea />
      {warningMessage === '' && <ReportTable items={data} />}
    </>
  )
}
