import { useQuery, useQueryClient } from '@tanstack/react-query'

import { CalenderState } from '../../../components/common/DayPickerRange'
import { request } from '../../request'
import { getDateStringYMD, getDateYYYYMM } from '../../Date'

export type ContentCaptureHistory = {
  id: number
  capturedAt: string
  capturedYearMonth: string
  captured: boolean
}

export type ContentCaptureHistoryData = {
  histories: ContentCaptureHistory[]
}

type ApiResponse = {
  results: Array<{
    id: number
    created_at: string
    captured: boolean
  }>
}

type Props = {
  projectId: string
  pageId: number
  contentId: number
  calenderState: CalenderState
  setErrorMessage: (message: string) => void
}

export const useContentCaptureHistoryData = ({
  projectId,
  pageId,
  contentId,
  calenderState,
  setErrorMessage,
}: Props) => {
  const queryClient = useQueryClient()
  const queryKey = [
    'ContentCaptureHistory',
    {
      projectId,
      pageId,
      contentId,
      startDate: calenderState.startDate,
      endDate: calenderState.endDate,
    },
  ]
  const setContentReport = (
    updater:
      | ContentCaptureHistoryData
      | undefined
      | ((oldData: ContentCaptureHistoryData | undefined) => ContentCaptureHistoryData | undefined),
  ) => queryClient.setQueryData<ContentCaptureHistoryData>(queryKey, updater)
  const invalidateContentReport = async () => {
    await queryClient.invalidateQueries({ queryKey })
  }

  const enabled = projectId !== '' && !!pageId && !!contentId

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const startDate = getDateStringYMD(calenderState.startDate, '-')
      const endDate = getDateStringYMD(calenderState.endDate, '-')
      const response = await request<ApiResponse>(
        'GET',
        `/api/projects/${projectId}/pages/${pageId}/content_capture_history/${contentId}?crawled_date_after=${startDate}&crawled_date_before=${endDate}`,
        true,
      )
      const histories = response.results.map((data) => {
        return {
          id: data.id,
          capturedYearMonth: getDateYYYYMM(data.created_at),
          capturedAt: data.created_at,
          captured: data.captured,
        }
      })
      return { histories }
    },
    enabled,
    onError: (e) => setErrorMessage(typeof e === 'string' ? e : ''),
  })
  return {
    ...queryResult,
    setContentReport,
    invalidateContentReport,
  }
}
