import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { colors, layout } from '../styleConstants'
import { usePageState } from './Remind/state'
import { AdminNormalButton } from '../components/common/Button'
import { Input } from '../components/common/Input'
import { SimplePageLayout } from '../components/layout/SimplePageLayout'
import { PageHeader } from '../components/common/PageHeader'
import { CheckCircle } from '@styled-icons/boxicons-regular'

export function Remind(_: RouteComponentProps) {
  const {
    state: { email, disabled, sendFailure, sendSuccess },
    actions,
  } = usePageState()
  return (
    <SimplePageLayout>
      <PageHeader title="パスワード再設定メールの送信" description="パスワード再設定メールの送信" />
      <Title>パスワードの再設定</Title>
      <Form onSubmit={actions.submit}>
        <Label>メールアドレス</Label>
        <DesignedInput
          type="email"
          value={email}
          onChange={actions.updateEmail}
          required
          data-testid="email"
          look={sendFailure ? 'red' : 'normal'}
        />
        <Comment>登録されているメールアドレスを入力してください、パスワード再設定のメールを送信します。</Comment>
        <Comment>※メールは「no-reply@contentanalytics.jp」から送信します。</Comment>
        <div style={{ marginTop: '2rem' }}>
          <AdminNormalButton type="submit" disabled={disabled} data-testid="button" style={{ width: '100%' }}>
            送信
          </AdminNormalButton>
        </div>
        <MessageContainer>
          {sendSuccess && (
            <>
              <Icon />
              <Comment>メールを送信しました。</Comment>
            </>
          )}
          {sendFailure && <Error>入力されたメールアドレスが正しくありません。</Error>}
        </MessageContainer>
      </Form>
    </SimplePageLayout>
  )
}

const Title = styled.h1`
  text-align: center;
  color: ${colors.lightBlue};
`

const Form = styled.form``

const Label = styled.div`
  font-weight: ${layout.boldFontWeight};
  margin-top: 2rem;
`

const Comment = styled.div`
  color: ${colors.gray500};
  font-size: 0.8rem;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 40px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  margin-top: 0.5rem;
`

const Icon = styled(CheckCircle).attrs({
  size: '24',
  color: `${colors.lightBlue}`,
})``

const Error = styled.div`
  color: ${colors.error};
  font-size: 0.8rem;
`
