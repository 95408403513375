import styled from 'styled-components'

export interface IconProps {
  size: number
  color?: string
}

export const BaseIcon = styled.div.attrs<IconProps>(({ size, color }) => ({
  style: {
    size,
    color,
  },
}))``
