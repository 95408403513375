import * as React from 'react'
import { request } from '../../util/request'
import { UserData } from '../../util/Response'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly loading: boolean
  readonly logined: boolean
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  fetch = async (setFunc: any) => {
    try {
      const user: UserData = await request('GET', '/api/user/', true)

      // グローバルステータスをセット
      await setFunc(
        user.username,
        user.id,
        user.team.name,
        user.team.slug,
        user.role_name,
        user.permissions,
        user.team.plan.display_name,
        user.team.plan.report_days_limit,
        user.team.plan.display_monthly_crawl_limit,
        user.team.plan.can_track_content_event,
      )
      this.setState({ ...this.state, loading: false, logined: true })
    } catch {
      this.setState({ ...this.state, loading: false, logined: false })
    }
  }
}

const initialState: State = {
  loading: true,
  logined: false,
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
