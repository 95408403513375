import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import styled from 'styled-components'

import { PAGE_TITLES } from '../../constants'
import { ReportsArea } from './ReportsArea'
import { makeProjectPageHeaderInfo } from '../../util/makePageHeaderInfo'
import { PageHeader } from '../../components/common/PageHeader'
import { GlobalContext } from '../../GlobalState'
import { PageLayout } from '../../components/layout/PageLayout'
import { ProjectContext } from '../ProjectRoot'

interface Props extends RouteComponentProps {}

export function FunnelReportsPage(_props: Props) {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)

  const {
    state: { baseUrl },
  } = React.useContext(ProjectContext)

  const mainAreaRef = React.useRef<HTMLDivElement>(null)

  const pageHeaderInfo = makeProjectPageHeaderInfo(PAGE_TITLES.FUNNEL_REPORTS, AccountInfo)

  return (
    <>
      <PageHeader title={pageHeaderInfo.title} description={pageHeaderInfo.description} />
      <PageLayout
        headerTitle={PAGE_TITLES.FUNNEL_REPORTS}
        baseUrl={baseUrl}
        optionHidden={true}
        filterButtonHidden={true}
      >
        <MainArea>
          <ReportAreaWrapper>
            <ReportsArea mainAreaRef={mainAreaRef} />
          </ReportAreaWrapper>
        </MainArea>
      </PageLayout>
    </>
  )
}

const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ReportAreaWrapper = styled.div`
  max-width: 1400px;
  padding: 32px;
`
