import * as React from 'react'
import cc from 'classcat'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { AddButton } from '../common/Button'
import { PopupMenu } from '../common/PopupMenu'
import { Settings } from '@styled-icons/material/Settings'

export interface DimensionState {
  readonly id: number
  readonly index: number
  readonly name: string
  readonly scope: number
  readonly querySourced: boolean
  readonly activated: boolean
}

export const ScopeCondition = {
  user: 1, // ユーザー
  session: 2, // セッション
  hit: 3, // ヒット
}

export const ScopeConditionMap: { [key: number]: string } = {
  1: 'ユーザー',
  2: 'セッション',
  3: 'ヒット',
}

// 表示幅
const layoutWidth = {
  index: '100px',
  name: '280px',
  scope: '140px',
  querySourced: '130px',
  activated: '100px',
}

interface Props {
  readonly dimensions: DimensionState[]
  readonly remained: number
  readonly canAddCustomDimension?: boolean
  readonly canChangeCustomDimension?: boolean
  readonly onAdd?: () => void
  readonly onEdit?: (id: number) => void
}

export function DimensionList(props: Props) {
  const { dimensions, remained, canAddCustomDimension, canChangeCustomDimension, onAdd, onEdit } = props
  const [state, setState] = React.useState({ id: 0, opened: false })
  const onClick = (id: number) => {
    setState({ ...state, id: id, opened: true })
  }
  const popupClose = () => {
    setState({ ...state, id: 0, opened: false })
  }
  return (
    <Container>
      {canAddCustomDimension && (
        <div style={{ margin: '1rem auto 0.5rem' }}>
          <AddButton onClick={onAdd}>カスタムディメンションを追加</AddButton>
        </div>
      )}
      {dimensions.length === 0 ? (
        <div style={{ margin: '1.5rem 0' }}>カスタムディメンションが登録されていません</div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Item style={{ paddingBottom: '0.5rem' }}>
            <Box className="title" style={{ width: `${layoutWidth.index}` }}>
              Index
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.name}` }}>
              名前
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.scope}` }}>
              範囲
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.querySourced}` }}>
              クエリー由来
            </Box>
            <Box className="title" style={{ width: `${layoutWidth.activated}` }}>
              状態
            </Box>
          </Item>
          {dimensions.map((dimension) => {
            return (
              <Item key={dimension.id} className={cc({ actived: dimension.activated })}>
                <Box style={{ width: `${layoutWidth.index}` }}>{dimension.index}</Box>
                <Box style={{ width: `${layoutWidth.name}` }}>{dimension.name}</Box>
                <Box style={{ width: `${layoutWidth.scope}` }}>{ScopeConditionMap[dimension.scope]}</Box>
                <Box style={{ width: `${layoutWidth.querySourced}` }}>{dimension.querySourced ? 'はい' : 'いいえ'}</Box>
                <Box style={{ width: `${layoutWidth.activated}` }}>{dimension.activated ? 'アクティブ' : '無効'}</Box>
                {canChangeCustomDimension && (
                  <SettingBox>
                    <SettingIcon onClick={() => onClick(dimension.id)} />
                    {state.opened && state.id === dimension.id && (
                      <PopupMenu onClose={popupClose} style={{ right: '20px', bottom: '-10px', width: '90px' }}>
                        <Links>
                          <DesignedLink onClick={() => onEdit && onEdit(dimension.id)}>編集</DesignedLink>
                        </Links>
                      </PopupMenu>
                    )}
                  </SettingBox>
                )}
              </Item>
            )
          })}
        </div>
      )}
      <div
        style={{
          color: `${colors.gray500}`,
          marginTop: '1rem',
        }}
      >{`カスタムディメンション設定可能数：残り${remained}個`}</div>
    </Container>
  )
}

const Container = styled.div``

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  height: 100%;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${colors.gray300};

  &:not(.actived) {
    color: ${colors.gray400};
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;

  & + & {
    margin-left: 1.5rem;
  }

  &.title {
    color: ${colors.gray500};
  }
`

const SettingBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
`

const SettingIcon = styled(Settings).attrs({
  size: '24',
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
`

const DesignedLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  color: ${colors.link.base};
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.link.hover};
    font-weight: 600;
    background-color: ${colors.gray200};
  }

  & + & {
    margin-top: 0.1rem;
  }
`
