import * as React from 'react'
import { request } from '../../util/request'
import { State } from './state'
import { Role } from '../../util/Authority'

export const MemberStep = {
  none: 0,
  editExec: 1, // 編集実行
  editComplete: 2, // 編集完了

  deleteExec: 3, // 削除確認
  deleteComplete: 4, // 削除完了

  sendInvite: 5, // 招待
  setRole: 6, // 権限設定
  inviteConfirm: 7, // 内容確認
  inviteComplete: 8, // 招待完了
}

export class Member {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // メンバーの追加フロー開始
  onAddMember = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        email: '',
        emailDisabled: true,
        step: MemberStep.sendInvite,
      },
      memberErrorMessage: '',
    })
  }

  // モーダルを閉じる
  onCancelModal = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        email: '',
        emailDisabled: true,
        tempRole: Role.User.admin,
        step: MemberStep.none,
      },
      project: {
        ...this.state.project,
        tempProjects: this.state.project.projects.map((project) => {
          return { id: project.id, name: project.name, role: project.role }
        }),
      },
    })
  }

  // 招待メール入力モーダルに戻る
  onBackSendInvite = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        step: MemberStep.sendInvite,
      },
    })
  }

  // 権限設定モーダルに戻る
  onBackRole = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        step: MemberStep.setRole,
      },
    })
  }

  // 招待メール入力完了
  onApplySendInvite = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        step: MemberStep.setRole,
      },
    })
  }

  // 権限設定完了
  onApplyRole = () => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        step: MemberStep.inviteConfirm,
      },
    })
  }

  // 招待メールの入力更新
  updateInviteEmail = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        email: event.currentTarget.value,
        emailDisabled: !event.currentTarget.validity.valid,
      },
    })
  }

  // ユーザーの権限を変更
  selectRole = (event: React.FormEvent<HTMLDivElement>) => {
    if (!event.currentTarget.dataset.id) {
      return
    }
    this.setState({
      ...this.state,
      member: {
        ...this.state.member,
        tempRole: event.currentTarget.dataset.id === 'admin' ? Role.User.admin : Role.User.member,
        inviteDisabled:
          event.currentTarget.dataset.id === 'admin'
            ? false
            : this.state.project.tempProjects.filter((project) => project.role !== Role.Project.none).length === 0,
      },
    })
  }

  // プロジェクトごとの権限を変更
  changeRole = (event: React.FormEvent<HTMLSelectElement>) => {
    const idx = this.state.project.tempProjects.findIndex(
      (project) => project.id === Number(event.currentTarget.dataset.id),
    )
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        tempProjects: [
          ...this.state.project.tempProjects.slice(0, idx),
          {
            ...this.state.project.tempProjects[idx],
            role: Number(event.currentTarget.value),
          },
          ...this.state.project.tempProjects.slice(idx + 1),
        ],
      },
    })
  }

  // 確認完了
  onApplyInviteConfirm = async () => {
    // 権限が指定されているプロジェクトのみ抽出
    const projects =
      this.state.project.tempProjects.length === 0
        ? []
        : this.state.project.tempProjects
            .map((project) => {
              return {
                project: project.id,
                role: this.state.member.tempRole === Role.User.admin ? Role.Project.admin : project.role,
              }
            })
            .filter((project) => project.role !== Role.Project.none)
    try {
      await request(
        'POST',
        `/api/invite/`,
        true,
        JSON.stringify({
          email: this.state.member.email,
          role: this.state.member.tempRole,
          invited_projects: projects,
        }),
      )
      this.setState({
        ...this.state,
        reload: true,
        member: {
          ...this.state.member,
          step: MemberStep.none,
          tempRole: Role.User.admin,
        },
        project: {
          ...this.state.project,
          tempProjects:
            this.state.project.projects.length === 0
              ? []
              : this.state.project.projects.map((project) => {
                  return { id: project.id, name: project.name, role: project.role }
                }),
        },
        toastMessage: '招待者へメールを送信しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        member: {
          ...this.state.member,
          step: MemberStep.none,
          tempRole: Role.User.admin,
        },
        project: {
          ...this.state.project,
          tempProjects:
            this.state.project.projects.length === 0
              ? []
              : this.state.project.projects.map((project) => {
                  return { id: project.id, name: project.name, role: project.role }
                }),
        },
        memberErrorMessage: typeof e === 'string' ? e : 'メンバーの追加に失敗しました。',
      })
    }
  }

  // ユーザーの編集
  onEditUser = (id: number) => {
    const user = this.state.member.users.find((user) => user.id === id)
    if (user) {
      this.setState({
        ...this.state,
        selectedUserId: user.id,
        selectedUserName: user.userName,
        selectedUserRole: user.role,
        member: {
          ...this.state.member,
          step: MemberStep.editExec,
        },
        memberErrorMessage: '',
      })
    }
  }

  // ユーザー編集キャンセル
  onCancelEditUser = () => {
    this.setState({
      ...this.state,
      selectedUserId: 0,
      selectedUserName: '',
      selectedUserRole: Role.User.none,
      member: {
        ...this.state.member,
        editDisabled: true,
        step: MemberStep.none,
      },
    })
  }

  // ユーザの権限変更
  onChangeEditUser = (event: React.FormEvent<HTMLSelectElement>) => {
    const user = this.state.member.users.find((user) => user.id === this.state.selectedUserId)
    if (user) {
      this.setState({
        ...this.state,
        selectedUserRole: Number(event.currentTarget.value),
        member: {
          ...this.state.member,
          editDisabled: !(user.role !== Number(event.currentTarget.value)), // 現在の権限から変更されている
        },
      })
    }
  }

  // ユーザー編集内容の反映
  onApplyEditUser = async () => {
    try {
      await request(
        'PATCH',
        `/api/users/${this.state.selectedUserId}/`,
        true,
        JSON.stringify({
          role: this.state.selectedUserRole,
        }),
      )
      this.setState({
        ...this.state,
        selectedUserId: 0,
        selectedUserName: '',
        selectedUserRole: Role.User.none,
        reload: true,
        member: {
          ...this.state.member,
          editDisabled: true,
          step: MemberStep.none,
        },
        toastMessage: 'メンバーの権限を変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        selectedUserId: 0,
        selectedUserName: '',
        selectedUserRole: Role.User.none,
        member: {
          ...this.state.member,
          editDisabled: true,
          step: MemberStep.none,
        },
        memberErrorMessage: typeof e === 'string' ? e : '権限の変更に失敗しました。',
      })
    }
  }

  // ユーザーの削除開始
  onDeleteUser = (id: number) => {
    const user = this.state.member.users.find((user) => user.id === id)
    if (user) {
      this.setState({
        ...this.state,
        selectedUserId: user.id,
        selectedUserName: user.userName,
        member: {
          ...this.state.member,
          step: MemberStep.deleteExec,
        },
        memberErrorMessage: '',
      })
    }
  }

  // メンバー削除キャンセル
  onCancelDeleteUser = () => {
    this.setState({
      ...this.state,
      selectedUserId: 0,
      selectedUserName: '',
      member: {
        ...this.state.member,
        step: MemberStep.none,
      },
    })
  }

  // ユーザー削除
  onApplyDeleteUser = async () => {
    try {
      await request('DELETE', `/api/users/${this.state.selectedUserId}/`, true)
      this.setState({
        ...this.state,
        reload: true,
        selectedUserId: 0,
        selectedUserName: '',
        member: {
          ...this.state.member,
          step: MemberStep.none,
        },
        toastMessage: 'ユーザーを無効化しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        selectedUserId: 0,
        selectedUserName: '',
        member: {
          ...this.state.member,
          step: MemberStep.none,
        },
        memberErrorMessage: typeof e === 'string' ? e : 'ユーザーの削除に失敗しました。',
      })
    }
  }
}
