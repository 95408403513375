import React from 'react'
import styled from 'styled-components'
import { FunnelStepData } from '../../../../util/hooks/api/FunnelReport/types'
import { colors } from '../../../../styleConstants'

interface Props {
  transitionCount: FunnelStepData['transitionCount']
  transitionRate: FunnelStepData['transitionRate']
}

export function StepTransaction({ transitionCount, transitionRate }: Props) {
  return (
    <Container>
      <InfoBox>
        <Count>{transitionCount !== null ? transitionCount.toLocaleString() : '-'}</Count>
        <Rate>({transitionRate !== null ? transitionRate.toFixed(1) : '-'}%)</Rate>
      </InfoBox>
    </Container>
  )
}

const Container = styled.div`
  margin: 4px 0;
  padding: 12px 0;
  background: linear-gradient(
    ${colors.blue} 0%,
    20%,
    transparent 20%,
    80%,
    ${colors.blue} 80%,
    93%,
    transparent 93%,
    96.5%,
    ${colors.blue} 96.5%,
    ${colors.blue} 100%
  );
  background-size: 56px 100%;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
`

const InfoBox = styled.div`
  display: inline-block;
  padding: 1px 12px;
  border: 1px solid ${colors.lightBlue};
  border-radius: 5px;
  background: ${colors.white};
  font-weight: bold;
`

const Count = styled.span`
  font-size: 14px;
  vertical-align: middle;
`

const Rate = styled.span`
  margin-left: 5px;
  font-size: 12px;
  color: ${colors.gray750};
  vertical-align: middle;
`
