import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../../styleConstants'
import { Select } from '../../../common/Select'
import {
  DataImportAllKeyFieldsData,
  KeyFieldData,
  ImportField,
  EditImportField,
  ImportData,
} from '../../../../util/hooks/api/DataImport/types'
import { useCreateDataImportSchemas } from '../../../../util/hooks/api/DataImport/useCreateDataImportSchemas'
import { useUpdateDataImportSchemas } from '../../../../util/hooks/api/DataImport/useUpdateDataImportSchemas'
import { useLatestImportData } from '../../../../util/hooks/api/DataImport/useLatestImportData'
import { Loading } from '../../../common/Loading'
import { KeyFieldStep } from './KeyFieldStep'
import { ImportFieldStep } from './ImportFieldStep'
import { EditButtons } from './EditButtons'
import { useDataImportAllKeyFields } from '../../../../util/hooks/api/DataImport/useDataImportAllKeyFields'
import { useDataImportCsv } from '../../../../util/hooks/api/DataImport/useDataImportCsv'
import { useDataImportSchemas } from '../../../../util/hooks/api/DataImport/useDataImportSchemas'
import { useDeleteDataImportFieldSuggestion } from '../../../../util/hooks/api/DataImport/useDeleteDataImportFieldSuggestion'
import { useUpdateDataImportFieldSuggestion } from '../../../../util/hooks/api/DataImport/useUpdateDataImportFieldSuggestion'
import { useToast } from '../../../../util/hooks/useToast'
import { CsvImportModal } from '../CsvImportModal'
import { KeyFieldUnit } from '../KeyFieldUnit'

export const INIT_EDIT_IMPORT_FIELD: EditImportField = {
  primaryKey: false,
  fieldName: '',
  dataType: undefined,
  enabled: true,
  editable: true,
} as const

const getInitEditImportFields = (
  importFields: ImportField[] | undefined,
  importData: ImportData | null,
): EditImportField[] => {
  if (!importFields) return [{ ...INIT_EDIT_IMPORT_FIELD }]
  return importFields.map((importField: ImportField) => {
    return {
      ...importField,
      enabled: true,
      editable: importData === null,
    }
  })
}

interface Props {
  projectId: string
  schemasId: number | undefined
  allKeyFieldsData: DataImportAllKeyFieldsData | undefined
  keyFieldLabel: string | undefined
  importFields: ImportField[] | undefined
  closeEditPanel: () => void
  setCsvSuggestionId: (arg: number | null) => void
  csvSuggestionId: number | null
}

export function FieldEditPanel({
  projectId,
  schemasId,
  allKeyFieldsData,
  keyFieldLabel,
  importFields,
  closeEditPanel,
  setCsvSuggestionId,
  csvSuggestionId,
}: Props) {
  const { data: latestImportData } = useLatestImportData({ projectId })

  const saved = !!importFields
  const [editKeyFieldLabel, setEditKeyFieldLabel] = useState(keyFieldLabel)
  const [editImportFields, setEditImportFields] = useState<EditImportField[]>(
    getInitEditImportFields(importFields, latestImportData || null),
  )
  const [errorMessage, setErrorMessage] = useState('')
  const [isCsvImportModalOpened, setIsCsvImportModalOpened] = useState(false)

  const { openToast } = useToast()
  const { invalidateDataImportSchemas } = useDataImportSchemas({
    projectId,
  })
  const { invalidate: invalidateAllKeyFields } = useDataImportAllKeyFields({
    projectId,
  })
  const { setDataImportCsv } = useDataImportCsv({ projectId })
  const { mutate: updateSuggestionMutate, isLoading: isUpdatingSuggestion } = useUpdateDataImportFieldSuggestion({
    projectId,
    options: {
      onSuccess: () => {
        setDataImportCsv({ isImporting: true })
        openToast({ message: `データインポートを開始しました。` })
        setCsvSuggestionId(null)
        setIsCsvImportModalOpened(false)
        closeEditPanel()
      },
      onError: (_: string) => {
        openToast({ message: 'データインポートを開始できませんでした。' })
        setCsvSuggestionId(null)
        setIsCsvImportModalOpened(false)
        closeEditPanel()
      },
    },
  })
  const { mutate: deleteSuggestionMutate } = useDeleteDataImportFieldSuggestion({
    projectId,
  })

  const openCsvImportModal = () => setIsCsvImportModalOpened(true)
  const closeCsvImportModal = () => {
    deleteSuggestionMutate({ csvSuggestionId })
    setCsvSuggestionId(null)
    setIsCsvImportModalOpened(false)
    closeEditPanel()
  }
  const onCsvImport = () => {
    if (!csvSuggestionId) return
    updateSuggestionMutate(csvSuggestionId)
  }

  const handleSuccessSave = () => {
    invalidateDataImportSchemas()
    invalidateAllKeyFields()
    openToast({ message: 'インポートフィールドを保存しました。' })
    if (csvSuggestionId) {
      openCsvImportModal()
      return
    }
    closeEditPanel()
  }

  const {
    mutate: createDataImportSchemasMutate,
    isLoading: isCreatingDataImportSchemas,
    isError: isCreateDataImportSchemasMutateError,
  } = useCreateDataImportSchemas({
    projectId: projectId,
    options: {
      onSuccess: handleSuccessSave,
      onError: (e: string) => {
        setErrorMessage(typeof e === 'string' ? e : 'インポートフィールドの作成に失敗しました。')
      },
    },
  })

  const {
    mutate: updateDataImportSchemasMutate,
    isLoading: isUpdatingDataImportSchemas,
    isError: isUpdateDataImportSchemasMutateError,
  } = useUpdateDataImportSchemas({
    projectId: projectId,
    options: {
      onSuccess: handleSuccessSave,
      onError: (e: string) => {
        setErrorMessage(typeof e === 'string' ? e : 'インポートフィールドの編集に失敗しました。')
      },
    },
  })

  const allKeyFields: KeyFieldData[] = allKeyFieldsData?.dataImportKeyFields || []
  const disactivatedCustomDimensionExists: boolean = allKeyFieldsData?.disactivatedCustomDimensionExists || false
  const fieldsMaxCount: number = (importFields?.length || 0) + (allKeyFieldsData?.fieldRemaining || 0)
  const enabledCount: number = editImportFields.filter((editImportField) => editImportField.enabled).length

  const isLoading: boolean = isCreatingDataImportSchemas || isUpdatingDataImportSchemas || isUpdatingSuggestion
  const hasError: boolean = isCreateDataImportSchemasMutateError || isUpdateDataImportSchemasMutateError

  const enabledFields = editImportFields
    .filter((field) => field.enabled)
    .map((field) => {
      return {
        primaryKey: field.primaryKey,
        fieldName: field.fieldName,
        dataType: field.dataType,
      }
    })
  const isFieldsNotLength: boolean = enabledFields.length === 0
  const isFieldsNotChanged: boolean = JSON.stringify(importFields) === JSON.stringify(enabledFields)
  const isFieldsValueEmpty: boolean = enabledFields.some((field) => !field.fieldName || !field.dataType)
  const isFieldsPrimaryKeyNotSelect: boolean = isFieldsNotLength
    ? false
    : !enabledFields.some((field) => field.primaryKey)
  const isFieldsRowLimitExceeded: boolean = enabledCount > fieldsMaxCount
  const isFieldStepError: boolean =
    isFieldsNotLength ||
    isFieldsNotChanged ||
    isFieldsValueEmpty ||
    isFieldsPrimaryKeyNotSelect ||
    isFieldsRowLimitExceeded
  const isAllStepError: boolean = !editKeyFieldLabel || isFieldStepError

  const onSave = async () => {
    if (!editKeyFieldLabel) return

    const newKeyField = allKeyFields.find((allKeyField) => allKeyField.nameLabel === editKeyFieldLabel)
    if (!newKeyField) return

    const newImportFields: ImportField[] = editImportFields
      .filter((field) => field.enabled && field.editable)
      .map((field) => {
        return {
          primaryKey: field.primaryKey,
          fieldName: field.fieldName,
          dataType: field.dataType,
        }
      })
    if (!newImportFields) return

    if (schemasId) {
      updateDataImportSchemasMutate({
        schemasId: schemasId,
        importFields: newImportFields,
        keyField: newKeyField,
      })
    } else {
      createDataImportSchemasMutate({
        importFields: newImportFields,
        keyField: newKeyField,
      })
    }
  }

  const onCancel = () => {
    setCsvSuggestionId(null)
    closeEditPanel()
  }

  return (
    <>
      {isLoading && <Loading />}

      {!!latestImportData && <KeyFieldUnit value={keyFieldLabel || ''} />}
      {!latestImportData && (
        <KeyFieldStep
          saved={saved}
          disactivatedCustomDimensionExists={disactivatedCustomDimensionExists}
          projectId={projectId}
          allKeyFields={allKeyFields}
          editKeyFieldLabel={editKeyFieldLabel}
          setEditKeyFieldLabel={setEditKeyFieldLabel}
        />
      )}
      <ImportFieldStep
        projectId={projectId}
        importFields={importFields}
        editImportFields={editImportFields}
        importData={latestImportData || null}
        setEditImportFields={setEditImportFields}
        setCsvSuggestionId={setCsvSuggestionId}
        errorMessage={errorMessage}
        saved={saved}
        hasError={hasError}
        enabledCount={enabledCount}
        fieldsMaxCount={fieldsMaxCount}
        isFieldsPrimaryKeyNotSelect={isFieldsPrimaryKeyNotSelect}
        isFieldsRowLimitExceeded={isFieldsRowLimitExceeded}
        isFieldsValueEmpty={isFieldsValueEmpty}
        isFieldsNotLength={isFieldsNotLength}
        isFieldsNotChanged={isFieldsNotChanged}
        isFieldStepError={isFieldStepError}
      />
      <EditButtons onSave={onSave} onCancel={onCancel} isSaveDisabled={isAllStepError} />
      <CsvImportModal
        opened={isCsvImportModalOpened}
        onClose={closeCsvImportModal}
        onCsvImport={onCsvImport}
        isImportDataExists={!!latestImportData}
      />
    </>
  )
}

export const StepSection = styled.div`
  display: flex;
  font-size: 16px;
  padding: 0 0 20px;
`
export const StepSectionHead = styled.div`
  position: relative;
`
export const StepSectionHeadNo = styled.div<{ saved: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 10px 0 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ saved: saved }) => (saved ? colors.headerBg : colors.gray100)};
  color: ${({ saved: saved }) => (saved ? colors.white : colors.disabled)};
  font-size: 1rem;
  font-weight: bold;
  z-index: 2;
`
export const StepSectionHeadBorder = styled.div`
  position: absolute;
  top: 1px;
  left: 11px;
  height: calc(100% + 30px);
  border-left: 2px solid ${colors.gray100};
  z-index: 1;
`
export const StepSectionBody = styled.div`
  flex: 1;
  padding-top: 3px;
  color: ${colors.gray750};
`
export const StepSectionBodyTitle = styled.div``

export const StepSectionBodyNotes = styled.div`
  margin: 16px 0px;
  line-height: 1.5;
`

export const StepSectionBodyField = styled.div`
  margin: 16px 0 0;
`

export const StepSectionBodyTitleText = styled.div<{ saved: boolean }>`
  margin: 0 5px 0 0;
  font-weight: bold;
  color: ${({ saved: saved }) => (saved ? colors.headerBg : colors.disabled)};
`

export const FlexBox = styled.div`
  display: flex;
`

export const Label = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.black};
`

export const DesignedSelect = styled(Select)`
  padding: 10px;
  font-size: 16px;
  border: 1px solid ${colors.gray250};
  border-radius: 2px;
  &:invalid {
    border: 1px solid ${colors.error};
  }
`

export const ErrorMessage = styled.div`
  line-height: 1.5;
  font-size: 16px;
  color: ${colors.error};
`
