import * as React from 'react'
import {
  CONDITION_TYPES,
  DIMENSION_EMPTY_TYPES,
  DIMENSION_BETWEEN_TYPES,
} from '../../../util/hooks/api/Filter/constants'
import { DimensionFilterState } from '../../../util/hooks/api/Filter/types'
import styled from 'styled-components'
import { Input } from '../../common/Input'
import { CustomFilterAction } from '../../../contexts/CustomFilterContext/state'
import { InputErrorMessage } from '../../errors/InputErrorMessage'

const TEXT_INPUT_WIDTH = 290
const INPUT_MARGIN_LEFT = 14

interface Props {
  readonly actions: CustomFilterAction
  state: DimensionFilterState
  filterIndex: number
  dimensionIndex: number
  stateIndex: number
}

export function DimensionItemValues({ actions, state, filterIndex, dimensionIndex, stateIndex }: Props) {
  if (!state.id || DIMENSION_EMPTY_TYPES.includes(state.condition)) {
    return null
  }

  // input text > value
  if (state.conditionType === CONDITION_TYPES.STRING || state.conditionType === CONDITION_TYPES.STRING_WITH_EMPTY) {
    const errors = actions.getDimensionValueErrors(filterIndex, dimensionIndex)
    const hasError = errors.length > 0
    return (
      <InputWithErrorArea>
        <DesignedTextInput
          value={state.value}
          type="text"
          onChange={actions.onDimensionValueUpdate}
          onBlur={() => actions.validateDimensionValue(filterIndex, dimensionIndex)}
          required
          data-index={filterIndex}
          data-dimensionindex={dimensionIndex}
          data-stateindex={stateIndex}
          look={hasError ? 'red' : undefined}
        />
        {hasError && (
          <InputErrorMessages>
            {errors.map((message, index) => (
              <InputErrorMessage key={index}>{message}</InputErrorMessage>
            ))}
          </InputErrorMessages>
        )}
      </InputWithErrorArea>
    )
  }

  // input number > value, fromValue, toValue
  if (state.conditionType === CONDITION_TYPES.NUMERIC || state.conditionType === CONDITION_TYPES.NUMERIC_WITH_EMPTY) {
    if (!DIMENSION_BETWEEN_TYPES.includes(state.condition)) {
      return (
        <DesignedNumberInput
          value={state.value}
          type="number"
          onChange={actions.onDimensionValueUpdate}
          required
          data-index={filterIndex}
          data-dimensionindex={dimensionIndex}
          data-stateindex={stateIndex}
        />
      )
    }

    return (
      <>
        <DesignedNumberInput
          value={state.fromValue}
          type="number"
          onChange={actions.onDimensionFromValueUpdate}
          required
          data-index={filterIndex}
          data-dimensionindex={dimensionIndex}
          data-stateindex={stateIndex}
        />
        <BetWeenSeparate>-</BetWeenSeparate>
        <DesignedNumberInput
          value={state.toValue}
          type="number"
          onChange={actions.onDimensionToValueUpdate}
          required
          data-index={filterIndex}
          data-dimensionindex={dimensionIndex}
          data-stateindex={stateIndex}
        />
      </>
    )
  }

  // input date > value, fromValue, toValue
  if (state.conditionType === CONDITION_TYPES.DATE_WITH_EMPTY) {
    if (!DIMENSION_BETWEEN_TYPES.includes(state.condition)) {
      return (
        <DesignedDateInput
          value={state.value}
          type="date"
          onChange={actions.onDimensionValueUpdate}
          required
          data-index={filterIndex}
          data-dimensionindex={dimensionIndex}
          data-stateindex={stateIndex}
        />
      )
    }

    return (
      <>
        <DesignedDateInput
          value={state.fromValue}
          type="date"
          onChange={actions.onDimensionFromValueUpdate}
          required
          data-index={filterIndex}
          data-dimensionindex={dimensionIndex}
          data-stateindex={stateIndex}
        />
        <BetWeenSeparate>-</BetWeenSeparate>
        <DesignedDateInput
          value={state.toValue}
          type="date"
          onChange={actions.onDimensionToValueUpdate}
          required
          data-index={filterIndex}
          data-dimensionindex={dimensionIndex}
          data-stateindex={stateIndex}
        />
      </>
    )
  }

  return null
}

const DesignedInput = styled(Input)`
  margin-left: ${INPUT_MARGIN_LEFT}px;
  width: 120px;
  height: 30px;
  font-size: 0.8rem;
  margin-right: 1rem;
`

const DesignedTextInput = styled(DesignedInput)`
  width: ${TEXT_INPUT_WIDTH}px;
`

const DesignedNumberInput = styled(DesignedInput)`
  width: 80px;
`

const DesignedDateInput = styled(DesignedInput)`
  width: auto;
`

const BetWeenSeparate = styled.span`
  margin: 0 -10px;
`

const InputWithErrorArea = styled.div`
  display: flex;
  flex-direction: column;
`

const InputErrorMessages = styled.div`
  margin-left: ${INPUT_MARGIN_LEFT}px;
  width: ${TEXT_INPUT_WIDTH}px;
`
