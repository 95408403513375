import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../styleConstants'
import { AdminNormalButton, CancelButton } from '../common/Button'
import { Input } from '../common/Input'

export interface CaptureLimitState {
  readonly id: number
  readonly name: string
  readonly rate: number // 上限割合
  readonly editRate: number // 編集用
  readonly standard: number // 月間取得回数目安
  readonly excessRate: string // 月間取得超過率
}

// 表示幅
const layoutWidth = {
  name: '280px',
  rate: '170px',
  standard: '200px',
  excessRate: '180px',
  message: '380px', // standard + excessRate
}

interface Props {
  readonly disabled?: boolean
  readonly limits: CaptureLimitState[]
  readonly teamLimit: number // 組織の上限
  readonly totalRate: number // 割合合計
  readonly onChange: (event: React.FormEvent<HTMLInputElement>, id: number) => void
  readonly onReset?: () => void
  readonly onApply?: () => void
}

export function CaptureLimitList(props: Props) {
  const { disabled, limits, teamLimit, totalRate, onChange, onReset, onApply } = props

  return (
    <Container>
      {limits.length === 0 ? (
        <div style={{ marginBottom: '1rem' }}>プロジェクトが登録されていません</div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', fontSize: '1.2rem', fontWeight: 'bold' }}>
            <div>月間キャプチャ上限数 :&nbsp;</div>
            <div>{teamLimit > 0 ? `約 ${teamLimit.toLocaleString()}` : 0}</div>
          </div>

          <Item style={{ paddingBottom: '0.5rem' }}>
            <Box className="title" style={{ width: layoutWidth.name }}>
              プロジェクト名
            </Box>
            <Box className={cc(['title', 'num'])} style={{ width: layoutWidth.rate }}>
              キャプチャ上限割合
            </Box>
            <Box className={cc(['title', 'num'])} style={{ width: layoutWidth.standard }}>
              月間キャプチャ数予測
            </Box>
            <Box className={cc(['title', 'num'])} style={{ width: layoutWidth.excessRate }}>
              キャプチャ消費率
            </Box>
          </Item>

          {limits.map((limit, index) => {
            return (
              <Item key={index}>
                <Box style={{ width: layoutWidth.name }}>{limit.name}</Box>
                <Box className="num" style={{ width: layoutWidth.rate }}>
                  <DesignedInput
                    type="number"
                    value={limit.editRate}
                    textAlign="center"
                    min={0}
                    max={100}
                    style={{ marginRight: '0.3rem' }}
                    onChange={(e) => onChange(e, limit.id)}
                  />
                  <span>%</span>
                </Box>
                <Box className="num" style={{ width: layoutWidth.standard }}>
                  {limit.standard.toLocaleString()}
                </Box>
                <Box className="num" style={{ width: layoutWidth.excessRate }}>
                  {limit.excessRate}%
                </Box>
              </Item>
            )
          })}

          <Item style={{ paddingBottom: '0.5rem', borderBottom: 'none' }}>
            <Box className="title" style={{ fontSize: '1.2rem', fontWeight: 'bold', width: layoutWidth.name }}>
              キャプチャ上限割合合計
            </Box>
            <Box
              className="total"
              style={{
                fontSize: '1.2rem',
                width: layoutWidth.rate,
                color: totalRate !== 100 ? colors.error : colors.gray500,
              }}
            >
              {totalRate}%
            </Box>
            <Box
              style={{
                justifyContent: 'flex-end',
                width: layoutWidth.message,
                color: totalRate !== 100 ? colors.error : colors.gray500,
              }}
            >
              {totalRate > 100 && '合計値が超過しています'}
              {totalRate < 100 && '割り振りできる値が残っています'}
            </Box>
          </Item>

          <Buttons>
            <CancelButton onClick={onReset}>リセット</CancelButton>
            <AdminNormalButton disabled={disabled} onClick={onApply}>
              保存
            </AdminNormalButton>
          </Buttons>
        </div>
      )}
    </Container>
  )
}

const Container = styled.div``

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  min-height: 40px;
  height: 100%;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${colors.gray300};
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
  word-break: break-all;
  height: 100%;

  & + & {
    margin-left: 1.5rem;
  }

  &.num {
    justify-content: flex-end;
    padding-right: 1rem;
  }

  &.title {
    color: ${colors.gray500};
    padding-right: 0;
  }

  &.total {
    justify-content: flex-end;
    color: ${colors.gray500};
    padding-right: 1rem;
  }
`

const DesignedInput = styled(Input)`
  width: 70px;
  height: 30px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  gap: 0 1rem;
`
