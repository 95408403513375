import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'

type Payload = {
  scheduleId: number
}

type FormattedPayload = {
  data_import_schedule_id: number
}

type Response = {
  download_url: string
}

type Props = {
  projectId: string
  options: UseMutationOptions<string, string, Payload, void>
}

export const useDownloadSftpPubkey = ({ projectId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async (payload: Payload) => {
      const formattedPayload: FormattedPayload = {
        data_import_schedule_id: payload.scheduleId,
      }

      const result: Response = await request(
        'POST',
        `/api/projects/${projectId}/data_import_sftp_pubkey_download/`,
        true,
        JSON.stringify(formattedPayload),
      )

      return result.download_url
    },
    ...options,
  })
  return mutationResult
}
