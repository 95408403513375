import * as React from 'react'
import { ContentNameLongHeaderCell } from '../cells/ContentNameLongHeaderCell'
import { ViewCountHeaderCell } from '../cells/ViewCountHeaderCell'
import { ViewRateHeaderCell } from '../cells/ViewRateHeaderCell'
import { ViewSecondHeaderCell } from '../cells/ViewSecondHeaderCell'
import { GoalCountHeaderCell } from '../cells/GoalCountHeaderCell'
import { GoalRateHeaderCell } from '../cells/GoalRateHeaderCell'
import { ClickCountHeaderCell } from '../cells/ClickCountHeaderCell'
import { ClickRateHeaderCell } from '../cells/ClickRateHeaderCell'
import { BaseRow } from './BaseRow'
import { SortItemKey, SortState } from '../../../util/hooks/api/useContentReport'

interface Props {
  readonly sortState: SortState
  readonly onSortClick: (key: SortItemKey) => void
}

export function ContentGroupHeaderRow({ sortState, onSortClick }: Props) {
  return (
    <BaseRow>
      <ContentNameLongHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <ViewCountHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <ViewRateHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <ViewSecondHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <GoalCountHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <GoalRateHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <ClickCountHeaderCell sortState={sortState} onSortClick={onSortClick} />
      <ClickRateHeaderCell sortState={sortState} onSortClick={onSortClick} />
    </BaseRow>
  )
}
