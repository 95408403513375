import { DataImportSchemasResultsResponse, DataImportSchemasData } from './types'

export function formatResponse(response: DataImportSchemasResultsResponse): DataImportSchemasData {
  return {
    schemasId: response.id,
    editable: response.editable,
    fieldRemaining: response.field_remaining,
    dataImportKeyField: {
      nameLabel: response.data_import_key_field.name_label,
      table: response.data_import_key_field.table,
      customDimensionId: response.data_import_key_field.custom_dimension?.id,
    },
    dataImportFields: response.data_import_fields.map((importField) => {
      return {
        primaryKey: importField.for_key,
        fieldName: importField.name,
        dataType: importField.type,
      }
    }),
  }
}
