import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { colors, layout } from '../styleConstants'
import { GlobalContext } from '../GlobalState'
import { PageLayout } from '../components/layout/PageLayout'
import { usePageState } from './Account/state'
import { AdminNormalButton, CancelButton } from '../components/common/Button'
import { Input } from '../components/common/Input'
import { Password } from '../components/common/Password'
import { PageHeader } from '../components/common/PageHeader'
import { PasswordRegex, PasswordMinLength } from '../util/Password'
import { AccountCircle } from '@styled-icons/material/AccountCircle'
import { Password as PasswordIcon } from '@styled-icons/fluentui-system-regular/Password'
import { AccordionBox } from '../components/common/AccordionBox'

interface AccountProps extends RouteComponentProps {
  projectId?: string
}

export function Account(props: AccountProps) {
  const {
    state: { AccountInfo },
    actions,
  } = React.useContext(GlobalContext)

  // ライブラリの関数を直接実行
  const panelRefs = React.useRef<any[]>([])

  const {
    state: {
      loading,
      username,
      baseUsername,
      email,
      disabled,
      oldPassword,
      newPassword,
      passwordDisabled,
      isSnackbar,
      accountErrorMessage,
      passwordErrorMessage,
    },
    pageActions,
  } = usePageState()
  if (loading) {
    pageActions.fetch()
  }
  // スナックバーに成功表示が行われる際に表示プロジェクト名を更新
  React.useEffect(() => {
    if (isSnackbar) {
      actions.setName(username, '')
    }
  }, [isSnackbar])
  const baseUrl = props.uri?.split(props.path!).join('')

  React.useEffect(() => {
    // ライブラリの関数を直接実行
    panelRefs.current.forEach((ref) => {
      ref?.calcHeight()
    })
  }, [accountErrorMessage, passwordErrorMessage])

  return (
    <PageLayout
      headerTitle="アカウント設定"
      optionHidden={true}
      baseUrl={baseUrl}
      adminLayout={true}
      accountLayout={true}
    >
      <PageHeader
        title={`アカウント設定 | ${AccountInfo.teamName}チーム`}
        description={`アカウント設定 | ${AccountInfo.teamName}チーム`}
      />
      {!loading && (
        <Container>
          <AccordionBoxes>
            <AccordionBox
              title="アカウント情報"
              icon={<AccountCircle />}
              isAdmin
              accordionProps={{ defaultExpanded: true }}
            >
              {accountErrorMessage && (
                <ErrorMessage data-testid="account-error-message">{accountErrorMessage}</ErrorMessage>
              )}
              <AccountContainer>
                <div>
                  <Label>アカウント名</Label>
                  <DesignedInput
                    type="text"
                    value={username}
                    onChange={pageActions.updateUsername}
                    required
                    className="input-name"
                    data-testid="account-input"
                  />
                  <Label>メールアドレス</Label>
                  {email}
                </div>
                <ButtonContainer>
                  {username !== baseUsername && <CancelButton onClick={pageActions.onCancel}>キャンセル</CancelButton>}
                  <AdminNormalButton data-testid="save-button" disabled={disabled} onClick={pageActions.saveAccount}>
                    保存
                  </AdminNormalButton>
                </ButtonContainer>
              </AccountContainer>
            </AccordionBox>

            <AccordionBox
              title="パスワード変更"
              icon={<PasswordIcon />}
              isAdmin
              accordionProps={{ defaultExpanded: false }}
              dataTestId="password-box"
            >
              {passwordErrorMessage && (
                <ErrorMessage data-testid="password-error-message">{passwordErrorMessage}</ErrorMessage>
              )}
              <AccountContainer>
                <div>
                  <Label>現在のパスワード</Label>
                  <DesignedInput
                    type="password"
                    value={oldPassword}
                    minLength={PasswordMinLength}
                    pattern={PasswordRegex}
                    required
                    data-testid="old-password"
                    onChange={pageActions.updateOldPassword}
                    onBlur={pageActions.onBlurOldPassword}
                  />
                  <Label>新しいパスワード</Label>
                  <DesignedPassword
                    value={newPassword}
                    minLength={PasswordMinLength}
                    pattern={PasswordRegex}
                    required
                    data-testid="new-password"
                    onChange={pageActions.updateNewPassword}
                    onBlur={pageActions.onBlurNewPassword}
                  />
                  <Comment>・パスワードは最低１０文字以上必要です。</Comment>
                  <Comment>
                    ・パスワードはアルファベット、数字および記号のうち少なくとも２種類以上の組み合わせである必要があります。
                  </Comment>
                </div>
                <ButtonContainer>
                  <AdminNormalButton
                    data-testid="change-button"
                    disabled={passwordDisabled}
                    onClick={pageActions.changePassword}
                  >
                    変更
                  </AdminNormalButton>
                </ButtonContainer>
              </AccountContainer>
            </AccordionBox>
          </AccordionBoxes>
        </Container>
      )}
    </PageLayout>
  )
}

const Container = styled.div`
  width: ${layout.settingPageWidth};
  height: 100%;
  padding-top: 2rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    height: calc(100vh - ${layout.headerHeight});
    min-height: calc(100vh - ${layout.headerHeight});
  }
`

const AccountContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
`

const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  gap: 0 1rem;
`

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const DesignedInput = styled(Input)`
  width: 250px;
  height: 30px;
  margin-bottom: 1.5rem;
`

const DesignedPassword = styled(Password)`
  width: 250px;
  height: 30px;
  margin-bottom: 1.5rem;
`

const Comment = styled.div`
  color: ${colors.gray500};
  font-size: 0.8rem;

  & + & {
    margin-top: 0.5rem;
  }
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`

const AccordionBoxes = styled.div`
  > :nth-child(n + 2) {
    margin-top: 28px;
  }
`
