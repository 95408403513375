import * as React from 'react'
import styled from 'styled-components'
import { Link } from '@gatsbyjs/reach-router'
import { colors } from '../../styleConstants'

interface Props {
  readonly message?: string
  readonly linkMessage?: string
  readonly linkPath?: string
}

export function InvalidLayout({ message, linkMessage, linkPath }: Props) {
  return (
    <Container>
      <Message>{message}</Message>
      {linkPath !== '' && <DesignedLink to={`${linkPath}`}>{linkMessage}</DesignedLink>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`

const Message = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`

const DesignedLink = styled(Link)`
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.link.base};

  &:visited {
    color: ${colors.link.visited};
  }
`
