import styled from 'styled-components'
import { colors } from '../../styleConstants'

export const Link = styled.a`
  color: ${colors.link.base};
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;

  &:visited {
    color: ${colors.link.visited};
  }

  &:hover {
    color: ${colors.link.hover};
  }
`
