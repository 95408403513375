import {
  AGGREGATE_SCOPES,
  CONDITION_TYPES,
  DEFAULT_DIMENSION_OPTIONS,
  DIMENSION_CATEGORIES,
  DIMENSION_KEYS,
} from '../Filter/constants'
import { GoalType } from '../Goal/types'
import { ImportFieldTypeTableTypes } from '../DataImport/types'
import { importFieldToConditionTypes } from '../Filter/useReportFilterSelects'
import { DimensionOption } from '../Filter/types'
import { GOAL_TYPES } from '../Goal/constants'
import { ScopeCondition } from '../../../../components/list/DimensionList'
import { IMPORT_FIELD_TYPE_TABLE_TYPES } from '../DataImport/constants'

export function transformDimensionOption(
  key: string,
  customDimension?: { id: number; name: string; scope: number; activated: boolean } | null,
  goal?: { id: number; name: string; goal_type: GoalType } | null,
  dataImportField?: { id: number; name: string; type: ImportFieldTypeTableTypes } | null,
): DimensionOption | null {
  const option = DEFAULT_DIMENSION_OPTIONS.find((opt) => opt.id === key)
  if (option) return option

  switch (key) {
    case DIMENSION_KEYS.CUSTOM_DIMENSION:
      // 無効カスタムディメンションはデータが返ってくるため、nameとIDは置き換えて後続処理を実行する
      // 例) ファネルレポートで「指定したカスタムディメンションは無効」のエラーメッセージ表示判定でそのままの値だとハンドリングできない
      return {
        id: DIMENSION_KEYS.CUSTOM_DIMENSION,
        name: customDimension?.activated ? customDimension?.name ?? '' : '',
        dimensionCategory: DIMENSION_CATEGORIES.CUSTOM_DIMENSION,
        conditionType: CONDITION_TYPES.STRING_WITH_EMPTY,
        targetScopes:
          customDimension?.scope === ScopeCondition.hit
            ? [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION, AGGREGATE_SCOPES.PAGE_VIEW]
            : [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
        customDimensionId: customDimension?.activated ? customDimension?.id : undefined,
      }
    case DIMENSION_KEYS.GOAL:
      return {
        id: DIMENSION_KEYS.GOAL,
        name: goal?.name ?? '',
        dimensionCategory: DIMENSION_CATEGORIES.GOAL,
        conditionType: CONDITION_TYPES.CHOICE,
        targetScopes:
          goal?.goal_type === GOAL_TYPES.EVENT
            ? [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION, AGGREGATE_SCOPES.PAGE_VIEW]
            : [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
        goalId: goal?.id,
      }
    case DIMENSION_KEYS.DATA_IMPORT_FIELD:
      return {
        id: DIMENSION_KEYS.DATA_IMPORT_FIELD,
        name: dataImportField?.name ?? '',
        dimensionCategory: DIMENSION_CATEGORIES.DATA_IMPORT_FIELD,
        conditionType: importFieldToConditionTypes[dataImportField?.type ?? IMPORT_FIELD_TYPE_TABLE_TYPES.NUMERIC],
        targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
        dataImportFieldId: dataImportField?.id,
      }
  }

  const pageOption = findPageDimensionOption(key)
  if (pageOption) return pageOption

  return null
}

/**
 * ページに関する DimensionOption を探す
 *
 * @param {string} key - The key to search for in the dimension options.
 * @returns {DimensionOption|null} - The found dimension option, or null if not found.
 */
const findPageDimensionOption = (key: string): DimensionOption | null => {
  const options = [DIMENSION_KEYS.BROWSING_PAGE, DIMENSION_KEYS.LANDING_PAGE]
    .map((checkKey) => {
      if (!key.includes(checkKey)) return
      const option = DEFAULT_DIMENSION_OPTIONS.find((opt) => opt.id === checkKey)
      if (option) return option
      return null
    })
    .filter(Boolean) as DimensionOption[]
  return options ? options[0] : null
}
