import { CHOICE_DATASET_KEYS } from '../api/PageReport/TimelineReport/constants'
import { ChoiceDatasetKey } from '../api/PageReport/TimelineReport/types'
import { PAGE_REPORT_TIMELINE_SECOND_CHOICE_COOKIE_KEY } from './constants'
import { useCookieState } from './useCookieState'

/**
 * 時系列グラフの2つ目の選択肢を取得するhook
 *
 * @returns {object} - The second choice of the timeline.
 */
export const useTimelineSecondChoice = () => {
  const [secondChoice, setSecondChoice] = useCookieState<ChoiceDatasetKey>({
    cookieKey: PAGE_REPORT_TIMELINE_SECOND_CHOICE_COOKIE_KEY,
    initialValue: CHOICE_DATASET_KEYS.GOAL_RATE,
  })
  return { secondChoice, setSecondChoice }
}
