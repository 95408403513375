import React, { useState } from 'react'
import { useUploadDataImportCsv } from '../../../../util/hooks/api/DataImport/useUploadDataImportCsv'
import { useDataImportCsv } from '../../../../util/hooks/api/DataImport/useDataImportCsv'
import { useToast } from '../../../../util/hooks/useToast'
import { scrollToElement } from '../../../../util/scrollToElement'
import { NormalButton } from '../../../common/Button'
import styled from 'styled-components'
import { Panel, PanelTitle, PanelText } from '../'
import { ErrorBox } from '../../../common/ErrorBox'
import { EmbeddedLoading } from '../../../common/EmbeddedLoading'
import { IMPORTED_HISTORY_AREA_ID } from '../..'

type Props = {
  projectId: string
}

export function ExecutionCsvUploadPanel({ projectId }: Props) {
  const [file, setFile] = useState<File | null>(null)
  const { openToast } = useToast()

  const {
    data: importCsvData,
    isError: isImportCsvDataError,
    error: importCsvDataError,
    isLoading: isImportCsvDataLoading,
    setDataImportCsv,
  } = useDataImportCsv({
    projectId: projectId,
  })

  const {
    mutate: uploadDataImportCsvMutate,
    isLoading: isUploadingDataImportCsv,
    isError: isUploadDataImportCsvError,
    error: uploadDataImportCsvError,
  } = useUploadDataImportCsv({
    projectId: projectId,
  })

  const isImporting: boolean = importCsvData?.isImporting || false
  const isLoading: boolean = isImportCsvDataLoading || isUploadingDataImportCsv

  const handleSetFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] || null)
  }

  const handleUpload = () => {
    if (!file) return
    const formData: FormData = new FormData()
    formData.append('file', file)
    uploadDataImportCsvMutate(
      { formData: formData },
      {
        onSuccess: function () {
          openToast({ message: 'ファイルをアップロードしました。' })
          setDataImportCsv({ isImporting: true })
        },
      },
    )
  }

  return (
    <>
      <Panel>
        <PanelTitle>CSVアップロード</PanelTitle>
        {isLoading && <EmbeddedLoading />}
        {isImportCsvDataError && (
          <ErrorBox>
            {typeof importCsvDataError === 'string'
              ? importCsvDataError
              : 'CSVのアップロードの状態取得に失敗しました。'}
          </ErrorBox>
        )}
        {isUploadDataImportCsvError && (
          <ErrorBox>
            {typeof uploadDataImportCsvError === 'string'
              ? uploadDataImportCsvError
              : 'CSVのアップロードに失敗しました。'}
          </ErrorBox>
        )}
        {!isLoading && (
          <>
            {isImporting && (
              <PanelText>
                データインポート中です。インポートには数分掛かる場合があります。 <br />
                インポート状況と結果は、
                <a href="#" onClick={() => scrollToElement(IMPORTED_HISTORY_AREA_ID)}>
                  インポート済データ
                </a>
                からご確認ください。
              </PanelText>
            )}
            {!isImporting && (
              <section>
                <UploadInput type="file" onChange={handleSetFile} />

                <ButtonArea>
                  <NormalButton onClick={handleUpload} disabled={!file}>
                    アップロード
                  </NormalButton>
                </ButtonArea>
              </section>
            )}
          </>
        )}
      </Panel>
    </>
  )
}

const UploadInput = styled.input`
  display: block;
  margin: 0 0 16px;
  padding: 20px;
  width: 100%;
  background: #f5f5f5;
`

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0 16px;
`
