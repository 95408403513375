// IPアドレスのフィルター条件
export const IpAddressConditionType = {
  perfect: 1, // 完全一致
  head: 2, // 前方一致
  regexp: 3, // 正規表現一致
  partial: 4, // 部分一致
  rear: 5, // 後方一致
}

export const IpAddressConditionMap: { [key: number]: string } = {
  1: '完全一致',
  2: '前方一致',
  3: '正規表現一致',
  4: '部分一致',
  5: '後方一致',
}
