import * as React from 'react'
import styled from 'styled-components'
import { DimensionOption, CustomFilterState, DimensionFilterState } from '../../../util/hooks/api/Filter/types'
import { DimensionItem } from './DimensionItem'
import { CustomFilterAction } from '../../../contexts/CustomFilterContext/state'

interface Props {
  readonly actions: CustomFilterAction
  filter: CustomFilterState
  filterIndex: number
  dimensionOptions: DimensionOption[]
}

export function DimensionItems(props: Props) {
  const { filter, dimensionOptions, ...otherProps } = props

  return (
    <>
      <Title>ディメンション</Title>
      {/* AND条件を並べる */}
      {filter.dimensions.map((dimension, dimensionIndex) => {
        {
          return dimension.states.map((state: DimensionFilterState, stateIndex: number) => {
            // OR条件を並べる
            return (
              <DimensionItem
                filter={filter}
                dimension={dimension}
                dimensionIndex={dimensionIndex}
                state={state}
                stateIndex={stateIndex}
                key={`${dimensionIndex}-${stateIndex}`}
                dimensionOptions={dimensionOptions.filter((option) =>
                  option.targetScopes.includes(filter.aggregate.unit),
                )}
                {...otherProps}
              />
            )
          })
        }
      })}
    </>
  )
}

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
`
