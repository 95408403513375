import * as React from 'react'
import { GlobalContext } from '../GlobalState'
import { Redirect } from '@gatsbyjs/reach-router'
import { usePageState } from './Auth/state'

export function Auth(props: any): JSX.Element {
  const { actions: globalActions } = React.useContext(GlobalContext)
  const {
    state: { loading, logined },
    actions,
  } = usePageState()

  React.useEffect(() => {
    if (loading) {
      actions.fetch(globalActions.setUserInfo)
    }
  }, [])

  if (loading) {
    return <div />
  }

  // ログインしていない時はリダイレクト
  if (!logined) {
    // ログインページと組織管理ページにはリダイレクトしない
    let path: string = props.location.pathname + props.location.search
    if (path.match('/login') || path.match('/admin')) {
      path = ''
    }
    return <Redirect to={path ? `/login/?next=${path}` : '/login'} noThrow />
  }

  return props.children
}
