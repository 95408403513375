import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { ContextType, useContextState } from './state'
import { SCOPE_TYPE, useScopeType } from '../../util/hooks/useScopeType'
import { SORT_KEYS } from '../../util/hooks/api/CustomDimensionReport/constants'
import { matchSortKeyAndScopeType } from '../../util/hooks/api/CustomDimensionReport/utils'

export const CustomDimensionReportContext = React.createContext<ContextType>(null as any)

interface Props extends RouteComponentProps {
  readonly children?: React.ReactNode | React.ReactNode[]
}

/**
 * カスタムディメンションレポートの state, actions をグローバルで使用できるよう Provider で定義する
 *
 * @param {Object} props - The props object containing the children component.
 * @param {React.ReactNode} props.children - The child component(s) to be wrapped.
 */
export function CustomDimensionReportProvider({ children }: Props) {
  const { state, actions } = useContextState()
  const { scopeType } = useScopeType()

  if (!matchSortKeyAndScopeType(state.sortKey, scopeType)) {
    // 集計単位の"ユーザー/セッション"とソートキーの"ユーザー数/セッション数"を合わせる。
    const newSortKey = scopeType === SCOPE_TYPE.USER ? SORT_KEYS.USER_COUNT : SORT_KEYS.SESSION_COUNT
    actions.setSortValues(newSortKey)
  }

  return (
    <CustomDimensionReportContext.Provider value={{ state, actions }}>{children}</CustomDimensionReportContext.Provider>
  )
}
