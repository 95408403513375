import styled from 'styled-components'
import { ChevronThinDown } from 'styled-icons/entypo'
import { ChevronDown } from 'styled-icons/boxicons-regular'
import { colors } from '../../styleConstants'

export const DownArrowIcon = styled(ChevronThinDown).attrs((props: { size?: string }) => ({
  size: props.size || '30',
  color: `${colors.blue}`,
}))``

export const BoldDownArrowIcon = styled(ChevronDown).attrs((props: { size?: string }) => ({
  size: props.size || '30',
  color: `${colors.blue}`,
}))``
