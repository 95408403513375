import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { InputErrorMessage } from '../errors/InputErrorMessage'

// ポップアップの最大幅
const POPUP_MAX_WIDTH = 400
// 三角フキダシの幅と高さ
const POPUP_TRIANGLE_WIDTH = 26
const POPUP_TRIANGLE_HEIGHT = 16

type HorizontalDirection = 'left' | 'right'
type VerticalDirection = 'top' | 'bottom'

export interface PopupContent {
  title?: string
  items: string[]
}

interface Props {
  contents: PopupContent[]
  horizontalDirection?: HorizontalDirection
  verticalDirection?: VerticalDirection
  offset?: { x: number; y: number }
  fitContent?: boolean
  error?: boolean
}

/**
 * ポップアップコンポーネント
 * マウスホバー時にポップアップを表示します。
 *
 * @param {Object} props
 * @param {PopupContent[]} props.contents - ポップアップの内容。空配列の場合はポップアップを非表示
 * @param {'left' | 'right'} [props.horizontalDirection='left'] - ポップアップの左右表示方向
 * @param {'top' | 'bottom'} [props.verticalDirection='bottom'] - ポップアップの上下表示方向
 * @param {{x: number, y: number}} [props.offset] - ポップアップの位置オフセット
 * @param {boolean} [props.fitContent=false] - ポップアップのホバー反応範囲をコンテンツの横幅まで縮小するかどうか。コンテンツがinlineやflex要素の場合にtrueにしてください。通常のblock要素でtrueにしてしまうと、block要素のテキストが短い場合などに背景色が横幅いっぱいまで広がらないことがあるのでtrueにしないでください
 * @param {boolean} [props.error=false] - エラー状態かどうか。エラー状態の場合はデザインを赤色に変更する
 * @returns {React.ReactElement}
 */
export const Popup: React.FC<Props> = ({
  children,
  contents,
  horizontalDirection = 'left',
  verticalDirection = 'bottom',
  offset,
  fitContent = false,
  error = false,
}) => {
  if (contents.length === 0) {
    return <>{children}</>
  }

  const [isPopupVisible, setIsPopupVisible] = React.useState(false)

  const handleMouseEnter = () => {
    setIsPopupVisible(true)
  }

  const handleMouseLeave = () => {
    setIsPopupVisible(false)
  }

  return (
    <Container
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      fitContent={fitContent}
      data-testid="popup"
    >
      {children}
      {isPopupVisible && (
        <PopupArea
          horizontalDirection={horizontalDirection}
          verticalDirection={verticalDirection}
          offset={offset}
          error={error}
        >
          {contents.map((content, i) => {
            return (
              <React.Fragment key={`${content}${i}`}>
                {i !== 0 && <Border />}
                {content.title && <Title>{content.title}</Title>}
                <Body>
                  {content.items.map((item) => {
                    return (
                      <React.Fragment key={item}>
                        {error ? <InputErrorMessage>{item}</InputErrorMessage> : <BodyText>{item}</BodyText>}
                      </React.Fragment>
                    )
                  })}
                </Body>
              </React.Fragment>
            )
          })}
        </PopupArea>
      )}
    </Container>
  )
}

const Container = styled.div<{ fitContent: boolean }>`
  position: relative;
  ${({ fitContent }) => fitContent && 'width: fit-content;'}
`

const PopupArea = styled.div<{
  horizontalDirection: HorizontalDirection
  verticalDirection: VerticalDirection
  offset?: { x: number; y: number }
  error: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  ${({ horizontalDirection, verticalDirection, offset, error }) => `
    ${horizontalDirection === 'left' ? 'left: 0;' : 'right: 0;'}
    ${
      verticalDirection === 'bottom' ? 'top: calc(100%);' : `bottom: calc(100% + ${POPUP_TRIANGLE_HEIGHT}px);` // フキダシ分を上にずらす
    }
    ${offset ? `transform: translate(${offset.x}px, ${offset.y}px);` : ''}
    ${
      error
        ? `filter: drop-shadow(0px 0px 1px ${colors.red}) drop-shadow(0px 0px 1px ${colors.red}) drop-shadow(0px 0px 1px ${colors.red});` // 赤い線が薄いので重ね掛けした
        : `filter: drop-shadow(0px 0px 1px ${colors.contentBlue.blue7});`
    }
  `}
  z-index: 1;
  max-width: ${POPUP_MAX_WIDTH}px;
  padding: 12px;
  background: ${colors.white};
  border-top: solid ${POPUP_TRIANGLE_HEIGHT}px rgb(255, 255, 255, 0);
  background-clip: padding-box;
  word-break: keep-all;
  overflow-wrap: break-word;
  white-space: normal;

  /* 三角フキダシ */
  &::before {
    content: '';
    position: absolute;
    ${({ verticalDirection, horizontalDirection }) => `
      ${
        verticalDirection === 'bottom'
          ? `top: -${POPUP_TRIANGLE_HEIGHT - 1}px;` // フキダシと四角の交わる部分のborderを隠すために -1px している
          : `bottom: -${POPUP_TRIANGLE_HEIGHT - 1}px;`
      }
      ${horizontalDirection === 'left' ? `left: ${POPUP_TRIANGLE_WIDTH}px;` : `right: ${POPUP_TRIANGLE_WIDTH}px;`}
      transform: ${verticalDirection === 'top' ? 'rotate(180deg)' : 'none'};
    `}
    width: ${POPUP_TRIANGLE_WIDTH}px;
    height: ${POPUP_TRIANGLE_HEIGHT}px;
    background: ${colors.white};
    clip-path: polygon(50% 30%, 0% 100%, 100% 100%);
  }

  /* verticalDirectionがtopの場合にホバー時にmouseLeaveしてしまいポップアップテキストが選択できないので、マウスのホバー反応領域を確保するための透明なエリアを用意した */
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    ${({ verticalDirection }) =>
      verticalDirection === 'top'
        ? `
          bottom: -${POPUP_TRIANGLE_HEIGHT}px;
          height: ${POPUP_TRIANGLE_HEIGHT}px;
        `
        : ''}
  }
`

const Title = styled.p`
  margin: 0;
  color: ${colors.contentBlue.blue7};
  font-weight: bold;
  font-size: 12px;
  line-height: 1.5;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const BodyText = styled.p`
  margin: 0;
  color: ${colors.black};
  font-size: 14px;
  line-height: 1.5;
`

const Border = styled.hr`
  height: 1px;
  border: none;
  width: 100%;
  background-color: ${colors.gray250};
`
