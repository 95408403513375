import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../../request'
import {
  DataImportFieldSuggestionPayload,
  DataImportFieldSuggestionResponse,
  DataImportFieldSuggestionData,
} from './types'

type Props = {
  projectId: string
  options: UseMutationOptions<DataImportFieldSuggestionData, string, DataImportFieldSuggestionPayload, void>
}

export const useUploadDataImportFieldSuggestion = ({ projectId, options }: Props) => {
  const mutationResult = useMutation({
    mutationFn: async (payload: DataImportFieldSuggestionPayload) => {
      const { formData } = payload
      const result: DataImportFieldSuggestionResponse = await request(
        'POST',
        `/api/projects/${projectId}/data_import_field_suggestion/`,
        true,
        formData,
      )

      const formattedResponse: DataImportFieldSuggestionData = {
        suggestionId: result.id,
        importFields: result.import_fields.map((importField) => {
          return {
            fieldName: importField.name,
            dataType: importField.type,
          }
        }),
      }
      return formattedResponse
    },
    ...options,
  })
  return mutationResult
}
